import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useField } from 'formik';

import styles from './ButtonTypeSelectorStyles.module.sass';

interface IButtonTypeSelectorProps {
  entry: any;
  name: string;
}

const ButtonTypeSelector: React.FC<IButtonTypeSelectorProps> = (
  props: IButtonTypeSelectorProps,
) => {
  const { entry, name } = props;
  const [{ value = '' }, , { setValue }] = useField(name);

  const onChange = (event: RadioChangeEvent) => setValue(event.target.value);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{entry.title.toUpperCase()}</div>
      <Radio.Group onChange={onChange} defaultValue={value}>
        <Radio value="outlined">
          <div className={styles.outlined} />
        </Radio>
        <Radio value="contained">
          <div className={styles.contained} />
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default ButtonTypeSelector;
