import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import BillingOrganizationService from 'services/billing/billing-organizations.service';
import { IBillingCarOwner, IBillingOrganization } from 'services/billing/billing-service.types';

import { CarOwnerValidation } from '../../car-owner.validation';

interface ICreateCarOwnerLinkStep {
  entry: IBillingCarOwner;
  onSubmitStep: (entry: any) => void;
}

const CreateCarOwnerStep: React.FC<ICreateCarOwnerLinkStep> = ({
  entry,
  onSubmitStep,
}: ICreateCarOwnerLinkStep) => {
  const validation = Yup.object().shape(CarOwnerValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IBillingCarOwner}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="firstname" label="Firstname">
              <Input name="firstname" placeholder="First Name" />
            </Form.Item>
            <Form.Item name="lastname" label="Lastname">
              <Input name="lastname" placeholder="Lastname" />
            </Form.Item>

            <Form.Item name="email" label="E-Mail">
              <Input name="email" placeholder="E-Mail" />
            </Form.Item>

            <Form.Item name="organization" label="Organization">
              <EntitySelector
                name="organization"
                entityService={BillingOrganizationService}
                onSelect={(organization: IBillingOrganization) =>
                  setFieldValue('organization', organization)
                }
              />
            </Form.Item>

            <Form.Item name="employeeNumber" label="Employee number">
              <Input name="employeeNumber" placeholder="" />
            </Form.Item>

            <Form.Item name="iban" label="IBAN">
              <Input name="iban" placeholder="DE..." />
            </Form.Item>

            <Form.Item name="bic" label="BIC">
              <Input name="bic" placeholder="" />
            </Form.Item>

            <Form.Item name="receiverName" label="Receiver Name">
              <Input name="receiverName" placeholder="" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateCarOwnerStep;
