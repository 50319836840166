import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import BillingOrganizationService from 'services/billing/billing-organizations.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ChargingStationDetailsTab from './tabs/BillingOrganizationsDetailsTab';
import BillingOrganizationsInvoicesTab from './tabs/BillingOrganizationsReportsTab';

const BillingOrganizationPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Invoices',
      displayName: 'Invoices',
      render: (entity: any) => <BillingOrganizationsInvoicesTab entry={entity} />,
    },
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: any, optionalProps?: ICrudDetailViewCallbacks) => (
        <ChargingStationDetailsTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];
  return (
    <CrudDetailContainer
      entityName="Billing Organization"
      tabs={tabs}
      baseLink={links.billingOrganization}
      defaultPayload={params}
      resource={BillingOrganizationService}
    />
  );
};

export default BillingOrganizationPage;
