import * as React from 'react';
import { Button, DatePicker, Form } from 'antd';
import { Moment } from 'moment';

import BillingOrganizationService from 'services/billing/billing-organizations.service';
import { showErrorNotification } from 'utils/notificationUtil';

import { IBillingOrganizationsDetailsTab } from '.';

const BillingOrganizationsInvoicesTab: React.FC<IBillingOrganizationsDetailsTab> = (
  props: IBillingOrganizationsDetailsTab,
) => {
  const { entry } = props;
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(null);

  if (!props) {
    return <div>data entry is missing</div>;
  }

  const doDownloadFile = async (callback: Function) => {
    if (!entry || !entry.id) {
      return;
    }
    const month = (selectedDate?.month() || 0) + 1;
    try {
      await callback(entry, selectedDate?.year(), month);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <div>
      <Form.Item label="Download Organization Statements">
        <DatePicker onChange={(date) => setSelectedDate(date)} picker="month" />
        <br />
        <br />
        <br />
        <br />
        <Button
          disabled={selectedDate === null}
          onClick={() => doDownloadFile(BillingOrganizationService.downloadCSV)}
        >
          Download CSV Export
        </Button>
        <br />
        <br />

        <Button
          disabled={selectedDate === null}
          onClick={() => doDownloadFile(BillingOrganizationService.downloadPDFInvoice)}
        >
          Download PDF Invoice
        </Button>
        <br />
        <br />

        <Button
          disabled={selectedDate === null}
          onClick={() => doDownloadFile(BillingOrganizationService.downloadReviewPdfDocument)}
        >
          Download Review PDF Document
        </Button>
        <br />
        <br />

        <Button
          disabled={selectedDate === null}
          onClick={() => doDownloadFile(BillingOrganizationService.downloadXml)}
        >
          Download Bank Transaction XML
        </Button>
      </Form.Item>
    </div>
  );
};

export default BillingOrganizationsInvoicesTab;
