import * as Yup from 'yup';

import {
  EnumISAConnectionType,
  EnumISAServiceTicketStatus,
  EnumISAWallboxLocation,
} from 'services/isa-service/isa-service.types';

export const INITIAL_VALUES = {
  user: null,
  wallbox: null,
  carModel: null,
  problem: null,
  serialNumber: null,
  typeReference: null,
  connectionType: null,
  wallboxLocation: null,
  issueDescription: null,
  questionResponse: null,
  diagnostic: null,
  questionResponses: null,
  status: EnumISAServiceTicketStatus.OPEN,
};

export const validationSchema = Yup.object().shape({
  serialNumber: Yup.string()
    .nullable()
    .min(4, 'Field has to be longer than 4 characters!')
    .max(12, 'Field has not to be longer than 12 characters!')
    .required('Required'),
  typeReference: Yup.string()
    .nullable()
    .min(4, 'Field has to be longer than 4 characters!')
    .max(12, 'Field has not to be longer than 12 characters!')
    .required('Required'),
  connectionType: Yup.mixed<EnumISAConnectionType>()
    .nullable()
    .oneOf(Object.values(EnumISAConnectionType), 'Required')
    .required('Required'),
  wallboxLocation: Yup.mixed<EnumISAWallboxLocation>()
    .nullable()
    .oneOf(Object.values(EnumISAWallboxLocation), 'Required')
    .required('Required'),
  issueDescription: Yup.string()
    .nullable()
    .min(4, 'Field has to be longer than 4 characters!')
    .max(500, 'Field has not to be longer than 500 characters!')
    .required('Required'),
  user: Yup.object().nullable(),
  carModel: Yup.object().nullable(),
  diagnostic: Yup.object().nullable(),
  problem: Yup.object().nullable(),
  questionResponses: Yup.array().nullable(),
  wallbox: Yup.object().nullable(),
  status: Yup.mixed<EnumISAServiceTicketStatus>()
    .nullable()
    .oneOf(Object.values(EnumISAServiceTicketStatus)),
});
