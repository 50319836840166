import { notification } from 'antd';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

const prevError = {
  error: null,
  time: new Date().getTime(),
};
interface IShowErrorParams {
  message?: String;
  description?: String;
  error?: any;
  type?: 'success' | 'error' | 'warning';
}

export const showNotification = (props: IShowErrorParams) => {
  const { message, error, type } = props;
  let { description } = props;
  const msg = message || error.message || error || 'unkown error';

  if (message && error && !description) {
    description = error.message;
  }

  notification.open({
    message: msg,
    type,
    description,
  });
};

export const showErrorNotification = async (error: any, sender?: string) => {
  if (isEqual(error, prevError.error) && new Date().getTime() - prevError.time < 500) {
    return;
  }

  prevError.error = error;
  prevError.time = new Date().getTime();

  const message = `${error.message || error.error || error || 'unkown error'}`;
  let description = sender;
  const httpResponse = get(error, 'response.data.message');
  if (httpResponse) {
    description = httpResponse;
  }
  // handle blob errors:
  const blobResponse = get(error, 'response.data');
  if (blobResponse && blobResponse.size && blobResponse.text && blobResponse.size < 2048) {
    const text = await blobResponse.text();
    if (text) {
      description = text;
    }
  }

  showNotification({ type: 'error', message, description });
};

export const showSuccessNotification = (message: string, description?: string) => {
  showNotification({ type: 'success', message, description });
};
