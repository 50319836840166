import React from 'react';

import { FormEntitySelect, FormikForm, FormSubmit } from 'components/forms';
import { IFormEntitySelectProps } from 'components/forms/FormEntitySelect';
import { IFormikFormProps } from 'components/forms/FormikForm';
import {
  ISADiagnosticsService,
  ISAProblemsService,
  ISASolutionsService,
  ISAWallboxesService,
} from 'services/isa-service';
import { IISAMapping } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISAMapping;
  onSubmit: (entry: IISAMapping) => Promise<IISAMapping>;
}

export default function ISAMappingForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISAMapping> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISAMapping,
  };

  const wallboxProps: IFormEntitySelectProps = {
    label: 'Wallbox',
    name: 'wallbox',
    entityService: ISAWallboxesService,
    current: entry.wallbox,
    required: true,
  };

  const problemProps: IFormEntitySelectProps = {
    label: 'Problem',
    name: 'problem',
    entityService: ISAProblemsService,
    current: entry.problem,
    required: true,
  };

  const solutionProps: IFormEntitySelectProps = {
    label: 'Solution',
    name: 'solution',
    entityService: ISASolutionsService,
    current: entry.solution,
    required: true,
  };

  const diagnosticProps: IFormEntitySelectProps = {
    label: 'Diagnostic',
    name: 'diagnostic',
    entityService: ISADiagnosticsService,
    current: entry.diagnostic,
  };

  return (
    <FormikForm {...formikProps}>
      <FormEntitySelect {...wallboxProps} />
      <FormEntitySelect {...problemProps} />
      <FormEntitySelect {...solutionProps} />
      <FormEntitySelect {...diagnosticProps} />
      <FormSubmit />
    </FormikForm>
  );
}
