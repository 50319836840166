import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ClientRequestState } from 'framework/constants';
import TendergyArchiveRequestService from 'services/tendergy/tendergy-archive-request.service';
import * as actions from 'store/tendegyService/request/request.actions';
import { IClientRequest, ITendergyUser } from 'store/tendegyService/tendergyService.types';
import { CurrencyUtils } from 'utils/currencyUtils';
import { formatDefaultDate } from 'utils/date.helper';

const sortFields = [
  { dataIndex: 'displayId', title: 'No.' },
  { dataIndex: 'createdAt', title: 'Created date' },
  { dataIndex: 'deletedDate', title: 'Deleted date' },
];

const ArchiveRequestsOverviewPage: React.FC = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [request, setRequest] = useState<IClientRequest>();

  const columns = [
    {
      title: 'No.',
      dataIndex: 'displayId',
      key: 'displayId',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Client',
      dataIndex: 'owner',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      key: 'owner',
      render: (owner: ITendergyUser) =>
        owner ? (
          <>
            {' '}
            {owner.firstName} {owner.lastName}{' '}
          </>
        ) : (
          'n/a'
        ),
    },
    {
      title: 'Location',
      dataIndex: 'address',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      key: 'address',
      render: (address: any) => (address ? `${address.postalCode} ${address.city}` : 'n/a'),
    },
    {
      title: 'Price Range',
      render: (row: IClientRequest) =>
        `${CurrencyUtils.defaultFormatter(row.costEstimateMin)} - 
        ${CurrencyUtils.defaultFormatter(row.costEstimateMax)}`,
    },
    {
      title: 'Start date',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: string) => formatDefaultDate(date),
    },
    {
      title: 'Soonest Possible',
      dataIndex: 'soonestPossible',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      key: 'soonestPossible',
      render: (value: boolean) => (value ? value.toString() : 'false'),
    },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      key: 'state',
      render: (value: ClientRequestState) => value || '–',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      render: (date: string) => formatDefaultDate(date),
    },
    {
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      title: 'Deleted Date',
      dataIndex: 'deletedDate',
      key: 'deletedDate',
      render: (date: string) => formatDefaultDate(date),
    },
    {
      title: 'Action',
      key: 'action',
      render: renderArchive,
    },
  ];

  function renderArchive(row: IClientRequest) {
    const disabled = !row.archived && row.deletedDate ? false : true;
    return (
      <Button
        icon={<DeleteOutlined />}
        disabled={disabled}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setRequest(row);
          setVisible(true);
        }}
        type="text"
        danger
      />
    );
  }

  const unarchiveRequest = () => {
    dispatch(actions.unarchive(request));
    setVisible(false);
  };

  return (
    <>
      <CrudListViewContainer
        withSorting
        sortFields={sortFields}
        entityName="Archive Client Requests"
        columns={columns}
        resource={TendergyArchiveRequestService}
      />
      <Modal
        title="CONFIRM ACTION"
        visible={visible}
        onOk={() => unarchiveRequest()}
        onCancel={() => setVisible(false)}
      >
        <p>Are you sure you want to unarchive request?</p>
      </Modal>
    </>
  );
};
export default ArchiveRequestsOverviewPage;
