import * as React from 'react';
import * as Yup from 'yup';

import { FormikForm, FormInput, FormSubmit } from 'components/forms';
import { buildValues } from 'framework/form';
import { IShopStore } from 'services/shop/shop-service.types';

import { StoreValidation } from '../../../store.validation';
import AdditionalInformationBlock from '../../details/components/AdditionalInformationBlock';
import AddressBlock from '../../details/components/AddressBlock';
import ContactInformationBlock from '../../details/components/ContactBlock';
import PaymentMethodsSelector from '../../details/components/PaymentMethodsSelector';

import styles from '../../details/tabs/StoreTabsStyles.module.sass';

interface Props {
  entry: IShopStore;
  onSubmitStep: (entry: IShopStore) => void;
}

const StoreBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(StoreValidation);

  return (
    <FormikForm
      initialValues={buildValues(validation, entry) as IShopStore}
      validationSchema={validation}
      onSubmit={(values) => onSubmitStep(values)}
      className={styles.form}
    >
      <FormInput name="name" label="Store name" required />
      <FormInput name="publicUrl" label="Store url" required />
      <FormInput name="registrationId" label="Registration Id" />
      <PaymentMethodsSelector name="paymentMethods" label="Payment methods" required />

      <ContactInformationBlock />
      <AddressBlock />
      <AdditionalInformationBlock />

      <FormSubmit />
    </FormikForm>
  );
};

export default StoreBaseStep;
