import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IShopStoreEmail } from './shop-service.types';

class StoreEmailsServiceClass extends EntityService<IShopStoreEmail> {}

const StoreEmailsService = new StoreEmailsServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/store-emails',
  createLink: links.shopCreateStoreEmail,
  detailLink: links.shopStoreEmail,
});

export default StoreEmailsService;
