import * as React from 'react';
import { useState } from 'react';
import { Modal } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import {
  ITendergyClientRequestPool,
  ITendergyQuestionnaireConfig,
  QuestionKey,
} from 'store/tendegyService/tendergyService.types';

import { QuestionnaireConfigValidation } from '../questionnaire-config.validation';

const QuestionnaireConfigOverviewTab: React.FC<
  IGenericEditTabProps<ITendergyQuestionnaireConfig>
> = (props: IGenericEditTabProps<ITendergyQuestionnaireConfig>) => {
  const { entry, onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  if (!props) {
    return <div>data entry is missing</div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  const onOk = (values: ITendergyQuestionnaireConfig, setSubmitting: Function) => {
    onSave(values);
    setVisible(false);
    setSubmitting(false);
  };

  const onCancel = (setSubmitting: Function, resetForm: Function) => {
    setVisible(false);
    setSubmitting(false);
    resetForm();
  };
  const validation = Yup.object().shape(QuestionnaireConfigValidation);
  return (
    <div>
      <Formik
        initialValues={entry as ITendergyQuestionnaireConfig}
        onSubmit={() => {}}
        validationSchema={validation}
      >
        {({ handleSubmit, setFieldValue, values, setSubmitting, resetForm }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="pool" label="Pool">
              <EntitySelector
                entityService={ClientRequestPoolService}
                name="pool"
                current={entry.pool}
                onSelect={(pool: ITendergyClientRequestPool) => {
                  setFieldValue('pool', pool);
                }}
              />
            </Form.Item>

            <Form.Item name="questionKey" label="Question Key">
              <Select name="questionKey">
                {Object.entries(QuestionKey).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="defaultAnswer" label="Default Answer(JSON)">
              <Input name="defaultAnswer" />
            </Form.Item>

            <Form.Item name="possibleAnswers" label="Possible Answers(JSON)">
              <Input name="possibleAnswers" />
            </Form.Item>

            <Form.Item name="isDisabled" label="Disabled">
              <Switch name="isDisabled" />
            </Form.Item>

            <Form.Item name="isVisible" label="Visible">
              <Switch name="isVisible" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton onClick={() => setVisible(true)}>Submit</SubmitButton>
            </Form.Item>
            <Modal
              title="CONFIRM ACTION"
              visible={visible}
              onOk={() => onOk(values, setSubmitting)}
              onCancel={() => onCancel(setSubmitting, resetForm)}
            >
              <p>
                Editing this config incorrectly can cause systems to fail, are you sure you want to
                do this?
              </p>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionnaireConfigOverviewTab;
