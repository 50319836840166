import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';

import JsonTwoEntriesTab from 'components/layout/JsonTwoEntriesTab/JsonTwoEntriesTab';
import Tabs from 'components/layout/Tabs';
import links from 'framework/links';
import AuthOrganizationService from 'services/auth/auth-organization.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import { IAuthOrganization } from 'store/authService/authService.types';
import { lastOpenedPageSelector } from 'store/common/common.selectors';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import {
  ITendergyOrganization,
  OrganizationTypes,
} from 'store/tendegyService/tendergyService.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import OrganizationActionsComponent from './components/OrganizationActionsComponent/OrganizationActionsComponent';
import OrganizationOverviewTab from './tabs/AuthOrganizationTab/AuthOrganizationTab';
import ClientRequestPoolsTab from './tabs/ClientRequestPoolsTab/ClientRequestPoolsTab';
import CraftOrganizationTab from './tabs/CraftOrganizationTab/CraftOrganizationTab';
import DefaultSettings from './tabs/DefaultSettings/DefaultSettings';
import LogoTab from './tabs/LogoTab/LogoTab';
import MembershipTab from './tabs/MembershipTab/MembershipTab';
import OrganizationMembersTab from './tabs/OrganizationMembersTab/OrganizationMembersTab';

import styles from './OrganizationDetailsPage.module.sass';

const OrganizationDetailsPages = Object.freeze({
  JSON: 'json',
  OVERVIEW: 'overview',
  USERS: 'users',
  TENDERGY_ORGANIZATION: 'tendergy-organization',
  POOLS: 'pools',
  MEMBERSHIP: 'membership',
  LOGO: 'logo',
  DEFAULT_SETTINGS: 'default-settings',
});

const OrganizationDetailsPageNames = Object.freeze({
  [OrganizationDetailsPages.OVERVIEW]: 'Overview',
  [OrganizationDetailsPages.TENDERGY_ORGANIZATION]: 'Tendergy Organization',
  [OrganizationDetailsPages.USERS]: 'Members',
  [OrganizationDetailsPages.POOLS]: 'Pools',
  [OrganizationDetailsPages.MEMBERSHIP]: 'Membership Details',
  [OrganizationDetailsPages.JSON]: 'JSON',
  [OrganizationDetailsPages.LOGO]: 'LOGO',
  [OrganizationDetailsPages.DEFAULT_SETTINGS]: 'Default Settings',
});

const OrganizationDetails: React.FC = () => {
  const history = useHistory();
  const {
    params: { id, page },
  } = useRouteMatch() as any;
  const activePage = page || OrganizationDetailsPages.OVERVIEW;
  const [craftOrganization, setCraftOrganization] = useState<ITendergyOrganization>();
  const [authOrganization, setAuthOrganization] = useState<IAuthOrganization>();

  const lastOpenedPage = useSelector(lastOpenedPageSelector);

  const craftPayload: IGetSingleEntityPayload = {
    id,
  };

  const getCraftData = async () => {
    try {
      const response = await TendergyOrganizationService.getById(craftPayload);
      setCraftOrganization(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const getAuthData = async () => {
    try {
      const response = await AuthOrganizationService.getById({ id });
      setAuthOrganization(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const saveAuthData = async (updatedValues: IAuthOrganization) => {
    try {
      await AuthOrganizationService.save({ ...updatedValues });
      getAuthData();
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const saveCraftData = async (updatedValues: ITendergyOrganization) => {
    try {
      await TendergyOrganizationService.save({
        ...updatedValues,
        variableFee: updatedValues.variableFee || null,
      });
      getCraftData();
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const confirmOrganization = () => {
    TendergyOrganizationService.confirmById(id).then(() => {
      getCraftData();
    });
  };

  const rejectOrganization = (rejectReason: string) => {
    if (craftOrganization) {
      const payload = { id, rejectReason };

      TendergyOrganizationService.rejectById(payload).then(() => {
        getCraftData();
      });
    }
  };

  const deleteOrganization = async () => {
    const payload = { id };
    try {
      await AuthOrganizationService.deleteById(payload);
      showSuccessNotification('Entry successfully removed');
      // @ts-ignore
      history.goBack();
    } catch (e) {
      showErrorNotification(e);
      console.error(e);
    }
  };

  useEffect(() => {
    getAuthData();
    getCraftData();
  }, [id]); //eslint-disable-line

  const renderContent = () => {
    switch (activePage) {
      case OrganizationDetailsPages.JSON:
        return (
          <JsonTwoEntriesTab
            firstEntry={authOrganization}
            firstTitle="Auth Data"
            secondEntry={craftOrganization}
            secondTitle="Tendergy Data"
          />
        );
      case OrganizationDetailsPages.OVERVIEW:
        return <OrganizationOverviewTab entry={authOrganization} onSave={saveAuthData} />;
      case OrganizationDetailsPages.TENDERGY_ORGANIZATION:
        return <CraftOrganizationTab entry={craftOrganization} onSave={saveCraftData} />;
      case OrganizationDetailsPages.USERS:
        return <OrganizationMembersTab organization={craftOrganization} />;
      case OrganizationDetailsPages.POOLS:
        return (
          <ClientRequestPoolsTab
            poolTenderOrganizations={craftOrganization?.poolTenderOrganizations}
          />
        );
      case OrganizationDetailsPages.MEMBERSHIP:
        return <MembershipTab organization={craftOrganization} />;
      case OrganizationDetailsPages.LOGO:
        return <LogoTab organization={craftOrganization} getCraftData={getCraftData} />;
      case OrganizationDetailsPages.DEFAULT_SETTINGS:
        return <DefaultSettings organization={craftOrganization} />;
      default:
        return;
    }
  };

  const pagesToRender = { ...OrganizationDetailsPageNames };

  const renderTabs = () => {
    if (craftOrganization?.type !== OrganizationTypes.Intermediate) {
      delete pagesToRender.membership;
    }
    return (
      <Tabs
        activeTitle="Details of Organization"
        activePageKey={activePage}
        baseRoute={{
          link: links.organization,
          params: { id },
        }}
        contentMinHeight="calc(100vh - 280px)"
        pages={pagesToRender}
      >
        {renderContent()}
      </Tabs>
    );
  };

  const renderActions = () => (
    <OrganizationActionsComponent
      entry={craftOrganization}
      onDelete={deleteOrganization}
      rejectOrganization={rejectOrganization}
      confirmOrganization={confirmOrganization}
    />
  );

  const goBack = () => {
    if (lastOpenedPage) {
      history.push(lastOpenedPage);
    } else {
      // @ts-ignore
      history.push(links.users);
    }
  };

  return (
    <Row gutter={16}>
      <Col span={18}>
        <Button type="link" className={styles.backButton} onClick={goBack}>
          <ArrowLeftOutlined />
          <span>Back</span>
        </Button>
        <Typography.Title level={3} className={styles.title}>
          {`${authOrganization?.displayId} ${authOrganization?.name}`}
        </Typography.Title>
        {renderTabs()}
      </Col>
      <Col span={6}>{renderActions()}</Col>
    </Row>
  );
};

export default OrganizationDetails;
