import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import {
  ITendergyOrganization,
  ITendergyOrgDefaultSettings,
} from 'store/tendegyService/tendergyService.types';

import { IRejectPayload } from '../../store/storeInterfaces';

class CraftOrganizationServiceClass extends EntityService<ITendergyOrganization> {
  confirmById = (id: string) => this.post(`${this.config.entityURL}/${id}/approve`, {}, true);

  rejectById = (payload: IRejectPayload) =>
    this.post(
      `${this.config.entityURL}/${payload.id}/reject`,
      { rejectReason: payload.rejectReason },
      true,
    );

  getMembership = (id: string) => this.get(`${this.config.entityURL}/${id}/membership`);

  getDisplayName(entry: any): string {
    return entry?.organization?.name ? entry?.organization.name : entry.name;
  }

  getDefaultSettings = (orgId: string) => this.get(`${this.config.entityURL}/${orgId}/defaults`);

  saveDefaultSettings = (orgId: string, dataToUpdate: ITendergyOrgDefaultSettings) =>
    this.post(`${this.config.entityURL}/${orgId}/defaults`, dataToUpdate);
}

const TendergyOrganizationService = new CraftOrganizationServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/organizations',
  detailLink: links.organization,
});

export default TendergyOrganizationService;
