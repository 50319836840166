import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IEmailTemplate } from './email-service.types';

class EmailTemplatesServiceClass extends EntityService<IEmailTemplate> {
  getDisplayName(entry: IEmailTemplate): string {
    return `${entry.name}, ${entry.language}`;
  }
}

const EmailTemplatesService = new EmailTemplatesServiceClass({
  apiURL: config.EMAIL_SERVICE_URL,
  entityURL: 'admin/templates',
  createLink: links.emailCreateTemplate,
  detailLink: links.emailTemplate,
});

export default EmailTemplatesService;
