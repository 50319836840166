import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { IISAQuestion } from 'services/isa-service/isa-service.types';

interface IISAQuestionsTabProps {
  questions: IISAQuestion[];
}

export default function ISAQuestionsTab(props: IISAQuestionsTabProps) {
  const { questions } = props;
  const history = useHistory();

  function handleRowClick(value: IISAQuestion) {
    return () => history.push(generatePath(links.isaCarModelsDetails, { id: value.id }));
  }

  return (
    <Table
      columns={[
        { title: 'Question Title', dataIndex: 'value' },
        { title: 'Logical Name', dataIndex: 'logicalName' },
      ]}
      rowKey="id"
      dataSource={questions}
      onRow={(value) => ({ onClick: handleRowClick(value) })}
    />
  );
}
