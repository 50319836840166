import * as React from 'react';
import { Form, FormItemProps, Input, TextAreaProps } from 'formik-antd';

export interface IFormTextAreaProps extends Partial<FormItemProps & TextAreaProps> {
  name: string;
}

function FormTextArea(props: IFormTextAreaProps) {
  const { name, ...rest } = props;

  return (
    <Form.Item name={name} {...rest}>
      <Input.TextArea name={name} />
    </Form.Item>
  );
}

export default FormTextArea;
