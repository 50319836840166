import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IB } from 'pages/billing-v3/charging-stations/constants';
import InvitationLinkService from 'services/tendergy/tendergy-invitation-links.service';
import {
  ITendergyClientRequestPool,
  ITendergyInvitationLink,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

import InvitationLinksFilters from '../components/InvitationLinksFilters';

const columns: IB<ITendergyInvitationLink>[] = [
  {
    title: 'Organization',
    dataIndex: 'organization',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'organization',
    sortParameter: 'organization.name',
    render: (value: ITendergyOrganization) => (value ? value.name : '–'),
  },
  {
    title: 'Client Request Pool',
    dataIndex: 'clientRequestPool',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'clientRequestPool',
    sortParameter: 'clientRequestPool.name',
    render: (value: ITendergyClientRequestPool) => (value ? value.name : '–'),
  },
  {
    title: 'Token',
    dataIndex: 'token',
    key: 'token',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },

  {
    title: 'Type',
    dataIndex: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'type',
  },
  {
    title: 'Expiry',
    dataIndex: 'expires',
    key: 'expires',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date: string) => formatDefaultDate(date),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const InvitationLinksOverview: React.FC = () => (
  <CrudListViewContainer
    entityName="Invitation Links"
    columns={columns}
    resource={InvitationLinkService}
    customFilters={InvitationLinksFilters}
    disableSearch
  />
);
export default InvitationLinksOverview;
