import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IProductModel } from 'store/tendegyService/tendergyService.types';

import styles from './styles.module.sass';

interface IProps {
  entry: IProductModel;
  triggerReload: Function;
}

const ProductActionsComponent: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();

  const { entry } = props;

  return (
    <div className={styles.containerOuter}>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push(
              createLink(links.productVendor, {
                id: entry.vendor && entry.vendor.id,
              }),
            )
          }
        >
          Go to Vendor
        </Button>
      </div>
    </div>
  );
};
export default ProductActionsComponent;
