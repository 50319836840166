import config from 'config';

import { ISA_DIAGNOSTIC_STEPS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISADiagnosticStep } from '../isa-service.types';

const ISADiagnosticStepsService = new EntityService<IISADiagnosticStep>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_DIAGNOSTIC_STEPS_ENDPOINT,
  createLink: links.isaDiagnosticStepsCreate,
  detailLink: links.isaDiagnosticStepsDetails,
});

ISADiagnosticStepsService.getDisplayName = (entry) => entry.title;

export default ISADiagnosticStepsService;
