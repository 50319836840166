import React from 'react';

import styles from './styles.module.sass';

interface IProps {
  currentTab: string;
}

const BillOrganizationActions: React.FC<IProps> = () => {
  return <div className={styles.containerInner} />;
};
export default BillOrganizationActions;
