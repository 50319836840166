import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import BillingCarOwnerService from 'services/billing/billing-car-owner.service';
import { IBillingCarOwner, IParams } from 'services/billing/billing-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import CarOwnerActionsComponent from './components/CarOwnerActionsComponent/CarOwnerActionsComponent';
import CarOwnerChargingStationsTab from './tabs/CarOwnerChargingStations';
import CarOwnerRFIDCardsTab from './tabs/CarOwnerRFIDCardsTab';
import CarOwnerDetailsTab from './tabs/CarOwnersDetailsTab';
import CarOwnerProofOfChargingTab from './tabs/CarOwnersInvoicesTab';

const BillingCarOwnerPage: React.FC = () => {
  const { id } = useParams<IParams>();

  const params: IGetSingleEntityPayload = {
    id,
    relations: [
      'energyPrices',
      'carOwnerRFIDCards',
      'carOwnerRFIDCards.rfidCard',
      'chargingStations',
      'address',
      'organization',
    ],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IBillingCarOwner, { onSave }: ICrudDetailViewCallbacks) => (
        <CarOwnerDetailsTab entry={entity} onSave={onSave} />
      ),
    },
    {
      name: 'poc',
      displayName: 'Proof of chargings',
      render: (entity: IBillingCarOwner, { onSave }: ICrudDetailViewCallbacks) => (
        <CarOwnerProofOfChargingTab entry={entity} onSave={onSave} />
      ),
    },
    {
      name: 'rfidcards',
      displayName: 'RFIDCards',
      render: (entity: IBillingCarOwner, { onSave }: ICrudDetailViewCallbacks) => (
        <CarOwnerRFIDCardsTab entry={entity} onSave={onSave} />
      ),
    },
    {
      name: 'charingstations',
      displayName: 'Charging stations',
      render: (entity: IBillingCarOwner, { onSave }: ICrudDetailViewCallbacks) => (
        <CarOwnerChargingStationsTab entry={entity} onSave={onSave} />
      ),
    },
  ];

  const renderActions = (entry: IBillingCarOwner, triggerReload: Function) => (
    <CarOwnerActionsComponent entry={entry} triggerReload={triggerReload} />
  );

  const renderTitle = (entry: IBillingCarOwner) => `${entry.firstname} ${entry.lastname}`;
  return (
    <CrudDetailContainer
      entityName="Car Owner"
      tabs={tabs}
      renderActions={renderActions}
      renderTitle={renderTitle}
      baseLink={links.billingCarOwner}
      defaultPayload={params}
      resource={BillingCarOwnerService}
    />
  );
};

export default BillingCarOwnerPage;
