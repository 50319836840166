import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ConsultantCommercialFleetTypeService } from 'services/consulant/commercialFleetType';

import Filters from './components/filter';
import columns from './constants';

const ConsultantCommercialFleetTypePage: React.FC = () => {
  return (
    <CrudListViewContainer
      entityName="Entities"
      columns={columns}
      resource={ConsultantCommercialFleetTypeService}
      customFilters={Filters}
    />
  );
};
export default ConsultantCommercialFleetTypePage;
