import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import AddressComponent from 'components/billing/address-component/AddressComponent';
import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import BillingOrganizationService from 'services/billing/billing-organizations.service';
import { IBillingCarOwner, IBillingOrganization } from 'services/billing/billing-service.types';

import { CarOwnerValidation } from '../../car-owner.validation';

interface ICarOwnerDetailsTabProps {
  entry: IBillingCarOwner;
  onSave: (entry: IBillingCarOwner) => Promise<IBillingCarOwner>;
}

const CarOwnerDetailsTab: React.FC<ICarOwnerDetailsTabProps> = (
  props: ICarOwnerDetailsTabProps,
) => {
  const { entry, onSave } = props;

  const validation = Yup.object().shape(CarOwnerValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IBillingCarOwner}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="firstname" label="Firstname">
              <Input name="firstname" placeholder="First Name" />
            </Form.Item>
            <Form.Item name="lastname" label="Lastname">
              <Input name="lastname" placeholder="Lastname" />
            </Form.Item>

            <Form.Item name="email" label="E-Mail">
              <Input name="email" placeholder="E-Mail" />
            </Form.Item>

            <Form.Item name="employeeNumber" label="Employee number">
              <Input name="employeeNumber" placeholder="" />
            </Form.Item>

            <Form.Item name="organization" label="Organization">
              <EntitySelector
                name="organization"
                entityService={BillingOrganizationService}
                current={entry.organization}
                onSelect={(organization: IBillingOrganization) =>
                  setFieldValue('organization', organization)
                }
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </Form.Item>

            <AddressComponent
              value={entry.address}
              onChange={(address) => setFieldValue('address', address)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CarOwnerDetailsTab;
