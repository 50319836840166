import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillImportsService from 'services/billing-v2/billing-v2-imports.service';
import { IBillingImport } from 'services/billing-v2/billing-v2-service.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IBillingImport> = [
  {
    title: 'Id',
    dataIndex: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const ImportsOverviewPage: React.FC = () => {
  return (
    <CrudListViewContainer entityName="Imports" columns={columns} resource={BillImportsService} />
  );
};
export default ImportsOverviewPage;
