export const getFilteredFormFields = (form: { [key: string]: any }) => {
  const keys = Object.keys(form);
  const filteredForm: any = {};

  keys.forEach((key) => {
    const value = form[key];
    if (value !== null && value !== undefined && value !== '') {
      filteredForm[key] = value;
    }
  });

  return filteredForm;
};
