import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergyInvitationLink } from 'store/tendegyService/tendergyService.types';

import config from '../../config';

class InvitationLinkServiceClass extends EntityService<ITendergyInvitationLink> {
  createLink = ({ formData, id }: any) =>
    this.post(`admin/organizations/${id}/invite-link`, formData);
}
const InvitationLinkService = new InvitationLinkServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/invite-tokens',
  createLink: links.createInviteLink,
  detailLink: links.invitationLink,
});

export default InvitationLinkService;
