import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IClientRequest } from 'store/tendegyService/tendergyService.types';

class ClientArchiveRequestAPI extends EntityService<IClientRequest> {
  getDisplayName(entry: IClientRequest): string {
    return entry.id as string;
  }
}
const TendergyArchiveRequestService = new ClientArchiveRequestAPI({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/client-requests/archive/',
  detailLink: links.archiveRequests,
});

export default TendergyArchiveRequestService;
