import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISASolutionsService } from 'services/isa-service';

import ISASolutionsForm from './components/ISASolutionsForm';

export default function ISASolutionsCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Solution',
          render: (_entry, onSubmit) => <ISASolutionsForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="solution"
      baseLink={links.isaSolutionsOverview}
      resource={ISASolutionsService}
    />
  );
}
