import * as Yup from 'yup';

export const INITIAL_VALUES = {
  value: null,
  logicalName: null,
  problemCategory: null,
};

export const validationSchema = Yup.object().shape({
  value: Yup.string().nullable().required('Required field'),
  logicalName: Yup.string().nullable().required('Required field'),
  problemCategory: Yup.object().nullable(),
});
