import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

class BillChargingStationsServiceClass extends EntityService<any> {
  getDisplayName(entry: any): string {
    return `${entry.firstName} ${entry.email}`;
  }
}
class BillOrganizationServiceClass extends EntityService<any> {
  getDisplayName(entry: any): string {
    return `${entry.name} ${entry.id}`;
  }
}

class BillChargePointGroupClass extends EntityService<any> {
  getDisplayName(entry: any): string {
    return `${entry.name}`;
  }
}
class BillChargePointClass extends EntityService<any> {
  getDisplayName(entry: any): string {
    return `${entry.evse}`;
  }
}

const BillChargingStationsService = new BillChargingStationsServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/charging-points',
  detailLink: links.billV3ChargingStation,
  createLink: links.billV3CreateChargingStation,
});
export const BillChargingPointGroupService = new BillChargingStationsServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/charging-point-groups',
  detailLink: links.billV3ChargingStation,
  createLink: links.billV3CreateChargingStation,
});
export const BillChargingPointGroupAssignService = new BillChargingStationsServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/organizations',
  detailLink: links.billV3ChargingStation,
  createLink: links.billV3CreateChargingStation,
  oneToManyRelation: '/{organizationId}/access-relations/{chargingPointGroupId}',
});
export const BillChargingStationsGroupService = new BillChargingStationsServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/charging-point-groups',
  detailLink: links.billV3ChargingStation,
  createLink: links.billV3CreateChargingStation,
  oneToManyRelation: '/{groupId}/attach/{chargingPointId}',
});
export const BillChargingStationAccess = new BillChargingStationsServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/users',
  detailLink: links.billV3ChargingStation,
  createLink: links.billV3CreateChargingStation,
  oneToManyRelation: '/{userId}/access-relations/{chargingPointId}',
});
export const BillCGPList = new BillChargePointGroupClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/charging-point-groups',
});
export const BillCPList = new BillChargePointClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/charging-points',
});
export const BillCPListwithPathname = new BillChargePointClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/charging-points',
  additionalPathname: 'id',
});
export const BillGetUsers = new BillChargingStationsServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/users',
});
export const BillGetOrganizations = new BillOrganizationServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/organizations',
});
export const BillGetOrganizationsId = new BillOrganizationServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/organizations',
});
export const BillGetCountries = new BillOrganizationServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/countries?limit=50',
});
export default BillChargingStationsService;
