import React from 'react';

import { FormEntitySelect, FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormEntitySelectProps } from 'components/forms/FormEntitySelect';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { ISASolutionsService } from 'services/isa-service';
import { IISASolutionStep } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISASolutionStep;
  onSubmit: (entry: IISASolutionStep) => Promise<IISASolutionStep>;
}

export default function ISASolutionStepsForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISASolutionStep> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISASolutionStep,
  };

  const solutionProps: IFormEntitySelectProps = {
    label: 'Solution',
    name: 'solution',
    entityService: ISASolutionsService,
    current: entry.solution,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="title" label="Solution Step Title" required />
      <FormInput name="description" label="Description" required />
      <FormInput name="sequenceNumber" label="Sequence Number" type="number" required />
      <FormEntitySelect {...solutionProps} />
      <FormSubmit />
    </FormikForm>
  );
}
