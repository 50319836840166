import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IShopProduct } from './shop-service.types';

class ProductsServiceClass extends EntityService<any> {
  getDisplayName(entry: IShopProduct): string {
    return entry && entry.name;
  }
}

const ProductsService = new ProductsServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/products',
  createLink: links.shopCreateProduct,
  detailLink: links.shopProduct,
});

export default ProductsService;
