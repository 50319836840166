import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { IBillingRFIDCardCarOwnerRelationship } from 'services/billing/billing-service.types';

import { IBillingCarOwner, IBillingRFIDCard } from './billing-v2-service.types';

class BillingRfidCardCarOwnerServiceClass extends EntityService<IBillingRFIDCardCarOwnerRelationship> {
  attachCardToCarOwner(card: IBillingRFIDCard, carOwner: IBillingCarOwner, params: any) {
    /**
     * POST /api/v1/admin/car-owner-rfid-cards&
     */
    // {
    //   "validFrom": "string",
    //   "validTo": "string",
    //   "carOwner": {
    //     "id": "string"
    //   },
    //   "rfidCard": {
    //     "id": "string"
    //   }
    // }
    params.rfidCard = { id: card.id };
    params.carOwner = { id: carOwner.id };

    return this.post('admin/car-owner-rfid-cards', params);
  }

  getDisplayName(entry: IBillingRFIDCardCarOwnerRelationship): string {
    return `${entry && entry.rfidCard && entry.rfidCard.evcoId}-relationship`;
  }
}

const BillingRfidCardCarOwnerService = new BillingRfidCardCarOwnerServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/car-owner-rfid-cards',
});

// this way I can autowire the result (with command + .);
export default BillingRfidCardCarOwnerService;
