// enums
export enum ShopProductType {
  Product = 'product',
  Service = 'service',
}

export enum ShopPaymentMethods {
  Sepa = 'sepa',
  PayPal = 'paypal',
  Invoice = 'invoice',
}

export enum ShopUserType {
  Private = 'private',
  Company = 'company',
}

export enum ELandingPageContentBlockType {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}
export enum ELandingPageContentBlockPartType {
  Image = 'image',
  Text = 'text',
  Form = 'form',
}

export enum ShopStoreEmailKeys {
  BusinessSales = 'businessSales',
  ReceiveOrders = 'receiveOrders',
}

export enum ShopIndustryOptions {
  Manufacturing = 'manufacturing',
  It = 'it',
  Legal = 'legal',
  Banking = 'banking',
  Other = 'other',
}

export enum ProductAttributeOptionType {
  Model = 'model',
  Option = 'option',
  Service = 'service',
}

export enum ShopOrderStatus {
  InProgress = 'inprogress',
  Pending = 'pending',
  Review = 'review',
  Paid = 'paid',
  Fulfilled = 'fulfilled',
  Cancelled = 'cancelled',
}

export enum PlugType {
  Typ1 = 'Typ1',
  Typ2 = 'Typ2',
  CCS = 'CCS',
  CHAdeMO = 'CHAdeMO',
  Schuko = 'Schuko',
  Supercharger = 'Supercharger',
}

// interfaces

interface IBaseEntity {
  id?: string;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly deleteDate?: Date;
}

export interface ICommonParams {
  id: string;
}

export interface IShopStore extends IBaseEntity {
  name: string;
  publicUrl: string;
  registrationId: string;
  categories?: IShopCategory[];
  address?: IShopAddress;
  paymentMethods?: string[];
}

export interface IStoreCustomization {
  scheme: IStoreCustomizationScheme;
}

export interface IStoreCustomizationScheme {
  [key: string]: IStoreCustomizationOption;
}

export interface IStoreCustomizationOption {
  primaryValue?: string;
  inverseValue?: string;
  value?: string | number;
  title: string;
  type: string;
}

export interface IShopAddress extends IBaseEntity {
  country?: string;
  region?: string;
  city?: string;
  address?: string;
  postalCode?: string;
  additionalInformation?: string;
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  isPrimary?: boolean;
}

export interface IShopCategory extends IBaseEntity {
  name: string;
  store?: IShopStore;
  parent?: IShopCategory;
  productAttribute: IShopProductAttribute;
  products?: IShopProduct[];
}

export interface IShopProduct extends IBaseEntity {
  name: string;
  price: number;
  vat: number;
  type: ShopProductType;
  category: IShopCategory;
  description?: string;
  imageUrls?: string[];
  stockQuantity?: number;
  productAttributes?: IShopProductAttribute[];
}

export interface IShopProductAttributeOption extends IBaseEntity {
  description?: string;
  information?: string;
  name: string;
  order: number;
  price?: number;
  priceComment?: string;
  stockQuantity?: number;
  vat?: number;
  productAttribute: IShopProductAttribute;
  type: ProductAttributeOptionType;
  chargePoints?: number;
  kwAC?: number;
  plugType?: PlugType;
  phases?: number;
  accessSecurity?: string;
  subsidyReady?: boolean;
  communicationModule?: string;
  communicationProtocol?: string;
  metering?: string;
  securityComponents?: boolean;
}

export interface IShopProductAttribute extends IBaseEntity {
  title: string;
  subtitle: string;
  index?: number;
  required?: boolean;
  product?: IShopProduct;
  productAttributeOptions?: IShopProductAttributeOption[];
}

export interface IShopCarModel extends IBaseEntity {
  make: string;
  model: string;
  plugTypes?: PlugType[];
  kwAC?: number;
  kwDC?: number;
  batteryCapacity?: string;
}

export interface IShopUser extends IBaseEntity {
  type: ShopUserType;
  readonly email: string;
  firstName?: string;
  phone?: string;
  lastName?: string;
  companyName?: string;
  website?: string;
  industry?: ShopIndustryOptions;
  billingAddress?: IShopAddress;
  shippingAddress?: IShopAddress;
}

export interface IShopPaymentMethod {
  type: string;
  name: string;
}

export interface IShopOrder extends IBaseEntity {
  total: number;
  status: ShopOrderStatus;
  orderPayment: IShopPayment;
  displayId: string;
  billingAddress?: IShopAddress;
  shippingAddress?: IShopAddress;
  installationNeeded?: boolean;
  installationApproved?: boolean;
  orderItems?: IShopOrderItem[];
}
export interface IShopPayment extends IBaseEntity {
  type: ShopPaymentMethods;
}

export interface IShopOrderItem extends IBaseEntity {
  quantity: number;
  price: number;
  vat?: number;
  discount?: number;
  shippingPrice?: number;
  productAttributeOption: IShopProductAttributeOption;
  order: IShopOrder;
  name?: string;
  productName?: string;
  total?: number;
}

export interface ISaveSchemaPayload {
  id: string;
  scheme: IStoreCustomization;
}

export interface IShopStoreEmail extends IBaseEntity {
  key: ShopStoreEmailKeys;
  email: string;
  store?: IShopStore;
}

export interface IShopLanding extends IBaseEntity {
  key: string;
  scheme: ICurrentPageScheme;
  store: IShopStore;
}

export interface ICurrentPageScheme {
  title: string | null;
  headerImage: string;
  blocks: ILandingPageContentBlock[] | null;
}

export interface ILandingPageContentBlock {
  type: ELandingPageContentBlockType;
  style?: IStyle | null;
  content?: (
    | ILandingPageContentBlockTextPart
    | ILandingPageContentBlockImagePart
    | ILandingPageContentBlockFormPart
    | ILandingPageContentBlock
  )[];
  shopUrl?: boolean | null;
}

export interface IStyle {
  backgroundColor?: string;
  fontWeight?:
    | 'bold'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'normal'
    | 'bolder'
    | 'lighter'
    | (number & {});
  position?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'fixed'
    | '-webkit-sticky'
    | 'absolute'
    | 'relative'
    | 'static'
    | 'sticky';
  fontSize?: string;
  margin?: string;
  padding?: string;
  borderRadius?: string;
}

export interface ILandingPageContentBlockTextPart {
  title?: string;
  subTitle?: string;
  text: string;
  type: ELandingPageContentBlockPartType.Text;
  style?: IStyle | null;
  underlined?: boolean | null;
  img?: ILandingPageContentBlockImagePart | null;
}

export interface ILandingPageContentBlockPartText {
  text: string;
  style: IStyle | null;
}

export interface ILandingPageContentBlockImagePart {
  title?: string | ILandingPageContentBlockPartText;
  url: string;
  type: ELandingPageContentBlockPartType.Image;
  style?: IStyle | null;
  underlined?: boolean | null;
}

export interface ILandingPageContentBlockFormPart {
  type: ELandingPageContentBlockPartType.Form;
  form: string;
}
