import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingImport } from './billing-v2-service.types';

class BillImportsServiceClass extends EntityService<IBillingImport> {}

const BillImportsService = new BillImportsServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/imports',
  createLink: links.billCreateImport,
  detailLink: links.billImport,
});

export default BillImportsService;
