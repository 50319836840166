import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton, Switch } from 'formik-antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import {
  ITendergyClientRequestPool,
  ITendergyQuestionnaireConfig,
  QuestionKey,
} from 'store/tendegyService/tendergyService.types';

interface Props {
  entry: ITendergyQuestionnaireConfig;
  onSubmitStep: (entry: ITendergyQuestionnaireConfig) => void;
}

const QuestionnaireConfigBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const poolId = new URLSearchParams(useLocation().search).get('poolId');

  return (
    <div>
      <Formik
        initialValues={entry as ITendergyQuestionnaireConfig}
        onSubmit={(values) => onSubmitStep({ ...values, pool: { id: poolId } as any })}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="pool" label="Pool">
              <EntitySelector
                entityService={ClientRequestPoolService}
                name="pool"
                current={poolId ? poolId : entry.pool}
                onSelect={(pool: ITendergyClientRequestPool) => {
                  setFieldValue('pool', pool);
                  setFieldValue('poolId', pool.id);
                }}
                defaultPayload={{
                  relations: ['intermediateOrganization'],
                }}
              />
            </Form.Item>

            <Form.Item name="questionKey" label="Question Key" required>
              <Select name="questionKey">
                {Object.entries(QuestionKey).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="defaultAnswer" label="Default Answer(JSON)">
              <Input name="defaultAnswer" />
            </Form.Item>

            <Form.Item name="possibleAnswers" label="Possible Answers(JSON)">
              <Input name="possibleAnswers" />
            </Form.Item>

            <Form.Item name="isDisabled" label="Disabled">
              <Switch name="isDisabled" />
            </Form.Item>

            <Form.Item name="isVisible" label="Visible">
              <Switch name="isVisible" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionnaireConfigBaseStep;
