import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillImportsService from 'services/billing-v2/billing-v2-imports.service';
import { IBillingImport, IParams } from 'services/billing-v2/billing-v2-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ImportsDetailsTab from './tabs/ImportsDetailsTab';

const ImportsDetailsPage: React.FC = () => {
  const { id } = useParams<IParams>();

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IBillingImport) => <ImportsDetailsTab document={entity} />,
    },
  ];
  return (
    <CrudDetailContainer
      entityName="Billing Import"
      tabs={tabs}
      baseLink={links.billingImport}
      defaultPayload={params}
      hideJsonTab
      resource={BillImportsService}
    />
  );
};

export default ImportsDetailsPage;
