import React from 'react';
import { AppstoreOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import tendergy from 'assets/images/tendergy.png';
import DashboardTableContainer from 'components/containers/DashboardTableContainer/DashboardTableContainer';
import links from 'framework/links';
import TendergyInstallerService from 'services/tendergy/tendergy-installer.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import {
  ApprovalState,
  IPoolTenderOrganization,
  ITendergyUser,
} from 'store/tendegyService/tendergyService.types';

import ProductTable from './ProductTable';
import VendorTable from './VendorTable';

import styles from './DashboardPage.module.sass';

const columnsUsers = [
  {
    title: 'Email',
    dataIndex: 'user',
    key: 'user',
    render: (val: ITendergyUser) => (val?.email ? val.email : ''),
    width: 240,
    fixed: true,
  },
  {
    title: 'First name',
    dataIndex: 'user',
    key: 'user',
    render: (val: ITendergyUser) => (val?.firstName ? val.firstName : ''),
  },
  {
    title: 'Last name',
    dataIndex: 'user',
    key: 'user',
    render: (val: ITendergyUser) => (val?.lastName ? val.lastName : ''),
  },
];
const columnsOrganizations = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (val: string) => val || '',
    width: 340,
    fixed: true,
  },
  {
    title: 'Pool',
    dataIndex: 'poolTenderOrganizations',
    key: 'poolTenderOrganizations',
    render: (val: IPoolTenderOrganization[]) =>
      val.length ? val.map((item) => item.clientRequestPool?.name) : '-',
  },
];

const organizationsPayload: IGetMultiEntityPayload = {
  state: ApprovalState.Pending,
};

const installersPayload: any = {
  state: ApprovalState.Pending,
  sortFields: 'user.createdAt',
  sortDirections: -1,
};

const DashboardPage: React.FC = () => (
  <div className={styles.dashboardContainer}>
    <div className={styles.logoWrapper}>
      <img src={tendergy} className={styles.logo} alt="tendergy logo" />
    </div>
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <DashboardTableContainer
          columns={columnsUsers}
          resource={TendergyInstallerService}
          basicUrl={links.users}
          defaultPayload={installersPayload}
          title="Unapproved installers"
          icon={<UserOutlined className={styles.icon} />}
        />
      </Col>
      <Col span={12}>
        <DashboardTableContainer
          columns={columnsOrganizations}
          resource={TendergyOrganizationService}
          title="Unapproved organizations"
          defaultPayload={organizationsPayload}
          basicUrl={links.organizations}
          icon={<AppstoreOutlined className={styles.icon} />}
        />
      </Col>
      <Col span={12}>
        <ProductTable />
      </Col>
      <Col span={12}>
        <VendorTable />
      </Col>
    </Row>
  </div>
);

export default DashboardPage;
