import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillRFIDCardsService from 'services/billing-v2/billing-v2-rfid-cards.service';
import { IBillingRFIDCard } from 'services/billing-v2/billing-v2-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

const columns: ColumnsType<IBillingRFIDCard> = [
  {
    title: 'Identifier',
    dataIndex: 'identifier',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'EVCO Id',
    dataIndex: 'evcoId',
    render: (id) => (id ? id : '-'),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Username',
    dataIndex: 'username',
    render: (username) => (username ? username : '-'),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Full name',
    dataIndex: 'fullname',
    render: (fullname) => (fullname ? fullname : '-'),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const params: IGetMultiEntityPayload = {};

const RfidCardsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="RFID Cards"
    columns={columns}
    defaultPayload={params}
    resource={BillRFIDCardsService}
  />
);
export default RfidCardsOverviewPage;
