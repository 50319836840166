import config from 'config';

import EntityService from 'services/abstract/entity.service';

import { IShopPaymentMethod } from './shop-service.types';

class PaymentMethodsServiceClass extends EntityService<IShopPaymentMethod> {
  getDisplayName(entry: IShopPaymentMethod): string {
    return entry && entry.name;
  }
}

const PaymentMethodsService = new PaymentMethodsServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/payment-methods',
});

export default PaymentMethodsService;
