import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import BillRFIDCardsService from 'services/billing-v2/billing-v2-rfid-cards.service';

import RfidCardForm from './components/RfidCardForm/RfidCardForm';

export default function RfidCardCreatePage() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add RFID Card',
          render: (entry, onSubmit) => <RfidCardForm onSubmit={onSubmit} entry={entry} />,
        },
      ]}
      entityName="RFID card"
      baseLink={links.billRfidCards}
      resource={BillRFIDCardsService}
    />
  );
}
