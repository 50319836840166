import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISAProblemCategoriesService } from 'services/isa-service';
import { IISAProblemCategory, IParams } from 'services/isa-service/isa-service.types';

import ISAProblemCategoriesForm from './components/ISAProblemCategoriesForm';
import ISAProblemsTab from './components/ISAProblemsTab';

export default function ISAProblemCategoriesDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => (
            <ISAProblemCategoriesForm entry={entry} onSubmit={onSave} />
          ),
        },
        {
          name: 'problems',
          displayName: 'Problems',
          render: (entry: IISAProblemCategory) => <ISAProblemsTab problems={entry.problems} />,
        },
      ]}
      entityName="problem category"
      baseLink={links.isaProblemCategoriesDetails}
      defaultPayload={{ id, relations: ['problems'] }}
      resource={ISAProblemCategoriesService}
      hideJsonTab
    />
  );
}
