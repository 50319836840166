import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import {
  IPoolTenderOrganization,
  ITendergyClientRequestPool,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';

import { ClientRequestPoolValidation } from '../../client-request-pool.validation';

interface IClientRequestPoolStep {
  clientRequestPool: ITendergyClientRequestPool;
  onSubmit?: Function;
  saveNewPool?: Function;
}

const ClientRequestPoolStep: React.FC<IClientRequestPoolStep> = ({
  clientRequestPool,
  onSubmit,
  saveNewPool,
}: IClientRequestPoolStep) => {
  const validation = Yup.object().shape(ClientRequestPoolValidation);

  const onSavePool = (values: any, setSubmitting: Function) => {
    const formattedValues = { ...values };
    formattedValues.poolTenderOrganizations = [];
    values &&
      values.poolTenderOrganizations &&
      values.poolTenderOrganizations.forEach((organization: IPoolTenderOrganization) => {
        formattedValues &&
          formattedValues.poolTenderOrganizations &&
          formattedValues.poolTenderOrganizations.push({ organization });
      });
    saveNewPool && saveNewPool(formattedValues);
    setSubmitting(false);
  };

  if (!onSubmit) {
    return <div>onSubmit can not be undefined</div>;
  }

  if (!clientRequestPool) {
    return <div>Loading</div>;
  }

  return (
    <Formik
      initialValues={clientRequestPool}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        onSavePool(values, setSubmitting);
        onSubmit();
      }}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
          <Form.Item name="name" label="Pool Name">
            <Input name="name" />
          </Form.Item>

          <Form.Item name="intermediateOrganizationId" label="Intermediate Organization">
            <EntitySelector
              current={values.intermediateOrganizationId}
              onSelect={(tenderOrganization: ITendergyOrganization) => {
                setFieldValue('intermediateOrganizationId', tenderOrganization);
              }}
              entityService={TendergyOrganizationService}
              name="intermediateOrganizationId"
            />
          </Form.Item>

          <Form.Item name="poolTenderOrganizations" label="Pool Tender Organizations">
            <EntitySelector
              current={values.poolTenderOrganizations}
              onSelect={(tenderOrganizations: ITendergyOrganization) => {
                setFieldValue('poolTenderOrganizations', tenderOrganizations);
              }}
              entityService={TendergyOrganizationService}
              mode="multiple"
              name="poolTenderOrganizations"
            />
          </Form.Item>

          <Form.Item name="users" label="Users">
            <EntitySelector
              current={values.users}
              onSelect={(users) => {
                setFieldValue('users', users);
              }}
              entityService={TendergyUserService}
              mode="multiple"
              name="users"
            />
          </Form.Item>

          <Form.Item name="isDefault" label="Default">
            <Switch name="isDefault" />
          </Form.Item>
          <Form.Item name="requireRequestConfirmation" label="Require Request Confirmation">
            <Switch name="requireRequestConfirmation" />
          </Form.Item>
          <Form.Item name="costEstimateMin" label="CostEstimateMin">
            <Input name="costEstimateMin" type="number" />
          </Form.Item>
          <Form.Item name="costEstimateMax" label="Cost Estimate Max">
            <Input name="costEstimateMax" type="number" />
          </Form.Item>

          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton shape="round" icon={<ArrowRightOutlined />} disabled={isSubmitting}>
              Create Pool
            </SubmitButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default ClientRequestPoolStep;
