import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IShopStore } from 'services/shop/shop-service.types';
import StoreService from 'services/shop/shop-stores.service';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IShopStore> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Url',
    dataIndex: 'publicUrl',
    key: 'publicUrl',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const StoresOverviewPage: React.FC = () => (
  <CrudListViewContainer entityName="Stores" columns={columns} resource={StoreService} />
);
export default StoresOverviewPage;
