import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons/lib';
import { Alert, Button, message, Popconfirm, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import config from 'config';

import links from 'framework/links';
import { ApprovalState, ITendergyOrganization } from 'store/tendegyService/tendergyService.types';

import styles from './OrganizationActionsComponent.module.sass';

interface Props {
  entry?: ITendergyOrganization;
  onDelete: () => void;
  rejectOrganization: (rejectReason: string) => void;
  confirmOrganization: () => void;
}

const OrganizationActionsComponent: React.FC<Props> = (props: Props) => {
  const { entry, onDelete, rejectOrganization, confirmOrganization } = props;
  const history = useHistory();

  const [rejectReason, setRejectReason] = useState<string>('');

  if (!entry) {
    return <div>Entry is not set</div>;
  }

  const getLink = (token: string) => `${config.TENDERGY_UI_URL}registration/${token}`;

  return (
    <div className={styles.containerOuter}>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push(`${links.activityLogs}?scope=organizations&affectedEntry=${entry.id}`)
          }
        >
          Go to Activity Logs
        </Button>
      </div>
      <div className={styles.containerInner}>
        <Popconfirm
          title="Are you sure you want to delete this entry?"
          onConfirm={() => onDelete()}
        >
          <Button
            type="primary"
            className={styles.button}
            shape="round"
            size="large"
            danger
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        </Popconfirm>
      </div>

      <div className={styles.blockWrapper}>
        <div className={styles.containerInner}>
          <div className={styles.containerInner}>
            <div>
              <h3>Tendergy Actions</h3>
            </div>
            {entry.inviteTokens &&
              entry.inviteTokens.map((link, index) => (
                <React.Fragment key={`invite-token-${index}`}>
                  <div className={styles.containerInner}>
                    <Alert
                      className={styles.linkWrapper}
                      message={getLink(link.token)}
                      type="info"
                    />
                  </div>
                  <CopyToClipboard text={getLink(link.token)}>
                    <Button
                      className={styles.button}
                      type="primary"
                      size="large"
                      shape="round"
                      onClick={() => message.info('Invitation Link Copied')}
                    >
                      Copy Link
                    </Button>
                  </CopyToClipboard>
                </React.Fragment>
              ))}
          </div>

          {/* If organization is approved */}
          {entry?.state === ApprovalState.Approved && (
            <Button type="primary" className={styles.button} shape="round" size="large" disabled>
              Approved
            </Button>
          )}

          {/* If organization is rejected */}
          {entry?.state === ApprovalState.Rejected && (
            <Button type="primary" className={styles.button} shape="round" size="large" disabled>
              Rejected
            </Button>
          )}

          {/* If organization is NOT approved NOR rejected show actions */}
          {entry?.state === ApprovalState.Pending && (
            <>
              <Button
                type="primary"
                className={styles.button}
                shape="round"
                size="large"
                onClick={() => confirmOrganization()}
              >
                Approve Organization
              </Button>

              <TextArea
                rows={5}
                placeholder="Reject Reason"
                onChange={(e) => setRejectReason(e.target.value)}
                value={rejectReason}
              />
              <Tooltip title={!rejectReason && 'Please enter reason to reject installer'}>
                <Button
                  className={styles.rejectButton}
                  type="primary"
                  danger
                  size="large"
                  shape="round"
                  disabled={!rejectReason}
                  onClick={() => rejectOrganization(rejectReason)}
                >
                  Reject Organization
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationActionsComponent;
