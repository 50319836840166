import * as Yup from 'yup';

/*
export interface ICraftInvitationLink {
    token: string;
    expires: string;
    type: string;
    organization: ICraftOrganization | string;
    organizationId?: string;
    id?: string;
}
*/

const today = new Date();
today.setHours(0, 0, 0, 0);

export const InvitationLinkValidation = {
  expires: Yup.date()
    .nullable()
    .typeError('Date must be valid')
    .min(today, 'Date cannot be in the past'),
  type: Yup.string().required('Required'),
  isEnabled: Yup.boolean().nullable(),
};
