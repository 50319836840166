import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import InvitationLinkService from 'services/tendergy/tendergy-invitation-links.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import {
  ITendergyClientRequestPool,
  ITendergyInvitationLink,
} from 'store/tendegyService/tendergyService.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import ClientRequestPoolStep from './steps/ClientRequestPoolStep';
import UserInvitationLinkStep from './steps/UserInvitationLinkStep';

const CreateClientRequestPoolPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params
  const [savedPool, setSavedPool] = useState<ITendergyClientRequestPool | null>(null);

  const params: IGetSingleEntityPayload = {
    id,
  };

  const saveNewPool = async (entry: ITendergyClientRequestPool) => {
    try {
      const pool = await ClientRequestPoolService.save(entry);
      setSavedPool(pool);
    } catch (e) {
      showErrorNotification(e);
      return;
    }
    showSuccessNotification('Client Request Pool was created successfully');
  };

  const saveInviteLink = async (entry: ITendergyInvitationLink) => {
    try {
      await InvitationLinkService.save(entry);
    } catch (e) {
      showErrorNotification(e);
      return;
    }
    showSuccessNotification('Invitation Link was created successfully');
  };

  const steps: ICrudStep[] = [
    {
      name: 'Create a new pool',
      render: (entry, onSubmitStep) => (
        <ClientRequestPoolStep
          clientRequestPool={entry}
          onSubmit={onSubmitStep}
          saveNewPool={saveNewPool}
        />
      ),
    },
    {
      name: 'Create Invitation Link',
      render: (entry, onSubmitStep) => (
        <UserInvitationLinkStep
          entry={savedPool}
          onSubmitStep={onSubmitStep}
          saveInviteLink={saveInviteLink}
        />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="client request pool"
      steps={steps}
      baseLink={links.clientRequestPools}
      defaultPayload={params}
      resource={ClientRequestPoolService}
    />
  );
};

export default CreateClientRequestPoolPage;
