import * as React from 'react';
import { Row } from 'antd';
import * as Yup from 'yup';

import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { IBillingEnergyPrice, IOpt } from 'services/billing-v3/billing-service.types';
import { IBillingV3GetDocuments } from 'services/billing-v3/billing-v3-energy-prices.service';
import { showErrorNotification } from 'utils/notificationUtil';

import { EnergyPriceValidation } from '../../energy-price.validation';
import EnergyPriceDetailForm from '../EnergyPriceDetailForm';

const disablityList = ['chargePerUnit', 'payoutPerUnit', 'validFrom', 'chargingPoint'];

const MapEnums = (Enum: any) =>
  Object.keys(Enum).reduce((acc: IOpt[], field) => {
    const value = Enum[field];
    return [...acc, { label: field, value }];
  }, []);

enum EUpdateEnergyPrice {
  Approved = 'approved',
  Declined = 'declined',
  Pending = 'pending',
}
const EnergyPriceDetailsTab: React.FC<IGenericEditTabProps<IBillingEnergyPrice>> = (
  props: IGenericEditTabProps<IBillingEnergyPrice>,
) => {
  const { entry, onSave } = props;
  const ref = React.useRef<HTMLAnchorElement | null>(null);
  const [url, setUrl] = React.useState<string>('');
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const getLeadData = async (id: string) => {
    try {
      const response: any = await IBillingV3GetDocuments.getById({
        id: id || '',
      });
      setUrl(response.link);
      ref.current?.click();
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const validation = Yup.object().shape(EnergyPriceValidation);
  const handleClickDownloadDocument = (id: string) => {
    getLeadData(id);
  };
  return (
    <div>
      <Row>
        <EnergyPriceDetailForm
          createEnergyPrice={entry}
          onSave={onSave}
          entry={entry}
          statusOptions={MapEnums(EUpdateEnergyPrice)}
          disabledList={disablityList}
          clickDocument={handleClickDownloadDocument}
          validation={validation}
        />

        <a href={url} className="hidden" rel="noopener noreferrer" ref={ref}>
          {' '}
        </a>
      </Row>
    </div>
  );
};

export default EnergyPriceDetailsTab;
