import React from 'react';
import { Button, Popconfirm } from 'antd';

import { IBillingInvoice } from 'services/billing-v2/billing-v2-service.types';

import styles from './InvoiceActionsComponent.module.sass';

interface Props {
  downloadZip: () => void;
  cancelInvoice: () => void;
  sendDocsByEmail: (onlyChanged: boolean) => void;
  entry: IBillingInvoice;
  cancelIsDone: boolean;
  deleteInvoice: (invoiceId: string) => void;
}

const InvoiceActionsComponent: React.FC<Props> = (props: Props) => {
  const { downloadZip, cancelInvoice, sendDocsByEmail, entry, cancelIsDone, deleteInvoice } = props;
  const shouldShowResendChangedPoc: boolean =
    entry.status === 'calculation_done' &&
    Number(entry.invoiceAttempts?.filter((invoice) => invoice.status === 'done')?.length) > 1;
  const isRemovable: boolean = [
    'calculating',
    'inprogress',
    'pending',
    'error',
    'canceled',
  ].includes(entry.status);

  return (
    <div className={styles.containerOuter}>
      {shouldShowResendChangedPoc && (
        <div className={styles.containerInner}>
          <Popconfirm
            title="Are you sure you want to resend changed POC by email?"
            onConfirm={() => sendDocsByEmail(true)}
          >
            <Button className={styles.button} type="primary" size="middle">
              Resend changed POC by email
            </Button>
          </Popconfirm>
        </div>
      )}
      <div className={styles.containerInner}>
        <Button className={styles.button} type="primary" size="middle" onClick={downloadZip}>
          Download all
        </Button>
      </div>
      {entry.status === 'calculation_done' && (
        <div className={styles.containerInner}>
          <Popconfirm
            title="Are you sure you want to send it by email?"
            onConfirm={() => sendDocsByEmail(false)}
          >
            <Button className={styles.button} type="primary" size="middle">
              Send by email
            </Button>
          </Popconfirm>
        </div>
      )}
      {!cancelIsDone && entry.status === 'done' && (
        <div className={styles.containerInner}>
          <Popconfirm
            title="Are you sure you want to cancel this invoice?"
            onConfirm={cancelInvoice}
          >
            <Button className={styles.button} type="primary" size="middle">
              Cancel invoice
            </Button>
          </Popconfirm>
        </div>
      )}
      {isRemovable && (
        <Popconfirm
          title="Are you sure you want to delete this entry?"
          onConfirm={() => deleteInvoice(entry.id)}
        >
          <Button type="primary" className={styles.button} size="middle" danger>
            Delete
          </Button>
        </Popconfirm>
      )}
    </div>
  );
};

export default InvoiceActionsComponent;
