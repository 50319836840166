import React from 'react';

import { FormEntitySelect, FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormEntitySelectProps } from 'components/forms/FormEntitySelect';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { ISASolutionStepsService } from 'services/isa-service';
import { IISASolution } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISASolution;
  onSubmit: (entry: IISASolution) => Promise<IISASolution>;
}

export default function ISASolutionsForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISASolution> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISASolution,
  };

  const solutionStepsProps: IFormEntitySelectProps = {
    label: 'Solution Steps',
    name: 'solutionSteps',
    entityService: ISASolutionStepsService,
    current: entry.solutionSteps,
    mode: 'multiple',
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="title" label="Solution title" required />
      <FormInput name="logicalName" label="Logical Name" required />
      <FormInput name="summary" label="Summary" required />
      <FormEntitySelect {...solutionStepsProps} />
      <FormSubmit />
    </FormikForm>
  );
}
