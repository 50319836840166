import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IRejectPayload } from 'store/storeInterfaces';

import { IProductVendor } from '../../store/tendegyService/tendergyService.types';

class ProductServiceClass extends EntityService<IProductVendor> {
  getDisplayName(entry: IProductVendor): string {
    return entry.name;
  }

  confirmById = (id: string) => this.post(`${this.config.entityURL}/${id}/approve`, {}, true);

  rejectById = (payload: IRejectPayload) =>
    this.post(
      `${this.config.entityURL}/${payload.id}/reject`,
      { rejectReason: payload.rejectReason },
      true,
    );
}

const VendorService = new ProductServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/product-vendors',
  createLink: links.createProductVendor,
  detailLink: links.productVendor,
});

export default VendorService;
