import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IB } from 'pages/billing-v3/charging-stations/constants';
import SurveysService from 'services/tendergy/tendergy-survey.service';
import { ITendergySurvey } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

import SurveysFilters from '../componentns/SurveysFilters';

const columns: IB<ITendergySurvey>[] = [
  {
    title: 'Id',
    dataIndex: 'displayId',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Pool',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'pool',
    sortParameter: 'pool.name',
    key: 'poolName',
    render: (pool: any) => pool?.name,
  },
  {
    title: 'Questionnaire',
    dataIndex: 'versionedQuestionnaireId',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    sortParameter: 'versionedQuestionnaireId.id',
    key: 'versionedQuestionnaireId',
    render: (questionnaire) => {
      return (
        <span>
          {questionnaire.id} ({questionnaire.version})
        </span>
      );
    },
  },
  {
    title: 'Step',
    dataIndex: 'step',
    key: 'step',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
    sorter: true,
    defaultSortOrder: 'descend' as SortOrder,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
  },
];

const SurveysOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Surveys"
    columns={columns}
    customFilters={SurveysFilters}
    resource={SurveysService}
  />
);
export default SurveysOverviewPage;
