import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import ProductAttributeOptionsService from 'services/shop/shop-product-options.service';
import { IShopProductAttributeOption } from 'services/shop/shop-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

const columns: ColumnsType<IShopProductAttributeOption> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
];

const params: IGetMultiEntityPayload = {};

const ProductAttributeOptionsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Product Attribute Options"
    columns={columns}
    defaultPayload={params}
    resource={ProductAttributeOptionsService}
  />
);
export default ProductAttributeOptionsOverviewPage;
