import config from '../../config';
import links from '../../framework/links';
import EntityService from '../abstract/entity.service';
import { IConsultantParameters } from './consultant-service.types';

class ConsultantCommercialFleetTypeClass extends EntityService<IConsultantParameters> {
  getDisplayName(entry: IConsultantParameters): string {
    return entry?.items?.[0].entity;
  }
}
export const ConsultantCommercialFleetTypeService = new ConsultantCommercialFleetTypeClass({
  apiURL: config.CONSULTANT_SERVICE_URL,
  entityURL: 'admin/commercial-fleet-types',
  detailLink: links.eConsultantCommercialFleetTypesDetail,
});
