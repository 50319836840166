import React, { useEffect, useState } from 'react';

import { FormSelect } from 'components/forms';
import PaymentMethodsService from 'services/shop/payment-methods.service';
import { IShopPaymentMethod } from 'services/shop/shop-service.types';

interface IPaymentMethodsSelectorProps {
  name: string;
  label?: string;
  required?: boolean;
}

const PaymentMethodsSelector: React.FC<IPaymentMethodsSelectorProps> = (props: any) => {
  const { name, label = '', required = false, ...rest } = props;

  const [responseResults, setResponseResults] = useState<string[]>([]);

  const getOptions = async () => {
    const entities = await PaymentMethodsService.getEntries();
    const results = entities?.items?.map((entry: IShopPaymentMethod) => entry.type);
    // @ts-ignore
    setResponseResults(results);
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <FormSelect
      name={name}
      label={label}
      required={required}
      options={responseResults}
      selectProps={{
        mode: 'multiple',
        placeholder: 'Select methods',
      }}
      {...rest}
    />
  );
};

export default PaymentMethodsSelector;
