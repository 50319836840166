import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillOrganizationService from 'services/billing-v2/billing-v2-organizations.service';
import { IBillingOrganization } from 'services/billing-v2/billing-v2-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IBillingOrganization> = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Customer Number',
    dataIndex: 'customerNumber',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (date: Date) => formatDefaultDate(date),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const params: IGetMultiEntityPayload = {};

const BillOrganizationsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Organizations"
    columns={columns}
    defaultPayload={params}
    resource={BillOrganizationService}
  />
);
export default BillOrganizationsOverviewPage;
