import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { ITendergyComment, ITendergyUser } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

interface IProps {
  comments?: ITendergyComment[];
}

const CommentsTab: React.FC<IProps> = (props: IProps) => {
  const { comments } = props;
  const history = useHistory();

  const columns = [
    {
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      title: 'Author',
      sortParameter: 'author.firstName',
      dataIndex: 'author',
      render: (author: ITendergyUser) =>
        author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : '-',
    },
    {
      title: 'Text',
      dataIndex: 'text',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      render: (date: string) => formatDefaultDate(date),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={comments}
      onRow={(record) => ({
        onClick: () => history.push(createLink(links.comment, { id: record.id })),
      })}
    />
  );
};

export default CommentsTab;
