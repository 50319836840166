import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Select } from 'antd';

export interface ILayoutFilters {
  query: null | string;
  language: null | string;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const LayoutFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<ILayoutFilters>({
    query: null,
    language: 'de',
  });

  useEffect(() => {
    onFilter && onFilter(value);
  }, [value]); // eslint-disable-line

  const queryInputProps = {
    placeholder: 'Search by name...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };

  const languageSelectProps = {
    placeholder: 'Select Type',
    onChange: (language: string) => {
      setValue({ ...value, language });
    },
    style: { width: '100%' },
    allowClear: true,
    options: [
      { value: 'de', label: 'German' },
      { value: 'en', label: 'English' },
      { value: 'fr', label: 'French' },
      { value: 'it', label: 'Italy' },
    ],
    value: value.language as string,
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col span={12}>
        <Input {...queryInputProps} />
      </Col>
      <Col span={12}>
        <Select {...languageSelectProps} />
      </Col>
    </Row>
  );
};

export default LayoutFilters;
