import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingCarOwner, IBillingGridwareCreds } from './billing-v2-service.types';

class BillCarOwnersServiceClass extends EntityService<IBillingCarOwner> {
  getDisplayName(entry: IBillingCarOwner): string {
    return `${entry.firstname} ${entry.lastname}`;
  }

  postToGridware = async (payload: IBillingGridwareCreds, id: string) => {
    const url = `${this.config.entityURL}/${id}/gridware`;
    await this.post(url, payload);
  };
}

const BillCarOwnersService = new BillCarOwnersServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/car-owners',
  detailLink: links.billCarOwner,
  createLink: links.billCreateCarOwner,
});

export default BillCarOwnersService;
