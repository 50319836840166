import React, {
  useEffect,
  //  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  // Button,
  Card,
  Col,
  Row,
  Select,
  Typography,
} from 'antd';

import {
  BillCGPList,
  BillCPList,
  BillGetOrganizationsId,
} from 'services/billing-v3/billing-v3-charging-stations.service';
import { showErrorNotification, showNotification } from 'utils/notificationUtil';

interface ICPG {
  id: string;
  name: string;
}
interface IChargingPointGroups {
  accessOrganizationChargingPointGroups: ICPG[];
}
interface IAttachedCPG {
  name: string;
  id: string;
}
interface IAttachedCP {
  evse: string;
  id: string;
}
const relations = ['accessOrganizationChargingPointGroups'];

const ChargingStationForm = () => {
  const params = useParams<any>();
  // const addedCPList = BillCPList;
  // addedCPList.config.entityURL = `${BillCGPList.config.entityURL}/${params.id}`;
  const [selectedCPG, setSelectedCPG] = useState<any>([]);
  const [selectedCP, setSelectedCP] = useState<any[]>(['068d1cde-6b41-40c4-a05c-344157cf489b']);
  const [selectedCPGForCP, setSelectedCPGForCP] = useState<string>('');
  const [allCpList, setAllCpList] = useState<IAttachedCP[]>([]);
  const [chargePointGroups, setChargePointGroups] = useState<IAttachedCPG[]>([]);
  const [attachedCPG, setAttachedCPG] = useState<IChargingPointGroups>({
    accessOrganizationChargingPointGroups: [],
  });
  const getAttachedCPG = async () => {
    try {
      const response = await BillGetOrganizationsId.getById({
        id: params.id,
        relations,
      });
      //@ts-ignore
      setAttachedCPG(response);
      const CPGArray = response.accessOrganizationChargingPointGroups?.map(
        ({ id, name }: { id: any; name: string }) => {
          return { value: id, name };
        },
      );
      setSelectedCPG(CPGArray);
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const getCGP = async () => {
    try {
      const response = await BillCGPList.getEntries();
      //@ts-ignore
      setChargePointGroups(response?.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const getCP = async () => {
    try {
      const response = await BillCPList.getEntries({ limit: 100 });

      setAllCpList(response?.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const attachCPGtoORG = async () => {
    try {
      await BillGetOrganizationsId.patch(`admin/organizations/${params.id}/charging-point-groups`, {
        chargingPointGroupIds: selectedCPG.map(
          ({ value }: { value: string; name: string }) => value,
        ),
      });
      showNotification({
        message: `updated successfully`,
        type: 'success',
      });
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const attachCPtoCPG = async () => {
    try {
      await BillGetOrganizationsId.post(
        `admin/charging-point-groups/${selectedCPGForCP}/attach/${selectedCP}`,
        {
          chargingPoints: selectedCPG,
        },
      );
      showNotification({
        message: `updated successfully`,
        type: 'success',
      });
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const getCPGPoints = async () => {
    try {
      const response = await BillCGPList.get(
        `admin/charging-point-groups/${selectedCPGForCP}?relations[]=chargingPoints`,
      );
      setSelectedCP(response.chargingPoints?.map(({ id }: { id: any }) => id));
    } catch (e) {
      showErrorNotification(e);
    }
  };
  useEffect(() => {
    getAttachedCPG();
    getCGP();
    getCP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCPGForCP) {
      getCPGPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCPGForCP]);

  const handleAttach = () => {
    attachCPGtoORG();
  };

  const { Text } = Typography;
  const { Option } = Select;
  return (
    <>
      {/* <AdvancedSelect
        name="Attached CPG"
        entityService={addedCPList}
        mode="multiple"
        current={selectedCP}
        onSelect={setSelectedCP}
      /> */}
      <Card title="Attach/Dettach CPG to Organization">
        <Row justify="space-between" align="middle">
          <Col lg={8}>
            <Text strong>Current Charge Point Groups: </Text>
          </Col>
          <Col lg={10}>
            <Select
              labelInValue
              value={selectedCPG}
              mode="multiple"
              style={{ width: '100%' }}
              onChange={(value) => setSelectedCPG(value)}
            >
              {chargePointGroups?.map(({ name, id }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col lg={3}>
            <Button
              type="primary"
              style={{ width: '100%', paddingLeft: '20px' }}
              onClick={() => handleAttach()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>

      <Card title="Assign/Unassign CP to CPG" style={{ marginTop: '20px' }}>
        <Row justify="space-between" align="middle">
          <Col lg={8}>
            <Text strong>Organization CPG List: </Text>
          </Col>
          <Col lg={14}>
            <Select
              value={selectedCPGForCP}
              style={{ width: '100%' }}
              onChange={(value) => setSelectedCPGForCP(value)}
            >
              {attachedCPG.accessOrganizationChargingPointGroups?.map(({ name, id }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        {selectedCPGForCP && (
          <Row justify="space-between" align="middle" style={{ marginTop: '20px' }}>
            <Col lg={8}>
              <Text strong>CP List: </Text>
            </Col>
            <Col lg={16}>
              <Select
                mode="multiple"
                value={selectedCP}
                style={{ width: '100%' }}
                onChange={(value) => setSelectedCP(value)}
              >
                {allCpList?.map(({ evse, id }) => (
                  <Option value={id} key={id}>
                    {evse}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col lg={4}>
              <Button
                type="primary"
                style={{ width: '100%', paddingLeft: '20px' }}
                onClick={() => attachCPtoCPG()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
};

export default ChargingStationForm;
