import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Select } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { ClientRequestState } from 'framework/constants';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import IntermediatesService from 'services/tendergy/tendergy-intermediates.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import {
  ITendergyClientRequestPool,
  ITendergyIntermediate,
  ITendergyUser,
} from 'store/tendegyService/tendergyService.types';

export interface IClientRequestsFilters {
  state: null | string;
  query: null | string;
  pools: null | string;
  intermediateOrganization: null | string;
  owner: null | string;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const ClientRequestsFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IClientRequestsFilters>({
    state: null,
    query: null,
    pools: null,
    intermediateOrganization: null,
    owner: null,
  });

  useEffect(() => {
    onFilter && onFilter(value);
  }, [value]); // eslint-disable-line

  const clientRequestStateProps = {
    placeholder: 'Select State',
    onChange: (state: string) => {
      setValue({ ...value, state });
    },
    style: { width: '100%' },
    allowClear: true,
    options: Object.entries(ClientRequestState).map(([key, value]) => ({ value, label: key })),
  };

  const queryInputProps = {
    placeholder: 'Search by id...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };

  const poolEntitySelectorProps = {
    name: 'pools',
    allowClear: true,
    value: value.pools,
    style: { width: '100%' },
    entityService: ClientRequestPoolService,
    onSelect: (pool: ITendergyClientRequestPool) => {
      setValue({ ...value, pools: pool?.id || null });
    },
  };

  const intermediateOrganizationEntitySelectorProps = {
    name: 'intermediateOrganization',
    allowClear: true,
    value: value.intermediateOrganization,
    style: { width: '100%' },
    entityService: IntermediatesService,
    onSelect: (intermediate: ITendergyIntermediate) => {
      setValue({ ...value, intermediateOrganization: intermediate?.id || null });
    },
  };

  const ownerEntitySelectorProps = {
    placeholder: 'Select Owner',
    name: 'owner',
    allowClear: true,
    value: value.owner,
    style: { width: '100%' },
    entityService: TendergyUserService,
    onSelect: (user: ITendergyUser) => {
      setValue({ ...value, owner: user?.id || null });
    },
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col flex="20%">
        <Select {...clientRequestStateProps} />
      </Col>
      <Col flex="20%">
        <Input {...queryInputProps} />
      </Col>
      <Col flex="20%">
        <EntitySelector {...poolEntitySelectorProps} />
      </Col>
      <Col flex="20%">
        <EntitySelector {...intermediateOrganizationEntitySelectorProps} />
      </Col>
      <Col flex="20%">
        <EntitySelector {...ownerEntitySelectorProps} />
      </Col>
    </Row>
  );
};

export default ClientRequestsFilters;
