import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import CategoryService from 'services/shop/shop-categories.service';
import { IShopCategory, IShopStore } from 'services/shop/shop-service.types';
import StoreService from 'services/shop/shop-stores.service';

import { CategoryValidation } from '../../category.validation';

interface Props {
  entry: IShopCategory;
  onSubmitStep: (entry: IShopCategory) => void;
}

const CategoryBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(CategoryValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IShopCategory}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, dirty }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Category name" required>
              <Input name="name" />
            </Form.Item>

            <Form.Item name="store" label="Store" required>
              <EntitySelector
                entityService={StoreService}
                name="store"
                onSelect={(store: IShopStore) => setFieldValue('store', store)}
              />
            </Form.Item>

            <Form.Item name="parent" label="Parent category">
              <EntitySelector
                entityService={CategoryService}
                name="parent"
                current={entry.parent}
                onSelect={(parent: IShopStore) => setFieldValue('parent', parent)}
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting || !isValid || !dirty}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CategoryBaseStep;
