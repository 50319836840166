import links from 'framework/links';
import CreateCarOwnerPage from 'pages/billing/billing-car-owners/create/createCarOwnerPage';
import BillingCarOwnerPage from 'pages/billing/billing-car-owners/details/BillingCarOwnerPage';
import BillingCarOwnersPage from 'pages/billing/billing-car-owners/overview/BillingCarOwnersPage';
import BillingCreateFlowPage from 'pages/billing/billing-create-flow/BillingCreateFlowPage';
import BillingDocumentPage from 'pages/billing/billing-documents/overview/BillingDocumentsPage';
import BillingCreateImportPage from 'pages/billing/billing-imports/create/billing-create-import-page';
import BillingImportPage from 'pages/billing/billing-imports/details/BillingImportPage';
import BillingImportsPage from 'pages/billing/billing-imports/overview/BillingImportsPage';
import BillingInvoicePage from 'pages/billing/billing-invoices/details/BillingInvoicePage';
import BillingInvoicesPages from 'pages/billing/billing-invoices/overview/BillingInvoicesPage';
import BillingOrganizationPage from 'pages/billing/billing-organizations/details/BillingOrganizationPage';
import BillingOrganizationsPage from 'pages/billing/billing-organizations/overview/BillingOrganizationsPage';
import ChargingStationPage from 'pages/billing/charging-stations/details/ChargingStationPage';
import ChargingStationsOverviewPage from 'pages/billing/charging-stations/overview/ChargingStationsOverviewPage';
import BillingEnergyPricePage from 'pages/billing/energy-prices/details/BillingEnergyPricePage';
import BillingEnergyPricesPage from 'pages/billing/energy-prices/overview/BillingEnergyPricesPage';
import RFIDCardPage from 'pages/billing/rfid-cards/details/RFIDCardPage';
import RFIDCardsPage from 'pages/billing/rfid-cards/overview/RFIDCardsPage';

export const BILLING_ROUTES = [
  { path: links.billingChargingStation, component: ChargingStationPage },
  { path: links.billingChargingStations, component: ChargingStationsOverviewPage },
  { path: links.billingOrganization, component: BillingOrganizationPage },
  { path: links.billingOrganizations, component: BillingOrganizationsPage },
  { path: links.billingRFIDCard, component: RFIDCardPage },
  { path: links.billingRFIDCards, component: RFIDCardsPage },
  { path: links.billingImport, component: BillingImportPage },
  { path: links.billingImports, component: BillingImportsPage },
  { path: links.billingCreateImport, component: BillingCreateImportPage },
  { path: links.billingInvoices, component: BillingInvoicesPages },
  { path: links.billingInvoice, component: BillingInvoicePage },
  { path: links.billingDocuments, component: BillingDocumentPage },
  { path: links.billingCarOwner, component: BillingCarOwnerPage },
  { path: links.billingCarOwners, component: BillingCarOwnersPage },
  { path: links.billingCreateCarOwners, component: CreateCarOwnerPage },
  { path: links.billingEnergyPrice, component: BillingEnergyPricePage },
  { path: links.billingEnergyPrices, component: BillingEnergyPricesPage },
  { path: links.billingCreateCarOwnerFlow, component: BillingCreateFlowPage },
];
