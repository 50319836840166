import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { ISaveSchemaPayload, IShopStore } from './shop-service.types';

class StoreServiceClass extends EntityService<any> {
  getDisplayName(entry: IShopStore): string {
    return entry && entry.name;
  }

  getSchema = (id: string) => this.get(`${this.config.entityURL}/${id}/theme`, {}, true);

  saveSchema = (payload: ISaveSchemaPayload) =>
    this.put(`${this.config.entityURL}/${payload.id}/theme`, payload.scheme, true);
}

const StoreService = new StoreServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/stores',
  createLink: links.shopCreateStore,
  detailLink: links.shopStore,
});

export default StoreService;
