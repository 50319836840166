import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { IShopUser, ShopUserType } from 'services/shop/shop-service.types';

import { ShopUserValidation } from '../../shop-user.validation';

import styles from './ShopUserTabsStyles.module.sass';

const ShopUserOverviewTab: React.FC<IGenericEditTabProps<IShopUser>> = (
  props: IGenericEditTabProps<IShopUser>,
) => {
  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }
  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(ShopUserValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IShopUser}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="email" label="Email">
              {entry.email}
            </Form.Item>
            <Form.Item name="type" label="User type" required>
              <Select name="type">
                <Select.Option value={ShopUserType.Private}>Private</Select.Option>
                <Select.Option value={ShopUserType.Company}>Company</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="phone" label="Phone">
              <Input name="phone" placeholder="" />
            </Form.Item>
            {values.type === ShopUserType.Private && (
              <>
                <Form.Item name="firstName" label="First Name">
                  <Input name="firstName" placeholder="" />
                </Form.Item>
                <Form.Item name="lastName" label="Last Name">
                  <Input name="lastName" placeholder="" />
                </Form.Item>
              </>
            )}
            {values.type === ShopUserType.Company && (
              <>
                <Form.Item name="companyName" label="Company Name">
                  <Input name="companyName" placeholder="" />
                </Form.Item>
                <Form.Item name="website" label="Website">
                  <Input name="website" placeholder="" />
                </Form.Item>
              </>
            )}
            <div className={styles.centered}>
              <h4> Billing Address</h4>
            </div>
            <Form.Item name="billingAddress.country" label="Country">
              <Input name="billingAddress.country" placeholder="" />
            </Form.Item>
            <Form.Item name="billingAddress.region" label="Region">
              <Input name="billingAddress.region" placeholder="" />
            </Form.Item>
            <Form.Item name="billingAddress.city" label="City">
              <Input name="billingAddress.city" placeholder="" />
            </Form.Item>
            <Form.Item name="billingAddress.address" label="Address">
              <Input name="billingAddress.address" placeholder="" />
            </Form.Item>
            <Form.Item name="billingAddress.postalCode" label="Postal Code">
              <Input name="billingAddress.postalCode" placeholder="" />
            </Form.Item>
            <div className={styles.centered}>
              <h4>Additional Information for Billing Address </h4>
            </div>
            <Form.Item name="billingAddress.additionalInformation" label="Additional Information">
              <Input name="billingAddress.additionalInformation" placeholder="" />
            </Form.Item>
            <Form.Item name="billingAddress.phone" label="Phone">
              <Input name="billingAddress.phone" placeholder="" />
            </Form.Item>
            <Form.Item name="billingAddress.email" label="Email">
              <Input name="billingAddress.email" placeholder="" />
            </Form.Item>
            <Form.Item name="billingAddress.firstName" label="First Name">
              <Input name="billingAddress.firstName" placeholder="" />
            </Form.Item>
            <Form.Item name="billingAddress.lastName" label="Last Name">
              <Input name="billingAddress.lastName" placeholder="" />
            </Form.Item>

            <div className={styles.centered}>
              <h4>Shipping Address</h4>
            </div>
            <Form.Item name="shippingAddress.country" label="Country">
              <Input name="shippingAddress.country" placeholder="" />
            </Form.Item>
            <Form.Item name="shippingAddress.region" label="Region">
              <Input name="shippingAddress.region" placeholder="" />
            </Form.Item>
            <Form.Item name="shippingAddress.city" label="City">
              <Input name="shippingAddress.city" placeholder="" />
            </Form.Item>
            <Form.Item name="shippingAddress.address" label="Address">
              <Input name="shippingAddress.address" placeholder="" />
            </Form.Item>
            <Form.Item name="shippingAddress.postalCode" label="Postal Code">
              <Input name="shippingAddress.postalCode" placeholder="" />
            </Form.Item>
            <div className={styles.centered}>
              <h4>Additional Information for Shipping Address</h4>
            </div>
            <Form.Item name="shippingAddress.additionalInformation" label="Additional Information">
              <Input name="shippingAddress.additionalInformation" placeholder="" />
            </Form.Item>
            <Form.Item name="shippingAddress.phone" label="Phone">
              <Input name="shippingAddress.phone" placeholder="" />
            </Form.Item>
            <Form.Item name="shippingAddress.email" label="Email">
              <Input name="shippingAddress.email" placeholder="" />
            </Form.Item>
            <Form.Item name="shippingAddress.firstName" label="First Name">
              <Input name="shippingAddress.firstName" placeholder="" />
            </Form.Item>
            <Form.Item name="shippingAddress.lastName" label="Last Name">
              <Input name="shippingAddress.lastName" placeholder="" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ShopUserOverviewTab;
