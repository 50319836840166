import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, Switch } from 'formik-antd';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import TendergyQuestionnaireService from 'services/tendergy/tendergy-questionnaire.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import {
  ITendergyClientRequestPool,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

import { ClientRequestPoolValidation } from '../../client-request-pool.validation';
import PoolTenderOrganizationsSelector from '../components/PoolEntitySelector/PoolEntitySelector';

interface IClientRequestPoolForm {
  clientRequestPool: ITendergyClientRequestPool;
  onSubmit?: Function;
}

const ClientRequestPoolDetailsTab: React.FC<IClientRequestPoolForm> = ({
  clientRequestPool,
  onSubmit,
}: IClientRequestPoolForm) => {
  const validation = Yup.object().shape(ClientRequestPoolValidation);

  const onSaveEdited = (values: any) => {
    const formattedValues = { ...values };

    if (!isEqual(values.poolTenderOrganizations, clientRequestPool.poolTenderOrganizations)) {
      formattedValues.poolTenderOrganizations = [];

      values &&
        values.poolTenderOrganizations &&
        values.poolTenderOrganizations.forEach((organization: ITendergyOrganization) => {
          const existedOrganization = clientRequestPool!.poolTenderOrganizations!.find(
            (org) => org.organization?.id === organization?.id,
          );
          formattedValues.poolTenderOrganizations.push(
            existedOrganization ? existedOrganization : { organization },
          );
        });
    }

    onSubmit && onSubmit(formattedValues);
  };

  if (!onSubmit) {
    return <div>onSubmit can not be undefined</div>;
  }

  if (!clientRequestPool) {
    return <div>Loading</div>;
  }

  return (
    <Formik initialValues={clientRequestPool} validationSchema={validation} onSubmit={onSaveEdited}>
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
          <Form.Item name="name" label="Pool Name">
            <Input name="name" />
          </Form.Item>

          <Form.Item name="poolTenderOrganizations" label="Pool Tender Organizations">
            <PoolTenderOrganizationsSelector
              name="poolTenderOrganizations"
              onSelect={(tenderOrganizations: ITendergyOrganization[]) => {
                setFieldValue('poolTenderOrganizations', tenderOrganizations);
              }}
              current={values.poolTenderOrganizations}
            />
          </Form.Item>

          <Form.Item name="users" label="Users">
            <EntitySelector
              current={values.users}
              onSelect={(users) => {
                setFieldValue('users', users);
              }}
              entityService={TendergyUserService}
              mode="multiple"
              name="users"
            />
          </Form.Item>

          <Form.Item name="questionnaire" label="Questionnaire">
            <EntitySelector
              current={values.questionnaire}
              onSelect={(questionnaire) => {
                questionnaire && setFieldValue('questionnaire', questionnaire);
              }}
              onClear={() => setFieldValue('questionnaire', null)}
              hasVersion={true}
              entityService={TendergyQuestionnaireService}
              name="questionnaire"
              allowClear
            />
          </Form.Item>
          <Form.Item name="surveyQuestionnaire" label="Survey Questionnaire">
            <EntitySelector
              current={values.surveyQuestionnaire}
              onSelect={(surveyQuestionnaire) => {
                surveyQuestionnaire && setFieldValue('surveyQuestionnaire', surveyQuestionnaire);
              }}
              onClear={() => setFieldValue('surveyQuestionnaire', null)}
              hasVersion={true}
              entityService={TendergyQuestionnaireService}
              name="surveyQuestionnaire"
              allowClear
            />
          </Form.Item>

          <Form.Item name="createdAt" label="Created At">
            {formatDefaultDate(clientRequestPool.createdAt)}
          </Form.Item>
          <Form.Item name="isDefault" label="Default">
            <Switch name="isDefault" />
          </Form.Item>
          <Form.Item name="requireRequestConfirmation" label="Require Request Confirmation">
            <Switch name="requireRequestConfirmation" />
          </Form.Item>
          <Form.Item name="costEstimateMin" label="CostEstimateMin">
            <Input name="costEstimateMin" placeholder="" type="number" />
          </Form.Item>
          <Form.Item name="costEstimateMax" label="Cost Estimate Max">
            <Input name="costEstimateMax" placeholder="" type="number" />
          </Form.Item>
          <Form.Item name="intermediateOffer" label="Intermediate Offer">
            {clientRequestPool?.intermediateOffer ? 'Yes' : 'No'}
          </Form.Item>
          <Form.Item name="intermediateHomeCheckOffer" label="Intermediate Home Check Offer">
            {clientRequestPool?.intermediateHomeCheckOffer ? 'Yes' : 'No'}
          </Form.Item>
          <Form.Item name="isPublicPool" label="Is Public Pool">
            {clientRequestPool?.isPublicPool ? 'Yes' : 'No'}
          </Form.Item>

          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton shape="round" icon={<ArrowRightOutlined />} disabled={isSubmitting}>
              Save
            </SubmitButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default ClientRequestPoolDetailsTab;
