import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import OrderService from 'services/shop/shop-orders.service';
import { IShopOrder, IShopStore } from 'services/shop/shop-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { CurrencyUtils } from 'utils/currencyUtils';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IShopOrder> = [
  {
    title: 'Store',
    dataIndex: 'store',
    render: (entry: IShopStore) => entry.name || '',
  },
  {
    title: 'Order No.',
    dataIndex: 'displayId',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    render: (value: number) => CurrencyUtils.defaultFormatter(value / 100),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const params: IGetMultiEntityPayload = {
  relations: ['store'],
};

const OrdersOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Orders"
    columns={columns}
    defaultPayload={params}
    resource={OrderService}
  />
);

export default OrdersOverviewPage;
