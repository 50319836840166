import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { ITendergyFeedback } from 'store/tendegyService/tendergyService.types';

class CraftFeedbackServiceClass extends EntityService<ITendergyFeedback> {}

const TendergyFeedbackService = new CraftFeedbackServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/feedback',
});

export default TendergyFeedbackService;
