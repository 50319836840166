import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISAProblemCategoriesService } from 'services/isa-service';

import ISAProblemCategoriesForm from './components/ISAProblemCategoriesForm';

export default function ISAProblemCategoriesCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Problem Category',
          render: (_entry, onSubmit) => <ISAProblemCategoriesForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="problem category"
      baseLink={links.isaProblemCategoriesOverview}
      resource={ISAProblemCategoriesService}
    />
  );
}
