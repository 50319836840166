import * as React from 'react';
import { Col, Divider, Radio, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IConsultantLeadDetail } from 'services/consulant/consultant-service.types';
import { formatDefaultDate } from 'utils/date.helper';

import { eConsultantLeadStatus } from '../../../framework/constants';
import links from '../../../framework/links';
import { validationSchema } from '../leads/validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
interface IOpt {
  label: string;
  value: string;
}
interface IFleetManager {
  firstName: string;
  lastName: string;
  phone: string;
  id: string;
  organization: string;
  email: string;
}
interface Props {
  entry?: IConsultantLeadDetail | null;
  onSave: (updatedValues: IConsultantLeadDetail) => Promise<any>;
  fleetManager?: IFleetManager;
}

// export enum CurrencyType {
//   EUR = 'EUR',
//   USD = 'USD',
//   CHF = 'CHF',
//   NOK = 'NOK',
// }
// export enum CurrencySign {
//   Euro = '€',
//   Dollar = '$',
//   SwitzerlandFranc = 'CHf',
//   NorwayKrone = 'kr',
// }
// export enum DistanceUnit {
//   Kilometers = 'km',
//   Miles = 'mi',
// }
//
// export enum CountryStatus {
//   Enabled = 'enabled',
//   Disabled = 'disabled',
//   Hidden = 'hidden',
// }
// export enum CountryLanguage {
//   German = 'German',
//   English = 'English',
//   French = 'French',
// }

const MapEnums = (Enum: any) =>
  Object.keys(Enum).reduce((acc: IOpt[], field) => {
    const value = Enum[field];
    return [...acc, { label: field.replace(/([A-Z])/g, ' $1').trim(), value }];
  }, []);

const LeadsDetailTab: React.FC<Props> = (props: Props) => {
  const { entry, onSave, fleetManager } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }
  if (!entry) {
    return <div> Loading </div>;
  }
  const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
  // @ts-ignore
  const digitalReportBaseURL: string = links[`eConsultant_${env}`];

  return (
    <div>
      {entry.data && (
        <Formik
          initialValues={entry as IConsultantLeadDetail}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const data = {
              message: values.data.message,
              sitesCount: values.data.sitesCount,
              status: values.data.status,
              id: values.data.id,
            };
            onSave((data as unknown) as IConsultantLeadDetail).then(() => setSubmitting(false));
          }}
        >
          {({ handleSubmit, isSubmitting, values, handleChange }) => {
            return (
              <>
                <Row>
                  <Col xs={24}>
                    <a
                      rel="noopener noreferrer"
                      href={`${digitalReportBaseURL}business-case/reports?leadId=${entry?.data.id}`}
                      target="_blank"
                    >
                      <strong> Link to digital report</strong>
                    </a>
                  </Col>
                  <Col xs={6}>Created At: {formatDefaultDate(entry.data?.createdAt)}</Col>
                  <Col xs={6}>Updated At: {formatDefaultDate(entry.data?.updatedAt)}</Col>

                  {fleetManager?.firstName && (
                    <Col xs={6}>Username : {fleetManager?.firstName}</Col>
                  )}
                  {fleetManager?.lastName && <Col xs={6}>Surname : {fleetManager?.lastName}</Col>}
                  {fleetManager?.email && <Col xs={6}>Email : {fleetManager?.email}</Col>}
                  {fleetManager?.organization && (
                    <Col xs={6}>Organization : {fleetManager?.organization}</Col>
                  )}
                  {fleetManager?.phone && <Col xs={6}>Phone Number : {fleetManager?.phone}</Col>}
                </Row>
                <Divider />
                <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
                  <Form.Item name="type" label="Type">
                    <Input disabled name="type" placeholder="" />
                  </Form.Item>
                  <Form.Item name="data.message" label="Message">
                    <Input name="data.message" placeholder="" />
                  </Form.Item>
                  <Form.Item name="data.sitesCount" label="Sites Count">
                    <Input name="data.sitesCount" placeholder="" />
                  </Form.Item>
                  <Form.Item name="data.status" label="Lead Status">
                    <Radio.Group
                      defaultValue={values?.data?.status}
                      name={`data.status`}
                      options={MapEnums(eConsultantLeadStatus)}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  {/*COUNTRY FORM WILL BE ADDED LATER.*/}
                  {/*<Typography.Title level={3}>Costs Data</Typography.Title>*/}
                  {/*<Row>*/}
                  {/*  <Col xs={6}>Created At:</Col>*/}
                  {/*  <Col xs={6}>{formatDefaultDate(entry.country.createdAt)}</Col>*/}
                  {/*</Row>*/}
                  {/*<Divider/>*/}
                  {/*<Form.Item name="country.code" label="Country Code">*/}
                  {/*  <Input disabled name="country.code" placeholder="" />*/}
                  {/*</Form.Item>*/}
                  {/*<Form.Item name='country.currency' label='Currency Sign'>*/}
                  {/*  <Radio.Group defaultValue={values.country.currency}*/}
                  {/*      name={`country.currency`}*/}
                  {/*      options={MapEnums(CurrencyType)}*/}
                  {/*               onChange={handleChange}*/}
                  {/*  />*/}
                  {/*</Form.Item>*/}
                  {/*<Form.Item name='country.currencySign' label='Currency Sign'>*/}
                  {/*  <Radio.Group defaultValue={values.country.currencySign}*/}
                  {/*      name={`country.currency`}*/}
                  {/*      options={MapEnums(CurrencySign)}*/}
                  {/*               onChange={handleChange}*/}
                  {/*  />*/}
                  {/*</Form.Item>*/}
                  {/*<Form.Item name='country.distanceUnit' label='Distance Unit'>*/}
                  {/*  <Radio.Group defaultValue={values.country.distanceUnit}*/}
                  {/*      name={`country.distanceUnit`}*/}
                  {/*      options={MapEnums(DistanceUnit)}*/}
                  {/*               onChange={handleChange}*/}

                  {/*  />*/}
                  {/*</Form.Item>*/}
                  {/*<Form.Item name='country.language' label='Language'>*/}
                  {/*  <Radio.Group defaultValue={values.country.language}*/}
                  {/*      name={`country.language`}*/}
                  {/*      options={MapEnums(CountryLanguage)}*/}
                  {/*               onChange={handleChange}*/}

                  {/*  />*/}
                  {/*</Form.Item>*/}
                  {/*<Form.Item name='country.status' label='Status'>*/}
                  {/*  <Radio.Group defaultValue={values.country.status}*/}
                  {/*      name={`country.status`}*/}
                  {/*      options={MapEnums(CountryStatus)}*/}
                  {/*               onChange={handleChange}*/}

                  {/*  />*/}
                  {/*</Form.Item>*/}
                  <Form.Item {...CrudFormDefaultActionParams} name="submit">
                    <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                      Submit
                    </SubmitButton>
                  </Form.Item>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default LeadsDetailTab;
