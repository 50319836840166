import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { UploadProps } from 'antd/es/upload';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import ImgCrop from 'antd-img-crop';

interface IUploaderModalProps {
  visible: boolean;
  onCancel: () => void;
  onUpload: (file: File | Blob) => void;
}

const UploaderModal = ({ visible, onCancel, onUpload }: IUploaderModalProps) => {
  const [fileList, setFileList] = useState<UploadFile<File>[]>([]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess();
    }, 0);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleCancel = () => {
    setFileList([]);
    onCancel();
  };

  const handleSave = () => {
    if (fileList.length) {
      setFileList([]);
      if (fileList[0].originFileObj) onUpload(fileList[0].originFileObj);
    }
  };

  return (
    <Modal visible={visible} title="Upload Cover Image" onCancel={handleCancel} onOk={handleSave}>
      <ImgCrop quality={1} aspect={16 / 9}>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          maxCount={1}
          customRequest={dummyRequest}
          accept=".jpeg,.png,.jpg"
        >
          Upload
        </Upload>
      </ImgCrop>
    </Modal>
  );
};

export default UploaderModal;
