import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons/lib';
import { Button, Tooltip } from 'antd';

import styles from './LandingComponentsStyles.module.sass';

interface IProps {
  tooltip?: string;
  onClick: () => void;
}

export default function DeleteButton({ onClick, tooltip = '' }: IProps) {
  return (
    <div className={styles.deleteBtn}>
      <Tooltip title={tooltip}>
        <Button
          size={'small'}
          danger
          type="text"
          shape="circle"
          icon={<CloseCircleOutlined />}
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
}
