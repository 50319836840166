import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillingInvoiceService from 'services/billing/billing-invoices.service';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<any> = [
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Month',
    dataIndex: 'month',
    render: (month: number) => moment().month(month).format('MMMM'),
  },
  {
    title: 'Year',
    dataIndex: 'year',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const BillingInvoicesPages: React.FC = () => {
  const params: any = {
    status: 'done,error,canceled',
  };
  return (
    <CrudListViewContainer
      additionalPayload={params}
      entityName="Billing Invoices"
      columns={columns}
      resource={BillingInvoiceService}
    />
  );
};
export default BillingInvoicesPages;
