import * as React from 'react';
import { Button, Typography } from 'antd';

import Box from 'components/layout/Box/Box';

import styles from './successStep.module.sass';

export interface IErrorStepProps {
  error: any;
  onDismiss?: () => void;
}

const ErrorStep: React.FC<IErrorStepProps> = (props: IErrorStepProps) => {
  const { Title, Paragraph } = Typography;
  const { error, onDismiss } = props;
  return (
    <Box>
      <div className={styles.successStep}>
        <div />
        <Title level={3}>Something went wrong!</Title>

        <Paragraph>Please contact the IT team and share the following error message</Paragraph>

        <Paragraph>
          <b>{error.message}</b>
        </Paragraph>
        <br />
        <br />
        <br />
        {onDismiss ? (
          <Button type="primary" onClick={() => onDismiss()}>
            {' '}
            Hide{' '}
          </Button>
        ) : (
          ''
        )}
      </div>
    </Box>
  );
};

export default ErrorStep;
