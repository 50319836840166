import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISAProblemsService } from 'services/isa-service';

import ISAProblemsForm from './components/ISAProblemsForm';

export default function ISAProblemsCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Problem',
          render: (_entry, onSubmit) => <ISAProblemsForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="problem"
      baseLink={links.isaProblemsOverview}
      resource={ISAProblemsService}
    />
  );
}
