import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergyLead } from 'store/tendegyService/tendergyService.types';

class LeadServiceClass extends EntityService<ITendergyLead> {}

const LeadService = new LeadServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/leads',
  detailLink: links.lead,
  createLink: links.createLead,
});

export default LeadService;
