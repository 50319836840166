import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { IApiClient } from 'store/authService/authService.types';
import { formatDefaultDate } from 'utils/date.helper';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const ApiClientsOverviewTab: React.FC<IGenericEditTabProps<IApiClient>> = (
  props: IGenericEditTabProps<IApiClient>,
) => {
  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  return (
    <div>
      <Formik
        initialValues={entry as IApiClient}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="clientName" label="Client Name" required>
              <Input name="clientName" />
            </Form.Item>
            <Form.Item name="clientEmail" label="Client Email" required>
              <Input name="clientEmail" type="email" />
            </Form.Item>
            <Form.Item name="clientDescription" label="Client Description">
              <Input.TextArea name="clientDescription" />
            </Form.Item>
            <Form.Item name="allowedIPRanges" label="Allowed IP Ranges">
              <Select name="allowedIPRanges" mode="tags" />
            </Form.Item>
            <Form.Item name="allowedIPs" label="Allowed IPs">
              <Select name="allowedIPs" mode="tags" />
            </Form.Item>
            <Form.Item name="passphrase" label="Passphrase">
              <Input name="passphrase" />
            </Form.Item>
            {entry?.apiKey && (
              <Form.Item name="apiKey" label="Api Key">
                {entry?.apiKey}
              </Form.Item>
            )}
            {entry?.apiKeySalt && (
              <Form.Item name="apiKeySalt" label="Api Key Salt">
                {entry?.apiKeySalt}
              </Form.Item>
            )}
            {entry?.publicKey && (
              <Form.Item name="publicKey" label="Public Key">
                {entry?.publicKey}
              </Form.Item>
            )}

            {entry?.createdAt ? (
              <Form.Item name="createdAt" label="Created At">
                {formatDefaultDate(entry?.createdAt)}
              </Form.Item>
            ) : null}

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ApiClientsOverviewTab;
