import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { ClientRequestState } from 'framework/constants';
import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IClientRequest } from 'store/tendegyService/tendergyService.types';

import styles from './styles.module.sass';

interface Props {
  entry: IClientRequest;
  acceptRequest: Function;
  rejectRequest: Function;
  rejectReason: string;
  setRejectReason: Function;
}

const ClientRequestActionsComponent: React.FC<Props> = (props: Props) => {
  const { entry, acceptRequest, rejectRequest, setRejectReason, rejectReason } = props;
  const history = useHistory();

  return (
    <>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          onClick={() =>
            history.push(`${links.activityLogs}?scope=client_requests&affectedEntry=${entry.id}`)
          }
        >
          Go to Activity Logs
        </Button>
      </div>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          onClick={() =>
            history.push(
              createLink(links.user, {
                id: entry && entry.ownerId,
              }),
            )
          }
        >
          Go to User
        </Button>
      </div>
      {entry?.poolId && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            onClick={() =>
              history.push(
                createLink(links.clientRequestPool, {
                  id: entry && entry.poolId,
                }),
              )
            }
          >
            Go to Pool
          </Button>
        </div>
      )}

      {entry.state === ClientRequestState.ApprovalPending && (
        <div className={`${styles.containerOuter} ${styles.containerReverse}`}>
          <div className={styles.blockWrapper}>
            <div className={styles.containerInner}>
              <TextArea
                rows={4}
                placeholder="Reject Reason"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              />
              <Row>
                <Col span={12}>
                  <Button
                    className={`${styles.button} ${styles.smallBtn}`}
                    type="primary"
                    size="large"
                    onClick={() => acceptRequest()}
                  >
                    Approve
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    className={`${styles.button} ${styles.smallBtn}`}
                    type="primary"
                    danger
                    size="large"
                    disabled={!rejectReason}
                    onClick={() => rejectRequest()}
                  >
                    Reject
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientRequestActionsComponent;
