import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import VendorService from 'services/tendergy/tendergy-vendor.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import Vendor1Step from './steps/Vendor1Step';

const VendorCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Create Vendor',
      description: 'required information',
      render: (entry, onSubmitStep) => <Vendor1Step entry={entry} onSubmitStep={onSubmitStep} />,
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Vendor"
      steps={steps}
      baseLink={links.productVendors}
      defaultPayload={params}
      resource={VendorService}
    />
  );
};

export default VendorCreatePage;
