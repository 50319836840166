import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import CategoryService from 'services/shop/shop-categories.service';
import { IShopCategory } from 'services/shop/shop-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import CategoryOverviewTab from './tabs/CategoryOverviewTab';
import CategoryProductsTab from './tabs/CategoryProductsTab';

const CategoryDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['store', 'parent', 'products'],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IShopCategory, optionalProps?: ICrudDetailViewCallbacks) => (
        <CategoryOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
    {
      name: 'Category Products',
      displayName: 'Category Products',
      render: (entity: IShopCategory) => <CategoryProductsTab products={entity.products} />,
    },
  ];

  return (
    <CrudDetailContainer
      entityName="Category"
      tabs={tabs}
      baseLink={links.shopCategory}
      defaultPayload={params}
      resource={CategoryService}
    />
  );
};

export default CategoryDetailsPage;
