import * as React from 'react';
import { Form, FormItemProps, Switch, SwitchProps } from 'formik-antd';

interface IFormInputProps extends Partial<FormItemProps & SwitchProps> {
  name: string;
  label?: string;
}

function FormSwitch(props: IFormInputProps) {
  const { name, label, ...rest } = props;

  return (
    <Form.Item name={name} label={label} {...rest}>
      <Switch name={name} />
    </Form.Item>
  );
}

export default FormSwitch;
