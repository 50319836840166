import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';

import JsonOneEntryTab from 'components/layout/JsonOneEntryTab/JsonOneEntryTab';
import Tabs from 'components/layout/Tabs';
import links from 'framework/links';
import ElectrificationTab from 'pages/consultant/tabs/ElectrificationTab';
import LeadsDetailTab from 'pages/consultant/tabs/LeadsDetailsTab';
import {
  IConsultantElectrification,
  IConsultantLeadDetail,
} from 'services/consulant/consultant-service.types';
import ConsultantLeadParametersService, {
  ConsultantLeadAllParametersService,
  ConsultantLeadServiceFleetElectrification,
  ConsultantLeadServiceFleetLead,
  ConsultantLocalLeadParametersService,
} from 'services/consulant/leadParameters';
import { lastOpenedPageSelector } from 'store/common/common.selectors';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import Breadcrumb from '../../../../../components/layout/Breadcrumb';
import CurrentFleet from '../../../tabs/CurrentFleet';
import RecommendationTab from '../../../tabs/Recommendation';
import LeadActionsComponent from './components/LeadActionsComponent/LeadActionsComponent';

import styles from './LeadsPage.module.sass';

const LeadDetailsPage = Object.freeze({
  JSON: 'json',
  LEAD: 'lead',
  ELECTRIFICATION: 'electrification',
  CURRENTFLEET: 'currentFleet',
  RECOMMENDATION: 'Recommendation Consultant',
});

const LeadDetailsPageNames = Object.freeze({
  [LeadDetailsPage.LEAD]: 'Lead',
  [LeadDetailsPage.ELECTRIFICATION]: 'Electrification',
  [LeadDetailsPage.CURRENTFLEET]: 'Current Fleet',
  [LeadDetailsPage.RECOMMENDATION]: 'Recommendation Consultant',
  [LeadDetailsPage.JSON]: 'JSON',
});

const LeadParameterDetailPage: React.FC = () => {
  const history = useHistory();

  const breadLinks = [
    { label: 'Dashboard', route: links.home },
    { label: 'eConsultant', route: links.eConsultantLeads },
    { label: 'Leads', route: links.eConsultantLead },
  ];
  const {
    params: { id, page },
  } = useRouteMatch() as any;
  const activePage = page || LeadDetailsPage.LEAD;
  // const [electrificationData, setElectrificationData] = useState<any>({});
  const [leadData, setLeadData] = useState<any>({});
  const [allData, setAllData] = useState<any>({});
  const lastOpenedPage = useSelector(lastOpenedPageSelector);
  // const getElectrificationData = async () => {
  //   try {
  //     const response = await ConsultantLeadServiceFleetElectrification.getById({ id });
  //     setElectrificationData(response);
  //   } catch (e) {
  //     showErrorNotification(e);
  //   }
  // };
  const saveElectrificationData = async (updatedValues: IConsultantElectrification) => {
    try {
      await ConsultantLeadServiceFleetElectrification.save({ ...updatedValues });
      showSuccessNotification('Data Saved');
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const saveCurrentFleet = async () => {
    //   try {
    //
    //     //@ts-ignore
    //     await ConsultantCurrentFleetService.save({...updatedValues});
    //     showSuccessNotification('Data Saved');
    //   } catch (e) {
    //     showErrorNotification(e);
    //   }
  };

  const getLeadData = async () => {
    try {
      const response = await ConsultantLeadParametersService.getById({ id });
      setLeadData(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const getAllData = async () => {
    try {
      const response = await ConsultantLeadAllParametersService.getById({ id });
      setAllData(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    getLeadData();
    // getElectrificationData();
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveLead = async (updatedValues: IConsultantLeadDetail) => {
    try {
      await ConsultantLeadServiceFleetLead.save({ ...updatedValues! });
      showSuccessNotification('Data Saved');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const deleteLead = async () => {
    const payload = { id };
    try {
      await ConsultantLocalLeadParametersService.deleteById(payload);
      showSuccessNotification('Entry successfully removed');
      // @ts-ignore
      history.goBack();
    } catch (e) {
      showErrorNotification(e);
      console.error(e);
    }
  };

  const renderTabs = () => {
    return (
      <Tabs
        activeTitle="Details of Lead"
        activePageKey={activePage}
        baseRoute={{
          link: links.eConsultantLead,
          params: { id },
        }}
        contentMinHeight="calc(100vh - 280px)"
        pages={{ ...LeadDetailsPageNames }}
      >
        {renderContent()}
      </Tabs>
    );
  };

  const renderContent = () => {
    switch (activePage) {
      case LeadDetailsPage.LEAD:
        return (
          <LeadsDetailTab
            entry={leadData}
            onSave={saveLead}
            fleetManager={allData?.data?.fleetManager}
          />
        );
      case LeadDetailsPage.ELECTRIFICATION:
        return (
          <ElectrificationTab
            entry={allData?.data?.electrification}
            onSave={saveElectrificationData}
          />
        );
      case LeadDetailsPage.RECOMMENDATION:
        return (
          <RecommendationTab entry={allData?.data?.businessCase} onSave={saveElectrificationData} />
        );
      case LeadDetailsPage.CURRENTFLEET:
        return (
          <CurrentFleet
            entry={allData?.data?.electrification?.currentFleet}
            onSave={saveCurrentFleet}
          />
        );
      case LeadDetailsPage.JSON:
        return <JsonOneEntryTab entry={allData} title="Lead Data" />;

      default:
        return null;
    }
  };

  const renderActions = () => <LeadActionsComponent entry={leadData} onDelete={deleteLead} />;

  const goBack = () => {
    if (lastOpenedPage) {
      history.push(lastOpenedPage);
    } else {
      // @ts-ignore
      history.push(links.users);
    }
  };
  const detailTitle = () => {
    if (allData.data?.fleetManager?.firstName) {
      return `${allData.data.fleetManager.firstName} ${allData.data.fleetManager.lastName}`;
    }
    return leadData.data?.id;
  };
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Button type="link" className={styles.backButton} onClick={goBack}>
          <ArrowLeftOutlined />
          <span>Back</span>
        </Button>
        <Breadcrumb breads={breadLinks} />
        <Typography.Title level={3}>{detailTitle()}</Typography.Title>
        {renderTabs()}
      </Col>
      {renderActions()}
    </Row>
  );
};

export default LeadParameterDetailPage;
