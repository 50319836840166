import * as React from 'react';
import { Col, Row, Typography } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, InputNumber, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import {
  IBillingChargingStation,
  IBillingEnergyPrice,
} from 'services/billing/billing-service.types';
import ChargingStationService from 'services/billing/charging-station.service';

import { EnergyPriceValidation } from '../../energy-price.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const EnergyPriceDetailsTab: React.FC<IGenericEditTabProps<IBillingEnergyPrice>> = (
  props: IGenericEditTabProps<IBillingEnergyPrice>,
) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  // @todo: we might pick only relevant fields..
  const validation = Yup.object().shape(EnergyPriceValidation);

  // export interface IBillingEnergyPrice extends IBaseEntity {
  //   validFrom: string | Date;
  //   validTo: string | Date;
  //   chargePerUnit: number;
  //   payoutPerUnit: number;
  //   type: string; // enum value;

  //   organization?: IBillingOrganization;
  //   carOwner?: IBillingCarOwner;
  // }

  return (
    <div>
      <Row>
        <Col span={18}>
          <Formik
            initialValues={entry as IBillingEnergyPrice}
            validationSchema={validation}
            onSubmit={(values, { setSubmitting }) => {
              onSave(values).then(() => setSubmitting(false));
            }}
          >
            {({ handleSubmit, isSubmitting, setFieldValue }) => (
              <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
                <Form.Item name="chargePerUnit" label="chargePerUnit">
                  <InputNumber name="chargePerUnit" min={0} max={1} placeholder="0.20" />
                </Form.Item>
                <Form.Item name="payoutPerUnit" label="payoutPerUnit">
                  <InputNumber name="payoutPerUnit" min={0} max={1} placeholder="0.02" />
                </Form.Item>

                <Form.Item name="chargingStation" label="Charging station">
                  <EntitySelector
                    name="chargingStation"
                    entityService={ChargingStationService}
                    current={entry.chargingStation}
                    onSelect={(station: IBillingChargingStation) =>
                      setFieldValue('chargingStation', station)
                    }
                  />
                </Form.Item>

                <Form.Item name="type" label="type">
                  <Typography.Text>{entry?.type}</Typography.Text>
                </Form.Item>

                <Form.Item name="validFrom" label="validFrom">
                  <DatePicker name="validFrom" />
                </Form.Item>

                <Form.Item name="validTo" label="validTo">
                  <DatePicker name="validTo" />
                </Form.Item>

                <Form.Item {...CrudFormDefaultActionParams} name="submit">
                  <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                    Submit
                  </SubmitButton>
                </Form.Item>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default EnergyPriceDetailsTab;
