import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillInvoicesService from 'services/billing-v2/billing-v2-invoices.service';
import { IBillingInvoice, IParams } from 'services/billing-v2/billing-v2-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import InvoiceActionsComponent from './components/InvoiceActionsComponent/InvoiceActionsComponent';
import InvoiceDetailsTab from './tabs/InvoiceDetailsTab';

const InvoiceDetailsPage: React.FC = () => {
  const { id } = useParams<IParams>();

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['documents'],
  };

  const downloadZip = async (entry: IBillingInvoice) => {
    BillInvoicesService.downloadZip(entry);
  };

  const cancelInvoice = async (entry: IBillingInvoice) => {
    try {
      await BillInvoicesService.cancelInvoice(entry);
      window.location.reload();
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const sendByEmail = async (entry: IBillingInvoice) => {
    try {
      await BillInvoicesService.sendByEmail(entry);
      showSuccessNotification('Invoice documents were sent by email successfully');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IBillingInvoice) => <InvoiceDetailsTab entry={entity} />,
    },
  ];

  const renderActions = (entry: IBillingInvoice) => (
    <InvoiceActionsComponent
      entry={entry}
      downloadZip={() => downloadZip(entry)}
      cancelInvoice={() => cancelInvoice(entry)}
      sendDocsByEmail={() => sendByEmail(entry)}
    />
  );

  const renderTitle = (entry: IBillingInvoice) =>
    `${moment(entry.month).format('MMMM')} ${entry.year} - ${entry.status}`;

  return (
    <CrudDetailContainer
      entityName="Invoice"
      tabs={tabs}
      renderActions={renderActions}
      renderTitle={renderTitle}
      baseLink={links.billInvoice}
      defaultPayload={params}
      hideJsonTab
      resource={BillInvoicesService}
    />
  );
};

export default InvoiceDetailsPage;
