import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IBillingRFIDCardCarOwnerRelationship } from 'services/billing/billing-service.types';

import { showErrorNotification } from '../../utils/notificationUtil';
import { IBillingCarOwner } from './billing-service.types';

class BillingCarOwnerServiceClass extends EntityService<IBillingCarOwner> {
  async downloadPDFInvoice(
    carOwnerId: string,
    carOwnerName?: string,
    year?: number,
    month?: number,
  ) {
    // /api/v1/organizations/{id}/document/csv
    const filename = `Proof-of-Charge-${carOwnerName}-${year}-${month}.pdf`;
    const url = `${this.getEntityUrl()}/${carOwnerId}/document/monthly-proof-of-charging-pdf`;
    const params = {
      year,
      month,
    };
    try {
      await this.downloadFile(url, params, filename);
    } catch (e) {
      showErrorNotification(e);
    }
  }

  getDisplayName(entry: IBillingCarOwner): string {
    return entry && `${entry.firstname} ${entry.lastname}`;
  }

  dettachRfidCardRelationship(entry: IBillingRFIDCardCarOwnerRelationship) {
    const url = this.getURL(`/admin/car-owner-rfid-cards/${entry.id}`);
    return this.delete(url);
  }
}

const BillingCarOwnerService = new BillingCarOwnerServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/car-owners',
  detailLink: links.billingCarOwner,
  overviewLink: links.billingCarOwners,
});

// this way I can autowire the result (with command + .);
export default BillingCarOwnerService;
