// import * as Yup from 'yup';

/*
export interface IBillingCarOwner extends IBaseEntity {
  firstname?: string;
  lastname?: string;
  email: string;
  authId?: string;
  organizationId?: string;
  organization?: IBillingOrganization;
}
*/
import * as Yup from 'yup';

export enum EEnergyPriceStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DECLINED = 'declined',
}

export const EnergyPriceValidation = {
  chargePerUnit: Yup.number().nullable(),
  payoutPerUnit: Yup.number().nullable(),
  validFrom: Yup.date().typeError('Date must be valid').required('Required'),
  status: Yup.mixed<EEnergyPriceStatus>()
    .required('Required')
    .oneOf(Object.values(EEnergyPriceStatus)),
};
