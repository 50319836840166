import React from 'react';
import { Button, Card, Typography } from 'antd';
import { FieldArray, FormikValues } from 'formik';

import { FormInput, FormSelect } from 'components/forms';

import BlockContentSubComponent from './BlockContentSubComponent';
import DeleteButton from './DeleteCircleBtn';
import ImageContentBlock from './ImageContentBlock';
import TextContentBlock from './TextContentBlock';

import styles from './LandingComponentsStyles.module.sass';

const { Title } = Typography;

interface IContentComponentProps {
  values: FormikValues;
  index: number;
}

const BlockContentComponent = (props: IContentComponentProps) => {
  const { values, index } = props;

  const renderContent = (contentType: string, contentItemString: string, i: number) => {
    switch (contentType) {
      case 'text':
        return <TextContentBlock contentItemPath={contentItemString} />;
      case 'image':
        return <ImageContentBlock contentItemPath={contentItemString} />;
      case 'form':
        return <FormInput name={`${contentItemString}.form`} label="Form" />;
      case 'horizontal':
        return <BlockContentSubComponent index={index} i={i} values={values} />;
      case 'vertical':
        return <BlockContentSubComponent index={index} i={i} values={values} />;
    }
  };

  return (
    <FieldArray
      name={`scheme.blocks[${index}].content`}
      render={(arrayHelpers) => {
        return (
          <>
            {values?.scheme?.blocks[index]?.content?.map((content: any, i: number) => {
              const contentType = values.scheme.blocks[index].content[i].type;
              const contentItemString = `scheme.blocks[${index}].content[${i}]`;
              return (
                <Card bordered={false} style={{ margin: '10px 20px' }} key={i}>
                  <DeleteButton
                    tooltip="Delete this content block"
                    onClick={() => arrayHelpers.remove(i)}
                  />
                  <div className={styles.center}>
                    <Title level={5}>Content Block</Title>
                  </div>
                  <FormSelect
                    name={`${contentItemString}.type`}
                    options={['text', 'image', 'form', 'horizontal', 'vertical']}
                    label="Сontent Block Type"
                  />

                  {renderContent(contentType, contentItemString, i)}
                </Card>
              );
            })}

            <div className={styles.center}>
              <Button className={styles.button} onClick={() => arrayHelpers.push({})}>
                Add new content block
              </Button>
            </div>
          </>
        );
      }}
    />
  );
};

export default BlockContentComponent;
