import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';

import styles from './styles.module.sass';

interface IProps {}

const ProductAttributeActionsComponent: React.FC<IProps> = () => {
  const history = useHistory();

  return (
    <div className={styles.containerInner}>
      <Button
        className={styles.button}
        type="primary"
        size="large"
        shape="round"
        onClick={() => history.push(createLink(links.shopCreateProductAttributeOption))}
      >
        Create Attribute Option
      </Button>
    </div>
  );
};
export default ProductAttributeActionsComponent;
