import * as React from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { ITendergyInvitationLink } from 'store/tendegyService/tendergyService.types';

import { InvitationLinkValidation } from '../../invitation-link.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const InvitationLinkDetailsTab: React.FC<IGenericEditTabProps<ITendergyInvitationLink>> = (
  props: IGenericEditTabProps<ITendergyInvitationLink>,
) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(InvitationLinkValidation);

  return (
    <div>
      <Formik
        initialValues={entry as any}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="type" label="Type:">
              {entry.type}
            </Form.Item>
            {entry.organization && (
              <Form.Item name="organization" label="Organization name:">
                {entry.organization?.name}
              </Form.Item>
            )}
            {entry.clientRequestPool && (
              <Form.Item name="clientRequestPool" label="Pool:">
                {entry.clientRequestPool?.name}
              </Form.Item>
            )}

            <Form.Item name="expires" label="Expires at">
              <DatePicker name="expires" placeholder="" />
            </Form.Item>

            <Form.Item name="token" label="Token">
              {entry.token}
            </Form.Item>
            <Form.Item name="isEnabled" label="Is Enabled">
              <Switch name="isEnabled" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InvitationLinkDetailsTab;
