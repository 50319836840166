import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ISAFeedbacksService } from 'services/isa-service';
import { IISAFeedback, IISAUser } from 'services/isa-service/isa-service.types';
import { formatDefaultDate } from 'utils/date.helper';

export default function ISAFeedbacksOverview() {
  return (
    <CrudListViewContainer<IISAFeedback>
      columns={[
        { title: 'Feedback Description', dataIndex: 'description' },
        { title: 'Rating', dataIndex: 'rating' },
        { title: 'User', dataIndex: 'user', render: (user: IISAUser) => user?.email },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          render: (date: string) => formatDefaultDate(date),
        },
      ]}
      entityName="feedbacks"
      resource={ISAFeedbacksService}
      defaultPayload={{ relations: ['user'] }}
      withSorting
    />
  );
}
