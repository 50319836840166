import config from 'config';

import { ISA_MAPPINGS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAMapping } from '../isa-service.types';

const ISAMappingService = new EntityService<IISAMapping>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_MAPPINGS_ENDPOINT,
  createLink: links.isaMappingCreate,
  detailLink: links.isaMappingDetails,
});

ISAMappingService.getDisplayName = (entry) => entry.id;

export default ISAMappingService;
