import * as React from 'react';
import { Button, Table, Typography } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, InputNumber, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { EnergyPriceValidation } from 'pages/billing/energy-prices/energy-price.validation';
import BillingEnergyPricingService from 'services/billing/billing-energy-prices.service';
import {
  IBillingChargingStation,
  IBillingEnergyPrice,
} from 'services/billing/billing-service.types';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from '../billing-create-flow.module.sass';

interface ICreateOrSelectRFIDCardStepParams {
  onSelect: (values: IBillingEnergyPrice) => void;
  chargingStation: IBillingChargingStation;
  energyPrices?: IBillingEnergyPrice[];
}

/**
 * This step should emit a carOwner Id.
 *
 *
 */
const SetEnergyPriceStep: React.FC<ICreateOrSelectRFIDCardStepParams> = (
  props: ICreateOrSelectRFIDCardStepParams,
) => {
  const validation = Yup.object().shape(EnergyPriceValidation);
  const { chargingStation, energyPrices, onSelect } = props;
  const defaultValues: Partial<IBillingEnergyPrice> = {
    type: 'station',
    validFrom: new Date(),
    chargingStation,
  };

  const createEnergyPrice = async (values: IBillingEnergyPrice) => {
    let energyPrice;
    try {
      energyPrice = await BillingEnergyPricingService.save(values);
      onSelect(energyPrice);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const columns = [
    {
      title: 'validTo',
      dataIndex: 'validTo',
      key: 'validTo',
    },
    {
      title: 'validFrom',
      dataIndex: 'validFrom',
      key: 'validFrom',
    },
    {
      title: 'payoutPerUnit',
      dataIndex: 'payoutPerUnit',
      key: 'payoutPerUnit',
    },

    {
      title: 'select',
      key: 'action',
      render: (entry: IBillingEnergyPrice) => (
        <Button onClick={() => props.onSelect(entry)}>Select</Button>
      ),
    },
  ];

  return (
    <div>
      <div className={styles.entitySelector}>
        <Typography.Title className={styles.textCenter} level={5}>
          Select existing energy price
        </Typography.Title>

        {energyPrices && energyPrices.length > 0 ? (
          <Table dataSource={energyPrices} columns={columns} />
        ) : (
          'This car Owner has no energy prices yet, please create a new one'
        )}
      </div>
      <div>
        <Typography.Title className={styles.textCenter} level={5}>
          Create new Energy Price
        </Typography.Title>
        <Formik
          initialValues={defaultValues}
          validationSchema={validation}
          onSubmit={(values: any) => createEnergyPrice(values)}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
              <p className={styles.textCenter}>
                Create new energy price for {chargingStation.evse} charging station{' '}
              </p>

              <Form.Item name="chargePerUnit" label="chargePerUnit">
                <InputNumber name="chargePerUnit" min={0} max={1} placeholder="0.20" />
              </Form.Item>
              <Form.Item name="payoutPerUnit" label="payoutPerUnit">
                <InputNumber name="payoutPerUnit" min={0} max={1} placeholder="0.02" />
              </Form.Item>

              <Form.Item name="type" label="type">
                <Select name="type">
                  <Select.Option value="station">station</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="validFrom" label="validFrom">
                <DatePicker name="validFrom" />
              </Form.Item>

              <Form.Item name="validTo" label="validTo">
                <DatePicker name="validTo" />
              </Form.Item>

              <Form.Item {...CrudFormDefaultActionParams} name="submit">
                <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                  Submit
                </SubmitButton>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SetEnergyPriceStep;
