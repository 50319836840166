import React from 'react';
import { Button, Col, Row, Table } from 'antd';

import FileUploader from 'components/ui/FileUploader';
import { ITendergyDocument, MediaCategoryType } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

interface IPoolDocumentsTab {
  documents?: ITendergyDocument[];
  onOpen?: Function;
  deleteFile?: (fileName: string) => void;
  uploadFile?: (document: File, category: MediaCategoryType) => Promise<void>;
}

const ClientRequestPoolDocumentsTab: React.FC<IPoolDocumentsTab> = (props: IPoolDocumentsTab) => {
  const { documents, onOpen, deleteFile, uploadFile } = props;

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 150,
    },
    {
      title: 'Original Name',
      dataIndex: 'originalName',
      key: 'originalName',
      width: 150,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => formatDefaultDate(date),
      width: 150,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (entry: any) => (
        <Row gutter={[16, 0]}>
          <Col>
            <Button onClick={() => !!onOpen && onOpen(entry?.name)}>Open</Button>
          </Col>
          <Col>
            <Button onClick={() => !!deleteFile && deleteFile(entry?.name)}>Delete</Button>
          </Col>
        </Row>
      ),
    },
  ];

  if (!documents) {
    return <div>Client Request Pool Documents data is missing</div>;
  }

  const fileUploaderProps = {
    categoriesList: Object.values(MediaCategoryType).filter((value) => value.includes('pool')),
    onFileUpload: uploadFile,
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <FileUploader {...fileUploaderProps} />
      </Col>
      <Col span={24}>
        <Table columns={columns} dataSource={documents} />
      </Col>
    </Row>
  );
};

export default ClientRequestPoolDocumentsTab;
