import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IB } from 'pages/billing-v3/charging-stations/constants';
import VendorService from 'services/tendergy/tendergy-vendor.service';
import { IProduct } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: IB<any>[] = [
  {
    title: 'Vendor name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Products',
    dataIndex: 'productModels',
    key: 'products',
    sorter: true,
    sortParameter: 'productModels.name',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (value: IProduct[]) => `${value.length} models` || 'No products',
  },
  {
    title: 'State',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'state',
  },
  {
    title: 'Created',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const VendorsOverviewPage: React.FC = () => (
  <CrudListViewContainer entityName="Vendors" columns={columns} resource={VendorService} />
);
export default VendorsOverviewPage;
