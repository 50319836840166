import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import EmailLayoutService from 'services/email/email-layouts.service';
import { IEmailLayout } from 'services/email/email-service.types';
import { formatDefaultDate } from 'utils/date.helper';

import LayoutFilters from '../components/LayoutFilters';

const columns: ColumnsType<IEmailLayout> = [
  {
    title: 'Context',
    dataIndex: 'context',
    key: 'context',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Language',
    dataIndex: 'language',
    key: 'language',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const defaultPayload = {
  language: 'de',
};

const LayoutOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Layouts"
    columns={columns}
    resource={EmailLayoutService}
    customFilters={LayoutFilters}
    defaultPayload={defaultPayload as any}
  />
);
export default LayoutOverviewPage;
