import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select as FormSelect, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { QuestionnaireStepValidation } from 'pages/tendergy/questionnaire-steps/questionnaireSteps.validation';
import { QuestionnaireStepType } from 'pages/tendergy/questionnaire-steps/questionnaireTypes';
import TendergyQuestionnaireStepService from 'services/tendergy/tendergy-questionnaire-step.service';
import { ITendergyQuestionnaireStep } from 'store/tendegyService/tendergyService.types';

interface Props {
  entry: ITendergyQuestionnaireStep;
  questionnaireId: string;
  questionnaireVersion: number;
  onSubmitStep: (entry: ITendergyQuestionnaireStep) => void;
}

const QuestionnaireBaseStep: React.FC<Props> = ({
  onSubmitStep,
  questionnaireId,
  questionnaireVersion,
}: Props) => {
  const [steps, setSteps] = useState<any>([]);
  const { Option } = Select;

  useEffect(
    () => {
      fetchSteps(questionnaireId);
    },
    // eslint-disable-next-line
    [],
  );

  const validation = Yup.object().shape(QuestionnaireStepValidation);

  const fetchSteps = async (questionnaireId: string) => {
    let requestPayload = {
      limit: 100,
      questionnaireId: questionnaireId,
      questionnaireVersion: questionnaireVersion,
    };
    // @ts-ignore
    let result = await TendergyQuestionnaireStepService.getEntries({ ...requestPayload });
    let steps = result?.items.map((entry) => {
      const label = entry.title;
      return { label, id: entry.id, entry };
    });
    setSteps(steps);
  };
  return (
    <div>
      <Formik
        initialValues={
          {
            questionnaire: { id: questionnaireId, version: questionnaireVersion },
          } as ITendergyQuestionnaireStep
        }
        onSubmit={(values) => {
          onSubmitStep({ ...values });
        }}
        validationSchema={validation}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="parent" label="Parent Step">
              <Select
                style={{ width: '100%' }}
                placeholder={`Select parent step only for sub steps`}
                onChange={(parent) => {
                  setFieldValue('parent', parent);
                }}
              >
                {steps &&
                  steps?.map((item: any) => (
                    <Option key={item.id} value={item.id} item={item}>
                      {item.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item required name="title" label="Title">
              <Input name="title" />
            </Form.Item>
            {!values.parent ? (
              <Form.Item name="stepType" label="Type" required>
                <FormSelect name="stepType">
                  {[
                    QuestionnaireStepType.QuickCheck,
                    QuestionnaireStepType.User,
                    QuestionnaireStepType.CostCalculation,
                    QuestionnaireStepType.RemoteHomeCheck,
                  ].map((type) => (
                    <FormSelect.Option value={type} key={type}>
                      {type}
                    </FormSelect.Option>
                  ))}
                </FormSelect>
              </Form.Item>
            ) : null}
            <Form.Item required name="order" label="Order">
              <Input
                name="order"
                type={'number'}
                placeholder={`The number of parent's child plus one`}
              />
            </Form.Item>
            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
                onClick={() =>
                  setFieldValue('questionnaire', {
                    id: questionnaireId,
                    version: questionnaireVersion,
                  })
                }
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionnaireBaseStep;
