import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { createFingerprint } from 'framework/fingerprint';
import Routes from 'routes';
import { fingerprintSelector } from 'store/auth/auth.selectors';

import { authInit, getFingerprint } from './store/auth/auth.actions';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const fingerprint = useSelector(fingerprintSelector);

  useEffect(() => {
    if (fingerprint) {
      dispatch(authInit());
      localStorage.setItem('fingerprint', fingerprint);
    } else {
      createFingerprint().then((fg) => dispatch(getFingerprint(fg)));
    }

    // eslint-disable-next-line
  }, [fingerprint]);

  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default App;
