import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { ITendergyInstaller } from 'store/tendegyService/tendergyService.types';

class InstallerAPI extends EntityService<ITendergyInstaller> {
  confirmById = (id: string) => this.post(`${this.config.entityURL}/${id}/approve`, {}, true);

  getDisplayName(entry: ITendergyInstaller): string {
    return `${entry?.user?.firstName} ${entry?.user?.lastName}`;
  }
}

const TendergyInstallerService = new InstallerAPI({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/installers',
});

export default TendergyInstallerService;
