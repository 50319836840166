import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IBillingRFIDCard } from 'services/billing/billing-service.types';
import RFIDCardsService from 'services/billing/rfid-cards.service';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IBillingRFIDCard> = [
  {
    title: 'identifier',
    dataIndex: 'identifier',
    key: 'identifier',
  },
  {
    title: 'evcoId',
    dataIndex: 'evcoId',
    key: 'evcoId',
  },
  {
    title: 'username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'isPrivate',
    dataIndex: 'isPrivate',
    key: 'isPrivate',
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const RFIDCardsPage: React.FC = () => (
  <CrudListViewContainer entityName="RFID Cards" columns={columns} resource={RFIDCardsService} />
);
export default RFIDCardsPage;
