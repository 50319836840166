import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISAAddressesService } from 'services/isa-service';

import ISAAddressesForm from './components/ISAAddressesForm';

export default function ISAAddressesCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add User Address',
          render: (_entry, onSubmit) => <ISAAddressesForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="address"
      baseLink={links.isaAddressOverview}
      resource={ISAAddressesService}
    />
  );
}
