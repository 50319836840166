import { CHANGE_LAST_OPENED_PAGE } from './common.types';

// Change last opened page
export const changeLastOpenedPage = (page: string, entityURL: string) => ({
  type: CHANGE_LAST_OPENED_PAGE,
  payload: {
    page,
    entityURL,
  },
});
