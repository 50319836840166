import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import CountryService from 'services/tendergy/tendergy-country.service';
import { ITendergyCountry } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<ITendergyCountry> = [
  {
    title: 'ISO 31661 Alpha 3',
    dataIndex: 'iso31661Alpha3',
    key: 'iso31661Alpha3',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Vat Rate',
    dataIndex: 'vatRate',
    key: 'vatRate',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Default Timezone',
    dataIndex: 'defaultTimezone',
    key: 'defaultTimezone',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'isDefault',
    dataIndex: 'isDefault',
    key: 'isDefault',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (isDefault: boolean) => (isDefault ? 'Yes' : 'No'),
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date: string) => formatDefaultDate(date),
  },
];

const CountryOverviewPage: React.FC = () => (
  <CrudListViewContainer entityName="Countries" columns={columns} resource={CountryService} />
);
export default CountryOverviewPage;
