import links from 'framework/links';
import { consultantCommercialFleetTypesPage } from 'pages/consultant/commercialFleetType';
import consultantCommercialFleetTypesDetailPage from 'pages/consultant/commercialFleetType/CommercialFleetTypeDetails';
import { consultantCountriesPage } from 'pages/consultant/countries';
import consultantCountriesDetailPage from 'pages/consultant/countries/CountriesDetails';
import { consultantFleetTypesPage } from 'pages/consultant/fleetType';
import consultantFleetTypesDetailPage from 'pages/consultant/fleetType/FleetTypeDetails';
import { ConsultantGlobalLeadParametersPage } from 'pages/consultant/leads';
import GlobalLeadParameterDetail from 'pages/consultant/leads/components/details/LeadsPage';
import { ConsultantLocalLeadParameterPage } from 'pages/consultant/local-lead-parameters';
// import LocalLeadParameterDetail from 'pages/consultant/local-lead-parameters/components/details/LeadParameterPage';

export const CONSULTANT_ROUTES = [
  { path: links.eConsultantLeads, component: ConsultantGlobalLeadParametersPage },
  { path: links.eConsultantLead, component: GlobalLeadParameterDetail },

  { path: links.eConsultantLocalLeadParameters, component: ConsultantLocalLeadParameterPage },
  { path: links.eCCountries, component: consultantCountriesPage },
  { path: links.eCCountriesDetail, component: consultantCountriesDetailPage },
  { path: links.eConsultantFleetTypes, component: consultantFleetTypesPage },
  { path: links.eConsultantFleetTypesDetail, component: consultantFleetTypesDetailPage },
  { path: links.eConsultantCommercialFleetTypes, component: consultantCommercialFleetTypesPage },
  {
    path: links.eConsultantCommercialFleetTypesDetail,
    component: consultantCommercialFleetTypesDetailPage,
  },
  // { path: links.consultantLocalLeadParameter, component: LocalLeadParameterDetail },
];
