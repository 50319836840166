import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ConsultantCountriesService } from 'services/consulant/countries';

import Filters from './components/filter';
import columns from './constants';

const ConsultantDefaultVariablesPage: React.FC = () => {
  return (
    <CrudListViewContainer
      entityName="Entities"
      columns={columns}
      resource={ConsultantCountriesService}
      customFilters={Filters}
    />
  );
};
export default ConsultantDefaultVariablesPage;
