import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import LeadService from 'services/tendergy/tendergy-lead.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import {
  ITendergyClientRequestPool,
  ITendergyLead,
  LeadState,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<ITendergyLead> = [
  {
    title: 'Lead Type',
    dataIndex: 'leadType',
    key: 'leadType',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (companyName: string) => (companyName ? companyName : '-'),
  },
  {
    title: 'Number Of Cars',
    dataIndex: 'numberOfCars',
    key: 'numberOfCars',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (numberOfCars: string) => (numberOfCars ? numberOfCars : '-'),
  },
  {
    title: 'Contact Person Name',
    dataIndex: 'contactPersonName',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'contactPersonName',
    render: (contactPersonName: string) => (contactPersonName ? contactPersonName : '-'),
  },
  {
    title: 'Contact Person Email',
    dataIndex: 'contactPersonEmail',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'contactPersonEmail',
    render: (contactPersonEmail: string) => (contactPersonEmail ? contactPersonEmail : '-'),
  },
  {
    title: 'Contact Person Phone',
    dataIndex: 'contactPersonPhone',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'contactPersonPhone',
    render: (contactPersonPhone: string) => (contactPersonPhone ? contactPersonPhone : '-'),
  },
  {
    title: 'Initiator Name',
    dataIndex: 'initiatorName',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'initiatorName',
    render: (initiatorName: string) => (initiatorName ? initiatorName : '-'),
  },
  {
    title: 'Pool',
    dataIndex: 'pool',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'pool',
    render: (pool: ITendergyClientRequestPool) => (pool?.name ? pool?.name : '-'),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date, 'HH:mm DD.MM.YYYY'),
    sorter: true,
    defaultSortOrder: 'descend' as SortOrder,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
  },
];

const payload: IGetMultiEntityPayload = {
  sortFields: 'createdAt',
  sortDirections: '-1',
};

const LeadsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    statesForFiltering={Object.values(LeadState)}
    entityName="Leads"
    defaultPayload={payload}
    columns={columns}
    resource={LeadService}
  />
);
export default LeadsOverviewPage;
