import produce from 'immer';

import {
  OfferActionTypes,
  OfferState,
  RESET,
  SET,
  SET_LOADING_STATUS,
  TRANSITION,
} from './offer.types';

const initialState: OfferState = {
  data: {
    id: null,
  },
  loading: false,
};

const OfferReducer = (state = initialState, action: OfferActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET:
        draft.data = action.payload;
        break;
      case SET_LOADING_STATUS:
        draft.loading = action.payload;
        break;
      case RESET:
        draft.data = initialState.data;
        draft.loading = initialState.loading;
        break;
      case TRANSITION:
        draft.data = action.payload;
        break;
    }
  });

export default OfferReducer;
