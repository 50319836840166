import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillingImportsService from 'services/billing/billing-imports.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import RFIDCardsDetailsTab from './tabs/RFIDCardsDetailsTab';

const BillingImportPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: any) => <RFIDCardsDetailsTab entry={entity} />,
    },
  ];
  return (
    <CrudDetailContainer
      entityName="Billing Import"
      tabs={tabs}
      baseLink={links.billingImport}
      defaultPayload={params}
      resource={BillingImportsService}
    />
  );
};

export default BillingImportPage;
