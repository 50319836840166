import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { ITendergyActivityLog } from 'store/tendegyService/tendergyService.types';

class CraftActivityLogsServiceClass extends EntityService<ITendergyActivityLog> {}

const TendergyActivityLogsService = new CraftActivityLogsServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/activity-logs',
});

export default TendergyActivityLogsService;
