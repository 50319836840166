import config from 'config';

import { AUTH_TOKEN_KEY } from 'framework/constants';
import EntityService from 'services/abstract/entity.service';
import { showErrorNotification } from 'utils/notificationUtil';

import { IBillingDocument } from './billing-v3-service.types';

class BillDocumentServiceClass extends EntityService<IBillingDocument> {
  getDisplayName(entry: IBillingDocument): string {
    return entry.toString();
  }

  async downloadDocument(doc: IBillingDocument) {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);

    const url = this.getURL(`admin/documents/download/${doc.id}`);
    const res = await this.getURL(`admin/documents/download/${doc.id}`);
    if (res !== undefined) {
      window.open(`${url}?token=${accessToken}`);
    } else {
      showErrorNotification('Failed to download invoice, try again later.');
    }
  }
}

const BillDocumentService = new BillDocumentServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/documents',
});

export default BillDocumentService;
