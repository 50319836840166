import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, DatePicker, Row, Select, Table, Typography } from 'antd';
import moment from 'moment';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { Loader } from 'components/ui';
import { DATE_AND_TIME } from 'framework/constants/dateFormats';
import links from 'framework/links';
import EmailActivityLogsServiceService from 'services/email/email-activity-logs.service';
import EmailLayoutService from 'services/email/email-layouts.service';
import {
  IEmailActivityLog,
  IEmailLayout,
  IEmailTemplate,
} from 'services/email/email-service.types';
import EmailTemplatesService from 'services/email/email-templates.service';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

const { RangePicker } = DatePicker;

const ITEMS_PER_PAGE = 10;

const columns = [
  {
    title: 'Recipient Name',
    dataIndex: 'payload',
    key: 'recipientName',
    render: (payload: any) => (payload?.recipientName ? payload.recipientName : '–'),
  },
  {
    title: 'Recipient Email',
    dataIndex: 'to',
    key: 'to',
  },
  {
    title: 'Layout',
    dataIndex: 'layout',
    key: 'layout',
  },
  {
    title: 'Template',
    dataIndex: 'template',
    key: 'template',
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (date: string) => (date ? formatDefaultDate(date, DATE_AND_TIME) : '–'),
  },
];

const today = new Date().toISOString();
const yesterday = new Date(Date.now() - 86400000).toISOString();

const ActivityLogsOverviewPage: React.FC = () => {
  const [logs, setLogs] = useState<IEmailActivityLog[] | null>(null);
  const [fromDate, setFromDate] = useState<string>(yesterday);
  const [toDate, setToDate] = useState<string>(today);
  const [layout, setLayout] = useState<IEmailLayout | null>(null);
  const [template, setTemplate] = useState<IEmailTemplate | null>(null);
  const [withError, setWithError] = useState<string | null>();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const history = useHistory();

  const params: any = {
    sortDirections: -1,
    sortFields: 'timestamp',
    fromDate,
    toDate,
    layout: layout?.context,
    template: template?.name,
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
    error: withError,
  };

  const getActivityLogs = async () => {
    try {
      const response = await EmailActivityLogsServiceService.getEntries({ ...params });
      setLogs(response.items);
      setTotal(response.total || 0);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    setPage(1);
    getActivityLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, layout, template, withError]);

  useEffect(() => {
    getActivityLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!logs) {
    return <Loader />;
  }

  const dateRangePickerProps = {
    value: [moment(fromDate), moment(toDate)] as any,
    style: { width: '100%' },
    onChange: (dates: any) => {
      setFromDate(dates ? dates[0].toISOString() : yesterday);
      setToDate(dates ? dates[1].toISOString() : today);
    },
  };

  const layoutEntitySelectorProps = {
    entityService: EmailLayoutService,
    name: 'layout',
    onSelect: (layout: IEmailLayout) => {
      setLayout(layout);
    },
    allowClear: true,
    value: layout?.id,
    placeholder: 'Select layout',
    style: { width: '100%' },
  };

  const templateEntitySelectorProps = {
    entityService: EmailTemplatesService,
    name: 'template',
    onSelect: (template: IEmailTemplate) => {
      setTemplate(template);
    },
    allowClear: true,
    value: template?.id,
    placeholder: 'Select template',
    style: { width: '100%' },
  };

  const errorEntitySelectorProps = {
    name: 'withError',
    onChange: (value: string) => {
      setWithError(value);
    },
    allowClear: true,
    value: withError || undefined,
    placeholder: 'Errors',
    style: { width: '100%' },
    options: [
      { value: '1', label: 'With errors' },
      { value: '0', label: 'Without errors' },
    ],
  };

  const tableProps = {
    rowKey: 'id',
    columns,
    dataSource: logs,
    pagination: {
      total,
      defaultPageSize: ITEMS_PER_PAGE,
      current: page,
      onChange: (page: number) => setPage(page),
      showSizeChanger: false,
    },
    onRow: (log: IEmailActivityLog) => ({
      onClick: () => history.push(`${links.emailActivityLogs}/${log.id}`),
    }),
  };

  return (
    <>
      <Row>
        <Col>
          <Typography.Title level={3} style={{ lineHeight: 1 }}>
            List of Email Activity Logs
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <RangePicker {...dateRangePickerProps} picker="date" />
        </Col>
        <Col span={6}>
          <EntitySelector {...layoutEntitySelectorProps} />
        </Col>
        <Col span={6}>
          <EntitySelector {...templateEntitySelectorProps} />
        </Col>
        <Col span={6}>
          <Select {...errorEntitySelectorProps} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table {...tableProps} />
        </Col>
      </Row>
    </>
  );
};

export default ActivityLogsOverviewPage;
