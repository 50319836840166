import React from 'react';
import { InputNumber } from 'antd';
import { useField } from 'formik';

import styles from './CustomizeNumberInputStyles.module.sass';

interface ICustomizeNumberInputProps {
  entry: any;
  name: string;
  minValue?: number;
  maxValue?: number;
  step?: number;
}

const CustomizeNumberInput: React.FC<ICustomizeNumberInputProps> = (
  props: ICustomizeNumberInputProps,
) => {
  const { entry, name, minValue, maxValue, step } = props;
  const [field, , { setValue }] = useField({
    name,
    type: 'number',
  });

  const onChange = (value: number | string | undefined | null) => setValue(value);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{entry.title.toUpperCase()}</div>
      <InputNumber
        defaultValue={+field.value}
        min={minValue}
        max={maxValue}
        step={step}
        {...field}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomizeNumberInput;
