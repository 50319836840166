import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import AuthRoleService from 'services/auth/role.service';

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const RolesOverview: React.FC = () => {
  return <CrudListViewContainer entityName="Roles" columns={columns} resource={AuthRoleService} />;
};

export default RolesOverview;
