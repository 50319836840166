import React from 'react';
import { Form, Switch } from 'formik-antd';

import { FormInput, FormSelect } from 'components/forms';

import styles from './LandingComponentsStyles.module.sass';

interface TextContentBlockProps {
  contentItemPath: string;
}

const TextContentBlock: React.FC<TextContentBlockProps> = (props: TextContentBlockProps) => {
  const { contentItemPath } = props;

  return (
    <div>
      <FormInput name={`${contentItemPath}.title`} label="Title" />
      <FormInput name={`${contentItemPath}.subTitle`} label="Subtitle" />
      <FormInput name={`${contentItemPath}.text`} label="Text" required />
      <Form.Item name={`${contentItemPath}.underlined`} label="Underlined">
        <Switch name={`${contentItemPath}.underlined`} />
      </Form.Item>
      <div className={styles.center}>
        <div className={styles.subtitle}>You can add image to the text block:</div>
      </div>
      <FormInput name={`${contentItemPath}.img.title.text`} label="Image title" />
      <FormInput name={`${contentItemPath}.img.url`} label="Image URL" />
      <FormSelect name={`${contentItemPath}.img.type`} options={['image']} label="Image Type" />
      <Form.Item name={`${contentItemPath}.img.underlined`} label="Image underlined">
        <Switch name={`${contentItemPath}.img.underlined`} />
      </Form.Item>

      <FormInput
        name={`${contentItemPath}.img.title.style.backgroundColor`}
        label={'Image title background color'}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      />
      <FormSelect
        name={`${contentItemPath}.img.title.style.fontWeight`}
        label={'Image title font weight'}
        options={['lighter', 'bold', 'bolder']}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      />
      <FormSelect
        name={`${contentItemPath}.img.title.style.position`}
        label={'Image title position'}
        options={['fixed', 'absolute', 'relative']}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      />
      <FormInput
        name={`${contentItemPath}.img.title.style.fontSize`}
        label={'Image title font size'}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      />
      <FormInput
        name={`${contentItemPath}.img.title.style.margin`}
        label={'Image title margin'}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      />
      <FormInput
        name={`${contentItemPath}.img.title.style.padding`}
        label={'Image title padding'}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      />
      <FormInput
        name={`${contentItemPath}.img.title.style.borderRadius`}
        label={'Image title border radius'}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      />
    </div>
  );
};

export default TextContentBlock;
