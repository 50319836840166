import React, { useMemo } from 'react';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input/Input';
import InputNumber from 'antd/lib/input-number';
import Select from 'antd/lib/select';
import moment from 'moment';

import { ClientRequestState } from 'framework/constants';
import { getFilteredFormFields } from 'framework/utils/helpers';
import TendergyQuestionnaireService from 'services/tendergy/tendergy-questionnaire.service';
import { IClientRequest, ITendergyUser } from 'store/tendegyService/tendergyService.types';

import QuestionnaireEntitySelector from './QuestionnaireEntitySelector';

interface Props {
  entry?: IClientRequest;
  onSubmit: (entry: any) => Promise<IClientRequest>;
}

const RequestDetailsComponent: React.FC<Props> = ({ entry, onSubmit }: Props) => {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const filteredValues = getFilteredFormFields(values);
    filteredValues.id = entry?.id;
    onSubmit(filteredValues);
  };

  const clientRequestStates = useMemo(
    () =>
      Object.keys(ClientRequestState).map((key) => ({
        state: ClientRequestState[key as keyof typeof ClientRequestState],
        title: key,
      })),
    [],
  );

  return (
    <Form
      form={form}
      name="Edit Client Request"
      initialValues={{
        ...entry,
        startDate: entry?.startDate ? moment(entry.startDate, 'YYYY-MM-DD HH:mm') : '',
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      onFinish={handleSubmit}
    >
      <Form.Item label="id">{entry?.id}</Form.Item>
      <Form.Item label="Created At">{entry?.createdAt}</Form.Item>
      <Form.Item label="Display Id">{entry?.displayId}</Form.Item>
      <Form.Item label="Last Reminder Sent At">{entry?.lastStateChangedAt}</Form.Item>
      <Form.Item label="Last Reminder Sent At">{entry?.lastReminderSentAt}</Form.Item>
      {entry?.deletedDate && <Form.Item label="Updated Up">{entry?.updatedUp}</Form.Item>}
      {entry?.deletedDate && <Form.Item label="Deleted Date">{entry?.deletedDate}</Form.Item>}

      {[{ field: 'startDate', label: 'Start Date' }].map((field) => (
        <Form.Item name={field.field} label={field.label} key={field.field}>
          <DatePicker />
        </Form.Item>
      ))}

      <Form.Item name="state" label="State">
        <Select>
          {clientRequestStates.map((item) => (
            <Select.Option value={item.state} key={item.state}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {[
        { field: 'step', label: 'Step' },
        { field: 'subStep', label: 'SubStep' },
        { field: 'soonestPossible', label: 'Soonest Possible' },
        { field: 'weekdays', label: 'Weekdays' },
        { field: 'contactType', label: 'Contact Type' },
        { field: 'annotation', label: 'Annotation' },
        { field: 'rejectReason', label: 'Reject Reason' },
        { field: 'phone', label: 'Phone' },
        { field: 'bundle', label: 'Bundle' },
        { field: 'businessEmail', label: 'Business Email' },
        // { field: 'address', label: 'address' },
        // { field: 'billingAddressId', label: 'Billing Address Id' },
        { field: 'ownerId', label: 'Owner Id' },
        { field: 'poolId', label: 'Pool Id' },
        { field: 'userType', label: 'User Type' },
        { field: 'questionnaireSkipped', label: 'Questionnaire Skipped' },
        { field: 'installationOfferApproval', label: 'Installation Offer Approval' },
        { field: 'homeCheckOfferApproval', label: 'Home Check Offer Approval' },
        { field: 'costEstimateMin', label: 'CostEstimateMin', type: 'number' },
        { field: 'costEstimateMax', label: 'Cost Estimate Max', type: 'number' },
        { field: 'lastReminderTypeSent', label: 'Last Reminder Type Sent' },
      ].map((field) => (
        <Form.Item name={field.field} label={field.label} key={field.field}>
          {field.type === 'number' ? <InputNumber /> : <Input />}
        </Form.Item>
      ))}

      <Form.Item name="versionedQuestionnaireId.id" label="Questionnaire id">
        <QuestionnaireEntitySelector
          name="questionnaireId"
          questionnaire={entry?.questionnaire}
          entityService={TendergyQuestionnaireService}
          current={entry?.versionedQuestionnaireId}
          onSelect={(user: ITendergyUser) => console.log('organizationId', user)}
        />
      </Form.Item>

      {/*<Form.Item label="Questionnaire version">{entry?.versionedQuestionnaireId?.version}</Form.Item>*/}

      <Form.Item name="submit">
        <Button type="primary" htmlType="submit" style={{ marginRight: '20px' }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RequestDetailsComponent;
