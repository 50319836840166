import React, { useRef } from 'react';
import { Button, Modal } from 'antd';

import { FormikForm, FormInput } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { IBillingGridwareCreds } from 'services/billing-v3/billing-v3-service.types';

interface GridwareModalProps {
  toggleModal: (state: boolean) => void;
  isModalOpen: boolean;
  sendToGrid: Function;
}

const GridwareModal = (props: GridwareModalProps) => {
  const { toggleModal, isModalOpen, sendToGrid } = props;
  const formRef = useRef();
  const formikProps: IFormikFormProps<IBillingGridwareCreds> = {
    onSubmit: () => {},
    initialValues: {} as IBillingGridwareCreds,
    //@ts-ignore
    innerRef: formRef,
  };

  const footer = [
    <Button key="back" onClick={() => toggleModal(!isModalOpen)}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      onClick={() => {
        //@ts-ignore
        sendToGrid(formRef.current.values);
      }}
    >
      Send to Gridware
    </Button>,
  ];

  return (
    <Modal
      title="Synchronize with Gridware"
      footer={footer}
      visible={isModalOpen}
      onOk={() => toggleModal(!isModalOpen)}
      onCancel={() => toggleModal(!isModalOpen)}
    >
      <FormikForm {...formikProps}>
        <FormInput name="username" label="Username" />
        <FormInput name="password" label="Password" type="password" />
      </FormikForm>
    </Modal>
  );
};

export default GridwareModal;
