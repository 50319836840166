import * as Yup from 'yup';

export const QuestionnaireConfigValidation = {
  pool: Yup.object().nullable(),
  questionKey: Yup.string().nullable(),
  defaultAnswer: Yup.string()
    .test('test-json', 'This field should be a valid JSON', (value) => {
      try {
        // @ts-ignore
        JSON.parse(value);
      } catch (e) {
        return false;
      }
      return true;
    })
    .nullable(),
  possibleAnswers: Yup.string()
    .test('test-json', 'This field should be a valid JSON', (value) => {
      try {
        // @ts-ignore
        JSON.parse(value);
      } catch (e) {
        return false;
      }
      return true;
    })
    .nullable(),
  isDisabled: Yup.boolean().nullable(),
  isVisible: Yup.boolean().nullable(),
};
