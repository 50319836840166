import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, DatePicker, Input, Row, Select, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { Loader } from 'components/ui';
import { DATE_AND_TIME } from 'framework/constants/dateFormats';
import TendergyActivityLogsService from 'services/tendergy/tendergy-activity-logs.service';
import TendergyInstallerService from 'services/tendergy/tendergy-installer.service';
import TendergyOfferService from 'services/tendergy/tendergy-offer.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import TendergyClientRequestsService from 'services/tendergy/tendergy-request.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import {
  ActivityLogsActions,
  ActivityLogsScope,
  ClientRequestActionType,
  IClientRequest,
  InstallersActionType,
  IOffer,
  ITendergyActivityLog,
  ITendergyOrganization,
  ITendergyUser,
  OfferActionType,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

const { RangePicker } = DatePicker;

const ITEMS_PER_PAGE = 10;

const columns = [
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Scope',
    dataIndex: 'scope',
    key: 'scope',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Initiator',
    dataIndex: 'user',
    key: 'user',
    render: (user: ITendergyUser) => (user ? `${user.firstName} ${user.lastName}` : '–'),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Affected Entry',
    dataIndex: 'affectedEntry',
    key: 'affectedEntry',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (id: ActivityLogsScope, row: ITendergyActivityLog) =>
      ({
        [ActivityLogsScope.ClientRequests]: id,
        [ActivityLogsScope.Installers]: id,
        [ActivityLogsScope.Offers]: id,
        [ActivityLogsScope.Organizations]: row.organization?.name,
      }[row.scope as ActivityLogsScope]),
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'timestamp',
    render: (date: string) => (date ? formatDefaultDate(date, DATE_AND_TIME) : '–'),
  },
];

export enum LoadingFields {
  DateRange,
  Action,
  Scope,
  Comment,
  AffectedEntry,
  Initiator,
}

const today = new Date().toISOString();
const yesterday = new Date(Date.now() - 86400000).toISOString();

const ActivityLogsOverviewPage: React.FC = () => {
  const location = useLocation();
  const [logs, setLogs] = useState<ITendergyActivityLog[] | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [fromDate, setFromDate] = useState<string>(yesterday);
  const [toDate, setToDate] = useState<string>(today);
  const [action, setAction] = useState<string | null>(null);
  const [scope, setScope] = useState<ActivityLogsScope | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  const [affectedEntry, setAffectedEntry] = useState<string | null>(null);
  const [initiator, setInitiator] = useState<string | null>(null);
  const [loading, setLoading] = useState<LoadingFields | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setScope(query.get('scope') as ActivityLogsScope);
    setAffectedEntry(query.get('affectedEntry'));
    setInitiator(query.get('initiator'));
  }, [location]);

  const actionTypes = () => {
    switch (scope) {
      case ActivityLogsScope.Installers:
      case ActivityLogsScope.Organizations:
        return InstallersActionType.map((item) => ({ value: item }));
      case ActivityLogsScope.ClientRequests:
        return ClientRequestActionType.map((item) => ({ value: item }));
      case ActivityLogsScope.Offers:
        return OfferActionType.map((item) => ({ value: item }));
      default:
        return Object.keys(ActivityLogsActions).map((item) => ({ value: item }));
    }
  };
  const params: any = {
    fromDate,
    toDate,
    action: action && action,
    scope: scope && scope,
    query: comment && comment,
    initiator: initiator && initiator,
    affectedEntry: affectedEntry && affectedEntry,
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
    sortFields: 'timestamp',
  };

  const getActivityLogs = async () => {
    try {
      const response = await TendergyActivityLogsService.getEntries({ ...params });
      setLoading(null);
      setLogs(response.items);
      setTotal(response.total || 0);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    setPage(1);
    getActivityLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, action, scope, initiator, comment, affectedEntry]);

  useEffect(() => {
    getActivityLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!logs) {
    return <Loader />;
  }

  const dateRangePickerProps = {
    value: [moment(fromDate), moment(toDate)] as any,
    style: { width: '100%' },
    onChange: (dates: any) => {
      setFromDate(dates ? dates[0].toISOString() : today);
      setToDate(dates ? dates[1].toISOString() : today);
      setLoading(LoadingFields.DateRange);
    },
    suffixIcon:
      loading === LoadingFields.DateRange ? <LoadingOutlined spin /> : <CalendarOutlined />,
  };

  const actionSelectProps = {
    placeholder: 'Select Action',
    onSelect: (value: string) => {
      setAction(value);
      setLoading(LoadingFields.Action);
    },
    style: { width: '100%' },
    allowClear: true,
    onClear: () => {
      setAction(null);
      setLoading(LoadingFields.Action);
    },
    options: actionTypes(),
    loading: loading === LoadingFields.Action,
  };

  const initiatorEntitySelectorProps = {
    entityService: TendergyUserService,
    name: 'initiator',
    onSelect: (user: ITendergyUser) => {
      setInitiator(user?.id || null);
      setLoading(LoadingFields.Initiator);
    },
    allowClear: true,
    onClear: () => {
      setInitiator(null);
      setLoading(LoadingFields.Initiator);
    },
    loading: loading === LoadingFields.Initiator,
    value: initiator,
  };

  const scopeSelectProps = {
    placeholder: 'Select Scope',
    onSelect: (value: ActivityLogsScope) => {
      setScope(value);
      setLoading(LoadingFields.Scope);
    },
    style: { width: '100%' },
    allowClear: true,
    onClear: () => {
      setScope(null);
      setAffectedEntry(null);
      setLoading(LoadingFields.Scope);
    },
    options: [
      { value: ActivityLogsScope.Installers, label: 'Installers' },
      { value: ActivityLogsScope.Organizations, label: 'Organizations' },
      { value: ActivityLogsScope.ClientRequests, label: 'ClientRequests' },
      { value: ActivityLogsScope.Offers, label: 'Offers' },
    ],
    loading: loading === LoadingFields.Scope,
    value: scope as ActivityLogsScope,
  };

  const commentInputProps = {
    placeholder: 'Comment...',
    onChange: (event: any) => setComment(event.target.value),
    allowClear: true,
  };

  const baseAffectedEntryProps = {
    name: 'affectedEntry',
    allowClear: true,
    onClear: () => {
      setAffectedEntry(null);
      setLoading(LoadingFields.AffectedEntry);
    },
    loading: loading === LoadingFields.AffectedEntry,
    value: affectedEntry,
  };

  const installerEntitySelectorProps = {
    ...baseAffectedEntryProps,
    entityService: TendergyInstallerService,
    onSelect: (user: ITendergyUser) => {
      setAffectedEntry(user?.id || null);
      setLoading(LoadingFields.AffectedEntry);
    },
  };

  const organizationsEntitySelectorProps = {
    ...baseAffectedEntryProps,
    entityService: TendergyOrganizationService,
    onSelect: (organization: ITendergyOrganization) => {
      setAffectedEntry(organization?.id || null);
      setLoading(LoadingFields.AffectedEntry);
    },
  };

  const offersEntitySelectorProps = {
    ...baseAffectedEntryProps,
    entityService: TendergyOfferService,
    onSelect: (offer: IOffer) => {
      setAffectedEntry(offer?.id || null);
      setLoading(LoadingFields.AffectedEntry);
    },
  };

  const clientRequestsEntitySelectorProps = {
    ...baseAffectedEntryProps,
    entityService: TendergyClientRequestsService,
    onSelect: (clientRequests: IClientRequest) => {
      setAffectedEntry(clientRequests?.id || null);
      setLoading(LoadingFields.AffectedEntry);
    },
  };

  const tableProps = {
    rowKey: 'id',
    columns,
    dataSource: logs,
    pagination: {
      total,
      defaultPageSize: ITEMS_PER_PAGE,
      current: page,
      onChange: (page: number) => setPage(page),
      showSizeChanger: false,
    },
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <RangePicker {...dateRangePickerProps} picker="date" />
        </Col>
        <Col span={6}>
          <Select {...actionSelectProps} />
        </Col>
        <Col span={6}>
          <Input {...commentInputProps} />
        </Col>
        <Col span={6}>
          <EntitySelector {...initiatorEntitySelectorProps} />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Select {...scopeSelectProps} />
        </Col>
        {scope === ActivityLogsScope.Installers ? (
          <Col span={6}>
            <EntitySelector {...installerEntitySelectorProps} />
          </Col>
        ) : null}
        {scope === ActivityLogsScope.Organizations ? (
          <Col span={4}>
            <EntitySelector {...organizationsEntitySelectorProps} />
          </Col>
        ) : null}
        {scope === ActivityLogsScope.Offers ? (
          <Col span={6}>
            <EntitySelector {...offersEntitySelectorProps} />
          </Col>
        ) : null}
        {scope === ActivityLogsScope.ClientRequests ? (
          <Col span={4}>
            <EntitySelector {...clientRequestsEntitySelectorProps} />
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col span={24}>
          <Table {...tableProps} />
        </Col>
      </Row>
    </>
  );
};

export default ActivityLogsOverviewPage;
