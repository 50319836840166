import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IShopProductAttributeOption } from './shop-service.types';

class ProductAttributeOptionsServiceClass extends EntityService<IShopProductAttributeOption> {
  getDisplayName(entry: IShopProductAttributeOption): string {
    return entry && entry.name;
  }
}

const ProductAttributeOptionsService = new ProductAttributeOptionsServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/product-attribute-options',
  createLink: links.shopCreateProductAttributeOption,
  detailLink: links.shopProductAttributeOption,
});

export default ProductAttributeOptionsService;
