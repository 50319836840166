import * as React from 'react';
import { Descriptions } from 'antd';

import { ITendergyUser } from 'store/tendegyService/tendergyService.types';

interface Props {
  installerUser?: ITendergyUser;
}

/**
 * Renders the installer
 *
 * @param param0
 */
const OfferInstallerComponent: React.FC<Props> = ({ installerUser }: Props) => {
  if (!installerUser) {
    return <div> User information loading.. </div>;
  }

  const renderInstaller = () => (
    <Descriptions title="Installer Contact" column={1} layout="horizontal">
      <Descriptions.Item label="UserName">
        {installerUser.firstName} {installerUser.lastName}
      </Descriptions.Item>
      <Descriptions.Item label="E-Mail">{installerUser.email}</Descriptions.Item>
      <Descriptions.Item label="Phone">
        {installerUser.phone || installerUser.installer?.mobile}
      </Descriptions.Item>
    </Descriptions>
  );

  return <div>{renderInstaller()}</div>;
};

export default OfferInstallerComponent;
