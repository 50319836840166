import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { IISACarModel } from 'services/isa-service/isa-service.types';

interface IISACarModelsTabProps {
  carModels: IISACarModel[];
}

export default function ISACarModelsTab(props: IISACarModelsTabProps) {
  const { carModels } = props;
  const history = useHistory();

  function handleRowClick(value: IISACarModel) {
    return () => history.push(generatePath(links.isaCarModelsDetails, { id: value.id }));
  }

  return (
    <Table
      columns={[
        { title: 'Car Model Title', dataIndex: 'name' },
        { title: 'Logical Name', dataIndex: 'logicalName' },
      ]}
      rowKey="id"
      dataSource={carModels}
      onRow={(value) => ({ onClick: handleRowClick(value) })}
    />
  );
}
