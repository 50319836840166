import React, { useState } from 'react';
import { Col, message, Modal } from 'antd';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { Loader } from 'components/ui';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import {
  ITendergyQuestionnaire,
  QuestionnaireType,
} from 'store/tendegyService/tendergyService.types';

import { QuestionnaireValidation } from '../questionnaire.validation';

interface IProps {
  entry: ITendergyQuestionnaire;
  onSave: (step: ITendergyQuestionnaire, callback: Function) => void;
}

const QuestionnaireOverviewTab: React.FC<IProps> = (props: IProps) => {
  const { entry, onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const validation = Yup.object().shape(QuestionnaireValidation);

  const onOk = (values: ITendergyQuestionnaire, setSubmitting: Function) => {
    let questionnaireData = { ...values };
    if (values.isDefault) questionnaireData.pools = [];
    onSave && onSave(questionnaireData, () => message.success('Changes are successfully saved'));
    setVisible(false);
    setSubmitting(false);
  };

  const onCancel = (setSubmitting: Function, resetForm: Function) => {
    setVisible(false);
    setSubmitting(false);
    resetForm();
  };

  const handleFormSubmit = () => {
    setVisible(true);
  };

  if (!props) {
    return <div>data entry is missing</div>;
  }

  if (!entry) {
    return <Loader />;
  }

  if (!onSave) {
    return <div> require save method </div>;
  }

  const poolsField = (values: ITendergyQuestionnaire) =>
    values.questionnaireType === QuestionnaireType.Request ? 'pools' : 'surveyPools';

  return (
    <div>
      <Col span={12}>
        <Formik
          initialValues={entry as ITendergyQuestionnaire}
          onSubmit={handleFormSubmit}
          validationSchema={validation}
        >
          {({ handleSubmit, values, setSubmitting, resetForm, setFieldValue }) => (
            <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
              <Form.Item name="name" label="Name">
                <Input name="name" />
              </Form.Item>
              <Form.Item
                name="isDefault"
                label="Default"
                tooltip="Default will be used for all the pools without a private questionnaire"
              >
                <Checkbox disabled={entry.isDefault} name="isDefault" />
              </Form.Item>
              <Form.Item name="questionnaireType" label="Questionnaire Type">
                <Select name="questionnaireType">
                  <Select.Option value={QuestionnaireType.Request}>Request</Select.Option>
                  <Select.Option value={QuestionnaireType.Survey}>Survey</Select.Option>
                </Select>
              </Form.Item>
              {!values.isDefault && (
                <Form.Item
                  name={poolsField(values)}
                  label="Pools"
                  tooltip="Select the pools that should use this questionnaire"
                >
                  <EntitySelector
                    current={entry[poolsField(values)]}
                    onSelect={(pools) => setFieldValue(poolsField(values), pools)}
                    entityService={ClientRequestPoolService}
                    name={poolsField(values)}
                    mode="multiple"
                  />
                </Form.Item>
              )}
              <Form.Item name="clientrequests" label="Client requests">
                <span>{entry.clientRequests?.length}</span>
              </Form.Item>
              <Form.Item {...CrudFormDefaultActionParams} name="submit">
                <SubmitButton disabled={!!entry?.isDefault}>Submit</SubmitButton>
              </Form.Item>
              <Modal
                title="CONFIRM ACTION"
                visible={visible}
                onOk={() => onOk(values, setSubmitting)}
                onCancel={() => onCancel(setSubmitting, resetForm)}
              >
                <p>Are you sure you want apply the changes?</p>
              </Modal>
            </Form>
          )}
        </Formik>
      </Col>
    </div>
  );
};

export default QuestionnaireOverviewTab;
