import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ISAAddressesService } from 'services/isa-service';
import { formatDefaultDate } from 'utils/date.helper';

export default function ISAAddressesOverview() {
  return (
    <CrudListViewContainer
      columns={[
        {
          title: 'Full Address',
          dataIndex: 'city',
          render: (_data, entry) =>
            `${entry.city} ${entry.postalCode} ${entry.street} ${entry.doorNumber}`,
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          render: (date: string) => formatDefaultDate(date),
        },
      ]}
      entityName="addresses"
      resource={ISAAddressesService}
    />
  );
}
