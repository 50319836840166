import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISADiagnosticStepsService } from 'services/isa-service';

import ISADiagnosticStepsForm from './components/ISADiagnosticStepsForm';

export default function ISADiagnosticStepsCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Diagnostic Step',
          render: (_entry, onSubmit) => <ISADiagnosticStepsForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="diagnostic step"
      baseLink={links.isaDiagnosticStepsOverview}
      resource={ISADiagnosticStepsService}
    />
  );
}
