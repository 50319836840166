import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import CommentsService from 'services/tendergy/tendergy-comments.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import { CommentStates, ITendergyComment } from 'store/tendegyService/tendergyService.types';

import { CommentValidation } from '../../comments.validation';

interface Props {
  entry: ITendergyComment;
  onSubmitStep: (entry: ITendergyComment) => void;
}

const CommentBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(CommentValidation);

  return (
    <div>
      <Formik
        initialValues={entry as ITendergyComment}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="text" label="Text">
              <Input name="text" placeholder="" />
            </Form.Item>
            <Form.Item name="state" label="State">
              <Select name="state">
                <Select.Option value={CommentStates.Pending}>Pending</Select.Option>
                <Select.Option value={CommentStates.Resolved}>Resolved</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="author" label="Author">
              <EntitySelector
                entityService={TendergyUserService}
                name="author"
                current={entry.author}
                onSelect={(user) => {
                  setFieldValue('author', user);
                  setFieldValue('authorId', user.id);
                }}
              />
            </Form.Item>

            <Form.Item name="commentableType" label="Commentable Type">
              <Input name="commentableType" />
            </Form.Item>

            <Form.Item name="commentableId" label="Commentable ID">
              <Input name="commentableId" />
            </Form.Item>

            <Form.Item name="parentComment" label="Parent Comment">
              <EntitySelector
                entityService={CommentsService}
                name="parentComment"
                current={entry.parentComment}
                onSelect={(comment) => {
                  setFieldValue('parentComment', comment);
                  setFieldValue('parentCommentId', comment.id);
                }}
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CommentBaseStep;
