import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Typography } from 'antd';

import Tabs from 'components/layout/Tabs';
import { Loader } from 'components/ui';
import DetailviewJsonTab from 'framework/abstract-crud/components/detailview-json-tab';
import links from 'framework/links';
import AuthPermissionService from 'services/auth/permission.service';
import AuthRoleService from 'services/auth/role.service';
import { IAuthPermission, IAuthRole } from 'store/authService/authService.types';
import { lastOpenedPageSelector } from 'store/common/common.selectors';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import RoleActionsComponent from './components/RoleActionsComponent/RoleActionsComponent';
import RoleOverviewTab from './tabs/RoleOverviewTab/RoleOverviewTab';

import styles from './RoleDetailsPage.module.sass';

const RoleDetailsPages = Object.freeze({
  JSON: 'json',
  OVERVIEW: 'overview',
});

const RoleDetailsPageNames = Object.freeze({
  [RoleDetailsPages.OVERVIEW]: 'Overview',
  [RoleDetailsPages.JSON]: 'JSON',
});

const RoleDetails: React.FC = () => {
  const history = useHistory();
  const { id, page } = useParams<{ id: string; page: string }>();
  const activePage = page || RoleDetailsPages.OVERVIEW;

  const [authRole, setAuthRole] = useState<IAuthRole>();
  const [authPermissions, setAuthPermissions] = useState<IAuthPermission[]>([]);

  const lastOpenedPage = useSelector(lastOpenedPageSelector);

  const getRolesData = async () => {
    try {
      const response = await AuthRoleService.getById({ id });
      setAuthRole(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const getPermissionsData = async () => {
    try {
      const response = await AuthPermissionService.getEntries();
      setAuthPermissions(response.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const saveAuthData = async (updatedValues: IAuthRole) => {
    try {
      await AuthRoleService.updatePermissions(
        authRole!.id!,
        updatedValues.permissions!.map((item) => item.id!),
      );
      getRolesData();
      message.success('Permissions successfully updated');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const deleteRole = async () => {
    const payload = { id };
    try {
      await AuthRoleService.deleteById(payload);
      showSuccessNotification('Role successfully removed');
      // @ts-ignore
      history.goBack();
    } catch (e) {
      showErrorNotification(e);
      console.error(e);
    }
  };

  useEffect(() => {
    getRolesData();
    getPermissionsData();
  }, [id]); //eslint-disable-line

  const pagesToRender = { ...RoleDetailsPageNames };

  const renderContent = () => {
    switch (activePage) {
      case RoleDetailsPages.JSON:
        return <DetailviewJsonTab entry={authRole} />;
      case RoleDetailsPages.OVERVIEW:
        return (
          <RoleOverviewTab entry={authRole} onSave={saveAuthData} permissions={authPermissions} />
        );
      default:
        return null;
    }
  };

  const renderTabs = () => {
    return (
      <Tabs
        activeTitle="Details of Role"
        activePageKey={activePage}
        baseRoute={{
          link: links.authRole,
          params: { id },
        }}
        contentMinHeight="calc(100vh - 280px)"
        pages={pagesToRender}
      >
        {renderContent()}
      </Tabs>
    );
  };

  const renderActions = () => <RoleActionsComponent entry={authRole} onDelete={deleteRole} />;

  const goBack = () => {
    if (lastOpenedPage) {
      history.push(lastOpenedPage);
    } else {
      // @ts-ignore
      history.push(links.authRoles);
    }
  };

  if (!authRole) {
    return <Loader />;
  }

  return (
    <Row gutter={16}>
      <Col span={18}>
        <Button type="link" className={styles.backButton} onClick={goBack}>
          <ArrowLeftOutlined />
          <span>Back</span>
        </Button>
        <Typography.Title level={3} className={styles.title}>
          {authRole.name}
        </Typography.Title>
        {renderTabs()}
      </Col>
      <Col span={6}>{renderActions()}</Col>
    </Row>
  );
};

export default RoleDetails;
