import React, { ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Steps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import EntityService from 'services/abstract/entity.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { showErrorNotification } from 'utils/notificationUtil';

import ErrorStep from './components/errorStep';
import SuccessStep from './components/successStep';

import styles from './abstract-crud.module.sass';

const { Sider, Content } = Layout;

export type AntTableColumnsType = ColumnsType<any>;

export interface ICrudDetailViewProps<T> {
  entityName: string;
  resource: EntityService<any>;
  renderTitle?: (entry: any) => ReactElement;
  defaultPayload?: IGetSingleEntityPayload;
  defaultValues?: T;
  baseLink: string;
  steps: ICrudStep[];
  onSuccess?: (entry: T) => void;
}

type TCrudDetailViewProps<T> = ICrudDetailViewProps<T> & RouteComponentProps;

interface ICrudDetailState {
  entry: any; // Record
  currentStep: number;
  successPage: boolean;
  error: any;
  responseEntry: any;
}

export interface ICrudStep {
  name: string;
  description?: string;
  render: (entry: any, onChange: any) => ReactElement;
}

/** **
 * @docs:
 *  This container is made to provice a multistep creation page to add
 *  a new entity.
 * <CrudCreateWizardContainer steps>
 * </CrudCreateWizardContainer>
 *
 * Plase see createInvitationLinkPage.tsx for example!
 *
 * TODO:
 *
 */
class CrudCreateWizardContainer extends React.Component<
  TCrudDetailViewProps<any>,
  ICrudDetailState
> {
  constructor(props: TCrudDetailViewProps<any>) {
    super(props);
    this.state = {
      error: null,
      entry: props.defaultValues || {},
      currentStep: 0,
      successPage: false,
      responseEntry: null,
    };
  }

  onSubmitStep(e: any) {
    const { entry } = this.state;
    const newEntry = { ...entry, ...e };
    this.setState({ entry: newEntry });
    this.navigateNextStep();
  }

  //  the way to find the dispaly id has to be provided by parameter..
  defaultDisplayName(entry: any) {
    if (!entry) {
      return 'n/a';
    }
    const title = entry.name || entry.displayId || entry.id || 'n/a';
    return <Typography.Title level={3}>{title}</Typography.Title>;
  }

  navigateNextStep() {
    const { currentStep } = this.state;
    const { steps } = this.props;
    const totalSteps = steps.length;
    const isLastStep = currentStep + 1 === totalSteps;
    if (isLastStep) {
      console.log('islast');
      this.saveEntity();
      return;
    }
    this.setState({ currentStep: currentStep + 1 });
  }

  navigateLastStep() {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep - 1 });
  }

  navigateToStep(step: number) {
    this.setState({ currentStep: step });
  }
  handleError(e: any) {
    showErrorNotification(e);
    this.setState({ error: e });
    return;
  }

  async saveEntity() {
    const { resource } = this.props;
    const { entry } = this.state;
    let responseEntry;
    try {
      responseEntry = await resource.save(entry);
      if (responseEntry.status && responseEntry.status !== 201) {
        throw new Error(responseEntry.message);
      }
    } catch (e) {
      this.handleError(e);
    }
    const { onSuccess } = this.props;
    onSuccess && onSuccess(responseEntry);
    this.setState({ successPage: true, responseEntry });
  }

  renderNavigation() {
    const { currentStep } = this.state;
    const { steps } = this.props;

    const backButton = () => (
      <Button shape="round" onClick={() => this.navigateLastStep()}>
        <ArrowLeftOutlined />
        <span>Back</span>
      </Button>
    );

    const totalSteps = steps.length;

    return totalSteps > 1 ? (
      <div>
        <Row>
          <Col span={8}>{currentStep > 0 ? backButton() : ''}</Col>
          <Col span={8}>
            {currentStep + 1} / {totalSteps}
          </Col>
        </Row>
      </div>
    ) : null;
  }

  renderSidebar() {
    const { currentStep } = this.state;
    const { steps } = this.props;
    return (
      <Steps
        current={currentStep}
        onChange={(step) => this.navigateToStep(step)}
        direction="vertical"
      >
        {steps.map((step) => (
          <Steps.Step key={step.name} title={step.name} description={step.description} />
        ))}
      </Steps>
    );
  }

  renderContent() {
    const { steps, match } = this.props;
    const params = match.params as any;
    delete params.page;
    const { currentStep, entry } = this.state;
    const currentStepElement = steps[currentStep];
    return (
      <div>
        {currentStepElement
          ? currentStepElement.render(entry, (e: any) => this.onSubmitStep(e))
          : 'unkown step'}
      </div>
    );
  }

  render() {
    const { entry, successPage, error } = this.state;
    const { entityName, renderTitle, history } = this.props;

    const title = renderTitle ? renderTitle(entry) : this.defaultDisplayName(entry);

    return (
      <div>
        {/* @ts-ignore */}
        <Button type="link" onClick={() => history.goBack()}>
          <ArrowLeftOutlined />
          <span>Back</span>
          {title}
        </Button>
        {error ? <ErrorStep error={error} onDismiss={() => this.setState({ error: null })} /> : ''}
        {successPage ? (
          <SuccessStep title={`Saved ${entityName} successfully`} />
        ) : (
          <Layout className="site-layout-background">
            <Sider theme="light" width={250}>
              <Content className={styles.sidebar}>{this.renderSidebar()}</Content>
            </Sider>
            <Content className={styles.contentWrapper}>
              {this.renderContent()}
              {this.renderNavigation()}
            </Content>
          </Layout>
        )}
      </div>
    );
  }
}

export default withRouter(CrudCreateWizardContainer);
