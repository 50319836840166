import * as React from 'react';

import { IBillingImport } from 'services/billing-v2/billing-v2-service.types';

interface IImportsDetailsTabProps {
  document: IBillingImport;
}

const ImportsDetailsTab: React.FC<IImportsDetailsTabProps> = (props: IImportsDetailsTabProps) => {
  const { document } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  return <pre>{JSON.stringify(document, null, 2)}</pre>;
};

export default ImportsDetailsTab;
