import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISAMappingService } from 'services/isa-service';

import ISAMappingForm from './components/ISAMappingForm';

export default function ISAMappingCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Mapping',
          render: (_entry, onSubmit) => <ISAMappingForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="mapping"
      baseLink={links.isaMappingOverview}
      resource={ISAMappingService}
    />
  );
}
