import produce from 'immer';

import {
  RESET,
  SET_CRAFTUSER,
  SET_LOADING_STATUS,
  SET_USER_AUTH,
  SET_USERS,
  UserActionTypes,
  UserState,
} from './user.types';

const initialState: UserState = {
  data: null,
  loading: false,
  usersData: null,
  userAuthData: null,
};

const UserReducer = (state = initialState, action: UserActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CRAFTUSER:
        draft.data = action.payload;
        break;
      case SET_LOADING_STATUS:
        draft.loading = action.payload;
        break;
      case SET_USERS:
        draft.usersData = action.payload.items;
        break;
      case SET_USER_AUTH:
        draft.userAuthData = action.payload;
        break;
      case RESET:
        draft.data = initialState.data;
        draft.loading = initialState.loading;
        break;
    }
  });

export default UserReducer;
