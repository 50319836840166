import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import { IAuthOrganization, IOrganizationMember } from '../authService.types';

export const GET = 'auth_organization/GET';
export const GET_MEMBERS = 'auth_organization/GET_MEMBERS';
export const SET = 'auth_organization/SET';
export const SET_MEMBERS = 'auth_organization/SET_MEMBERS';

export const SET_LOADING_STATUS = 'auth_organization/SET_LOADING_STATUS';
export const RESET = 'auth_organization/RESET';

export interface OrganizationState {
  entry: IAuthOrganization | null;
  pagination: null;
  loading: boolean;
  members: IOrganizationMember[];
}

export interface AuthOrganizationMember {
  createdAt: string;
  deletedDate: string | null;
  email: string;
  emailVerified: boolean;
  firstName: string;
  id: string;
  lastName: string;
  updatedAt: string;
}

export interface GetOrganizationAction {
  type: typeof GET;
  payload: IGetSingleEntityPayload;
}

export interface GetMembersAction {
  type: typeof GET_MEMBERS;
  payload: string;
}

export interface SetMembersAction {
  type: typeof SET_MEMBERS;
  payload: AuthOrganizationMember[];
}

export interface SetOrganizationAction {
  type: typeof SET;
  payload: IAuthOrganization;
}

export interface SetLoadingStatusAction {
  type: typeof SET_LOADING_STATUS;
  payload: boolean;
}

export interface ResetAction {
  type: typeof RESET;
}

export type OrganizationActionTypes =
  | GetOrganizationAction
  | SetOrganizationAction
  | SetLoadingStatusAction
  | ResetAction
  | GetMembersAction
  | SetMembersAction;
