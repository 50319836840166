import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { ITendergyFeedback } from 'store/tendegyService/tendergyService.types';

class TendergyHistoryLogClass extends EntityService<ITendergyFeedback> {
  getDisplayName(entry: any): string {
    return entry.name;
  }

  getAuditHistory = ({ tableName, entityId }: { tableName: string; entityId: string }) =>
    this.get(`${this.config.entityURL}`, { tableName, entityId });
}

const TendergyHistoryLogService = new TendergyHistoryLogClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/audit',
});

export default TendergyHistoryLogService;
