import * as React from 'react';

const DetailviewJsonTab: React.FC<any> = (props: any) => {
  const { entry } = props;
  if (!props || !entry) {
    return <div>data entry is missing</div>;
  }
  return (
    <div>
      <p>
        The following data is formatted as JSON (Javascript Object notation) and will be used if
        something is unclear or not shown correctly.
      </p>
      <pre>{JSON.stringify(entry, null, 2)}</pre>
    </div>
  );
};

export default DetailviewJsonTab;
