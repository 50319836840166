import * as React from 'react';
import { Form, FormItemProps, Rate, RateProps } from 'formik-antd';

export interface IFormRateProps extends Partial<FormItemProps & RateProps> {
  name: string;
}

function FormRate(props: IFormRateProps) {
  const { name, ...rest } = props;

  return (
    <Form.Item name={name} {...rest}>
      <Rate name={name} />
    </Form.Item>
  );
}

export default FormRate;
