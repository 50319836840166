import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import CategoryService from 'services/shop/shop-categories.service';
import { IShopCategory, IShopStore } from 'services/shop/shop-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IShopCategory> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Store',
    dataIndex: 'store',
    key: 'store',
    render: (value: IShopStore) => value.name,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const params: IGetMultiEntityPayload = {
  relations: ['store'],
};

const CategoriesOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Categories"
    columns={columns}
    defaultPayload={params}
    resource={CategoryService}
  />
);
export default CategoriesOverviewPage;
