import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import CategoryService from 'services/shop/shop-categories.service';
import { IShopCategory, IShopStore } from 'services/shop/shop-service.types';
import StoreService from 'services/shop/shop-stores.service';

import { CategoryValidation } from '../../category.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const CategoryOverviewTab: React.FC<IGenericEditTabProps<IShopCategory>> = (
  props: IGenericEditTabProps<IShopCategory>,
) => {
  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(CategoryValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IShopCategory}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name" required>
              <Input name="name" placeholder="" />
            </Form.Item>

            <Form.Item name="store" label="Store" required>
              <EntitySelector
                entityService={StoreService}
                name="store"
                current={entry.store}
                onSelect={(store: IShopStore) => setFieldValue('store', store)}
              />
            </Form.Item>

            <Form.Item name="parent" label="Parent category">
              <EntitySelector
                entityService={CategoryService}
                name="parent"
                // @ts-ignore
                defaultPayload={{ storeId: entry?.store?.id }}
                current={entry.parent}
                onSelect={(parent: IShopStore) => setFieldValue('parent', parent)}
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CategoryOverviewTab;
