import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Select } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { OfferState } from 'framework/constants';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import IntermediatesService from 'services/tendergy/tendergy-intermediates.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import TendergyRequestService from 'services/tendergy/tendergy-request.service';
import {
  IClientRequest,
  ITendergyClientRequestPool,
  ITendergyIntermediate,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';

export interface IOffersFilters {
  query: null | string;
  state: null | string;
  poolId: null | string;
  intermediateOrganization: null | string;
  organizationId: null | string;
  clientRequestId: null | string;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const OffersFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IOffersFilters>({
    query: null,
    state: null,
    poolId: null,
    intermediateOrganization: null,
    organizationId: null,
    clientRequestId: null,
  });

  useEffect(() => {
    onFilter && onFilter(value);
  }, [value]); // eslint-disable-line

  const offerStateProps = {
    placeholder: 'Select State',
    onChange: (state: string) => {
      setValue({ ...value, state });
    },
    style: { width: '100%' },
    allowClear: true,
    options: Object.entries(OfferState).map(([key, value]) => ({ value, label: key })),
  };

  const queryInputProps = {
    placeholder: 'Search by id...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };

  const poolEntitySelectorProps = {
    name: 'pools',
    allowClear: true,
    value: value.poolId,
    style: { width: '100%' },
    entityService: ClientRequestPoolService,
    onSelect: (pool: ITendergyClientRequestPool) => {
      setValue({ ...value, poolId: pool?.id || null });
    },
  };

  const intermediateOrganizationEntitySelectorProps = {
    name: 'intermediateOrganization',
    allowClear: true,
    value: value.intermediateOrganization,
    style: { width: '100%' },
    entityService: IntermediatesService,
    onSelect: (intermediate: ITendergyIntermediate) => {
      setValue({ ...value, intermediateOrganization: intermediate?.id || null });
    },
  };

  const installerOrganizationEntitySelectorProps = {
    placeholder: 'Select Installer Organization',
    name: 'installerOrganization',
    allowClear: true,
    value: value.organizationId,
    style: { width: '100%' },
    entityService: TendergyOrganizationService,
    onSelect: (organization: ITendergyOrganization) => {
      setValue({ ...value, organizationId: organization?.id || null });
    },
  };

  const clientRequestEntitySelectorProps = {
    placeholder: 'Select Client Request',
    name: 'clientRequest',
    allowClear: true,
    value: value.clientRequestId,
    style: { width: '100%' },
    entityService: TendergyRequestService,
    onSelect: (clientRequest: IClientRequest) => {
      setValue({ ...value, clientRequestId: clientRequest?.id || null });
    },
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col span={4}>
        <Select {...offerStateProps} />
      </Col>
      <Col span={4}>
        <Input {...queryInputProps} />
      </Col>
      <Col span={4}>
        <EntitySelector {...poolEntitySelectorProps} />
      </Col>
      <Col span={4}>
        <EntitySelector {...intermediateOrganizationEntitySelectorProps} />
      </Col>
      <Col span={4}>
        <EntitySelector {...installerOrganizationEntitySelectorProps} />
      </Col>
      <Col span={4}>
        <EntitySelector {...clientRequestEntitySelectorProps} />
      </Col>
    </Row>
  );
};

export default OffersFilters;
