import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IB } from 'pages/billing-v3/charging-stations/constants';
import CommentsService from 'services/tendergy/tendergy-comments.service';
import { ITendergyComment, ITendergyUser } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: IB<ITendergyComment>[] = [
  {
    title: 'Сommentable Type',
    dataIndex: 'commentableType',
    key: 'commentableType',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    sortParameter: 'author.firstName',
    render: (author: ITendergyUser) =>
      author?.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : '-',
  },
  {
    title: 'Author Email',
    dataIndex: 'author',
    key: 'author',
    sorter: true,
    sortParameter: 'author.email',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (author: ITendergyUser) => (author?.email ? author.email : '-'),
  },
  {
    title: 'State',
    dataIndex: 'state',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'state',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const CommentsOverviewPage: React.FC = () => (
  <CrudListViewContainer entityName="Comments" columns={columns} resource={CommentsService} />
);
export default CommentsOverviewPage;
