import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import ProductModelService from 'services/tendergy/tendergy-product.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { IProduct, IProductModel } from 'store/tendegyService/tendergyService.types';

import ProductActionsComponent from './components/ProductActionsComponent/ProductActionsComponent';
import ProductOverviewTab from './tabs/ProductOverviewTab';

const ProductDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['vendor'],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IProduct, optionalProps?: ICrudDetailViewCallbacks) => (
        <ProductOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  const renderActions = (entry: IProductModel, triggerReload: Function) => (
    <ProductActionsComponent entry={entry} triggerReload={triggerReload} />
  );

  return (
    <CrudDetailContainer
      entityName="Product"
      tabs={tabs}
      baseLink={links.productModel}
      defaultPayload={params}
      renderActions={renderActions}
      resource={ProductModelService}
    />
  );
};

export default ProductDetailsPage;
