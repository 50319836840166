import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import fileDownload from 'js-file-download';

import {
  AUTH_TOKEN_KEY,
  FINGERPRINT,
  REFRESH_TOKEN_EXPIRES_KEY,
  REFRESH_TOKEN_KEY,
} from 'framework/constants';
import links from 'framework/links';
import { redirect } from 'utils/common-utils';

import * as appConfig from '../../config';

export interface IRequestParams {
  url: string;
  method?: Method;
  headers?: any;
  data?: any;
  params?: any;
  auth?: boolean;
  query?: any;
}

export interface IHttpConfig {
  name?: string;
  apiURL?: string;
  headers?: any;

  // entity service config; should be separted...
  entityURL?: string;
  detailLink?: string;
  createLink?: string;
  overviewLink?: string;
  saveURL?: string;
  entityPrimaryKey?: string;
  method?: Method;
  oneToManyRelation?: string;
  additionalPathname?: string;
}

/**
 * @todo: I don't like this logic, rather messy.
 * I think everything should be handled by the axios.client()
 * instead of copying data arround.
 *
 */
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
class HttpService<T> {
  config: IHttpConfig = {
    apiURL: '',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };
  client = axios.create({ ...this.config });

  constructor(params: IHttpConfig) {
    this.config = { ...this.config, ...params };
  }

  getHeaders = (headers?: any, auth?: boolean): any => {
    console.log('in headr', this.client.interceptors.response);
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    const headersObj =
      token && auth ? { ...headers, Authorization: `Bearer ${token}` } : { ...headers };
    return { ...this.config.headers, ...headersObj };
  };

  getURL = (url: string) => `${this.config.apiURL}/${url}`;

  getEntityUrl = () => `${this.config.apiURL}/${this.config.entityURL}`;

  downloadFile = async (url: string, params: any, filename?: string) => {
    const headers = this.getHeaders({}, true);
    const res = await this.client.request({
      url: url,
      params,
      responseType: 'blob',
      headers,
    });

    fileDownload(res.data, filename || 'test');
  };

  request = (params: IRequestParams) => {
    const headers = this.getHeaders(params.headers, params.auth);
    const url = this.getURL(params.url);
    const requestParams = {
      url,
      headers,
      method: params.method,
      data: params.data,
      params: params.params,
      query: params.query,
    };
    this.client.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);
        if (window.location.pathname !== '/login' && !accessToken) {
          redirect(links.login);
        }
        return config;
      },

      (error) => {
        return Promise.reject(error);
      },
    );
    this.client.interceptors.response.use(
      (response: AxiosResponse<any>) => {
        return response;
      },
      (error: any) => {
        console.log('in err');
        const { config } = error;

        const originalRequest = error.config;
        if (
          error.response?.status === 401 &&
          !config.url?.includes('login') &&
          !originalRequest._retry
        ) {
          if (isRefreshing) {
            localStorage.setItem(AUTH_TOKEN_KEY, '');
            localStorage.setItem('refreshToken', '');
            if (error.config?.url.includes('refresh')) {
              // Refresh Token is expired too.
              window.location.href = '/login';
              return;
            }

            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then(() => {
                const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);

                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }
          if (!isRefreshing) {
            isRefreshing = true;
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
            const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);
            const fingerprint = localStorage.getItem(FINGERPRINT);
            return new Promise((resolve, reject) => {
              this.client
                .post(
                  `${appConfig.default.AUTH_SERVICE_URL}/auth/refresh`,
                  {
                    refreshToken: refreshToken,
                    fingerprint: fingerprint,
                    context: 'context',
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                  },
                )
                .then((res) => {
                  localStorage.setItem(AUTH_TOKEN_KEY, res.data.data.accessToken);
                  localStorage.setItem(
                    REFRESH_TOKEN_KEY,
                    res.data.data.refreshSession.refreshToken,
                  );
                  localStorage.setItem(
                    REFRESH_TOKEN_EXPIRES_KEY,
                    res.data.data.refreshSession.expires,
                  );
                  this.client.get(`${appConfig.default.USER_SERVICE_URL}/users/profile/acl`, {
                    headers: {
                      Authorization: `Bearer ${res.data.data.accessToken}`,
                    },
                  });
                  originalRequest.headers.Authorization = `Bearer ${res.data.data.accessToken}`;
                  this.client.defaults.headers.common.Authorization = `Bearer ${res.data.data.accessToken}`;
                  processQueue(null, res.data.data.accessToken);
                  resolve(axios(originalRequest));
                })
                .catch((err) => {
                  processQueue(err, null);
                  reject(err);
                })
                .then(() => {
                  isRefreshing = false;
                });
            });
          }

          return Promise.reject(error);
        }
        return error.response;
      },
    );

    return this.client
      .request(requestParams)
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((error: AxiosError<any>) => {
        const { response } = error;

        if (error?.response?.status === 403) {
          redirect(links.forbidden);
        }
        if (!response?.config.url?.includes('login') && response?.status === 401)
          if (
            error?.response?.data?.code !== 'TENDERGY_MEMBERSHIP_NOT_FOUND' &&
            error?.response?.data?.code !== 'ENOREFRESHTOKEN'
          ) {
            // showErrorNotification(error);
            throw error;
          }
      });
  };

  // Since most of requests in the app are user private
  // we make auth = true by default.
  post = (url: string, data?: any, auth: boolean = true, headers?: any) =>
    this.request({
      url,
      data,
      auth,
      method: 'post',
      headers,
    });

  put = (url: string, data?: any, auth: boolean = true) =>
    this.request({
      url,
      data,
      auth,
      method: 'put',
    });

  patch = (url: string, data?: any, auth: boolean = true) =>
    this.request({
      url,
      data,
      auth,
      method: 'patch',
    });

  get = (url: string, data?: any, auth: boolean = true, query?: any) =>
    this.request({
      url,
      data,
      query,
      params: data,
      auth,
      method: 'get',
    });

  delete = (url: string, data?: any, auth: boolean = true) =>
    this.request({
      url,
      data,
      auth,
      method: 'delete',
    });
}

export default HttpService;
