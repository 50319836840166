import { UploadProps } from 'antd/lib/upload';
import { Method } from 'axios';

import { createLink } from '../../framework/utils/links';
import {
  IDeleteEntityPayload,
  IGetDocumentsListPayload,
  IGetFileLinkPayload,
  IGetFileLinkResponse,
  IGetMultiEntityPayload,
  IGetSingleEntityPayload,
  IOfferDocument,
  IUploadFilePayload,
} from '../../store/storeInterfaces';
import HttpService from './http.service';

export interface IRequestParams {
  url: string;
  method?: Method;
  headers?: any;
  data?: any;
  params?: any;
  auth?: boolean;
  query?: any;
}

export interface IListResponse<T> {
  count: number;
  total?: number;
  averageRating?: number;
  items: T[];
}

export interface IFileUploadOptions {
  url: string;
  name?: string;
  action?: string;
}

/**
 * @todo: I don't like this logic, rather messy.
 * I think everything should be handled by the axios.client()
 * instead of copying data arround.
 *
 */
class EntityService<T> extends HttpService<T> {
  getName = (): string => this.config.name || 'n/a';
  handleOneToManyRelation = (relation: string, requestPayload: any) => {
    const bracketReg = /({.*?})/;
    const splitRelation: string[] = relation?.split(bracketReg);
    if (splitRelation) {
      const handleFilter = (b: string) => {
        return b.includes('{');
      };
      if (splitRelation.length < 2) {
        throw new Error('There is no curly bracket in oneToManyRelation method ');
      } else {
        for (let [index, bracket] of splitRelation.entries()) {
          const isRelation = handleFilter(bracket);
          const relationB: any = isRelation && bracket.replace(/[{}]/g, '');

          if (isRelation && (!requestPayload[relationB! as any] as any)) {
            // throw new Error('There no entity same as your added relation in request payload.');
          }
          splitRelation[index] = requestPayload[relationB] || bracket;
        }
        return splitRelation.join('');
      }
    }
  };
  handleMethod = (method: Method, url: string, data: any) => {
    switch (method.toLowerCase()) {
      case 'post':
        return this.post(url, { ...data });
      case 'put':
        return this.put(url, { ...data });
      case 'patch':
        return this.patch(url, { ...data });
    }
  };

  /**
   * @override
   * @param entry
   * @param secondEntry (for questionnaire we have to fields)
   */
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  getDisplayName(entry: T, secondEntry?: T): string {
    throw new Error(
      'please override getDisplayName() in specific entityService to make this method work. ' +
        'Please check the stracktrace for details',
    );
  }

  getDetailLink(entry: T): string | null {
    const id = this.config.entityPrimaryKey
      ? (entry as any)?.[this.config.entityPrimaryKey]
      : (entry as any)?.id;
    const version = (entry as any)?.version;
    if (!id) {
      return null;
    }
    if (!this.config.detailLink) {
      console.warn(
        '[EntityService] Requires detailLink to be set in constructor parameter, please' +
          ' check if you passed this config. Now the automatic link generation will not work. ' +
          'See here the current config of this entity:',
        this.config,
      );
      return null;
    }
    return createLink(this.config.detailLink, { id, version });
  }

  getCreateLink(id: string): string | null {
    if (!this.config.createLink) {
      return null;
    }
    if (id) {
      return createLink(this.config.createLink, { id });
    }
    return createLink(this.config.createLink);
  }

  getEntries = async (requestPayload?: IGetMultiEntityPayload): Promise<IListResponse<T>> => {
    if (!this.config.entityURL) {
      throw new Error('HttpService: entityUrl needs to be set in constructor()');
    }
    let URL = this.config.entityURL;
    if (this.config.additionalPathname) {
      URL = `${URL}/${this.config.additionalPathname}`;
    }
    try {
      const response = await this.get(URL, requestPayload);
      // handle backends without pagination :(
      // then the reponse is a blank array without metadata;..
      if (Array.isArray(response)) {
        const items = response as T[];
        return {
          items,
          count: items?.length,
        };
      }
      return response as Promise<IListResponse<T>>;
    } catch (e) {
      throw new Error(`HTTP Service Error: ${e}`);
    }
  };

  getById = (requestPayload: IGetSingleEntityPayload): Promise<T> => {
    if (!this.config.entityURL) {
      throw new Error('HttpService: entityUrl needs to be set in constructor()');
    }
    console.log('req', requestPayload);
    return this.get(`${this.config.entityURL}/${requestPayload.id}`, {
      relations: requestPayload.relations,
    }) as Promise<T>;
  };

  save = (requestPayload: T): Promise<T> => {
    this.handleOneToManyRelation(this.config.oneToManyRelation as string, requestPayload);

    if (!this.config.entityURL) {
      throw new Error('HttpService: entityUrl needs to be set in constructor()');
    }
    const method = this.config.method;
    // if there is one to many relation this part will handle it.
    const oneToManyRelation = this.handleOneToManyRelation(
      this.config.oneToManyRelation as string,
      requestPayload,
    );
    const entityId = this.config.entityPrimaryKey
      ? (requestPayload as any)?.[this.config.entityPrimaryKey]
      : (requestPayload as any)?.id;
    const entityVersion = (requestPayload as any).version;
    if (entityId && (requestPayload as any)?.id !== null && !method) {
      const relations = entityVersion ? `${entityId}/${entityVersion}` : `${entityId}`;
      return this.handleMethod(
        'put',
        `${this.config.entityURL}/${oneToManyRelation || relations}`,
        {
          ...requestPayload,
        },
      ) as Promise<T>;
    }
    // this option is for changing request method.
    if (method) {
      return this.handleMethod(
        method,
        `${this.config.saveURL || this.config.entityURL}${oneToManyRelation || ''}/${entityId}`,
        { ...requestPayload },
      ) as Promise<T>;
    }
    return this.handleMethod(
      'post',
      `${this.config.saveURL || this.config.entityURL}${oneToManyRelation || ''}`,
      {
        ...requestPayload,
      },
    ) as Promise<T>;
  };

  deleteById = async (requestPayload: IDeleteEntityPayload): Promise<T> => {
    if (!this.config.entityURL) {
      throw new Error('HttpService: entityUrl needs to be set in constructor()');
    }
    try {
      const response: Promise<T> = await this.delete(
        `${this.config.entityURL}/${requestPayload.id}`,
        {
          ...requestPayload,
        },
      );
      return response;
    } catch (e) {
      throw new Error(`HTTP Service Error: ${e}`);
    }
  };

  search = (searchTerm: string, limit: number = 10, options?: IGetMultiEntityPayload) => {
    const payload: IGetMultiEntityPayload = {
      query: searchTerm,
      limit,
      ...options,
    };
    return this.getEntries(payload);
  };

  /**
   *
   *
   */
  getFileUploadProps(options: IFileUploadOptions): UploadProps {
    const usedOptions: IFileUploadOptions = {
      name: 'file',
      action: `${this.config.apiURL}/${options.url}`,
      ...options,
    };
    return {
      headers: {
        ...this.getHeaders({}, true),
      },
      ...usedOptions,
    } as UploadProps;
  }

  getFiles = (requestPayload: IGetDocumentsListPayload): Promise<T> => {
    if (!this.config.entityURL) {
      throw new Error('HttpService: entityUrl needs to be set in constructor()');
    }
    return this.get(
      `${this.config.entityURL}/${requestPayload.id}/media/${
        requestPayload.category ? requestPayload.category : ''
      }`,
    ) as Promise<T>;
  };

  getFileLink = (requestPayload: IGetFileLinkPayload): Promise<IGetFileLinkResponse> => {
    const encodedName = encodeURIComponent(requestPayload.fileName);
    return this.get(`admin/files/link/${encodedName}`) as Promise<IGetFileLinkResponse>;
  };

  deleteFile = (requestPayload: IGetFileLinkPayload): Promise<IGetFileLinkResponse> => {
    const encodedName = encodeURIComponent(requestPayload.fileName);
    return this.delete(`admin/files/${encodedName}`) as Promise<IGetFileLinkResponse>;
  };

  uploadFile = (requestPayload: IUploadFilePayload): Promise<IOfferDocument> => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const data = new FormData();
    data.append('document', requestPayload.document);
    data.append('category', requestPayload.category);

    return this.post(
      `${this.config.entityURL}/${requestPayload.entityId}/media/upload`,
      data,
      true,
      headers,
    ) as Promise<IOfferDocument>;
  };

  //for upload organization logo
  uploadOrganizationLogo = (orgId: string, logo: File) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const data = new FormData();
    data.append('logo', logo);
    return this.post(`${this.config.entityURL}/${orgId}/logo`, data, true, headers);
  };

  deleteOrganizationLogo = (orgId: string) => {
    return this.delete(`${this.config.entityURL}/${orgId}/logo`);
  };
}

export default EntityService;
