import * as React from 'react';
import { Descriptions } from 'antd';

import { IClientRequest } from 'store/tendegyService/tendergyService.types';

interface Props {
  clientRequest?: IClientRequest;
}

const ClientRequestAddress: React.FC<Props> = ({ clientRequest }: Props) => {
  if (!clientRequest || !clientRequest.address) {
    return <div> no client available</div>;
  }
  const { address } = clientRequest;
  return (
    <div>
      <Descriptions column={1} layout="horizontal">
        <Descriptions.Item label="Address">
          {address.street} {address.houseNumber}
          {address.postalCode} {address.city}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default ClientRequestAddress;
