import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IApiClient } from 'store/authService/authService.types';

interface Props {
  entry: IApiClient;
  onSubmitStep: (entry: IApiClient) => void;
}

const ApiClientsBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  return (
    <div>
      <Formik initialValues={entry as IApiClient} onSubmit={(values) => onSubmitStep(values)}>
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="clientName" label="Client Name" required>
              <Input name="clientName" />
            </Form.Item>
            <Form.Item name="clientEmail" label="Client Email" required>
              <Input name="clientEmail" type="email" />
            </Form.Item>
            <Form.Item name="clientDescription" label="Client Description">
              <Input.TextArea name="clientDescription" />
            </Form.Item>
            <Form.Item name="allowedIPRanges" label="Allowed IP Ranges">
              <Select name="allowedIPRanges" mode="tags"></Select>
            </Form.Item>
            <Form.Item name="allowedIPs" label="Allowed IPs">
              <Select name="allowedIPs" mode="tags"></Select>
            </Form.Item>
            <Form.Item name="passphrase" label="Passphrase">
              <Input name="passphrase" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ApiClientsBaseStep;
