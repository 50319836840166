import React from 'react';
import { Descriptions } from 'antd';

import { IClientRequest } from 'store/tendegyService/tendergyService.types';

interface IProps {
  entry: IClientRequest;
}

const RequestAddressComponent = (props: IProps) => {
  const { entry } = props;
  return (
    <Descriptions title="Client address" column={1} layout="horizontal">
      {entry?.address?.street && (
        <Descriptions.Item label="Street">
          {`${entry?.address.street} ${entry.address.houseNumber} ${entry?.address?.address2}`}
        </Descriptions.Item>
      )}
      {entry?.address?.postalCode && (
        <Descriptions.Item label="Postal Code">{entry?.address.postalCode}</Descriptions.Item>
      )}
      {entry?.address?.city && (
        <Descriptions.Item label="City">{entry?.address.city}</Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default RequestAddressComponent;
