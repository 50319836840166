import React from 'react';

import { FormikForm, FormInput, FormSelect, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { IFormSelectProps } from 'components/forms/FormSelect';
import { EnumISAPrefferedContact, IISAUser } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

import styles from './styles.module.sass';

interface IFormProps {
  entry?: IISAUser;
  onSubmit: (entry: IISAUser) => Promise<IISAUser>;
}

const PREFERRED_CONTACT_OPTIONS = Object.values(EnumISAPrefferedContact);

export default function ISAUsersForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISAUser> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISAUser,
  };

  const preferredContactProps: IFormSelectProps = {
    label: 'Preferred Contact',
    name: 'preferredContact',
    options: PREFERRED_CONTACT_OPTIONS,
    required: true,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="email" label="Email" required />
      <FormInput name="firstName" label="First Name" required />
      <FormInput name="lastName" label="Last Name" required />
      <FormInput name="phone" label="Phone" required />
      <FormSelect {...preferredContactProps} />
      <div className={styles.centered}>
        <h4>Address</h4>
      </div>
      <FormInput name="address.street" label="Street" required />
      <FormInput name="address.doorNumber" label="House Number" required />
      <FormInput name="address.city" label="City" required />
      <FormInput name="address.postalCode" label="Postal Code" required />
      <FormSubmit />
    </FormikForm>
  );
}
