import * as Yup from 'yup';

import 'yup-phone-lite';

/*
export interface IAuthUser {
  id: string,
  firstName: string,
  lastName: string,
  email?: string,
  emailVerified?: boolean,
  roles?: any,
  password?: string,
  salt?: string,
  language?: {
    id: number,
    isoName: string,
    nativeName: string,
    isoCode: string,
    users: any
  },
  organization?: {
    id: string,
    name: string,
    members: any
  },
  subscriptions?: any,
  role?: string
}
*/

export const AuthUserValidation = {
  firstName: Yup.string().required('Required').min(2, 'Too short!').max(50, 'Too long'),
  lastName: Yup.string().required('Required').min(2, 'Too short!').max(50, 'Too long'),
  email: Yup.string().nullable().min(2, 'Too short!').max(50, 'Too long'),
  title: Yup.string().nullable().min(2, 'Too short!').max(50, 'Too long'),
  gender: Yup.number().nullable(),
  // @ts-ignore
  phone: Yup.string().phone('DE').required('Required'),
  fax: Yup.string().nullable().min(2, 'Too short!').max(20, 'Too long'),
  language: Yup.string().nullable(),
  roles: Yup.array().of(Yup.string()),
};

export const CraftUserValidation = {
  firstName: Yup.string().required('Required').min(2, 'Too short!').max(50, 'Too long'),
  lastName: Yup.string().required('Required').min(2, 'Too short!').max(50, 'Too long'),
  email: Yup.string().nullable().min(2, 'Too short!').max(50, 'Too long'),
  title: Yup.string().nullable().min(2, 'Too short!').max(50, 'Too long'),
  gender: Yup.number().nullable(),
  phone: Yup.string().nullable().min(2, 'Too short!').max(20, 'Too long'),
  fax: Yup.string().nullable().min(2, 'Too short!').max(20, 'Too long'),

  address: Yup.object()
    .shape({
      street: Yup.string().required('Required').min(2, 'Too short!').max(40, 'Too long'),
      houseNumber: Yup.string().required('Required').min(1, 'Too short!').max(40, 'Too long'),
      address2: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
      postalCode: Yup.string().required('Required').min(2, 'Too short!').max(8, 'Too long'),
      city: Yup.string().required('Required').min(2, 'Too short!').max(40, 'Too long'),
    })
    .nullable(),
  currentPool: Yup.object().nullable(),
};
