import * as React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillCarOwnersService from 'services/billing-v2/billing-v2-car-owners.service';
import {
  BillOrganizationPayload,
  IBillingOrganization,
} from 'services/billing-v2/billing-v2-service.types';

interface Props {
  entry?: IBillingOrganization;
}

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstname',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Last name',
    dataIndex: 'lastname',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const CarOwnersTab: React.FC<Props> = ({ entry }: Props) => {
  const payload: BillOrganizationPayload = {
    organizationId: entry?.id,
  };

  return (
    <CrudListViewContainer
      entityName="Car Owners"
      columns={columns}
      additionalPayload={payload}
      resource={BillCarOwnersService}
      disableSearch
    />
  );
};

export default CarOwnersTab;
