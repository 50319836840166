import React from 'react';

import { FormInput, FormSelect } from 'components/forms';
import FormSwitch from 'components/forms/FormSwitch/FormSwitch';

import styles from './LandingComponentsStyles.module.sass';

interface LandingBlockProps {
  index: number;
}

const LandingBlock: React.FC<LandingBlockProps> = ({ index }: LandingBlockProps) => {
  const blockStyle = `scheme.blocks[${index}].style`;
  return (
    <>
      <FormSelect
        name={`scheme.blocks[${index}].type`}
        options={['horizontal', 'vertical']}
        label={'Type'}
      />
      <FormSwitch name={`scheme.blocks[${index}].shopUrl`} label="Show shop URL" />
      <div className={styles.center}>
        <p className={styles.subtitle}>Block styling:</p>
      </div>
      <FormInput
        name={`${blockStyle}.backgroundColor`}
        label={'Background color'}
        required={false}
      />
      <FormSelect
        name={`${blockStyle}.fontWeight`}
        label={'Font weight'}
        options={['lighter', 'bold', 'bolder']}
      />
      <FormSelect
        name={`${blockStyle}.position`}
        label={'Position'}
        options={['fixed', 'absolute', 'relative']}
      />
      <FormInput name={`${blockStyle}.fontSize`} label={'Font size'} required={false} />
      <FormInput name={`${blockStyle}.margin`} label={'Margin'} required={false} />
      <FormInput name={`${blockStyle}.padding`} label={'Padding'} required={false} />
      <FormInput name={`${blockStyle}.borderRadius`} label={'Border radius'} required={false} />
    </>
  );
};

export default LandingBlock;
