import React from 'react';

import { FormEntitySelect, FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormEntitySelectProps } from 'components/forms/FormEntitySelect';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { ISACarModelsService } from 'services/isa-service';
import { IISACarBrand } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISACarBrand;
  onSubmit: (entry: IISACarBrand) => Promise<IISACarBrand>;
}

export default function ISACarBrandsForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISACarBrand> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISACarBrand,
  };

  const carModelsProps: IFormEntitySelectProps = {
    label: 'Car Models',
    name: 'carModels',
    entityService: ISACarModelsService,
    current: entry.carModels,
    mode: 'multiple',
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="name" label="Car Brand Title" required />
      <FormEntitySelect {...carModelsProps} />
      <FormSubmit />
    </FormikForm>
  );
}
