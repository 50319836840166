import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import ProductsService from 'services/shop/shop-products.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ProductBaseStep from './steps/ProductBaseStep';

const ProductCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add product',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <ProductBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Product"
      steps={steps}
      baseLink={links.shopProducts}
      defaultPayload={params}
      resource={ProductsService}
    />
  );
};

export default ProductCreatePage;
