import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { IProduct, IProductModel } from 'store/tendegyService/tendergyService.types';

import { ProductValidation } from '../../product.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const ProductOverviewTab: React.FC<IGenericEditTabProps<IProduct>> = (
  props: IGenericEditTabProps<IProduct>,
) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  // @todo: we might pick only relevant fields..
  const validation = Yup.object().shape(ProductValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IProductModel}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name">
              <Input name="name" placeholder="" />
            </Form.Item>

            <Form.Item name="category" label="category">
              {values.category}
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductOverviewTab;
