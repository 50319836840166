/**
 *
 * @docs:
 *   please find the bakcend models
 *   https://gitlab.com/inno2grid-fit/schneidercraft-be-v1/-/tree/develop/src/models
 */
interface IBaseEntity {
  id: string | null;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly deleteDate?: Date;
}

export enum InviteLinkType {
  installer = 'installer',
  user = 'enduser',
  installerPool = 'installerPool',
}

export interface IRegistrationLink {
  token: string;
  expires: string;
  type: string;
  organization: string;
  id: number;
}

export enum OrganizationTypes {
  Installer = 'installer',
  Intermediate = 'intermediate',
  User = 'user',
}

export enum OrganizationState {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  InvitationPending = 'invited',
}

export enum ActivityLogsScope {
  Installers = 'installers',
  Organizations = 'organizations',
  ClientRequests = 'client_requests',
  Offers = 'offers',
}

export enum ActivityLogsActions {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  PENDING = 'PENDING',

  CUSTOMER_REQUEST_CREATED = 'CUSTOMER_REQUEST_CREATED',
  CUSTOMER_UPLOADED_DOCS = 'CUSTOMER_UPLOADED_DOCS',
  OFFER_CREATED = 'OFFER_CREATED',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  HOME_CHECK_OFFER_ACCEPTED = 'HOME_CHECK_OFFER_ACCEPTED',
  OFFER_RELEASED = 'OFFER_RELEASED',
  INSTALLATION_DONE = 'INSTALLATION_DONE',
  CUSTOMER_REQUEST_UPDATED = 'CUSTOMER_REQUEST_UPDATED',

  COORDINATOR_ACCEPTED = 'COORDINATOR_ACCEPTED',
  COORDINATOR_REJECTED = 'COORDINATOR_REJECTED',
  CUSTOMER_ACCEPTED = 'CUSTOMER_ACCEPTED',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',

  INITIATED = 'INITIATED',
  INSTALLER_ACCEPTED = 'INSTALLER_ACCEPTED',
  INSTALLER_ACCEPTED_HOME_CHECK = 'INSTALLER_ACCEPTED_HOME_CHECK',
  INSTALLER_CANCELLED = 'INSTALLER_CANCELLED',
  INSTALLER_REJECTED = 'INSTALLER_REJECTED',
  INSTALLER_CONFIRMED = 'INSTALLER_CONFIRMED',
  INTERMEDIATE_ACCEPTED = 'INTERMEDIATE_ACCEPTED',
  INTERMEDIATE_REJECTED = 'INTERMEDIATE_REJECTED',
  CUSTOMER_SELECTED_DATE = 'CUSTOMER_SELECTED_DATE',
  INSTALLER_OFFER_CREATED = 'INSTALLER_OFFER_CREATED',
  INSTALLER_APPOINTMENT_CREATED = 'INSTALLER_APPOINTMENT_CREATED',
  CUSTOMER_APPOINTMENT_CONFIRMED = 'CUSTOMER_APPOINTMENT_CONFIRMED',
  INSTALLER_WORK_COMPLETED = 'INSTALLER_WORK_COMPLETED',
  INSTALLER_WORK_CANCELLED = 'INSTALLER_WORK_CANCELLED',
  HOME_CHECK_DONE = 'HOME_CHECK_DONE',
}

export const InstallersActionType = [
  ActivityLogsActions.APPROVE,
  ActivityLogsActions.REJECT,
  ActivityLogsActions.PENDING,
];

export const ClientRequestActionType = [
  ActivityLogsActions.CUSTOMER_REQUEST_CREATED,
  ActivityLogsActions.CUSTOMER_UPLOADED_DOCS,
  ActivityLogsActions.OFFER_CREATED,
  ActivityLogsActions.OFFER_ACCEPTED,
  ActivityLogsActions.HOME_CHECK_OFFER_ACCEPTED,
  ActivityLogsActions.OFFER_RELEASED,
  ActivityLogsActions.INSTALLATION_DONE,
  ActivityLogsActions.CUSTOMER_REQUEST_UPDATED,
  ActivityLogsActions.COORDINATOR_ACCEPTED,
  ActivityLogsActions.COORDINATOR_REJECTED,
  ActivityLogsActions.CUSTOMER_ACCEPTED,
  ActivityLogsActions.CUSTOMER_REJECTED,
];

export const OfferActionType = [
  ActivityLogsActions.COORDINATOR_ACCEPTED,
  ActivityLogsActions.COORDINATOR_REJECTED,
  ActivityLogsActions.CUSTOMER_ACCEPTED,
  ActivityLogsActions.CUSTOMER_REJECTED,
  ActivityLogsActions.INITIATED,
  ActivityLogsActions.INSTALLER_ACCEPTED,
  ActivityLogsActions.INSTALLER_ACCEPTED_HOME_CHECK,
  ActivityLogsActions.INSTALLER_CANCELLED,
  ActivityLogsActions.INSTALLER_REJECTED,
  ActivityLogsActions.INSTALLER_CONFIRMED,
  ActivityLogsActions.INTERMEDIATE_ACCEPTED,
  ActivityLogsActions.INTERMEDIATE_REJECTED,
  ActivityLogsActions.CUSTOMER_SELECTED_DATE,
  ActivityLogsActions.INSTALLER_OFFER_CREATED,
  ActivityLogsActions.INSTALLER_APPOINTMENT_CREATED,
  ActivityLogsActions.CUSTOMER_APPOINTMENT_CONFIRMED,
  ActivityLogsActions.INSTALLER_WORK_COMPLETED,
  ActivityLogsActions.INSTALLER_WORK_CANCELLED,
  ActivityLogsActions.HOME_CHECK_DONE,
];

export enum CommentStates {
  Pending = 'pending',
  Resolved = 'resolved',
}

export enum LeadState {
  NEW = 'NEW',
  CONTACTED = 'CONTACTED',
}

export enum CustomDocumentsCategoryType {
  InstallerLandingPage = 'installer_landing_page',
  UserCostCalculatorDescription = 'user_cost_calculator',
  UserFAQPage = 'user_faq_page',
  SurveyLandingPage = 'survey_landing_page',
}

export enum QuestionKey {
  WallboxAlreadyInstalled = 'wallboxAlreadyInstalled',
  HouseType = 'houseType',
  HouseOwnership = 'houseOwnership',
  OwnParkingSpace = 'ownParkingSpace',
  ParkingSpaceEnergy = 'parkingSpaceEnergy',
  ParkingSpaceLocation = 'parkingSpaceLocation',
  InstallationSeparateMeter = 'installationSeparateMeter',
  EnergyType = 'energyType',
  ConnectionSecurity = 'connectionSecurity',
  InstallationMeterLocation = 'installationMeterLocation',
  InstallationDigging = 'installationDigging',
  InstallationDistance = 'installationDistance',
  InstallationDiggingRestauration = 'installationDiggingRestauration',
  InstallationExcavationSurface = 'installationExcavationSurface',
  InstallationAffectedSurface = 'installationAffectedSurface',
  ChargingStation = 'chargingStation',
  InstallationAmountWalls = 'installationAmountWalls', // how many walls needs to be drilled for installation
  InstallationStraightWall = 'installationStraightWall', // is there a straight wall with min width 30cm
  ParkingSpaceWall = 'parkingSpaceWall',
  CarModel = 'carModel',
}

export interface ITendergyAddress {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  confirmedAt?: Date;
  deletedDate?: any;
  street: string;
  houseNumber: string;
  address2?: any;
  postalCode: string;
  city: string;
}

export interface ITendergyOrganization {
  id: string;
  displayId?: string;
  createdAt: Date;
  updatedAt: Date;
  state?: ApprovalState;
  deletedDate?: Date;
  authId: string;
  name: string;
  type?: OrganizationTypes;
  evuConfirmed: boolean;
  address?: ITendergyAddress;
  inviteTokens?: ITendergyInvitationLink[];
  poolTenderOrganizations?: IPoolTenderOrganization[];
  isPrivate?: boolean;
  variableFee?: number | null;
  apiClientId?: number;
  logo?: string;
}

export interface ITendergyOrgDefaultSettings {
  id?: string;
  updatedAt?: Date;
  createdAt?: Date;
  deletedDate?: Date;
  organization?: ITendergyOrganization;
  appointmentOffTendergy?: boolean;
  currency?: number;
  currencySymbol?: string;
  homeCheckPrice?: number;
  offerValidityDays?: number;
  vat?: number;
}

export enum ApprovalState {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface ITendergyInvitationLink {
  token: string;
  expires: string;
  type: string;
  organization: ITendergyOrganization;
  organizationId?: string;
  id?: string;
  clientRequestPool?: ITendergyClientRequestPool;
  isEnabled?: boolean;
}

export interface ITendergyIntermediate {
  address?: ITendergyAddress;
  confirmedAt?: string;
  confirmedBy?: string;
  createdAt: Date;
  deletedDate?: Date;
  displayId?: string;
  id: string;
  isConfirmed?: boolean;
  name: string;
  rejectReason?: string;
  rejectedAt?: string;
  rejectedBy?: string;
  type?: string;
  updatedAt?: string;
}

export interface ITendergyClientRequestPool {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  clientRequests?: IClientRequest[];
  poolTenderOrganizations?: IPoolTenderOrganization[];
  users?: ITendergyUser[];
  name: string;
  isDefault?: boolean;
  requireOfferConfirmation?: boolean;
  costEstimateMin?: string;
  costEstimateMax?: string;
  intermediateOffer?: boolean;
  intermediateHomeCheckOffer?: boolean;
  isPublicPool?: boolean;
  intermediateOrganizationId?: string;
  intermediateOrganization?: ITendergyOrganization;
  token?: string;
  questionnaire?: ITendergyQuestionnaire;
  surveyQuestionnaire?: ITendergyQuestionnaire;
}

export interface IPoolTenderOrganization {
  automaticApproval?: boolean;
  clientRequestPool: ITendergyClientRequestPool;
  organization: ITendergyOrganization;
}

export interface ITendergyInstaller {
  id: string;
  gender: number;
  title?: any;
  mobile: string;
  fax?: any;
  state?: ApprovalState;
  searchRadius: number;
  searchZipCode?: any;
  searchCountryCode?: any;
  minChargingPoints: number;
  maxChargingPoints: number;
  organization: ITendergyOrganization;
  user?: ITendergyUser;
}

export interface ITendergyUser {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedDate?: any;

  displayId: string;
  authId: string;
  firstName: string;
  lastName: string;
  gender: number;
  email: string;
  title: string;
  phone?: any;
  fax: string;
  installer?: ITendergyInstaller;
  address?: any;
  organization: ITendergyOrganization;
  clientRequestPool?: ITendergyClientRequestPool;
  currentPool?: ITendergyClientRequestPool;
  createdByClientId?: number;
}

export interface IAnswerEntry {
  questionKey: string;
  answerValue: any;
  createdAt: Date;
  updatedAt: Date;
  deletedDate?: any;
}

export interface IClientRequest {
  id: string | null;
  displayId?: string;
  owner?: ITendergyUser;
  ownerId?: string;
  createdAt?: Date;
  updatedUp?: Date;
  deletedDate?: Date;
  annotation?: string;
  rejectReason?: string;
  savePosition?: string;
  lastStateChangedAt?: Date;
  lastReminderSentAt?: Date;
  lastReminderTypeSent?: string;
  address?: ITendergyAddress;
  state?: string;
  offers?: IOffer[];
  answers?: IAnswerEntry[];
  pool?: ITendergyClientRequestPool;
  poolId?: string;
  costEstimateMax?: string;
  costEstimateMin?: string;
  businessEmail?: string;
  bundle?: string;
  phone?: string;
  userType?: string;
  step?: string;
  subStep?: string;
  soonestPossible?: boolean;
  archived?: boolean;
  startDate?: Date;
  weekdays?: string[];
  contactType?: string;
  questionnaireSkipped?: boolean;
  questionnaire?: {
    name: string;
  };
  versionedQuestionnaireId?: {
    id: string;
    version: number;
  };
}

export interface IOffer extends IBaseEntity {
  displayId?: string;
  state?: string;
  annotation?: string;
  doneAsStated?: boolean;
  feedbackReason?: string;
  feedbackAnnotation?: string;
  costEstimateHomeCheck?: string;
  costEstimate?: string;
  initialCostEstimateHomeCheck?: string;
  initialCostEstimate?: string;
  contactType?: string;
  clientRequest?: IClientRequest;
  clientRequestId?: string;
  installerUserId?: string;
  organization?: ITendergyOrganization;
  organizationId?: string;
  installerUser?: ITendergyUser;
  costEstimateAdditional?: number;
  additionalConditions?: string[];
  additionalConditionsDescription?: string;
  finalInstallationTimestamp?: Date;
  finalHomeCheckTimestamp?: Date;
  invoiceAcceptedTimestamp?: Date;
  invoiceSubmittedTimestamp?: Date;
  tenderAcceptedHomeCheckTimestamp?: Date;
  tenderAcceptedTimestamp?: Date;
  reminderSentAt?: Date;
  doneInformationSentAt?: Date;
  rejectReason?: string;
  offerReplacedBy?: ITendergyUser;
  offerReplacedById?: string;
  homeCheckOfferReplacedBy?: ITendergyUser;
  homeCheckOfferReplacedById?: string;
  initialInvoiceHomeCheck?: string;
  initialInvoiceAmount?: string;
  invoiceHomeCheck?: string;
  invoiceAmount?: string;
  installedProductModel?: IProductModel;
  installedProductModelId?: string;
  deletedDate?: Date;
  homeCheckAnnotation?: string;
  actualInstallationTimestamp?: Date;
  actualHomeCheckTimestamp?: Date;
}

export enum ProductStatus {
  Listed = 'listed',
  Unlisted = 'unlisted',
}

export interface IProductPrice extends IBaseEntity {
  netPrice?: number;
  currencySymbol?: string;
  currency?: string;
  tax?: number;
  totalPrice?: number;
  default?: boolean;
  description?: string;
  prodcut?: IProduct;
}

export interface IProduct {
  status?: ProductStatus;
  title?: string;
  referenceId?: string;
  description?: string;
  onRequestPrice?: boolean;
  onRequestPriceTitle?: string;
  prices?: IProductPrice[];
  model?: IProductModel;
  pool?: ITendergyClientRequestPool;
}

export enum ProductModelCategoryType {
  Wallbox = 'wallbox',
  ChargingStations = 'chargingStations',
}

export interface IProductModel extends IBaseEntity {
  name: string;
  vendor?: IProductVendor;
  category?: ProductModelCategoryType;
  pool?: ITendergyClientRequestPool;
}

export interface IProductVendor extends IBaseEntity {
  name: string;
  pool?: ITendergyClientRequestPool;
  productModels?: IProductModel[];
}

export interface ICustomDocument extends IBaseEntity {
  scopeId: string;
  scopeType: string;
  category: CustomDocumentsCategoryType;
  text?: string;
  imageLink?: string;
  media: any[];
  heading?: string;
}

export interface ITendergyComment extends IBaseEntity {
  author: ITendergyUser;
  authorId?: string;
  commentableId: string;
  commentableType: string;
  parentComment: ITendergyComment;
  parentCommentId: string;
  text: string;
  state: CommentStates;
  attachments: ITendergyDocument[];
}

export interface ITendergyDocument extends IBaseEntity {
  name: string;
  originalName: string;
  mime: string;
  size: number;
  uploadedBy: ITendergyUser;
  category: string;
}

export interface IUISchema {
  type: string;
  elements: any[];
}

export interface ITendergyQuestionnaireStep extends IBaseEntity {
  order: number;
  title: string;
  questionnaireId: string;
  parentId?: string;
  questionnaire?: ITendergyQuestionnaire;
  parent?: ITendergyQuestionnaireStep;
  children?: ITendergyQuestionnaireStep[];
  schema?: any;
  uiSchema?: any;
}

export interface ITendergyQuestionnaire extends IBaseEntity {
  name: string;
  isDefault: boolean;
  version: number;
  pools?: ITendergyClientRequestPool[];
  surveyPools?: ITendergyClientRequestPool[];
  steps?: ITendergyQuestionnaireStep[];
  questionnaireType: QuestionnaireType;
  clientRequests?: IClientRequest[];
}

export enum QuestionnaireType {
  Request = 'request',
  Survey = 'survey',
}

export interface ITendergyQuestionnaireConfig extends IBaseEntity {
  questionKey: QuestionKey;
  isDisabled?: boolean;
  isVisible?: boolean;
  defaultAnswer?: object;
  possibleAnswers?: object;
  pool?: ITendergyClientRequestPool;
}

export interface ITendergyActivityLog {
  action: string;
  scope: string;
  affectedEntry: string;
  initiator: string;
  comment: string;
  timestamp: Date;
  clientRequest?: IClientRequest | null;
  offer?: IOffer | null;
  organization?: ITendergyOrganization | null;
  installer?: ITendergyInstaller | null;
  user?: ITendergyUser | null;
}

export interface ITendergyLead {
  state: LeadState;
  leadType: string;
  companyName?: string;
  numberOfCars?: string;
  contactPersonName?: string;
  contactPersonEmail?: string;
  initiatorName?: string;
  contactPersonPhone?: string;
  address?: ITendergyAddress;
  pool?: ITendergyClientRequestPool;
}

export interface ITendergyFeedback {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  author?: ITendergyUser;
  authorId?: string;
  receiverId?: string;
  receiverType?: FeedbackReceiverType;
  referenceId: string;
  referenceType: FeedbackReferenceType;
  text?: string;
  rating: number;
  reference?: any;
}

export enum FeedbackReceiverType {
  Installers = 'installers',
  Organizations = 'organizations',
  Platform = 'platform',
}

export enum FeedbackReferenceType {
  ClientRequests = 'client_requests',
  Offers = 'offers',
}

export enum MediaCategoryType {
  RequestLandlordPermission = 'request:landlord_permission',
  RequestParking = 'request:parking',
  RequestFloorPlan = 'request:floor_plan',
  RequestSketch = 'request:sketch',
  RequestPhotos = 'request:photos',
  RequestFuseBox = 'request:fuseBox',
  RequestHomeCheckDocumentation = 'request:home_check_documentation',
  OfferBid = 'offer:bid',
  OfferBidInstaller = 'offer:bid_installer',
  OfferHomeCheck = 'offer:home_check',
  OfferHomeCheckInstaller = 'offer:home_check_installer',
  OfferInvoiceHomeCheckInstaller = 'offer:invoice_home_check_installer',
  OfferInvoiceHomeCheck = 'offer:invoice_home_check',
  OfferInvoiceInstallationInstaller = 'offer:invoice_installation_installer',
  OfferInvoiceInstallation = 'offer:invoice_installation',
  OfferNetworkRegistration = 'offer:network_registration',
  InstallationFinalDocumentation = 'offer:final_documentation',
  OrganizationEvuConfirmation = 'organization:evu_confirmation',
  InstallerConfirmation = 'installer:confirmation',
  PoolAvatar = 'pool:avatar',
  CommentAttachment = 'comment:attachment',
  CustomDocumentImage = 'custom_document:image',
  QuestionnaireStepImage = 'questionnaire_step:image',
}

export enum UserGroup {
  Customer = 'customer',
  InstallerOrganization = 'installer organization',
  IntermediateOrganization = 'intermediate organization',
}

export interface IMembershipOption {
  id?: number;
  name: string;
  description?: string;
  userGroup: UserGroup;
}

export interface ITendergyMembershipPlan {
  id?: number;
  organization?: ITendergyOrganization;
  monthlyPrice?: number;
  commission?: number;
  createdAt?: Date;
  dateSubscribed?: Date;
  validUntil?: Date;
  startFrom?: Date;
  optionsIncluded?: IMembershipOption[];
  viewAllInstallers?: boolean;
  intermediatesWhiteList?: ITendergyOrganization[];
}

export interface IHardwareReport {
  id: string;
  name: string;
  totalInstalled: number;
}

export enum AppointmentPurpose {
  HomeCheck = 'home_check',
  Installation = 'installation',
}

export enum OfferingUserType {
  END_USER = 'end_user',
  INSTALLER = 'installer',
}

export interface ITendergyAppointment {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  timestamp: Date;
  proposedBy: OfferingUserType;
  purpose?: AppointmentPurpose | null;
  offer?: IOffer | null;
}

export interface ITendergyCountry {
  iso31661Alpha3: string;
  vatRate: number;
  currency: string;
  addresses?: ITendergyAddress[];
  isDefault?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ITendergySurvey {
  createdAt?: string;
  displayId: string;
  id: string;
  step?: string;
  answers?: IAnswerEntry[];
  pool?: ITendergyClientRequestPool;
  poolId?: string;
  questionnaire?: ITendergyQuestionnaire;
  versionedQuestionnaireId?: {
    id: string;
    version: number;
  };
}
