import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Descriptions, Typography } from 'antd';

import Box from 'components/layout/Box/Box';
import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import {
  IBillingCarOwner,
  IBillingChargingStation,
  IBillingEnergyPrice,
  IBillingRFIDCard,
} from 'services/billing/billing-service.types';
import { CurrencyUtils } from 'utils/currencyUtils';

import styles from '../billing-create-flow.module.sass';

export interface ICreateBlllingSummaryStepPRops {
  carOwner: IBillingCarOwner;
  energyPrice: IBillingEnergyPrice;
  chargingStation: IBillingChargingStation;
  rfidCard: IBillingRFIDCard;
}

const CreateBillingFlowSummaryStep: React.FC<ICreateBlllingSummaryStepPRops> = (
  props: ICreateBlllingSummaryStepPRops,
) => {
  const { Title, Paragraph } = Typography;
  const history = useHistory();
  const { carOwner, rfidCard, chargingStation, energyPrice } = props;

  const navigateTo = (link: string, id?: string) => history.push(createLink(link, { id }));

  const renderButtons = () => (
    <div className={styles.bottomButtonsWrapper}>
      <Button
        className={styles.bottomButton}
        type="primary"
        onClick={() => {
          history.push(links.billingCreateCarOwnerFlow);
          history.go(0);
        }}
      >
        Create another one
      </Button>

      <Button
        className={styles.bottomButton}
        type="ghost"
        onClick={() => history.push(createLink(links.billingCarOwner, { id: carOwner.id }))}
      >
        Open {carOwner.firstname} {carOwner.lastname}
      </Button>
    </div>
  );
  return (
    <Box>
      <div className={styles.successStep}>
        <div>
          <CheckCircleOutlined className={styles.icon} />
        </div>
        <Title level={3}>Successfully created</Title>

        <Descriptions
          className={styles.section}
          column={1}
          title="Car Owner"
          bordered
          extra={
            <Button onClick={() => navigateTo(links.billingCarOwner, carOwner.id)} type="primary">
              Open
            </Button>
          }
        >
          <Descriptions.Item label="Name">
            {carOwner.firstname} {carOwner.lastname}
          </Descriptions.Item>
          <Descriptions.Item label="E-Mail">{carOwner.email}</Descriptions.Item>
          <Descriptions.Item label="Organization">{carOwner.organization?.name}</Descriptions.Item>
        </Descriptions>

        <Descriptions
          className={styles.section}
          column={1}
          title="RFID Card"
          bordered
          extra={
            <Button onClick={() => navigateTo(links.billingRFIDCard, rfidCard.id)} type="primary">
              Open
            </Button>
          }
        >
          <Descriptions.Item label="Identifier">{rfidCard.identifier}</Descriptions.Item>
          <Descriptions.Item label="evcoId">{rfidCard.evcoId}</Descriptions.Item>
        </Descriptions>

        <Descriptions
          className={styles.section}
          column={1}
          title="ChargingStation"
          bordered
          extra={
            <Button
              onClick={() => navigateTo(links.billingChargingStation, energyPrice.id)}
              type="primary"
            >
              Open
            </Button>
          }
        >
          <Descriptions.Item label="Evse">{chargingStation.evse}</Descriptions.Item>
          <Descriptions.Item label="Country">
            {chargingStation.postal_code} {chargingStation.city}, {chargingStation.country}{' '}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          className={styles.section}
          column={1}
          title="EnergyPrice"
          bordered
          extra={
            <Button
              onClick={() => navigateTo(links.billingEnergyPrice, energyPrice.id)}
              type="primary"
            >
              Open
            </Button>
          }
        >
          <Descriptions.Item label="Payout Per Unit">
            {CurrencyUtils.formatCurrency(energyPrice.payoutPerUnit)}
          </Descriptions.Item>
          <Descriptions.Item label="Charge per Unit">
            {CurrencyUtils.formatCurrency(energyPrice.chargePerUnit)}
          </Descriptions.Item>
        </Descriptions>

        <Paragraph>{renderButtons()}</Paragraph>
      </div>
    </Box>
  );
};

export default CreateBillingFlowSummaryStep;
