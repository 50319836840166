import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillChargingStationsService from 'services/billing-v2/billing-v2-charging-stations.service';
import { IBillingChargingStation } from 'services/billing-v2/billing-v2-service.types';

import ChargingStationForm from './components/ChargingStationForm/ChargingStationForm';
import EnergyPricesTab from './tabs/EnergyPricesTab';

interface IParams {
  id: string;
}

export default function ChargingStationDetailsPage() {
  const { id } = useParams<IParams>();
  const payload = {
    id,
  };

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ChargingStationForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'energy-prices',
          displayName: 'Energy Prices',
          render: (entry: IBillingChargingStation) => <EnergyPricesTab entry={entry} />,
        },
      ]}
      entityName="charging station"
      baseLink={links.billChargingStation}
      defaultPayload={payload}
      resource={BillChargingStationsService}
      renderTitle={(entry: IBillingChargingStation) => `${entry.evse} (${entry.type})`}
    />
  );
}
