import * as Yup from 'yup';

export const INITIAL_VALUES = {
  street: null,
  doorNumber: null,
  city: null,
  postalCode: null,
};

export const validationSchema = Yup.object().shape({
  street: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  doorNumber: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  city: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  postalCode: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
});
