import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import TendergyQuestionnaireService, {
  TendergyQuestionnaireServiceCopy,
} from 'services/tendergy/tendergy-questionnaire.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import QuestionnaireBaseStep from './steps/QuestionnaireBaseStep';

const CreateQuestionnaireConfigPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [questionnaireId, setQuestionnaireId] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add Questionnaire',
      description:
        'You can add an empty questonnaire or copy an existing questionnaire and customize it later',
      render: (entry, onSubmitStep) => {
        return (
          <QuestionnaireBaseStep
            entry={entry}
            onSubmitStep={(values) => {
              onSubmitStep(values);
            }}
            onChangeQuestionnaireId={(id, version) => {
              setQuestionnaireId(id);
              setVersion(version);
            }}
            onChangeCopy={(value) => setIsCopy(value)}
          />
        );
      },
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Questionnaire Config"
      steps={steps}
      baseLink={links.tendergyQuestionnaires}
      defaultPayload={params}
      resource={
        isCopy
          ? TendergyQuestionnaireServiceCopy(questionnaireId, version)
          : TendergyQuestionnaireService
      }
    />
  );
};

export default CreateQuestionnaireConfigPage;
