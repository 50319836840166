import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

class OrderServiceClass extends EntityService<any> {}

const OrderService = new OrderServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/orders',
  detailLink: links.shopOrder,
});

export default OrderService;
