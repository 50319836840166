import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IProductVendor } from 'store/tendegyService/tendergyService.types';

import { VendorValidation } from '../../vendor.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const VendorDetailsTab: React.FC<IGenericEditTabProps<IProductVendor>> = (
  props: IGenericEditTabProps<IProductVendor>,
) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  // @todo: we might pick only relevant fields..
  const validation = Yup.object().shape(VendorValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IProductVendor}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name">
              <Input name="name" placeholder="" />
            </Form.Item>
            <Form.Item name="products" label="Products">
              {entry.productModels?.map((product: any) => (
                <div key={product.id}>
                  <Link to={createLink(links.product, { id: product.id })}>{product.name}</Link>
                </div>
              ))}
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VendorDetailsTab;
