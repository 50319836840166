import links from 'framework/links';
import WebhookCreatePage from 'pages/integration/webhooks/create/WebhookCreatePage';
import WebhookDetailsPage from 'pages/integration/webhooks/details/WebhookDetailsPage';
import WebhooksPage from 'pages/integration/webhooks/overview/WebhooksPage';

export const INTEGRATION_ROUTES = [
  { path: links.webhooksOverview, component: WebhooksPage },
  { path: links.webhookCreate, component: WebhookCreatePage },
  { path: links.webhookDetails, component: WebhookDetailsPage },
];
