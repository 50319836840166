import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingEnergyPrice } from './billing-service.types';

class BillingEnergyPricingServiceClass extends EntityService<IBillingEnergyPrice> {}

const BillingEnergyPricingService = new BillingEnergyPricingServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/energy-prices',
  detailLink: links.billingEnergyPrice,
});

// this way I can autowire the result (with command + .);
export default BillingEnergyPricingService;
