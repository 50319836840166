import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavLink } from 'react-router-dom';
import { Layout, Menu, Tabs, Typography } from 'antd';

import { createLink } from 'framework/utils/links';

import styles from './styles.module.sass';

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

interface TabsProps {
  children?: any;
  contentMinHeight?: string;
  contentMaxHeight?: string;
  activeTitle?: string;
  activePageKey: string;
  baseRoute: {
    link: string;
    params?: {
      [param: string]: string;
    };
  };
  pages: {
    [pageRoute: string]: string;
  };
  legacy?: boolean;
}

const CustomTabs: React.FC<TabsProps> = ({
  children,
  contentMinHeight = 'auto',
  contentMaxHeight,
  activeTitle,
  activePageKey,
  baseRoute,
  pages,
  legacy = false,
}: TabsProps) => {
  return (
    <Layout>
      {legacy ? (
        <Sider theme="light" className={styles.sider} width={250}>
          <div className={styles.siderTitle}>Navigation</div>
          <Menu selectedKeys={[activePageKey]} className={styles.menu}>
            {Object.entries(pages).map(([route, name]) => (
              <Menu.Item key={route} className={styles.menuItem}>
                <NavLink to={createLink(baseRoute.link, { ...baseRoute.params, page: route })}>
                  {name}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      ) : (
        <Tabs activeKey={activePageKey} className={styles.tabs}>
          {Object.entries(pages).map(([route, name]) => (
            <TabPane
              tab={
                <NavLink
                  className={styles.navItem}
                  to={createLink(baseRoute.link, { ...baseRoute.params, page: route })}
                >
                  {name}
                </NavLink>
              }
              key={route}
              className={styles.menuItem}
            ></TabPane>
          ))}
        </Tabs>
      )}

      <Layout>
        <Content className={styles.tabContent}>
          {activeTitle && (
            <div className={styles.tabContentHeader}>
              <Typography.Text strong>{activeTitle}</Typography.Text>
              <div id={`${activePageKey}-header-buttons`} className={styles.buttonsBlock} />
            </div>
          )}

          <Scrollbars
            autoHide={false}
            autoHeight
            autoHeightMin={contentMinHeight}
            autoHeightMax={contentMaxHeight}
          >
            <div className={styles.content}>{children}</div>
          </Scrollbars>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CustomTabs;
