import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  identifier: Yup.string()
    .required('Identifier is required')
    .max(64, 'Field has not to be longer than 64 characters!'),
  evcoId: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
  username: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
  fullname: Yup.string().nullable().max(256, 'Field has not to be longer than 256 characters!'),
  isPrivate: Yup.boolean().nullable(),
});
