import { ClientRequestEventType } from 'framework/constants';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import { IClientRequest } from '../tendergyService.types';

export const GET = 'request/GET';
export const SET = 'request/SET';
export const SET_LOADING_STATUS = 'request/SET_LOADING_STATUS';
export const RESET = 'request/RESET';
export const TRANSITION = 'request/TRANSITION';
export const UNARCHIVE_REQUEST = 'UNARCHIVE_REQUEST';

/**
 * @deprecated don't use this anymore.
 */
export interface Address {
  address1?: string;
  address2?: string;
  postalCode: string;
  city?: string;
}

export interface RequestState {
  data: IClientRequest;
  loading: boolean;
}

export interface GetRequestAction {
  type: typeof GET;
  payload: IGetSingleEntityPayload;
}

export interface SetRequestAction {
  type: typeof SET;
  payload: IClientRequest;
}

export interface SetLoadingStatusAction {
  type: typeof SET_LOADING_STATUS;
  payload: boolean;
}

export interface UnarchiveAction {
  type: typeof UNARCHIVE_REQUEST;
  payload: IClientRequest;
}

export interface ResetAction {
  type: typeof RESET;
}

export interface TransitionRequestAction {
  type: typeof TRANSITION;
  payload: {
    type: ClientRequestEventType;
    id: string;
    rejectReason?: string;
  };
}

export type RequestActionTypes =
  | GetRequestAction
  | SetRequestAction
  | SetLoadingStatusAction
  | ResetAction
  | UnarchiveAction
  | TransitionRequestAction;
