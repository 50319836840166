import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergySurvey } from 'store/tendegyService/tendergyService.types';

class SurveyServiceClass extends EntityService<ITendergySurvey> {
  getDisplayName(entry: ITendergySurvey): string {
    return entry && entry.displayId;
  }
}

const SurveyService = new SurveyServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/surveys',
  detailLink: links.survey,
});

export default SurveyService;
