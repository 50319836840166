import * as React from 'react';
import { Button, DatePicker, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, Switch } from 'formik-antd';
import moment, { Moment } from 'moment';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { RFIDCardValidation } from 'pages/billing/rfid-cards/rfidcard.validation';
import { IBillingCarOwner, IBillingRFIDCard } from 'services/billing/billing-service.types';
import RFIDCardsService from 'services/billing/rfid-cards.service';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from '../billing-create-flow.module.sass';

interface ICreateOrSelectRFIDCardStepParams {
  currentRFIDCard?: IBillingRFIDCard;
  carOwner: IBillingCarOwner;
  onSelect: (rfidCard: IBillingRFIDCard) => void;
}

/**
 * This step should emit a carOwner Id.
 *
 */
const CreateOrSelectRFIDStep: React.FC<ICreateOrSelectRFIDCardStepParams> = (
  props: ICreateOrSelectRFIDCardStepParams,
) => {
  const validation = Yup.object().shape(RFIDCardValidation);

  const today = new Date();
  const defaultDate = moment(today);
  const [selectedValidFromDate, setValidFromDate] = React.useState<Moment>(defaultDate);
  const [selectedValidToDate, setValidToDate] = React.useState<Moment>(defaultDate);
  const [selectedRFIDCard, setSelectedRFIDCard] = React.useState<IBillingRFIDCard>();

  const defaultValues = {
    isPrivate: false,
    fullname: '',
  };

  const attachRfdCardToCarOwner = async (card: IBillingRFIDCard) => {
    const validFrom = selectedValidFromDate ? selectedValidFromDate.toDate() : new Date();
    const validTo = selectedValidToDate ? selectedValidToDate.toDate() : new Date();
    await RFIDCardsService.attachCardToCarOwner(card, props.carOwner, {
      validFrom,
      validTo,
    });
    // await RFIDC carOwner
  };
  const createRFIDAndSubmit = async (values: IBillingRFIDCard) => {
    let rfidCard;
    try {
      rfidCard = await RFIDCardsService.save(values);
      await attachRfdCardToCarOwner(rfidCard);
      props.onSelect(rfidCard);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const selectRFIDcardAndSubmit = async (selectedCard: IBillingRFIDCard) => {
    // maybe we need an 'are you sure here?'
    try {
      await attachRfdCardToCarOwner(selectedCard);
      props.onSelect(selectedCard);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <div>
      <div className={styles.entitySelector}>
        <Typography.Title className={styles.textCenter} level={5}>
          Select existing RFID-Card
        </Typography.Title>
        <EntitySelector
          name="rfidCard"
          entityService={RFIDCardsService}
          onSelect={(rfidCard: IBillingRFIDCard) => setSelectedRFIDCard(rfidCard)}
        />
        <div className={styles.description}>This card is valid from:</div>

        <DatePicker
          onChange={(selectedDate) => setValidFromDate(selectedDate || moment())}
          defaultValue={selectedValidFromDate}
        />
        <br />
        <div className={styles.description}>This card is valid to:</div>

        <DatePicker
          onChange={(selectedDate) => setValidToDate(selectedDate || moment())}
          defaultValue={selectedValidToDate}
        />
        <br />
        <Button
          className={styles.selectBtn}
          onClick={() => selectRFIDcardAndSubmit(selectedRFIDCard as IBillingRFIDCard)}
          disabled={!selectedRFIDCard}
        >
          Select this Card
        </Button>
      </div>
      <div>
        <Typography.Title className={styles.textCenter} level={5}>
          Or create a new RFID Card
        </Typography.Title>
        <Formik
          initialValues={defaultValues}
          validationSchema={validation}
          onSubmit={(values: any) => createRFIDAndSubmit(values)}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
              <Form.Item name="identifier" label="identifier">
                <Input name="identifier" placeholder="" />
              </Form.Item>
              <Form.Item name="username" label="username">
                <Input name="username" placeholder="" />
              </Form.Item>
              <Form.Item name="evcoId" label="evcoId">
                <Input name="evcoId" placeholder="" />
              </Form.Item>
              <Form.Item name="isPrivate" label="Is Private?">
                <Switch name="isPrivate" />
              </Form.Item>
              <Form.Item {...CrudFormDefaultActionParams} name="submit">
                <SubmitButton
                  type="primary"
                  htmlType="submit"
                  className={styles.selectBtn}
                  disabled={isSubmitting}
                >
                  Submit
                </SubmitButton>
              </Form.Item>
              <Form.Item name="validFrom" label=" This card is valid from:">
                <DatePicker
                  onChange={(selectedDate) => setValidFromDate(selectedDate || moment())}
                  defaultValue={selectedValidFromDate}
                />
              </Form.Item>
              <Form.Item name="validTo" label=" This card is valid to:">
                <DatePicker
                  onChange={(selectedDate) => setValidToDate(selectedDate || moment())}
                  defaultValue={selectedValidToDate}
                />
              </Form.Item>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateOrSelectRFIDStep;
