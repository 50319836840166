import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import WebhooksService from 'services/integration/webhooks.service';
import { formatDefaultDate } from 'utils/date.helper';

import WebhooksFilters from '../components/WebhooksFilters';

const columns: ColumnsType<any> = [
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (date: string) => formatDefaultDate(date),
  },
  {
    title: 'Url',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: 'Subscription',
    dataIndex: 'subscription',
    key: 'subscription',
  },
  {
    title: 'ApiClientId',
    dataIndex: 'apiClientId',
    key: 'apiClientId',
  },
];

const WebhooksPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Webhooks"
    columns={columns}
    resource={WebhooksService}
    customFilters={WebhooksFilters}
  />
);
export default WebhooksPage;
