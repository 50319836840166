import * as React from 'react';

interface Props {
  firstEntry: any;
  secondEntry: any;
  firstTitle: string;
  secondTitle: string;
}

const JsonTwoEntriesTab: React.FC<Props> = ({
  firstEntry,
  firstTitle,
  secondEntry,
  secondTitle,
}: Props) => {
  if (!firstEntry && !secondEntry) {
    return <div>Loading</div>;
  }

  return (
    <>
      <div>
        <h4>{firstTitle}</h4>
        <pre>{JSON.stringify(firstEntry, null, 2)}</pre>
      </div>
      <div>
        <h4>{secondTitle}</h4>
        <pre>{JSON.stringify(secondEntry, null, 2)}</pre>
      </div>
    </>
  );
};

export default JsonTwoEntriesTab;
