import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { DatePicker, Form, Select, SubmitButton, Switch } from 'formik-antd';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import {
  InviteLinkType,
  ITendergyClientRequestPool,
  ITendergyInvitationLink,
} from 'store/tendegyService/tendergyService.types';

import { InvitationLinkValidation } from '../../invitation-link.validation';

interface ICreateCarOwnerLinkStep {
  entry: ITendergyInvitationLink;
  onSubmitStep: (entry: ITendergyInvitationLink) => void;
}

const InvitationLinkBaseStep: React.FC<ICreateCarOwnerLinkStep> = ({
  entry,
  onSubmitStep,
}: any) => {
  const validation = Yup.object().shape(InvitationLinkValidation);

  return (
    <div>
      <Formik
        initialValues={entry as any}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, errors }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item required name="type" label="Type">
              <Select name="type">
                <Select.Option value={InviteLinkType.installer}>Für Installeteure</Select.Option>
                <Select.Option value={InviteLinkType.user}>For Enduser</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="expires" label="Expiry">
              <DatePicker inputReadOnly name="expires" placeholder="" />
            </Form.Item>

            {values.type === InviteLinkType.installer && (
              <Form.Item required name="organization" label="Organization">
                <EntitySelector
                  current={entry.organization}
                  onSelect={(organization) => setFieldValue('organization', organization)}
                  entityService={TendergyOrganizationService}
                  name="organization"
                />
              </Form.Item>
            )}

            {values.type === InviteLinkType.user && (
              <Form.Item required name="clientRequestPool" label="Client Request Pool">
                <EntitySelector
                  name="clientRequestPool"
                  current={values.clientRequestPoolId}
                  onSelect={(pool: ITendergyClientRequestPool) => {
                    setFieldValue('clientRequestPool.id', pool.id);
                  }}
                  entityService={ClientRequestPoolService}
                />
              </Form.Item>
            )}

            <Form.Item name="isEnabled" label="Is Enabled">
              <Switch name="isEnabled" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InvitationLinkBaseStep;
