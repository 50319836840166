import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import ProductsService from 'services/shop/shop-products.service';
import { IShopCategory, IShopProduct } from 'services/shop/shop-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { CurrencyUtils } from 'utils/currencyUtils';

const columns: ColumnsType<IShopProduct> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    render: (value: IShopCategory) => (value?.name ? value.name : '-'),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value: number) => CurrencyUtils.defaultFormatter(value / 100),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
];

const params: IGetMultiEntityPayload = {
  relations: ['category'],
};

const ProductsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Products"
    columns={columns}
    defaultPayload={params}
    resource={ProductsService}
  />
);
export default ProductsOverviewPage;
