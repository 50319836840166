import config from 'config';

import { ISA_FEEDBACKS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAFeedback } from '../isa-service.types';

const ISAFeedbacksService = new EntityService<IISAFeedback>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_FEEDBACKS_ENDPOINT,
  detailLink: links.isaFeedbacksDetails,
});

ISAFeedbacksService.getDisplayName = (entry) => entry.description || entry.id;

export default ISAFeedbacksService;
