import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import {
  ITendergyClientRequestPool,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';

export interface IUsersFilters {
  query: null | string;
  organization: null | string;
  currentPool: null | string;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const UsersFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IUsersFilters>({
    query: null,
    organization: null,
    currentPool: null,
  });

  useEffect(() => {
    onFilter && onFilter(value);
  }, [value]); // eslint-disable-line

  const queryInputProps = {
    placeholder: 'Search by name...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };

  const poolEntitySelectorProps = {
    placeholder: 'Select Client Request Pool',
    name: 'installerOrganization',
    allowClear: true,
    value: value.currentPool,
    style: { width: '100%' },
    entityService: ClientRequestPoolService,
    onSelect: (pool: ITendergyClientRequestPool) => {
      setValue({ ...value, currentPool: pool?.id || null });
    },
  };

  const organizationEntitySelectorProps = {
    placeholder: 'Select Organization',
    name: 'installerOrganization',
    allowClear: true,
    value: value.organization,
    style: { width: '100%' },
    entityService: TendergyOrganizationService,
    onSelect: (organization: ITendergyOrganization) => {
      setValue({ ...value, organization: organization?.id || null });
    },
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col span={8}>
        <Input {...queryInputProps} />
      </Col>
      <Col span={8}>
        <EntitySelector {...organizationEntitySelectorProps} />
      </Col>
      <Col span={8}>
        <EntitySelector {...poolEntitySelectorProps} />
      </Col>
    </Row>
  );
};

export default UsersFilters;
