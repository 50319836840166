import React from 'react';
import { ContactsOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import DashboardTableContainer from 'components/containers/DashboardTableContainer/DashboardTableContainer';
import links from 'framework/links';
import VendorService from 'services/tendergy/tendergy-vendor.service';
import { IProductVendor } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

import styles from './DashboardPage.module.sass';

const columns: ColumnsType<IProductVendor> = [
  {
    title: 'Vendor name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const VendorTable: React.FC = () => (
  <DashboardTableContainer
    columns={columns}
    resource={VendorService}
    title="Product vendors"
    basicUrl={links.productVendors}
    icon={<ContactsOutlined className={styles.icon} />}
  />
);

export default VendorTable;
