import * as React from 'react';
import { Card, Col, Row } from 'antd';

import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { ITendergyLead } from 'store/tendegyService/tendergyService.types';

import LeadAddressComponent from '../../components/LeadAddressComponent';
import LeadDetailsComponent from '../../components/LeadDetailsComponent';

import styles from './LeadOverviewTab.module.sass';
/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const LeadOverviewTab: React.FC<IGenericEditTabProps<ITendergyLead>> = (
  props: IGenericEditTabProps<ITendergyLead>,
) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  return (
    <div>
      <Row justify="space-between">
        <Col span={24}>
          <Card>
            <LeadDetailsComponent lead={entry} onSave={onSave} />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24} className={styles.rowMargin}>
          <Card>
            <LeadAddressComponent entry={entry} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LeadOverviewTab;
