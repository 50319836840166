import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import TendergyRequestService from 'services/tendergy/tendergy-request.service';

import * as actions from './request.actions';
import {
  GET,
  GetRequestAction,
  TRANSITION,
  TransitionRequestAction,
  UNARCHIVE_REQUEST,
  UnarchiveAction,
} from './request.types';

export function* get({ payload }: GetRequestAction) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(TendergyRequestService.getById, payload);
    yield put(actions.set(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* transition({ payload }: TransitionRequestAction) {
  try {
    yield call(TendergyRequestService.transition, payload.type, payload.id, payload.rejectReason);
  } catch (err) {
    message.error(err);
  }
}

export function* unarchive({ payload }: UnarchiveAction) {
  try {
    if (payload.id) {
      yield call(TendergyRequestService.unarchive, payload.id);
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;

    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    message.error(message);
  }
}

export default function* () {
  yield all([
    takeLatest(GET, get),
    takeLatest(TRANSITION, transition),
    takeLatest(UNARCHIVE_REQUEST, unarchive),
  ]);
}
