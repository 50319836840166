import { BILLING_ROUTES } from './billing';
import { BILLING_V2_ROUTES } from './billing-v2';
import { BILLING_V3_ROUTES } from './billing-v3';
import { CONSULTANT_ROUTES } from './consultant';
import { EMAIL_ROUTES } from './email';
import { INTEGRATION_ROUTES } from './integration';
import { SERVICE_APP_ROUTES } from './service-app';
import { SHOP_ROUTES } from './shop';
import { TENDERGY_ROUTES } from './tendergy';

export const ROUTES_LIST = [
  ...TENDERGY_ROUTES,
  ...INTEGRATION_ROUTES,
  ...BILLING_ROUTES,
  ...BILLING_V2_ROUTES,
  ...BILLING_V3_ROUTES,
  ...SHOP_ROUTES,
  ...SERVICE_APP_ROUTES,
  ...EMAIL_ROUTES,
  ...CONSULTANT_ROUTES,
];
