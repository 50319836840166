import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import { IShopStore } from 'services/shop/shop-service.types';
import StoreService from 'services/shop/shop-stores.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import StoreActionsComponent from './components/StoreActionsComponent/StoreActionsComponent';
import StoreCustomizationTab from './tabs/StoreCustomizationTab';
import StoreLandingsTab from './tabs/StoreLandingsTab';
import StoreOverviewTab from './tabs/StoreOverviewTab';

const options = {
  scheme: {
    background: {
      primaryValue: '#fafafa',
      inverseValue: '#2c3440',
      title: 'Background',
      type: 'color',
    },
    primary: {
      primaryValue: '#0bb537',
      inverseValue: '#ffffff',
      title: 'Primary',
      type: 'color',
    },
    error: {
      primaryValue: '#f60202',
      inverseValue: '#ffffff',
      title: 'Error',
      type: 'color',
    },
    success: {
      primaryValue: '#0bb537',
      inverseValue: '#ffffff',
      title: 'Success',
      type: 'color',
    },
    info: {
      primaryValue: '#2196f3',
      inverseValue: '#ffffff',
      title: 'Info',
      type: 'color',
    },
    warning: {
      primaryValue: '#FFE522',
      inverseValue: '#ffffff',
      title: 'Warning',
      type: 'color',
    },
    mainTitle: {
      value: 'string',
      title: 'Shop name',
      type: 'text',
    },
    fontFamily: {
      value: 'Noto Sans',
      title: 'Font',
      type: 'fontfamily',
    },
    buttonType: {
      value: 'contained',
      title: 'Button border',
      type: 'buttonType',
    },
    buttonCorner: {
      value: 6,
      title: 'Button corner',
      type: 'number',
    },
    mainLogo: {
      value: '/images/logo.svg',
      title: 'Main image',
      type: 'image',
    },
  },
};

interface IParams {
  id: string;
  page: string;
}

const StoreDetailsPage: React.FC = () => {
  const { id, page } = useParams<IParams>();

  const getCustomizationOptions = async () => {};

  useEffect(() => {
    getCustomizationOptions();
  }, []);

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['categories', 'address'],
  };

  const tabs = [
    {
      name: 'details',
      displayName: 'Details',
      render: (entity: IShopStore, optionalProps?: ICrudDetailViewCallbacks) => (
        <StoreOverviewTab entry={entity} onSave={optionalProps?.onSave} pageKey="details" />
      ),
    },
    {
      name: 'store-customization',
      displayName: 'Store Customization',
      render: () => <StoreCustomizationTab customization={options} />,
    },
    {
      name: 'landings',
      displayName: 'Landings',
      render: (entity: IShopStore) => <StoreLandingsTab store={entity} />,
    },
  ];

  const renderActions = () => <StoreActionsComponent currentTab={page} />;

  return (
    <CrudDetailContainer
      entityName="Store"
      tabs={tabs}
      baseLink={links.shopStore}
      defaultPayload={params}
      resource={StoreService}
      renderActions={renderActions}
    />
  );
};

export default StoreDetailsPage;
