import { IPagination } from 'framework/models';
import { IAuthUser } from 'store/authService/authService.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import { ITendergyUser } from '../../tendegyService/tendergyService.types';

export const GET_CRAFTUSER = 'user/GET_CRAFTUSER';
export const GET_AUTHUSER = 'user/GET_AUTHUSER';
export const GET_AUTHUSERS = 'user/GET_USERS';
export const SET_CRAFTUSER = 'user/SET_CRAFTUSER';
export const SET_USERS = 'user/SET_USERS';
export const SET_USER_AUTH = 'user/SET_USER_AUTH';

export const SET_LOADING_STATUS = 'user/SET_LOADING_STATUS';
export const RESET = 'user/RESET';

export interface UserState {
  data: ITendergyUser | null;
  loading: boolean;
  usersData: IAuthUser[] | null;
  userAuthData: IAuthUser | null;
}

export interface GetUserAction {
  type: typeof GET_CRAFTUSER;
  payload: IGetSingleEntityPayload;
}

export interface GetUserAuthAction {
  type: typeof GET_AUTHUSER;
  payload: IGetSingleEntityPayload;
}

export interface GetUsersAction {
  type: typeof GET_AUTHUSERS;
}

export interface SetUserAction {
  type: typeof SET_CRAFTUSER;
  payload: ITendergyUser;
}

export interface SetUsersAction {
  type: typeof SET_USERS;
  payload: IPagination<IAuthUser>;
}

export interface SetUserAuthAction {
  type: typeof SET_USER_AUTH;
  payload: IAuthUser;
}

export interface SetLoadingStatusAction {
  type: typeof SET_LOADING_STATUS;
  payload: boolean;
}

export interface ResetAction {
  type: typeof RESET;
}

export type UserActionTypes =
  | GetUserAction
  | SetUserAction
  | SetLoadingStatusAction
  | ResetAction
  | SetUsersAction
  | GetUsersAction
  | GetUserAuthAction
  | SetUserAuthAction;
