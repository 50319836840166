import React from 'react';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillOrganizationService from 'services/billing-v3/billing-v3-organizations.service';
import { IBillingOrganization } from 'services/billing-v3/billing-v3-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { formatDefaultDate } from 'utils/date.helper';

import Filters from './filter';

const columns: ColumnsType<IBillingOrganization> = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Customer Number',
    dataIndex: 'customerNumber',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Support Mail',
    dataIndex: 'supportMail',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Country',
    dataIndex: 'address',
    sorter: true,
    align: 'center',
    render: (address: any) => {
      if (address && address.country) {
        return `${address?.country?.nameDe} - ${address?.country?.nameEn}`;
      }
      return '-';
    },
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Support PC@Work',
    dataIndex: 'stripeId',
    render: (stripeId: string) =>
      stripeId ? <Tag color="green">Support</Tag> : <Tag color="red">No Support</Tag>,
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Support Number',
    dataIndex: 'supportNumber',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Modified',
    dataIndex: 'updatedAt',
    width: '150px',
    render: (date: Date) => formatDefaultDate(date),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Created At ',
    width: '150px',
    dataIndex: 'createdAt',
    render: (date: Date) => formatDefaultDate(date),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const params: IGetMultiEntityPayload = {};

const BillOrganizationsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Organizations"
    columns={columns}
    customFilters={Filters}
    defaultPayload={params}
    resource={BillOrganizationService}
  />
);
export default BillOrganizationsOverviewPage;
