import * as Yup from 'yup';

const weekDaySchema = Yup.number()
  .min(0)
  .max(7)
  .default(0)
  .transform((value, originalValue) => (!value || String(originalValue).trim() === '' ? 0 : value));

export const StoreValidation = {
  name: Yup.string().required('Required').min(2, 'Too short!').max(50, 'Too long!'),
  publicUrl: Yup.string().required('Required').min(2, 'Too short!').max(300, 'Too long!'),
  registrationId: Yup.string().nullable().min(1, 'Too short!').max(30, 'Too long!'),
  paymentMethods: Yup.array().required('Required!').of(Yup.string()),

  workingHoursStart: Yup.date().nullable().default(new Date(0, 0, 0, 8, 0, 0, 0)),
  workingHoursEnd: Yup.date().nullable().default(new Date(0, 0, 0, 17, 0, 0, 0)),
  workingWeekStart: weekDaySchema
    .test(
      'lessThan',
      'Week start value must be less than week end value',
      function (value: number) {
        if (!value) return false;
        return +this.parent.workingWeekEnd > value;
      },
    )
    .default(1),
  workingWeekEnd: weekDaySchema
    .test(
      'moreThan',
      'Week end value must be more than week start value',
      function (value: number) {
        if (!value) return false;
        return +this.parent.workingWeekStart < value;
      },
    )
    .default(5),
  serviceEmail: Yup.string().nullable(),
  servicePhone: Yup.string().nullable(),
};
