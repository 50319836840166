import * as React from 'react';
import { Button, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import BillDocumentService from 'services/billing-v2/billing-v2-documents.service';
import { IBillingDocument, IBillingInvoice } from 'services/billing-v2/billing-v2-service.types';
import { formatDateFromIso } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

const InvoiceDetailsTab: React.FC<IGenericEditTabProps<IBillingInvoice>> = (
  props: IGenericEditTabProps<IBillingInvoice>,
) => {
  const { entry } = props;
  if (!entry) {
    return <div>data entry is missing</div>;
  }

  async function downloadInvoice(doc: IBillingDocument) {
    try {
      BillDocumentService.downloadDocument(doc);
    } catch (e) {
      showErrorNotification(e);
    }
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (doc: IBillingDocument) => doc.displayId + doc.extName,
      width: 120,
      fixed: true,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Type',
      key: 'type',
      render: (doc: IBillingDocument) => doc.type,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      width: 200,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (date: Date) => formatDateFromIso(date),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      width: 150,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (doc: IBillingDocument) => (
        <Button onClick={() => downloadInvoice(doc)}>Download</Button>
      ),
      width: 150,
    },
  ];

  return <Table scroll={{ x: '100%' }} columns={columns} dataSource={entry.documents} />;
};

export default InvoiceDetailsTab;
