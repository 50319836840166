import * as React from 'react';
import { Descriptions } from 'antd';

import { ITendergyUser } from 'store/tendegyService/tendergyService.types';

interface Props {
  owner?: ITendergyUser;
}

const ClientRequestOwnerComponent: React.FC<Props> = ({ owner }: Props) => {
  if (!owner) {
    return <div> no answers available</div>;
  }

  return (
    <div>
      <Descriptions title="Client" column={1} layout="horizontal">
        <Descriptions.Item label="Name">
          {owner.firstName} {owner.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="E-Mail">{owner.email}</Descriptions.Item>
        <Descriptions.Item label="Phone">{owner.phone}</Descriptions.Item>
        <Descriptions.Item label="Registraton Date">{owner.createdAt}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default ClientRequestOwnerComponent;
