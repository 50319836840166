import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Spin, { SpinProps } from 'antd/lib/spin';
import clsx from 'clsx';

import styles from './styles.module.sass';

interface LoaderProps extends SpinProps {
  children?: any;
  className?: string;
  selfContained?: boolean;
  fluid?: boolean;
}

const Loader: React.FC<LoaderProps> = ({
  children,
  selfContained = true,
  fluid,
  className,
  ...props
}: LoaderProps) => (
  <Spin
    {...props}
    /* eslint-disable-next-line react/destructuring-assignment */
    indicator={<LoadingOutlined spin style={!props.size ? { fontSize: 24 } : {}} />}
    className={clsx(
      styles.spinner,
      {
        [styles.selfContained]: selfContained,
        [styles.fluid]: fluid,
      },
      className,
    )}
  >
    {children}
  </Spin>
);

export default Loader;
