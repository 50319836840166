import chargingUIStructure from './SchemaStructures/chargingUISchemaStructure.json';
import multipleUIStructure from './SchemaStructures/multipleUISchemaStructure.json';
import radioUIStructure from './SchemaStructures/radioUISchemaStructure.json';
import radioWithCustomInputUIStructure from './SchemaStructures/radioWithCustomInputUISchemaStructure.json';
import singleUIStructure from './SchemaStructures/singleUISchemaStructure.json';
import surfaceUIStructure from './SchemaStructures/surfaceUISchemaStructure.json';

enum questionFieldType {
  RadioFields = 'radioFields',
  ChargingStationFields = 'chargingStationFields',
  SurfaceFields = 'surfaceFields',
  MultipleInputFields = 'multipleInputFields',
  SingleInputFields = 'singleInputFields',
}

export type questionType =
  | 'singleInput'
  | 'radio'
  | 'radioWithCustomInput'
  | 'multipleInput'
  | 'chargingStation'
  | 'surface';

export const questionUISchemaBuilder = (type: questionType) => {
  switch (type) {
    case 'singleInput':
      return singleUIStructure;
    case 'radio':
      return radioUIStructure;
    case 'radioWithCustomInput':
      return radioWithCustomInputUIStructure;
    case 'multipleInput':
      return multipleUIStructure;
    case 'chargingStation':
      return chargingUIStructure;
    case 'surface':
      return surfaceUIStructure;
  }
};

export const uiSchemaBuilder = (uiSchema: any) => {
  const normalElements = uiSchema.elements.map((item: any) => {
    item.type = 'Control';
    return item;
  });
  return { type: 'Group', elements: normalElements };
};

const schemaBuilder = (uiSchema: any) => {
  let schema: { [key: string]: any } = {};

  uiSchema.elements.map((question: any) => {
    let questionKey = question.scope.replace('#/properties/', '');
    let questionType: questionType = question.options.format;
    let questionSchema: any = {};
    const questionFieldMapper = {
      radio: questionFieldType.RadioFields,
      radioWithCustomInput: questionFieldType.RadioFields,
      chargingStation: questionFieldType.ChargingStationFields,
      surface: questionFieldType.SurfaceFields,
      multipleInput: questionFieldType.MultipleInputFields,
      singleInput: questionFieldType.SingleInputFields,
    };
    const questionFields = questionFieldMapper[questionType];
    let enumData = [];
    const questionOptions =
      question.options[questionFields] && question.options[questionFields].options;
    if (questionOptions) enumData = questionOptions.map((option: any) => option.value);

    switch (questionType) {
      case 'singleInput':
        questionSchema['type'] = 'string';
        break;

      case 'radio':
        questionSchema['enum'] = enumData;
        questionSchema['type'] = 'string';
        break;

      case 'radioWithCustomInput':
        questionSchema['type'] = 'object';
        questionSchema['properties'] = {
          selectedOption: {
            type: 'string',
            enum: [...enumData, 'other'],
          },
          text: { type: 'string' },
        };
        break;

      case 'multipleInput': {
        let properties: any = {};
        const multipleInputFields = question.options[questionFields];
        if (multipleInputFields && multipleInputFields.inputs) {
          multipleInputFields.inputs.map((input: any) => {
            properties[input.key] = {
              type: 'string',
            };
            if (input.pattern) properties[input.key].pattern = input.pattern;
            if (input.minLength) properties[input.key].minLength = input.minLength;
            if (input.maxLength) properties[input.key].maxLength = input.maxLength;
            return properties;
          });
          if (multipleInputFields.booleanOption)
            properties[multipleInputFields.booleanOption.key] = { type: 'boolean' };
        }
        questionSchema['type'] = 'object';
        questionSchema['properties'] = properties;
        break;
      }

      case 'chargingStation':
        questionSchema['type'] = 'object';
        questionSchema['properties'] = {
          answer: { type: 'string', enum: enumData },
          company: { type: 'string' },
          model: { type: 'string' },
          otherCompany: { type: 'string' },
          otherModel: { type: 'string' },
        };
        break;

      case 'surface':
        questionSchema['type'] = 'array';
        questionSchema['items'] = {
          type: 'object',
          properties: {
            surfaceCode: {
              type: 'string',
              enum: enumData,
            },
            distance: { type: 'string' },
          },
        };
    }
    schema[questionKey] = questionSchema;
    return schema;
  });

  return { type: 'object', properties: schema };
};

export default schemaBuilder;
