import * as React from 'react';
import { DatePicker, DatePickerProps, Form, FormItemProps } from 'formik-antd';

type TDefProps = Partial<FormItemProps & DatePickerProps>;
export type IFormDatePickerProps = TDefProps & {
  name: string;
};

function FormDatePicker(props: IFormDatePickerProps) {
  const { name, ...rest } = props;

  return (
    <Form.Item name={name} {...rest}>
      <DatePicker name={name} style={{ width: '100%' }} />
    </Form.Item>
  );
}

export default FormDatePicker;
