import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import CarModelsService from 'services/shop/car-models.service';
import { IShopCarModel } from 'services/shop/shop-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import CarModelOverviewTab from './tabs/CarModelOverviewTab';

const CarModelDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IShopCarModel, optionalProps?: ICrudDetailViewCallbacks) => (
        <CarModelOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  return (
    <CrudDetailContainer
      entityName="Car Model"
      tabs={tabs}
      baseLink={links.shopCarModel}
      defaultPayload={params}
      resource={CarModelsService}
    />
  );
};

export default CarModelDetailsPage;
