import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { DATE_AND_TIME } from 'framework/constants/dateFormats';
import AppointmentService from 'services/tendergy/tendergy-appointment.service';
import {
  AppointmentPurpose,
  IOffer,
  ITendergyAppointment,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

import AppointmentsFilters from '../components/AppointmentsFilters';

const payload = {
  sortFields: 'createdAt',
  sortDirections: '-1',
};

const columns: ColumnsType<ITendergyAppointment> = [
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date: string) => formatDefaultDate(date, DATE_AND_TIME),
  },
  {
    title: 'Offer',
    dataIndex: 'offer',
    key: 'offer',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (offer: IOffer | null) => offer?.displayId || '-',
  },
  {
    title: 'Proposed By',
    dataIndex: 'proposedBy',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'proposedBy',
  },
  {
    title: 'Purpose',
    dataIndex: 'purpose',
    key: 'purpose',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (value: AppointmentPurpose | null) => value || '–',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date: string) => formatDefaultDate(date),
  },
];

const AppointmentsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Appointments"
    defaultPayload={payload}
    columns={columns}
    resource={AppointmentService}
    customFilters={AppointmentsFilters}
  />
);
export default AppointmentsOverviewPage;
