import * as Yup from 'yup';

export const INITIAL_VALUES = {
  title: null,
  problems: null,
};

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .max(256, 'Field has not to be longer than 256 characters!')
    .required('Required field'),
  problems: Yup.array().nullable(),
});
