import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IUploadFilePayload } from 'store/storeInterfaces';
import { ITendergyQuestionnaireStep } from 'store/tendegyService/tendergyService.types';

class QuestionnaireStepServiceClass extends EntityService<ITendergyQuestionnaireStep> {
  getDisplayName(entry: any): string {
    return entry?.title;
  }

  uploadFile = (requestPayload: Omit<IUploadFilePayload, 'entityId'>) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const data = new FormData();
    data.append('image', requestPayload.document);
    data.append('category', requestPayload.category);

    return this.post(`${this.config.entityURL}/upload-image`, data, true, headers);
  };
}

const TendergyQuestionnaireStepService = new QuestionnaireStepServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/questionnaire-steps',
  detailLink: links.tendergyQuestionnaireStep,
  createLink: links.tendergyCreateQuestionnaireStep,
});

export const TendergyCreateQuestionnaireStepService = (
  questionnaireId: string | null,
  version: number,
) => {
  return new QuestionnaireStepServiceClass({
    apiURL: config.CRAFT_SERVICE_URL,
    entityURL: 'admin/questionnaire-steps',
    detailLink: links.tendergyQuestionnaireStep,
    createLink:
      questionnaireId && version
        ? `${links.tendergyCreateQuestionnaireStepBaseURL}/${questionnaireId}/${version}`
        : links.tendergyCreateQuestionnaireStep,
  });
};

export default TendergyQuestionnaireStepService;
