import * as Yup from 'yup';

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string().max(256, 'Field has not to be longer than 256 characters!')
  .required('This field is required!'),
  lastName: Yup.string().max(256, 'Field has not to be longer than 256 characters!')
  .required('This field is required!'),
  email: Yup.string()
    .max(256, 'Field has not to be longer than 256 characters!')
    .required('This field is required!'),
  fingerprint: Yup.string().max(256, 'Field has not to be longer than 256 characters!')
  .required('This field is required!'),
  password: Yup.string().max(256, 'Field has not to be longer than 256 characters!')
  .required('This field is required!'),
  generateCoordinates: Yup.boolean().nullable(),
  address: Yup.object().shape({
    address: Yup.string()
      .max(256, 'Field has not to be longer than 256 characters!'),
    city: Yup.string()
      .max(64, 'Field has not to be longer than 64 characters!'),
    region: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
    country: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
    postalCode: Yup.string()
      .max(10, 'Field has not to be longer than 10 characters!'),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
    iso3166_1_alpha_3: Yup.string()
      .nullable()
      .max(3, 'Field has not to be longer than 3 characters!'),
  }),
  billing: Yup.object().shape({
    iban: Yup.string().max(256, 'Field has not to be longer than 256 characters!'),
    receiverName: Yup.string().max(256, 'Field has not to be longer than 256 characters!'),
  })
});
