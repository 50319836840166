import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IBillingRFIDCard } from 'services/billing/billing-service.types';

import styles from './styles.module.sass';

interface RFIDActionsComponentProps {
  entry: IBillingRFIDCard;
}

const RFIDActionsComponent: React.FC<RFIDActionsComponentProps> = ({
  entry,
}: RFIDActionsComponentProps) => {
  const history = useHistory();

  return (
    <div className={styles.containerOuter}>
      {entry?.carOwnerRFIDCards && entry?.carOwnerRFIDCards[0]?.carOwner && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={() =>
              history.push(
                createLink(links.billingCarOwner, {
                  id: entry.carOwnerRFIDCards && entry.carOwnerRFIDCards[0].carOwner.id,
                }),
              )
            }
          >
            Go to Car Owner
          </Button>
        </div>
      )}
    </div>
  );
};

export default RFIDActionsComponent;
