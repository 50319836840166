import * as React from 'react';
import { useSelector } from 'react-redux';
import { FieldArray, Formik, FormikValues } from 'formik';
import { Form, Input, Radio, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import ClientIdEntitySelector from 'components/forms/organization/ClientIdEntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { UserRole } from 'framework/constants';
import { AuthUserValidation } from 'pages/auth/users/users.validation';
import EntityService from 'services/abstract/entity.service';
import ApiClientService from 'services/auth/api-client.service';
import UserAuthService from 'services/auth/user-auth.service';
import { userSelector } from 'store/auth/auth.selectors';
import { IAuthUser } from 'store/authService/authService.types';

import styles from './UserOverviewTab.module.sass';

interface OverviewProps {
  entry?: IAuthUser;
  onSave: (updatedValues: any, resource: EntityService<any>) => Promise<any>;
}

const UserOverviewTab: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { entry, onSave } = props;
  const userProfile = useSelector(userSelector);
  const canEditRoles = userProfile.permissions?.includes('manage_user_roles');

  if (!props) {
    return <div>data entry is missing</div>;
  }

  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const languageCode = entry.language?.isoCode;
  const entryToRender = { ...entry, language: languageCode };

  const validation = Yup.object().shape(
    {
      ...AuthUserValidation,
      phone: Yup.string().when('phone', {
        is: (value: string) => value?.length > 0,
        // @ts-ignore
        then: Yup.string().phone('DE'),
        otherwise: Yup.string().nullable(),
      }),
    },
    [['phone', 'phone']],
  );

  const renderRolesArray = (values: FormikValues) => (
    <FieldArray
      name="roles"
      render={(arrayHelpers) => (
        <div className={styles.rolesWrapper}>
          {Object.values(UserRole).map((role: string) => (
            <div key={role}>
              <div>
                <input
                  name="roles"
                  type="checkbox"
                  value={role}
                  disabled={!canEditRoles}
                  checked={values.roles.includes(role)}
                  onChange={(e) => {
                    if (e.target.checked) arrayHelpers.push(role);
                    else {
                      const idx = values.roles.indexOf(role);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                {` ${role}`}
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );

  return (
    <div>
      <Formik
        initialValues={entryToRender as any}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values, UserAuthService).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, setFieldValue, values, isSubmitting, handleChange }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="firstName" label="First name">
              <Input name="firstName" placeholder="" />
            </Form.Item>
            <Form.Item name="lastName" label="Last Name">
              <Input name="lastName" placeholder="" />
            </Form.Item>
            <Form.Item name="title" label="Title">
              <Input name="title" placeholder="" />
            </Form.Item>
            <Form.Item name="gender" label="Gender">
              <Radio.Group name="gender" onChange={handleChange}>
                {['Female', 'Male', 'Other'].map((g, i) => (
                  <Radio name="gender" value={i} key={g}>
                    {g}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input name="email" placeholder="" />
            </Form.Item>
            <Form.Item name="phone" label="Phone">
              <Input name="phone" />
            </Form.Item>
            <Form.Item name="fax" label="Fax">
              <Input name="fax" placeholder="" />
            </Form.Item>
            <Form.Item name="roles" label="Roles">
              {renderRolesArray(values)}
            </Form.Item>

            <Form.Item name="language" label="Language">
              <Select name="language">
                <Select.Option value="en">en</Select.Option>
                <Select.Option value="de">de</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="createdByClientId" label="Created By Client Id">
              <ClientIdEntitySelector
                name="createdByClientId"
                entityService={ApiClientService}
                current={entry?.createdByClientId}
                onSelect={(entry: any) => setFieldValue('createdByClientId', entry.clientId)}
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserOverviewTab;
