import React from 'react';

import { FormEntitySelect, FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormEntitySelectProps } from 'components/forms/FormEntitySelect';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { ISAProblemsService } from 'services/isa-service';
import { IISAProblemCategory } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISAProblemCategory;
  onSubmit: (entry: IISAProblemCategory) => Promise<IISAProblemCategory>;
}

export default function ISAProblemCategoriesForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISAProblemCategory> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISAProblemCategory,
  };

  const problemCategoryProps: IFormEntitySelectProps = {
    label: 'Problems',
    name: 'problems',
    entityService: ISAProblemsService,
    current: entry.problems,
    mode: 'multiple',
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="title" label="Problem Category Title" required />
      <FormEntitySelect {...problemCategoryProps} />
      <FormSubmit />
    </FormikForm>
  );
}
