import React from 'react';

import { FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { IISAAddress } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISAAddress;
  onSubmit: (entry: IISAAddress) => Promise<IISAAddress>;
}

export default function ISAAddressesForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISAAddress> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISAAddress,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="city" label="City" required />
      <FormInput name="postalCode" label="Postal Code" required />
      <FormInput name="street" label="Street" required />
      <FormInput name="doorNumber" label="Door Number" required />
      <FormSubmit />
    </FormikForm>
  );
}
