import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons/lib';
import { Button, Popconfirm, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { userSelector } from 'store/auth/auth.selectors';
import { ApprovalState, ITendergyUser } from 'store/tendegyService/tendergyService.types';

import styles from './UserActionsComponent.module.sass';

interface Props {
  entry?: ITendergyUser;
  onDelete: () => void;
  rejectInstaller: (rejectReason: string) => void;
  confirmInstaller: () => void;
  passwordRecover: () => void;
  loginAsUser: () => void;
  setPassword: () => void;
  emailVerification: () => void;
  isEmailVerified?: boolean;
}

const UserActionsComponent: React.FC<Props> = (props: Props) => {
  const {
    entry,
    onDelete,
    rejectInstaller,
    confirmInstaller,
    passwordRecover,
    loginAsUser,
    setPassword,
    emailVerification,
    isEmailVerified,
  } = props;
  const history = useHistory();
  const [rejectReason, setRejectReason] = useState<string>('');
  const userProfile = useSelector(userSelector);
  const canLoginAsUser = userProfile.permissions?.includes('admin_login_as_user');
  if (!entry) {
    return <div>Entry is not set</div>;
  }
  return (
    <div className={styles.containerOuter}>
      {entry.createdByClientId && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={setPassword}
          >
            Send Set Passwort Email
          </Button>
        </div>
      )}
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={passwordRecover}
        >
          Send Password Recover Email
        </Button>
      </div>
      {!isEmailVerified && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={emailVerification}
          >
            Send E-Mail Verification E-Mail
          </Button>
        </div>
      )}
      <div className={styles.containerInner}>
        <Popconfirm
          title="Are you sure you want to delete this entry?"
          onConfirm={() => onDelete()}
        >
          <Button
            type="primary"
            className={styles.button}
            shape="round"
            size="large"
            danger
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        </Popconfirm>
      </div>

      {canLoginAsUser && (
        <div className={styles.containerInner}>
          <Button
            type="primary"
            className={styles.button}
            shape="round"
            size="large"
            onClick={loginAsUser}
          >
            Login as this user
          </Button>
        </div>
      )}

      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() => history.push(`${links.activityLogs}?initiator=${entry.id}`)}
        >
          Go to Activity Logs as initiator
        </Button>
      </div>
      {entry.organization && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={() =>
              history.push(
                createLink(links.organization, {
                  id: entry?.organization && entry.organization.id,
                }),
              )
            }
          >
            Open Organization
          </Button>
        </div>
      )}

      {/* Actions if user is installer */}
      {entry.installer && (
        <div className={styles.blockWrapper}>
          <div className={styles.containerInner}>
            <div>
              <h3>Tendergy Actions</h3>
            </div>

            <Button
              className={styles.button}
              type="primary"
              size="large"
              shape="round"
              onClick={() =>
                history.push(`${links.activityLogs}?scope=installers&affectedEntry=${entry.id}`)
              }
            >
              Go to Activity Logs
            </Button>

            {/* If installer is approved */}
            {entry?.installer?.state === ApprovalState.Approved && (
              <Button type="primary" className={styles.button} shape="round" size="large" disabled>
                Approved
              </Button>
            )}

            {/* If installer is rejected */}
            {entry?.installer?.state === ApprovalState.Rejected && (
              <Button type="primary" className={styles.button} shape="round" size="large" disabled>
                Rejected
              </Button>
            )}

            {/* If installer is NOT approved NOR rejected show actions */}
            {entry?.installer?.state === ApprovalState.Pending && (
              <>
                <Button
                  type="primary"
                  className={styles.button}
                  shape="round"
                  size="large"
                  onClick={() => confirmInstaller()}
                >
                  Approve Installer
                </Button>

                <TextArea
                  rows={5}
                  placeholder="Reject Reason"
                  onChange={(e) => setRejectReason(e.target.value)}
                  value={rejectReason}
                />
                <Tooltip title={!rejectReason && 'Please enter reason to reject installer'}>
                  <Button
                    className={styles.rejectButton}
                    type="primary"
                    danger
                    size="large"
                    shape="round"
                    disabled={!rejectReason}
                    onClick={() => rejectInstaller(rejectReason)}
                  >
                    Reject Installer
                  </Button>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      )}
      {entry.currentPool && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={() =>
              history.push(
                createLink(links.clientRequestPool, {
                  id: entry?.currentPool && entry.currentPool.id,
                }),
              )
            }
          >
            Open Pool
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserActionsComponent;
