import config from '../../config';
import links from '../../framework/links';
import EntityService from '../abstract/entity.service';
import { IConsultantParameters } from './consultant-service.types';

class ConsultantCountriesClass extends EntityService<IConsultantParameters> {
  getDisplayName(entry: IConsultantParameters): string {
    return entry?.items?.[0].entity;
  }
}

export const ConsultantCountriesService = new ConsultantCountriesClass({
  apiURL: config.CONSULTANT_SERVICE_URL,
  entityURL: 'admin/countries',
  detailLink: links.eCCountriesDetail,
});
