import React from 'react';
import { Card } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import _omit from 'lodash/omit';

import { FormEntitySelect, FormInput, FormSelect, FormSubmit } from 'components/forms';
import BillChargingStationsService, {
  BillCGPList,
  BillChargingStationAccess,
  BillChargingStationsGroupService,
  BillGetUsers,
} from 'services/billing-v3/billing-v3-charging-stations.service';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import { validationSchema } from '../validation';
import CreateCPG from './ChargingStationGroupCreatePage';

interface IChargePoint {
  id: string;
}
const ChargingStationForm = () => {
  enum EPlugTypes {
    Unknown = 'unknown',
    Chademo = 'chademo',
    Combo = 'combo',
    Type2 = 'type2',
    Type3 = 'type3',
    Nema = 'nema',
    Avcon = 'avcon',
  }
  enum EPartnerType {
    GridWare = 'gridware',
    LBC = 'lbc',
  }
  const attachCPG = async (entry: any) => {
    try {
      const resp = await BillChargingStationsGroupService.save(entry);
      if (resp !== undefined) showSuccessNotification('Charging Point Group Attached Successfully');
      else showErrorNotification('Failed to attach Charging Point Group');
      return resp;
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const accessToCP = async (entry: any) => {
    try {
      const resp = await BillChargingStationAccess.save(entry);
      if (resp !== undefined) showSuccessNotification('User Access To Charging Point Successfully');
      else showErrorNotification('Failed to attach User to Charging Point');
      return resp;
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const CreateCP = async (entry: any) => {
    try {
      const resp = await BillChargingStationsService.save(entry);
      if (resp !== undefined) showSuccessNotification('Charging Point Created Successfully');
      else showErrorNotification('Failed to create Charging Point');
      return resp;
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const createChargingStation = async (values: any) => {
    const cpData = _omit(values, ['chargePointGroup']);
    const createdCP: IChargePoint = await CreateCP(cpData);
    if (createdCP) {
      if (values.chargePointGroup) {
        const entry = {
          chargingPointId: createdCP.id,
          groupId: values.chargePointGroup.id,
        };
        attachCPG(entry);
      }
      if (values.user) {
        const entry = {
          userId: values.user.id,
          chargingPointId: createdCP.id,
        };
        accessToCP(entry);
      }
    }
  };
  const selectUserProps = {
    label: 'User',
    entityService: BillGetUsers,
    name: 'user',
    current: '',
    defaultPayload: {
      sortFields: 'firstName',
      sortDirection: -1,
    },
  };

  const selectCPGProps = {
    label: 'Charging Point Group',
    entityService: BillCGPList,
    name: 'chargePointGroup',
    current: '',
    defaultPayload: {
      sortFields: 'name',
      sortDirection: -1,
      limit: 100,
    },
  };
  const renderAddress = () => {
    return (
      <>
        <FormInput name="address.country" label="Country" />
        <FormInput name="address.iso3166_1_alpha_3" label="ISO 3166-1 alpha-3 Code" required />
        <FormInput name="address.region" label="Region" />
        <FormInput name="address.city" label="City" required />
        <FormInput name="address.address" label="Address" required />
        <FormInput name="address.postalCode" label="Postcode" required />
        <FormInput name="address.latitude" label="Latitude" required />
        <FormInput name="address.longitude" label="Longitude" required />
      </>
    );
  };

  const renderNameAndType = (values: any) => {
    return (
      <>
        <FormSelect name="type" label="Type" options={['home', 'work', 'public']} required />
        {values.type === 'home' && (
          <FormEntitySelect {...selectUserProps} style={{ width: '100%' }} />
        )}
        <FormEntitySelect {...selectCPGProps} style={{ width: '100%' }} required />
      </>
    );
  };

  return (
    <>
      <Card title="Create Charging Point" style={{ marginTop: '20px' }}>
        <Formik
          enableReinitialize
          onSubmit={createChargingStation}
          initialValues={{}}
          validationSchema={validationSchema}
          render={({ values }) => {
            return (
              <Form>
                <FormInput name="evse" label="Evse" required />
                {renderNameAndType(values)}
                <FormInput name="stationId" label="Station ID" required />
                <FormInput name="stationName" label="Station Name" required />
                <FormInput name="connectorId" label="Connector ID" required />
                <FormInput name="connectorName" label="Connector Name" required />
                <FormInput name="rentalId" label="Rental ID" required />
                <FormInput name="description" label="Charging Point Description" />
                <FormInput name="power" label="Power" required />
                <FormSelect
                  name="plugType"
                  label="Plug Type"
                  options={Object.values(EPlugTypes).map((value: string) => ({
                    value,
                    label: value,
                  }))}
                  required
                />
                <FormSelect
                  name="partnerType"
                  label="Partner Type"
                  options={Object.values(EPartnerType).map((value: string) => ({
                    value,
                    label: value,
                  }))}
                  required
                />
                {renderAddress()}
                <FormSubmit />
              </Form>
            );
          }}
        />
      </Card>
      <CreateCPG />
    </>
  );
};

export default ChargingStationForm;
