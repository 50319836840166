import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import CommentsService from 'services/tendergy/tendergy-comments.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergyComment } from 'store/tendegyService/tendergyService.types';

import CommentsActionsComponent from './components/CommentsActionsComponent/CommentsActionsComponent';
import CommentOverviewTab from './tabs/CommentOverviewTab';

const CommentDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['author', 'parentComment'],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: ITendergyComment, optionalProps?: ICrudDetailViewCallbacks) => (
        <CommentOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  const renderActions = (entry: ITendergyComment, triggerReload: Function) => (
    <CommentsActionsComponent entry={entry} triggerReload={triggerReload} />
  );

  return (
    <CrudDetailContainer
      entityName="Comment"
      tabs={tabs}
      baseLink={links.comment}
      defaultPayload={params}
      renderActions={renderActions}
      resource={CommentsService}
    />
  );
};

export default CommentDetailsPage;
