import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, InputNumber, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import CategoryService from 'services/shop/shop-categories.service';
import { IShopCategory, IShopProduct, ShopProductType } from 'services/shop/shop-service.types';

import { ProductValidation } from '../../product.validation';

import styles from './ProductTabsStyles.module.sass';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const ProductOverviewTab: React.FC<IGenericEditTabProps<IShopProduct>> = (
  props: IGenericEditTabProps<IShopProduct>,
) => {
  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(ProductValidation);
  const formattedProduct = { ...entry };
  formattedProduct.price = entry.price / 100;

  return (
    <div>
      <Formik
        initialValues={formattedProduct as IShopProduct}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          const formattedValues = { ...values };
          formattedValues.price = values.price * 100;
          onSave(formattedValues).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name" required>
              <Input name="name" placeholder="" />
            </Form.Item>
            <Form.Item name="type" label="Type" required>
              <Select name="type">
                <Select.Option value={ShopProductType.Product}>Product</Select.Option>
                <Select.Option value={ShopProductType.Service}>Service</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="description" label="Description">
              <Input name="description" placeholder="" />
            </Form.Item>
            <Form.Item name="price" label="Price (in Eur.)">
              <InputNumber
                name="price"
                type="number"
                placeholder=""
                className={styles.numberInput}
              />
            </Form.Item>
            <Form.Item name="vat" label="Vat %">
              <InputNumber name="vat" type="number" placeholder="" className={styles.numberInput} />
            </Form.Item>
            <Form.Item name="stockQuantity" label="Stock Quantity">
              <InputNumber
                name="stockQuantity"
                type="number"
                placeholder=""
                className={styles.numberInput}
              />
            </Form.Item>
            <Form.Item required name="category" label="Product category">
              <EntitySelector
                entityService={CategoryService}
                name="category"
                current={entry.category}
                onSelect={(category: IShopCategory) => setFieldValue('category', category)}
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductOverviewTab;
