import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISAAnswersService } from 'services/isa-service';
import { IISAAnswer, IParams } from 'services/isa-service/isa-service.types';

import ISAAnswersForm from './components/ISAAnswersForm';

export default function ISAAnswersDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISAAnswersForm entry={entry} onSubmit={onSave} />,
        },
      ]}
      entityName="answer"
      baseLink={links.isaAnswersDetails}
      defaultPayload={{ id }}
      resource={ISAAnswersService}
      hideJsonTab
      renderTitle={(entry: IISAAnswer) => entry.logicalName || entry.value}
    />
  );
}
