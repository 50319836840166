import config from 'config';

import EntityService from 'services/abstract/entity.service';

class EmailRenderingServiceClass extends EntityService<any> {}

const EmailRenderingService = new EmailRenderingServiceClass({
  apiURL: config.EMAIL_SERVICE_URL,
  entityURL: 'admin/render',
});

export default EmailRenderingService;
