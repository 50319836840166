import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import OrderService from 'services/shop/shop-orders.service';
import { IShopOrder, IShopOrderItem } from 'services/shop/shop-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import OrderItemsTab from './tabs/OrderItemsTab';
import OrderOverviewTab from './tabs/OrderOverviewTab';

const OrderDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['billingAddress', 'shippingAddress', 'orderItems', 'orderPayment'],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IShopOrder, optionalProps?: ICrudDetailViewCallbacks) => (
        <OrderOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
    {
      name: 'Order Items',
      displayName: 'Order Items',
      render: (entity: IShopOrderItem[]) => <OrderItemsTab entry={entity} />,
    },
  ];

  const renderTitle = (entry: IShopOrder) => `Order no. ${entry.displayId}`;

  return (
    <CrudDetailContainer
      entityName="Order"
      tabs={tabs}
      baseLink={links.shopOrder}
      defaultPayload={params}
      resource={OrderService}
      renderTitle={renderTitle}
    />
  );
};

export default OrderDetailsPage;
