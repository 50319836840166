import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';

import { FormikForm, FormInput, FormSelect, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { BillGetCountries } from 'services/billing-v3/billing-v3-charging-stations.service';
import {
  IBillCountries,
  IBillingChargingStation,
} from 'services/billing-v3/billing-v3-service.types';

import { validationSchema } from '../../validation';

import styles from './styles.module.sass';

interface BillOrganizationFormProps {
  entry?: IBillingChargingStation;
  onSubmit: (entry: IBillingChargingStation) => Promise<IBillingChargingStation>;
}

const BillOrganizationForm = (props: BillOrganizationFormProps) => {
  const { entry, onSubmit } = props;
  const handleSubmit = (values: any) => {
    let country = { ...values.address?.country };
    let pages = [...values.pages];
    if (selectedCountry) {
      country = { id: selectedCountry };
    }
    if (values.iban === entry?.iban) {
      delete values.iban;
      delete entry?.iban;
    }
    if (!pages?.[0]?.url) {
      pages = [];
    }
    if (values.stripeId === '') {
      values.stripeId = null;
    }
    if (!entry!.pages!.length && values.pages[0]?.url) {
      const date = new Date();
      pages[0].createdAt = date.toISOString();
      pages[0].organizationId = entry!.id;
      pages[0].type = 'faq';
      pages[0].updatedAt = null;
      pages[0].deletedDate = null;
    }
    onSubmit({
      ...values,
      pages,
      address: {
        ...values.address,
        ...(country?.id && { country: { ...country } }),
      },
    });
  };

  useEffect(() => {
    getCountries();
  }, []);
  const [countries, setCountries] = useState<IBillCountries[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const getCountries = async () => {
    try {
      const response = await BillGetCountries.getEntries();
      //@ts-ignore
      setCountries(response?.items);
    } catch (e) {
      setCountries(e as any);
    }
  };
  const formikProps: IFormikFormProps<IBillingChargingStation> = {
    onSubmit: handleSubmit,
    validationSchema,
    initialValues: entry as IBillingChargingStation,
  };
  const { Option } = Select;
  return (
    <FormikForm {...formikProps}>
      <FormInput name="name" label="Name" required />
      <FormInput name="customerNumber" label="Customer Number" />
      <FormInput name="supportMail" label="Support Mail" />
      <FormInput name="supportNumber" label="Support Number" />
      <FormInput name="pages[0].url" label="FAQ website" />

      <FormInput name="costObjectId" label="Cost Object ID" />
      <FormInput name="responsiblePerson" label="Responsible Person" />
      <FormInput name="personalNumberTag" label="Personal Number Tag" />
      <FormInput name="receiverName" label="Receiver Name" />
      <FormInput name="pocCustomText" label="POC Custom Text" />
      <FormInput name="bic" label="BIC" />
      <FormInput name="iban" label="Iban" />
      <FormInput name="stripeId" label="Stripe ID" />

      <FormSelect name="language" label="Language" options={['de', 'en']} required />
      <div className={styles.centered}>
        <h4>Address</h4>
      </div>
      <Row style={{ marginBottom: '24px' }}>
        <Col flex="8" style={{ fontWeight: 'bold' }}>
          Select Country:
        </Col>
        {countries.length && (
          <Col flex="16">
            <Select
              defaultValue={entry?.address?.country?.id}
              style={{ width: '100%' }}
              onChange={(value: string) => setSelectedCountry(value as any)}
            >
              {countries?.map(({ nameDe, nameEn, id }) => (
                <Option value={id} key={id}>
                  {`${nameDe} - ${nameEn}`}
                </Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>

      <FormInput name="address.region" label="Region" />
      <FormInput name="address.city" label="City" />
      <FormInput name="address.address" label="Address" />
      <FormInput name="address.postalCode" label="Postcode" />
      <FormInput name="address.latitude" label="Latitude" />
      <FormInput name="address.longitude" label="Longitude" />
      <FormSubmit />
    </FormikForm>
  );
};

export default BillOrganizationForm;
