import React, { useCallback, useRef, useState } from 'react';
import { Button, Col, Row, Select } from 'antd';

import { MediaCategoryType } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

interface IFileUploaderProps {
  onFileUpload?: (file: File, category: MediaCategoryType) => Promise<void>;
  categoriesList?: MediaCategoryType[];
  category?: MediaCategoryType;
}

const FileUploader: React.FC<IFileUploaderProps> = (props: IFileUploaderProps) => {
  const { onFileUpload, categoriesList, category } = props;

  const [selectedCategory, setSelectedCategory] = useState<MediaCategoryType | null>(
    category || null,
  );
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileUploading, setFileUploading] = useState(false);

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file || !selectedCategory || !onFileUpload) {
      return;
    }

    setFileUploading(true);

    try {
      await onFileUpload(file, selectedCategory);
    } catch (e) {
      showErrorNotification(e);
    }
    setFileUploading(false);
  };

  const convetCategoriesList = useCallback(() => {
    if (!categoriesList?.length) {
      return [];
    }
    return categoriesList.map((category) => ({ value: category, label: category }));
  }, [categoriesList]);

  const categorySelectProps = {
    options: convetCategoriesList(),
    value: selectedCategory as any,
    placeholder: 'Select Category',
    onChange: (value: MediaCategoryType) => setSelectedCategory(value),
    allowClear: true,
    style: { width: '100%' },
  };

  const uploadButtonProps = {
    type: 'primary' as any,
    loading: fileUploading,
    onClick: () => fileInput?.current?.click(),
    disabled: !selectedCategory,
  };

  return (
    <Row gutter={[16, 0]} align="middle">
      {categoriesList && (
        <>
          <Col>Select category</Col>
          <Col flex="1">
            <Select {...categorySelectProps} />
          </Col>
        </>
      )}
      <Col>
        <input type="file" style={{ display: 'none' }} ref={fileInput} onChange={onFileChange} />
        <Button {...uploadButtonProps}>Upload file</Button>
      </Col>
    </Row>
  );
};

export default FileUploader;
