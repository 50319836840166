import { ITendergyOrganization } from 'store/tendegyService/tendergyService.types';

import { IAuthOrganization } from '../authService.types';

export const GET = 'organizations/GET';
export const GET_CRAFT_ENTRIES = 'organizations/GET_CRAFT_ENTRIES';
export const SET = 'organizations/SET';
export const SET_CRAFT_ENTRIES = 'organizations/SET_CRAFT_ENTRIES';
export const SET_LOADING_STATUS = 'organizations/SET_LOADING_STATUS';
export const RESET = 'organizations/RESET';

export interface OrganizationsState {
  entries: IAuthOrganization[];
  paginationCount: number;
  loading: boolean;
  craftEntries: ITendergyOrganization[];
}

export interface AuthData {
  items: IAuthOrganization[];
  count: number;
}

export interface GetOrganizationsAction {
  type: typeof GET;
  payload: { limit: number; offset: number };
}

export interface GetCraftOrganizationsAction {
  type: typeof GET_CRAFT_ENTRIES;
}

export interface SetOrganizationsAction {
  type: typeof SET;
  payload: AuthData;
}

export interface SetCraftOrganizationsAction {
  type: typeof SET_CRAFT_ENTRIES;
  payload: ITendergyOrganization[];
}

export interface SetLoadingStatusAction {
  type: typeof SET_LOADING_STATUS;
  payload: boolean;
}

export interface ResetAction {
  type: typeof RESET;
}

export type OrganizationsActionTypes =
  | GetOrganizationsAction
  | SetOrganizationsAction
  | SetLoadingStatusAction
  | ResetAction
  | GetCraftOrganizationsAction
  | SetCraftOrganizationsAction;
