import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import { Alert, Button, message } from 'antd';
import config from 'config';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { ITendergyClientRequestPool } from 'store/tendegyService/tendergyService.types';

import styles from './styles.module.sass';

interface PoolActionsComponentProps {
  pool: ITendergyClientRequestPool;
}

const PoolActionsComponent: React.FC<PoolActionsComponentProps> = (
  props: PoolActionsComponentProps,
) => {
  const { pool } = props;
  const getLink = () => `${config.TENDERGY_UI_URL}registration/${pool.token}`;
  const history = useHistory();

  return (
    <>
      {pool?.intermediateOrganization && (
        <div className={styles.containerOuter}>
          <div className={styles.containerInner}>
            <Button
              className={styles.orgButton}
              type="primary"
              size="large"
              shape="round"
              onClick={() =>
                history.push(
                  createLink(links.organization, {
                    id: pool.intermediateOrganization?.id,
                  }),
                )
              }
            >
              Go to Intermed. Org.
            </Button>
          </div>
        </div>
      )}
      {pool?.token && (
        <div className={styles.containerOuter}>
          <div className={styles.containerInner}>
            <Alert message={getLink()} type="info" />
          </div>
          <div className={styles.containerInner}>
            <CopyToClipboard text={getLink()}>
              <Button
                className={styles.button}
                type="primary"
                size="large"
                shape="round"
                onClick={() => message.info('Invitation Link Copied')}
              >
                Copy Link
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </>
  );
};

export default PoolActionsComponent;
