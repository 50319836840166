import * as React from 'react';
import { useState } from 'react';
import CodeMirror from 'react-codemirror';
import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { CrudFormDefaultActionParams } from 'framework/abstract-crud/abstractCrud.constants';
import EmailLayoutService from 'services/email/email-layouts.service';
import EmailRenderingService from 'services/email/email-rendering.service';
import { IEmailLayout, IEmailTemplate } from 'services/email/email-service.types';
import { showErrorNotification } from 'utils/notificationUtil';

import { TemplateValidation } from '../../template.validation';

import 'codemirror/theme/material.css';
import styles from './TemplateCreateStepsStyles.module.sass';

require('codemirror/lib/codemirror.css');
require('codemirror/mode/htmlmixed/htmlmixed');

interface Props {
  entry: IEmailTemplate;
  onSubmitStep: (entry: IEmailTemplate) => Promise<IEmailTemplate>;
}

const TemplateEmailStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const [body, setBody] = useState<string>('');
  const [layoutId, setLayout] = useState<string>('');
  const [preview, setPreview] = useState<string>('');

  const onPreview = async () => {
    try {
      const response = await EmailRenderingService.post(
        'admin/render/mjml',
        { layoutId, template: body },
        true,
      );
      setPreview(response.html);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  if (!onSubmitStep) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(TemplateValidation);
  const options = {
    lineNumbers: true,
    mode: { name: 'htmlmixed' },
    theme: 'material',
    tags: {
      style: [
        ['type', /^text\/(x-)?scss$/, 'text/x-scss'],
        [null, null, 'css'],
      ],
      custom: [[null, null, 'customMode']],
    },
  };
  return (
    <Formik
      initialValues={entry as IEmailTemplate}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitStep(values).then(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form wrapperCol={{ span: 22, offset: 1 }} onFinish={handleSubmit}>
          <Form.Item name="layout" required>
            <div className={styles.codeWrapper}>Layout:</div>
            <EntitySelector
              entityService={EmailLayoutService}
              name="layout"
              onSelect={(layout: IEmailLayout) => {
                // @ts-ignore
                setLayout(layout.id);
              }}
            />
          </Form.Item>
          <Form.Item name="body" required>
            <div className={styles.codeWrapper}>Body:</div>
            <CodeMirror
              value={body}
              onChange={(value) => {
                setFieldValue('body', value);
                setBody(value);
              }}
              options={options}
            />
          </Form.Item>
          <Form.Item name="button" required>
            <Button onClick={onPreview}>Preview</Button>
          </Form.Item>
          {preview.length ? <div dangerouslySetInnerHTML={{ __html: preview }} /> : null}

          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton>Submit</SubmitButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default TemplateEmailStep;
