import React from 'react';

import {
  FormEntitySelect,
  FormikForm,
  FormInput,
  FormSelect,
  FormSubmit,
  FormTextArea,
} from 'components/forms';
import { IFormEntitySelectProps } from 'components/forms/FormEntitySelect';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { IFormSelectProps } from 'components/forms/FormSelect';
import {
  ISAAnswersService,
  ISACarModelsService,
  ISADiagnosticsService,
  ISAProblemsService,
  ISAQuestionsService,
  ISAUsersService,
  ISAWallboxesService,
} from 'services/isa-service';
import {
  EnumISAConnectionType,
  EnumISAServiceTicketStatus,
  EnumISAWallboxLocation,
  IISAServiceTicket,
} from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISAServiceTicket;
  onSubmit: (entry: IISAServiceTicket) => Promise<IISAServiceTicket>;
}

const STATUS_OPTIONS = Object.values(EnumISAServiceTicketStatus);
const CONNECTION_TYPE_OPTIONS = Object.values(EnumISAConnectionType);
const WALLBOX_LOCATION_OPTIONS = Object.values(EnumISAWallboxLocation);

export default function ISAServiceTicketsForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISAServiceTicket> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISAServiceTicket,
  };

  const wallboxProps: IFormEntitySelectProps = {
    label: 'Wallbox',
    name: 'wallbox',
    entityService: ISAWallboxesService,
    current: entry.wallbox,
  };

  const carModelProps: IFormEntitySelectProps = {
    label: 'Car model',
    name: 'carModel',
    entityService: ISACarModelsService,
    current: entry.carModel,
  };

  const problemProps: IFormEntitySelectProps = {
    label: 'Problem',
    name: 'problem',
    entityService: ISAProblemsService,
    current: entry.problem,
  };

  const diagnosticProps: IFormEntitySelectProps = {
    label: 'Diagnostic',
    name: 'diagnostic',
    entityService: ISADiagnosticsService,
    current: entry.diagnostic,
  };

  const userProps: IFormEntitySelectProps = {
    label: 'User',
    name: 'user',
    entityService: ISAUsersService,
    current: entry.user,
  };

  const connectionTypeProps: IFormSelectProps = {
    label: 'Type of connection',
    name: 'connectionType',
    options: CONNECTION_TYPE_OPTIONS,
    required: true,
  };

  const wallboxLocationProps: IFormSelectProps = {
    label: 'Location wallbox',
    name: 'wallboxLocation',
    options: WALLBOX_LOCATION_OPTIONS,
    required: true,
  };

  function renderQAs() {
    return entry.questionResponse?.map((item, index) => {
      const answerProps: IFormEntitySelectProps = {
        label: 'Answer',
        name: 'answer',
        entityService: ISAAnswersService,
        current: item?.answer,
        disabled: true,
      };

      const questionsProps: IFormEntitySelectProps = {
        label: 'Questions',
        name: 'questions',
        entityService: ISAQuestionsService,
        current: item?.question,
        disabled: true,
      };

      return (
        <React.Fragment key={index}>
          <FormEntitySelect {...questionsProps} />
          <FormEntitySelect {...answerProps} />
        </React.Fragment>
      );
    });
  }

  return (
    <FormikForm {...formikProps}>
      <FormEntitySelect {...wallboxProps} />
      <FormEntitySelect {...carModelProps} />
      <FormEntitySelect {...problemProps} />
      <FormEntitySelect {...diagnosticProps} />
      {renderQAs()}
      <FormInput name="serialNumber" label="Serial number" required />
      <FormInput name="typeReference" label="Type reference" required />
      <FormSelect {...connectionTypeProps} />
      <FormSelect {...wallboxLocationProps} />
      <FormTextArea name="issueDescription" label="Problem description" required />
      <FormEntitySelect {...userProps} />
      <FormSelect name="status" label="Status" options={STATUS_OPTIONS} />
      <FormSubmit />
    </FormikForm>
  );
}
