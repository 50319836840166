import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IShopCategory } from './shop-service.types';

class CategoryServiceClass extends EntityService<IShopCategory> {
  getDisplayName(entry: IShopCategory): string {
    return entry && entry.name;
  }
}

const CategoryService = new CategoryServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/categories',
  createLink: links.shopCreateCategory,
  detailLink: links.shopCategory,
});

export default CategoryService;
