import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import CommentsService from 'services/tendergy/tendergy-comments.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import CommentBaseStep from './steps/CommentBaseStep';

const CommentCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add comment',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <CommentBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Comment"
      steps={steps}
      baseLink={links.comments}
      defaultPayload={params}
      resource={CommentsService}
    />
  );
};

export default CommentCreatePage;
