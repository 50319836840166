import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Tooltip, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import AddressComponent from 'components/billing/address-component/AddressComponent';
import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { CarOwnerValidation } from 'pages/billing/billing-car-owners/car-owner.validation';
import BillingCarOwnerService from 'services/billing/billing-car-owner.service';
import BillingOrganizationService from 'services/billing/billing-organizations.service';
import { IBillingCarOwner, IBillingOrganization } from 'services/billing/billing-service.types';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from '../billing-create-flow.module.sass';

interface ICreateOrSelectCarOwnerStepParams {
  currentCarOwner?: IBillingCarOwner;
  onSelect: (carOwnerId: IBillingCarOwner) => void;
}

/**
 * This step should emit a carOwner Id.
 *
 *
 */
const CreateOrSelectCarOwnerStep: React.FC<ICreateOrSelectCarOwnerStepParams> = (
  props: ICreateOrSelectCarOwnerStepParams,
) => {
  const validation = Yup.object().shape(CarOwnerValidation);

  const defaultValues = {};

  const createCarOwnerAndSubmit = async (values: IBillingCarOwner) => {
    let carOwner;
    try {
      carOwner = await BillingCarOwnerService.save(values);
      props.onSelect(carOwner);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <div>
      <Typography.Title className={styles.textCenter} level={5}>
        Select existing CarOwner
      </Typography.Title>

      <div className={styles.entitySelector}>
        <EntitySelector
          name="carOwner"
          entityService={BillingCarOwnerService}
          onSelect={(carOwner: IBillingCarOwner) => props.onSelect(carOwner)}
        />
      </div>

      <Typography.Title className={styles.textCenter} level={5}>
        -- OR Create new CarOwner --
      </Typography.Title>

      <Formik
        initialValues={defaultValues}
        validationSchema={validation}
        onSubmit={(values: any) => createCarOwnerAndSubmit(values)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="firstname" label="Firstname">
              <Input name="firstname" placeholder="Peter" />
            </Form.Item>
            <Form.Item name="lastname" label="Lastname">
              <Input name="lastname" placeholder="Pahn" />
            </Form.Item>

            <Form.Item name="email" label="E-Mail">
              <Input name="email" placeholder="peter@pahn.com" />
            </Form.Item>

            <Form.Item name="employeeNumber" label="Employee number">
              <Input name="employeeNumber" placeholder="" />
            </Form.Item>

            <b>Bank Account</b>
            <hr className={styles.hr} />

            <Form.Item name="receiverName" label="Receiver Name">
              <Input name="receiverName" placeholder="" />
              <Tooltip placement="bottom" title="copy from firstname / lastname">
                <Button
                  className={styles.autofillBtn}
                  onClick={() =>
                    setFieldValue(
                      'receiverName',
                      `${(values as IBillingCarOwner).firstname} ${
                        (values as IBillingCarOwner).lastname
                      }`,
                    )
                  }
                >
                  Autofill
                </Button>
              </Tooltip>
            </Form.Item>

            <Form.Item name="iban" label="IBAN">
              <Input name="iban" placeholder="DE..." />
            </Form.Item>

            <Form.Item name="bic" label="BIC">
              <Input name="bic" placeholder="" />
            </Form.Item>

            <b>Address</b>
            <hr className={styles.hr} />

            <AddressComponent
              onChange={(address) => setFieldValue('address', address)}
              defaultEditMode
            />

            <b>Organization</b>
            <hr className={styles.hr} />

            <Form.Item name="organization" label="Organization">
              <EntitySelector
                name="organization"
                entityService={BillingOrganizationService}
                onSelect={(organization: IBillingOrganization) =>
                  setFieldValue('organization', organization)
                }
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting}
              >
                Create and Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateOrSelectCarOwnerStep;
