import * as React from 'react';
import { Card, Col, Row } from 'antd';

import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { ITendergyAppointment } from 'store/tendegyService/tendergyService.types';

import AppointmentDetailsComponent from '../../components/AppointmentDetailsComponent';
/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const AppointmentOverviewTab: React.FC<IGenericEditTabProps<ITendergyAppointment>> = (
  props: IGenericEditTabProps<ITendergyAppointment>,
) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  return (
    <div>
      <Row justify="space-between">
        <Col span={24}>
          <Card>
            <AppointmentDetailsComponent appointment={entry} onSave={onSave} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentOverviewTab;
