import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { AuthOrganizationValidation } from 'pages/auth/organizations/organizations.validation';
import { IAuthOrganization } from 'store/authService/authService.types';

interface Props {
  entry?: IAuthOrganization | null;
  onSave: (updatedValues: IAuthOrganization) => Promise<any>;
}

const AuthOrganizationTab: React.FC<Props> = (props: Props) => {
  const { entry, onSave } = props;

  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(AuthOrganizationValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IAuthOrganization}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name">
              <Input name="name" placeholder="" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthOrganizationTab;
