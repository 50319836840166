import config from 'config';

import EntityService from 'services/abstract/entity.service';

import { IBillingDocument } from './billing-v2-service.types';

class BillDocumentServiceClass extends EntityService<IBillingDocument> {
  getDisplayName(entry: IBillingDocument): string {
    return entry.toString();
  }

  downloadDocument(doc: IBillingDocument) {
    const url = this.getURL(`admin/documents/download/${doc.id}`);
    const filename = doc.displayId + doc.extName;

    this.downloadFile(url, {}, filename);
  }
}

const BillDocumentService = new BillDocumentServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/documents',
});

export default BillDocumentService;
