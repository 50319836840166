import * as React from 'react';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';

import TendergyUserService from 'services/tendergy/tendergy-user.service';
import { IOfferDocumentsResponseItem } from 'store/storeInterfaces';
import { IOffer, ITendergyUser } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

import OfferDetailsComponent from '../../components/offerDetailsComponent/offerDetailsComponent';
import OfferInstallerComponent from '../../components/offerInstallerComponent/offerInstallerComponent';

import styles from './ReviewOfferTab.module.sass';

interface Props {
  offer: IOffer;
  documents: IOfferDocumentsResponseItem[];
  onSave?: Function;
}

const ReviewOfferComponent: React.FC<Props> = (props: Props) => {
  const { offer, documents, onSave } = props;
  const [craftUser, setUser] = useState<ITendergyUser>();

  const getCraftUser = async (id: string) => {
    if (offer.installerUserId) {
      try {
        const response = await TendergyUserService.getById({ id });
        setUser(response);
      } catch (e) {
        showErrorNotification(e);
      }
    }
  };

  useEffect(() => {
    getCraftUser(offer.installerUserId as string);
  }, []); //eslint-disable-line

  return (
    <div>
      <Row justify="space-between">
        <Col span={24}>
          <Card>
            <OfferDetailsComponent offer={offer} documents={documents} onSave={onSave} />
          </Card>
        </Col>
      </Row>
      <Row className={styles.rowMargin}>
        <Col span={24}>
          <Card>
            <OfferInstallerComponent installerUser={craftUser} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReviewOfferComponent;
