import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergyQuestionnaire } from 'store/tendegyService/tendergyService.types';

class QuestionnaireServiceClass extends EntityService<ITendergyQuestionnaire> {
  getDisplayName(entry: any, secondEntry?: any): string {
    return `${entry?.name || secondEntry?.name} (v${entry?.version})`;
  }

  increaseVersion(questionnaireId: string): Promise<any> {
    return this.post(
      `${this.config.entityURL}/increase-version/${questionnaireId}`,
    ) as Promise<any>;
  }

  importQuestionaire(destinationId: string, destinationVersion: number, jsonFile: any) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const data = new FormData();
    data.append('questionnaire', jsonFile);
    return this.post(
      `${this.config.entityURL}/import/${destinationId}/${destinationVersion}`,
      data,
      true,
      headers,
    );
  }
  exportQuestionaire(questionnaireId: string, version: number): Promise<any> {
    return this.get(
      `${this.config.entityURL}/export/${questionnaireId}/${version}`,
    ) as Promise<any>;
  }

  deleteByIdVersion(requestPayload: { id: string; version: number }): Promise<any> {
    if (!this.config.entityURL) {
      throw new Error('HttpService: entityUrl needs to be set in constructor()');
    }
    return this.delete(`${this.config.entityURL}/${requestPayload.id}/${requestPayload.version}`, {
      ...requestPayload,
    }) as Promise<any>;
  }
}

const TendergyQuestionnaireService = new QuestionnaireServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/questionnaires',
  detailLink: links.tendergyQuestionnaire,
  createLink: links.tendergyCreateQuestionnaire,
});

export const TendergyQuestionnaireServiceCopy = (questionnaireId: string, version: string) => {
  return new QuestionnaireServiceClass({
    apiURL: config.CRAFT_SERVICE_URL,
    entityURL: `admin/questionnaires/copy/${questionnaireId}/${version}`,
    detailLink: links.tendergyQuestionnaire,
    createLink: links.tendergyCreateQuestionnaire,
  });
};

export default TendergyQuestionnaireService;
