import * as React from 'react';

import { FormInput } from 'components/forms';

export default function AddressBlock() {
  return (
    <>
      <h4>Address</h4>
      <FormInput name="address.country" label="Country" />
      <FormInput name="address.region" label="Region" />
      <FormInput name="address.city" label="City" />
      <FormInput name="address.address" label="Address" />
      <FormInput name="address.postalCode" label="Postal Code" />
    </>
  );
}
