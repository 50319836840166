import produce from 'immer';

import {
  RESET,
  SET,
  SET_AUTH_USERS,
  SET_LOADING_STATUS,
  UsersActionTypes,
  UsersState,
} from './users.types';

const initialState: UsersState = {
  filters: {
    // TODO: update later
    sortDirection: 1,
  },
  entries: [],
  paginationCount: 0,
  loading: false,
  authUsers: [],
};

const UsersReducer = (state = initialState, action: UsersActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET:
        draft.entries = action.payload.items;
        break;
      case SET_AUTH_USERS:
        draft.authUsers = action.payload.items;
        draft.paginationCount = action.payload.count;
        break;
      case SET_LOADING_STATUS:
        draft.loading = action.payload;
        break;
      case RESET:
        draft.filters = initialState.filters;
        draft.entries = initialState.entries;
        draft.loading = initialState.loading;
        break;
    }
  });

export default UsersReducer;
