import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISADiagnosticStepsService } from 'services/isa-service';
import { IISADiagnosticStep, IParams } from 'services/isa-service/isa-service.types';

import ISADiagnosticStepsForm from './components/ISADiagnosticStepsForm';
import ISAQuestionsTab from './components/ISAQuestionsTab';

export default function ISADiagnosticStepsDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISADiagnosticStepsForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'questions',
          displayName: 'Questions',
          render: (entry: IISADiagnosticStep) => <ISAQuestionsTab questions={entry.questions} />,
        },
      ]}
      entityName="diagnostic step"
      baseLink={links.isaDiagnosticStepsDetails}
      defaultPayload={{ id, relations: ['diagnostic', 'questions'] }}
      resource={ISADiagnosticStepsService}
      hideJsonTab
    />
  );
}
