import * as React from 'react';
import { useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import {
  ITendergyClientRequestPool,
  ITendergySurvey,
  ITendergyUser,
} from 'store/tendegyService/tendergyService.types';

import EntitySelector from '../../../../../components/forms/organization/EntitySelector';
import ClientRequestPoolService from '../../../../../services/tendergy/client-request-pool.service';
import TendergyQuestionnaireService from '../../../../../services/tendergy/tendergy-questionnaire.service';
import QuestionnaireEntitySelector from '../../../client-requests/components/QuestionnaireEntitySelector';
import { SurveyValidation } from '../../survey.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const SurveyOverviewTab: React.FC<IGenericEditTabProps<ITendergySurvey>> = (
  props: IGenericEditTabProps<ITendergySurvey>,
) => {
  const { entry, onSave } = props;
  const [survey, setSurvey] = React.useState(entry);

  useEffect(() => {
    setSurvey(survey);
  }, [entry]); // eslint-disable-line

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(SurveyValidation);

  return (
    <div>
      <Formik
        initialValues={entry as any}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="id" label="Id">
              <Input disabled name="id" placeholder="" />
            </Form.Item>
            <Form.Item name="displayId" label="Display ID">
              <Input disabled name="displayId" placeholder="" />
            </Form.Item>
            <Form.Item name="step" label="Step">
              <Input name="step" placeholder="" />
            </Form.Item>
            <Form.Item name="versionedQuestionnaireId.id" label="Questionnaire id">
              <QuestionnaireEntitySelector
                name="questionnaireId"
                questionnaire={values?.versionedQuestionnaireId}
                entityService={TendergyQuestionnaireService}
                current={values?.versionedQuestionnaireId}
                onSelect={(user: ITendergyUser) => console.log('organizationId', user)}
              />
            </Form.Item>
            <Form.Item name="poolId" label="Pool">
              <EntitySelector
                onSelect={(pool: ITendergyClientRequestPool) => {
                  setFieldValue('poolId', pool.id || null);
                }}
                entityService={ClientRequestPoolService}
                name="poolId"
                current={values.poolId || null}
                allowClear
              />
            </Form.Item>
            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SurveyOverviewTab;
