import config from 'config';

import { ISA_QUESTIONS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAQuestion } from '../isa-service.types';

const ISAQuestionsService = new EntityService<IISAQuestion>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_QUESTIONS_ENDPOINT,
  createLink: links.isaQuestionsCreate,
  detailLink: links.isaQuestionsDetails,
});

ISAQuestionsService.getDisplayName = (entry) => entry.value || entry.logicalName;

export default ISAQuestionsService;
