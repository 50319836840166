import * as React from 'react';
import { useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import ClientIdEntitySelector from 'components/forms/organization/ClientIdEntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { WebhookValidation } from 'pages/integration/webhooks/webhooks.validation';
import ApiClientService from 'services/auth/api-client.service';
import { IWebhook, WebhookSubscriptionType } from 'services/integration/webhooks-service.types';

interface Props {
  entry: IWebhook;
  onSubmitStep: (entry: IWebhook) => void;
}

const WebhookBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(WebhookValidation);
  const [webhook, setWebhook] = React.useState(entry);

  useEffect(() => {
    setWebhook(webhook);
  }, [entry]); // eslint-disable-line

  return (
    <div>
      <Formik
        initialValues={webhook as IWebhook}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="apiClientId" label="Api Client Id">
              <ClientIdEntitySelector
                name="apiClientId"
                entityService={ApiClientService}
                current={entry?.apiClientId}
                onSelect={(entry: any) => setFieldValue('apiClientId', entry.clientId)}
              />
            </Form.Item>

            <Form.Item name="url" label="Url">
              <Input name="url" />
            </Form.Item>

            <Form.Item name="subscription" label="Subscription">
              <Select name="subscription">
                <Select.Option value={WebhookSubscriptionType.ClientRequestStatusUpdate}>
                  ClientRequestStatusUpdate
                </Select.Option>
                <Select.Option value={WebhookSubscriptionType.OfferStatusUpdate}>
                  OfferStatusUpdate
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WebhookBaseStep;
