import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import LeadService from 'services/tendergy/tendergy-lead.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import LeadBaseStep from './steps/LeadBaseStep';

const LeadCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add lead',
      description: 'required information',
      render: (entry, onSubmitStep) => <LeadBaseStep entry={entry} onSubmitStep={onSubmitStep} />,
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Lead"
      steps={steps}
      baseLink={links.leads}
      defaultPayload={params}
      resource={LeadService}
    />
  );
};

export default LeadCreatePage;
