import React from 'react';
import { Input } from 'antd';
import { useField } from 'formik';

import styles from '../FontPicker/FontPickerStyles.module.sass';

interface CustomizeStoreInputProps {
  entry: any;
  name: string;
}

const CustomizeStoreInput: React.FC<CustomizeStoreInputProps> = (
  props: CustomizeStoreInputProps,
) => {
  const { name, entry } = props;
  const [{ value = '', ...field }] = useField(name);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{entry.title.toUpperCase()}</div>
      <Input value={value} {...field} />
    </div>
  );
};

export default CustomizeStoreInput;
