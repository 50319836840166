import * as React from 'react';

import { FormInput } from 'components/forms';

export default function AdditionalInformationBlock() {
  return (
    <>
      <h4>Additional information</h4>
      <FormInput name="address.additionalInformation" label="Additional Information" />
      <FormInput name="address.phone" label="Phone" />
      <FormInput name="address.email" label="Email" />
      <FormInput name="address.firstName" label="First Name" />
      <FormInput name="address.lastName" label="Last Name" />
    </>
  );
}
