import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import { IClientRequest } from '../tendergyService.types';
import {
  GET,
  RequestActionTypes,
  RESET,
  SET,
  SET_LOADING_STATUS,
  TRANSITION,
  UNARCHIVE_REQUEST,
} from './request.types';

export function get(payload: IGetSingleEntityPayload): RequestActionTypes {
  return {
    type: GET,
    payload,
  };
}

export function set(payload: IClientRequest): RequestActionTypes {
  return {
    type: SET,
    payload,
  };
}

export function transition(payload: any): RequestActionTypes {
  return {
    type: TRANSITION,
    payload,
  };
}

export function setLoadingStatus(payload: boolean): RequestActionTypes {
  return {
    type: SET_LOADING_STATUS,
    payload,
  };
}

export function unarchive(payload: any): RequestActionTypes {
  return {
    type: UNARCHIVE_REQUEST,
    payload,
  };
}

export function reset(): RequestActionTypes {
  return {
    type: RESET,
  };
}
