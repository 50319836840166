import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import ProductAttributesService from 'services/shop/shop-product-attributes.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ProductAttributeBaseStep from './steps/ProductAttributeBaseStep';

const ProductAttributeCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add product attribute',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <ProductAttributeBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Product Attribute"
      steps={steps}
      baseLink={links.shopProductAttributes}
      defaultPayload={params}
      resource={ProductAttributesService}
    />
  );
};

export default ProductAttributeCreatePage;
