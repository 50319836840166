import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import WebhooksService from 'services/integration/webhooks.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import WebhookBaseStep from './steps/WebhookBaseStep';

const WebhookCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add webhook',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <WebhookBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];

  return (
    <CrudCreateWizardContainer
      entityName="Webhook"
      steps={steps}
      baseLink={links.webhooksOverview}
      defaultPayload={params}
      resource={WebhooksService}
    />
  );
};

export default WebhookCreatePage;
