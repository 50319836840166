import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd/lib';

export interface IMenuItemProps {
  link: string;
  icon: React.ReactNode;
  label: string;
  style?: any;
  linkStyle?: any;
}

function MenuItem(props: IMenuItemProps) {
  const { link, icon: Icon, label, linkStyle, ...rest } = props;

  return (
    <Menu.Item icon={Icon} {...rest}>
      <Link to={link} {...linkStyle}>
        {label}
      </Link>
    </Menu.Item>
  );
}

export default MenuItem;
