import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import { CrudFormDefaultActionParams } from 'framework/abstract-crud/abstractCrud.constants';
import { IEmailTemplate } from 'services/email/email-service.types';

import { TemplateValidation } from '../../template.validation';

import 'codemirror/theme/material.css';

require('codemirror/lib/codemirror.css');
require('codemirror/mode/htmlmixed/htmlmixed');

interface Props {
  entry: IEmailTemplate;
  onSubmitStep: (entry: IEmailTemplate) => Promise<IEmailTemplate>;
}

const TemplateBasicStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  if (!onSubmitStep) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(TemplateValidation);

  return (
    <Formik
      initialValues={entry as IEmailTemplate}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitStep(values).then(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit }) => (
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit}>
          <Form.Item name="language" label="Language" required>
            <Select name="language">
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="de">German</Select.Option>
              <Select.Option value="fr">French</Select.Option>
              <Select.Option value="it">Italy</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="name" label="Name" required>
            <Input name="name" />
          </Form.Item>

          <Form.Item name="category" label="Category" required>
            <Input name="category" />
          </Form.Item>

          <Form.Item name="subject" label="Subject" required>
            <Input name="subject" />
          </Form.Item>

          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton>Submit</SubmitButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default TemplateBasicStep;
