export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 20;
export const DEFAULT_OFFSET = 0;
export const DEFAULT_SORT_FIELD = undefined;
export const DEFAULT_QUERY = undefined;
export const DEFAULT_FILTERS = undefined;
export const DEFAULT_RELATIONS = [];
export const DEFAULT_SORT_DIRECTION = undefined;
export const DEFAULT_SORT_DIRECTIONS = -1;
export const DEFAULT_SORT_FIELDS = 'createdAt';

export enum EnumSortDirections {
  ASC = 'asc',
  DESC = 'desc',
}
