import config from 'config';

import EntityService from 'services/abstract/entity.service';

import { IFleetAppOrganization } from 'services/fleet-app/fleet-app-service.types';

class FleetAppOrganizationServiceClass extends EntityService<IFleetAppOrganization> {
  getDisplayName(entry: IFleetAppOrganization): string {
    return entry && entry.name;
  }
}

const FleetAppOrganizationService = new FleetAppOrganizationServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/organizations'
});

export default FleetAppOrganizationService;
