import React from 'react';
import { Table, Tabs } from 'antd';

import schneidercraftClientRequestProcess from 'assets/images/schneidercraft-client-request-process.png';
import schneidercraftOfferProcess from 'assets/images/schneidercraft-offer-process.png';

const HelpPage: React.FC = () => {
  const renderClientRequestStatesFAQ = () => {
    const dataSource = [
      {
        term: 'Initiated',
        descEN: '',
        descDE: '',
      },
      {
        term: 'NotSubmitted',
        descEN: '',
        descDE: '',
      },
      {
        term: 'Submitted',
        descEN: '',
        descDE: '',
      },
      {
        term: 'Approval Pending',
        descEN: '',
        descDE: '',
      },
      {
        term: 'Approved',
        descEN: '',
        descDE: '',
      },
    ];

    const columns = [
      {
        title: 'Term',
        dataIndex: 'term',
        key: 'term',
      },
      {
        title: 'Description English',
        dataIndex: 'descEN',
        key: 'descEN',
      },
      {
        title: 'Description German',
        dataIndex: 'descDE',
        key: 'descDE',
      },
    ];
    return (
      <div>
        <a href="https://xstate.js.org/viz/?gist=0cb5daeb38e317fd2eb04305027e632b">
          {' '}
          Please find the process view here
        </a>
        <br />
        <Table dataSource={dataSource} columns={columns} />
        <img src={schneidercraftClientRequestProcess} width="100%" alt=" client requets process " />
      </div>
    );
  };
  const renderOfferStatesFAQ = () => {
    // None = 'none',
    // TenderSubmitted = 'tenderSubmitted',
    // TenderSubmittedWithConditions = 'tenderSubmittedWithConditions',
    // TenderCancelled = 'tenderCancelled',
    // TenderAccepted = 'tenderAccepted',
    // TenderRejected = 'tenderRejected',
    // OfferSubmitted = 'offerSubmitted',
    // OfferAccepted = 'offerAccepted',
    // OfferRejected = 'offerRejected',
    // InstallationPending = 'installationPending',
    // InstallationDone = 'installationDone',
    // Review = 'review'

    const dataSource = [
      {
        term: 'TenderSubmitted',
        descEN: '',
        descDE: '',
      },
      {
        term: 'TenderSubmittedWithConditions',
        descEN: '',
        descDE: '',
      },
      {
        term: 'TenderCancelled',
        descEN: '',
        descDE: '',
      },
      {
        term: 'TenderAccepted',
        descEN: '',
        descDE: '',
      },

      {
        term: 'OfferAccepted',
        descEN: '',
        descDE: '',
      },

      {
        term: 'TenderRejected',
        descEN: '',
        descDE: '',
      },

      {
        term: 'InstallationPending',
        descEN: 'Waiting for installation to come or beeing logged.',
        descDE: '',
      },
      {
        term: 'InstallationDone',
        descEN: 'Installation was done and protocol is saved.',
        descDE: '',
      },
      {
        term: 'review',
        descEN: 'Something unexpected happened and a coordinator needs to review',
        descDE: '',
      },
    ];

    const columns = [
      {
        title: 'Term',
        dataIndex: 'term',
        key: 'term',
      },
      {
        title: 'Description English',
        dataIndex: 'descEN',
        key: 'descEN',
      },
      {
        title: 'Description German',
        dataIndex: 'descDE',
        key: 'descDE',
      },
    ];
    return (
      <div>
        <a href="https://xstate.js.org/viz/?gist=0cb5daeb38e317fd2eb04305027e632b">
          {' '}
          Please find the process view here
        </a>
        <br />
        <Table dataSource={dataSource} columns={columns} />
        <img src={schneidercraftOfferProcess} width="100%" alt="offer process" />
      </div>
    );
  };

  const { TabPane } = Tabs;
  const renderTabs = () => (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Help Intro" key="1">
        Home
      </TabPane>
      <TabPane tab="Client Request Process" key="2">
        {renderClientRequestStatesFAQ()}
      </TabPane>
      <TabPane tab="Offer Process" key="3">
        {renderOfferStatesFAQ()}
      </TabPane>
    </Tabs>
  );

  return (
    <div>
      <h1> Help & FAQ</h1>
      {renderTabs()}
    </div>
  );
};

export default HelpPage;
