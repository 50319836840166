import * as React from 'react';
import { useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, InputNumber, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { ITendergyCountry } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

import { CountryValidation } from '../../country.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const CountryOverviewTab: React.FC<IGenericEditTabProps<ITendergyCountry>> = (
  props: IGenericEditTabProps<ITendergyCountry>,
) => {
  const { entry, onSave } = props;
  const [country, setCountry] = React.useState(entry);

  useEffect(() => {
    setCountry(country);
  }, [entry]); // eslint-disable-line

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(CountryValidation);

  return (
    <div>
      <Formik
        initialValues={country as ITendergyCountry}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="iso31661Alpha3" label="ISO 31661 Alpha 3">
              <Input name="iso31661Alpha3" placeholder="" />
            </Form.Item>
            <Form.Item name="vatRate" label="Vat Rate">
              <InputNumber
                name="vatRate"
                type="number"
                placeholder="0"
                min={0}
                max={100}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name="currency" label="Currency">
              <Input name="currency" placeholder="" />
            </Form.Item>
            <Form.Item name="currencySymbol" label="Symbol">
              <Input name="currencySymbol" placeholder="" />
            </Form.Item>
            <Form.Item name="defaultTimezone" label="Default Timezone">
              <Input name="defaultTimezone" placeholder="" />
            </Form.Item>
            <Form.Item name="isDefault" label="Is Default">
              <Switch name="isDefault" />
            </Form.Item>

            {entry?.createdAt ? (
              <Form.Item name="createdAt" label="Created At">
                {formatDefaultDate(entry?.createdAt)}
              </Form.Item>
            ) : null}

            {entry?.updatedAt ? (
              <Form.Item name="updatedAt" label="Updated At">
                {formatDefaultDate(entry?.updatedAt)}
              </Form.Item>
            ) : null}

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CountryOverviewTab;
