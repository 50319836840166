import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillInvoicesService from 'services/billing-v2/billing-v2-invoices.service';
import {
  IBillingInvoice,
  IBillingOrganization,
} from 'services/billing-v2/billing-v2-service.types';
import { formatDefaultDate } from 'utils/date.helper';

interface IParams {
  status: string;
}

const columns: ColumnsType<IBillingInvoice> = [
  {
    title: 'Organization',
    dataIndex: 'organization',
    render: (organization: IBillingOrganization) => organization?.name || '-',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Period',
    key: 'period',
    dataIndex: 'month',
    render: (_, entry: IBillingInvoice) =>
      `${moment().month(entry.month).format('MMMM')} ${entry.year}`,
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const InvoicesOverviewPages: React.FC = () => {
  const params: IParams = {
    status: 'done,error,canceled',
  };

  return (
    <CrudListViewContainer
      additionalPayload={params}
      entityName="Billing Invoices"
      columns={columns}
      resource={BillInvoicesService}
    />
  );
};

export default InvoicesOverviewPages;
