import { ITendergyOrganization } from 'store/tendegyService/tendergyService.types';

import {
  AuthData,
  GET,
  GET_CRAFT_ENTRIES,
  OrganizationsActionTypes,
  RESET,
  SET,
  SET_CRAFT_ENTRIES,
  SET_LOADING_STATUS,
} from './organizations.types';

export function get(payload: { limit: number; offset: number }): OrganizationsActionTypes {
  return {
    type: GET,
    payload,
  };
}

export function getCraftOrganizations(): OrganizationsActionTypes {
  return {
    type: GET_CRAFT_ENTRIES,
  };
}

export function set(payload: AuthData): OrganizationsActionTypes {
  return {
    type: SET,
    payload,
  };
}

export function setCraftOrganizations(payload: ITendergyOrganization[]): OrganizationsActionTypes {
  return {
    type: SET_CRAFT_ENTRIES,
    payload,
  };
}

export function setLoadingStatus(payload: boolean): OrganizationsActionTypes {
  return {
    type: SET_LOADING_STATUS,
    payload,
  };
}

export function reset(): OrganizationsActionTypes {
  return {
    type: RESET,
  };
}
