import * as React from 'react';
import { Col, Divider, Radio, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IConsultantElectrification } from 'services/consulant/consultant-service.types';
import { formatDefaultDate } from 'utils/date.helper';

import { validationSchema } from '../leads/validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
interface IOpt {
  label: string;
  value: string;
}

interface Props {
  entry?: IConsultantElectrification | null;
  onSave: (updatedValues: IConsultantElectrification) => Promise<any>;
}

export enum AcquiringType {
  Purchase = 'purchase',
  Lease = 'lease',
}

export enum EvStatus {
  AlreadySelected = 'already-selected',
  CurrentlyEvaluating = 'currently-evaluating',
}

export enum HomeChargingCosts {
  FullyCovered = 'fully-covered',
  PartiallyCovered = 'partially-covered',
  NotDecided = 'not-decided',
}

const MapEnums = (Enum: any) =>
  Object.keys(Enum).reduce((acc: IOpt[], field) => {
    const value = Enum[field];
    return [...acc, { label: field, value }];
  }, []);

const ElectrificationTab: React.FC<Props> = (props: Props) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }
  if (!entry) {
    return <div> Loading </div>;
  }

  return (
    <div>
      <Formik
        initialValues={entry as IConsultantElectrification}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, values, handleChange }) => {
          return (
            <>
              <Row>
                <Col xs={6}>Created At: {formatDefaultDate(entry.createdAt)}</Col>
                <Col xs={6}>Updated At: {formatDefaultDate(entry.updatedAt)}</Col>
              </Row>
              <Divider />
              <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
                <Form.Item
                  name="targetElectrificationDuration"
                  label="ُTarget Electrification Duration"
                >
                  <Input name="targetElectrificationDuration" placeholder="" disabled />
                </Form.Item>
                <Form.Item name="targetElectrificationRate" label="Target Electrification Rate">
                  <Input name="targetElectrificationRate" disabled placeholder="" />
                </Form.Item>
                <Form.Item name="notSure" label="Not Sure (I Don't Know)">
                  <Radio.Group
                    defaultValue={values.notSure}
                    name={`notSure`}
                    disabled
                    options={[
                      { label: 'true', value: true },
                      {
                        label: 'false',
                        value: false,
                      },
                    ]}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="isParkingAtWorkAvailable" label="Parking At Work">
                  <Radio.Group
                    disabled
                    defaultValue={values.isParkingAtWorkAvailable}
                    name={`isParkingAtWorkAvailable`}
                    options={[
                      { label: 'true', value: true },
                      {
                        label: 'false',
                        value: false,
                      },
                    ]}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="isCpAtHomeAvailable" label="Charge Point">
                  <Radio.Group
                    disabled
                    defaultValue={values.isCpAtHomeAvailable}
                    name={`isCpAtHomeAvailable`}
                    options={[
                      { label: 'true', value: true },
                      {
                        label: 'false',
                        value: false,
                      },
                    ]}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="isCpAtHomeAvailable" label="Charge Point At Home ">
                  <Radio.Group
                    disabled
                    defaultValue={values.isCpAtHomeAvailable}
                    name={`isCpAtHomeAvailable`}
                    options={[
                      { label: 'true', value: true },
                      {
                        label: 'false',
                        value: false,
                      },
                    ]}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="isCpAtPublicAvailable" label="Charge Point At Public ">
                  <Radio.Group
                    disabled
                    defaultValue={values.isCpAtPublicAvailable}
                    name={`isCpAtPublicAvailable`}
                    options={[
                      { label: 'true', value: true },
                      {
                        label: 'false',
                        value: false,
                      },
                    ]}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item name="isCpAtWorkAvailable" label="Charge Point At Work ">
                  <Radio.Group
                    disabled
                    defaultValue={values.isCpAtWorkAvailable}
                    name={`isCpAtWorkAvailable`}
                    options={[
                      { label: 'true', value: true },
                      {
                        label: 'false',
                        value: false,
                      },
                    ]}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item name="evAcquiringType" label="evAcquiringType ">
                  <Radio.Group
                    disabled
                    defaultValue={values.evAcquiringType}
                    name={`evAcquiringType`}
                    options={MapEnums(AcquiringType)}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="evStatus" label="evStatus ">
                  <Radio.Group
                    disabled
                    defaultValue={values.evStatus}
                    name={`evStatus`}
                    options={MapEnums(EvStatus)}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="homeChargingCosts" label="Home Charging Costs ">
                  <Radio.Group
                    disabled
                    defaultValue={values.homeChargingCosts}
                    name={`homeChargingCosts`}
                    options={MapEnums(HomeChargingCosts)}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item {...CrudFormDefaultActionParams} name="submit">
                  <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                    Submit
                  </SubmitButton>
                </Form.Item>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ElectrificationTab;
