import produce from 'immer';

import {
  RequestActionTypes,
  RequestState,
  RESET,
  SET,
  SET_LOADING_STATUS,
  TRANSITION,
  UNARCHIVE_REQUEST,
} from './request.types';

const initialState: RequestState = {
  data: {
    id: null,
  },
  loading: false,
};

const RequestReducer = (state = initialState, action: RequestActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET:
        draft.data = action.payload;
        break;
      case SET_LOADING_STATUS:
        draft.loading = action.payload;
        break;
      case RESET:
        draft.data = initialState.data;
        draft.loading = initialState.loading;
        break;
      case TRANSITION:
        draft.data = action.payload;
        break;
      case UNARCHIVE_REQUEST:
        break;
    }
  });

export default RequestReducer;
