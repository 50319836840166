import { combineReducers } from 'redux';

import auth from './auth/auth.reducer';
import organization from './authService/organization/organization.reducer';
import organizations from './authService/organizations/organizations.reducer';
import user from './authService/user/user.reducer';
import users from './authService/users/users.reducer';
import common from './common/common.reducer';
import offer from './tendegyService/offer/offer.reducer';
import offers from './tendegyService/offers/offers.reducer';
import request from './tendegyService/request/request.reducer';

export const rootReducer = combineReducers({
  common,
  auth,
  users,
  user,
  request,
  offers,
  offer,
  organizations,
  organization,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
