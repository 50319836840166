export interface ICommonState {
  lastOpenedPage: string;
  entityURL: string;
}

export const CHANGE_LAST_OPENED_PAGE = 'CHANGE_LAST_OPENED_PAGE';

// Change last opened page
export interface ChangeLastOpenedPageAction {
  type: typeof CHANGE_LAST_OPENED_PAGE;
  payload: {
    page: string;
    entityURL: string;
  };
}

export type CommonActionTypes = ChangeLastOpenedPageAction;
