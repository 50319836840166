import produce from 'immer';

import { CHANGE_LAST_OPENED_PAGE, CommonActionTypes, ICommonState } from './common.types';

const initialState: ICommonState = {
  lastOpenedPage: '',
  entityURL: '',
};

const ClientRequestReducer = (state = initialState, action: CommonActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_LAST_OPENED_PAGE:
        draft.lastOpenedPage = action.payload.page;
        draft.entityURL = action.payload.entityURL;
        break;
    }
  });

export default ClientRequestReducer;
