import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillChargingStationsService from 'services/billing-v3/billing-v3-charging-stations.service';
import { IBillingChargingStation } from 'services/billing-v3/billing-v3-service.types';

import EnergyPricesTab from './tabs/EnergyPricesTab';

interface IParams {
  id: string;
}

export default function ChargingStationDetailsPage() {
  const { id } = useParams<IParams>();
  const payload = {
    id,
  };

  return (
    <CrudDetailContainer
      legacy
      tabs={[
        // {
        //   name: 'details',
        //   displayName: 'Details',
        //   render: (entry, { onSave }) => <ChargingStationForm entry={entry} onSubmit={onSave} />,
        // },
        {
          name: 'energy-prices',
          displayName: 'Energy Prices',
          // @ts-ignore
          render: (entry: IBillingChargingStation) => <EnergyPricesTab entry={entry} />,
        },
      ]}
      entityName="charging station"
      baseLink={links.billV3ChargingStation}
      defaultPayload={payload}
      resource={BillChargingStationsService}
      renderTitle={(entry: IBillingChargingStation) => `${entry.evse} (${entry.type})`}
    />
  );
}
