import * as Yup from 'yup';

/*
export  interface IBillingOrganization extends IBaseEntity {
  name: string;
}
*/
export const BillingOrganizationsValidation = {
  name: Yup.string().required('Required').min(2, 'Too short').max(50, 'Too long'),
};
