import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IProductVendor } from 'store/tendegyService/tendergyService.types';

import { VendorValidation } from '../../vendor.validation';

interface Props {
  entry: IProductVendor;
  onSubmitStep: (entry: IProductVendor) => void;
}

const VendorStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(VendorValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IProductVendor}
        validationSchema={validation}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            onSubmitStep(values);
            actions.setSubmitting(false);
          }, 300);
        }}
      >
        {({ handleSubmit, isSubmitting, isValid, dirty }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name">
              <Input name="name" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting || !isValid || !dirty}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VendorStep;
