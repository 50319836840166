import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import UserAuthAPI from 'services/auth/user-auth.service';
import UserAPI from 'services/tendergy/tendergy-user.service';

import * as actions from './user.actions';
import { GET_AUTHUSER, GET_CRAFTUSER, GetUserAction } from './user.types';

export function* get({ payload }: GetUserAction) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(UserAPI.getById, payload);
    yield put(actions.setCraftUser(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getAuthUser({ payload }: GetUserAction) {
  yield put(actions.setLoadingStatus(true));
  try {
    const data = yield call(UserAuthAPI.getById, payload);
    yield put(actions.setAuthUser(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([takeLatest(GET_CRAFTUSER, get), takeLatest(GET_AUTHUSER, getAuthUser)]);
}
