import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, Popconfirm, Select, Spin, Typography } from 'antd';
import moment, { Moment } from 'moment';

import BillInvoicesService from 'services/billing-v3/billing-v3-invoices.service';
import BillOrganizationService from 'services/billing-v3/billing-v3-organizations.service';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import styles from './styles.module.sass';

const InvoiceCreatePage: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [selectedYear, setSelectedYear] = useState<string>();
  const [organizationId, setOrganizationId] = useState<string>();
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [query, setQuery] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(false);

  const { Option } = Select;

  const getChargingGroups = async () => {
    try {
      setFetching(true);
      setOrganizations([]);
      const response = await BillOrganizationService.get(
        `admin/organizations?limit=100&offset=0&query=${query}`,
      );
      setFetching(false);

      setOrganizations(response?.items);
    } catch (e) {
      setFetching(false);
      showErrorNotification(e);
    }
  };
  useEffect(() => {
    getChargingGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  const generateInvoice = () => {
    if (selectedMonth && selectedYear && organizationId) {
      try {
        BillInvoicesService.generateInvoice({
          month: selectedMonth,
          year: selectedYear,
          organizationId: organizationId,
        });
        showSuccessNotification('Invoice Generated Successfully');
      } catch (e) {
        showErrorNotification(e);
      }
    }
  };

  const onSelectDate = (date: Moment | null) => {
    setSelectedMonth((moment(date).month() + 1).toString());
    setSelectedYear(moment(date).year().toString());
  };

  return (
    <div className={styles.wrapper}>
      <Card title="Generate invoice" bordered={false} className={styles.card}>
        <div className={styles.wrapper}>
          <Typography className={styles.generateText}>
            Please select month & year & organization Id to new generate invoice:
          </Typography>
          <DatePicker
            style={{ width: '200px' }}
            picker="month"
            onChange={(date: Moment | null) => onSelectDate(date)}
          />
          {/* <Input
            style={{ width: '138px', marginTop: '10px' }}
            className={styles.input}
            name="organizationId"
            placeholder="Organization Id"
            onChange={(e) => setOrganizationId(e.target.value)}
          /> */}

          <Select
            showSearch
            labelInValue
            style={{ width: '200px', marginTop: '10px' }}
            onChange={(e: any) => setOrganizationId(e.value)}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onSearch={setQuery}
            filterOption={(input, option) =>
              ((option!.children as unknown) as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {organizations?.map(({ id, name }) => {
              return (
                <Option value={id} key={id}>
                  {name}
                </Option>
              );
            })}
          </Select>
          {/* {errors.chargingPoint && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {errors.chargingPoint}
                </div>
              )} */}
          <Popconfirm
            title="Are you sure you want to generate new invoice?"
            onConfirm={generateInvoice}
          >
            <Button type="primary" shape="round" className={styles.generateButton}>
              Generate
            </Button>
          </Popconfirm>
        </div>
      </Card>
    </div>
  );
};

export default InvoiceCreatePage;
