import * as Yup from 'yup';

export const INITIAL_VALUES = {
  value: null,
  description: null,
  answerLabel: null,
  logicalName: null,
  answers: null,
  diagnosticStep: null,
};

export const validationSchema = Yup.object().shape({
  value: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  description: Yup.string()
    .nullable()
    .max(256, 'Field has not to be longer than 256 characters!')
    .required('Required field'),
  answerLabel: Yup.string()
    .nullable()
    .max(32, 'Field has not to be longer than 256 characters!')
    .required('Required field'),
  logicalName: Yup.string().nullable().required('Required field'),
  answers: Yup.array().nullable(),
  diagnosticStep: Yup.object().nullable(),
});
