import { IPagination } from 'framework/models';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

import { ITendergyUser } from '../../tendegyService/tendergyService.types';
import { IAuthUser } from '../authService.types';
import {
  GET,
  GET_AUTH_USERS,
  RESET,
  SET,
  SET_AUTH_USERS,
  SET_LOADING_STATUS,
  UsersActionTypes,
} from './users.types';

export function get(): UsersActionTypes {
  return {
    type: GET,
  };
}

export function set(payload: IPagination<ITendergyUser>): UsersActionTypes {
  return {
    type: SET,
    payload,
  };
}

export function getUsers(payload: IGetMultiEntityPayload): UsersActionTypes {
  return {
    type: GET_AUTH_USERS,
    payload,
  };
}

export function setUsers(payload: IPagination<IAuthUser>): UsersActionTypes {
  return {
    type: SET_AUTH_USERS,
    payload,
  };
}

export function setLoadingStatus(payload: boolean): UsersActionTypes {
  return {
    type: SET_LOADING_STATUS,
    payload,
  };
}

export function reset(): UsersActionTypes {
  return {
    type: RESET,
  };
}
