import * as Yup from 'yup';

export const AuthOrganizationValidation = {
  name: Yup.string().min(2, 'Too short!').max(50, 'Too long!').required('Required'),
};

export const CraftOrganizationValidation = {
  name: Yup.string().min(2, 'Too short!').max(50, 'Too long!').required('Required'),
  address: Yup.object().shape({
    street: Yup.string().required('Required').min(2, 'Too short!').max(40, 'Too long'),
    houseNumber: Yup.string().required('Required').min(1, 'Too short!').max(40, 'Too long'),
    address2: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    postalCode: Yup.string().required('Required').min(2, 'Too short!').max(8, 'Too long'),
    city: Yup.string().required('Required').min(2, 'Too short!').max(40, 'Too long'),
  }),
};
