import * as React from 'react';
import { Form } from 'formik-antd';
import * as Yup from 'yup';

import { FormikForm, FormInput } from 'components/forms';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { buildValues } from 'framework/form';
import { IShopCategory, IShopStore } from 'services/shop/shop-service.types';

import { StoreValidation } from '../../../store.validation';
import AdditionalInformationBlock from '../components/AdditionalInformationBlock';
import AddressBlock from '../components/AddressBlock';
import ContactInformationBlock from '../components/ContactBlock';
import PaymentMethodsSelector from '../components/PaymentMethodsSelector';

import styles from './StoreTabsStyles.module.sass';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const StoreOverviewTab: React.FC<IGenericEditTabProps<IShopStore>> = (props: any) => {
  const { entry, onSave, pageKey } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(StoreValidation);

  return (
    <FormikForm
      initialValues={buildValues(validation, entry) as IShopStore}
      validationSchema={validation}
      onSubmit={onSave}
      className={styles.form}
      successLabel={`Store ${entry.name} updated successfully`}
      buttonsContainerId={`${pageKey}-header-buttons`}
      autoSave
    >
      <FormInput name="name" label="Name" required />
      <FormInput name="publicUrl" label="Public Url" required>
        {entry.publicUrl && (
          <a className={styles.linkWrapper} href={entry.publicUrl}>
            {entry.publicUrl}
          </a>
        )}
      </FormInput>
      <FormInput name="registrationId" label="Registration Id" />
      <Form.Item name="categories" label="Categories">
        {entry.categories?.length
          ? entry.categories.map((item: IShopCategory) => <div key={item.id}>{item.name}</div>)
          : 'No categories'}
      </Form.Item>
      <PaymentMethodsSelector name="paymentMethods" label="Payment methods" required />

      <ContactInformationBlock />
      <AddressBlock />
      <AdditionalInformationBlock />
    </FormikForm>
  );
};

export default StoreOverviewTab;
