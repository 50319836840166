import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import ProductsService from 'services/shop/shop-products.service';
import { IShopProduct } from 'services/shop/shop-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ProductActionsComponent from './components/ProductActionsComponent/ProductActionsComponent';
import ProductAttributesTab from './tabs/ProductAttributesTab';
import ProductOverviewTab from './tabs/ProductOverviewTab';

const ProductDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['category', 'productAttributes'],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IShopProduct, optionalProps?: ICrudDetailViewCallbacks) => (
        <ProductOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
    {
      name: 'Product Attributes',
      displayName: 'Product Attributes',
      render: (entity: IShopProduct) => (
        <ProductAttributesTab attributes={entity.productAttributes} />
      ),
    },
  ];

  const renderActions = () => <ProductActionsComponent />;

  return (
    <CrudDetailContainer
      entityName="Product"
      tabs={tabs}
      baseLink={links.shopProduct}
      defaultPayload={params}
      renderActions={renderActions}
      resource={ProductsService}
    />
  );
};

export default ProductDetailsPage;
