import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillingCarOwnerService from 'services/billing/billing-car-owner.service';
import { IBillingCarOwner, IBillingOrganization } from 'services/billing/billing-service.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<any> = [
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
    render: (val: any, row: IBillingCarOwner) => `${row.firstname} ${row.lastname}`,
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    key: 'organization',
    render: (val: IBillingOrganization) => (val ? `${val.name}` : ''),
  },

  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const BillingCarOwnersPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Car Owners"
    columns={columns}
    resource={BillingCarOwnerService}
  />
);
export default BillingCarOwnersPage;
