import { ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import { IConsultantParameters } from 'services/consulant/consultant-service.types';
import { formatDefaultDate } from 'utils/date.helper';

export interface IC<T> extends ColumnType<T> {
  searchKey?: string;
  filterParameter?: string;
}
const columns: IC<IConsultantParameters>[] = [
  {
    title: 'Username',
    dataIndex: ['fleetManager', 'firstName'],
    render: (value: string) => value || '–',
  },
  {
    title: 'Surname',
    dataIndex: ['fleetManager', 'lastName'],
    render: (value: string) => value || '–',
  },
  {
    title: 'User Email',
    dataIndex: ['fleetManager', 'email'],
    render: (value: string) => value || '–',
  },
  {
    title: 'Organization',
    dataIndex: ['fleetManager', 'organization'],
    render: (value: string) => value || '–',
  },
  {
    title: 'Phone Number',
    dataIndex: ['fleetManager', 'phone'],
    render: (value: string) => value || '–',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    defaultSortOrder: 'ascend' as SortOrder,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Lead Id',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    defaultSortOrder: 'ascend' as SortOrder,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Sites Count',
    dataIndex: 'sitesCount',
    key: 'sitesCount',
    sorter: true,
    defaultSortOrder: 'ascend' as SortOrder,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    render: (date: string) => formatDefaultDate(date),
    sorter: true,
    defaultSortOrder: 'ascend' as SortOrder,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'created',
    render: (date: string) => formatDefaultDate(date),
    sorter: true,
    defaultSortOrder: 'ascend' as SortOrder,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

export default columns;
