import React, { useMemo } from 'react';
import { InputNumber } from 'antd';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input/Input';
import Select from 'antd/lib/select';
import moment from 'moment';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { OfferState } from 'framework/constants';
import { getFilteredFormFields } from 'framework/utils/helpers';
import ProductModelService from 'services/tendergy/tendergy-product.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import ProductVendor from 'services/tendergy/tendergy-vendor.service';
import { IOfferDocumentsResponseItem } from 'store/storeInterfaces';
import { IOffer, ITendergyUser } from 'store/tendegyService/tendergyService.types';

import styles from './offerDetailsComponent.module.sass';

interface Props {
  offer?: IOffer;
  onSave?: Function;
  documents: IOfferDocumentsResponseItem[];
}

/**
 * Renders the installer contact data and offer details;
 *
 * @param param0
 */
const OfferDetailsComponent: React.FC<Props> = ({ offer, onSave }: Props) => {
  const [form] = Form.useForm();

  const offerStates = useMemo(
    () =>
      Object.keys(OfferState).map((key) => ({
        state: OfferState[key as keyof typeof OfferState],
        title: key,
      })),
    [],
  );

  if (!offer) {
    return <div> Offer loading.. </div>;
  }

  if (!onSave) {
    return <div>onSave method required</div>;
  }

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const preparedForm = getFilteredFormFields(values);
    onSave(preparedForm);
  };

  const renderOffer = () => (
    <Form
      className={styles.form}
      {...CrudFormDefaultParams}
      form={form}
      name="Edit Offer"
      initialValues={{
        ...offer,
        finalInstallationTimestamp: offer.finalInstallationTimestamp
          ? moment(offer.finalInstallationTimestamp, 'YYYY-MM-DD HH:mm')
          : null,
        finalHomeCheckTimestamp: offer.finalHomeCheckTimestamp
          ? moment(offer.finalHomeCheckTimestamp, 'YYYY-MM-DD HH:mm')
          : null,
        reminderSentAt: offer.reminderSentAt
          ? moment(offer.reminderSentAt, 'YYYY-MM-DD HH:mm')
          : null,
        doneInformationSentAt: offer.doneInformationSentAt
          ? moment(offer.doneInformationSentAt, 'YYYY-MM-DD HH:mm')
          : null,
        tenderAcceptedHomeCheckTimestamp: offer.tenderAcceptedHomeCheckTimestamp
          ? moment(offer.tenderAcceptedHomeCheckTimestamp, 'YYYY-MM-DD HH:mm')
          : null,
        tenderAcceptedTimestamp: offer.tenderAcceptedTimestamp
          ? moment(offer.tenderAcceptedTimestamp, 'YYYY-MM-DD HH:mm')
          : null,
      }}
      onFinish={handleSubmit}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    >
      <Form.Item label="Display Id">{offer?.displayId}</Form.Item>
      <Form.Item label="Id">
        <span>{offer?.id}</span>
      </Form.Item>
      <Form.Item label="Created At">
        <span>{offer?.createdAt}</span>
      </Form.Item>
      <Form.Item label="Updated At">{offer?.updatedAt}</Form.Item>
      <Form.Item label="Client Request Id">{offer?.clientRequestId}</Form.Item>
      <Form.Item label="Invoice Submitted Timestamp">{offer?.invoiceSubmittedTimestamp}</Form.Item>
      <Form.Item label="Invoice Accepted Timestamp">{offer?.invoiceAcceptedTimestamp}</Form.Item>

      {offer?.doneAsStated && <Form.Item label="Done As Stated">{offer?.doneAsStated}</Form.Item>}
      {offer?.deletedDate && <Form.Item label="Deleted Date">{offer?.deletedDate}</Form.Item>}

      <Form.Item name="state" label="Offer State">
        <Select>
          {offerStates.map((item) => (
            <Select.Option value={item.state} key={item.state}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="offerReplacedBy" label="Offer Replaced By">
        <EntitySelector
          name="offerReplacedBy"
          entityService={TendergyUserService}
          current={offer.offerReplacedById}
          onSelect={(user: ITendergyUser) => console.log('offerReplacedBy', user)}
        />
      </Form.Item>
      <Form.Item name="homeCheckOfferReplacedBy" label="Home Check Offer Replaced By">
        <EntitySelector
          name="homeCheckOfferReplacedBy"
          entityService={TendergyUserService}
          current={offer.homeCheckOfferReplacedById}
          onSelect={(user: ITendergyUser) => console.log('homeCheckOfferReplacedBy', user)}
        />
      </Form.Item>
      <Form.Item name="installerUserId" label="Installer User Id">
        <EntitySelector
          name="installerUserId"
          entityService={TendergyUserService}
          current={offer.installerUserId}
          onSelect={(user: ITendergyUser) => console.log('installerUserId', user)}
        />
      </Form.Item>
      <Form.Item name="organizationId" label="Organization Id">
        <EntitySelector
          name="organizationId"
          entityService={TendergyUserService}
          current={offer.organization}
          onSelect={(user: ITendergyUser) => console.log('organizationId', user)}
        />
      </Form.Item>

      {/* Date-time pickers */}
      {[
        { field: 'finalInstallationTimestamp', label: 'Final Installation Timestamp' },
        { field: 'finalHomeCheckTimestamp', label: 'Final HomeCheck Timestamp' },
        { field: 'reminderSentAt', label: 'Reminder Sent At' },
        { field: 'doneInformationSentAt', label: 'Done Information Sent At' },
        {
          field: 'tenderAcceptedHomeCheckTimestamp',
          label: 'Tender Accepted Home Check Timestamp',
        },
        { field: 'tenderAcceptedTimestamp', label: 'Tender Accepted Timestamp' },
      ].map((field) => (
        <Form.Item name={field.field} label={field.label} key={field.field}>
          <DatePicker showTime={true} />
        </Form.Item>
      ))}

      {[
        {
          field: 'costEstimateHomeCheck',
          label: 'End User Offer Home Check',
          isNumeric: true,
          min: 0,
        },
        { field: 'costEstimate', label: 'End User Offer Installation', isNumeric: true, min: 0 },
        { field: 'vatRate', label: 'Vat Rate', isNumeric: true, min: 0 },
        {
          field: 'initialCostEstimateHomeCheck',
          label: 'Installer Offer Home Check',
          isNumeric: true,
          min: 0,
        },
        {
          field: 'initialCostEstimate',
          label: 'Installer Offer Installation',
          isNumeric: true,
          min: 0,
        },
        { field: 'initialVatRate', label: 'Initial Vat Rate', readOnly: true },
        { field: 'annotation', label: 'Annotation' },
        { field: 'homeCheckAnnotation', label: 'Home Check Annotation' },
        { field: 'contactType', label: 'Contact Type' },
        { field: 'rejectReason', label: 'Reject Reason' },
        {
          field: 'initialInvoiceHomeCheck',
          label: 'Installer Price Home Check',
          isNumeric: true,
          min: 0,
        },
        {
          field: 'initialInvoiceAmount',
          label: 'Installer Price Installation',
          isNumeric: true,
          min: 0,
        },
        { field: 'invoiceHomeCheck', label: 'End User Price Home Check', isNumeric: true, min: 0 },
        { field: 'invoiceAmount', label: 'End User Price Installation', isNumeric: true, min: 0 },
        { field: 'actualInstallationTimestamp', label: 'Actual Installation Timestamp' },
        { field: 'actualHomeCheckTimestamp', label: 'Actual Home Check Timestamp' },
        { field: 'offerReplacedById', label: 'Offer Replaced By Id' },
        { field: 'homeCheckOfferReplacedById', label: 'Home Check Offer Replaced By Id' },
        { field: 'installedProductId', label: 'Installed Product Id' },
      ].map((fieldItem) => {
        const { field, label, isNumeric, ...rest } = fieldItem;
        return (
          <Form.Item name={field} label={label} key={field}>
            {isNumeric ? (
              <InputNumber
                parser={(value) => (value?.replace(/[^0-9.]/g, '') as unknown) as number}
                {...rest}
              />
            ) : (
              <Input {...rest} />
            )}
          </Form.Item>
        );
      })}

      <Form.Item name="installedProduct" label="Іnstalled Product">
        <EntitySelector
          name="installedProduct"
          entityService={ProductModelService}
          current={offer?.installedProductModel}
          onSelect={(product: any) => console.log('Іnstalled Product', product)}
        />
      </Form.Item>

      <Form.Item name="installedVendor" label="Іnstalled Vendor">
        <EntitySelector
          name="installedVendor"
          entityService={ProductVendor}
          current={offer?.installedProductModel?.vendor}
          onSelect={(vendor: any) => console.log(vendor)}
        />
      </Form.Item>

      <Form.Item {...CrudFormDefaultActionParams} name="submit">
        <Button type="primary" htmlType="submit" style={{ marginRight: '20px' }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );

  return <div>{renderOffer()}</div>;
};

export default OfferDetailsComponent;
