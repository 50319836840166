import React from 'react';
import { ColumnType, SortOrder } from 'antd/lib/table/interface';

import {
  IBillingAddress,
  IBillingChargingStation,
  IBillingEnergyPrice,
  IBillingInvoice,
  IBillingUserAccess,
} from 'services/billing-v3/billing-v3-service.types';
import { formatDefaultDate } from 'utils/date.helper';

import { StatusChecker } from '../invoices/InvoicesOverviewPage';

export interface IB<T> extends ColumnType<T> {
  searchKey?: string;
  sortParameter?: string;
  filterParameter?: string;
}
const columns: IB<IBillingChargingStation>[] = [
  {
    title: 'Charging Station EVSE',
    dataIndex: 'evse',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Charge Point Group Name',
    dataIndex: 'chargingPointGroup',
    sorter: true,
    sortParameter: 'chargingPointGroup.name',
    align: 'center',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (chargingPointGroup: IBillingChargingStation) => `${chargingPointGroup?.name || '-'}`,
  },
  {
    title: 'Assigned User',
    dataIndex: 'accessChargingPointUsers',
    render: (userAccess: IBillingUserAccess[]) =>
      `${userAccess?.[0]?.firstName || ''} ${userAccess?.[0]?.lastName || ''} `,
    sorter: true,
    sortParameter: 'accessChargingPointUsers.firstName',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Plug Type',
    dataIndex: 'plugType',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },

  {
    title: 'Address',
    dataIndex: 'chargingPointGroup',
    render: (chargingPointGroup: IBillingAddress) => {
      return `${chargingPointGroup?.address?.address || '-'}`;
    },
    sorter: true,
    sortParameter: 'address.address',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Address Zip',
    dataIndex: 'chargingPointGroup',
    render: (chargingPointGroup: IBillingAddress) =>
      `${chargingPointGroup?.address?.postalCode || '-'}`,
    sorter: true,
    sortParameter: 'address.postalCode',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Address City',
    dataIndex: 'chargingPointGroup',
    render: (chargingPointGroup: IBillingAddress) => `${chargingPointGroup?.address?.city || '-'}`,
    sorter: true,
    sortParameter: 'address.city',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Charge/ kWh',
    dataIndex: 'approvedEnergyPrice',
    render: (energyPrice: IBillingEnergyPrice, record: any) => {
      const field: IBillingEnergyPrice = energyPrice ? energyPrice : record.pendingEnergyPrice;
      return `${field?.chargePerUnit || '-'}`;
    },
    sorter: true,
    sortParameter: 'approvedEnergyPrice.chargePerUnit',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Payout/ kWh',
    dataIndex: 'approvedEnergyPrice',
    render: (energyPrice: IBillingEnergyPrice, record: any) => {
      const field: IBillingEnergyPrice = energyPrice ? energyPrice : record.pendingEnergyPrice;
      return `${field?.payoutPerUnit || '-'}`;
    },
    sorter: true,
    sortParameter: 'approvedEnergyPrice.payoutPerUnit',

    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Energy Price Status',
    dataIndex: 'approvedEnergyPrice',
    sortParameter: 'approvedEnergyPrice.status',
    width: 300,
    render: (energyPrice: IBillingEnergyPrice, record: any) => {
      let statuses = [];
      if (energyPrice) {
        statuses.push(energyPrice);
      }
      if (record?.pendingEnergyPrice) {
        statuses.push(record?.pendingEnergyPrice);
      }
      return statuses.map(({ status, id }) => (
        <div key={id}>
          <div style={{ marginBottom: '10px' }}>{StatusChecker(status)}</div>
        </div>
      ));
    },
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Last Price Set',
    dataIndex: 'approvedEnergyPrice',
    width: '120px',
    align: 'left',
    render: (energyPrice: string, record: any) => {
      const field: IBillingInvoice = energyPrice ? energyPrice : record.pendingEnergyPrice;
      const formatted =
        formatDefaultDate(field?.createdAt) === 'n/a'
          ? 'not set'
          : formatDefaultDate(field?.createdAt);
      return formatted;
    },
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];
export default columns;
