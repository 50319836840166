export interface IWebhook {
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  deletedDate?: Date;
  id: string;
  url?: string;
  subscription?: any;
  apiClientId?: string;
  enabled?: boolean;
}

export enum WebhookSubscriptionType {
  ClientRequestStatusUpdate = 'client-request.status-updates',
  OfferStatusUpdate = 'offer.status-updates',
}
