import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ConsultantLocalLeadParametersService } from 'services/consulant/leadParameters';

import columns from './constants';

const ConsultantDefaultVariablesPage: React.FC = () => {
  return (
    <CrudListViewContainer
      entityName="Entities"
      columns={columns}
      resource={ConsultantLocalLeadParametersService}
    />
  );
};
export default ConsultantDefaultVariablesPage;
