import { IPagination } from 'framework/models';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { ITendergyUser } from 'store/tendegyService/tendergyService.types';

import { IAuthUser } from '../authService.types';

export const GET = 'users/GET';
export const GET_AUTH_USERS = 'users/GET_AUTH_USERS';
export const SET_AUTH_USERS = 'users/SET_AUTH_USERS';
export const SET = 'users/SET';

export const SET_LOADING_STATUS = 'users/SET_LOADING_STATUS';
export const RESET = 'users/RESET';

export interface UsersFilter {
  sortDirection: number;
  // ...
}

export interface UsersState {
  filters: UsersFilter;
  entries: ITendergyUser[];
  paginationCount: number;
  loading: boolean;
  authUsers: IAuthUser[];
}

export interface AuthUsersData {
  items: IAuthUser[];
  count: number | string;
}

export interface UsersData {
  items: ITendergyUser[];
  count: string;
}

export interface UserRole {
  name: string;
  id: number;
}

export interface GetUsersAction {
  type: typeof GET;
}

export interface GetAuthUsersAction {
  type: typeof GET_AUTH_USERS;
  payload: IGetMultiEntityPayload;
}

export interface SetAuthUsersAction {
  type: typeof SET_AUTH_USERS;
  payload: IPagination<IAuthUser>;
}

export interface SetUsersAction {
  type: typeof SET;
  payload: IPagination<ITendergyUser>;
}

export interface SetLoadingStatusAction {
  type: typeof SET_LOADING_STATUS;
  payload: boolean;
}

export interface ResetAction {
  type: typeof RESET;
}

export type UsersActionTypes =
  | GetUsersAction
  | SetUsersAction
  | SetLoadingStatusAction
  | ResetAction
  | GetAuthUsersAction
  | SetAuthUsersAction;
