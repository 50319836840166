import config from 'config';

import { ISA_ANSWERS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAAnswer } from '../isa-service.types';

const ISAAnswersService = new EntityService<IISAAnswer>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_ANSWERS_ENDPOINT,
  createLink: links.isaAnswersCreate,
  detailLink: links.isaAnswersDetails,
});

ISAAnswersService.getDisplayName = (entry) => entry.value || entry.logicalName;

export default ISAAnswersService;
