import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISAMappingService } from 'services/isa-service';
import { IParams } from 'services/isa-service/isa-service.types';

import ISAMappingForm from './components/ISAMappingForm';

export default function ISAMappingDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISAMappingForm entry={entry} onSubmit={onSave} />,
        },
      ]}
      entityName="mapping"
      baseLink={links.isaMappingDetails}
      defaultPayload={{ id, relations: ['wallbox', 'problem', 'solution', 'diagnostic'] }}
      resource={ISAMappingService}
      hideJsonTab
    />
  );
}
