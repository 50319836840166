import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import { UserRole } from 'framework/constants';
import links from 'framework/links';
import AuthOrganizationService from 'services/auth/auth-organization.service';
import UserAuthService from 'services/auth/user-auth.service';
import IntermediatesService from 'services/tendergy/tendergy-intermediates.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import { IAuthOrganization, IAuthUser } from 'store/authService/authService.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import {
  ITendergyOrganization,
  OrganizationTypes,
} from 'store/tendegyService/tendergyService.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import IntermediateBaseStep from './steps/IntermediateBaseStep';

const CreateIntermediatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const [chosenOrganization, setChosenOrganisation] = useState<ITendergyOrganization>();
  const [authOrganization, setAuthOrganisation] = useState<IAuthOrganization>();
  const [authManager, setAuthManager] = useState<IAuthUser>();

  const params: IGetSingleEntityPayload = {
    id,
  };

  const upgradeOrganization = async (entry: any) => {
    entry.organization.type = OrganizationTypes.Intermediate;
    try {
      await TendergyOrganizationService.save(entry.organization);
      setChosenOrganisation(entry.organization);
    } catch (e) {
      showErrorNotification(e);
      return;
    }
    showSuccessNotification('Organization was updated successfully');
  };

  const updateUser = async () => {
    const entry: IAuthUser = { ...authManager } as IAuthUser;
    entry.roles = [UserRole.TendergyInstaller, UserRole.TendergyIntermediateManager];
    // @ts-ignore
    entry.language = authManager?.language?.isoCode;
    try {
      await UserAuthService.save(entry);
    } catch (e) {
      showErrorNotification(e);
      return;
    }
    showSuccessNotification('Installer manger was set successfully');
  };

  const getAuthOrganization = async (organizationId: string) => {
    try {
      const response = await AuthOrganizationService.getById({ id: organizationId });
      setAuthOrganisation(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const getAuthUser = async (userId: string) => {
    try {
      const response = await UserAuthService.getById({ id: userId });
      setAuthManager(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    chosenOrganization && getAuthOrganization(chosenOrganization?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenOrganization]);

  useEffect(() => {
    authOrganization?.ownerId && getAuthUser(authOrganization.ownerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authOrganization]);

  const steps: ICrudStep[] = [
    {
      name: 'Choose organization to upgrade',
      render: (entry) => (
        <IntermediateBaseStep
          entry={entry}
          upgradeOrganization={upgradeOrganization}
          manager={authManager}
          updateUser={updateUser}
        />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Intermediate"
      steps={steps}
      baseLink={links.intermediate}
      defaultPayload={params}
      resource={IntermediatesService}
    />
  );
};

export default CreateIntermediatePage;
