import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillingOrganizationService from 'services/billing/billing-organizations.service';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<any> = [
  {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const BillingOrganizationsPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Billing Organizations"
    columns={columns}
    resource={BillingOrganizationService}
  />
);
export default BillingOrganizationsPage;
