import { IAuthUser } from 'store/authService/authService.types';

export interface UserCredentials {
  email: string;
  password: string;
  fingerprint?: string;
  context?: string;
}

export interface AuthState {
  user: IAuthUser;
  token?: string | null;
  loading: boolean;
  error?: string | null;
  fingerprint?: string | null;
}

// Init authentication flow
export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_FINGERPRINT = 'AUTH_FINGERPRINT';

// Login action types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';

// Refresh token
export const AUTH_TOKEN_REFRESH = 'AUTH_TOKEN_REFRESH';
export const AUTH_TOKEN_REFRESH_SUCCESS = 'AUTH_TOKEN_REFRESH_SUCCESS';
export const AUTH_TOKEN_REFRESH_ERROR = 'AUTH_TOKEN_REFRESH_ERROR';

// Logout action types
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR';

export interface LoginAction {
  type: typeof AUTH_LOGIN;
  payload: UserCredentials;
}

export interface LoginSuccessAction {
  type: typeof AUTH_LOGIN_SUCCESS;
  payload: { user: IAuthUser; token: string };
}

export interface LoginErrorAction {
  type: typeof AUTH_LOGIN_ERROR;
  payload: any;
}

export interface LogoutAction {
  type: typeof AUTH_LOGIN;
}

export interface LogoutSuccessAction {
  type: typeof AUTH_LOGIN_SUCCESS;
}

export interface LogoutErrorAction {
  type: typeof AUTH_LOGIN_ERROR;
}

export interface tokenRefreshAction {
  type: typeof AUTH_TOKEN_REFRESH;
}

export interface tokenRefreshSuccessAction {
  type: typeof AUTH_TOKEN_REFRESH_SUCCESS;
  payload: { user: IAuthUser; token: string };
}

export interface tokenRefreshErrorAction {
  type: typeof AUTH_TOKEN_REFRESH_ERROR;
  payload: any;
}

export type AuthActionTypes =
  | LoginAction
  | LoginSuccessAction
  | LoginErrorAction
  | LogoutAction
  | LogoutSuccessAction
  | LogoutErrorAction
  | tokenRefreshAction
  | tokenRefreshSuccessAction
  | tokenRefreshErrorAction;
