import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillChargingStationsService from 'services/billing-v2/billing-v2-charging-stations.service';
import {
  IBillingCarOwner,
  IBillingChargingStation,
} from 'services/billing-v2/billing-v2-service.types';

const columns: ColumnsType<IBillingChargingStation> = [
  {
    title: 'EVSE',
    dataIndex: 'evse',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Label',
    dataIndex: 'label',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Car Owner',
    dataIndex: 'carOwner',
    render: (carOwner: IBillingCarOwner) => `${carOwner?.firstname} ${carOwner?.lastname}`,
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const BillCarOwnersOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Charging stations"
    columns={columns}
    resource={BillChargingStationsService}
  />
);
export default BillCarOwnersOverviewPage;
