import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { ITendergyIntermediate } from 'store/tendegyService/tendergyService.types';

import links from '../../framework/links';

class IntermediatesServiceClass extends EntityService<ITendergyIntermediate> {
  getDisplayName(entry: ITendergyIntermediate): string {
    return entry.name;
  }

  getInvoicesStatistic = (requestPayload?: IGetMultiEntityPayload) =>
    this.get(`${this.config.entityURL}/invoice-statistic`, requestPayload);

  getOrganizationsStatistic = (requestPayload?: IGetMultiEntityPayload) =>
    this.get(`${this.config.entityURL}/organizations-statistic`, requestPayload);

  getPoolsStatistic = (id: string, requestPayload?: IGetMultiEntityPayload) =>
    this.get(`${this.config.entityURL}/pools-statistic/${id}`, requestPayload);

  getRequestsStatistic = (id: string, requestPayload?: IGetMultiEntityPayload) =>
    this.get(`${this.config.entityURL}/requests-statistic/${id}`, requestPayload);

  downloadOrganizationsStatistic = (fileName: string, requestPayload?: IGetMultiEntityPayload) =>
    this.downloadFile(
      this.getURL(`${this.config.entityURL}/organizations-statistic/?format=csv`),
      requestPayload,
      fileName,
    );

  downloadPoolsStatistic = (
    id: string,
    fileName: string,
    requestPayload?: IGetMultiEntityPayload,
  ) =>
    this.downloadFile(
      this.getURL(`${this.config.entityURL}/pools-statistic/${id}/?format=csv`),
      requestPayload,
      fileName,
    );

  downloadRequestsStatistic = (
    id: string,
    fileName: string,
    requestPayload?: IGetMultiEntityPayload,
  ) =>
    this.downloadFile(
      this.getURL(`${this.config.entityURL}/requests-statistic/${id}/?format=csv`),
      requestPayload,
      fileName,
    );
}

const IntermediatesService = new IntermediatesServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/intermediates',
  detailLink: links.organization,
  createLink: links.createIntermediate,
  name: 'Intermediate Organization',
});

export default IntermediatesService;
