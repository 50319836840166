import produce from 'immer';

import { OffersActionTypes, OffersState, RESET, SET, SET_LOADING_STATUS } from './offers.types';

const initialState: OffersState = {
  entries: [],
  loading: false,
  // filters: { // TODO: use later
  //   sortDirection: 1,
  // },
  paginationCount: 0,
};

const OffersReducer = (state = initialState, action: OffersActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET:
        draft.entries = action.payload.items;
        draft.paginationCount = action.payload.total;
        break;
      case SET_LOADING_STATUS:
        draft.loading = action.payload;
        break;
      case RESET:
        draft.entries = initialState.entries;
        draft.loading = initialState.loading;
        // draft.filters = initialState.filters;
        // draft.pagination = initialState.pagination;
        break;
    }
  });

export default OffersReducer;
