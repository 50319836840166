// Contains all login, logout actions
import { IAuthUser } from 'store/authService/authService.types';

import {
  AUTH_FINGERPRINT,
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_TOKEN_REFRESH,
  AUTH_TOKEN_REFRESH_ERROR,
  AUTH_TOKEN_REFRESH_SUCCESS,
  UserCredentials,
} from './auth.types';

export const authInit = () => ({
  type: AUTH_INIT,
});

export const getFingerprint = (fingerprint: string) => ({
  type: AUTH_FINGERPRINT,
  payload: { fingerprint },
});

export const login = (credentials: UserCredentials) => ({
  type: AUTH_LOGIN,
  payload: credentials,
});

export const loginSuccess = (user: IAuthUser, token: string) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: { user, token },
});

export const loginError = (error: any) => ({
  type: AUTH_LOGIN_ERROR,
  payload: error,
});

export const tokenRefresh = () => ({
  type: AUTH_TOKEN_REFRESH,
});

export const tokenRefreshSuccess = (user: IAuthUser, token: string) => ({
  type: AUTH_TOKEN_REFRESH_SUCCESS,
  payload: { user, token },
});

export const tokenRefreshError = (error: any) => ({
  type: AUTH_TOKEN_REFRESH_ERROR,
  payload: error,
});

export const logout = () => ({
  type: AUTH_LOGOUT,
});

export const logoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS,
});

export const logoutError = () => ({
  type: AUTH_LOGOUT_ERROR,
});
