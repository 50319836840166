import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISACarModelsService } from 'services/isa-service';
import { IParams } from 'services/isa-service/isa-service.types';

import ISACarModelsForm from './components/ISACarModelsForm';

export default function ISACarModelsDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISACarModelsForm entry={entry} onSubmit={onSave} />,
        },
      ]}
      entityName="car model"
      baseLink={links.isaCarModelsDetails}
      defaultPayload={{ id, relations: ['carBrand'] }}
      resource={ISACarModelsService}
      hideJsonTab
    />
  );
}
