export class CurrencyUtils {
  static formatCurrency = (value: any): string => {
    if (!value && parseFloat(value) === undefined) {
      return 'n/a';
    }
    return `${parseFloat(value).toFixed(2)} €`;
  };

  static defaultFormatter = (value: any): string => {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    });

    return formatter.format(value);
  };
}
