import * as React from 'react';
import { Form, FormItemProps, Select, SelectProps } from 'formik-antd';

export interface ISelectOption {
  value: string | number;
  label: string;
}

export interface IFormSelectProps extends Partial<FormItemProps> {
  name: string;
  options: (string | ISelectOption)[];
  selectProps?: Omit<SelectProps, 'options'>;
}

function FormSelect(props: IFormSelectProps) {
  const { name, options, selectProps = {}, ...rest } = props;

  return (
    <Form.Item name={name} {...rest}>
      <Select name={name} {...selectProps}>
        {options.map((option) => {
          if (typeof option === 'string') {
            return (
              <Select.Option value={option} key={option}>
                {option}
              </Select.Option>
            );
          }
          return (
            <Select.Option value={option.value} key={option.value}>
              {option.label}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}

export default FormSelect;
