import * as React from 'react';
import { Button, Table } from 'antd';

import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import BillingDocumentService from 'services/billing/billing-documents.service';
import { IBillingDocument, IBillingInvoice } from 'services/billing/billing-service.types';
import { formatDateFromIso } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const BillingInvoiceDetailsTab: React.FC<IGenericEditTabProps<IBillingInvoice>> = (
  props: IGenericEditTabProps<IBillingInvoice>,
) => {
  const { entry } = props;
  if (!entry) {
    return <div>data entry is missing</div>;
  }

  async function downloadInvoice(doc: IBillingDocument) {
    try {
      BillingDocumentService.downloadDocument(doc);
    } catch (e) {
      showErrorNotification(e);
    }
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (doc: IBillingDocument) => doc.displayId + doc.extName,
      width: 120,
      fixed: true,
    },
    {
      title: 'Type',
      key: 'type',
      render: (doc: IBillingDocument) => doc.type,
      width: 200,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (date: Date) => formatDateFromIso(date),
      width: 150,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (doc: IBillingDocument) => (
        <Button onClick={() => downloadInvoice(doc)}>Download</Button>
      ),
      width: 150,
    },
  ];

  return <Table scroll={{ x: '100%' }} columns={columns} dataSource={entry.documents} />;
};

export default BillingInvoiceDetailsTab;
