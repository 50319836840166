import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingCarOwner, IBillingRFIDCard } from './billing-service.types';

class RFIDCardsServiceClass extends EntityService<IBillingRFIDCard> {
  getDisplayName(entry: IBillingRFIDCard): string {
    return entry.identifier;
  }

  attachCardToCarOwner(card: IBillingRFIDCard, carOwner: IBillingCarOwner, params: any) {
    /**
     * POST /api/v1/admin/car-owner-rfid-cards&
     */
    // {
    //   "validFrom": "string",
    //   "validTo": "string",
    //   "carOwner": {
    //     "id": "string"
    //   },
    //   "rfidCard": {
    //     "id": "string"
    //   }
    // }
    params.rfidCard = { id: card.id };
    params.carOwner = { id: carOwner.id };

    return this.post('admin/car-owner-rfid-cards', params);
  }
}

const RFIDCardsService = new RFIDCardsServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/rfid-cards',
  detailLink: links.billingRFIDCard,
});

// this way I can autowire the result (with command + .);
export default RFIDCardsService;
