import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { notification } from 'antd';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import ApiClientService from 'services/auth/api-client.service';
import { IApiClient } from 'store/authService/authService.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ApiClientsBaseStep from './steps/ApiClientsBaseStep';

const CreateApiClientsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add api client',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <ApiClientsBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];

  const onSuccess = (entry: IApiClient) => {
    notification.info({
      duration: 0,
      message: 'API Client successfully registered',
      description: (
        <>
          <div>
            <b>API key:</b> {entry.apiKey}
          </div>
          <div>
            <b>Client Id:</b> {entry.clientId}
          </div>
        </>
      ),
    });
  };

  return (
    <CrudCreateWizardContainer
      entityName="Comment"
      steps={steps}
      baseLink={links.authApiClients}
      defaultPayload={params}
      resource={ApiClientService}
      onSuccess={onSuccess}
    />
  );
};

export default CreateApiClientsPage;
