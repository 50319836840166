import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISAAnswersService } from 'services/isa-service';

import ISAAnswersForm from './components/ISAAnswersForm';

export default function ISAAnswersCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Answer',
          render: (_entry, onSubmit) => <ISAAnswersForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="answer"
      baseLink={links.isaAnswersOverview}
      resource={ISAAnswersService}
    />
  );
}
