import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import BillOrganizationService from 'services/billing-v3/billing-v3-organizations.service';

import BillOrganizationForm from './components/BillOrganizationForm/BillOrganizationForm';

export default function BillOrganizationCreatePage() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Billing Organization',
          render: (entry, onSubmit) => <BillOrganizationForm onSubmit={onSubmit} entry={entry} />,
        },
      ]}
      entityName="billing organization"
      baseLink={links.billOrganizations}
      resource={BillOrganizationService}
    />
  );
}
