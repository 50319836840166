import * as Yup from 'yup';

/*
export interface IBillingRFIDCard extends IBaseEntity {
  identifier: string;
  evcoId?: string;
  username?: string;
  fullname?: string;
  isPrivate: boolean;
}

*/
export const RFIDCardValidation = {
  identifier: Yup.string().min(2, 'Too Short!').max(15, 'Too Long!').required('Required'),
  evcoId: Yup.string().nullable().min(2, 'Too Short!').max(30, 'Too Long!'),
  username: Yup.string().min(2, 'Too Short!').max(15, 'Too Long!'),
  fullname: Yup.string().min(2, 'Too Short!').max(15, 'Too Long!'),
  isPrivate: Yup.boolean(),
};
