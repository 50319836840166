import * as Yup from 'yup';

export const INITIAL_VALUES = {
  name: null,
  carModels: null,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  carModels: Yup.array().nullable(),
});
