import React, { useRef, useState } from 'react';
import { CustomPicker, InjectedColorProps } from 'react-color';
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
// import Swatch from 'react-color/lib/components/common/Swatch';
import { useField } from 'formik';

import { useOutsideClick } from 'utils/UseOutsideClick';

import styles from './ColorPickerStyles.module.sass';

/* const AppStyleSwatch = ({ color, onClick }) => {
    const handleClick = (e: Event) => onClick(color, e);
    return (
        <div className={styles.swatchWrapper}>
            <Swatch color={color} onClick={handleClick} />
        </div>
    );
}; */

interface IPickerProps extends InjectedColorProps {
  hex?: string;
}

interface IColorPickerField {
  name: string;
}

const Picker: React.FC<IPickerProps> = (props: IPickerProps) => {
  const { hex, onChange /* , colours */ } = props;
  return (
    <div className={styles.pickerWrapper}>
      <div className={styles.saturationWrapper}>
        {/* @ts-ignore */}
        <Saturation {...props} pointer={() => <div className={styles.saturationPointer} />} />
      </div>
      <div className={styles.hueWrapper}>
        {/* @ts-ignore */}
        <Hue {...props} pointer={() => <div className={styles.huePointer} />} />
      </div>
      <div className={styles.editableInputWrapper} color={hex}>
        <div className={styles.editableInputSwatch} style={{ backgroundColor: `${hex}` }} />
        <EditableInput value={hex} onChange={onChange} />
      </div>
      {/* <div className={styles.swatchesWrapper}>
        {colours.map((colour: any, index: string) => (
          <AppStyleSwatch
            key={`${colour}_${index}`}
            color={colour}
            onClick={props.onChange}
          />
        ))}
      </div> */}
    </div>
  );
};
const ColorPicker = CustomPicker(Picker);

export const ColorPickerField: React.FC<IColorPickerField> = ({ name }: IColorPickerField) => {
  const [{ value }, , { setValue }] = useField(name);
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(false);
  };

  const handleChange = (args: IPickerProps) => {
    const { hex } = args;
    setValue(hex);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, handleClose);
  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div
        className={styles.colorSwatch}
        style={{ backgroundColor: `${value}` }}
        color={value}
        onClick={handleClick}
      />
      {visible ? (
        <div className={styles.pickerContainer}>
          {/* @ts-ignore */}
          <ColorPicker color={value} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};
