import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Input, Pagination, Row, Table, Typography } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ColumnType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { Key, SorterResult } from 'antd/lib/table/interface';

import { Loader } from 'components/ui';
import * as CRUD from 'constants/crud';
import EntityService from 'services/abstract/entity.service';
import { changeLastOpenedPage } from 'store/common/common.actions';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { showErrorNotification } from 'utils/notificationUtil';

import { crudColumnsTransform, ISearchValue } from '../utils/crud-columns-transform';
import CrudListViewActions, {
  ICrudListViewActionsProps,
  ISortField,
} from './components/CrudListViewActions';

import styles from './abstract-crud.module.sass';

export interface IColumns<T> extends ColumnType<T> {
  searchKey?: string;
  filterParameter?: string;
  sortParameter?: string;
}

export interface ICustomFiltersProps {
  onFilter: (value: any) => void;
}

export interface ICrudListViewProps<T> {
  entityName: string;
  columns: IColumns<T>[];
  resource: EntityService<T>;
  disableSearch?: boolean;
  withSorting?: boolean;
  defaultPayload?: IGetMultiEntityPayload;
  statesForFiltering?: string[];
  additionalPayload?: object;
  sortFields?: ISortField[];
  customFilters?: React.ComponentType<ICustomFiltersProps>;
  additionalPathname?: string;
}

/** **
 * @docs:
 *  This cotainer has the purpose to wrap a Resource (for example HTTPService)
 *  to the listview. Basically it should handle: List, Pagnation, Service, Filter etc. for all entites.
 *
 * As Parameter it should take all needed configuration for the single pages.
 * Basically this is made to save duplicate code for every entity.
 *
 * Please have a look into UserListView.tsx to see how it simpliefies the process
 *
 * TODO:
 *  - Store (it does not use redux yet, only local state);
 *  - allow to add all parameters to Antdesign table
 *
 */

export default function CrudListViewContainer<T extends object>(props: ICrudListViewProps<T>) {
  const {
    resource,
    entityName,
    columns,
    disableSearch,
    statesForFiltering,
    defaultPayload,
    additionalPayload,
    withSorting,
    sortFields,
    additionalPathname,
    customFilters: CustomFilters,
  } = props;
  const locationPage = new URLSearchParams(window.location.search).get('page');
  const [loading, setLoading] = useState<boolean>(false);
  const [entries, setEntries] = useState<T[] | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(
    locationPage ? +locationPage : CRUD.DEFAULT_PAGE,
  );
  const [count, setCount] = useState<number>(20);
  const [columnsFilter, setColumnsFilter] = useState<ISearchValue>({});
  const [payload, setPayload] = useState<IGetMultiEntityPayload>({
    limit: defaultPayload?.limit || CRUD.DEFAULT_LIMIT,
    offset: locationPage
      ? (+locationPage - 1) * CRUD.DEFAULT_LIMIT
      : defaultPayload?.offset || CRUD.DEFAULT_OFFSET,
    sortField: defaultPayload?.sortField || CRUD.DEFAULT_SORT_FIELD,
    sortFields: defaultPayload?.sortFields || CRUD.DEFAULT_SORT_FIELDS,
    filters: defaultPayload?.filters || CRUD.DEFAULT_FILTERS,
    query: defaultPayload?.query || CRUD.DEFAULT_QUERY,
    relations: defaultPayload?.relations || CRUD.DEFAULT_RELATIONS,
    sortDirection: defaultPayload?.sortDirection || CRUD.DEFAULT_SORT_DIRECTION,
    sortDirections: defaultPayload?.sortDirections || CRUD.DEFAULT_SORT_DIRECTIONS,
  });
  const [customPayload, setCustomPayload] = useState<any>({});
  const history = useHistory();
  const params = useParams<any>();
  const dispatch = useDispatch();

  const createLink = resource.getCreateLink(params.id);
  const payloadFilter = payload?.state;
  const payloadSortFields = payload?.sortFields;
  const payloadSortDirections = payload?.sortDirections;
  const payloadQuery = payload?.query;
  const payloadOffset = payload?.offset;
  const payloadLimit = payload?.limit;
  const filterInput = useRef<Input>(null);
  const refreshEntries = useCallback(async () => {
    setLoading(true);
    if (additionalPathname) {
      resource.config.additionalPathname = additionalPathname;
    }

    try {
      const response = await resource.getEntries({
        ...payload,
        ...additionalPayload,
        ...columnsFilter,
        ...customPayload,
      });
      setEntries(response?.items);
      setCount(response?.total || response?.count);
    } catch (error) {
      showErrorNotification(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payloadQuery,
    payloadOffset,
    payloadFilter,
    columnsFilter,
    payloadSortFields,
    payloadSortDirections,
    customPayload,
    additionalPayload,
    payloadLimit,
  ]);
  console.log('entries', entries);
  useEffect(() => {
    if (entries) {
      setCurrentPage(CRUD.DEFAULT_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payloadQuery,
    payloadFilter,
    columnsFilter,
    payloadSortFields,
    payloadSortDirections,
    customPayload,
    payloadLimit,
  ]);

  useEffect(() => {
    setPayload({
      ...payload,
      offset: ((currentPage || 1) - 1) * payload.limit!,
    });

    dispatch(
      changeLastOpenedPage(
        `${window.location.pathname}?page=${currentPage || 1}`,
        resource.getEntityUrl(),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, payload.limit]);
  useEffect(() => {
    refreshEntries();
  }, [refreshEntries]);
  function navigateToDetails(record: T) {
    const link = resource.getDetailLink(record);
    return link;
  }
  function navigate(record: T) {
    const link = resource.getDetailLink(record);
    if (!link) {
      return null;
    }

    history.push(link);
  }
  function handleRowClick(record: T) {
    return navigate?.(record);
  }

  function handleCreateClick() {
    if (!createLink) {
      throw new Error('create link not set in entityservice');
    }

    history.push(createLink);
  }
  function handleSearch(value: string) {
    setPayload({
      ...payload,
      offset: CRUD.DEFAULT_OFFSET,
      query: value.trim() || undefined,
    });
  }

  function handleFilter(value: string) {
    setPayload({ ...payload, offset: CRUD.DEFAULT_OFFSET, state: value });
  }

  function handleCustomFilter(value: any) {
    setCustomPayload(value);
  }

  function handleSortChange(value: string | number) {
    setPayload({ ...payload, offset: CRUD.DEFAULT_OFFSET, sortFields: value || undefined });
  }

  function handleSortDirectionChange(e: RadioChangeEvent) {
    setPayload({
      ...payload,
      offset: CRUD.DEFAULT_OFFSET,
      sortDirections: +e.target.value || undefined,
    });
  }

  const crudListViewActionsProps: ICrudListViewActionsProps<T> = {
    onFilter: handleFilter,
    onSearch: handleSearch,
    onCreate: handleCreateClick,
    onSort: handleSortChange,
    onSortDirection: handleSortDirectionChange,
    createLink,
    entityName,
    disableSearch,
    statesForFiltering,
    columns,
    withSorting,
    sortFields,
    isCustomFilters: !!CustomFilters,
  };
  interface ISorter<T> extends SorterResult<T> {
    searchKey?: string;
    column: IColumns<T>;
    filterParameter?: string;
  }
  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, (Key | boolean)[] | null>,
    sorter: ISorter<T> | ISorter<T>[],
  ) => {
    if (!Array.isArray(sorter) && !Array.isArray(sorter.field) && sorter.field) {
      const sortField = sorter.column?.sortParameter ? sorter.column.sortParameter : sorter.field;
      setPayload({
        ...payload,
        offset: CRUD.DEFAULT_OFFSET,
        sortDirections: sorter.order === 'ascend' ? '1' : '-1',
        sortFields: sortField,
      });
    }
  };

  const tableProps: TableProps<T> = {
    rowKey: resource.config.entityPrimaryKey || 'id',
    scroll: { x: '100%' },
    // @ts-ignore
    onRow: (record) => ({ onClick: () => handleRowClick?.(record) }),
    columns: crudColumnsTransform(
      columns,
      filterInput,
      columnsFilter,
      setColumnsFilter,
      setPayload,
      payload,
      navigateToDetails,
    ),
    dataSource: entries || [],
    pagination: false,
    // @ts-ignore
    onChange: handleTableChange,
  };
  const paginationProps: PaginationProps = {
    total: count,
    showTotal: (total) => `Total ${total} items`,
    showSizeChanger: false,
    current: currentPage,
    onChange: (page: number) => {
      setCurrentPage(page);
    },
    showQuickJumper: true,
    defaultPageSize: payload.limit,
  };
  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPayload({ ...payload, limit: pageSize });
  };
  return (
    <div className={styles.container}>
      {/* <CSVLink filename={'Expense_Table.csv'} data={entries || []} headers={columns as any}>
        Export to CSV
      </CSVLink> */}
      <Row>
        <Col span={12}>
          <Typography.Title level={3} style={{ lineHeight: 1 }}>
            List of {entityName.toLowerCase()}
            {loading && <Loader className={styles.loader} />}
          </Typography.Title>
        </Col>
        <CrudListViewActions {...crudListViewActionsProps} />
      </Row>
      {CustomFilters && <CustomFilters onFilter={handleCustomFilter} />}
      <Table {...tableProps} />
      <div className={styles.pagination}>
        <Pagination
          {...paginationProps}
          pageSizeOptions={['20', '50', `${count < 100 ? count : 100}`]}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </div>
  );
}
