import config from 'config';

import { OfferEventType } from 'framework/constants';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IOffer } from 'store/tendegyService/tendergyService.types';

class OfferAPI extends EntityService<IOffer> {
  transition = (type: OfferEventType, id: string, rejectReason?: string) => {
    this.put('offers/transition', {
      type,
      offer: {
        id,
        rejectReason,
      },
    });
  };

  getDisplayName(entry: IOffer): string {
    return entry.displayId as string;
  }
}

const OfferService = new OfferAPI({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/offers',
  detailLink: links.offer,
});

export default OfferService;
