import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import { Avatar, Button, Slider } from 'antd/lib';
import _get from 'lodash/get';

import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import { ITendergyOrganization } from 'store/tendegyService/tendergyService.types';

import 'cropperjs/dist/cropper.css';
import styles from './LogoTab.module.sass';

interface CraftOrganizationTabProps {
  organization?: ITendergyOrganization | null;
  getCraftData: () => Promise<any>;
}

const LogoTab: React.FC<CraftOrganizationTabProps> = (props: CraftOrganizationTabProps) => {
  const { organization, getCraftData } = props;
  const [loading, setLoading] = useState(false);
  const [deleteloading, setdeleteLoading] = useState(false);
  const [zoom, setzoom] = useState(0.1);
  const [range, setRange] = useState(1);
  const [image, setImage] = useState('');
  const [showCropper, setShowCropper] = useState(false);
  const orgId = organization?.id;
  const orgLogo = organization?.logo;
  const cropperRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  if (!organization) {
    return <div> Loading </div>;
  }

  const onclickUpload = () => {
    inputRef?.current?.click();
  };

  const deleteLogo = () => {
    setdeleteLoading(true);
    if (orgId) {
      TendergyOrganizationService.deleteOrganizationLogo(orgId).then((res: any) => {
        if (res) {
          getCraftData();
          setImage('');
          setShowCropper(false);
          setdeleteLoading(false);
        }
      });
    }
  };
  const uploadProfile = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    cropper.getCroppedCanvas().toBlob((blob: any) => {
      const file = _get(inputRef, 'current.files[0]', null);
      let CropedFile = new File([blob], file.name);
      if (orgId) {
        setLoading(true);
        TendergyOrganizationService.uploadOrganizationLogo(orgId, CropedFile).then((res: any) => {
          if (res) {
            setImage('');
            setShowCropper(false);
            getCraftData();
            setTimeout(() => {
              setLoading(false);
            }, 2000);
            if (inputRef) {
              inputRef?.current?.value ? (inputRef.current.value = '') : setImage('');
            }
          }
        });
      }
    });
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const files = event.target.files;
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result as any);
    };

    if (files) {
      reader.readAsDataURL(files[0]);
      setShowCropper(true);
    }
  };
  const setZoom = (val: number) => {
    setRange(val);
    setzoom(val / 10);
  };
  const renderOrganizationData = () => {
    const cropperProps = {
      src: image,
      ref: cropperRef,
      zoomTo: zoom,
      initialAspectRatio: 1,
      guides: true,
      autoCropArea: 1,
    };
    return (
      <div className={styles.userInfo}>
        <div>
          <input ref={inputRef} type="file" hidden={true} onChange={onFileChange} />
          {loading ? (
            ' loading....'
          ) : (
            <div className={styles.uploadProfileBox}>
              {!showCropper && (
                <Avatar
                  className={styles.organizationLogo}
                  shape="square"
                  size={100}
                  src={orgLogo ? orgLogo : ''}
                  icon={<UserOutlined />}
                />
              )}

              {showCropper ? (
                <>
                  <div className={styles.imageContainer}>
                    <Cropper
                      {...cropperProps}
                      checkOrientation={false}
                      style={{ position: 'absolute', height: '100%', width: '100%' }}
                    />
                  </div>
                  <div className={styles.sliderLogo}>
                    <MinusOutlined style={{ margin: 'auto 10px' }} />
                    <Slider
                      style={{ width: '220px' }}
                      value={range}
                      onChange={setZoom}
                      min={1}
                      max={10}
                    />
                    <PlusOutlined style={{ margin: 'auto 10px' }} />
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </div>

        <div style={{ marginTop: '20px' }}>
          <Button // @ts-ignore
            type="secondary"
            className={styles.uploadBtn}
            onClick={showCropper ? uploadProfile : onclickUpload}
            loading={loading}
            secondary
          >
            {showCropper ? 'Upload Avatar' : 'Update Avatar'}
          </Button>
          {!showCropper && orgLogo && (
            <Button
              type="primary"
              loading={deleteloading}
              className={styles.deleteBtn}
              onClick={deleteLogo}
              danger
            >
              Delete Avatar
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {' '}
      <div className={styles.profileWrapper}>
        <div className={styles.editProfile}></div>
      </div>
      <div className={styles.container}>
        <div className={styles.cardUser}>{renderOrganizationData()}</div>
      </div>
    </>
  );
};

export default LogoTab;
