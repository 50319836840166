import * as React from 'react';
import { Descriptions } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Select, SubmitButton } from 'formik-antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import OfferService from 'services/tendergy/tendergy-offer.service';
import {
  AppointmentPurpose,
  ITendergyAppointment,
  OfferingUserType,
} from 'store/tendegyService/tendergyService.types';

interface Props {
  appointment?: ITendergyAppointment;
  onSave?: Function;
}

const AppointmentDetailsComponent: React.FC<Props> = ({ appointment, onSave }: Props) => {
  if (!appointment) {
    return <div> Appointment loading.. </div>;
  }

  if (!onSave) {
    return <div>onSave method required</div>;
  }

  const renderAppointment = () => (
    <Formik
      initialValues={appointment as ITendergyAppointment}
      onSubmit={(values, { setSubmitting }) => {
        onSave(values).then(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
          <Descriptions title="Appointment details" column={1} layout="horizontal">
            <Form.Item name="proposedBy" label="Proposed By">
              <Select name="proposedBy">
                <Select.Option value={OfferingUserType.END_USER}>Customer</Select.Option>
                <Select.Option value={OfferingUserType.INSTALLER}>Installer</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="purpose" label="Purpose">
              <Select name="purpose" allowClear>
                <Select.Option value={AppointmentPurpose.HomeCheck}>HomeCheck</Select.Option>
                <Select.Option value={AppointmentPurpose.Installation}>Installation</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="offer" label="Appointment timestamp">
              <DatePicker name="timestamp" showTime />
            </Form.Item>

            <Form.Item name="offer" label="Offer">
              <EntitySelector
                current={values.offer}
                onSelect={(offer) => {
                  setFieldValue('offer', offer);
                }}
                entityService={OfferService}
                name="offer"
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" style={{ marginRight: '20px' }}>
                Submit
              </SubmitButton>
            </Form.Item>
          </Descriptions>
        </Form>
      )}
    </Formik>
  );

  return <div>{renderAppointment()}</div>;
};

export default AppointmentDetailsComponent;
