import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IAuthOrganization } from 'store/authService/authService.types';

class AuthOrganizationServiceClass extends EntityService<IAuthOrganization> {
  getMembersById = (id: string) => this.get(`${this.config.entityURL}/${id}/members`);
}
const AuthOrganizationService = new AuthOrganizationServiceClass({
  apiURL: config.ORGANIZATION_SERVICE_URL,
  entityURL: 'admin/organizations',
  // createLink: links.createOrganization, //disabled for now, might need it later
  detailLink: links.organization,
});

export default AuthOrganizationService;
