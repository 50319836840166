import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Select } from 'antd';

import { eConsultantLeadStatus } from 'framework/constants';
import BillChargingStationsService from 'services/billing-v3/billing-v3-charging-stations.service';
import { showErrorNotification } from 'utils/notificationUtil';

export interface IFilters {
  query: null | string;
  status: null | eConsultantLeadStatus;
  chargingPointGroupId: null | string;
  plugType: null | EPlugTypes;
  type: null | EType;
}
enum EPlugTypes {
  Unknown = 'unknown',
  Chademo = 'chademo',
  Combo = 'combo',
  Type2 = 'type2',
  Type3 = 'type3',
  Nema = 'nema',
  Avcon = 'avcon',
}
enum EType {
  Home = 'home',
  Work = 'work',
  Public = 'public',
}

interface IProps {
  onFilter?: (value: any) => void;
}

const Filters = (props: IProps) => {
  const { onFilter } = props;
  const [pointGroups, setGroupPoints] = useState<any>([]);
  const getChargingGroups = async () => {
    try {
      const response = await BillChargingStationsService.get('admin/charging-point-groups');
      setGroupPoints(response?.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };
  useEffect(() => {
    getChargingGroups();
  }, []);
  const [value, setValue] = useState<IFilters>({
    query: null,
    status: null,
    chargingPointGroupId: null,
    plugType: null,
    type: null,
  });

  useEffect(() => {
    onFilter &&
      onFilter({
        ...value,
      });
  }, [value]); // eslint-disable-line

  const queryInputProps = {
    placeholder: 'Search ...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };

  const TypeProps = {
    placeholder: 'Type',
    onChange: (type: EType) => {
      setValue({ ...value, type });
    },
    style: { width: '100%' },
    allowClear: true,
    options: Object.values(EType).map((value: string) => ({ value, label: value })),
  };
  const PlugTypeProps = {
    placeholder: 'Plug Type',
    onChange: (plugType: EPlugTypes) => {
      setValue({ ...value, plugType });
    },
    style: { width: '100%' },
    allowClear: true,
    options: Object.values(EPlugTypes).map((value: string) => ({ value, label: value })),
  };
  const chargingPointGroupIdProps = {
    placeholder: 'chargingPointGroupId',
    onChange: (groupId: eConsultantLeadStatus) => {
      setValue({ ...value, chargingPointGroupId: groupId });
    },
    style: { width: '100%' },
    allowClear: true,
    options: pointGroups?.map(({ name, id }: { name: string; id: string }) => ({
      value: id,
      label: name,
    })),
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col flex="1">
        <Input {...queryInputProps} />
      </Col>
      <Col flex="1">
        <Select {...TypeProps} />
      </Col>
      <Col flex="1">
        <Select {...PlugTypeProps} />
      </Col>
      <Col flex="1">
        <Select {...chargingPointGroupIdProps} />
      </Col>
    </Row>
  );
};

export default Filters;
