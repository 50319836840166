import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

import Box from 'components/layout/Box/Box';

import styles from './successStep.module.sass';

export interface ISuccessButtonProps {
  label?: string;
  link: string;
}
export interface ISuccessStepProps {
  buttons?: ISuccessButtonProps[];
  title?: string;
}

const SuccessStep: React.FC<ISuccessStepProps> = (props: ISuccessStepProps) => {
  const { Title, Paragraph } = Typography;
  const history = useHistory();

  const { buttons, title } = props;
  const visibleTitle = title || 'Success';

  const renderButtons = () => {
    if (!buttons) {
      return <div />;
    }
    return (
      <div>
        {buttons.map((button: ISuccessButtonProps, index: number) => (
          <Button
            key={index}
            className={styles.button}
            type="primary"
            onClick={() => history.push(button.link)}
          >
            {button.label || 'Next'}
          </Button>
        ))}
      </div>
    );
  };
  return (
    <Box>
      <div className={styles.successStep}>
        <div>
          <CheckCircleOutlined className={styles.icon} />
        </div>
        <Title level={3}>{visibleTitle}</Title>
        <Paragraph>{renderButtons()}</Paragraph>
      </div>
    </Box>
  );
};

export default SuccessStep;
