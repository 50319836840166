interface IConfigEnv {
  [key: string]: any;
}

interface IConfig {
  development: IConfigEnv;
  staging: IConfigEnv;
  production: IConfigEnv;
}

const configOptions: IConfig = {
  development: {
    AUTH_SERVICE_URL: 'https://auth.development.tendergy.com/api/v2',
    // BASED ON https://inno2grid.atlassian.net/browse/ARCH-316 WE DECIDE TO REMOVE
    // ALL V1 AUTH SERVICES BELOW FOR NOW AND USE FROM COMMON MS WHEN IMPLEMENTED INSTEAD OF AUTH V1.
    USER_SERVICE_URL: 'https://auth.development.tendergy.com/api/v1',
    ORGANIZATION_SERVICE_URL: 'https://auth.development.tendergy.com/api/v1',
    ROLE_SERVICE_URL: 'https://auth.development.tendergy.com/api/v1',
    PERMISSION_SERVICE_URL: 'https://auth.development.tendergy.com/api/v1',
    CLIENT_SERVICE_URL: 'https://auth.development.tendergy.com/api/v1',
    // UNTIL HERE.
    EMAIL_SERVICE_URL: 'https://email.development.tendergy.com/api/v1',
    CRAFT_SERVICE_URL: 'https://craft.development.tendergy.com/api/v1',
    CRAFT_AUTH_SERVICE_URL: 'https://craft.development.tendergy.com/api/v2',
    BILLING_SERVICE_URL: 'https://i2g-billing-be.development.tendergy.com/api/v1',
    SHOP_SERVICE_URL: 'https://i2g-shop-be-v1.development.tendergy.com/api/v1',
    TENDERGY_UI_URL: 'https://tendergy.development.tendergy.com/',
    I2G_SERVICE_URL: 'https://i2g-service-be.development.tendergy.com/api/v1',
    INTEGRATION_SERVICE_URL: 'https://integration.development.tendergy.com/api/v1',

    FLEETAPP_SERVICE_URL: 'https://i2g-fleetapp-be.development.tendergy.com/api/v1',
    CONSULTANT_SERVICE_URL: 'https://i2g-digital-consultant-be.development.tendergy.com/api/v1',
    NEW_PORTAL_LINK: 'https://portal.development.inno2fleet.com/',
    // SENTRY
    SENTRY_DSN_URL: 'https://61e00715435f42a9af11fbcac25dfbb6@o963834.ingest.sentry.io/6539106',
    SENTRY_ENVIRONMENT: 'development',
  },

  staging: {
    AUTH_SERVICE_URL: 'https://auth.staging.tendergy.com/api/v2',
    // BASED ON https://inno2grid.atlassian.net/browse/ARCH-316 WE DECIDE TO REMOVE
    // ALL V1 AUTH SERVICES BELOW FOR NOW AND USE FROM COMMON MS WHEN IMPLEMENTED INSTEAD OF AUTH V1.
    USER_SERVICE_URL: 'https://auth.staging.tendergy.com/api/v1',
    ORGANIZATION_SERVICE_URL: 'https://auth.staging.tendergy.com/api/v1',
    ROLE_SERVICE_URL: 'https://auth.staging.tendergy.com/api/v1',
    PERMISSION_SERVICE_URL: 'https://auth.staging.tendergy.com/api/v1',
    CLIENT_SERVICE_URL: 'https://auth.staging.tendergy.com/api/v1',
    // UNTIL HERE.
    EMAIL_SERVICE_URL: 'https://email.staging.tendergy.com/api/v1',
    CRAFT_SERVICE_URL: 'https://craft.staging.tendergy.com/api/v1',
    CRAFT_AUTH_SERVICE_URL: 'https://craft.staging.tendergy.com/api/v2',
    BILLING_SERVICE_URL: 'https://i2g-billing-be.staging.tendergy.com/api/v1',
    SHOP_SERVICE_URL: 'https://i2g-shop-be-v1.staging.tendergy.com/api/v1',
    TENDERGY_UI_URL: 'https://tendergy.staging.tendergy.com/',
    I2G_SERVICE_URL: 'https://i2g-service-be.staging.tendergy.com/api/v1',
    CONSULTANT_SERVICE_URL: 'https://i2g-digital-consultant-be.staging.tendergy.com/api/v1',
    FLEETAPP_SERVICE_URL: 'https://i2g-fleetapp-be.staging.tendergy.com/api/v1',
    NEW_PORTAL_LINK: 'https://portal.staging.inno2fleet.com/',

    // SENTRY
    SENTRY_DSN_URL: 'https://61e00715435f42a9af11fbcac25dfbb6@o963834.ingest.sentry.io/6539106',
    SENTRY_ENVIRONMENT: 'staging',
  },

  production: {
    AUTH_SERVICE_URL: 'https://auth.services.tendergy.com/api/v2',
    // BASED ON https://inno2grid.atlassian.net/browse/ARCH-316 WE DECIDE TO REMOVE
    // ALL V1 AUTH SERVICES BELOW FOR NOW AND USE FROM COMMON MS WHEN IMPLEMENTED INSTEAD OF AUTH V1.
    USER_SERVICE_URL: 'https://auth.services.tendergy.com/api/v1',
    ORGANIZATION_SERVICE_URL: 'https://auth.services.tendergy.com/api/v1',
    ROLE_SERVICE_URL: 'https://auth.services.tendergy.com/api/v1',
    PERMISSION_SERVICE_URL: 'https://auth.services.tendergy.com/api/v1',
    CLIENT_SERVICE_URL: 'https://auth.services.tendergy.com/api/v1',
    // UNTIL HERE.
    EMAIL_SERVICE_URL: 'https://email.services.tendergy.com/api/v1',
    CRAFT_SERVICE_URL: 'https://craft.services.tendergy.com/api/v1',
    CRAFT_AUTH_SERVICE_URL: 'https://craft.services.tendergy.com/api/v2',
    BILLING_SERVICE_URL: 'https://i2g-billing-be.services.tendergy.com/api/v1',
    SHOP_SERVICE_URL: 'https://shop.services.tendergy.com/api/v1',
    TENDERGY_UI_URL: 'https://tendergy.com/',
    I2G_SERVICE_URL: 'https://i2g-service.services.tendergy.com/api/v1',
    CONSULTANT_SERVICE_URL: 'https://econsultant-api.inno2fleet.com/api/v1',
    FLEETAPP_SERVICE_URL: 'https://i2g-fleetapp-be.services.tendergy.com/api/v1',
    NEW_PORTAL_LINK: 'https://portal.inno2fleet.com/',

    // Production
    SENTRY_DSN_URL: 'https://61e00715435f42a9af11fbcac25dfbb6@o963834.ingest.sentry.io/6539106',
    SENTRY_ENVIRONMENT: 'development',
  },
};

const env = process.env.REACT_APP_CUSTOM_NODE_ENV || 'development';
// @ts-ignore
const config: IConfigEnv = configOptions[env];

export default config;
