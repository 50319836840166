import {Breadcrumb as AntBreadcrumb} from "antd";
import React from "react";
import {NavLink} from 'react-router-dom';
import {useLocation} from "react-router-dom";

interface IBreads {
    label: string
    route?: string
}
interface IProps{
    breads?:IBreads[];
}
const Breadcrumb = ({breads}:IProps) => {
    const location = useLocation();
    const pathname = location.pathname.split('/');
    const handleBread = (paths: any[]) => {
        let a: IBreads[] = []
        for (let path of paths) {
            if (path === '') {
                a.push({label: 'Dashboard', })
            }
            if (path.length && path.length !== 36) {
                a.push({label: path})
            }
        }
        return a;
    }
    const paths =breads || handleBread(pathname);

    return (
        <>
            {
                paths && <AntBreadcrumb>
                    {paths.map(({label,route},index) => <AntBreadcrumb.Item key={label}>
                        {
                            paths.length === index + 1 ? label : route ? <NavLink to={route}>
                                {label}
                            </NavLink>:<>{label}</>
                        }
                    </AntBreadcrumb.Item>)}
                </AntBreadcrumb>
            }
        </>
    )
}
export default Breadcrumb;