import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

class CarModelsServiceClass extends EntityService<any> {}

const CarModelsService = new CarModelsServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/car-models',
  createLink: links.shopCreateCarModel,
  detailLink: links.shopCarModel,
});

export default CarModelsService;
