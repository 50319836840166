import React from 'react';

import { FormikForm, FormInput, FormRate, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { IISAFeedback } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISAFeedback;
  onSubmit: (entry: IISAFeedback) => Promise<IISAFeedback>;
}

export default function ISAFeedbacksForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISAFeedback> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISAFeedback,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="description" label="Description" required />
      <FormRate name="rating" label="Rating" required />
      <FormSubmit />
    </FormikForm>
  );
}
