import * as React from 'react';
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form, SubmitButton, Switch } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import EntityService from 'services/abstract/entity.service';
import AuthRoleService from 'services/auth/role.service';
import { IAuthPermission, IAuthRole } from 'store/authService/authService.types';

interface OverviewProps {
  entry?: IAuthRole;
  onSave: (updatedValues: any, resource: EntityService<any>) => Promise<any>;
  permissions: IAuthPermission[];
}

const RoleOverviewTab: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { entry, onSave, permissions } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const switchOption = (optionToSwitch: IAuthPermission, optionsList: IAuthPermission[] = []) => {
    const existedOption = optionsList.find((item) => item.name === optionToSwitch.name);
    if (existedOption) {
      return optionsList.filter((item) => item.name !== optionToSwitch.name);
    } else {
      return [...optionsList, optionToSwitch];
    }
  };

  const convertOptionName = (name: string) => {
    const convertedName = name.charAt(0).toUpperCase() + name.slice(1);
    return convertedName.replaceAll('_', ' ');
  };

  return (
    <div>
      <Formik
        initialValues={entry}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values, AuthRoleService).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name">
              {values.name}
            </Form.Item>

            <Form.Item name="permissions" label="Permissions">
              {permissions?.map((permission) => (
                <Row key={permission.name} gutter={[8, 8]} wrap={false}>
                  <Col>
                    <Switch
                      checked={!!values.permissions?.find((item) => item.name === permission.name)}
                      name={permission.name}
                      onChange={() =>
                        setFieldValue('permissions', switchOption(permission, values.permissions))
                      }
                    />
                  </Col>
                  <Col>
                    {convertOptionName(permission.name)}. {permission.description}
                  </Col>
                </Row>
              ))}
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RoleOverviewTab;
