import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import moment from 'moment';

import { Loader } from 'components/ui';
import links from 'framework/links';
import { TendergyCreateQuestionnaireStepService } from 'services/tendergy/tendergy-questionnaire-step.service';
import {
  ITendergyQuestionnaire,
  ITendergyQuestionnaireStep,
} from 'store/tendegyService/tendergyService.types';

const columns = [
  {
    title: 'Order',
    dataIndex: 'order',
  },
  {
    title: 'Step type',
    dataIndex: 'stepType',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: Date) => moment(date).format('MMM Do YY'),
  },
  {
    title: 'Last update',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (date: Date) => moment(date).format('MMM Do YY'),
  },
];

interface IProps {
  questionnaire: ITendergyQuestionnaire;
}

const QuestionnaireStepsTab: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const [steps, setSteps] = useState<ITendergyQuestionnaireStep[]>();
  const { questionnaire } = props;

  const payload: any = {
    questionnaireId: questionnaire?.id,
    questionnaireVersion: questionnaire?.version,
    limit: 100,
  };

  useEffect(() => {
    TendergyCreateQuestionnaireStepService(questionnaire.id, questionnaire.version)
      .getEntries(payload)
      .then((steps: any) => setSteps(steps?.items));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (record: ITendergyQuestionnaireStep) => {
    return () => {
      history.push(`${links.tendergyQuestionnaireSteps}/${record.id}`);
    };
  };

  const tableProps: TableProps<ITendergyQuestionnaireStep> = {
    columns: columns,
    dataSource: steps
      ?.filter((item) => item.parent === null)
      .sort((a, b) => {
        return a.order - b.order;
      }),
    onRow: (record) => ({ onClick: handleRowClick(record) }),
    rowKey: 'id',
  };

  return steps ? <Table {...tableProps} /> : <Loader />;
};

export default QuestionnaireStepsTab;
