import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Tag } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IBillingChargingStation } from 'services/billing-v3/billing-service.types';
import BillingEnergyPricingService from 'services/billing-v3/billing-v3-energy-prices.service';
import { formatDefaultDate } from 'utils/date.helper';

interface Props {
  entry?: IBillingChargingStation;
}
enum EStatus {
  Active = 'active',
  Approved = 'approved',
  Declined = 'declined',
  Inactive = 'inactive',
  Pending = 'pending',
}
interface IParams {
  id: string;
}

const EnergyPricesTab: React.FC<Props> = () => {
  const columns = [
    {
      title: 'Charge/ kWh',
      dataIndex: 'chargePerUnit',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Payout / kWh',
      dataIndex: 'payoutPerUnit',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Valid from',
      dataIndex: 'validFrom',
      render: (date: string) => formatDefaultDate(date || ''),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Valid to',
      dataIndex: 'validTo',
      render: (date: string) => formatDefaultDate(date || ''),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: any) => StatusChecker(status || ''),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
  ];
  const StatusChecker = (status: EStatus) => {
    switch (status) {
      case EStatus.Active:
        return <Tag color="green">Active</Tag>;
      case EStatus.Declined:
        return <Tag color="red">Declined</Tag>;
      case EStatus.Approved:
        return <Tag color="green">Approved</Tag>;
      case EStatus.Inactive:
        return <Tag color="red">Inactive</Tag>;
      case EStatus.Pending:
        return <Tag color="yellow">Pending</Tag>;
      default:
        return <Tag color="gray">Unknown</Tag>;
    }
  };

  const { id } = useParams<IParams>();
  const payload = {
    chargingPointId: id,
  };
  return (
    <CrudListViewContainer
      entityName="Energy Prices"
      columns={columns}
      additionalPayload={payload}
      resource={BillingEnergyPricingService}
      disableSearch
    />
  );
};

export default EnergyPricesTab;
