import config from 'config';

import { ISA_SOLUTIONS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISASolution } from '../isa-service.types';

const ISASolutionsService = new EntityService<IISASolution>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_SOLUTIONS_ENDPOINT,
  createLink: links.isaSolutionsCreate,
  detailLink: links.isaSolutionsDetails,
});

ISASolutionsService.getDisplayName = (entry) => entry.title || entry.logicalName;

export default ISASolutionsService;
