import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import BillCarOwnersService from 'services/billing-v2/billing-v2-car-owners.service';

import CarOwnerForm from './components/CarOwnerForm/CarOwnerForm';

export default function CarOwnerCreatePage() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Car Owner',
          render: (entry, onSubmit) => <CarOwnerForm onSubmit={onSubmit} entry={entry} />,
        },
      ]}
      entityName="car owner"
      baseLink={links.billCarOwners}
      resource={BillCarOwnersService}
    />
  );
}
