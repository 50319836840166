import { IPagination } from 'framework/models';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

import { IOffer } from '../tendergyService.types';

export const GET = 'offers/GET';
export const SET = 'offers/SET';
export const SET_LOADING_STATUS = 'offers/SET_LOADING_STATUS';
export const RESET = 'offers/RESET';

export interface OffersState {
  entries: IOffer[];
  paginationCount: number;
  loading: boolean;
}

export interface GetOffersAction {
  type: typeof GET;
  payload: IGetMultiEntityPayload;
}

export interface SetOffersAction {
  type: typeof SET;
  payload: IPagination<IOffer>;
}

export interface SetLoadingStatusAction {
  type: typeof SET_LOADING_STATUS;
  payload: boolean;
}

export interface ResetAction {
  type: typeof RESET;
}

// @ts-ignore
export type OffersActionTypes =
  | GetOffersAction
  | SetOffersAction
  | SetLoadingStatusAction
  | ResetAction;
