import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import IntermediatesService from 'services/tendergy/tendergy-intermediates.service';
import { showErrorNotification } from 'utils/notificationUtil';

import OrganizationStatisticTable from './OrganizationStatisticTable';
import StatisticTable, { InvoicesStatistic } from './StatisticTable';

const ActivityLogsOverviewPage: React.FC = () => {
  const [invoicesStatistic, setInvoicesStatistic] = useState<InvoicesStatistic | null>(null);

  useEffect(() => {
    getInvoicesStatistic();
  }, []);

  const getInvoicesStatistic = async () => {
    try {
      const response = await IntermediatesService.getInvoicesStatistic();
      setInvoicesStatistic(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <StatisticTable statistic={invoicesStatistic} />
      </Col>
      <Col span={24}>
        <OrganizationStatisticTable />
      </Col>
    </Row>
  );
};

export default ActivityLogsOverviewPage;
