import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import { ITendergyClientRequestPool } from 'store/tendegyService/tendergyService.types';

export interface IClientRequestsFilters {
  query: null | string;
  poolId: null | string;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const SurveysFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IClientRequestsFilters>({
    query: null,
    poolId: null,
  });

  useEffect(() => {
    onFilter && onFilter(value);
  }, [value]); // eslint-disable-line

  const queryInputProps = {
    placeholder: 'Search by id...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };

  const poolEntitySelectorProps = {
    name: 'pools',
    allowClear: true,
    value: value.poolId,
    style: { width: '100%' },
    entityService: ClientRequestPoolService,
    onSelect: (pool: ITendergyClientRequestPool) => {
      setValue({ ...value, poolId: pool?.id || null });
    },
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col flex="20%">
        <Input {...queryInputProps} />
      </Col>
      <Col flex="20%">
        <EntitySelector {...poolEntitySelectorProps} />
      </Col>
    </Row>
  );
};

export default SurveysFilters;
