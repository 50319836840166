import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import {
  InviteLinkType,
  ITendergyClientRequestPool,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';

export interface IClientRequestsFilters {
  type: null | InviteLinkType;
  pool: null | ITendergyClientRequestPool;
  organization: null | ITendergyOrganization;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const InvitationLinksFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IClientRequestsFilters>({
    type: null,
    pool: null,
    organization: null,
  });

  useEffect(() => {
    onFilter &&
      onFilter({
        type: value.type,
        poolName: value.pool?.name || null,
        organizationName: value.organization?.name || null,
      });
  }, [value]); // eslint-disable-line

  const typeSelectProps = {
    placeholder: 'Select Type',
    onChange: (type: InviteLinkType) => {
      setValue({ ...value, type });
    },
    style: { width: '100%' },
    allowClear: true,
    options: [
      { value: InviteLinkType.installer, label: 'Installer' },
      { value: InviteLinkType.installerPool, label: 'InstallerPool' },
      { value: InviteLinkType.user, label: 'EndUser' },
    ],
    value: value.type as InviteLinkType,
  };

  const organizationEntitySelectorProps = {
    name: 'organization',
    placeholder: 'Select Organization',
    allowClear: true,
    value: value.organization?.id,
    entityService: TendergyOrganizationService,
    onSelect: (organization: ITendergyOrganization) => {
      setValue({ ...value, organization });
    },
    style: { width: '100%' },
  };

  const poolEntitySelectorProps = {
    name: 'pool',
    placeholder: 'Select Pool',
    allowClear: true,
    value: value.pool?.id,
    entityService: ClientRequestPoolService,
    onSelect: (pool: ITendergyClientRequestPool) => {
      setValue({ ...value, pool });
    },
    style: { width: '100%' },
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col span={8}>
        <Select {...typeSelectProps} />
      </Col>
      <Col span={8}>
        <EntitySelector {...organizationEntitySelectorProps} />
      </Col>
      <Col span={8}>
        <EntitySelector {...poolEntitySelectorProps} />
      </Col>
    </Row>
  );
};

export default InvitationLinksFilters;
