import * as React from 'react';
import { Button, Col, Row, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

import FileUploader from 'components/ui/FileUploader';
import TendergyRequestService from 'services/tendergy/tendergy-request.service';
import { IGetFileLinkPayload } from 'store/storeInterfaces';
import { MediaCategoryType } from 'store/tendegyService/tendergyService.types';
import { formatDateFromIso } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

interface IProps {
  documents?: any;
  deleteFile?: (fileName: string) => void;
  uploadFile?: (document: File, category: MediaCategoryType) => Promise<void>;
}

const DocumentsTab: React.FC<IProps> = (props: IProps) => {
  const { documents, deleteFile, uploadFile } = props;

  const onFileClick = async (name: string) => {
    const payload: IGetFileLinkPayload = {
      fileName: name,
    };
    let response;

    try {
      response = await TendergyRequestService.getFileLink(payload);
      window.open(response.link, '_blank');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const columns = [
    {
      title: 'Original Name',
      dataIndex: 'originalName',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Created At',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      dataIndex: 'createdAt',
      render: (date: Date) => formatDateFromIso(date),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (entry: any) => (
        <Row gutter={[16, 0]}>
          <Col>
            <Button onClick={() => onFileClick(entry?.name)}>Open</Button>
          </Col>
          <Col>
            <Button onClick={() => !!deleteFile && deleteFile(entry?.name)}>Delete</Button>
          </Col>
        </Row>
      ),
    },
  ];

  const fileUploaderProps = {
    categoriesList: Object.values(MediaCategoryType).filter((value) => value.includes('request')),
    onFileUpload: uploadFile,
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <FileUploader {...fileUploaderProps} />
      </Col>
      <Col span={24}>
        <Table columns={columns} dataSource={documents} />
      </Col>
    </Row>
  );
};

export default DocumentsTab;
