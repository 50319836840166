import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import debounce from 'lodash/debounce';

import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import {
  IPoolTenderOrganization,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';

const { Option } = Select;

interface IEntitySelectorProps {
  current?: ITendergyOrganization[] | IPoolTenderOrganization[];
  onSelect?: (entryId: any) => void; // emits the fund entity as full object!
  defaultPayload?: IGetMultiEntityPayload; // if we need sorting/filtering/etc. for search request
  name: string; // TODO: Change select to formik-antd and use name
}

const PoolTenderOrganizationsSelector: React.FC<IEntitySelectorProps> = (
  props: IEntitySelectorProps,
) => {
  const { onSelect, current } = props;
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState<{ id: string; label?: string; entry: any }[]>(
    [],
  );
  const [currentEntity] = useState<any>(current);
  const [isEditMode, setIsEditMode] = useState<boolean>(!current);

  const history = useHistory();

  const searchEntities = async (searchQuery: string) => {
    const resultsLimit = 50;
    const entities = await TendergyOrganizationService.search(
      searchQuery,
      resultsLimit,
      props.defaultPayload,
    );
    const items: any[] = [...entities?.items];
    if (Array.isArray(currentEntity)) {
      currentEntity.forEach((entity) => {
        if (entity.organization?.id) {
          !items.find((item) => item.id === entity.organization?.id) &&
            items.push(entity.organization);
        } else {
          !items.find((item) => item.id === entity.id) && items.push(entity);
        }
      });
    }
    const results = items?.map((entry) => {
      const label = entry.name;
      return { label, id: entry.id, entry };
    });
    setSearchResults(results);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce((q) => searchEntities(q), 500),
    [],
  );

  useEffect(() => {
    if (!isEditMode) {
      return;
    }
    delayedSearch(query);
  }, [query, isEditMode, delayedSearch]);

  const onSelectEvent = (selected: string | string[], options: any | any[]) => {
    if (!onSelect) {
      return;
    }
    if (Array.isArray(options)) {
      onSelect(options.map((option) => option?.item?.entry));
      return;
    }
    const foundEntity = searchResults.find((e) => e.entry.id === selected);
    onSelect(foundEntity?.entry);
  };

  // eslint-disable-next-line consistent-return
  const getDefaultValue = () => {
    if (!currentEntity) {
      return undefined;
    }
    if (Array.isArray(currentEntity)) {
      return currentEntity.map((entity) => entity && entity.organization.id);
    }
  };

  const renderAutocomplete = () => (
    <Select
      defaultValue={getDefaultValue()}
      mode="multiple"
      showSearch
      style={{ width: 200 }}
      placeholder="Select Organization"
      optionFilterProp="children"
      onChange={(value, options) => onSelectEvent(value as any, options as any[])}
      onSearch={(searchTerm: string) => setQuery(searchTerm)}
    >
      {searchResults &&
        searchResults?.map((item) => (
          <Option key={item.id} value={item.id} item={item}>
            {item.label}
          </Option>
        ))}
    </Select>
  );

  const displayName = () => {
    if (!currentEntity) {
      return null;
    }

    return currentEntity
      .map((entity: any) => (entity.organization ? entity.organization.name : entity.name))
      .join(', ');
  };

  const detailLink = TendergyOrganizationService.getDetailLink(currentEntity);

  return (
    <>
      {isEditMode ? renderAutocomplete() : null}
      <div>
        {displayName()}{' '}
        {!isEditMode ? (
          <Button onClick={() => setIsEditMode(true)}>
            <EditOutlined />
          </Button>
        ) : null}
        {detailLink ? (
          <Button onClick={() => history.push(detailLink)}>
            <EyeOutlined />
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default PoolTenderOrganizationsSelector;
