import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useLocation } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { stateToHTML } from 'draft-js-export-html';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import {
  CustomDocumentsCategoryType,
  ICustomDocument,
  ITendergyClientRequestPool,
} from 'store/tendegyService/tendergyService.types';

import EntitySelector from '../../../../../components/forms/organization/EntitySelector';
import ClientRequestPoolService from '../../../../../services/tendergy/client-request-pool.service';
import { CustomDocumentValidation } from '../../custom-document.validation';

import styles from '../../../../shop/product-options/details/tabs/ProductAttributeOptionStyles.module.sass';

interface Props {
  entry: ICustomDocument;
  onSubmitStep: (entry: ICustomDocument) => void;
}

const listOptions = ['unordered', 'ordered'];
const inlineOptions = ['bold', 'italic', 'underline'];
const toolbarOptions = ['inline', 'list', 'remove', 'history', 'emoji'];

const CustomDocumentBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(CustomDocumentValidation);
  const poolId = new URLSearchParams(useLocation().search).get('poolId');

  return (
    <div>
      <Formik
        initialValues={
          (poolId
            ? { ...entry, scopeId: poolId, scopeType: 'client-request-pools' }
            : entry) as ICustomDocument
        }
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, isValid, dirty }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="scopeType" label="Scope Type" required>
              <Select
                name="scopeType"
                // value={poolId ? 'client-request-pools' : null}
                disabled={!!poolId}
              >
                <Select.Option value={'client-request-pools'}>Client Request Pools</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="scopeId" label="Scope Id" required>
              <EntitySelector
                name="scopeId"
                defaultPayload={{
                  relations: ['intermediateOrganization'],
                }}
                entityService={ClientRequestPoolService}
                current={poolId ? poolId : entry.scopeId}
                onSelect={(pool: ITendergyClientRequestPool) => setFieldValue('scopeId', pool.id)}
              />
            </Form.Item>

            <Form.Item name="category" label="Category" required>
              <Select name="category">
                <Select.Option value={CustomDocumentsCategoryType.InstallerLandingPage}>
                  Installer Landing Page
                </Select.Option>
                <Select.Option value={CustomDocumentsCategoryType.UserCostCalculatorDescription}>
                  User Cost Calculator Description
                </Select.Option>
                <Select.Option value={CustomDocumentsCategoryType.UserFAQPage}>
                  FAQ Page
                </Select.Option>
                <Select.Option value={CustomDocumentsCategoryType.SurveyLandingPage}>
                  Survey Landing Page
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="text" label="Text">
              <Editor
                toolbar={{
                  options: toolbarOptions,
                  inline: {
                    options: inlineOptions,
                  },
                  list: {
                    options: listOptions,
                  },
                }}
                wrapperClassName={styles.editorWrapper}
                editorClassName={styles.textAreaWrapper}
                onEditorStateChange={(editorData) =>
                  setFieldValue('text', stateToHTML(editorData.getCurrentContent()))
                }
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting || !isValid || !dirty}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomDocumentBaseStep;
