import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { IISAAnswer } from 'services/isa-service/isa-service.types';

interface IISAAnswersTabProps {
  answers: IISAAnswer[];
}

export default function ISAAnswersTab(props: IISAAnswersTabProps) {
  const { answers } = props;
  const history = useHistory();

  function handleRowClick(value: IISAAnswer) {
    return () => history.push(generatePath(links.isaAnswersDetails, { id: value.id }));
  }

  return (
    <Table
      columns={[
        { title: 'Answer Title', dataIndex: 'value' },
        { title: 'Logical Name', dataIndex: 'logicalName' },
      ]}
      rowKey="id"
      dataSource={answers}
      onRow={(value) => ({ onClick: handleRowClick(value) })}
    />
  );
}
