import React, { useState } from 'react';
import { Layout, Steps } from 'antd';

import { Loader } from 'components/ui';
import BillingEnergyPricingService from 'services/billing/billing-energy-prices.service';
import {
  IBillingCarOwner,
  IBillingChargingStation,
  IBillingEnergyPrice,
  IBillingRFIDCard,
} from 'services/billing/billing-service.types';
import ChargingStationService from 'services/billing/charging-station.service';
import { CurrencyUtils } from 'utils/currencyUtils';

import CreateBillingFlowSummaryStep from './steps/CreateBillingFlowSummaryStep';
import CreateOrSelectCarOwnerStep from './steps/CreateOrSelectCarOwner';
import CreateOrSelectChargingStationStep from './steps/SelectOrCreateChargingStationStep';
import CreateOrSelectRFIDStep from './steps/SelectOrCreateRFIDCardStep';
import SetEnergyPriceStep from './steps/SetEnergyPriceStep';

import styles from './billing-create-flow.module.sass';

const { Sider, Content } = Layout;

const BillingCreateFlowPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [carOwner, setCarOwner] = useState<IBillingCarOwner>();
  const [rfidCard, setRFIDCard] = useState<IBillingRFIDCard>();
  const [chargingStation, setChargingStation] = useState<IBillingChargingStation>();
  const [energyPrices, setEnergyPrices] = useState<IBillingEnergyPrice[]>();
  const [chargingStations, setChargingStations] = useState<IBillingChargingStation[]>();
  const [energyPrice, setEnergyPrice] = useState<IBillingEnergyPrice>();

  const saveAndFinalize = () => {
    setCurrentStep(4); // success
  };

  // we use this emthod to set the carOwner iwth Energy Prices,
  // this is helpful for the energy prices page, to directly show what data already exists.

  // eslint-disable-next-line
  const carOwnerSetter = async (carOwner: IBillingCarOwner) => {
    // eslint-disable-next-line
    const energyPrices = await BillingEnergyPricingService.getEntries({
      filters: { carOwner: carOwner.id },
      relations: ['carOwner'],
    });
    // eslint-disable-next-line
    const chargingStations = await ChargingStationService.getEntries({
      filters: { carOwner: carOwner.id },
      relations: ['carOwner'],
    });

    setChargingStations(chargingStations.items);
    setEnergyPrices(energyPrices.items);
    setCarOwner(carOwner);
  };

  const steps = [
    {
      name: 'Car Owner',
      description: carOwner ? `${carOwner.email} selected` : 'Select or create a carOwner',
      render: () => (
        <CreateOrSelectCarOwnerStep
          /* eslint-disable-next-line */
          onSelect={(carOwner: IBillingCarOwner) => {
            carOwnerSetter(carOwner);
            setCurrentStep(1);
          }}
        />
      ),
    },
    {
      name: 'RFID Card',
      description: rfidCard ? `${rfidCard.identifier} selected` : 'Select or create a RFID card',
      render: () => (
        <CreateOrSelectRFIDStep
          carOwner={carOwner as IBillingCarOwner}
          onSelect={(card: IBillingRFIDCard) => {
            setRFIDCard(card);
            setCurrentStep(2);
          }}
        />
      ),
    },
    {
      name: 'Charging Station',
      description: chargingStation
        ? `${chargingStation.evse} selected`
        : 'Select or create a Charging Station',
      render: () => (
        <CreateOrSelectChargingStationStep
          carOwner={carOwner as IBillingCarOwner}
          chargingStations={chargingStations}
          onSelect={(card: IBillingChargingStation) => {
            setChargingStation(card);
            setCurrentStep(3);
          }}
        />
      ),
    },
    {
      name: 'Energy Prices',
      description: energyPrice
        ? `${CurrencyUtils.formatCurrency(energyPrice.chargePerUnit)} set`
        : 'Select or create a Energyprice',
      render: () =>
        chargingStation ? (
          <SetEnergyPriceStep
            energyPrices={energyPrices}
            chargingStation={chargingStation}
            /* eslint-disable-next-line */
            onSelect={(energyPrice: IBillingEnergyPrice) => {
              setEnergyPrice(energyPrice);
              saveAndFinalize();
            }}
          />
        ) : (
          ''
        ),
    },
    {
      name: 'Done',
      description: '',
      render: () => (
        <CreateBillingFlowSummaryStep
          carOwner={carOwner as IBillingCarOwner}
          energyPrice={energyPrice as IBillingEnergyPrice}
          chargingStation={chargingStation as IBillingChargingStation}
          rfidCard={rfidCard as IBillingRFIDCard}
        />
      ),
    },
  ];

  const renderStep = () => {
    if (!steps[currentStep]) {
      return <div>Invalid step</div>;
    }
    if (currentStep > 0 && !carOwner) {
      return <Loader />;
    }
    return steps[currentStep].render();
  };

  const renderSidebar = () => (
    <Steps current={currentStep} direction="vertical">
      {steps.map((step) => (
        <Steps.Step key={step.name} title={step.name} description={step.description} />
      ))}
    </Steps>
  );

  return (
    <Layout className="site-layout-background">
      <Sider width={400} theme="light">
        <Content className={styles.sidebar}>{renderSidebar()}</Content>
      </Sider>
      <Content className={styles.contentWrapper}>{renderStep()}</Content>
    </Layout>
  );
};

export default BillingCreateFlowPage;
