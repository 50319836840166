const links = {
  login: '/login',
  forbidden: '/forbidden',
  home: '/home',
  users: '/auth/users',
  user: '/auth/users/:id/:page?',
  organizations: '/auth/organizations',
  organization: '/auth/organizations/:id/:page?',
  authRoles: '/auth/roles',
  authRole: '/auth/roles/:id/:page?',
  authApiClients: '/auth/api-clients',
  authApiClient: '/auth/api-clients/:id/:page?',
  createAuthApiClient: '/auth/api-clients-create',
  createOrganization: '/auth/organizations-create',
  tendergyUsers: '/tendergy/users',
  tendergyOrganizations: '/tendergy/organizations',
  requests: '/tendergy/requests',
  request: '/tendergy/requests/:id/:page?',
  offers: '/tendergy/offers',
  offer: '/tendergy/offers/:id/:page?',
  products: '/tendergy/products',
  product: '/tendergy/products/:id/:page?',
  createProduct: '/tendergy/products-create',

  productModels: '/tendergy/product-models',
  productModel: '/tendergy/product-models/:id/:page?',
  createProductModel: '/tendergy/product-models-create',

  productVendors: '/tendergy/product-vendors',
  productVendor: '/tendergy/product-vendor/:id/:page?',
  createProductVendor: '/tendergy/product-vendors-create',

  invitationLinks: '/tendergy/invitation-links',
  invitationLink: '/tendergy/invitation-links/:id/:page?',
  createInviteLink: '/tendergy/invitation-links-create', // -create is convention!

  clientRequestPools: '/tendergy/client-request-pools',
  clientRequestPool: '/tendergy/client-request-pools/:id/:page?',
  createClientRequestPool: '/tendergy/client-request-pools-create', // -create is convention!

  intermediates: '/tendergy/intermediates',
  intermediate: '/tendergy/intermediates/:id/:page?',
  createIntermediate: '/tendergy/intermediates-create',

  customDocuments: '/tendergy/custom-documents',
  customDocument: '/tendergy/custom-documents/:id/:page?',
  createCustomDocument: '/tendergy/custom-documents-create', // -create is convention!

  comments: '/tendergy/comments',
  comment: '/tendergy/comments/:id/:page?',
  createComment: '/tendergy/comments-create',

  appointments: '/tendergy/appointments',
  appointment: '/tendergy/appointments/:id/:page?',
  createAppointment: '/tendergy/appointments-create',

  questionnaireConfigs: '/tendergy/questionnaireConfigs',
  questionnaireConfig: '/tendergy/questionnaireConfigs/:id/:page?',
  createQuestionnaireConfig: '/tendergy/questionnaireConfigs-create',

  tendergyQuestionnaires: '/tendergy/questionnaires',
  tendergyQuestionnaire: '/tendergy/questionnaires/:id/:version/:page?',
  tendergyCreateQuestionnaire: '/tendergy/questionnaires-create',

  tendergyQuestionnaireSteps: '/tendergy/questionnaire-steps',
  tendergyQuestionnaireStep: '/tendergy/questionnaire-steps/:id/:page?',
  tendergyCreateQuestionnaireStep: '/tendergy/questionnaire-steps-create/:id/:version',
  tendergyCreateQuestionnaireStepBaseURL: '/tendergy/questionnaire-steps-create',
  tendergyimportQuestionnaire: '/tendergy/import-questionnaire/:id/:version',
  tendergyimportQuestionnaireBaseURL: '/tendergy/import-questionnaire',

  activityLogs: '/tendergy/activityLogs',

  archiveRequests: '/tendergy/archive-requests',

  feedback: '/tendergy/feedback',

  tendergyBilling: '/tendergy/billing',
  tendergyHardware: '/tendergy/hardware',

  leads: '/tendergy/leads',
  lead: '/tendergy/leads/:id/:page?',
  createLead: '/tendergy/lead-create',

  countries: '/tendergy/coutries',
  country: '/tendergy/country/:id/:page?',
  createCountry: '/tendergy/country-create',

  surveys: '/tendergy/surveys',
  survey: '/tendergy/surveys/:id',

  helpPage: '/help',

  // please make sure you name them after {service}{entity}{s} (plural = listview)
  billingChargingStations: '/billing/charging-stations',
  billingChargingStation: '/billing/charging-stations/:id/:page?',
  billingCreateChargingStation: '/billing/charging-stations-create', // -create is convention!

  // please make sure you name them after {service}{entity}{s} (plural = listview)
  billingOrganizations: '/billing/organizations',
  billingOrganization: '/billing/organizations/:id/:page?',
  billingCreateOrganization: '/billing/organizations-create', // -create is convention!

  // please make sure you name them after {service}{entity}{s} (plural = listview)
  billingRFIDCards: '/billing/rfid-cards',
  billingRFIDCard: '/billing/rfid-cards/:id/:page?',
  billingCreateRFIDCards: '/billing/rfid-cards-create', // -create is convention!
  billingCreateCarOwnerFlow: '/billing/create-car-owner-flow',
  // please make sure you name them after {service}{entity}{s} (plural = listview)
  billingImports: '/billing/imports',
  billingImport: '/billing/imports/:id/:page?',
  billingCreateImport: '/billing/imports-create', // -create is convention!
  billingInvoices: '/billing/invoices',
  billingInvoice: '/billing/invoice/:id',
  billingDocuments: '/billing/documents',

  // please make sure you name them after {service}{entity}{s} (plural = listview)
  billingEnergyPrices: '/billing/energy-prices',
  billingEnergyPrice: '/billing/energy-prices/:id/:page?',
  billingCreateEnergyPrice: '/billing/energy-prices-create', // -create is convention!

  // please make sure you name them after {service}{entity}{s} (plural = listview)
  billingCarOwners: '/billing/car-owners',
  billingCarOwner: '/billing/car-owners/:id/:page?',
  billingCreateCarOwners: '/billing/car-owners-create', // -create is convention!

  billOrganizations: '/billing-v2/organizations',
  billOrganization: '/billing-v2/organizations/:id/:page?',
  billCreateOrganization: '/billing-v2/organizations-create',

  billCarOwners: '/billing-v2/car-owners',
  billCarOwner: '/billing-v2/car-owners/:id/:page?',
  billCreateCarOwner: '/billing-v2/car-owners-create',

  fleetAppUsersList: '/billing-v2/users',
  fleetAppCreateUser: '/billing-v2/user-create',

  billChargingStations: '/billing-v2/charging-stations',
  billChargingStation: '/billing-v2/charging-stations/:id/:page?',
  billCreateChargingStation: '/billing-v2/charging-stations-create',

  billRfidCards: '/billing-v2/rfid-cards',
  billRfidCard: '/billing-v2/rfid-cards/:id/:page?',
  billCreateRfidCard: '/billing-v2/rfid-cards-create',

  billAttachRfidCardCarOwner: '/billing-v2/rfid-card-car-owner-create',

  billImports: '/billing-v2/imports',
  billImport: '/billing-v2/imports/:id/:page?',
  billCreateImport: '/billing-v2/imports-create',

  billInvoices: '/billing-v2/invoices',
  billInvoice: '/billing-v2/invoices/:id',
  billCreateInvoice: '/billing-v2/invoices-create',

  billV3ChargingStations: '/billing-v3/charging-stations',
  billV3ChargingStation: '/billing-v3/charging-stations/:id/:page?',
  billV3EnergyPrice: '/billing-v3/energy-price/:id/:page?',
  billV3EnergyPrices: '/billing-v3/energy-price',
  billV3CEnergyPrice: '/billing-v3/energy-price-create/new/:id',
  billV3Invoices: '/billing-v3/invoices',
  billV3Invoice: '/billing-v3/invoices/:id',
  billV3CreateInvoice: '/billing-v3/invoices-create',
  billV3AccessManagement: '/billing-v3/access-management',
  billV3CreateChargingStation: '/billing-v3/charging-stations-create',
  billV3Organizations: '/billing-v3/organizations',
  billV3Organization: '/billing-v3/organizations/:id/:page?',
  billV3CreateOrganization: '/billing-v3/organizations-create',

  billV3CreateCarOwner: '/billing-v3/car-owners-create',
  billV3CarOwners: '/billing-v3/car-owners',
  billV3CarOwner: '/billing-v3/car-owners/:id/:page?',

  shopStores: '/shop/stores',
  shopStore: '/shop/stores/:id/:page?',
  shopCreateStore: '/shop/stores-create', // -create is convention!

  shopCategories: '/shop/categories',
  shopCategory: '/shop/categories/:id/:page?',
  shopCreateCategory: '/shop/categories-create', // -create is convention!

  shopProducts: '/shop/products',
  shopProduct: '/shop/products/:id/:page?',
  shopCreateProduct: '/shop/products-create',

  shopProductAttributeOptions: '/shop/product-attribute-options',
  shopProductAttributeOption: '/shop/product-attribute-options/:id/:page?',
  shopCreateProductAttributeOption: '/shop/product-attribute-options-create',

  shopProductAttributes: '/shop/product-attributes',
  shopProductAttribute: '/shop/product-attributes/:id/:page?',
  shopCreateProductAttribute: '/shop/product-attributes-create',

  shopCarModels: '/shop/car-models',
  shopCarModel: '/shop/car-models/:id/:page?',
  shopCreateCarModel: '/shop/car-models-create',

  shopStoreEmails: '/shop/store-emails',
  shopStoreEmail: '/shop/store-emails/:id/:page?',
  shopCreateStoreEmail: '/shop/store-emails-create',

  shopLandings: '/shop/landings',
  shopLanding: '/shop/landings/:id/:page?',
  shopCreateLanding: '/shop/landing-create',

  shopUsers: '/shop/users',
  shopUser: '/shop/users/:id/:page?',

  shopOrders: '/shop/orders',
  shopOrder: '/shop/orders/:id/:page?',

  shopOrderItem: '/shop/order-items/:id/:page?',

  /* Service App (ISA) */

  isaWallboxesCreate: '/service/wallboxes/create',
  isaWallboxesDetails: '/service/wallboxes/:id/:page?',
  isaWallboxesOverview: '/service/wallboxes',

  isaCarBrandsCreate: '/service/car-brands/create',
  isaCarBrandsDetails: '/service/car-brands/:id/:page?',
  isaCarBrandsOverview: '/service/car-brands',

  isaCarModelsCreate: '/service/car-models/create',
  isaCarModelsDetails: '/service/car-models/:id/:page?',
  isaCarModelsOverview: '/service/car-models',

  isaProblemCategoriesCreate: '/service/problem-categories/create',
  isaProblemCategoriesDetails: '/service/problem-categories/:id/:page?',
  isaProblemCategoriesOverview: '/service/problem-categories',

  isaProblemsCreate: '/service/problems/create',
  isaProblemsDetails: '/service/problems/:id/:page?',
  isaProblemsOverview: '/service/problems',

  isaSolutionsCreate: '/service/solutions/create',
  isaSolutionsDetails: '/service/solutions/:id/:page?',
  isaSolutionsOverview: '/service/solutions',

  isaSolutionStepsCreate: '/service/solution-steps/create',
  isaSolutionStepsDetails: '/service/solution-steps/:id/:page?',
  isaSolutionStepsOverview: '/service/solution-steps',

  isaQuestionsCreate: '/service/questions/create',
  isaQuestionsDetails: '/service/questions/:id/:page?',
  isaQuestionsOverview: '/service/questions',

  isaAnswersCreate: '/service/answers/create',
  isaAnswersDetails: '/service/answers/:id/:page?',
  isaAnswersOverview: '/service/answers',

  isaDiagnosticsCreate: '/service/diagnostics/create',
  isaDiagnosticsDetails: '/service/diagnostics/:id/:page?',
  isaDiagnosticsOverview: '/service/diagnostics',

  isaDiagnosticStepsCreate: '/service/diagnostic-steps/create',
  isaDiagnosticStepsDetails: '/service/diagnostic-steps/:id/:page?',
  isaDiagnosticStepsOverview: '/service/diagnostic-steps',

  isaMappingCreate: '/service/mapping/create',
  isaMappingDetails: '/service/mapping/:id/:page?',
  isaMappingOverview: '/service/mapping',

  isaServiceTicketsDetails: '/service/service-tickets/:id/:page?',
  isaServiceTicketsOverview: '/service/service-tickets',

  isaFeedbacksDetails: '/service/feedbacks/:id/:page?',
  isaFeedbacksOverview: '/service/feedbacks',

  isaUsersCreate: '/service/users/create',
  isaUsersDetails: '/service/users/:id/:page?',
  isaUsersOverview: '/service/users',

  isaAddressCreate: '/service/addresses/create',
  isaAddressDetails: '/service/addresses/:id/:page?',
  isaAddressOverview: '/service/addresses',

  // email links
  emailLayouts: '/email/layouts',
  emailLayout: '/email/layouts/:id/:page?',
  emailCreateLayout: '/email/layouts-create',

  emailTemplates: '/email/templates',
  emailTemplate: '/email/templates/:id/:page?',
  emailCreateTemplate: '/email/templates-create',

  emailActivityLogs: '/email/activityLogs',
  emailActivityLog: '/email/activityLogs/:id/:page?',
  eConsultantLeads: '/eConsultant/leads',
  eConsultantLead: '/eConsultant/lead/:id/:page?',
  eCCountries: '/eConsultant/countries',
  eCCountriesDetail: '/eConsultant/country/:id/:page?',
  eConsultantLeadAll: '/eConsultant/leads/all/:id?',
  eConsultantFleetTypes: '/eConsultant/fleet-types',
  eConsultantFleetTypesDetail: '/eConsultant/fleet-types/:id/:page?',
  eConsultantCommercialFleetTypes: '/eConsultant/commercial-fleet-types',
  eConsultantCommercialFleetTypesDetail: '/eConsultant/commercial-fleet-types/:id/:page?',
  eConsultantGlobalLeadParametersCreate: '/eConsultant/leadParameter/global/create',
  eConsultantLocalLeadParameters: '/eConsultant/leadParameter/local',
  eConsultantLocalLeadParameter: '/eConsultant/leadParameter/local/:id/:page?',
  eConsultantLocalLeadParametersCreate: '/eConsultant/leadParameter/local/create',

  // integration
  webhooksOverview: '/integration/webhooks',
  webhookDetails: '/integration/webhooks/:id/:page?',
  webhookCreate: '/integration/webhooks/create',

  // Externals
  eConsultant_development: 'https://i2g-digital-consultant-fe.development.tendergy.com/',
  eConsultant_staging: 'https://i2g-digital-consultant-fe.staging.tendergy.com/',
  eConsultant_production: 'https://econsultant.inno2fleet.com/',
};

export default links;
