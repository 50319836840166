import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillingImportsService from 'services/billing/billing-imports.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<any> = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const BillingImportsPage: React.FC = () => {
  const defaultPayload: IGetMultiEntityPayload = {
    // sortField: 'createdAt',
    sortDirection: -1,
  };

  return (
    <CrudListViewContainer
      defaultPayload={defaultPayload}
      entityName="Imports"
      columns={columns}
      resource={BillingImportsService}
    />
  );
};
export default BillingImportsPage;
