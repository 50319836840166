import moment from 'moment';

import { BASE_DATE_FORMAT } from 'framework/constants/dateFormats';

export const isDateValid = (date: any) => moment(date, moment.ISO_8601, true).isValid();

export const formatDefaultDate = (date: any, outputDateFormat: string = BASE_DATE_FORMAT) => {
  if (!date) {
    return 'n/a';
  }
  return moment(date).format(outputDateFormat);
};

// @ts-ignore
// eslint-disable-next-line max-len
export const formatDateFromIso = (isoDate: Date, outputDateFormat: string = BASE_DATE_FORMAT) =>
  moment(isoDate).format(outputDateFormat);
