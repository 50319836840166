import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import UserAPI from 'services/tendergy/tendergy-user.service';

import UserAuthAPI from '../../../services/auth/user-auth.service';
import * as actions from './users.actions';
import { GET, GET_AUTH_USERS, GetAuthUsersAction } from './users.types';

// import { usersSelector } from './users.selectors';

export function* get({ payload }: GetAuthUsersAction) {
  // const { filters, pagination } = yield select(usersSelector);

  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(UserAPI.getEntries, payload);

    yield put(actions.set(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getAuthUsers({ payload }: GetAuthUsersAction) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(UserAuthAPI.getEntries, payload);
    yield put(actions.setUsers(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([takeLatest(GET, get), takeLatest(GET_AUTH_USERS, getAuthUsers)]);
}
