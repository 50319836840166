import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button } from 'antd';

import links from 'framework/links';

import styles from './StoreActionsComponentStyles.module.sass';

interface StoreActionsComponentProps {
  currentTab: string;
}

const StoreActionsComponent: React.FC<StoreActionsComponentProps> = (
  props: StoreActionsComponentProps,
) => {
  const { currentTab } = props;
  const history = useHistory();

  const onAddLanding = () => {
    history.push(generatePath(links.shopCreateLanding));
  };

  const addLandingButton = () => {
    if (currentTab === 'landings') {
      return (
        <Button
          className={styles.button}
          shape="round"
          type="primary"
          size="large"
          onClick={onAddLanding}
        >
          Add new landing
        </Button>
      );
    }
  };

  return <div className={styles.actionsWrapper}>{addLandingButton()}</div>;
};
export default StoreActionsComponent;
