import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Empty, Row, Tabs, Typography } from 'antd';

import ClientRequestAddress from 'components/containers/clientRequestContainer/componetns/ClientRequestAdressComponent';
import ClientRequestAnswers from 'components/containers/clientRequestContainer/componetns/ClientRequestAnswersComponent';
import ClientRequestOwnerComponent from 'components/containers/clientRequestContainer/componetns/ClientRequestOwnerComponent';
import TendergyRequestService from 'services/tendergy/tendergy-request.service';
import {
  IClientRequestDocument,
  IGetFileLinkPayload,
  IGetSingleEntityPayload,
} from 'store/storeInterfaces';
import { IClientRequest } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from './OfferClientRequestTabStyles.module.sass';

interface IProps {
  id?: string;
}

interface IContainerState {
  loading: boolean;
  clientRequest?: IClientRequest;
}

interface IState {
  documents: IClientRequestDocument[] | null;
  loading: boolean;
}

const OfferClientRequestTab: React.FC<IProps> = (props: IProps) => {
  const [state, setState] = useState<IState>({ documents: null, loading: false });
  const [clientRequestData, setData] = useState<IContainerState>({ loading: true });
  const { clientRequest, loading } = clientRequestData;
  const { id } = props;

  const params: IGetSingleEntityPayload = {
    id: id || '',
    relations: ['answers', 'owner'],
  };

  const getDocuments = async () => {
    const payload: IGetSingleEntityPayload = {
      id: id || '',
    };

    setState({ ...state, loading: true });
    let response;
    try {
      response = await TendergyRequestService.getFiles(payload);
      // @ts-ignore
      setState({ ...state, documents: response, loading: false });
    } catch (e) {
      showErrorNotification(e);
      setState({ ...state, loading: false });
    }
  };

  const onFileClick = async (fileName: string) => {
    const payload: IGetFileLinkPayload = {
      fileName,
    };
    let response;

    try {
      response = await TendergyRequestService.getFileLink(payload);
      window.open(response.link, '_blank');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    TendergyRequestService.getById(params).then((cr) =>
      setData({ clientRequest: cr, loading: false }),
    );

    getDocuments();
    // eslint-disable-next-line
  }, [id]);

  if (!id || !clientRequest || loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Typography.Title level={4}> Client Request Data </Typography.Title>

      <Row className={styles.clientCardWrapper}>
        <Col span={22}>
          <Card>
            <ClientRequestOwnerComponent owner={clientRequest.owner} />
            <ClientRequestAddress clientRequest={clientRequest} />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={22}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Answers" key="1">
              {clientRequest ? (
                <ClientRequestAnswers
                  clientRequest={clientRequest}
                  answers={clientRequest.answers}
                />
              ) : (
                'n/a'
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Document Uploads" key="2">
              {!state.documents && <Empty description="No document uploads found" />}

              {state?.documents?.map((item) => (
                <Button key={item.id} onClick={() => onFileClick(item.name)}>
                  {item.originalName}
                </Button>
              ))}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default OfferClientRequestTab;
