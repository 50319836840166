import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISAQuestionsService } from 'services/isa-service';

import ISAQuestionsForm from './components/ISAQuestionsForm';

export default function ISAQuestionsCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Question',
          render: (_entry, onSubmit) => <ISAQuestionsForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="question"
      baseLink={links.isaQuestionsOverview}
      resource={ISAQuestionsService}
    />
  );
}
