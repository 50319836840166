import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillOrganizationService from 'services/billing-v2/billing-v2-organizations.service';
import { IBillingOrganization } from 'services/billing-v2/billing-v2-service.types';

import BillOrganizationActions from './components/BillOrganizationActions/BillOrganizationActions';
import BillOrganizationForm from './components/BillOrganizationForm/BillOrganizationForm';
import CarOwnersTab from './tabs/CarOwnersTab';
import InvoicesTab from './tabs/InvoicesTab';

interface IParams {
  id: string;
  page: string;
}

export default function BillOrganizationDetailsPage() {
  const { id, page } = useParams<IParams>();
  const payload = {
    id,
  };

  const renderActions = () => <BillOrganizationActions currentTab={page} />;

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <BillOrganizationForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'car-owners',
          displayName: 'Car Owners',
          render: (entry) => <CarOwnersTab entry={entry} />,
        },
        {
          name: 'invoices',
          displayName: 'Invoices',
          render: (entry) => <InvoicesTab entry={entry} />,
        },
      ]}
      entityName="organization"
      baseLink={links.billOrganization}
      defaultPayload={payload}
      resource={BillOrganizationService}
      renderActions={renderActions}
      renderTitle={(entry: IBillingOrganization) => entry.name}
    />
  );
}
