import links from 'framework/links';
import AccessManagementPage from 'pages/billing-v3/access-management/AccessManagement';
import CarOwnerDetailsPage from 'pages/billing-v3/car-owners/CarOwnerDetailsPage';
import BillCarOwnersOverviewPage from 'pages/billing-v3/car-owners/CarOwnersOverviewPage';
import ChargingStationCreatePage from 'pages/billing-v3/charging-stations/ChargingStationCreatePage';
import ChargingStationDetailsPage from 'pages/billing-v3/charging-stations/ChargingStationDetailsPage';
import ChargingStationsOverviewPage from 'pages/billing-v3/charging-stations/ChargingStationsOverviewPage';
import BillingEnergyPricePage from 'pages/billing-v3/energy-prices/details/BillingEnergyPricePage';
import BillingEnergyPriceCreatePage from 'pages/billing-v3/energy-prices/EnergyPriceCreatePage';
import BillingEnergyPricesPage from 'pages/billing-v3/energy-prices/overview/BillingEnergyPricesPage';
import InvoiceCreatePage from 'pages/billing-v3/invoices/InvoiceCreatePage';
import BillingInvoicePage from 'pages/billing-v3/invoices/InvoiceDetailsPage';
import BillingInvoicesPage from 'pages/billing-v3/invoices/InvoicesOverviewPage';
import BillOrganizationCreatePage from 'pages/billing-v3/organizations/BillOrganizationCreatePage';
import BillOrganizationDetailsPage from 'pages/billing-v3/organizations/BillOrganizationDetailsPage';
import BillOrganizationsOverviewPage from 'pages/billing-v3/organizations/BillOrganizationsOverviewPage';

export const BILLING_V3_ROUTES = [
  { path: links.billV3ChargingStations, component: ChargingStationsOverviewPage },
  { path: links.billV3ChargingStation, component: ChargingStationDetailsPage },
  { path: links.billV3EnergyPrice, component: BillingEnergyPricePage },
  { path: links.billV3EnergyPrices, component: BillingEnergyPricesPage },
  { path: links.billV3CEnergyPrice, component: BillingEnergyPriceCreatePage },
  { path: links.billV3Invoices, component: BillingInvoicesPage },
  { path: links.billV3Invoice, component: BillingInvoicePage },
  { path: links.billV3CreateInvoice, component: InvoiceCreatePage },
  { path: links.billV3AccessManagement, component: AccessManagementPage },
  { path: links.billV3CreateChargingStation, component: ChargingStationCreatePage },
  { path: links.billV3Organizations, component: BillOrganizationsOverviewPage },
  { path: links.billV3Organization, component: BillOrganizationDetailsPage },
  { path: links.billV3CreateOrganization, component: BillOrganizationCreatePage },
  { path: links.billV3CarOwners, component: BillCarOwnersOverviewPage },
  { path: links.billV3CarOwner, component: CarOwnerDetailsPage },
];
