import React from 'react';
import FontPicker from 'font-picker-react';
import { useField } from 'formik';

import styles from './FontPickerStyles.module.sass';

interface IFontPickerProps {
  entry: any;
  name: string;
}

const FontPickerComponent: React.FC<IFontPickerProps> = (props: IFontPickerProps) => {
  const { entry, name } = props;
  const [{ value }, , { setValue }] = useField(name);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{entry.title.toUpperCase()}</div>
      {value && (
        <FontPicker
          apiKey="AIzaSyATWTTS_wvI8EQ2rjaaHX0yUz03k4-mkIM"
          activeFontFamily={value}
          onChange={(nextFont) => setValue(nextFont.family)}
        />
      )}
      <div className={`apply-font ${styles.previewText}`}>Selected font will look like this.</div>
    </div>
  );
};

export default FontPickerComponent;
