import * as Yup from 'yup';

/*
  firstname?: string;
  lastname?: string;
  email: string;
  authId?: string;
  organizationId?: string;
  organization?: IBillingOrganization;
*/
export const CarOwnerValidation = {
  firstname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),

  lastname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),

  email: Yup.string().email('Invalid email').required('Required'),
};
