import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Pagination, Row, Table, TableProps } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import moment from 'moment';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import BillDocumentService from 'services/billing-v3/billing-v3-documents.service';
import { IBillingCarOwner, IBillingDocument } from 'services/billing-v3/billing-v3-service.types';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from './styles.module.sass';

interface Props {
  entry?: IBillingCarOwner;
}

interface Payload {
  carOwnerId?: string;
  status: string;
  limit: number;
  offset: number;
}

const ProofOfChargingsTab: React.FC<Props> = ({ entry }: Props) => {
  const history = useHistory();
  const [count, setCount] = useState<number>();
  const [entries, setEntries] = useState<IBillingDocument[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [payload, setPayload] = useState<Payload>({
    limit: 10,
    offset: 0,
    carOwnerId: entry?.id,
    status: 'done,error',
  });

  const renderActions = (row: IBillingDocument) => (
    <Row>
      <Button
        style={{ marginRight: '10px' }}
        type="primary"
        shape="round"
        onClick={() => navigateToInvoice(row)}
      >
        Open invoice
      </Button>
      <Button onClick={() => downloadProofOfCharging(row)}>Download</Button>
    </Row>
  );

  const downloadProofOfCharging = (document: IBillingDocument) => {
    BillDocumentService.downloadDocument(document);
  };

  const navigateToInvoice = (document?: IBillingDocument) => {
    history.push(createLink(links.billingInvoice, { id: document?.invoice?.id }));
  };

  const refreshEntries = async () => {
    try {
      const response = await BillDocumentService.getEntries({ ...payload });
      setEntries(response?.items);
      setCount(response?.total || response?.count);
    } catch (error) {
      showErrorNotification(error);
    }
  };

  useEffect(() => {
    setPayload({
      ...payload,
      offset: (currentPage - 1) * payload.limit!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    refreshEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.offset]);

  const columns = [
    {
      title: 'Status',
      render: (_: any, entry: IBillingDocument) => entry?.invoice?.status,
    },
    {
      title: 'Month',
      render: (_: any, entry: IBillingDocument) =>
        entry?.invoice?.month && moment.months(entry?.invoice?.month),
    },
    {
      title: 'Year',
      render: (_: any, entry: IBillingDocument) => entry?.invoice?.year,
    },
    {
      title: 'Actions',
      render: (_: any, row: IBillingDocument) => renderActions(row),
    },
  ];

  const tableProps: TableProps<IBillingDocument> = {
    scroll: { x: '100%' },
    columns: columns,
    dataSource: entries,
    pagination: false,
  };

  const paginationProps: PaginationProps = {
    total: count,
    showTotal: (total) => `Total ${total} items`,
    showSizeChanger: false,
    current: currentPage,
    onChange: setCurrentPage,
    showQuickJumper: true,
    defaultPageSize: payload.limit,
  };

  return (
    <>
      <Table {...tableProps} />
      <div className={styles.pagination}>
        <Pagination {...paginationProps} />
      </div>
    </>
  );
};

export default ProofOfChargingsTab;
