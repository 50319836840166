import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import OfferService from 'services/tendergy/tendergy-offer.service';
import {
  AppointmentPurpose,
  IOffer,
  OfferingUserType,
} from 'store/tendegyService/tendergyService.types';

export interface IAppointmentsFilters {
  proposedBy: null | OfferingUserType;
  purpose: null | AppointmentPurpose;
  offer: null | IOffer;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const AppointmentsFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IAppointmentsFilters>({
    proposedBy: null,
    purpose: null,
    offer: null,
  });

  useEffect(() => {
    onFilter &&
      onFilter({
        proposedBy: value.proposedBy,
        purpose: value.purpose,
        offerId: value.offer?.id || null,
      });
  }, [value]); // eslint-disable-line

  const proposedBySelectProps = {
    placeholder: 'Proposed By',
    onChange: (proposedBy: OfferingUserType) => {
      setValue({ ...value, proposedBy });
    },
    style: { width: '100%' },
    allowClear: true,
    options: [
      { value: OfferingUserType.END_USER, label: 'End User' },
      { value: OfferingUserType.INSTALLER, label: 'Installer' },
    ],
    value: value.proposedBy as OfferingUserType,
  };

  const proposeSelectProps = {
    placeholder: 'Propose',
    onChange: (purpose: AppointmentPurpose) => {
      setValue({ ...value, purpose });
    },
    style: { width: '100%' },
    allowClear: true,
    options: [
      { value: AppointmentPurpose.HomeCheck, label: 'Home Check' },
      { value: AppointmentPurpose.Installation, label: 'Installation' },
    ],
    value: value.purpose as AppointmentPurpose,
  };

  const offerEntitySelectorProps = {
    name: 'offer',
    placeholder: 'Select Offer',
    allowClear: true,
    value: value.offer?.id,
    entityService: OfferService,
    onSelect: (offer: IOffer) => {
      setValue({ ...value, offer });
    },
    style: { width: '100%' },
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col span={8}>
        <Select {...proposedBySelectProps} />
      </Col>
      <Col span={8}>
        <Select {...proposeSelectProps} />
      </Col>
      <Col span={8}>
        <EntitySelector {...offerEntitySelectorProps} />
      </Col>
    </Row>
  );
};

export default AppointmentsFilters;
