import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IShopUser } from 'services/shop/shop-service.types';
import ShopUsersService from 'services/shop/shop-users.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

const columns: ColumnsType<IShopUser> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
];

const params: IGetMultiEntityPayload = {};

const ShopUsersOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Shop Users"
    columns={columns}
    defaultPayload={params}
    resource={ShopUsersService}
  />
);
export default ShopUsersOverviewPage;
