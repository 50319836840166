import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { TRANSITION } from 'store/tendegyService/offer/offer.types';

import { IOffer } from '../tendergyService.types';
import { GET, OfferActionTypes, RESET, SET, SET_LOADING_STATUS } from './offer.types';

export function get(payload: IGetSingleEntityPayload): OfferActionTypes {
  return {
    type: GET,
    payload,
  };
}

export function set(payload: IOffer): OfferActionTypes {
  return {
    type: SET,
    payload,
  };
}

export function transition(payload: any): OfferActionTypes {
  return {
    type: TRANSITION,
    payload,
  };
}

export function setLoadingStatus(payload: boolean): OfferActionTypes {
  return {
    type: SET_LOADING_STATUS,
    payload,
  };
}

export function reset(): OfferActionTypes {
  return {
    type: RESET,
  };
}
