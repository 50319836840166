import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import ProductAttributeOptionsService from 'services/shop/shop-product-options.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ProductAttributeOptionBaseStep from './steps/ProductAttributeOptionBaseStep';

const ProductAttributeOptionCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add product attribute option',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <ProductAttributeOptionBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Product Attribute Option"
      steps={steps}
      baseLink={links.shopProductAttributeOptions}
      defaultPayload={params}
      resource={ProductAttributeOptionsService}
    />
  );
};

export default ProductAttributeOptionCreatePage;
