import * as Yup from 'yup';

export const INITIAL_VALUES = {
  wallbox: null,
  problem: null,
  solution: null,
  diagnostic: null,
};

export const validationSchema = Yup.object().shape({
  wallbox: Yup.object().nullable(),
  problem: Yup.object().nullable(),
  solution: Yup.object().nullable(),
  diagnostic: Yup.object().nullable(),
});
