import React from 'react';

import { FormEntitySelect, FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import FormSwitch from 'components/forms/FormSwitch/FormSwitch';
import BillOrganizationService from 'services/billing-v2/billing-v2-organizations.service';
import { IBillingCarOwner } from 'services/billing-v2/billing-v2-service.types';

import { validationSchema } from '../../validation';

import styles from './styles.module.sass';

interface CarOwnerFormProps {
  entry?: IBillingCarOwner;
  onSubmit: (entry: IBillingCarOwner) => Promise<IBillingCarOwner>;
}

const CarOwnerForm = (props: CarOwnerFormProps) => {
  const { entry, onSubmit } = props;

  const formikProps: IFormikFormProps<IBillingCarOwner> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IBillingCarOwner,
  };

  const selectProps = {
    label: 'Organization',
    entityService: BillOrganizationService,
    name: 'organization',
    current: entry?.organization,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="email" label="Email" required />
      <FormInput name="firstname" label="First Name" />
      <FormInput name="lastname" label="Last Name" />
      <FormEntitySelect {...selectProps} />
      <FormInput name="employeeNumber" label="Employee Number" />
      <FormInput name="receiverName" label="Bank Account Holder" />
      <FormInput name="iban" label="IBAN" />
      <FormInput name="bic" label="BIC" />
      <div className={styles.centered}>
        <h4>Address</h4>
      </div>
      <FormInput name="address.country" label="Country" />
      <FormInput name="address.iso3166_1_alpha_3" label="ISO 3166-1 alpha-3 Code" required />
      <FormInput name="address.region" label="Region" />
      <FormInput name="address.city" label="City" required />
      <FormInput name="address.address" label="Address" required />
      <FormInput name="address.postalCode" label="Postcode" required />
      <FormInput name="address.latitude" label="Latitude" />
      <FormInput name="address.longitude" label="Longitude" />
      <FormSwitch name="generateCoordinates" label="Auto-generate coordinates" defaultChecked />
      <FormSubmit />
    </FormikForm>
  );
};

export default CarOwnerForm;
