import produce from 'immer';

import {
  AUTH_FINGERPRINT,
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_TOKEN_REFRESH,
  AUTH_TOKEN_REFRESH_ERROR,
  AUTH_TOKEN_REFRESH_SUCCESS,
  AuthState,
} from './auth.types';

const initialState: AuthState = {
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    role: '',
  },
  token: null,
  loading: true,
  error: null,
};

const AuthReducer = (state = initialState, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case AUTH_INIT:
      case AUTH_LOGIN:
      case AUTH_TOKEN_REFRESH:
        draft.loading = true;
        break;

      case AUTH_FINGERPRINT:
        draft.fingerprint = action.payload.fingerprint;
        break;

      case AUTH_LOGIN_SUCCESS:
      case AUTH_TOKEN_REFRESH_SUCCESS:
        draft.loading = false;
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        break;

      case AUTH_LOGIN_ERROR:
      case AUTH_TOKEN_REFRESH_ERROR:
        draft.loading = false;
        draft.error = '';
        break;

      case AUTH_LOGOUT:
        draft.user = initialState.user;
        draft.error = null;
        draft.token = null;

        break;
    }
  });

export default AuthReducer;
