import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Dropdown, Menu, Modal, Row, Spin } from 'antd';

import links from 'framework/links';
import TendergyQuestionnaireService from 'services/tendergy/tendergy-questionnaire.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergyQuestionnaire } from 'store/tendegyService/tendergyService.types';

import QuestionnaireOverviewTab from './tabs/QuestionnaireOverviewTab';
import QuestionnaireStepTab from './tabs/QuestionnaireStepsTab';

const QuestionnaireConfigDetailsPage: React.FC = () => {
  const history = useHistory();
  const [questionnaire, setQuestionnaire] = useState<ITendergyQuestionnaire>();
  const [visibleDelete, setVisibleDelete] = useState<boolean>();
  const [selectedTab, setSelectedTab] = useState(['detail']);
  const { id, version } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id: `${id}/${version}`,
    relations: ['steps', 'pools', 'surveyPools', 'clientRequests'],
  };

  const hasClientRequests =
    questionnaire?.clientRequests && questionnaire?.clientRequests.length > 0;
  const hasSteps = questionnaire?.steps && questionnaire?.steps.length > 0;
  const disableActions = {
    delete: questionnaire?.isDefault === true || hasClientRequests,
    import: hasClientRequests,
    increaseVersion: !hasClientRequests,
    export: !hasSteps,
  };

  useEffect(() => {
    TendergyQuestionnaireService.getById(params).then((response) => {
      setQuestionnaire(response);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin
      spinning={!questionnaire}
      style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
    >
      {questionnaire && (
        <Card
          title={
            <Breadcrumb>
              <Breadcrumb.Item>Tendergy</Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="/tendergy/questionnaires">Questionnaires</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href={`/tendergy/questionnaires/${questionnaire.id}/${questionnaire.version}`}>
                  {questionnaire.name} (Version: {questionnaire.version})
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          extra={
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="0"
                    onClick={() =>
                      history.push(
                        `${links.tendergyCreateQuestionnaireStepBaseURL}/${questionnaire.id}/${questionnaire.version}`,
                      )
                    }
                  >
                    Add new step
                  </Menu.Item>
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      if (questionnaire.id)
                        TendergyQuestionnaireService.increaseVersion(questionnaire.id).then(() => {
                          history.push(links.tendergyQuestionnaires);
                        });
                    }}
                    disabled={disableActions.increaseVersion}
                  >
                    Create version {questionnaire.version + 1}
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() => {
                      history.push(
                        `${links.tendergyimportQuestionnaireBaseURL}/${questionnaire.id}/${questionnaire.version}`,
                      );
                    }}
                    disabled={disableActions.import}
                  >
                    Import questionnaire
                  </Menu.Item>
                  <Menu.Item
                    key="3"
                    onClick={() => {
                      if (questionnaire.id)
                        TendergyQuestionnaireService.exportQuestionaire(
                          questionnaire.id,
                          questionnaire.version,
                        ).then((res) => {
                          console.log(res);
                          let response = JSON.stringify(res);
                          let str: any = `data:text/json;charset=utf-8,${encodeURIComponent(
                            response,
                          )}`;
                          let dl = document.createElement('a');
                          dl.setAttribute('href', str);
                          dl.setAttribute('download', 'jsonFile.json');
                          dl.click();
                        });
                    }}
                    disabled={disableActions.export}
                  >
                    Export questionnaire
                  </Menu.Item>
                  <Menu.Item
                    key="4"
                    disabled={disableActions.delete}
                    onClick={() => setVisibleDelete(true)}
                  >
                    Delete questionnaire
                  </Menu.Item>
                  <Modal
                    title="CONFIRM DELETE"
                    visible={visibleDelete}
                    onOk={() =>
                      TendergyQuestionnaireService.deleteByIdVersion({
                        id: id,
                        version: version,
                      }).then(() => {
                        history.push(links.tendergyQuestionnaires);
                      })
                    }
                    onCancel={() => setVisibleDelete(false)}
                  >
                    <p>{`Are you sure you want to delete ${questionnaire.name} (version:${questionnaire.version})`}</p>
                  </Modal>
                </Menu>
              }
            >
              <Button>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          }
        >
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Menu
                selectedKeys={selectedTab}
                mode="inline"
                style={{ background: '#fff!important' }}
              >
                <Menu.Item key={'detail'} onClick={() => setSelectedTab(['detail'])}>
                  Detail
                </Menu.Item>
                <Menu.Item key={'steps'} onClick={() => setSelectedTab(['steps'])}>
                  Steps
                </Menu.Item>
              </Menu>
            </Col>
            <Col span={20}>
              {selectedTab.includes('detail') && (
                <QuestionnaireOverviewTab
                  entry={questionnaire}
                  onSave={(data: ITendergyQuestionnaire) => {
                    TendergyQuestionnaireService.save(data);
                  }}
                />
              )}
              {selectedTab.includes('steps') && (
                <QuestionnaireStepTab questionnaire={questionnaire} />
              )}
            </Col>
          </Row>
        </Card>
      )}
    </Spin>
  );
};

export default QuestionnaireConfigDetailsPage;
