import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { OfferState } from 'framework/constants';
import { BASE_DATE_FORMAT } from 'framework/constants/dateFormats';
import OfferService from 'services/tendergy/tendergy-offer.service';
import { IClientRequest, IOffer } from 'store/tendegyService/tendergyService.types';

const columns = [
  {
    title: 'No.',
    dataIndex: 'displayId',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    title: 'Installer',
    dataIndex: 'installerUser',
    sortParameter: 'installerUser.firstName',
    key: 'installer',
    render: (entry: IOffer) =>
      entry.installerUser
        ? `${entry.installerUser?.firstName} ${entry.installerUser?.lastName}`
        : '–',
  },
  {
    title: 'Created',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'createdAt',
    render: (date: string) => (date ? moment(date).format(BASE_DATE_FORMAT) : '–'),
  },
  {
    title: 'State',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'state',
    render: (value: OfferState) => value || '–',
  },
  {
    title: 'Action',
    dataIndex: 'state',
    render: (value: OfferState) => {
      switch (value) {
        case OfferState.TenderSubmitted:
        case OfferState.TenderSubmittedHomeCheck:
          return <span>Accept / Reject Tender</span>;
        default:
          return <span>View Only</span>;
      }
    },
  },
];

interface Props {
  clientRequest: IClientRequest;
}

const OffersTab: React.FC<Props> = (props: Props) => {
  const { clientRequest } = props;
  const payload: any = {
    clientRequestId: [clientRequest?.id],
  };

  return (
    <CrudListViewContainer
      entityName="Offers"
      columns={columns}
      additionalPayload={payload}
      resource={OfferService}
      disableSearch
    />
  );
};

export default OffersTab;
