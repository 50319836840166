import React from 'react';
import { DeleteOutlined } from '@ant-design/icons/lib';
import { Button, Popconfirm } from 'antd';

import { IAuthRole } from 'store/authService/authService.types';

import styles from './RoleActionsComponent.module.sass';

interface Props {
  entry?: IAuthRole;
  onDelete: () => void;
}

const RoleActionsComponent: React.FC<Props> = (props: Props) => {
  const { entry, onDelete } = props;

  if (!entry) {
    return <div>Entry is not set</div>;
  }
  return (
    <div className={styles.containerOuter}>
      <div className={styles.containerInner}>
        <Popconfirm title="Are you sure you want to delete this role?" onConfirm={() => onDelete()}>
          <Button
            type="primary"
            className={styles.button}
            shape="round"
            size="large"
            danger
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default RoleActionsComponent;
