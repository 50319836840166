import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergyComment } from 'store/tendegyService/tendergyService.types';

class CommentsServiceClass extends EntityService<ITendergyComment> {
  getDisplayName(entry: ITendergyComment): string {
    return entry.text;
  }
}

const CommentsService = new CommentsServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/comments',
  detailLink: links.comment,
  createLink: links.createComment,
});

export default CommentsService;
