import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Checkbox, Form, Input } from 'antd';

import { login } from 'store/auth/auth.actions';
import { isAuthorizedSelector, isLoadingSelector } from 'store/auth/auth.selectors';

import { AUTH_TOKEN_KEY } from '../../../framework/constants';

import styles from './styles.module.sass';

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const Login: React.FC = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isLoading = useSelector(isLoadingSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  // const isAdmin = useSelector(isUserAdminSelector); // TODO: use later

  useEffect(() => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);

    if (!isLoading && isAuthorized && accessToken) {
      const { from } = (location.state as any) || ({ from: { pathname: '/' } } as any);
      history.replace(from);
    }
  }, [isAuthorized]); //eslint-disable-line

  const onFormSubmit = () => {
    // @ts-ignore
    const data = formRef.current.getFieldsValue();
    // temporary fix
    data.fingerprint = 'a577776b895b670168898a0a810cd379';
    data.context = 'admin';
    //
    dispatch(login(data));
  };

  const formProps = {
    ...layout,
    name: 'basic',
    ref: formRef,
    initialValues: { remember: true },
  };

  return (
    <div className={styles.container}>
      <Form className={styles.form} {...formProps} onFinish={onFormSubmit}>
        <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input placeholder="Email" width="100%" size="large" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Password" width="100%" size="large" />
        </Form.Item>

        <Form.Item {...layout} name="remember" valuePropName="checked">
          <div className={styles.extra}>
            <Checkbox>Remember me</Checkbox>
          </div>
        </Form.Item>

        <Form.Item {...layout}>
          <Button type="primary" size="large" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
