import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';

import { IBillingAddress } from 'services/billing/billing-service.types';

import { FormInput } from '../../forms';

import styles from './AddressComponent.module.sass';

interface BillingAddressComponentProps {
  value?: IBillingAddress;
  onChange?: (address: IBillingAddress) => void;
  defaultEditMode?: boolean;
}

const AddressComponent: React.FC<BillingAddressComponentProps> = (
  props: BillingAddressComponentProps,
) => {
  const { value, defaultEditMode } = props;
  const defaultValue: IBillingAddress = {
    city: '',
    address: '',
    postalCode: '',
    country: '',
    region: '',
    latitude: '',
    longitude: '',
    iso3166_1_alpha_3: '',
  };
  const [address, setAddress] = useState<IBillingAddress>(value || defaultValue);
  const [isEditMode, setIsEditMode] = useState<boolean>(defaultEditMode || false); // todo
  const { onChange } = props;

  useEffect(() => {
    if (onChange) {
      onChange(address);
    }
    // eslint-disable-next-line
  }, [address]);

  if (!isEditMode) {
    return (
      <div>
        <table>
          <tr>
            <td>Address</td>
            <td>{address.address}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              {address.postalCode} {address.city}
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{address.country}</td>
          </tr>
          <tr>
            <td>Latitude</td>
            <td>{address.latitude}</td>
          </tr>
          <tr>
            <td>Longitude</td>
            <td>{address.longitude}</td>
          </tr>
          <tr>
            <td>ISO 3166-1 alpha-3 Country Code</td>
            <td>{address.iso3166_1_alpha_3}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Button type="link" onClick={() => setIsEditMode(true)}>
                Edit
              </Button>
            </td>
          </tr>
        </table>
      </div>
    );
  }

  return (
    <div>
      <Form.Item label="address" name="address" rules={[{ required: true }]}>
        <Input
          value={address.address}
          onBlur={(newValue) => setAddress({ ...address, address: newValue.target.value })}
        />
      </Form.Item>

      <Form.Item label="postcode/city" name="city">
        <Input.Group compact className={styles.inputGroupWrapper}>
          <Form.Item name="Postal Code" rules={[{ required: true }]} className={styles.zipCode}>
            <Input
              placeholder="PLZ"
              value={address.postalCode}
              onBlur={(newValue) => setAddress({ ...address, postalCode: newValue.target.value })}
            />
          </Form.Item>
          <Form.Item name="city" rules={[{ required: true }]}>
            <Input
              placeholder="city name"
              value={address.city}
              onBlur={(newValue) => setAddress({ ...address, city: newValue.target.value })}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="country" name="country" rules={[{ required: true }]}>
        <Input
          value={address.country}
          onBlur={(newValue) => setAddress({ ...address, country: newValue.target.value })}
        />
      </Form.Item>
      <FormInput
        name="latitude"
        label="latitude"
        onBlur={(newValue) => setAddress({ ...address, latitude: newValue.target.value })}
      />
      <FormInput
        name="longitude"
        label="longitude"
        onBlur={(newValue) => setAddress({ ...address, longitude: newValue.target.value })}
      />
      <FormInput
        name="iso3166_1_alpha_3"
        label="ISO 3166-1 alpha-3 Country Code"
        required
        onBlur={(newValue) => setAddress({ ...address, iso3166_1_alpha_3: newValue.target.value })}
      />
    </div>
  );
};

export default AddressComponent;
