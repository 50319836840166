import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergyQuestionnaireConfig } from 'store/tendegyService/tendergyService.types';

class QuestionnaireConfigServiceClass extends EntityService<ITendergyQuestionnaireConfig> {}

const QuestionnaireConfigService = new QuestionnaireConfigServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/questionnaire-configs',
  detailLink: links.questionnaireConfig,
});

export default QuestionnaireConfigService;
