import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import ApiClientService from 'services/auth/api-client.service';
import { IApiClient } from 'store/authService/authService.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ApiClientsActionsComponent from './components/CommentsActionsComponent/ApiClientsActionsComponent';
import ApiClientsOverviewTab from './tabs/ApiClientsOverviewTab';

const ApiClientsDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IApiClient, optionalProps?: ICrudDetailViewCallbacks) => (
        <ApiClientsOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  const renderActions = (entry: IApiClient, triggerReload: Function) => (
    <ApiClientsActionsComponent entry={entry} triggerReload={triggerReload} />
  );

  return (
    <CrudDetailContainer
      entityName="Api Client"
      tabs={tabs}
      baseLink={links.authApiClients}
      defaultPayload={params}
      renderActions={renderActions}
      resource={ApiClientService}
    />
  );
};

export default ApiClientsDetailsPage;
