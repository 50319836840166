import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import EmailTemplatesService from 'services/email/email-templates.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import TemplateBasicStep from './steps/TemplateBasicStep';
import TemplateEmailStep from './steps/TemplateEmailStep';

const TemplateCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add basic info',
      render: (entry, onSubmitStep) => (
        <TemplateBasicStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
    {
      name: 'Add template',
      render: (entry, onSubmitStep) => (
        <TemplateEmailStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];

  return (
    <CrudCreateWizardContainer
      entityName="Template"
      steps={steps}
      baseLink={links.emailTemplates}
      defaultPayload={params}
      resource={EmailTemplatesService}
    />
  );
};

export default TemplateCreatePage;
