import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { checkActivity } from 'pages/billing-v2/charging-stations/tabs/EnergyPricesTab';
import BillingEnergyPricingService from 'services/billing/billing-energy-prices.service';
import {
  IBillingChargingStation,
  IBillingEnergyPrice,
} from 'services/billing/billing-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { CurrencyUtils } from 'utils/currencyUtils';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IBillingEnergyPrice> = [
  {
    title: 'Charging station',
    dataIndex: 'chargingStation',
    render: (station: IBillingChargingStation) => station.evse || '-',
  },
  {
    title: 'charge / kWh',
    dataIndex: 'chargePerUnit',
    key: 'chargePerUnit',
    align: 'right',
    render: (unit: string) => CurrencyUtils.formatCurrency(unit),
  },
  {
    title: 'Payout / kWh',
    dataIndex: 'payoutPerUnit',
    key: 'payoutPerUnit',
    align: 'right',
    render: (unit: string) => CurrencyUtils.formatCurrency(unit),
  },
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Status',
    render: (date: any) => {
      return checkActivity(date.validFrom, date.validTo);
    },
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'right',
    render: (date: string) => formatDefaultDate(date),
  },
];

const BillingEnergyPricesPage: React.FC = () => {
  const payload: IGetMultiEntityPayload = { relations: ['carOwner'] };
  return (
    <CrudListViewContainer
      entityName="Energy Prices"
      defaultPayload={payload}
      columns={columns}
      resource={BillingEnergyPricingService}
    />
  );
};
export default BillingEnergyPricesPage;
