import * as React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import OrganizationEntitySelector from 'components/forms/organization/ClientIdEntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import links from 'framework/links';
import { CraftOrganizationValidation } from 'pages/auth/organizations/organizations.validation';
import ApiClientService from 'services/auth/api-client.service';
import {
  ITendergyOrganization,
  OrganizationTypes,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

interface CraftOrganizationTabProps {
  entry?: ITendergyOrganization | null;
  onSave: (updatedValues: any) => Promise<any>;
}

const CraftOrganizationTab: React.FC<CraftOrganizationTabProps> = (
  props: CraftOrganizationTabProps,
) => {
  const { entry, onSave } = props;

  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(CraftOrganizationValidation);
  const inviteInfo = entry?.inviteTokens?.find((item) => item.type === 'installerManager');
  const invitedBy = inviteInfo
    ? inviteInfo?.clientRequestPool?.intermediateOrganization?.name
    : 'Unknown';
  const clientRequestPool = inviteInfo?.clientRequestPool;
  const poolLink = `${links.clientRequestPools}/${clientRequestPool?.id}`;

  return (
    <Row>
      <Col span={18}>
        <Formik
          initialValues={entry as any}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting }) => {
            onSave(values).then(() => setSubmitting(false));
          }}
        >
          {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
            <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
              <Form.Item name="name" label="Name">
                <Input name="name" placeholder="" />
              </Form.Item>

              <Form.Item name="type" label="Type">
                <Select name="type">
                  <Select.Option value={OrganizationTypes.Installer}>
                    Installer Organization
                  </Select.Option>
                  <Select.Option value={OrganizationTypes.Intermediate}>Intermediate</Select.Option>
                </Select>
              </Form.Item>
              {entry.type === 'installer' && inviteInfo && (
                <React.Fragment>
                  <Form.Item name="invitedBy" label="Invited by">
                    {invitedBy}
                  </Form.Item>
                  <Form.Item name="invitedForPool" label="Invited for pool">
                    <Link target="_blank" to={poolLink}>
                      {clientRequestPool?.name}
                    </Link>
                  </Form.Item>
                  <Form.Item name="invitedOn" label="Invited on">
                    {formatDefaultDate(clientRequestPool?.createdAt)}
                  </Form.Item>
                </React.Fragment>
              )}

              <Form.Item name="variableFee" label="Variable Fee">
                <Input name="variableFee" placeholder="" type="number" />
              </Form.Item>

              <Form.Item name="address.street" label="Street">
                <Input name="address.street" placeholder="" />
              </Form.Item>
              <Form.Item name="address.houseNumber" label="House Number">
                <Input name="address.houseNumber" placeholder="" />
              </Form.Item>
              <Form.Item name="address.address2" label="Address Line 2">
                <Input name="address.address2" placeholder="" />
              </Form.Item>
              <Form.Item name="address.postalCode" label="Postal Code">
                <Input name="address.postalCode" placeholder="" />
              </Form.Item>
              <Form.Item name="address.city" label="City">
                <Input name="address.city" placeholder="" />
              </Form.Item>

              <Form.Item name="apiClientId" label="Api Client Id">
                <OrganizationEntitySelector
                  name="apiClientId"
                  entityService={ApiClientService}
                  current={values?.apiClientId}
                  onSelect={(id: string) => setFieldValue('apiClientId', id)}
                />
              </Form.Item>

              <Form.Item {...CrudFormDefaultActionParams} name="submit">
                <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                  Submit
                </SubmitButton>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default CraftOrganizationTab;
