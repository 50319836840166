import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISAUsersService } from 'services/isa-service';
import { IISAUser, IParams } from 'services/isa-service/isa-service.types';

import ISAUsersForm from './components/ISAUsersForm';

export default function ISAUsersDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISAUsersForm entry={entry} onSubmit={onSave} />,
        },
      ]}
      entityName="user"
      baseLink={links.isaUsersDetails}
      defaultPayload={{ id, relations: ['address'] }}
      resource={ISAUsersService}
      hideJsonTab
      renderTitle={(entry: IISAUser) => entry.email}
    />
  );
}
