import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import OfferService from 'services/tendergy/tendergy-offer.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { IOffer } from 'store/tendegyService/tendergyService.types';

import OfferActionsComponent from './components/OfferActionsComponent/OfferActionsComponent';
import OfferClientRequestTab from './pages/OfferClientRequestTab/OfferClientRequestTab';
import OfferDocumentsTab from './pages/OfferDocumentsTab/OfferDocumentsTab';
import ReviewOfferComponent from './pages/ReviewOffer/ReviewOfferTab';

const OfferDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IOffer, optionalProps?: ICrudDetailViewCallbacks) => (
        <ReviewOfferComponent
          offer={entity}
          documents={optionalProps?.documents}
          onSave={optionalProps?.onSave}
        />
      ),
    },
    {
      name: 'Client Request',
      displayName: 'Client Request',
      render: (entity: IOffer) => <OfferClientRequestTab id={entity.clientRequestId} />,
    },
    {
      name: 'Documents',
      displayName: 'Offer Documents',
      render: (entity: IOffer, optionalProps?: ICrudDetailViewCallbacks) => (
        <OfferDocumentsTab
          documents={optionalProps?.documents}
          deleteFile={optionalProps?.deleteFile}
          uploadFile={optionalProps?.uploadFile}
        />
      ),
      triggerReload: true,
    },
  ];

  const renderActions = (entry: IOffer, triggerReload: Function) => (
    <OfferActionsComponent entry={entry} triggerReload={triggerReload} />
  );

  return (
    <CrudDetailContainer
      entityName="Offer"
      renderActions={renderActions}
      tabs={tabs}
      shouldGetFiles
      baseLink={links.offer}
      defaultPayload={params}
      resource={OfferService}
    />
  );
};

export default OfferDetailsPage;
