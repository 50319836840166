import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IShopLanding } from './shop-service.types';

class LandingsServiceClass extends EntityService<IShopLanding> {}

const LandingsService = new LandingsServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/landings',
  createLink: links.shopCreateCategory,
  detailLink: links.shopCategory,
});

export default LandingsService;
