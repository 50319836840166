import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Divider, Popover, Tag, Typography } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { OfferState } from 'framework/constants';
import { BASE_DATE_FORMAT, DATE_AND_TIME } from 'framework/constants/dateFormats';
import TendergyHistoryLogService from 'services/tendergy/tendergy-history-log.service';
import { IClientRequest } from 'store/tendegyService/tendergyService.types';

const typeChecker = (value: any) => {
  if (value === null || value === undefined) {
    return <Tag>null</Tag>;
  }
  // check if it's boolean return as tag
  if (typeof value === 'boolean') {
    return <Tag>{value.toString()}</Tag>;
  }
  // check if value is an object
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  // check if value is a date
  if (moment(value, moment.ISO_8601, true).isValid()) {
    return <Tag color="green">{moment(value).format(DATE_AND_TIME)}</Tag>;
  }
  return value;
};
const columns = [
  {
    title: 'Table Name',
    dataIndex: 'tableName',
  },
  {
    title: 'Current DB User',
    dataIndex: 'currentDbUser',
  },
  {
    title: 'Schema Name',
    dataIndex: 'schemaName',
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    render: (value: OfferState) => value || '–',
  },
  {
    title: 'Performer',
    dataIndex: 'performerUser',
    render: (value: any) => {
      const content = (
        <div>
          <p>
            <strong>Firstname: </strong>
            {value?.firstName}
          </p>
          <p>
            <strong>Lastname: </strong>
            {value?.lastName}
          </p>
          <p>
            <strong>DisplayId: </strong>
            {value?.displayId}
          </p>
          <p>
            <strong>Email: </strong>
            {value?.email}
          </p>
          <p>
            <strong>orgId: </strong>
            {value?.organizationId || '-'}
          </p>
        </div>
      );
      return (
        <Popover content={content} title="Performer Info" style={{ top: '10px' }}>
          <Button type="primary">Info</Button>
        </Popover>
      );
    },
  },
  {
    title: 'Changes',
    dataIndex: 'changes',
    render: (changes: any[]) => {
      const content = () =>
        (
          <div style={{ maxHeight: 280, overflow: 'auto' }}>
            {changes?.map(({ key, oldValue, newValue, id }) => {
              return (
                <div key={id}>
                  <div style={{ paddingBottom: '12px' }}>
                    <Typography style={{ display: 'inline' }}>Column name: </Typography>{' '}
                    <Tag>{key}</Tag>
                    <br />
                  </div>
                  <Typography style={{ display: 'inline' }}>changes:</Typography>{' '}
                  <Typography style={{ display: 'inline', padding: '4px' }}>
                    {' '}
                    {typeChecker(oldValue)}
                  </Typography>
                  <ArrowRightOutlined style={{ color: 'green' }} />{' '}
                  <Typography style={{ display: 'inline', padding: '4px' }}>
                    {typeChecker(newValue)}
                  </Typography>
                  <Divider />
                </div>
              );
            })}
          </div>
        ) || '-';
      return (
        <Popover content={content} title="Changes Info">
          <Button type="primary">Changes List</Button>
        </Popover>
      );
    },
  },
  {
    title: 'Created',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'createdAt',
    render: (date: string) => (date ? moment(date).format(BASE_DATE_FORMAT) : '–'),
  },
];

interface Props {
  clientRequest: IClientRequest;
}

const HistoryTab: React.FC<Props> = (props: Props) => {
  const { clientRequest } = props;
  const payload: any = {
    tableName: 'client_requests',
    entityId: clientRequest?.id,
  };

  return (
    <CrudListViewContainer
      entityName="History Logs"
      columns={columns}
      additionalPayload={payload}
      resource={TendergyHistoryLogService}
      disableSearch
    />
  );
};

export default HistoryTab;
