import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';
import * as Yup from 'yup';

import {
  IBillingChargingStation,
  IBillingEnergyPrice,
  IBillingStationEntity,
  IOpt,
} from 'services/billing-v3/billing-service.types';
import BillChargingStationsService from 'services/billing-v3/billing-v3-charging-stations.service';
import BillingEnergyPricingService from 'services/billing-v3/billing-v3-energy-prices.service';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import { CreateEnergyPriceValidation } from '../validation';
import EnergyPriceDetailForm from './details/EnergyPriceDetailForm';

const DisablityList = ['payoutPerUnit', 'documents'];
const MapEnums = (Enum: any) =>
  Object.keys(Enum).reduce((acc: IOpt[], field) => {
    const value = Enum[field];
    return [...acc, { label: field, value }];
  }, []);

enum ECreateEnergyPrice {
  Approved = 'approved',
}

export default function ChargingStationCreatePage() {
  const { Content } = Layout;
  const [pointGroups, setGroupPoints] = useState<IBillingChargingStation[]>([]);
  const [findUser, setFindUser] = useState<IBillingStationEntity>();
  const [query, setQuery] = useState<string>('');
  const [createEnergyPrice, setCreateEnergyPrice] = useState<any>('');
  const [fetching, setFetching] = useState<boolean>(false);
  const location = useLocation();
  const id = location.pathname.split('/')?.[4];
  const validation = Yup.object().shape(CreateEnergyPriceValidation);
  const handleCreateEnergyPrice = async (values: IBillingEnergyPrice) => {
    try {
      const res = await BillingEnergyPricingService.save({
        ...values,
        chargingPoint: {
          id: findUser?.id!,
        },
      });
      if (res.message) {
        showErrorNotification(res?.message);
      } else {
        setCreateEnergyPrice(res);
        showSuccessNotification('Energy Price successfully added.');
      }
    } catch (error) {
      console.error('API call failed:', error);
      showErrorNotification(error || 'Failed to add Energy Price');
    }
  };
  console.log('createEnergyPrice', createEnergyPrice);
  const getUser = async () => {
    try {
      setFetching(true);
      setFindUser(undefined);
      const response = await BillChargingStationsService.get(
        `admin/charging-points/${id}?limit=100&offset=0&query=${query}`,
      );
      setFetching(false);

      setFindUser(response);
    } catch (e) {
      setFetching(false);
      showErrorNotification(e);
    }
  };
  const getChargingGroups = async () => {
    try {
      setFetching(true);
      setGroupPoints([]);
      const response = await BillChargingStationsService.get(
        `admin/charging-points?limit=100&offset=0&query=${query}`,
      );
      setFetching(false);

      setGroupPoints(response?.items);
    } catch (e) {
      setFetching(false);
      showErrorNotification(e);
    }
  };
  useEffect(() => {
    getChargingGroups();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  return (
    <Row>
      <Col span={24}>
        <Content style={{ backgroundColor: '#fff', padding: '24px' }}>
          <div className="site-layout-background">
            <EnergyPriceDetailForm
              fetching={fetching}
              setQuery={setQuery}
              evseId={id}
              createEnergyPrice={createEnergyPrice}
              pointGroups={pointGroups}
              disabledList={DisablityList}
              entry={{ status: 'approved', chargingPointId: id }}
              statusOptions={MapEnums(ECreateEnergyPrice)}
              validation={validation}
              onSave={handleCreateEnergyPrice}
            />
          </div>
        </Content>
      </Col>
    </Row>
  );
}
