import * as Yup from 'yup';

// type: ShopUserType;
// firstName?: string;
// lastName?: string;
// companyName?: string;
// website?: string;
// industry?: ShopIndustryOptions;
// billingAddress?: IShopAddress;
// shippingAddress?: IShopAddress;

export const ShopUserValidation = {
  firstName: Yup.string().nullable().min(1, 'Too short!').max(30, 'Too long!'),
  lastName: Yup.string().nullable().min(1, 'Too short!').max(30, 'Too long!'),
  companyName: Yup.string().nullable().min(1, 'Too short!').max(30, 'Too long!'),
  website: Yup.string().nullable().min(1, 'Too short!').max(30, 'Too long!'),
  billingAddress: Yup.object().shape({
    country: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    region: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    city: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    address: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    postalCode: Yup.string().nullable().min(2, 'Too short!').max(8, 'Too long'),
    houseNumber: Yup.string().nullable().min(1, 'Too short!').max(40, 'Too long'),
    additionalInformation: Yup.string().nullable().min(2, 'Too short!').max(5000, 'Too long'),
    phone: Yup.string().nullable().min(2, 'Too short!').max(20, 'Too long'),
    email: Yup.string().nullable().email().min(2, 'Too short!').max(40, 'Too long'),
    firstName: Yup.string().nullable().min(2, 'Too short!').max(5000, 'Too long'),
    lastName: Yup.string().nullable().min(2, 'Too short!').max(5000, 'Too long'),
  }),
  shippingAddress: Yup.object().shape({
    country: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    region: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    city: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    address: Yup.string().nullable().min(2, 'Too short!').max(40, 'Too long'),
    postalCode: Yup.string().nullable().min(2, 'Too short!').max(8, 'Too long'),
    houseNumber: Yup.string().nullable().min(1, 'Too short!').max(40, 'Too long'),
    additionalInformation: Yup.string().nullable().min(2, 'Too short!').max(5000, 'Too long'),
    phone: Yup.string().nullable().min(2, 'Too short!').max(20, 'Too long'),
    email: Yup.string().nullable().email().min(2, 'Too short!').max(40, 'Too long'),
    firstName: Yup.string().nullable().min(2, 'Too short!').max(5000, 'Too long'),
    lastName: Yup.string().nullable().min(2, 'Too short!').max(5000, 'Too long'),
  }),
};
