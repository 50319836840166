import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergyAppointment } from 'store/tendegyService/tendergyService.types';

class AppointmentServiceClass extends EntityService<ITendergyAppointment> {}

const AppointmentService = new AppointmentServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/appointments',
  detailLink: links.appointment,
  createLink: links.createAppointment,
});

export default AppointmentService;
