import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISASolutionStepsService } from 'services/isa-service';

import ISASolutionStepsForm from './components/ISASolutionStepsForm';

export default function ISASolutionStepsCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Solution Step',
          render: (_entry, onSubmit) => <ISASolutionStepsForm onSubmit={onSubmit} />,
        },
      ]}
      entityName={'solution step'}
      baseLink={links.isaSolutionStepsOverview}
      resource={ISASolutionStepsService}
    />
  );
}
