import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import debounce from 'lodash/debounce';

import EntityService from 'services/abstract/entity.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

const { Option } = Select;

// any = record type
export interface IEntitySelectorProps extends SelectProps<any> {
  current?: string | any;
  entityService: EntityService<any>; //
  onSelect?: (entryId: any) => void; // emits the fund entity as full object!
  defaultPayload?: IGetMultiEntityPayload; // if we need sorting/filtering/etc. for search request
  name: string; // TODO: Change select to formik-antd and use name
}

const ClientIdEntitySelector: React.FC<IEntitySelectorProps> = (props: IEntitySelectorProps) => {
  const { onSelect, current, entityService, defaultPayload, disabled, ...rest } = props;
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState<{ id: string; label?: string; entry: any }[]>(
    [],
  );
  const [currentEntity, setCurrentEntity] = useState<any>(current);
  const [isEditMode, setIsEditMode] = useState<boolean>(!current);

  const history = useHistory();

  const searchEntities = async (searchQuery: string) => {
    const entities = await entityService.search(searchQuery, 50, defaultPayload);
    const items: any[] = [...entities.items];

    if (Array.isArray(currentEntity)) {
      currentEntity.forEach((entity) => {
        !items.find((item) => item.clientId === entity.clientId) && items.push(entity);
      });
    }

    const results = items.map((entry) => {
      const label = entityService.getDisplayName(entry);
      return { label, id: entry.clientId, entry };
    });

    setSearchResults(results);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce((q) => searchEntities(q), 500),
    [],
  );

  useEffect(() => {
    if (!isEditMode) {
      return;
    }
    delayedSearch(query);
  }, [query, isEditMode, entityService, delayedSearch]);

  useEffect(() => {
    let currentId: string = current;

    if (!current) {
      return;
    }

    if (typeof current === 'object' && current) {
      setCurrentEntity(current);
      return;
    }

    if (current.clientId) {
      currentId = current.clientId;
    }

    if (typeof current === 'string' && current) {
      currentId = current;
      setCurrentEntity(current);
    }

    entityService.getById({ id: currentId, ...defaultPayload }).then(() => {
      setCurrentEntity(currentEntity);
      setIsEditMode(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, entityService]);

  const entityName = entityService.getName() || '';

  const onSelectEvent = (selected: string | string[]) => {
    onSelect && onSelect(selected);
  };

  const renderAutocomplete = () => (
    <Select
      showSearch
      value={current}
      style={{ width: 200 }}
      placeholder={`Select ${entityName}`}
      optionFilterProp="children"
      onChange={(value) => onSelectEvent(value as string)}
      onSearch={(searchTerm: string) => setQuery(searchTerm)}
      disabled={disabled}
      {...rest}
    >
      {searchResults &&
        searchResults?.map((item) => (
          <Option key={item.id} value={item.id} item={item}>
            {item.label}
          </Option>
        ))}
    </Select>
  );

  const detailLink = entityService.getDetailLink(currentEntity);

  return (
    <>
      {isEditMode ? renderAutocomplete() : null}
      {current && (
        <div>
          {!isEditMode && !disabled ? (
            <Button onClick={() => setIsEditMode(true)}>
              <EditOutlined />
            </Button>
          ) : null}
          {detailLink ? (
            <Button onClick={() => history.push(detailLink)}>
              <EyeOutlined />
            </Button>
          ) : null}
        </div>
      )}
    </>
  );
};

export default ClientIdEntitySelector;
