import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import EntityService from 'services/abstract/entity.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { showErrorNotification } from 'utils/notificationUtil';

import links from '../../../framework/links';

import styles from './DashboardTableContainer.module.sass';

interface DashboardTableProps<T> {
  defaultPayload?: IGetMultiEntityPayload;
  columns: ColumnsType<T>;
  resource: EntityService<T>;
  title?: string;
  icon?: ReactElement;
  basicUrl: string;
}

interface DashboardState {
  loading: boolean;
  currentPage?: number;
  entries?: any[];
  count?: number;
  payload: IGetMultiEntityPayload;
  limit: number;
}

interface Record {
  id: string;
}

const DashboardTableContainer: React.FC<DashboardTableProps<any>> = (
  props: DashboardTableProps<any>,
) => {
  const history = useHistory();
  const { defaultPayload } = props;

  const statePayload = { limit: 10, offset: 0, ...defaultPayload };
  const [state, setState] = useState<DashboardState>({
    currentPage: 1,
    loading: false,
    limit: 10,
    entries: [],
    count: 0,
    payload: statePayload,
  });

  const refreshEntries = async () => {
    const { payload } = state;
    let response;
    payload.limit = payload.limit || state.limit || 10;
    payload.offset = (state.currentPage ? state.currentPage - 1 : 0) * (state.payload.limit || 10);
    try {
      response = await props.resource.getEntries(payload);
    } catch (e) {
      showErrorNotification({ error: e });
      return;
    }

    setState({ ...state, entries: response.items, count: response.total });
  };

  const setCurrentPage = (page: number, limit: number) => {
    setState({ ...state, currentPage: page, limit });
  };
  const renderFooter = () => {
    const { count } = state;

    return (
      <div className={styles.pagination}>
        <Pagination
          total={count}
          showSizeChanger={false}
          current={state.currentPage}
          size="small"
          onChange={(page, limit) => {
            setCurrentPage(page, limit || 10);
          }}
        />
      </div>
    );
  };

  const renderTitle = () => (
    <h3 className={styles.title}>
      {props.title}
      {props.icon}
    </h3>
  );

  const navigateToSubsite = (record: any) => {
    if (props.basicUrl === links.users) {
      history.push(`${props.basicUrl}/${record.user.id}`);
      return;
    }
    history.push(`${props.basicUrl}/${record.id}`);
  };

  const renderTable = () => {
    const tableProps = {
      columns: props.columns,
      dataSource: state.entries,
      pagination: false,
      footer: renderFooter,
      title: renderTitle,
      scroll: { y: 240 },
      onRow: (record: Record) => ({
        onClick: () => navigateToSubsite(record),
      }),
    };

    return (
      // @ts-ignore
      <Table {...tableProps} />
    );
  };

  useEffect(() => {
    refreshEntries();
  }, []); //eslint-disable-line

  useEffect(() => {
    refreshEntries();
  }, [state.currentPage]); //eslint-disable-line

  return <div>{renderTable()}</div>;
};

export default DashboardTableContainer;
