import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingOrganization } from './billing-service.types';

class BillingOrganizationServiceClass extends EntityService<IBillingOrganization> {
  downloadCSV = async (organization: IBillingOrganization, year?: number, month?: number) => {
    // /api/v1/organizations/{id}/document/csv
    const filename = `${year}-${month}-charging-sessions-${organization.name}.csv`;
    const url = `${this.getEntityUrl()}/${organization.id}/document/monthly-charging-sessions-csv`;
    const params = {
      year,
      month,
    };
    await this.downloadFile(url, params, filename);
  };

  downloadPDFInvoice = async (
    organization: IBillingOrganization,
    year?: number,
    month?: number,
  ) => {
    const filename = `${year}-${month}-invoice-${organization.name}.pdf`;
    const url = `${this.getEntityUrl()}/${organization.id}/document/monthly-invoice-pdf`;
    const params = {
      year,
      month,
    };
    await this.downloadFile(url, params, filename);
  };

  downloadXml = async (organization: IBillingOrganization, year?: number, month?: number) => {
    const filename = `${year}-${month}-bank-payout-transactions-${organization.name}.xml`;
    const url = `${this.getEntityUrl()}/${organization.id}/document/bank-payout-transactions-xml`;
    const params = {
      year,
      month,
    };
    await this.downloadFile(url, params, filename);
  };

  downloadReviewPdfDocument = async (
    organization: IBillingOrganization,
    year?: number,
    month?: number,
  ) => {
    const filename = `${year}-${month}-bank-payout-transactions-pdf-${organization.name}.pdf`;
    const url = `${this.getEntityUrl()}/${
      organization.id
    }/document/bank-payout-transactions-checksum-pdf`;
    const params = {
      year,
      month,
    };
    await this.downloadFile(url, params, filename);
  };

  getDisplayName(entry: IBillingOrganization): string {
    return entry && entry.name;
  }
}

const BillingOrganizationService = new BillingOrganizationServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/organizations',
  detailLink: links.billingOrganization,
});

// this way I can autowire the result (with command + .);
export default BillingOrganizationService;
