import * as React from 'react';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { IShopOrder, ShopOrderStatus } from 'services/shop/shop-service.types';
import { CurrencyUtils } from 'utils/currencyUtils';

import { OrderValidation } from '../../order.validation';

import styles from './OrderTabsStyles.module.sass';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const OrderOverviewTab: React.FC<IGenericEditTabProps<IShopOrder>> = (
  props: IGenericEditTabProps<IShopOrder>,
) => {
  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(OrderValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IShopOrder}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="status" label="Status" required>
              <Select name="status">
                <Select.Option value={ShopOrderStatus.InProgress}>In Progress</Select.Option>
                <Select.Option value={ShopOrderStatus.Pending}>Pending</Select.Option>
                <Select.Option value={ShopOrderStatus.Review}>Review</Select.Option>
                <Select.Option value={ShopOrderStatus.Paid}>Paid</Select.Option>
                <Select.Option value={ShopOrderStatus.Fulfilled}>Fulfilled</Select.Option>
                <Select.Option value={ShopOrderStatus.Cancelled}>Cancelled</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="total" label="Total">
              <div>{entry.total ? CurrencyUtils.defaultFormatter(entry.total / 100) : '-'}</div>
            </Form.Item>
            <Form.Item name="orderPayment" label="Order payment">
              <div>{entry.orderPayment.type ? entry.orderPayment.type : '-'}</div>
            </Form.Item>
            <Form.Item name="installationNeeded" label="Installation needed">
              <div>{entry.installationNeeded ? 'Yes' : 'No'}</div>
            </Form.Item>
            <Form.Item name="installationApproved" label="Installation approved">
              <div>{entry.installationApproved ? 'Yes' : 'No'}</div>
            </Form.Item>

            <div className={styles.centered}>
              <h4> Billing Address</h4>
            </div>
            <Form.Item name="billingAddress.country" label="Country">
              <div> {entry?.billingAddress?.country ? entry?.billingAddress?.country : '-'}</div>
            </Form.Item>
            <Form.Item name="billingAddress.region" label="Region">
              <div> {entry?.billingAddress?.region ? entry?.billingAddress?.region : '-'}</div>
            </Form.Item>
            <Form.Item name="billingAddress.city" label="City">
              <div> {entry?.billingAddress?.city ? entry?.billingAddress?.city : '-'}</div>
            </Form.Item>
            <Form.Item name="billingAddress.address" label="Address">
              <div> {entry?.billingAddress?.address ? entry?.billingAddress?.address : '-'}</div>
            </Form.Item>
            <Form.Item name="billingAddress.postalCode" label="Postal Code">
              <div>
                {' '}
                {entry?.billingAddress?.postalCode ? entry?.billingAddress?.postalCode : '-'}
              </div>
            </Form.Item>
            <div className={styles.centered}>
              <h4>Additional Information for Billing Address </h4>
            </div>
            <Form.Item name="billingAddress.additionalInformation" label="Additional Information">
              <div>
                {' '}
                {entry?.billingAddress?.additionalInformation
                  ? entry?.billingAddress?.additionalInformation
                  : '-'}
              </div>
            </Form.Item>
            <Form.Item name="billingAddress.phone" label="Phone">
              <div> {entry?.billingAddress?.phone ? entry?.billingAddress?.phone : '-'}</div>
            </Form.Item>
            <Form.Item name="billingAddress.email" label="Email">
              <div> {entry?.billingAddress?.email ? entry?.billingAddress?.email : '-'}</div>
            </Form.Item>
            <Form.Item name="billingAddress.firstName" label="First Name">
              <div>
                {' '}
                {entry?.billingAddress?.firstName ? entry?.billingAddress?.firstName : '-'}
              </div>
            </Form.Item>
            <Form.Item name="billingAddress.lastName" label="Last Name">
              <div> {entry?.billingAddress?.lastName ? entry?.billingAddress?.lastName : '-'}</div>
            </Form.Item>

            <div className={styles.centered}>
              <h4>Shipping Address</h4>
            </div>
            <Form.Item name="shippingAddress.country" label="Country">
              <div> {entry?.shippingAddress?.country ? entry?.shippingAddress?.country : '-'}</div>
            </Form.Item>
            <Form.Item name="shippingAddress.region" label="Region">
              <div> {entry?.shippingAddress?.region ? entry?.shippingAddress?.region : '-'}</div>
            </Form.Item>
            <Form.Item name="shippingAddress.city" label="City">
              <div> {entry?.shippingAddress?.city ? entry?.shippingAddress?.city : '-'}</div>
            </Form.Item>
            <Form.Item name="shippingAddress.address" label="Address">
              <div> {entry?.shippingAddress?.address ? entry?.shippingAddress?.address : '-'}</div>
            </Form.Item>
            <Form.Item name="shippingAddress.postalCode" label="Postal Code">
              <div>
                {' '}
                {entry?.shippingAddress?.postalCode ? entry?.shippingAddress?.postalCode : '-'}
              </div>
            </Form.Item>
            <div className={styles.centered}>
              <h4>Additional Information for Shipping Address</h4>
            </div>
            <Form.Item name="shippingAddress.additionalInformation" label="Additional Information">
              <div>
                {' '}
                {entry?.shippingAddress?.additionalInformation
                  ? entry?.shippingAddress?.additionalInformation
                  : '-'}
              </div>
            </Form.Item>
            <Form.Item name="shippingAddress.phone" label="Phone">
              <div> {entry?.shippingAddress?.phone ? entry?.shippingAddress?.phone : '-'}</div>
            </Form.Item>
            <Form.Item name="shippingAddress.email" label="Email">
              <div> {entry?.shippingAddress?.email ? entry?.shippingAddress?.email : '-'}</div>
            </Form.Item>
            <Form.Item name="shippingAddress.firstName" label="First Name">
              <div>
                {' '}
                {entry?.shippingAddress?.firstName ? entry?.shippingAddress?.firstName : '-'}
              </div>
            </Form.Item>
            <Form.Item name="shippingAddress.lastName" label="Last Name">
              <div>
                {' '}
                {entry?.shippingAddress?.lastName ? entry?.shippingAddress?.lastName : '-'}
              </div>
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OrderOverviewTab;
