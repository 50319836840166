import * as Yup from 'yup';

export const INITIAL_VALUES = {
  title: null,
  summary: null,
  logicalName: null,
  solutionSteps: null,
};

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  summary: Yup.string()
    .nullable()
    .max(256, 'Field has not to be longer than 256 characters!')
    .required('Required field'),
  logicalName: Yup.string().nullable().required('Required field'),
  solutionSteps: Yup.array().nullable(),
});
