import * as React from 'react';
import { Table } from 'antd';

import { IAnswerEntry, IClientRequest } from 'store/tendegyService/tendergyService.types';

interface Props {
  clientRequest: IClientRequest;
  answers?: IAnswerEntry[] | undefined;
}

const ClientRequestAnswers: React.FC<Props> = ({ answers }: Props) => {
  if (!answers) {
    return <div> no answers available</div>;
  }

  const columns = [
    {
      title: 'Key',
      dataIndex: 'questionKey',
    },
    {
      title: 'Value',
      dataIndex: 'answerValue',
      render: (field: any) =>
        typeof field.value === 'string' ? field.value : JSON.stringify(field),
    },
  ];

  return <Table dataSource={answers} columns={columns} />;
};

export default ClientRequestAnswers;
