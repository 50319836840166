import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import {
  ITendergyClientRequestPool,
  ITendergyUser,
} from 'store/tendegyService/tendergyService.types';

import { CraftUserValidation } from '../../../users.validation';

interface CraftUserProps {
  entry?: ITendergyUser;
  onSave?: Function;
}

const TendergyUserTab: React.FC<CraftUserProps> = (props: CraftUserProps) => {
  const validation = Yup.object().shape(CraftUserValidation);

  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div>on Save method required</div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  return (
    <div>
      <Formik
        initialValues={entry as any}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values, TendergyUserService).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="address.street" label="Street">
              <Input name="address.street" placeholder="" />
            </Form.Item>
            <Form.Item name="address.houseNumber" label="House Number">
              <Input name="address.houseNumber" placeholder="" />
            </Form.Item>
            <Form.Item name="address.address2" label="Address Line 2">
              <Input name="address.address2" placeholder="" />
            </Form.Item>
            <Form.Item name="address.postalCode" label="Postal Code">
              <Input name="address.postalCode" placeholder="" />
            </Form.Item>
            <Form.Item name="address.city" label="City">
              <Input name="address.city" placeholder="" />
            </Form.Item>
            <Form.Item name="currentPool" label="Client Request Pool">
              <EntitySelector
                onSelect={(pool: ITendergyClientRequestPool) => {
                  setFieldValue('currentPool', pool || null);
                }}
                entityService={ClientRequestPoolService}
                name="currentPool"
                current={values.currentPool || null}
                allowClear
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TendergyUserTab;
