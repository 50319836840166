import * as React from 'react';
import { Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

import { IAnswerEntry } from 'store/tendegyService/tendergyService.types';
import { formatDataKey } from 'utils/string.helper';

interface Props {
  answers?: IAnswerEntry[];
}

const QATab: React.FC<Props> = ({ answers }: Props) => {
  const columns = [
    {
      title: 'Key',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      dataIndex: 'questionKey',
      render: (value: string) => formatDataKey(value),
    },
    {
      title: 'Value',
      dataIndex: 'answerValue',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      render: (field: any) =>
        typeof field.value === 'string' ? field.value : JSON.stringify(field),
    },
  ];

  return <Table dataSource={answers} columns={columns} />;
};

export default QATab;
