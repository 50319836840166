import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

// @ts-ignore
const configureStore = (preloadedState) =>
  createStore(rootReducer, preloadedState, applyMiddleware(thunk, sagaMiddleware));

const store = configureStore({});
sagaMiddleware.run(rootSaga);

// @ts-ignore
export default store;
