import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';

import 'bootstrap/dist/css/bootstrap.min.css';
import './FormBuilder.modules.sass';

export interface FormBuilderProps {
  questions: Array<any>;
  selected: any;
  onSelect: Function;
  onAdd: Function;
}

const QuestionsSidebar: React.FC<FormBuilderProps> = (props: FormBuilderProps) => {
  const { questions, selected, onSelect, onAdd } = props;

  const questionTypes = [
    { key: 'radio', title: 'Radio question' },
    { key: 'singleInput', title: 'Single input question' },
    { key: 'multipleInput', title: 'Multiple input question' },
    { key: 'radioWithCustomInput', title: 'Radio with custom input' },
    { key: 'chargingStation', title: 'Charging question' },
    { key: 'surface', title: 'Surface question' },
  ];

  const questionMenuData = questions
    ? questions.map((question: any) => {
        return {
          key: question.scope,
          title: question.scope.replace('#/properties/', ''),
          type: question.options.format,
        };
      })
    : [];

  const menu = (
    <Menu onClick={(e) => onAdd(e.key)}>
      {questionTypes.map((item) => (
        <Menu.Item key={item.key}>{item.title}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      {questionMenuData && (
        <Menu
          onClick={(item) => onSelect(item.key)}
          selectedKeys={selected ? [selected.scope] : []}
          mode="inline"
        >
          {questionMenuData.map((item: any) => (
            <Menu.Item key={item.key}>{item.title}</Menu.Item>
          ))}
          <div style={{ marginLeft: '5px' }}>
            <Dropdown overlay={menu}>
              <Button>
                Add question <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </Menu>
      )}
    </div>
  );
};

export default QuestionsSidebar;
