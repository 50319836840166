import * as React from 'react';
import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { ICountriesDetail } from 'services/consulant/consultant-service.types';
import { formatDefaultDate } from 'utils/date.helper';

import { validationSchema } from './validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */

interface Props {
  entry?: ICountriesDetail | null;
  onSave: (updatedValues: ICountriesDetail) => Promise<any>;
}

const FleetTypeDetailTab: React.FC<Props> = (props: Props) => {
  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }
  if (!entry) {
    return <div> Loading </div>;
  }
  return (
    <div>
      {entry && (
        <Formik
          initialValues={entry as ICountriesDetail}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSave(values as ICountriesDetail).then(() => setSubmitting(false));
          }}
        >
          {({ handleSubmit, isSubmitting }) => {
            return (
              <>
                <Row>
                  <Col xs={6}>Created At: {formatDefaultDate(entry?.createdAt)}</Col>
                  <Col xs={6}>Updated At: {formatDefaultDate(entry?.updatedAt)}</Col>
                </Row>

                <Divider />
                <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
                  <Form.Item name="order" label="Order">
                    <Input name="order" placeholder="" />
                  </Form.Item>
                  <Form.Item name="description" label="Description">
                    <Input name="description" placeholder="" />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="Name"
                    help="This value use as key in Front-End. After changing inform dev team."
                  >
                    <Input name="name" placeholder="" />
                  </Form.Item>

                  <Form.Item {...CrudFormDefaultActionParams} name="submit">
                    <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                      Submit
                    </SubmitButton>
                  </Form.Item>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default FleetTypeDetailTab;
