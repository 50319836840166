import config from 'config';

import { AUTH_TOKEN_KEY } from 'framework/constants';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingInvoice } from './billing-service.types';

class BillingInvoiceServiceClass extends EntityService<IBillingInvoice> {
  getDisplayName(entry: IBillingInvoice): string {
    return entry.toString();
  }

  downloadInvoice(entry: IBillingInvoice) {
    // /api/v1/admin/documents/download/{id}?token=
    const url = this.getURL(`admin/documents/download/${entry.id}`);
    this.downloadFile(url, {}, 'invoice.zip');
  }

  downloadZip = (entry: IBillingInvoice) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);

    window.open(`${this.getEntityUrl()}/${entry.id}/download/zip?token=${accessToken}`);
  };

  cancelInvoice = (entry: IBillingInvoice) => {
    const url = `${this.config.entityURL}/${entry.id}/cancel`;
    this.post(url);
  };
}

const BillingInvoiceService = new BillingInvoiceServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/invoices',
  detailLink: links.billingInvoice,
});

// this way I can autowire the result (with command + .);
export default BillingInvoiceService;
