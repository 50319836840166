import * as Yup from 'yup';

import { EnumISAPrefferedContact } from 'services/isa-service/isa-service.types';

export const INITIAL_VALUES = {
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  preferredContact: null,
  address: null,
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  lastName: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  email: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  phone: Yup.string().nullable().required('Required field'),
  preferredContact: Yup.mixed<EnumISAPrefferedContact>()
    .nullable()
    .oneOf(Object.values(EnumISAPrefferedContact), 'Required')
    .required('Required'),
  address: Yup.object().nullable(),
});
