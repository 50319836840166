import config from 'config';

import { ISA_CAR_MODELS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISACarModel } from '../isa-service.types';

const ISACarModelsService = new EntityService<IISACarModel>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_CAR_MODELS_ENDPOINT,
  createLink: links.isaCarModelsCreate,
  detailLink: links.isaCarModelsDetails,
});

ISACarModelsService.getDisplayName = (entry) => entry.name || entry.logicalName;

export default ISACarModelsService;
