import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Radio, Select, Spin } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Input, InputNumber, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import {
  IBillingChargingStation,
  IBillingEnergyPrice,
  IOpt,
} from 'services/billing-v3/billing-service.types';

interface IProps {
  entry?: IBillingEnergyPrice;
  validation: any;
  onSave?: (values: any) => Promise<any>;
  clickDocument?: (id: string) => void;
  disabledList?: any[];
  fetching?: boolean;
  setQuery?: (value: string) => void;
  pointGroups?: IBillingChargingStation[];
  statusOptions: IOpt[];
  evseId?: string;
  createEnergyPrice?: any;
}

const EnergyPriceDetailForm = ({
  entry,
  validation,
  onSave,
  createEnergyPrice,
  clickDocument,
  disabledList,
  fetching,
  setQuery,
  pointGroups,
  evseId,
  statusOptions,
}: IProps) => {
  const { Option } = Select;
  const { id } = useParams<{ id: string }>();
  const handleCheckDisablility = (value: keyof IBillingEnergyPrice) => {
    if (disabledList?.length) {
      return disabledList.includes(value);
    }
    return false;
  };
  return (
    <Formik
      initialValues={entry as IBillingEnergyPrice}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        onSave?.({ ...values }).then(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, isSubmitting, values, handleChange, errors, setFieldValue }) => {
        return (
          <>
            <Col span={12}>
              <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
                <Form.Item
                  name="chargePerUnit"
                  label="chargePerUnit"
                  help={errors.chargePerUnit}
                  validateStatus={errors.chargePerUnit ? 'error' : ''}
                >
                  <InputNumber
                    name="chargePerUnit"
                    min={0}
                    placeholder="1"
                    disabled={handleCheckDisablility('chargePerUnit')}
                  />
                </Form.Item>
                <Form.Item
                  name="payoutPerUnit"
                  label="payoutPerUnit"
                  help={errors.payoutPerUnit}
                  validateStatus={errors.payoutPerUnit ? 'error' : ''}
                >
                  <InputNumber
                    disabled={handleCheckDisablility('payoutPerUnit')}
                    name="payoutPerUnit"
                    value={createEnergyPrice?.payoutPerUnit}
                  />
                </Form.Item>

                <Form.Item
                  name="chargingPointId"
                  label="Charging station"
                  help={errors.chargingPointId || 'EVSE ID - StationID'}
                  validateStatus={errors.chargingPointId ? 'error' : ''}
                >
                  {id ? (
                    <Input name="chargingPointId" defaultValue={evseId} disabled />
                  ) : (
                    <>
                      <Select
                        showSearch
                        disabled={handleCheckDisablility('chargingPoint')}
                        labelInValue
                        onChange={(e: any) => setFieldValue('chargingPoint', e.value)}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        onSearch={setQuery}
                        filterOption={(input, option) =>
                          ((option!.children as unknown) as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {pointGroups?.map(({ id, connectorName, stationId }) => {
                          return (
                            <Option value={id!} key={id}>
                              {`${connectorName} - ${stationId}`}
                            </Option>
                          );
                        })}
                      </Select>
                      {errors.chargingPoint && (
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {errors.chargingPoint}
                        </div>
                      )}
                    </>
                  )}
                </Form.Item>
                <Form.Item
                  name="status"
                  label="Status"
                  help={errors.status}
                  validateStatus={errors.status ? 'error' : ''}
                >
                  <Radio.Group
                    defaultValue={values?.status}
                    name={`status`}
                    options={statusOptions}
                    onChange={handleChange}
                    disabled={handleCheckDisablility('status')}
                  />
                </Form.Item>

                <Form.Item
                  name="validFrom"
                  label="validFrom"
                  help={errors.validFrom}
                  validateStatus={errors.validFrom ? 'error' : ''}
                >
                  <DatePicker name="validFrom" disabled={handleCheckDisablility('validFrom')} />
                </Form.Item>

                <Form.Item {...CrudFormDefaultActionParams} name="submit">
                  <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                    Submit
                  </SubmitButton>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <Form.Item
                name="comment"
                label="Comment"
                help={errors.comment}
                validateStatus={errors.comment ? 'error' : ''}
              >
                <Input
                  placeholder="Comment"
                  disabled={handleCheckDisablility('comment')}
                  type="text"
                  name="comment"
                />
              </Form.Item>
              <Form.Item
                name="documents"
                label="Documents"
                help={errors.documents}
                validateStatus={errors.documents ? 'error' : ''}
              >
                {entry?.documents?.map(({ mediaId }, index) => (
                  <Button
                    key={mediaId}
                    style={{ margin: '8px' }}
                    onClick={() => clickDocument?.(mediaId)}
                    color="primary"
                    disabled={handleCheckDisablility('documents')}
                  >{`Document ${index + 1}`}</Button>
                ))}
              </Form.Item>
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
export default EnergyPriceDetailForm;
