import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import CountryService from 'services/tendergy/tendergy-country.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import CountryBaseStep from './steps/CountryBaseStep';

const CountryCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add country',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <CountryBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Comment"
      steps={steps}
      baseLink={links.countries}
      defaultPayload={params}
      resource={CountryService}
    />
  );
};

export default CountryCreatePage;
