import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { ITendergyLead, LeadState } from 'store/tendegyService/tendergyService.types';

import { LeadValidation } from '../../leads.validation';

interface Props {
  entry: ITendergyLead;
  onSubmitStep: (entry: ITendergyLead) => void;
}

const LeadBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(LeadValidation);

  return (
    <div>
      <Formik
        initialValues={entry as ITendergyLead}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="leadType" label="Lead Type">
              <Input name="leadType" placeholder="" />
            </Form.Item>
            <Form.Item name="state" label="State">
              <Select name="state">
                <Select.Option value={LeadState.NEW}>New</Select.Option>
                <Select.Option value={LeadState.CONTACTED}>Contacted</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="companyName" label="Company Name">
              <Input name="companyName" />
            </Form.Item>

            <Form.Item name="numberOfCars" label="Number Of Cars">
              <Select name="numberOfCars">
                {['<11', '11-50', '51-100', '>100'].map((option, index) => (
                  <Select.Option value={option} key={index}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="contactPersonName" label="Contact Person Name">
              <Input name="contactPersonName" />
            </Form.Item>

            <Form.Item name="contactPersonEmail" label="Contact Person Email">
              <Input name="contactPersonEmail" />
            </Form.Item>

            <Form.Item name="initiatorName" label="Initiator Name">
              <Input name="initiatorName" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LeadBaseStep;
