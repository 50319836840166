import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISACarBrandsService } from 'services/isa-service';

import ISACarBrandsForm from './components/ISACarBrandsForm';

export default function ISACarBrandsCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Car Brand',
          render: (_entry, onSubmit) => <ISACarBrandsForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="car brand"
      baseLink={links.isaCarBrandsOverview}
      resource={ISACarBrandsService}
    />
  );
}
