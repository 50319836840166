import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import config from './config';
import * as serviceWorker from './serviceWorker';
// @ts-ignore
import store from './store/configureStore';

import 'assets/styles/global.sass';
import 'antd/dist/antd.css';

Sentry.init({
  dsn: config.SENTRY_DSN_URL,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [config.AUTH_SERVICE_URL],
    }),
  ],
  environment: config.SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
