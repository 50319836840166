import React from 'react';
import { Card, Col, message, Row } from 'antd';

import { IClientRequest } from 'store/tendegyService/tendergyService.types';

import RequestAddressComponent from '../../components/RequestAddressComponent';
import RequestDetailsComponent from '../../components/RequestDetailsComponent';

import styles from './styles.module.sass';

interface ClientRequestFormProps {
  entry?: IClientRequest;
  onSubmit: (entry: IClientRequest, onSuccess?: () => void) => Promise<IClientRequest>;
}

const ClientRequestOverviewTab = (props: ClientRequestFormProps) => {
  const { entry } = props;

  if (!entry) return <div>No data</div>;

  return (
    <div>
      <Row justify="space-between">
        <Col span={24}>
          <Card>
            <RequestDetailsComponent
              entry={entry}
              onSubmit={(values) =>
                props.onSubmit(values, () => message.success('Client request updated successfully'))
              }
            />
          </Card>
        </Col>
      </Row>
      {entry?.address && (
        <Row className={styles.rowMargin}>
          <Col span={24}>
            <Card>
              <RequestAddressComponent entry={entry} />
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ClientRequestOverviewTab;
