import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergyCountry } from 'store/tendegyService/tendergyService.types';

class CountryServiceClass extends EntityService<ITendergyCountry> {
  getDisplayName(entry: ITendergyCountry): string {
    return entry && entry.iso31661Alpha3;
  }
}

const CountryService = new CountryServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/countries',
  detailLink: links.country,
  createLink: links.createCountry,
  entityPrimaryKey: 'iso31661Alpha3',
});

export default CountryService;
