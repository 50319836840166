import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ClientRequestState } from 'framework/constants';
import TendergyRequestService from 'services/tendergy/tendergy-request.service';
import {
  IClientRequest,
  ITendergyClientRequestPool,
  ITendergyUser,
} from 'store/tendegyService/tendergyService.types';
import { CurrencyUtils } from 'utils/currencyUtils';
import { formatDefaultDate } from 'utils/date.helper';

import ClientRequestFilters from './components/ClientRequestsFilters';

const columns = [
  {
    title: 'No.',
    dataIndex: 'displayId',
    key: 'displayId',
    sorter: true,
    defaultSortOrder: 'descend' as SortOrder,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
  },
  {
    title: 'Client',
    dataIndex: 'owner',
    render: (owner: ITendergyUser) => (owner ? `${owner.firstName} ${owner.lastName}` : '-'),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Location',
    dataIndex: 'address',
    render: (address: any) => (address ? `${address.postalCode} ${address.city}` : 'n/a'),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Pool',
    dataIndex: 'pool',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (pool: ITendergyClientRequestPool) => (pool ? `${pool.name}` : 'n/a'),
  },
  {
    title: 'Price Range',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (row: IClientRequest) =>
      `${CurrencyUtils.defaultFormatter(row.costEstimateMin)} - 
      ${CurrencyUtils.defaultFormatter(row.costEstimateMax)}`,
  },
  {
    title: 'State',
    dataIndex: 'state',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (value: ClientRequestState) => value || '–',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date: string) => formatDefaultDate(date),
  },
];

const ClientRequestsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Client Requests"
    columns={columns}
    resource={TendergyRequestService}
    customFilters={ClientRequestFilters}
    defaultPayload={{ sortFields: 'displayId' }}
  />
);
export default ClientRequestsOverviewPage;
