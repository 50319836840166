import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IShopUser } from './shop-service.types';

class ShopUsersServiceServiceClass extends EntityService<IShopUser> {
  getDisplayName(entry: IShopUser): string {
    return entry && entry.email;
  }
}

const ShopUsersService = new ShopUsersServiceServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/users',
  detailLink: links.shopUser,
});

export default ShopUsersService;
