import * as React from 'react';
import { useState } from 'react';
import CodeMirror from 'react-codemirror';
import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import js_beautify from 'js-beautify';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { CrudFormDefaultActionParams } from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import EmailLayoutService from 'services/email/email-layouts.service';
import EmailRenderingService from 'services/email/email-rendering.service';
import { IEmailLayout, IEmailTemplate } from 'services/email/email-service.types';
import { showErrorNotification } from 'utils/notificationUtil';

import { TemplateValidation } from '../../template.validation';

import 'codemirror/mode/htmlmixed/htmlmixed';

import 'codemirror/theme/material.css';
import 'codemirror/lib/codemirror.css';
import styles from './TemplateTabsStyles.module.sass';

const TemplateOverviewTab: React.FC<IGenericEditTabProps<IEmailTemplate>> = (
  props: IGenericEditTabProps<IEmailTemplate>,
) => {
  const { entry, onSave } = props;
  const [body, setBody] = useState<string>(js_beautify.html_beautify(entry.body) || '');
  const [layoutId, setLayout] = useState<string>('');
  const [preview, setPreview] = useState<string>('');

  const onPreview = async () => {
    try {
      const response = await EmailRenderingService.post(
        'admin/render/mjml',
        { layoutId, template: entry.body },
        true,
      );
      setPreview(response.html);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(TemplateValidation);
  const options = {
    lineNumbers: true,
    mode: { name: 'htmlmixed' },
    theme: 'material',

    tags: {
      style: [
        ['type', /^text\/(x-)?scss$/, 'text/x-scss'],
        [null, null, 'css'],
      ],
      custom: [[null, null, 'customMode']],
    },
  };
  return (
    <Formik
      initialValues={entry as IEmailTemplate}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        onSave(values).then(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleSubmit}
          style={{ position: 'relative' }}
        >
          <Form.Item name="language" label="Language" required>
            <Select name="language">
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="de">German</Select.Option>
              <Select.Option value="fr">French</Select.Option>
              <Select.Option value="it">Italy</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="name" label="Name" required>
            <Input name="name" />
          </Form.Item>

          <Form.Item name="category" label="Category" required>
            <Input name="category" />
          </Form.Item>

          <Form.Item name="subject" label="Subject" required>
            <Input name="subject" />
          </Form.Item>
          <div className={styles.templateTitleWrapper}>
            <h3>Template Preview</h3>
          </div>
          <Form.Item name="layout" required>
            <div className={styles.codeWrapper}>Layout:</div>
            <EntitySelector
              entityService={EmailLayoutService}
              name="layout"
              onSelect={(layout: IEmailLayout) => {
                // @ts-ignore
                setLayout(layout.id);
              }}
            />
          </Form.Item>
          <Form.Item name="body" required className={styles.codeSection}>
            <div className={styles.codeWrapper}>Body:</div>
            <CodeMirror
              value={body}
              onChange={(value) => {
                setFieldValue('body', value);
                setBody(value);
              }}
              options={options}
              className={styles.codeMirror}
            />
          </Form.Item>
          <Button onClick={onPreview} className={styles.buttonWrapper} disabled={!layoutId}>
            Preview
          </Button>
          {preview.length ? (
            <div className={styles.previewWrapper} dangerouslySetInnerHTML={{ __html: preview }} />
          ) : null}

          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton style={{ marginLeft: '45px' }} className={styles.submitBtn}>
              Submit
            </SubmitButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default TemplateOverviewTab;
