import * as React from 'react';

import { FormInput, FormTimePicker } from 'components/forms';

export default function ContactInformationBlock() {
  return (
    <>
      <h4>Contact information</h4>
      <FormInput name="serviceEmail" label="Contact Email" />
      <FormInput name="servicePhone" label="Service Phone" />
      <FormInput name="workingWeekStart" label="Service working week start" type="number" />
      <FormInput name="workingWeekEnd" label="Service working week end" type="number" />
      <FormTimePicker name="workingHoursStart" label="Service working hours from" />
      <FormTimePicker name="workingHoursEnd" label="Service working hours to" />
    </>
  );
}
