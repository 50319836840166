import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import EmailActivityLogsServiceService from 'services/email/email-activity-logs.service';
import { IEmailActivityLog } from 'services/email/email-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ActivityLogOverviewTab from './tabs/ActivityLogOverviewTab';

const ActivityLogsDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IEmailActivityLog) => <ActivityLogOverviewTab entry={entity} />,
    },
  ];

  return (
    <CrudDetailContainer
      entityName="EmailActivityLog"
      tabs={tabs}
      baseLink={links.emailActivityLog}
      defaultPayload={params}
      resource={EmailActivityLogsServiceService}
    />
  );
};

export default ActivityLogsDetailsPage;
