import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IAuthOrganization } from 'store/authService/authService.types';
import { OrganizationTypes } from 'store/tendegyService/tendergyService.types';

import { AuthOrganizationValidation } from '../../organizations.validation';

interface ICreateOrganizationStep {
  entry: IAuthOrganization;
  onSubmitStep: (entry: IAuthOrganization) => void;
  saveNewOrganization: Function;
}

const OrganizationStep: React.FC<ICreateOrganizationStep> = ({
  entry,
  onSubmitStep,
  saveNewOrganization,
}: ICreateOrganizationStep) => {
  const validation = Yup.object().shape(AuthOrganizationValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IAuthOrganization}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, values, errors }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name">
              <Input name="name" placeholder="" />
            </Form.Item>

            <Form.Item name="type" label="Type">
              <Select name="type" defaultValue={OrganizationTypes.Installer}>
                <Select.Option value={OrganizationTypes.Installer}>
                  Installer Organization
                </Select.Option>
                <Select.Option value={OrganizationTypes.Intermediate}>Intermediate</Select.Option>
              </Select>
            </Form.Item>
            {/* Adding members not working on BE right now */}
            {/* <Form.Item name="members" label="Users"> */}
            {/*  <EntitySelector */}
            {/*    current={values.users} */}
            {/*    onSelect={(users) => { */}
            {/*      setFieldValue('members', users); */}
            {/*    }} */}
            {/*    entityService={UserAuthService} */}
            {/*    mode="multiple" */}
            {/*    name="members" */}
            {/*  ></EntitySelector> */}
            {/* </Form.Item> */}
            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                onClick={() => saveNewOrganization(values)}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OrganizationStep;
