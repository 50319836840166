import { ColumnsType } from 'antd/lib/table';

import { IConsultantParameters } from 'services/consulant/consultant-service.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IConsultantParameters> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Language',
    dataIndex: 'language',
    key: 'language',
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Currency Sign',
    dataIndex: 'currencySign',
    key: 'currencySign',
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (date: string) => formatDefaultDate(date),
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'created',
    render: (date: string) => formatDefaultDate(date),
  },
];

export default columns;
