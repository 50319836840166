import links from 'framework/links';
import CarModelCreatePage from 'pages/shop/car-models/create/CarModelCreatePage';
import CarModelDetailsPage from 'pages/shop/car-models/details/CarModelDetailsPage';
import CarModelsOverviewPage from 'pages/shop/car-models/overview/CarModelsOverviewPage';
import CategoryCreatePage from 'pages/shop/categories/create/CategoryCreatePage';
import CategoryDetailsPage from 'pages/shop/categories/details/CategoryDetailsPage';
import CategoriesOverviewPage from 'pages/shop/categories/overview/CategoriesOverviewPage';
import LandingCreatePage from 'pages/shop/landings/create/LandingCreatePage';
import LandingDetailsPage from 'pages/shop/landings/details/LandingDetailsPage';
import OrderDetailsPage from 'pages/shop/orders/details/OrderDetailsPage';
import OrdersOverviewPage from 'pages/shop/orders/overview/OrdersOverviewPage';
import ProductAttributeCreatePage from 'pages/shop/product-attributes/create/ProductAttributeCreatePage';
import ProductAttributeDetailsPage from 'pages/shop/product-attributes/details/ProductAttributeDetailsPage';
import ProductAttributesOverviewPage from 'pages/shop/product-attributes/overview/ProductAttributesOverviewPage';
import ProductAttributeOptionCreatePage from 'pages/shop/product-options/create/ProductAttributeOptionCreatePage';
import ProductAttributeOptionDetailsPage from 'pages/shop/product-options/details/ProductAttributeOptionDetailsPage';
import ProductAttributeOptionsOverviewPage from 'pages/shop/product-options/overview/ProductAttributeOptionsOverviewPage';
import ProductCreatePage from 'pages/shop/products/create/ProductCreatePage';
import ProductDetailsPage from 'pages/shop/products/details/ProductDetailsPage';
import ProductsOverviewPage from 'pages/shop/products/overview/ProductsOverviewPage';
import ShopUserDetailsPage from 'pages/shop/shop-users/details/ShopUserDetailsPage';
import ShopUsersOverviewPage from 'pages/shop/shop-users/overview/ShopUsersOverviewPage';
import StoreEmailCreatePage from 'pages/shop/store-emails/create/StoreEmailCreatePage';
import StoreEmailDetailsPage from 'pages/shop/store-emails/details/StoreEmailDetailsPage';
import StoreEmailsOverviewPage from 'pages/shop/store-emails/overview/StoreEmailsOverviewPage';
import StoreCreatePage from 'pages/shop/stores/create/StoreCreatePage';
import StoreDetailsPage from 'pages/shop/stores/details/StoreDetailsPage';
import StoresOverviewPage from 'pages/shop/stores/overview/StoresOverviewPage';
import CreateIntermediatePage from 'pages/tendergy/intermediates/create/CreateIntermediatePage';
import IntermediatesOverviewPage from 'pages/tendergy/intermediates/overview/IntermediatesOverviewPage';

export const SHOP_ROUTES = [
  { path: links.shopStores, component: StoresOverviewPage },
  { path: links.shopStore, component: StoreDetailsPage },
  { path: links.shopCreateStore, component: StoreCreatePage },
  { path: links.shopCategories, component: CategoriesOverviewPage },
  { path: links.shopCategory, component: CategoryDetailsPage },
  { path: links.shopCreateCategory, component: CategoryCreatePage },
  { path: links.shopProducts, component: ProductsOverviewPage },
  { path: links.shopProduct, component: ProductDetailsPage },
  { path: links.shopCreateProduct, component: ProductCreatePage },
  { path: links.shopProductAttributeOptions, component: ProductAttributeOptionsOverviewPage },
  { path: links.shopProductAttributeOption, component: ProductAttributeOptionDetailsPage },
  { path: links.shopCreateProductAttributeOption, component: ProductAttributeOptionCreatePage },
  { path: links.shopProductAttributes, component: ProductAttributesOverviewPage },
  { path: links.shopProductAttribute, component: ProductAttributeDetailsPage },
  { path: links.shopCreateProductAttribute, component: ProductAttributeCreatePage },
  { path: links.intermediates, component: IntermediatesOverviewPage },
  { path: links.createIntermediate, component: CreateIntermediatePage },
  { path: links.shopCarModels, component: CarModelsOverviewPage },
  { path: links.shopCarModel, component: CarModelDetailsPage },
  { path: links.shopCreateCarModel, component: CarModelCreatePage },
  { path: links.shopUsers, component: ShopUsersOverviewPage },
  { path: links.shopUser, component: ShopUserDetailsPage },
  { path: links.shopOrders, component: OrdersOverviewPage },
  { path: links.shopOrder, component: OrderDetailsPage },
  { path: links.shopStoreEmails, component: StoreEmailsOverviewPage },
  { path: links.shopStoreEmail, component: StoreEmailDetailsPage },
  { path: links.shopCreateStoreEmail, component: StoreEmailCreatePage },
  { path: links.shopCreateLanding, component: LandingCreatePage },
  { path: links.shopLanding, component: LandingDetailsPage },
];
