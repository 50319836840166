import * as React from 'react';
import { Col, Divider, InputNumber, Radio, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { ICurrentFleet } from 'services/consulant/consultant-service.types';
import { formatDefaultDate } from 'utils/date.helper';

import { validationSchema } from '../leads/validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
interface IOpt {
  label: string;
  value: string;
}

interface Props {
  entry?: ICurrentFleet | null;
  onSave: (updatedValues: ICurrentFleet) => Promise<any>;
}

export enum EAcquiredCost {
  Lease = 'lease',
  Purchase = 'purchase',
}
export enum TimespanType {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}

const MapEnums = (Enum: any) =>
  Object.keys(Enum).reduce((acc: IOpt[], field) => {
    const value = Enum[field];
    return [...acc, { label: field, value }];
  }, []);

const CurrentFleetTab: React.FC<Props> = (props: Props) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }
  if (!entry) {
    return <div> Loading </div>;
  }

  return (
    <div>
      <Formik
        initialValues={entry as ICurrentFleet}
        validationSchema={validationSchema}
        onSubmit={() => {
          // onSave({currentFleet:values}).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, values, handleChange }) => {
          return (
            <>
              <Row>
                <Col xs={6}>Created At: {formatDefaultDate(entry.createdAt)}</Col>
                <Col xs={6}>Updated At: {formatDefaultDate(entry.updatedAt)}</Col>
              </Row>
              <Divider />
              <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
                <Form.Item name="avgMileage" label="Average Mileage">
                  <Input name="avgMileage" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item name="bevCount" label="Number of electric vehicles">
                  <Input name="bevCount" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item
                  name="avgFleetCosts"
                  label="Average Fleet Cost"
                  help="-1 means don't know"
                >
                  <Input name="avgFleetCosts" disabled placeholder="" />
                </Form.Item>
                <Form.Item
                  name="avgTotalFuelCosts"
                  label="Average Total Fuel Costs"
                  help="-1 means don't know"
                >
                  <Input name="avgTotalFuelCosts" disabled placeholder="" />
                </Form.Item>
                <Form.Item
                  name="avgTotalMaintenanceCosts"
                  label="Average Total Maintenance Costs"
                  help="-1 means don't know"
                >
                  <Input name="avgTotalMaintenanceCosts" disabled placeholder="" />
                </Form.Item>
                <Form.Item name="fleetAcquiringType" label="Fleet Acquiring Type ">
                  <Radio.Group
                    disabled
                    defaultValue={values.fleetAcquiringType}
                    name={`fleetAcquiringType`}
                    options={MapEnums(EAcquiredCost)}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="maxMileageTimespan" label="Max Mileage Timespan ">
                  <Radio.Group
                    disabled
                    defaultValue={values.maxMileageTimespan}
                    name={`maxMileageTimespan`}
                    options={MapEnums(TimespanType)}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="operatingDaysTimespan" label="Operating Days Timespan">
                  <Radio.Group
                    disabled
                    defaultValue={values.operatingDaysTimespan}
                    name={`operatingDaysTimespan`}
                    options={MapEnums(TimespanType)}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item name="avgMileageTimespan" label="Average Mileage Timespan ">
                  <Radio.Group
                    disabled
                    defaultValue={values.avgMileageTimespan}
                    name={`avgMileageTimespan`}
                    options={MapEnums(TimespanType)}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item name="maxMileage" label="max Mileage">
                  <Input name="maxMileage" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item name="iceCount" label="ICE Count">
                  <Input name="iceCount" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item name="otherCount" label="Other Count">
                  <Input name="otherCount" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item name="parkedHoursHome" label="Parked Hours Home">
                  <Input name="parkedHoursHome" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item name="parkedHoursPublic" label="Parked Hours Public">
                  <Input name="parkedHoursPublic" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item name="parkedHoursWork" label="Parked Hours Work">
                  <Input name="parkedHoursWork" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item name="totalCount" label="Total Count">
                  <Input name="totalCount" disabled type="number" placeholder="" />
                </Form.Item>
                <Form.Item name="operatingDays" label="Operating Days">
                  <InputNumber
                    width="100%"
                    disabled
                    defaultValue={values.operatingDays}
                    name="operatingDays"
                    min={1}
                    max={7}
                  />
                </Form.Item>
                <Form.Item {...CrudFormDefaultActionParams} name="submit">
                  <SubmitButton type="primary" htmlType="submit" disabled>
                    Submit
                  </SubmitButton>
                </Form.Item>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default CurrentFleetTab;
