import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import StoreEmailsService from 'services/shop/shop-store-emails.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import StoreEmailForm from '../components/StoreEmailForm';

const StoreEmailCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add store email',
      description: 'required information',
      render: (entry, onSubmitStep) => <StoreEmailForm entry={entry} onSave={onSubmitStep} />,
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Store Email"
      steps={steps}
      baseLink={links.shopStoreEmails}
      defaultPayload={params}
      resource={StoreEmailsService}
    />
  );
};

export default StoreEmailCreatePage;
