import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons/lib';
import { useField } from 'formik';
import { hex, score } from 'wcag-contrast';

import { IStoreCustomizationScheme } from 'services/shop/shop-service.types';

import { ColorPickerField } from '../ColorPicker/ColorPicker';

import styles from './ColorPaletteStyles.module.sass';

interface IColorPalette {
  scheme: IStoreCustomizationScheme;
  label: string;
}

interface IColorsRow {
  title: string;
  name: string;
  colours?: any;
}

// const getPickerColours = (scheme: IStoreCustomizationScheme) => {
//   const primaryColours: string[] = [];
//   const inverseColours: string[] = [];
//
//   // eslint-disable-next-line array-callback-return
//   colorsRows.map(({ name }) => {
//     // @ts-ignore
//     primaryColours.push(scheme[name].primaryValue);
//     // @ts-ignore
//     inverseColours.push(scheme[name].inverseValue);
//   });
//   return [...primaryColours, ...inverseColours];
// };

const ColorsRow: React.FC<IColorsRow> = (props: IColorsRow) => {
  const { title, name } = props;
  const colorName = `${name}.primaryValue`;
  const inverseColorName = `${name}.inverseValue`;
  const [{ value: color }] = useField(colorName);
  const [{ value: colorInverse }] = useField(inverseColorName);
  return (
    <div role="rowgroup" className={styles.colorSectionRow}>
      <div role="cell" className={styles.colorSectionCell}>
        <span>{title}</span>
      </div>
      <div role="cell" className={styles.colorSectionCell}>
        <ColorPickerField name={colorName} />
      </div>
      <div role="cell" className={styles.colorSectionCell}>
        <ColorPickerField name={inverseColorName} />
      </div>
      <div role="cell" className={styles.colorSectionCell}>
        {['AAA', 'AA'].includes(score(hex(color, colorInverse))) ? (
          <div className={styles.check}>
            <CheckOutlined />
          </div>
        ) : (
          <div className={styles.cross}>
            <CloseOutlined />
          </div>
        )}
      </div>
    </div>
  );
};

export const ColorPalette: React.FC<IColorPalette> = (props: IColorPalette) => {
  const { label, scheme } = props;

  // const pickerColours = getPickerColours(scheme);
  return (
    <div className={styles.themePalette}>
      <div className={styles.title}>{label.toUpperCase()}</div>
      <div role="table" className={styles.colorSectionTable}>
        <div role="rowgroup" className={styles.colorSectionRow}>
          <div role="cell" className={styles.colorSectionCell} />
          <div role="cell" className={styles.colorSectionCell}>
            <span>Primary</span>
          </div>
          <div role="cell" className={styles.colorSectionCell}>
            <span>Inverse</span>
          </div>
          <div role="cell" className={styles.colorSectionCell}>
            <span>Contrast</span>
          </div>
        </div>
        {Object.keys(scheme).map((key) => (
          <ColorsRow key={key} title={scheme[key].title} name={key} />
        ))}
      </div>
    </div>
  );
};
