import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ISAUsersService } from 'services/isa-service';
import { formatDefaultDate } from 'utils/date.helper';

export default function ISAUsersOverview() {
  return (
    <CrudListViewContainer
      columns={[
        { title: 'User email', dataIndex: 'email' },
        {
          title: 'User name',
          dataIndex: 'firstName',
          render: (_value, entry) => `${entry.firstName} ${entry.lastName}`,
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          render: (date: string) => formatDefaultDate(date),
        },
      ]}
      entityName="users"
      resource={ISAUsersService}
      withSorting
    />
  );
}
