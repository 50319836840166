import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingEnergyPrice } from './billing-service.types';

class BillingEnergyPricingServiceClass extends EntityService<IBillingEnergyPrice> {}

const BillingEnergyPricingService = new BillingEnergyPricingServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/energy-prices',
  detailLink: links.billV3EnergyPrice,
  createLink: links.billV3CEnergyPrice,
});
export const IBillingV3GetDocuments = new BillingEnergyPricingServiceClass({
  apiURL: config.FLEETAPP_SERVICE_URL,
  entityURL: 'admin/media/link',
  detailLink: links.billV3EnergyPrice,
});

// this way I can autowire the result (with command + .);
export default BillingEnergyPricingService;
