import * as React from 'react';
import { useEffect, useState } from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Col, Rate, Row } from 'antd';
import Table from 'antd/lib/table';

import { Loader } from 'components/ui';
import TendergyFeedbackService from 'services/tendergy/tendergy-feedback.service';
import {
  FeedbackReceiverType,
  FeedbackReferenceType,
  IClientRequest,
  ITendergyFeedback,
  ITendergyUser,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from './UserRatingsTab.module.sass';

interface RatingsUserProps {
  entry?: ITendergyUser;
}

const columns = [
  {
    title: 'Client Request',
    dataIndex: 'reference',
    key: 'reference',
    render: (reference: IClientRequest) => reference?.displayId || '–',
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
  },
  {
    title: 'Comment',
    dataIndex: 'text',
    key: 'text',
    render: (text: string) => text || '–',
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    render: (author: ITendergyUser) => (author ? `${author.firstName} ${author.lastName}` : '–'),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const ITEMS_PER_PAGE = 10;

const UserRatingsTab: React.FC<RatingsUserProps> = ({ entry }: RatingsUserProps) => {
  const [total, setTotal] = useState<number>(0);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [feedback, setFeedback] = useState<ITendergyFeedback[] | null>(null);

  const params: any = {
    receiverType: FeedbackReceiverType.Installers,
    referenceType: FeedbackReferenceType.ClientRequests,
    receiverId: entry?.installer?.id,
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
    sortFields: 'createdAt',
    relations: ['clientRequest'],
  };

  const getFeedback = async () => {
    try {
      const response = await TendergyFeedbackService.getEntries({ ...params });
      setFeedback(response.items);
      setTotal(response?.total || 0);
      setAverageRating(response.averageRating || 0);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    setPage(1);
    entry?.installer?.id && getFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, entry]);

  if (!feedback && entry?.installer?.id) {
    return <Loader />;
  }

  const tableProps = {
    rowKey: 'id',
    columns,
    dataSource: feedback || [],
    pagination: {
      total,
      defaultPageSize: ITEMS_PER_PAGE,
      current: page,
      onChange: (page: number) => setPage(page),
      showSizeChanger: false,
    },
  };

  const rateProps = {
    character: ({ value, index }: { value: number; index: number }) => {
      if (value > index) {
        return <StarFilled />;
      }
      return <StarOutlined />;
    },
    allowClear: false,
    className: styles.rate,
    value: averageRating,
    disabled: true,
  };

  return (
    <div>
      <Row gutter={[16, 16]} className={styles.info}>
        <Col span={9}>Ranked:</Col>
        <Col span={3}>{averageRating}/5</Col>
        <Col span={12}>
          <Rate {...rateProps} />
        </Col>
        <Col span={9}>No. reviews:</Col>
        <Col span={15}>{total}</Col>
      </Row>
      <Table {...tableProps} />
    </div>
  );
};

export default UserRatingsTab;
