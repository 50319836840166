import React from 'react';
import { Link } from 'react-router-dom';
import { ExportOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { SearchProps } from 'antd/lib/input';

import { IGetMultiEntityPayload } from '../../store/storeInterfaces';
import { IColumns } from '../abstract-crud/CrudListViewContainer';

export interface ISearchValue {
  [key: string]: string | undefined;
}

export interface IFilterValue {
  text: string;
  value: string;
}

export type TCheckboxGroupValues = (string | number | boolean)[];

export function crudColumnsTransform<T>(
  columns: IColumns<T>[],
  filterInput: React.RefObject<Input>,
  columnsFilter: ISearchValue,
  setColumnsFilter: React.Dispatch<React.SetStateAction<ISearchValue>>,
  setPayload: React.Dispatch<React.SetStateAction<any>>,
  payload: IGetMultiEntityPayload,
  navigateToDetails: (record: T) => string | null,
) {
  const linkColumns = {
    title: 'link',
    render: (_record: any, b: any) => {
      const link = navigateToDetails(b);
      return (
        link && (
          <Link to={link} target="_blank">
            <ExportOutlined style={{ fontWeight: 'bold' }} />
          </Link>
        )
      );
    },
    dataIndex: 'link',
  };
  return columns.reduce(
    (accumulator, current) => {
      if (current.searchKey) {
        current.filterIcon = () => (
          <SearchOutlined
            style={{ color: columnsFilter[current.searchKey!] ? '#1890ff' : undefined }}
          />
        );
        current.filterDropdown = ({ confirm }: any) => {
          const searchInputProps: SearchProps = {
            placeholder: `Search ${current?.dataIndex}`,
            onSearch: (value: string) => {
              setColumnsFilter({ ...columnsFilter, [current.searchKey!]: value || undefined });
              confirm();
            },
            style: { width: 200, marginBottom: 10 },
          };

          const searchButtonProps: ButtonProps = {
            type: 'primary',
            onClick: () => {
              setColumnsFilter({
                ...columnsFilter,
                [current.searchKey!]: filterInput.current!.input.value || undefined,
              });
              confirm();
            },
            style: { width: 95 },
          };

          const resetButtonProps: ButtonProps = {
            onClick: () => {
              filterInput.current &&
                filterInput.current.setValue('', () =>
                  setColumnsFilter({ ...columnsFilter, [current.searchKey!]: undefined }),
                );
            },
            style: { width: 95 },
          };

          return (
            <div style={{ padding: 10 }}>
              <Input.Search ref={filterInput} {...searchInputProps} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button {...searchButtonProps}>Search</Button>
                <Button {...resetButtonProps}>Reset</Button>
              </div>
            </div>
          );
        };

        current.onFilterDropdownVisibleChange = (visible) => {
          if (visible) {
            setTimeout(() => filterInput.current?.select(), 100);
          }
        };
      }

      if (current.filters && current?.filters?.length > 0) {
        current.filterDropdown = (props: {
          filters: IFilterValue[];
          setSelectedKeys: (value: TCheckboxGroupValues) => void;
          selectedKeys: TCheckboxGroupValues;
        }) => {
          const { filters, setSelectedKeys, selectedKeys } = props;
          function handleCheck(checkedValues: TCheckboxGroupValues) {
            const currentIndex = Array.isArray(current.dataIndex)
              ? current.dataIndex.join('.')
              : current.dataIndex;
            setColumnsFilter({
              ...columnsFilter,
              [currentIndex as string]: checkedValues.join(',') || undefined,
            });
            if (current.filterParameter) {
              setPayload({
                ...payload,
                sortFields: current.filterParameter,
              });
            }
            setSelectedKeys(checkedValues);
          }
          const resetButtonProps: ButtonProps = {
            onClick: () => {
              setSelectedKeys([]);
              setColumnsFilter({ ...columnsFilter, [current.dataIndex as string]: undefined });
            },
            style: { width: 95, marginLeft: 10 },
          };

          return (
            <Checkbox.Group style={{ padding: 10 }} onChange={handleCheck} value={selectedKeys}>
              {filters?.map((filter) => (
                <Checkbox value={filter.value} key={`${filter.value}`}>
                  {filter.text}
                </Checkbox>
              ))}
              <Button {...resetButtonProps}>Reset</Button>
            </Checkbox.Group>
          );
        };

        current.filterIcon = () => (
          <FilterOutlined
            style={{ color: columnsFilter[current.dataIndex as string] ? '#1890ff' : undefined }}
          />
        );
      }

      accumulator.push(current);
      return accumulator;
    },
    // @ts-ignore
    [linkColumns] as IColumns<T>[],
  );
}
