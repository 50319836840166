import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { IMembershipOption } from 'store/tendegyService/tendergyService.types';

class MembershipOptionServiceClass extends EntityService<IMembershipOption> {
  getDisplayName(entry: IMembershipOption): string {
    return entry.name as string;
  }
}

const MembershipOptionService = new MembershipOptionServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/membership-options',
});

export default MembershipOptionService;
