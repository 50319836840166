import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import TendergyQuestionnaireStepService from 'services/tendergy/tendergy-questionnaire-step.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import QuestionnaireStepBaseStep from './steps/QuestionnaireStepBaseStep';

const CreateQuestionnaireStepPage: React.FC = () => {
  const { id, version } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };
  const steps: ICrudStep[] = [
    {
      name: 'Add New Step to questionnaire',
      description: '',
      render: (entry, onSubmitStep) => (
        <QuestionnaireStepBaseStep
          entry={entry}
          questionnaireId={id}
          questionnaireVersion={Number(version)}
          onSubmitStep={onSubmitStep}
        />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Steps"
      steps={steps}
      baseLink={links.tendergyQuestionnaires}
      defaultPayload={params}
      resource={TendergyQuestionnaireStepService}
    />
  );
};

export default CreateQuestionnaireStepPage;
