import React from 'react';
import { useLocation } from 'react-router-dom';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import BillingRfidCardCarOwnerService from 'services/billing-v2/billing-rfid-card-car-owner.service';
import { IBillingCarOwner, IBillingRFIDCard } from 'services/billing-v2/billing-v2-service.types';

import AttachRfidCardCarOwnerForm from './components/AttachRfidCardCarOwnerForm';

interface StateFromHistory {
  carOwner?: IBillingCarOwner;
  rfidCard?: IBillingRFIDCard;
}

export default function ISAUsersCreate() {
  const { state } = useLocation<StateFromHistory>();

  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Attach RFID card to car owner',
          render: (_entry, onSubmit) => (
            <AttachRfidCardCarOwnerForm
              onSubmit={onSubmit}
              carOwner={state?.carOwner}
              rfidCard={state?.rfidCard}
            />
          ),
        },
      ]}
      entityName="RFID card - car owner relation"
      baseLink={links.billAttachRfidCardCarOwner}
      resource={BillingRfidCardCarOwnerService}
    />
  );
}
