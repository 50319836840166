import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingImport } from './billing-service.types';

class BillingImportsServiceClass extends EntityService<IBillingImport> {}

const BillingImportsService = new BillingImportsServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/imports',
  createLink: links.billingCreateImport,
  detailLink: links.billingImport,
});

// this way I can autowire the result (with command + .);
export default BillingImportsService;
