import React from 'react';
import { Col, Layout, Row } from 'antd';

import StoreCustomizationContainer from 'components/containers/StoreCustomizationContainer/StoreCustomizationContainer';
import { IStoreCustomization } from 'services/shop/shop-service.types';

import styles from './StoreTabsStyles.module.sass';

const { Content } = Layout;

interface IProps {
  customization: IStoreCustomization;
}

const StoreCustomizationTab: React.FC<IProps> = (props: IProps) => {
  const { customization } = props;

  return (
    <Content className={styles.customizationWrapper}>
      <h3 className={styles.tabTitle}>Store Customization Page</h3>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          <StoreCustomizationContainer scheme={customization.scheme} />
        </Col>
      </Row>
    </Content>
  );
};

export default StoreCustomizationTab;
