import config from 'config';

import { ISA_CAR_BRANDS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISACarBrand } from '../isa-service.types';

const ISACarBrandsService = new EntityService<IISACarBrand>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_CAR_BRANDS_ENDPOINT,
  createLink: links.isaCarBrandsCreate,
  detailLink: links.isaCarBrandsDetails,
});

ISACarBrandsService.getDisplayName = (entry) => entry.name;

export default ISACarBrandsService;
