import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { IShopCarModel, PlugType } from 'services/shop/shop-service.types';

import { CarModelValidation } from '../../car-model.validation';

const CarModelOverviewTab: React.FC<IGenericEditTabProps<IShopCarModel>> = (
  props: IGenericEditTabProps<IShopCarModel>,
) => {
  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(CarModelValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IShopCarModel}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="make" label="Make" required>
              <Input name="make" />
            </Form.Item>

            <Form.Item name="model" label="Model" required>
              <Input name="model" />
            </Form.Item>

            <Form.Item name="plugTypes" label="Plug types">
              <Select mode="multiple" name="plugTypes">
                <Select.Option value={PlugType.Typ1}>Typ1</Select.Option>
                <Select.Option value={PlugType.Typ2}>Typ2</Select.Option>
                <Select.Option value={PlugType.CCS}>CCS</Select.Option>
                <Select.Option value={PlugType.CHAdeMO}>CHAdeMO</Select.Option>
                <Select.Option value={PlugType.Schuko}>Schuko</Select.Option>
                <Select.Option value={PlugType.Supercharger}>Supercharger</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="kwAC" label="kwAC">
              <Input name="kwAC" placeholder="" type="number" />
            </Form.Item>

            <Form.Item name="kwDC" label="kwDC">
              <Input name="kwDC" placeholder="" type="number" />
            </Form.Item>

            <Form.Item name="batteryCapacity" label="batteryCapacity">
              <Input name="batteryCapacity" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CarModelOverviewTab;
