import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';

import { eConsultantLeadStatus, invoicesStatus } from 'framework/constants';

export interface IFilters {
  query: null | string;
  status: null | eConsultantLeadStatus;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const Filters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IFilters>({
    query: null,
    status: null,
  });

  useEffect(() => {
    onFilter &&
      onFilter({
        ...value,
      });
  }, [value]); // eslint-disable-line

  const leadStatusProps = {
    placeholder: 'Invoice Status',
    onChange: (status: eConsultantLeadStatus) => {
      setValue({ ...value, status });
    },
    style: { width: '100%' },
    allowClear: true,
    options: Object.values(invoicesStatus).map((value: string) => ({ value, label: value })),
  };
  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col flex="1">
        <Select {...leadStatusProps} />
      </Col>
    </Row>
  );
};

export default Filters;
