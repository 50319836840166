import links from 'framework/links';
import CarOwnerCreatePage from 'pages/billing-v2/car-owners/CarOwnerCreatePage';
import CarOwnerDetailsPage from 'pages/billing-v2/car-owners/CarOwnerDetailsPage';
import BillCarOwnersOverviewPage from 'pages/billing-v2/car-owners/CarOwnersOverviewPage';
import ChargingStationCreatePage from 'pages/billing-v2/charging-stations/ChargingStationCreatePage';
import ChargingStationDetailsPage from 'pages/billing-v2/charging-stations/ChargingStationDetailsPage';
import ChargingStationsOverviewPage from 'pages/billing-v2/charging-stations/ChargingStationsOverviewPage';
import ImportsCreatePage from 'pages/billing-v2/imports/ImportsCreatePage';
import ImportsDetailsPage from 'pages/billing-v2/imports/ImportsDetailsPage';
import ImportsOverviewPage from 'pages/billing-v2/imports/ImportsOverviewPage';
import InvoiceCreatePage from 'pages/billing-v2/invoices/InvoiceCreatePage';
import InvoiceDetailsPage from 'pages/billing-v2/invoices/InvoiceDetailsPage';
import InvoicesOverviewPages from 'pages/billing-v2/invoices/InvoicesOverviewPage';
import BillOrganizationCreatePage from 'pages/billing-v2/organizations/BillOrganizationCreatePage';
import BillOrganizationDetailsPage from 'pages/billing-v2/organizations/BillOrganizationDetailsPage';
import BillOrganizationsOverviewPage from 'pages/billing-v2/organizations/BillOrganizationsOverviewPage';
import AttachRfidCardCarOwner from 'pages/billing-v2/rfid-card-car-owner/AttachRfidCardCarOwner';
import RfidCardCreatePage from 'pages/billing-v2/rfid-cards/RfidCardCreatePage';
import RfidCardDetailsPage from 'pages/billing-v2/rfid-cards/RfidCardDetailsPage';
import RfidCardsOverviewPage from 'pages/billing-v2/rfid-cards/RfidCardsOverviewPage';
import FleetAppUserCreatePage from 'pages/fleet-app/users/pages/UserCreatePage';
import FleetAppUsersListPage from 'pages/fleet-app/users/pages/UsersListPage';

export const BILLING_V2_ROUTES = [
  { path: links.billOrganizations, component: BillOrganizationsOverviewPage },
  { path: links.billOrganization, component: BillOrganizationDetailsPage },
  { path: links.billCreateOrganization, component: BillOrganizationCreatePage },
  { path: links.billCarOwners, component: BillCarOwnersOverviewPage },
  { path: links.billCarOwner, component: CarOwnerDetailsPage },
  { path: links.billCreateCarOwner, component: CarOwnerCreatePage },
  { path: links.billChargingStations, component: ChargingStationsOverviewPage },
  { path: links.billChargingStation, component: ChargingStationDetailsPage },
  { path: links.billCreateChargingStation, component: ChargingStationCreatePage },
  { path: links.billRfidCards, component: RfidCardsOverviewPage },
  { path: links.billRfidCard, component: RfidCardDetailsPage },
  { path: links.billCreateRfidCard, component: RfidCardCreatePage },
  { path: links.billAttachRfidCardCarOwner, component: AttachRfidCardCarOwner },
  { path: links.billImports, component: ImportsOverviewPage },
  { path: links.billImport, component: ImportsDetailsPage },
  { path: links.billCreateImport, component: ImportsCreatePage },
  { path: links.billInvoices, component: InvoicesOverviewPages },
  { path: links.billInvoice, component: InvoiceDetailsPage },
  { path: links.billCreateInvoice, component: InvoiceCreatePage },
  // fleetapp user page placed here as the customer (Max) has wanted
  { path: links.fleetAppUsersList, component: FleetAppUsersListPage },
  { path: links.fleetAppCreateUser, component: FleetAppUserCreatePage },
];
