import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import OfferAPI from 'services/tendergy/tendergy-offer.service';

import * as actions from './offer.actions';
import { GET, GetOfferAction, TRANSITION, TransitionOfferAction } from './offer.types';

export function* get({ payload }: GetOfferAction) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(OfferAPI.getById, payload);
    yield put(actions.set(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* transition({ payload }: TransitionOfferAction) {
  try {
    yield call(OfferAPI.transition, payload.type, payload.id, payload.rejectReason || '');
  } catch (err) {
    message.error(err);
  }
}

export default function* () {
  yield all([takeLatest(GET, get), takeLatest(TRANSITION, transition)]);
}
