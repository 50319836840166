import React from 'react';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { IOfferDocument } from 'store/storeInterfaces';
import { formatDefaultDate } from 'utils/date.helper';

interface UserDocumentsTabProps {
  entry?: IOfferDocument[];
  onOpenFile?: (fileName: string) => void;
}

const UserDocumentsTab: React.FC<UserDocumentsTabProps> = (props: UserDocumentsTabProps) => {
  const { entry, onOpenFile } = props;

  if (!onOpenFile) {
    return <div>Provide onOpenFile method</div>;
  }

  const columns: ColumnsType<IOfferDocument> = [
    {
      title: 'Original Name',
      dataIndex: 'originalName',
      key: 'originalName',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => formatDefaultDate(date),
    },
    {
      title: 'Action',
      key: 'action',
      render: (document: IOfferDocument) => (
        <div>
          <Button
            onClick={() => {
              onOpenFile(document.name);
            }}
          >
            Open document
          </Button>
        </div>
      ),
    },
  ];

  const tableProps = {
    columns,
    dataSource: entry,
  };

  return (
    <div>
      <Table {...tableProps} />
    </div>
  );
};

export default UserDocumentsTab;
