import config from 'config';

import { ISA_PROBLEM_CATEGORIES_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAProblemCategory } from '../isa-service.types';

const ISAProblemCategoriesService = new EntityService<IISAProblemCategory>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_PROBLEM_CATEGORIES_ENDPOINT,
  createLink: links.isaProblemCategoriesCreate,
  detailLink: links.isaProblemCategoriesDetails,
});

ISAProblemCategoriesService.getDisplayName = (entry) => entry.title;

export default ISAProblemCategoriesService;
