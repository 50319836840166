/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { Loader } from 'components/ui';
import { AUTH_TOKEN_KEY } from 'framework/constants';
import { isAuthorizedSelector, isLoadingSelector } from 'store/auth/auth.selectors';

export interface IPrivateRouteProps {
  [key: string]: any;
  path?: string;
  component?: any;
  exact?: boolean;
}

const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = ({
  path,
  component: Component,
  profileStore,
  ...rest
}: IPrivateRouteProps): React.ReactElement<any> => {
  const isAuthorized: boolean = useSelector(isAuthorizedSelector);
  const isLoading: boolean = useSelector(isLoadingSelector);
  const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);

  const renderComponent = (props: any) => {
    if (props.location === '/login') {
      return null;
    }
    if (!accessToken) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }
    if (isLoading && !isAuthorized) {
      return <Loader fluid />;
    }

    return <Component {...props} />;
  };

  return (
    <Route {...rest} path={path} render={(props: RouteComponentProps) => renderComponent(props)} />
  );
};

export default PrivateRoute;
