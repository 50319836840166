import * as React from 'react';
import { Form, FormItemProps, Input, InputProps } from 'formik-antd';

export interface IFormInputProps extends Partial<FormItemProps & InputProps> {
  name: string;
  type?: string;
  children?: React.ReactNode;
}

function FormInput(props: IFormInputProps) {
  const { name, type, children = null, disabled = false, ...rest } = props;
  return (
    <Form.Item name={name} {...rest}>
      <Input name={name} type={type} disabled={disabled} />
      {children}
    </Form.Item>
  );
}

export default FormInput;
