import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, InputNumber, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import CategoryService from 'services/shop/shop-categories.service';
import { IShopCategory, IShopProduct, ShopProductType } from 'services/shop/shop-service.types';

import { ProductValidation } from '../../product.validation';

import styles from './ProductStepsStyles.module.sass';

interface Props {
  entry: IShopProduct;
  onSubmitStep: (entry: IShopProduct) => void;
}

const ProductBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(ProductValidation);

  const defaultEntry = { ...entry, imageUrls: [] };
  return (
    <div>
      <Formik
        initialValues={defaultEntry as IShopProduct}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSubmitStep(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, dirty }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name" required>
              <Input name="name" placeholder="" />
            </Form.Item>
            <Form.Item name="type" label="Type" required>
              <Select name="type">
                <Select.Option value={ShopProductType.Product}>Product</Select.Option>
                <Select.Option value={ShopProductType.Service}>Service</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="description" label="Description">
              <Input name="description" placeholder="" />
            </Form.Item>
            <Form.Item name="price" label="Price (in Cents)">
              <InputNumber
                name="price"
                type="number"
                placeholder=""
                className={styles.numberInput}
              />
            </Form.Item>
            <Form.Item name="vat" label="Vat %">
              <InputNumber name="vat" type="number" placeholder="" className={styles.numberInput} />
            </Form.Item>
            <Form.Item name="stockQuantity" required label="Stock Quantity">
              <InputNumber
                name="stockQuantity"
                type="number"
                placeholder=""
                className={styles.numberInput}
              />
            </Form.Item>

            <Form.Item name="category" label="Product category" required>
              <EntitySelector
                entityService={CategoryService}
                name="category"
                current={entry.category}
                onSelect={(category: IShopCategory) => setFieldValue('category', category)}
              />
            </Form.Item>
            <Form.Item required name="imageUrls" label="imageUrls">
              {entry.imageUrls}
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting || !isValid || !dirty}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductBaseStep;
