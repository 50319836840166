import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import QuestionnaireConfigService from 'services/tendergy/questionnaire-config.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergyQuestionnaireConfig } from 'store/tendegyService/tendergyService.types';

import QuestionnaireConfigOverviewTab from './tabs/QuestionnaireConfigOverviewTab';

const QuestionnaireConfigDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: ITendergyQuestionnaireConfig, optionalProps?: ICrudDetailViewCallbacks) => (
        <QuestionnaireConfigOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  return (
    <CrudDetailContainer
      entityName="Questionnaire Config"
      tabs={tabs}
      baseLink={links.questionnaireConfig}
      defaultPayload={params}
      resource={QuestionnaireConfigService}
    />
  );
};

export default QuestionnaireConfigDetailsPage;
