import config from 'config';

import { AUTH_TOKEN_KEY } from 'framework/constants';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingInvoice, IBillingInvoicePayload } from './billing-v2-service.types';

class BillInvoicesServiceClass extends EntityService<IBillingInvoice> {
  getDisplayName(entry: IBillingInvoice): string {
    return entry.toString();
  }

  downloadInvoice(entry: IBillingInvoice) {
    const url = this.getURL(`admin/documents/download/${entry.id}`);
    this.downloadFile(url, {}, 'invoice.zip');
  }

  downloadZip = (entry: IBillingInvoice) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);

    window.open(`${this.getEntityUrl()}/${entry.id}/download/zip?token=${accessToken}`);
  };

  cancelInvoice = (entry: IBillingInvoice) => {
    const url = `${this.config.entityURL}/${entry.id}/cancel`;
    this.post(url);
  };

  sendByEmail = (entry: IBillingInvoice) => {
    const url = `${this.config.entityURL}/async-flow/${entry.id}/email`;
    this.post(url);
  };

  generateInvoice = (payload: IBillingInvoicePayload) => {
    const url = `${this.config.entityURL}/generate?year=${payload.year}&month=${payload.month}`;
    this.post(url);
  };
}

const BillInvoicesService = new BillInvoicesServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/invoices',
  detailLink: links.billInvoice,
  createLink: links.billCreateInvoice,
});

export default BillInvoicesService;
