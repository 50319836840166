import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd/lib';

import Sidebar from 'components/layout/Sidebar';
import links from 'framework/links';
import { logout } from 'store/auth/auth.actions';
import { userSelector } from 'store/auth/auth.selectors';

import styles from './styles.module.sass';

const { Header, Content } = Layout;

interface DashboardLayoutProps {
  children: any;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }: DashboardLayoutProps) => {
  const [sidebarCollapsed, toggleSidebar] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector(userSelector);

  const onLogout = () => {
    dispatch(logout());
    history.push(links.login);
  };

  const userMenu = (
    <Menu className={styles.userMenu}>
      <Menu.Item key="1" onClick={onLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Sidebar collapsed={sidebarCollapsed} />

      <Layout>
        <Header className={styles.header}>
          <div className={styles.leftBar}>
            <Button
              type="text"
              className={styles.sidebarTrigger}
              onClick={() => toggleSidebar(() => !sidebarCollapsed)}
            >
              {sidebarCollapsed ? (
                <MenuUnfoldOutlined className={styles.sidebarTrigger} />
              ) : (
                <MenuFoldOutlined className={styles.sidebarTrigger} />
              )}
            </Button>
          </div>
          <div className={styles.rightBar}>
            <h3 className={styles.nameWrapper}>
              {`${userProfile.firstName} ${userProfile.lastName}`}
            </h3>
            <Dropdown overlay={userMenu}>
              <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
            </Dropdown>
          </div>
        </Header>

        <Content style={{ margin: '32px 24px 24px', overflow: 'initial' }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
