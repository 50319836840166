import config from 'config';

import { ISA_SERVICE_TICKETS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAServiceTicket } from '../isa-service.types';

const ISAServiceTicketsService = new EntityService<IISAServiceTicket>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_SERVICE_TICKETS_ENDPOINT,
  detailLink: links.isaServiceTicketsDetails,
});

ISAServiceTicketsService.getDisplayName = (entry) => entry.ticketNumber;

export default ISAServiceTicketsService;
