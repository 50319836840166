import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillCarOwnersService from 'services/billing-v2/billing-v2-car-owners.service';
import {
  IBillingCarOwner,
  IBillingGridwareCreds,
} from 'services/billing-v2/billing-v2-service.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import CarOwnerActionsComponent from './components/CarOwnerActionsComponent/CarOwnerActionsComponent';
import CarOwnerForm from './components/CarOwnerForm/CarOwnerForm';
import ChargingStationsTab from './tabs/ChargingStationsTab';
import ProofOfChargingsTab from './tabs/ProofOfChargingsTab';
import RFIDCardsTab from './tabs/RFIDCardsTab';

interface IParams {
  id: string;
  page: string;
}

export default function CarOwnerDetailsPage() {
  const { id } = useParams<IParams>();
  const payload = {
    id,
  };

  const sentToGrid = async (data: IBillingGridwareCreds) => {
    try {
      await BillCarOwnersService.postToGridware(data, id);
      showSuccessNotification('Car Owner successfully created in Gridware');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const renderActions = (entry: IBillingCarOwner) => (
    <CarOwnerActionsComponent entry={entry} sendToGrid={sentToGrid} />
  );

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <CarOwnerForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'charging-stations',
          displayName: 'Charging Stations',
          render: (entry: IBillingCarOwner) => <ChargingStationsTab entry={entry} />,
        },
        {
          name: 'rfid-cards',
          displayName: 'RFID Cards',
          render: (entry: IBillingCarOwner) => <RFIDCardsTab entry={entry} />,
        },
        {
          name: 'proof-of-chargings',
          displayName: 'Proof of Chargings',
          render: (entry: IBillingCarOwner) => <ProofOfChargingsTab entry={entry} />,
        },
      ]}
      entityName="car owner"
      baseLink={links.billCarOwner}
      defaultPayload={payload}
      resource={BillCarOwnersService}
      renderActions={renderActions}
      renderTitle={(entry: IBillingCarOwner) => `${entry.firstname} ${entry.lastname}`}
    />
  );
}
