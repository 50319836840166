import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd';

import links from 'framework/links';
import { logout } from 'store/auth/auth.actions';
import { userSelector } from 'store/auth/auth.selectors';

import logo from '../../assets/images/inno-2-grid.png';

import styles from './ForbiddenPage.module.sass';

const { Header, Content } = Layout;

const ForbiddenPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector(userSelector);

  const onLogout = () => {
    dispatch(logout());
    history.push(links.login);
  };
  const userMenu = (
    <Menu className={styles.userMenu}>
      <Menu.Item key="1" onClick={onLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Header
        className={styles.header}
        style={{
          padding: '0 24px 0 0',
          background: '#fff',
          height: '50px',
          lineHeight: '50px',
        }}
      >
        <div className={styles.leftBar}>
          <Link to={links.home}>
            <img src={logo} alt="inno-2-grid logo" className={styles.logo} />
          </Link>
        </div>
        <div className={styles.rightBar}>
          <h3 className={styles.nameWrapper}>
            {`${userProfile.firstName} ${userProfile.lastName}`}
          </h3>
          <Dropdown overlay={userMenu}>
            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
          </Dropdown>
        </div>
      </Header>
      <Content>
        <div className={styles.layout}>
          <h1> 403 </h1>
          <h2> Forbidden </h2>
          <h2> You don&apos;t have permission to access the page. </h2>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={onLogout}
          >
            Log out
          </Button>
        </div>
      </Content>
    </div>
  );
};

export default ForbiddenPage;
