import {
  ApartmentOutlined,
  ApiOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  CarOutlined,
  CheckCircleOutlined,
  CodeSandboxOutlined,
  CommentOutlined,
  CompassOutlined,
  ControlOutlined,
  CopyOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FileZipOutlined,
  FontSizeOutlined,
  ForkOutlined,
  FormOutlined,
  GroupOutlined,
  IdcardOutlined,
  InboxOutlined,
  IssuesCloseOutlined,
  MailOutlined,
  MessageOutlined,
  PartitionOutlined,
  PicCenterOutlined,
  PictureOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TagsOutlined,
  ToolOutlined,
  TrademarkCircleOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';

export const ICONS = {
  ApartmentOutlined,
  AppstoreOutlined,
  CarOutlined,
  CheckCircleOutlined,
  CodeSandboxOutlined,
  CommentOutlined,
  ControlOutlined,
  CopyOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  GroupOutlined,
  InboxOutlined,
  IssuesCloseOutlined,
  FontSizeOutlined,
  MailOutlined,
  MessageOutlined,
  PartitionOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TagsOutlined,
  TrademarkCircleOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
  PicCenterOutlined,
  FormOutlined,
  ToolOutlined,
  ApiOutlined,
  FileZipOutlined,
  IdcardOutlined,
  UploadOutlined,
  FileTextOutlined,
  ForkOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
  CompassOutlined,
  PictureOutlined,
};
