import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISAAddressesService } from 'services/isa-service';
import { IISAAddress, IParams } from 'services/isa-service/isa-service.types';

import ISAAddressesForm from './components/ISAAddressesForm';

export default function ISAAddressesDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISAAddressesForm entry={entry} onSubmit={onSave} />,
        },
      ]}
      entityName="address"
      baseLink={links.isaAddressDetails}
      defaultPayload={{ id }}
      resource={ISAAddressesService}
      hideJsonTab
      renderTitle={(entry: IISAAddress) =>
        `${entry.city} ${entry.postalCode} ${entry.street} ${entry.doorNumber}`
      }
    />
  );
}
