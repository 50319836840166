import React from 'react';

import { FormEntitySelect, FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormEntitySelectProps } from 'components/forms/FormEntitySelect';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { ISADiagnosticsService, ISAQuestionsService } from 'services/isa-service';
import { IISADiagnosticStep } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISADiagnosticStep;
  onSubmit: (entry: IISADiagnosticStep) => Promise<IISADiagnosticStep>;
}

export default function ISADiagnosticStepsForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISADiagnosticStep> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISADiagnosticStep,
  };

  const diagnosticProps: IFormEntitySelectProps = {
    label: 'Diagnostic',
    name: 'diagnostic',
    entityService: ISADiagnosticsService,
    current: entry.diagnostic,
  };

  const questionsProps: IFormEntitySelectProps = {
    label: 'Questions',
    name: 'questions',
    entityService: ISAQuestionsService,
    current: entry.questions,
    mode: 'multiple',
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="title" label="Diagnostic Step Title" required />
      <FormInput name="description" label="Description" required />
      <FormInput name="sequenceNumber" label="Sequence Number" type="number" required />
      <FormEntitySelect {...diagnosticProps} />
      <FormEntitySelect {...questionsProps} />
      <FormSubmit />
    </FormikForm>
  );
}
