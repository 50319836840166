import * as React from 'react';
import { Descriptions } from 'antd';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { ITendergyLead, LeadState } from 'store/tendegyService/tendergyService.types';

interface Props {
  lead?: ITendergyLead;
  onSave?: Function;
}

const LeadDetailsComponent: React.FC<Props> = ({ lead, onSave }: Props) => {
  if (!lead) {
    return <div> Lead loading.. </div>;
  }

  if (!onSave) {
    return <div>onSave method required</div>;
  }

  const renderLead = () => (
    <Formik
      initialValues={lead as ITendergyLead}
      onSubmit={(values, { setSubmitting }) => {
        onSave(values).then(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit }) => (
        <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
          <Descriptions title="Lead details" column={1} layout="horizontal">
            <Form.Item name="state" label="State">
              <Select name="state">
                <Select.Option value={LeadState.NEW}>New</Select.Option>
                <Select.Option value={LeadState.CONTACTED}>Contacted</Select.Option>
              </Select>
            </Form.Item>
            {lead?.leadType && (
              <Descriptions.Item label="Lead Type">{lead.leadType}</Descriptions.Item>
            )}
            {lead?.companyName && (
              <Descriptions.Item label="Company Name">{lead.companyName}</Descriptions.Item>
            )}
            {lead?.contactPersonName && (
              <Descriptions.Item label="Contact Person Name">
                {lead.contactPersonName}
              </Descriptions.Item>
            )}
            {lead?.contactPersonEmail && (
              <Descriptions.Item label="Contact Person Email">
                {lead?.contactPersonEmail}
              </Descriptions.Item>
            )}
            {lead?.contactPersonPhone && (
              <Descriptions.Item label="Contact Person Phone">
                {lead?.contactPersonPhone}
              </Descriptions.Item>
            )}
            {lead?.numberOfCars && (
              <Descriptions.Item label="Number Of Cars">{lead?.numberOfCars}</Descriptions.Item>
            )}
            {lead.initiatorName && (
              <Descriptions.Item label="Initiator Name">{lead.initiatorName}</Descriptions.Item>
            )}
            {lead.pool && <Descriptions.Item label="Pool">{lead.pool.name}</Descriptions.Item>}

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" style={{ marginRight: '20px' }}>
                Submit
              </SubmitButton>
            </Form.Item>
          </Descriptions>
        </Form>
      )}
    </Formik>
  );

  return <div>{renderLead()}</div>;
};

export default LeadDetailsComponent;
