import * as React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import TendergyUserService from 'services/tendergy/tendergy-user.service';

import { IClientRequestPoolTabProps } from '.';

const ClientRequestPoolUsersTab: React.FC<IClientRequestPoolTabProps> = (
  props: IClientRequestPoolTabProps,
) => {
  const { entry } = props;

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
  ];

  const payload: any = {
    currentPool: entry?.id,
  };

  return (
    <CrudListViewContainer
      entityName="Users"
      columns={columns}
      additionalPayload={payload}
      resource={TendergyUserService}
      disableSearch
    />
  );
};

export default ClientRequestPoolUsersTab;
