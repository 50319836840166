import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(256, 'Field has not to be longer than 256 characters!')
    .required('Required field'),
  customerNumber: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
  address: Yup.object().shape({
    address: Yup.string()
      .required('Address (street, house number, apartment number) is required')
      .max(256, 'Field has not to be longer than 256 characters!'),
    city: Yup.string()
      .required('City required')
      .max(64, 'Field has not to be longer than 64 characters!'),
    region: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
    country: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
    postalCode: Yup.string()
      .required('Postal code is required')
      .max(10, 'Field has not to be longer than 10 characters!'),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
    iso3166_1_alpha_3: Yup.string()
      .required('ISO 3166-1 alpha-3 Country Code is required')
      .nullable()
      .max(3, 'Field has not to be longer than 3 characters!'),
  }),
  generateCoordinates: Yup.boolean().nullable(),
});
