import HttpService from 'services/abstract/http.service';
import { UserCredentials } from 'store/auth/auth.types';
import { IAuthUser } from 'store/authService/authService.types';

import config from '../../config';

class AuthAPIClass extends HttpService<IAuthUser> {
  login = (params: UserCredentials) => this.post('admin/auth/login', { ...params });

  loginAsUser = (userId: string) => this.post(`admin/auth/login/user/${userId}`);

  tokenRefresh = (fingerprint: string, refreshToken: string, accessToken: string) =>
    this.post(
      'auth/refresh',
      {
        fingerprint,
        context: 'admin',
        refreshToken,
      },
      false,
      {
        Authorization: `Bearer ${accessToken}`,
      },
    );

  logout = (fingerprint: string, refreshToken: string) =>
    this.post('auth/logout', { refreshToken, fingerprint, context: 'admin' });

  resetPassword = (email: string) => this.post('auth/recover', { email });

  resetPasswordValidate = (token: string) => this.get(`auth/reset/${token}`);

  resetPasswordComplete = (token: string, password: string) =>
    this.post('auth/reset', { token, password });
}
const AuthAPI = new AuthAPIClass({
  apiURL: config.AUTH_SERVICE_URL,
});
export default AuthAPI;
