import * as Yup from 'yup';

export const CarModelValidation = {
  model: Yup.string().required('Required').min(1, 'Too short!').max(50, 'Too long!'),
  make: Yup.string().required('Required').min(1, 'Too short!').max(50, 'Too long!'),
  plugTypes: Yup.array().of(Yup.string()),
  kwAC: Yup.number().nullable(),
  kwDC: Yup.number().nullable(),
  batteryCapacity: Yup.string().nullable(),
};
