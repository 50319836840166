import React from 'react';
import { useParams } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import LandingsService from 'services/shop/shop-landings.service';
import { ICommonParams, IShopLanding } from 'services/shop/shop-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import LandingForm from '../components/LandingForm';

const LandingCreatePage: React.FC = () => {
  const { id } = useParams<ICommonParams>();

  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add landing',
      description: 'required information',
      render: (entry: IShopLanding, onSubmitStep) => (
        <LandingForm
          entry={entry}
          onSubmitStep={onSubmitStep}
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 5 }}
        />
      ),
    },
  ];

  return (
    <CrudCreateWizardContainer
      entityName="Landing"
      steps={steps}
      baseLink={links.shopLanding}
      defaultPayload={params}
      resource={LandingsService}
    />
  );
};

export default LandingCreatePage;
