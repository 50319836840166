import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import FleetAppUsersService from 'services/fleet-app/fleet-app-users.service';

import { UserCreateForm } from '../components/UserCreateForm';

export default function FleetAppUserCreatePage() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add User',
          render: (entry, onSubmit) => <UserCreateForm onSubmit={onSubmit} entry={entry} />,
        },
      ]}
      entityName="user"
      baseLink={links.fleetAppUsersList}
      resource={FleetAppUsersService}
    />
  );
}
