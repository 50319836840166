import * as React from 'react';
import { Tag } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillingEnergyPricingService from 'services/billing/billing-energy-prices.service';
import { IBillingChargingStation } from 'services/billing-v2/billing-v2-service.types';

export const checkActivity = (validFrom: Date, validTo: Date) => {
  const now = new Date();
  const isActive = moment(now).isBetween(validFrom, validTo);
  const validFromExists = !validTo && moment(now).isAfter(validFrom);

  if (isActive || validFromExists) {
    return <Tag color="green">Active</Tag>;
  }
  if (moment(now).isBefore(validFrom)) {
    return <Tag color="green">Approved</Tag>;
  }
  if (moment(now).isAfter(validTo)) {
    return <Tag color="red">Expired</Tag>;
  }
};
interface Props {
  entry?: IBillingChargingStation;
}

interface Payload {
  chargingStationId?: string;
}

const EnergyPricesTab: React.FC<Props> = ({ entry }: Props) => {
  const columns = [
    {
      title: 'Charge per unit',
      dataIndex: 'chargePerUnit',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Payout per unit',
      dataIndex: 'payoutPerUnit',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Status',
      render: (date: any) => {
        return checkActivity(date.validFrom, date.validTo);
      },
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
  ];
  const payload: Payload = {
    chargingStationId: entry?.id,
  };

  return (
    <CrudListViewContainer
      entityName="Energy Prices"
      columns={columns}
      additionalPayload={payload}
      resource={BillingEnergyPricingService}
      disableSearch
    />
  );
};

export default EnergyPricesTab;
