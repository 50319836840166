import React from 'react';
import { Card } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import _omit from 'lodash/omit';

import { FormEntitySelect, FormInput, FormSelect, FormSubmit } from 'components/forms';
import {
  BillChargingPointGroupAssignService,
  BillChargingPointGroupService,
  BillGetOrganizations,
} from 'services/billing-v3/billing-v3-charging-stations.service';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import { validationSchemaCPG } from '../validation';

interface IChargePoint {
  id: string;
}
const ChargePG = () => {
  const assignOrganization = async (entry: any) => {
    try {
      const resp = await BillChargingPointGroupAssignService.save(entry);
      showSuccessNotification('Organization Assigned to Charge Point Group');
      return resp;
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const CreateCPG = async (entry: any) => {
    try {
      const resp = await BillChargingPointGroupService.save(entry);
      showSuccessNotification('Charging Point Group Created Successfully');
      return resp;
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const createChargingStation = async (values: any) => {
    const cpgData = _omit(values, ['organization']);
    const createdCPG: IChargePoint = await CreateCPG(cpgData);
    if (createdCPG) {
      if (values.organization) {
        const entry = {
          chargingPointGroupId: createdCPG.id,
          organizationId: values.organization.id,
        };
        console.log('entry', entry);
        assignOrganization(entry);
      }
    }
  };

  const selectOrganizationProps = {
    label: 'Organization',
    entityService: BillGetOrganizations,
    name: 'organization',
    current: '',
  };

  const renderAddress = () => {
    return (
      <>
        <FormInput name="address.country" label="Country" />
        <FormInput name="address.iso3166_1_alpha_3" label="ISO 3166-1 alpha-3 Code" required />
        <FormInput name="address.region" label="Region" />
        <FormInput name="address.city" label="City" required />
        <FormInput name="address.address" label="Address" required />
        <FormInput name="address.postalCode" label="Postcode" required />
        <FormInput name="address.latitude" label="Latitude" />
        <FormInput name="address.longitude" label="Longitude" />
      </>
    );
  };

  const renderNameAndType = () => {
    return (
      <>
        <FormSelect name="type" label="Type" options={['home', 'work', 'public']} required />

        <FormInput name="name" label="Name" />
        <FormEntitySelect {...selectOrganizationProps} style={{ width: '100%' }} />
      </>
    );
  };

  return (
    <Card title="Create Charging Point Group" style={{ marginTop: '20px' }}>
      <Formik
        enableReinitialize
        onSubmit={createChargingStation}
        initialValues={{}}
        validationSchema={validationSchemaCPG}
        render={() => {
          return (
            <Form>
              {renderNameAndType()}
              {renderAddress()}
              <FormSubmit />
            </Form>
          );
        }}
      />
    </Card>
  );
};

export default ChargePG;
