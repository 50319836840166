import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Typography } from 'antd';

import TendergyQuestionnaireStepService from 'services/tendergy/tendergy-questionnaire-step.service';
import { IUISchema, MediaCategoryType } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

import config from './config.json';
// eslint-disable-next-line
import { questionType, questionUISchemaBuilder } from './helper';
import QuestionSidebar from './QuestionsSidebar';
import UploaderModal from './UploaderModal';

import 'bootstrap/dist/css/bootstrap.min.css';
import './FormBuilder.modules.sass';

export interface FormBuilderProps {
  data?: IUISchema;
  onChange: Function;
}

const FormBuilder: React.FC<FormBuilderProps> = (props: FormBuilderProps) => {
  const { onChange } = props;
  let { data } = props;
  const [editorInstance, setEditorInstance] = useState();
  const [questions, SetQuestions] = useState(data ? data.elements : []);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(-1);
  const [visible, setVisible] = useState<boolean>(false);
  const [uploader, setUploader] = useState<string>();
  const JSONEditor = require('@json-editor/json-editor').JSONEditor;
  const { Title } = Typography;
  useEffect(() => {
    SetQuestions(data ? data.elements : []);
  }, [data]);

  useEffect(() => {
    const firstQuestion = questions && questions[0];
    const element = document.getElementById('editor_holder');
    if (firstQuestion) {
      setSelectedQuestionIndex(0);
      JSONEditor.defaults.callbacks = {
        button: {
          openModal: (editor: any) => {
            setUploader(editor.path);
          },
        },
      };
      const editor = new JSONEditor(element, {
        schema: questionUISchemaBuilder(firstQuestion.options.format),
        startval: firstQuestion,
        ...config,
      });
      setEditorInstance(editor);
    }
    return () => {
      // @ts-ignore
      editorInstance?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (selectedQuestionIndex !== undefined && editorInstance) {
      // @ts-ignore
      editorInstance.on('change', () => {
        // @ts-ignore
        const errors = editorInstance?.validate();
        // @ts-ignore
        const uiSchema = editorInstance.getValue();
        const questionKey = uiSchema.scope;
        if (checkQuestionKeyUniqueness(questionKey)) {
          return;
        }
        let newData = { type: 'Group', elements: questions };
        newData.elements[selectedQuestionIndex] = uiSchema;
        SetQuestions(newData.elements);
        onChange(newData);
        if (errors.length !== 0) {
          errors.forEach((error: any) => {
            showErrorNotification(error.message);
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionIndex]);

  const checkQuestionKeyUniqueness = (questionKey: string) => {
    const equalQuestionKeyIndex = questions.findIndex((question) => question.scope === questionKey);

    if (equalQuestionKeyIndex !== -1 && equalQuestionKeyIndex !== selectedQuestionIndex) {
      showErrorNotification('Question key should be unique');
      return true;
    } else return false;
  };

  const onSelect = (key: any) => {
    // @ts-ignore
    editorInstance?.destroy();
    const selectedQuestionIndex = questions.findIndex((question: any) => question.scope === key);
    const selectedQuestion = questions[selectedQuestionIndex];
    setSelectedQuestionIndex(selectedQuestionIndex);
    const element = document.getElementById('editor_holder');
    const editor = new JSONEditor(element, {
      schema: questionUISchemaBuilder(selectedQuestion.options.format),
      startval: selectedQuestion,
      ...config,
    });
    setEditorInstance(editor);
  };

  const onAdd = (questionType: questionType) => {
    if (checkQuestionKeyUniqueness('#/properties/questionKey')) {
      return;
    }
    // @ts-ignore
    editorInstance?.destroy();
    const element = document.getElementById('editor_holder');
    const editor = new JSONEditor(element, {
      schema: questionUISchemaBuilder(questionType),
      startval: {},
      ...config,
    });
    setSelectedQuestionIndex(questions.length);
    setEditorInstance(editor);
  };

  const moveQuestion = (firstIndex: number, secondIndex: number) => {
    const newQuestions = [...questions];
    newQuestions[firstIndex] = questions[secondIndex];
    newQuestions[secondIndex] = questions[firstIndex];
    let newData = { type: 'Group', elements: questions };
    newData.elements = newQuestions;
    onChange(newData);
    SetQuestions(newQuestions);
  };

  const deleteQuestion = () => {
    const newQuestions = [...questions];
    newQuestions.splice(selectedQuestionIndex, 1);
    let newData = { type: 'Group', elements: questions };
    newData.elements = newQuestions;
    onChange(newData);
    if (newQuestions.length > 0) onSelect(newQuestions[0].scope);
    SetQuestions(newQuestions);
    setVisible(false);
    // @ts-ignore
    editorInstance?.destroy();
  };

  const configFormFileName = (determine: string) => {
    const path = uploader?.split(determine);
    path?.pop();
    return `${path?.join(determine)}.url`;
  };

  const handleUploadCoverImage = async (file: File | Blob) => {
    const { imagePublicURL } = await TendergyQuestionnaireStepService.uploadFile({
      document: file as File,
      category: MediaCategoryType.QuestionnaireStepImage,
    });

    const path = configFormFileName('.');
    // @ts-ignore
    const name = editorInstance?.getEditor(path);
    if (name) {
      name.setValue(imagePublicURL);
    }
    setUploader('');
  };

  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Title level={3}>Questions</Title>
          <QuestionSidebar
            questions={questions}
            selected={questions[selectedQuestionIndex]}
            onAdd={onAdd}
            onSelect={onSelect}
          />
        </Col>
        <Col span={18}>
          <Row gutter={[16, 16]}>
            <Col span={4} offset={20}>
              {editorInstance && (
                <span className="btn-group">
                  <button
                    type="button"
                    title="Delete question"
                    className="btn btn-default json-editor-btn-delete delete json-editor-btntype-delete"
                    onClick={() => setVisible(true)}
                  >
                    <i className="glyphicon glyphicon-trash"></i>
                  </button>
                  <UploaderModal
                    visible={!!uploader}
                    onCancel={() => setUploader('')}
                    onUpload={handleUploadCoverImage}
                  />
                  <Modal
                    title="CONFIRM Delete"
                    visible={visible}
                    onOk={() => deleteQuestion()}
                    onCancel={() => setVisible(false)}
                  >
                    <p>Are you sure that you want to delete the question?</p>
                  </Modal>
                  <button
                    type="button"
                    title="Move question up"
                    className="btn btn-default json-editor-btn-moveup moveup json-editor-btntype-move"
                    disabled={selectedQuestionIndex === 0}
                    onClick={() => {
                      setSelectedQuestionIndex(selectedQuestionIndex - 1);
                      moveQuestion(selectedQuestionIndex - 1, selectedQuestionIndex);
                    }}
                  >
                    <i className="glyphicon glyphicon-arrow-up"></i>
                  </button>
                  <button
                    type="button"
                    title="Move question down"
                    className="btn btn-default json-editor-btn-movedown movedown json-editor-btntype-move"
                    onClick={() => {
                      setSelectedQuestionIndex(selectedQuestionIndex + 1);
                      moveQuestion(selectedQuestionIndex, selectedQuestionIndex + 1);
                    }}
                    disabled={selectedQuestionIndex === questions.length - 1}
                  >
                    <i className="glyphicon glyphicon-arrow-down"></i>
                  </button>
                </span>
              )}
            </Col>
          </Row>

          <div id="editor_holder"></div>
        </Col>
      </Row>
    </Card>
  );
};

FormBuilder.defaultProps = {
  data: { type: 'Group', elements: [] },
};

export default FormBuilder;
