import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IFleetAppUsers } from 'services/fleet-app/fleet-app-service.types';

class BillUsersListClass extends EntityService<IFleetAppUsers> {
    getDisplayName(user: IFleetAppUsers): string {
        return `${user.firstName} ${user.lastName}`;
    }
}

const FleetAppUsersService = new BillUsersListClass({
    apiURL: config.FLEETAPP_SERVICE_URL,
    entityURL: 'admin/users',
    detailLink: links.fleetAppUsersList,
    createLink: links.fleetAppCreateUser,
});

export default FleetAppUsersService;
