import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISADiagnosticsService } from 'services/isa-service';

import ISADiagnosticsForm from './components/ISADiagnosticsForm';

export default function ISADiagnosticsCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Diagnostic',
          render: (_entry, onSubmit) => <ISADiagnosticsForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="diagnostic"
      baseLink={links.isaDiagnosticsOverview}
      resource={ISADiagnosticsService}
    />
  );
}
