import * as React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IShopStore, IShopStoreEmail, ShopStoreEmailKeys } from 'services/shop/shop-service.types';
import StoreService from 'services/shop/shop-stores.service';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import { ShopStoreEmailValidationSchema } from '../shop-store-email.validation';

interface IStoreEmailFormProps {
  entry: IShopStoreEmail;
  onSubmitStep?: (entry: IShopStoreEmail) => Promise<IShopStoreEmail>;
  onSave?: (entry: IShopStoreEmail) => Promise<IShopStoreEmail>;
}

const StoreEmailForm: React.FC<IStoreEmailFormProps> = (props: IStoreEmailFormProps) => {
  const { entry, onSave, onSubmitStep } = props;

  async function handleSubmit(values: IShopStoreEmail, actions: FormikHelpers<IShopStoreEmail>) {
    const submitAction = onSave ? onSave : onSubmitStep;

    if (submitAction) {
      try {
        await submitAction(values);
        showSuccessNotification('Success');
      } catch (error) {
        showErrorNotification(error);
      }
    }

    actions.setSubmitting(false);
  }

  const formikProps = {
    initialValues: entry,
    validationSchema: ShopStoreEmailValidationSchema,
    onSubmit: handleSubmit,
  };

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }
  if (!entry) {
    return <div> Loading </div>;
  }

  return (
    <Formik {...formikProps}>
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
          <Form.Item name="email" label="Email" required>
            <Input name="email" />
          </Form.Item>
          <Form.Item name="key" label="Key" required>
            <Select name="key">
              <Select.Option value={ShopStoreEmailKeys.BusinessSales}>Business Sales</Select.Option>
              <Select.Option value={ShopStoreEmailKeys.ReceiveOrders}>Receive Orders</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="store" label="Store">
            <EntitySelector
              entityService={StoreService}
              name="store"
              onSelect={(store: IShopStore) => setFieldValue('store', store)}
              current={entry.store}
            />
          </Form.Item>
          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton
              type="primary"
              htmlType="submit"
              disabled={isSubmitting}
              style={{ marginRight: '20px' }}
            >
              Submit
            </SubmitButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default StoreEmailForm;
