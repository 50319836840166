import * as Yup from 'yup';

export const INITIAL_VALUES = {
  rating: null,
  description: null,
};

export const validationSchema = Yup.object().shape({
  rating: Yup.number().nullable().required('Required field'),
  description: Yup.string().nullable().required('Required field'),
});
