import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import BillChargingStationsService from 'services/billing-v2/billing-v2-charging-stations.service';

import ChargingStationForm from './components/ChargingStationForm/ChargingStationForm';

export default function ChargingStationCreatePage() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Charging Station',
          render: (entry, onSubmit) => <ChargingStationForm onSubmit={onSubmit} entry={entry} />,
        },
      ]}
      entityName="charging station"
      baseLink={links.billChargingStations}
      resource={BillChargingStationsService}
    />
  );
}
