import * as React from 'react';
import { ButtonProps } from 'antd/lib/button';
import { useFormikContext } from 'formik';
import { Form, FormItemProps, SubmitButton } from 'formik-antd';

import { CrudFormDefaultActionParams } from 'framework/abstract-crud/abstractCrud.constants';

export interface IFormSubmitProps extends Partial<FormItemProps & ButtonProps> {
  name: string;
  children: string;
}

function FormSubmit(props: IFormSubmitProps) {
  const { children, ...rest } = props;
  const { errors } = useFormikContext();
  console.log('tihsiserr', errors);
  return (
    <Form.Item {...CrudFormDefaultActionParams} {...rest}>
      <SubmitButton>{children}</SubmitButton>
    </Form.Item>
  );
}

FormSubmit.defaultProps = {
  name: 'submit',
  children: 'Submit',
};

export default FormSubmit;
