import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IWebhook } from './webhooks-service.types';

class WebhooksServiceClass extends EntityService<IWebhook> {
  getDisplayName(entry: IWebhook): string {
    return entry.id;
  }
}

const WebhooksService = new WebhooksServiceClass({
  apiURL: config.INTEGRATION_SERVICE_URL,
  entityURL: 'admin/webhooks',
  createLink: links.webhookCreate,
  detailLink: links.webhookDetails,
});

export default WebhooksService;
