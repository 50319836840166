import * as React from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, Select, SubmitButton } from 'formik-antd';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import OfferService from 'services/tendergy/tendergy-offer.service';
import {
  AppointmentPurpose,
  ITendergyAppointment,
  OfferingUserType,
} from 'store/tendegyService/tendergyService.types';

import { AppointmentsValidation } from '../../appointments.validation';

interface Props {
  entry: ITendergyAppointment;
  onSubmitStep: (entry: ITendergyAppointment) => void;
}

const AppointmentBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(AppointmentsValidation);

  return (
    <div>
      <Formik
        initialValues={entry as ITendergyAppointment}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item required name="proposedBy" label="Proposed By">
              <Select name="proposedBy" placeholder="Select proposed by">
                <Select.Option value={OfferingUserType.END_USER}>Customer</Select.Option>
                <Select.Option value={OfferingUserType.INSTALLER}>Installer</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="purpose" label="Purpose">
              <Select name="purpose" allowClear placeholder="Select purpose">
                <Select.Option value={AppointmentPurpose.HomeCheck}>HomeCheck</Select.Option>
                <Select.Option value={AppointmentPurpose.Installation}>Installation</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item required name="timestamp" label="Appointment timestamp">
              <DatePicker name="timestamp" showTime />
            </Form.Item>

            <Form.Item name="offer" label="Offer">
              <EntitySelector
                current={values.offer}
                onSelect={(offer) => {
                  setFieldValue('offer', offer);
                }}
                entityService={OfferService}
                name="offer"
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting || !isEmpty(errors)}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AppointmentBaseStep;
