import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import TendergyRequestService from 'services/tendergy/tendergy-request.service';
import {
  ITendergyClientRequestPool,
  ITendergyUser,
} from 'store/tendegyService/tendergyService.types';
import { CurrencyUtils } from 'utils/currencyUtils';
import { formatDefaultDate } from 'utils/date.helper';

interface IClientRequestsTab {
  entry?: ITendergyClientRequestPool;
}

const ClientRequestsTab: React.FC<IClientRequestsTab> = (props: IClientRequestsTab) => {
  const { entry } = props;

  const columns = [
    {
      title: 'No',
      dataIndex: 'displayId',
      key: 'displayId',
      width: 100,
      fixed: true,
    },
    {
      title: 'Client',
      dataIndex: 'owner',
      key: 'owner',
      render: (owner: ITendergyUser) =>
        owner ? (
          <>
            {owner.firstName}
            {owner.lastName}
          </>
        ) : (
          'n/a'
        ),
      width: 150,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 150,
    },
    {
      title: 'Location',
      dataIndex: 'address',
      key: 'address',
      render: (address: any) => (address ? `${address.postalCode} ${address.city}` : 'n/a'),
      width: 150,
    },
    {
      title: 'Price Range',
      render: (record: any) => `${CurrencyUtils.defaultFormatter(record.costEstimateMin)} -
      ${CurrencyUtils.defaultFormatter(record.costEstimateMax)}`,
      width: 250,
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: string) => formatDefaultDate(date),
      width: 150,
    },
    {
      title: 'Soonest Possible',
      dataIndex: 'soonestPossible',
      key: 'soonestPossible',
      render: (value: boolean) => (value ? value.toString() : 'false'),
      width: 150,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => formatDefaultDate(date),
      width: 150,
    },
  ];
  const payload: any = {
    pools: entry?.id,
  };

  return (
    <CrudListViewContainer
      entityName="Client Requests"
      columns={columns}
      additionalPayload={payload}
      resource={TendergyRequestService}
      disableSearch
    />
  );
};

export default ClientRequestsTab;
