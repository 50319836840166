import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import CustomDocumentsService from 'services/tendergy/tendergy-custom-documents.service';
import { ICustomDocument } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<ICustomDocument> = [
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Scope type',
    dataIndex: 'scopeType',
    key: 'scopeType',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },

  {
    title: 'Created at',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const CustomDocumentsOverviewPage: React.FC = () => {
  return (
    <CrudListViewContainer
      entityName="Custom Documents"
      columns={columns}
      resource={CustomDocumentsService}
    />
  );
};
export default CustomDocumentsOverviewPage;
