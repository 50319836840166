import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISAServiceTicketsService } from 'services/isa-service';
import { IISAServiceTicket, IParams } from 'services/isa-service/isa-service.types';

import ISAServiceTicketsForm from './components/ISAServiceTicketsForm';

export default function ISAServiceTicketsDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISAServiceTicketsForm entry={entry} onSubmit={onSave} />,
        },
      ]}
      entityName="service ticket"
      baseLink={links.isaServiceTicketsDetails}
      defaultPayload={{
        id,
        relations: ['user', 'user.address', 'carModel', 'wallbox', 'problem', 'diagnostic'],
      }}
      resource={ISAServiceTicketsService}
      hideJsonTab
      renderTitle={(entry: IISAServiceTicket) => entry.ticketNumber}
    />
  );
}
