import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISAUsersService } from 'services/isa-service';

import ISAUsersForm from './components/ISAUsersForm';

export default function ISAUsersCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add User',
          render: (_entry, onSubmit) => <ISAUsersForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="user"
      baseLink={links.isaUsersOverview}
      resource={ISAUsersService}
    />
  );
}
