import links from 'framework/links';
import ActivityLogsDetailsPage from 'pages/email/activity-logs/details/ActivityLogsDetailsPage';
import ActivityLogsOverviewPage from 'pages/email/activity-logs/overview/ActivityLogsOverviewPage';
import LayoutCreatePage from 'pages/email/layouts/create/LayoutCreatePage';
import LayoutDetailsPage from 'pages/email/layouts/details/LayoutDetailsPage';
import LayoutOverviewPage from 'pages/email/layouts/overview/LayoutOverviewPage';
import TemplateCreatePage from 'pages/email/templates/create/TemplateCreatePage';
import TemplateDetailsPage from 'pages/email/templates/details/TemplateDetailsPage';
import TemplatesOverviewPage from 'pages/email/templates/overview/TemplatesOverviewPage';

export const EMAIL_ROUTES = [
  { path: links.emailLayouts, component: LayoutOverviewPage },
  { path: links.emailLayout, component: LayoutDetailsPage },
  { path: links.emailCreateLayout, component: LayoutCreatePage },
  { path: links.emailTemplates, component: TemplatesOverviewPage },
  { path: links.emailTemplate, component: TemplateDetailsPage },
  { path: links.emailCreateTemplate, component: TemplateCreatePage },
  { path: links.emailActivityLogs, component: ActivityLogsOverviewPage },
  { path: links.emailActivityLog, component: ActivityLogsDetailsPage },
];
