import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISADiagnosticsService } from 'services/isa-service';
import { IISADiagnostic, IParams } from 'services/isa-service/isa-service.types';

import ISADiagnosticsForm from './components/ISADiagnosticsForm';
import ISADiagnosticStepsTab from './components/ISADiagnosticStepsTab';

export default function ISADiagnosticsDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISADiagnosticsForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'diagnosticSteps',
          displayName: 'Diagnostic Steps',
          render: (entry: IISADiagnostic) => (
            <ISADiagnosticStepsTab diagnosticSteps={entry.diagnosticSteps} />
          ),
        },
      ]}
      entityName="diagnostic"
      baseLink={links.isaDiagnosticsDetails}
      defaultPayload={{ id, relations: ['diagnosticSteps'] }}
      resource={ISADiagnosticsService}
      hideJsonTab
    />
  );
}
