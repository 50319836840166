import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingRFIDCard } from './billing-v2-service.types';

class BillRFIDCardsServiceClass extends EntityService<IBillingRFIDCard> {
  getDisplayName(entry: IBillingRFIDCard): string {
    return entry.identifier;
  }
}

const BillRFIDCardsService = new BillRFIDCardsServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/rfid-cards',
  detailLink: links.billRfidCard,
  createLink: links.billCreateRfidCard,
});

export default BillRFIDCardsService;
