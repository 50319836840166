import * as Yup from 'yup';

import { ShopStoreEmailKeys } from 'services/shop/shop-service.types';

export const ShopStoreEmailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email').required('Required'),
  key: Yup.mixed<ShopStoreEmailKeys>()
    .oneOf(Object.values(ShopStoreEmailKeys))
    .required('Required'),
});
