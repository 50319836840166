import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import TendergyQuestionnaireService from 'services/tendergy/tendergy-questionnaire.service';
import { ITendergyQuestionnaire } from 'store/tendegyService/tendergyService.types';

const columns: ColumnsType<ITendergyQuestionnaire> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Default',
    dataIndex: 'isDefault',
    key: 'isDefault',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (value) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Type',
    dataIndex: 'questionnaireType',
    key: 'questionnaireType',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Last update',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date) => moment(date).format('MMM Do YY'),
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date) => moment(date).format('MMM Do YY'),
  },
];

const QuestionnaireOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Questionnaires"
    columns={columns}
    resource={TendergyQuestionnaireService}
  />
);
export default QuestionnaireOverviewPage;
