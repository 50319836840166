import * as Yup from 'yup';

export const ProductValidation = {
  name: Yup.string().required('Required').min(2, 'Too short!').max(50, 'Too long!'),
  description: Yup.string().nullable(),
  price: Yup.number().nullable().lessThan(100000000, 'Too big number'),
  vat: Yup.number().nullable().max(100000).lessThan(100000, 'Too big number'),
  stockQuantity: Yup.number().nullable().min(1).max(100000),
  category: Yup.object({
    id: Yup.string(),
  }).required(),
};
