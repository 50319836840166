import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form, Input, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { QuestionnaireValidation } from 'pages/tendergy/questionnaires/questionnaire.validation';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import TendergyQuestionnaireService from 'services/tendergy/tendergy-questionnaire.service';
import { ITendergyQuestionnaire } from 'store/tendegyService/tendergyService.types';

interface Props {
  entry: any;
  onSubmitStep: (entry: ITendergyQuestionnaire) => void;
  onChangeCopy: (isCopy: boolean) => void;
  onChangeQuestionnaireId: (id: string, version: string) => void;
}

const defaultValues = {
  name: '',
};

const QuestionnaireBaseStep: React.FC<Props> = ({
  entry,
  onSubmitStep,
  onChangeCopy,
  onChangeQuestionnaireId,
}: Props) => {
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [questionnaire, setQuestionnaire] = useState<boolean>(false);
  const validation = Yup.object().shape(QuestionnaireValidation);

  const renderForm = ({ isSubmitting, setFieldValue }: FormikProps<any>) => {
    const isSubmitDisabled = isSubmitting || (isCopy && !questionnaire);

    return (
      <Form {...CrudFormDefaultParams}>
        <Form.Item name="name" label="Name">
          <Input defaultValue={''} name="name" />
        </Form.Item>
        <Form.Item name="isCopy" label="Copy">
          <Switch
            name="isCopy"
            onChange={(checked: boolean) => {
              setIsCopy(checked);
              onChangeCopy(checked);
            }}
          />
        </Form.Item>
        {isCopy && (
          <>
            <Form.Item name="questionnaire" label="From" required={true}>
              <EntitySelector
                onSelect={(questionnaire) => {
                  setQuestionnaire(questionnaire);
                  onChangeQuestionnaireId(questionnaire.id, questionnaire.version);
                }}
                hasVersion={true}
                entityService={TendergyQuestionnaireService}
                name="questionnaire"
                style={{ width: '100%' }}
              />
              {isCopy && !questionnaire ? (
                <span style={{ fontSize: 14, color: '#ff4d4f' }}>From field is required</span>
              ) : null}
            </Form.Item>
            <Form.Item
              name="pools"
              label="Pools"
              tooltip="Select the pools that use this questionnaire"
            >
              <EntitySelector
                current={entry.pools}
                onSelect={(pools) => {
                  setFieldValue(
                    'poolId',
                    pools.map((pool: any) => pool.id),
                  );
                }}
                style={{ width: '100%' }}
                entityService={ClientRequestPoolService}
                name="pools"
                mode="multiple"
              />
            </Form.Item>
          </>
        )}

        <Form.Item {...CrudFormDefaultActionParams} name="submit">
          <SubmitButton
            type="primary"
            htmlType="submit"
            disabled={isSubmitDisabled}
            style={{ marginRight: '20px' }}
          >
            Submit
          </SubmitButton>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div>
      <Formik
        // @ts-ignore
        initialValues={defaultValues}
        validationSchema={validation}
        onSubmit={onSubmitStep}
        render={renderForm}
      />
    </div>
  );
};

export default QuestionnaireBaseStep;
