import * as React from 'react';
import { Button, DatePicker, Form } from 'antd';
import { Moment } from 'moment';

import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import BillingCarOwnerService from 'services/billing/billing-car-owner.service';
import { IBillingCarOwner } from 'services/billing/billing-service.types';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const CarOwnerProofOfChargingTab: React.FC<IGenericEditTabProps<IBillingCarOwner>> = (
  props: IGenericEditTabProps<IBillingCarOwner>,
) => {
  const { entry } = props;

  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(null);

  if (!props) {
    return <div>data entry is missing</div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }
  const downloadInvoice = () => {
    if (!entry || !entry.id) {
      return;
    }
    const month = (selectedDate?.month() || 0) + 1;
    const name = `${entry.firstname}-${entry.lastname}`;
    BillingCarOwnerService.downloadPDFInvoice(entry.id, name, selectedDate?.year(), month);
  };

  return (
    <div>
      <Form.Item label="Download Proof of Charging">
        <DatePicker onChange={(date) => setSelectedDate(date)} picker="month" />
        <Button disabled={selectedDate === null} onClick={() => downloadInvoice()}>
          Download Proof of charge
        </Button>
      </Form.Item>

      <br />
      <br />
    </div>
  );
};

export default CarOwnerProofOfChargingTab;
