import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillRFIDCardsService from 'services/billing-v2/billing-v2-rfid-cards.service';
import { IBillingRFIDCard } from 'services/billing-v2/billing-v2-service.types';

import RfidCardActions from './components/RfidCardActions/RfidCardActions';
import RfidCardForm from './components/RfidCardForm/RfidCardForm';
import RfidCardCarOwnersTab from './tabs/RfidCardCarOwnersTab';

interface IParams {
  id: string;
  page: string;
}

export default function RfidCardDetailsPage() {
  const { id } = useParams<IParams>();
  const payload = {
    id,
  };
  const renderActions = (entry: IBillingRFIDCard) => <RfidCardActions entry={entry} />;

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <RfidCardForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'car-owners',
          displayName: 'Car Owners',
          render: (entry) => <RfidCardCarOwnersTab entry={entry} />,
        },
      ]}
      entityName="RFID card"
      baseLink={links.billRfidCard}
      defaultPayload={payload}
      renderActions={renderActions}
      resource={BillRFIDCardsService}
      renderTitle={(entry: IBillingRFIDCard) => `RFID:${entry.identifier}`}
    />
  );
}
