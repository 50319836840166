import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillChargingStationsService from 'services/billing-v3/billing-v3-charging-stations.service';

import columns from './constants';
import Filters from './filter';

const ChargingStationOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Charging stations"
    columns={columns}
    customFilters={Filters}
    resource={BillChargingStationsService}
  />
);
export default ChargingStationOverviewPage;
