import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Table, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import { FormInput } from 'components/forms';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { ChargingStationValidation } from 'pages/billing/charging-stations/charging-station.validation';
import { IBillingCarOwner, IBillingChargingStation } from 'services/billing/billing-service.types';
import ChargingStationService from 'services/billing/charging-station.service';
import BillCarOwnersService from 'services/billing-v2/billing-v2-car-owners.service';
import { IBillingAddress } from 'services/billing-v2/billing-v2-service.types';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from '../billing-create-flow.module.sass';

interface ICreateOrSelectChargingStationStepParams {
  currentStationStation?: IBillingChargingStation;
  carOwner: IBillingCarOwner;
  chargingStations?: IBillingChargingStation[];
  onSelect: (rfidCard: IBillingChargingStation) => void;
}

/**
 * This step should emit a carOwner Id.
 *
 *
 */
const CreateOrSelectChargingStationStep = (props: ICreateOrSelectChargingStationStepParams) => {
  const validation = Yup.object().shape(ChargingStationValidation);

  const [initialValues, setInitialValues] = useState({});
  const [userAddress, setUserAddress] = useState<IBillingAddress>();

  const { chargingStations, carOwner } = props;

  const updateInitialValues = (address: IBillingAddress | undefined) => {
    if (address) {
      setInitialValues({ ...initialValues, address });
      setUserAddress(address);
    }
  };

  const getUserAddress = async () => {
    if (!carOwner) return;

    try {
      const id = carOwner?.id || '';
      const response = await BillCarOwnersService.getById({ id });
      updateInitialValues(response?.address);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const createChargingStationAndSubmit = async (values: IBillingChargingStation) => {
    values.carOwner = props.carOwner;

    let chargingStation;
    try {
      chargingStation = await ChargingStationService.save(values);
      props.onSelect(chargingStation);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const columns = [
    {
      title: 'City',
      dataIndex: 'city',
    },
    {
      title: 'Label',
      dataIndex: 'label',
    },
    {
      title: 'Evse',
      dataIndex: 'evse',
    },
    {
      title: 'select',
      key: 'action',
      render: (entry: IBillingChargingStation) => (
        <Button onClick={() => props.onSelect(entry)}>Select</Button>
      ),
    },
  ];

  useEffect(() => {
    renderAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress]);

  const renderAddress = () => {
    return (
      <>
        <FormInput name="address.country" label="Country" />
        <FormInput name="address.iso3166_1_alpha_3" label="ISO 3166-1 alpha-3 Code" required />
        <FormInput name="address.region" label="Region" />
        <FormInput name="address.city" label="City" required />
        <FormInput name="address.address" label="Address" required />
        <FormInput name="address.postalCode" label="Postcode" required />
        <FormInput name="address.latitude" label="Latitude" />
        <FormInput name="address.longitude" label="Longitude" />
      </>
    );
  };

  return (
    <div>
      <div className={styles.entitySelector}>
        <Typography.Title className={styles.textCenter} level={5}>
          Select existing Charging Station
        </Typography.Title>

        {chargingStations && chargingStations.length > 0 ? (
          <Table dataSource={chargingStations} columns={columns} />
        ) : (
          'This car Owner has no charging stations yet, please create a new one'
        )}
      </div>
      <div>
        <Typography.Title className={styles.textCenter} level={5}>
          Create new Charging Station
        </Typography.Title>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          enableReinitialize
          onSubmit={(values: any) => createChargingStationAndSubmit(values)}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
              <Form.Item name="evse" label="Evse">
                <Input name="evse" placeholder="" />
              </Form.Item>
              <Form.Item name="label" label="Label">
                <Input name="label" placeholder="label" />
              </Form.Item>

              <Form.Item name="name" label="name">
                <Input name="name" placeholder="" />
              </Form.Item>

              <Form.Item name="type" label="type">
                <Select name="type">
                  <Select.Option value="home">home</Select.Option>
                  <Select.Option value="work">work</Select.Option>
                  <Select.Option value="public">public</Select.Option>
                </Select>
              </Form.Item>
              <div className={styles.centered}>
                <Button onClick={getUserAddress} disabled={!carOwner?.id}>
                  Autofill
                </Button>
              </div>
              {renderAddress()}

              <Form.Item {...CrudFormDefaultActionParams} name="submit">
                <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                  Submit
                </SubmitButton>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateOrSelectChargingStationStep;
