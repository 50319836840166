import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

import { IProductModel } from '../../store/tendegyService/tendergyService.types';

class ProductModelServiceClass extends EntityService<IProductModel> {
  getDisplayName(entry: IProductModel): string {
    return entry.name;
  }

  getOverallHardwareReport = (requestPayload?: IGetMultiEntityPayload) =>
    this.get(`${this.config.entityURL}/overall-report`, requestPayload);

  getHardwareReport = (productId: string, requestPayload?: IGetMultiEntityPayload) =>
    this.get(`${this.config.entityURL}/report/${productId}`, requestPayload);

  downloadOverallHardwareReport = (fileName: string, requestPayload?: IGetMultiEntityPayload) =>
    this.downloadFile(
      this.getURL(`${this.config.entityURL}/overall-report/download`),
      requestPayload,
      fileName,
    );

  downloadHardwareReport = (
    fileName: string,
    productModelId: string,
    requestPayload?: IGetMultiEntityPayload,
  ) =>
    this.downloadFile(
      this.getURL(`${this.config.entityURL}/report/${productModelId}/download`),
      requestPayload,
      fileName,
    );
}

const ProductModelService = new ProductModelServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/product-models',
  createLink: links.createProductModel,
  detailLink: links.productModel,
});

export default ProductModelService;
