import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { IISADiagnosticStep } from 'services/isa-service/isa-service.types';

interface IISADiagnosticStepsTabProps {
  diagnosticSteps: IISADiagnosticStep[];
}

export default function ISADiagnosticStepsTab(props: IISADiagnosticStepsTabProps) {
  const { diagnosticSteps } = props;
  const history = useHistory();

  function handleRowClick(value: IISADiagnosticStep) {
    return () => history.push(generatePath(links.isaDiagnosticStepsDetails, { id: value.id }));
  }

  return (
    <Table
      columns={[{ title: 'Diagnostic Step Title', dataIndex: 'title' }]}
      rowKey="id"
      dataSource={diagnosticSteps}
      onRow={(value) => ({ onClick: handleRowClick(value) })}
    />
  );
}
