import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import { formatDefaultDate } from 'utils/date.helper';

import OrganizationsFilters from '../components/OrganizationsFilters';

const columns = [
  {
    title: 'No.',
    dataIndex: 'displayId',
    key: 'displayId',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'name',
    render: (value: string) => value || '–',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'type',
    render: (type: string) => type || '–',
  },
  {
    title: 'State',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'state',
    key: 'state',
    render: (state: string) => state || '–',
  },
  {
    title: 'created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
    sorter: true,
    defaultSortOrder: 'descend' as SortOrder,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
  },
];

const TendergyOrganizationsPage: React.FC = () => {
  const payload: IGetMultiEntityPayload = {
    sortFields: 'createdAt',
    sortDirections: '-1',
  };

  return (
    <CrudListViewContainer
      defaultPayload={payload}
      entityName="Organizations"
      columns={columns}
      resource={TendergyOrganizationService}
      customFilters={OrganizationsFilters}
    />
  );
};

export default TendergyOrganizationsPage;
