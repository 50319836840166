import * as React from 'react';
import { Col, Divider, Radio, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { ICountriesDetail } from 'services/consulant/consultant-service.types';
import { formatDefaultDate } from 'utils/date.helper';

import { validationSchema } from './validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
interface IOpt {
  label: string;
  value: string;
}

interface Props {
  entry?: ICountriesDetail | null;
  onSave: (updatedValues: ICountriesDetail) => Promise<any>;
}

export enum LeadStatus {
  NEW = 'NEW',
  CONTACTED = 'CONTACTED',
}

export enum CurrencyType {
  EUR = 'EUR',
  USD = 'USD',
  CHF = 'CHF',
  NOK = 'NOK',
}
export enum CurrencySign {
  Euro = '€',
  Dollar = '$',
  SwitzerlandFranc = 'CHf',
  NorwayKrone = 'kr',
}
export enum DistanceUnit {
  Kilometers = 'km',
  Miles = 'mi',
}

export enum CountryStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Hidden = 'hidden',
}
export enum CountryLanguage {
  German = 'German',
  English = 'English',
  French = 'French',
  Italy = 'Italy',
}

const MapEnums = (Enum: any) =>
  Object.keys(Enum).reduce((acc: IOpt[], field) => {
    const value = Enum[field];
    return [...acc, { label: field, value }];
  }, []);

const LeadsDetailTab: React.FC<Props> = (props: Props) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }
  if (!entry) {
    return <div> Loading </div>;
  }
  return (
    <div>
      {entry && (
        <Formik
          initialValues={entry as ICountriesDetail}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSave(values as ICountriesDetail).then(() => setSubmitting(false));
          }}
        >
          {({ handleSubmit, isSubmitting, values, handleChange }) => {
            return (
              <>
                <Row>
                  <Col xs={6}>Created At: {formatDefaultDate(entry?.createdAt)}</Col>
                  <Col xs={6}>Updated At: {formatDefaultDate(entry?.updatedAt)}</Col>
                </Row>

                <Divider />
                <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
                  <Form.Item name="code" label="Country Code">
                    <Input disabled name="code" placeholder="" />
                  </Form.Item>
                  <Form.Item name="currency" label="Currency">
                    <Radio.Group
                      defaultValue={values.currency}
                      name={`currency`}
                      options={MapEnums(CurrencyType)}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item name="currencySign" label="Currency Sign">
                    <Radio.Group
                      defaultValue={values.currencySign}
                      name={`currencySign`}
                      options={MapEnums(CurrencySign)}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item name="distanceUnit" label="Distance Unit">
                    <Radio.Group
                      defaultValue={values.distanceUnit}
                      name={`distanceUnit`}
                      options={MapEnums(DistanceUnit)}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item name="language" label="Language">
                    <Radio.Group
                      defaultValue={values.language}
                      name={`language`}
                      options={MapEnums(CountryLanguage)}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item name="status" label="Status">
                    <Radio.Group
                      defaultValue={values.status}
                      name={`status`}
                      options={MapEnums(CountryStatus)}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item {...CrudFormDefaultActionParams} name="submit">
                    <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                      Submit
                    </SubmitButton>
                  </Form.Item>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default LeadsDetailTab;
