import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ISAProblemsService } from 'services/isa-service';
import { formatDefaultDate } from 'utils/date.helper';

export default function ISAProblemsOverview() {
  return (
    <CrudListViewContainer
      columns={[
        { title: 'Problem Title', dataIndex: 'value' },
        { title: 'Logical Name', dataIndex: 'logicalName' },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          render: (date: string) => formatDefaultDate(date),
        },
      ]}
      entityName="problems"
      resource={ISAProblemsService}
      withSorting
    />
  );
}
