import React from 'react';
import { Table } from 'antd';

import { formatCurrency } from 'framework/utils/currency';

export interface InvoicesStatistic {
  totalPools: number;
  completedOffers: number;
  completedVolume: number;
  pendingOffers: number;
  pendingVolume: number;
  totalOrganizations: number;
  totalOffers: number;
}

export interface StatisticTableProps {
  statistic?: InvoicesStatistic | null;
}

const columns = [
  {
    title: 'Pool Operator Organization #',
    dataIndex: 'totalOrganizations',
    key: 'totalOrganizations',
  },
  {
    title: 'Pool(s) #',
    dataIndex: 'totalPools',
    key: 'totalPools',
  },
  {
    title: 'Total transactions #',
    dataIndex: 'totalOffers',
    key: 'totalOffers',
  },
  {
    title: 'Pending transactions #',
    dataIndex: 'pendingOffers',
    key: 'pendingOffers',
  },
  {
    title: 'Pending Volume €',
    dataIndex: 'pendingVolume',
    key: 'pendingVolume',
    render: (pendingVolume: number) => formatCurrency(pendingVolume),
  },
  {
    title: 'Completed transactions #',
    dataIndex: 'completedOffers',
    key: 'completedOffers',
  },
  {
    title: 'Completed Volume €',
    dataIndex: 'completedVolume',
    key: 'completedVolume',
    render: (completedVolume: number) => formatCurrency(completedVolume),
  },
  {
    title: 'Total Earnings €*',
    dataIndex: 'totalEarnings',
    key: 'totalEarnings',
    render: (totalEarnings: number) => formatCurrency(totalEarnings),
  },
];

const StatisticTable: React.FC<StatisticTableProps> = ({ statistic }: StatisticTableProps) => {
  const tableProps = {
    rowKey: 'id',
    columns,
    dataSource: statistic ? [statistic] : [],
  };
  return <Table {...tableProps} pagination={false} />;
};

export default StatisticTable;
