import config from 'config';

import { AUTH_TOKEN_KEY } from 'framework/constants';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { showErrorNotification } from 'utils/notificationUtil';

import { IBillingInvoice, IBillingInvoicePayload } from './billing-v3-service.types';

class BillInvoicesServiceClass extends EntityService<IBillingInvoice> {
  getDisplayName(entry: IBillingInvoice): string {
    return entry.toString();
  }

  downloadInvoice(entry: IBillingInvoice) {
    const url = this.getURL(`admin/documents/download/${entry.id}`);
    this.downloadFile(url, {}, 'invoice.zip');
  }

  downloadZip = async (entry: IBillingInvoice) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const res = await this.get(`admin/invoices/${entry.id}/download/zip?token=${accessToken}`);
    if (res !== undefined) {
      window.open(`${this.getEntityUrl()}/${entry.id}/download/zip?token=${accessToken}`);
    } else {
      showErrorNotification('Failed to download invoice, try again later.');
    }
  };

  cancelInvoice = (entry: IBillingInvoice) => {
    const url = `${this.config.entityURL}/async-flow/${entry.id}/cancel`;
    this.post(url);
  };

  deleteInvoice = (invoiceId: string) => {
    const url = `${this.config.entityURL}/async-flow/${invoiceId}`;
    this.delete(url);
  };

  sendByEmail = (entry: IBillingInvoice, onlyChanged: boolean = false) => {
    const url = `${this.config.entityURL}/async-flow/${entry.id}/email${
      onlyChanged ? '?onlyChanged=true' : ''
    }`;
    this.post(url);
  };

  generateInvoice = (payload: IBillingInvoicePayload) => {
    const url = `${this.config.entityURL}/async-flow/calculate/organization/${payload.organizationId}?year=${payload.year}&month=${payload.month}`;
    this.post(url);
  };
}

const BillInvoicesService = new BillInvoicesServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/invoices',
  detailLink: links.billV3Invoice,
  createLink: links.billV3CreateInvoice,
});

export default BillInvoicesService;
