import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IShopProductAttribute } from 'services/shop/shop-service.types';

interface Props {
  attributes?: IShopProductAttribute[];
}

const columns = [
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
  },
  {
    title: 'Subtitle',
    key: 'subtitle',
    dataIndex: 'subtitle',
  },
  {
    title: 'Index',
    key: 'index',
    dataIndex: 'index',
  },
];

const ProductAttributesTab: React.FC<Props> = ({ attributes }: Props) => {
  const history = useHistory();

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={attributes}
      onRow={(record) => ({
        onClick: () => history.push(createLink(links.shopProductAttribute, { id: record.id })),
      })}
    />
  );
};

export default ProductAttributesTab;
