import React from 'react';
import { Button, Popconfirm } from 'antd';

import { IBillingInvoice } from 'services/billing/billing-service.types';

import styles from './InvoiceActionsComponent.module.sass';

interface Props {
  downloadZip: () => void;
  cancelInvoice: () => void;
  entry: IBillingInvoice;
}

const InvoiceActionsComponent: React.FC<Props> = (props: Props) => {
  const { downloadZip, cancelInvoice, entry } = props;

  return (
    <div className={styles.containerOuter}>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={downloadZip}
        >
          Download all
        </Button>
      </div>
      {entry.status === 'done' && (
        <div className={styles.containerInner}>
          <Popconfirm
            title="Are you sure you want to cancel this invoice?"
            onConfirm={cancelInvoice}
          >
            <Button className={styles.button} type="primary" size="large" shape="round">
              Cancel invoice
            </Button>
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default InvoiceActionsComponent;
