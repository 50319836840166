import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISASolutionsService } from 'services/isa-service';
import { IISASolution, IParams } from 'services/isa-service/isa-service.types';

import ISASolutionsForm from './components/ISASolutionsForm';
import ISASolutionStepsTab from './components/ISASolutionStepsTab';

export default function ISASolutionsDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISASolutionsForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'solutionSteps',
          displayName: 'Solution Steps',
          render: (entry: IISASolution) => (
            <ISASolutionStepsTab solutionSteps={entry.solutionSteps} />
          ),
        },
      ]}
      entityName="solution"
      baseLink={links.isaSolutionsDetails}
      defaultPayload={{ id, relations: ['solutionSteps'] }}
      resource={ISASolutionsService}
      hideJsonTab
    />
  );
}
