import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IGetDocumentsListPayload } from 'store/storeInterfaces';
import { ITendergyClientRequestPool } from 'store/tendegyService/tendergyService.types';

class ClientRequestPoolServiceClass extends EntityService<ITendergyClientRequestPool> {
  getDisplayName(entry: ITendergyClientRequestPool): string {
    return entry && entry.name;
  }

  getDocuments = (requestPayload: IGetDocumentsListPayload) => {
    return this.get(`${this.config.entityURL}/${requestPayload.id}/media/`, {
      category: requestPayload.category,
    });
  };
}

const ClientRequestPoolService = new ClientRequestPoolServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/client-request-pools',
  name: 'Client Request Pools',
  createLink: links.createClientRequestPool,
  detailLink: links.clientRequestPool,
});

export default ClientRequestPoolService;
