import { OfferEventType } from 'framework/constants';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import { IOffer } from '../tendergyService.types';

export const GET = 'offer/GET';
export const SET = 'offer/SET';
export const SET_LOADING_STATUS = 'offer/SET_LOADING_STATUS';
export const RESET = 'offer/RESET';
export const TRANSITION = 'offer/TRANSITION';

export interface OfferState {
  data: IOffer;
  loading: boolean;
}

export interface GetOfferAction {
  type: typeof GET;
  payload: IGetSingleEntityPayload;
}

export interface SetOfferAction {
  type: typeof SET;
  payload: IOffer;
}

export interface SetLoadingStatusAction {
  type: typeof SET_LOADING_STATUS;
  payload: boolean;
}

export interface ResetAction {
  type: typeof RESET;
}

export interface TransitionOfferAction {
  type: typeof TRANSITION;
  payload: {
    type: OfferEventType;
    id: string;
    rejectReason?: string;
  };
}

export type OfferActionTypes =
  | GetOfferAction
  | SetOfferAction
  | SetLoadingStatusAction
  | ResetAction
  | TransitionOfferAction;
