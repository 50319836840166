import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import StoreService from 'services/shop/shop-stores.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import StoreBaseStep from './steps/StoreBaseStep';

const StoreCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add store',
      description: 'required information',
      render: (entry, onSubmitStep) => <StoreBaseStep entry={entry} onSubmitStep={onSubmitStep} />,
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Store"
      steps={steps}
      baseLink={links.shopStores}
      defaultPayload={params}
      resource={StoreService}
    />
  );
};

export default StoreCreatePage;
