import * as React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import ChargingStationService from 'services/billing-v3/billing-v3-charging-stations.service';
import { CarOwnerPayload, IBillingCarOwner } from 'services/billing-v3/billing-v3-service.types';

interface Props {
  entry?: IBillingCarOwner;
}

const columns = [
  {
    title: 'EVSE',
    dataIndex: 'evse',
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Label',
    dataIndex: 'label',
  },
];

const ChargingStationsTab: React.FC<Props> = ({ entry }: Props) => {
  const payload: CarOwnerPayload = {
    carOwnerId: entry?.id,
  };

  return (
    <CrudListViewContainer
      entityName="Charging Stations"
      columns={columns}
      additionalPayload={payload}
      resource={ChargingStationService}
      disableSearch
    />
  );
};

export default ChargingStationsTab;
