import React from 'react';
import * as Yup from 'yup';

import { FormEntitySelect, FormikForm, FormSubmit } from 'components/forms';
import FormDatePicker from 'components/forms/FormDatePicker';
import { IFormikFormProps } from 'components/forms/FormikForm';
import FormSwitch from 'components/forms/FormSwitch/FormSwitch';
import BillCarOwnersService from 'services/billing-v2/billing-v2-car-owners.service';
import BillRFIDCardsService from 'services/billing-v2/billing-v2-rfid-cards.service';
import {
  IBillingCarOwner,
  IBillingCarOwnerRFIDCardsRelationship,
  IBillingRFIDCard,
} from 'services/billing-v2/billing-v2-service.types';

interface BillOrganizationFormProps {
  carOwner?: IBillingCarOwner;
  rfidCard?: IBillingRFIDCard;
  isEnabled?: boolean;
  onSubmit: (
    entry: IBillingCarOwnerRFIDCardsRelationship,
  ) => Promise<IBillingCarOwnerRFIDCardsRelationship>;
}
export const validation = {
  // want to validFrom be always less that validTo if validTo exists.
  validFrom: Yup.date()
    .required('Required')
    .when('validTo', (validTo: Date, schema: any) =>
      validTo ? schema.max(validTo, 'Valid from must be before valid to') : schema,
    ),
  validTo: Yup.date(),
  carOwner: Yup.object().required('Required'),
  rfidCard: Yup.object().required('Required'),
};
const AttachRfidCardCarOwnerForm = (props: BillOrganizationFormProps) => {
  const { carOwner, rfidCard, onSubmit, isEnabled } = props;
  const formikProps: IFormikFormProps<IBillingCarOwnerRFIDCardsRelationship> = {
    onSubmit,
    validationSchema: Yup.object(validation),
    initialValues: {
      rfidCard: rfidCard,
      carOwner: carOwner,
      isEnabled: isEnabled,
    } as IBillingCarOwnerRFIDCardsRelationship,
  };

  return (
    <FormikForm {...formikProps}>
      <FormEntitySelect
        entityService={BillCarOwnersService}
        name="carOwner"
        label="Car Owner"
        current={carOwner}
        required
      />
      <FormEntitySelect
        entityService={BillRFIDCardsService}
        name="rfidCard"
        label="RFID Card"
        current={rfidCard}
        required
      />
      <FormDatePicker name="validFrom" label="Valid from" required />
      <FormDatePicker name="validTo" label="Valid to" required />
      <FormSwitch name="isEnabled" label="Is Enabled" required />

      <FormSubmit />
    </FormikForm>
  );
};

export default AttachRfidCardCarOwnerForm;
