import React from 'react';

import { FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import FormSwitch from 'components/forms/FormSwitch/FormSwitch';
import { IBillingRFIDCard } from 'services/billing-v2/billing-v2-service.types';

import { validationSchema } from '../../validation';

interface RfidCardFormProps {
  entry?: IBillingRFIDCard;
  onSubmit: (entry: IBillingRFIDCard) => Promise<IBillingRFIDCard>;
}

const RfidCardForm = (props: RfidCardFormProps) => {
  const { entry, onSubmit } = props;

  const handleSubmit = (entry: IBillingRFIDCard) => {
    entry.fullname = entry.fullname ? entry.fullname : '';
    entry.username = entry.username ? entry.username : '';
    entry.isPrivate = entry.isPrivate ? entry.isPrivate : false;
    entry.evcoId = entry.evcoId ? entry.evcoId : '';
    onSubmit(entry);
  };

  const formikProps: IFormikFormProps<IBillingRFIDCard> = {
    onSubmit: handleSubmit,
    validationSchema,
    initialValues: entry as IBillingRFIDCard,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="identifier" label="Identifier" required />
      <FormInput name="evcoId" label="EVCO Id" />
      <FormInput name="username" label="Username" />
      <FormInput name="fullname" label="Fullname" />
      <FormSwitch name="isPrivate" label="Private" required />
      <FormSubmit />
    </FormikForm>
  );
};

export default RfidCardForm;
