export const AUTH_TOKEN_KEY = 'token';
export const FINGERPRINT = 'fingerprint';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const REFRESH_TOKEN_EXPIRES_KEY = 'refreshTokenExpires';

export enum UserRole {
  User = 'user',
  Admin = 'admin',

  TendergyInstaller = 'installer',
  TendergyInstallerManager = 'installer manager',
  TendergyIntermediateManager = 'intermediate manager',

  StoreAdmin = 'store admin',
}

export enum ClientRequestState {
  None = 'none',
  Initiated = 'initiated',
  Cancelled = 'cancelled',
  Submitted = 'submitted',
  ApprovalPending = 'approvalPending',
  PendingDocument = 'pendingDocument',
  OfferSelectionHomeCheck = 'offerSelectionHomeCheck',
  OfferSelectionInstallation = 'offerSelectionInstallation',
  OrderSubmitted = 'orderSubmitted',
  HomeCheck = 'homeCheck', //Either the home check is pending or it is done but the customer has not accepted the installation offer yet
  Approved = 'approved',
  OfferSelection = 'offerSelection',
  InstallationPending = 'installationPending', //The customer has accepted an offer, even if appointments are not yet decided
  PaymentPending = 'paymentPending',
  Done = 'done',
}

export enum ClientRequestEventType {
  COORDINATOR_ACCEPTED = 'COORDINATOR_ACCEPTED',
  COORDINATOR_REJECTED = 'COORDINATOR_REJECTED',
}

export enum OfferState {
  None = 'none',
  TenderCreated = 'tenderCreated',
  TenderCreatedWithoutAppointments = 'tenderCreatedWithoutAppointments',
  TenderSubmitted = 'tenderSubmitted',
  TenderSubmittedHomeCheck = 'tenderSubmittedHomeCheck',
  TenderSubmittedRemoteHomeCheck = 'tenderSubmittedRemoteHomeCheck',
  TenderAcceptedRemoteHomeCheck = 'tenderAcceptedRemoteHomeCheck',
  TenderCheckingAccept = 'tenderCheckingAccept',
  TenderCheckingAcceptHomeCheck = 'tenderCheckingAcceptedHomeCheck',
  TenderCancelled = 'tenderCancelled',
  TenderAccepted = 'tenderAccepted',
  TenderRejected = 'tenderRejected',
  TenderAcceptedHomeCheck = 'tenderAcceptedHomeCheck',
  OfferAcceptedHomeCheckAppointmentNA = 'offerAcceptedHomeCheckAppointmentNA',
  OfferAcceptedRemoteHomeCheckAppointmentNA = 'offerAcceptedRemoteHomeCheckAppointmentNA',
  OfferAcceptedHomeCheckAppointmentTBD = 'offerAcceptedHomeCheckAppointmentTBD',
  OfferAcceptedRemoteHomeCheckAppointmentTBD = 'offerAcceptedRemoteHomeCheckAppointmentTBD',
  OfferAccepted = 'offerAccepted',
  OfferAcceptedHomeCheck = 'offerAcceptedHomeCheck',
  OfferAcceptedRemoteHomeCheck = 'offerAcceptedRemoteHomeCheck',
  OfferHomeCheckPending = 'offerHomeCheckPending',
  OfferRemoteHomeCheckPending = 'offerRemoteHomeCheckPending',
  OfferSubmitted = 'offerSubmitted',
  OfferRejected = 'offerRejected',
  OfferRejectedHomeCheck = 'offerRejectedHomeCheck',
  InvoiceRejectedInstallation = 'invoiceRejectedInstallation',
  InvoiceRejectedHomeCheck = 'invoiceRejectedHomeCheck',
  InstallationPending = 'installationPending',
  InstallationDone = 'installationDone',
  Review = 'review',
  Completed = 'completed',
  InvoiceCheckingSubmitHomeCheck = 'invoiceCheckingSubmitHomeCheck',
  InvoiceSubmittedHomeCheck = 'invoiceSubmittedHomeCheck',
  InvoiceSubmittedRemoteHomeCheck = 'invoiceSubmittedRemoteHomeCheck',
  InvoiceAcceptedHomeCheck = 'invoiceAcceptedHomeCheck',
  InvoiceAcceptedRemoteHomeCheck = 'invoiceAcceptedRemoteHomeCheck',
  InvoicePaymentPendingHomeCheck = 'invoicePaymentPendingHomeCheck',
  InvoicePaymentPendingRemoteHomeCheck = 'invoicePaymentPendingRemoteHomeCheck',
  InvoicePaidHomeCheck = 'invoicePaidHomeCheck',
  InvoicePaidRemoteHomeCheck = 'invoicePaidRemoteHomeCheck',
  OfferHomeCheckDone = 'offerHomeCheckDone',
  OfferRemoteHomeCheckDone = 'offerRemoteHomeCheckDone',
  InvoiceCheckingSubmitInstallation = 'invoiceCheckingSubmitInstallation', //Temporary state to check if invoice can be submitted directly without pool operator replace
  InvoiceSubmittedInstallation = 'invoiceSubmittedInstallation',
  InvoiceAcceptedInstallation = 'invoiceAcceptedInstallation',
  HomeCheckInvoiceRequested = 'homeCheckInvoiceRequested',
}

export enum OfferEventType {
  INITIATED = 'INITIATED',
  INSTALLER_ACCEPTED = 'INSTALLER_ACCEPTED',
  INSTALLER_ACCEPTED_HOME_CHECK = 'INSTALLER_ACCEPTED_HOME_CHECK',
  INSTALLER_CANCELLED = 'INSTALLER_CANCELLED',
  INSTALLER_REJECTED = 'INSTALLER_REJECTED',
  INSTALLER_CONFIRMED = 'INSTALLER_CONFIRMED',
  COORDINATOR_ACCEPTED = 'COORDINATOR_ACCEPTED',
  COORDINATOR_REJECTED = 'COORDINATOR_REJECTED',
  CUSTOMER_SELECTED_DATE = 'CUSTOMER_SELECTED_DATE',
  INSTALLER_OFFER_CREATED = 'INSTALLER_OFFER_CREATED',
  INSTALLER_APPOINTMENT_CREATED = 'INSTALLER_APPOINTMENT_CREATED',
  CUSTOMER_APPOINTMENT_CONFIRMED = 'CUSTOMER_APPOINTMENT_CONFIRMED',
  CUSTOMER_ACCEPTED = 'CUSTOMER_ACCEPTED',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
  INSTALLER_WORK_COMPLETED = 'INSTALLER_WORK_COMPLETED',
  INSTALLER_WORK_CANCELLED = 'INSTALLER_WORK_CANCELLED',
  HOME_CHECK_DONE = 'HOME_CHECK_DONE',
  INSTALLER_UPLOAD_DOCS = 'INSTALLER_UPLOAD_DOCS',
}

export enum eConsultantLeadStatus {
  New = 'NEW',
  Contacted = 'CONTACTED',
  Successful = 'SUCCESSFUL',
  Obsolete = 'OBSOLETE',
  NotSuccessful = 'NOT_SUCCESSFUL',
}
export enum invoicesStatus {
  Done = 'done',
  Calculating = 'calculating',
  CalculationDone = 'calculation_done',
  Pending = 'pending',
  Canceled = 'canceled',
  Error = 'error',
  InProgress = 'inprogress',
  GeneratingBill = 'generating_bill',
  WaitingForFeedback = 'waiting_for_feedback',
}
