import * as React from 'react';
import { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import LandingsService from 'services/shop/shop-landings.service';
import { IShopLanding, IShopStore } from 'services/shop/shop-service.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

const columns = [
  {
    title: 'Key',
    dataIndex: 'key',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

interface LandingTabProps {
  store: IShopStore;
}

const StoreLandingsTab: React.FC<LandingTabProps> = (props: LandingTabProps) => {
  const [landings, setLandings] = useState<IShopLanding[]>();
  const history = useHistory();
  const { store } = props;
  const params = {
    storeId: store?.id,
  };

  const tableProps = {
    dataSource: landings,
    columns: columns,
    onRow: (record: any) => ({
      onClick: () =>
        history.push(generatePath(links.shopLanding, { id: record.id }), { storeId: store?.id }),
    }),
  };

  const getLandings = async () => {
    try {
      // @ts-ignore
      const response = await LandingsService.getEntries({ ...params });
      setLandings(response?.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    getLandings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Table {...tableProps} />;
};

export default StoreLandingsTab;
