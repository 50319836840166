import produce from 'immer';

import {
  OrganizationActionTypes,
  OrganizationState,
  RESET,
  SET,
  SET_LOADING_STATUS,
  SET_MEMBERS,
} from './organization.types';

const initialState: OrganizationState = {
  entry: null,
  pagination: null,
  loading: false,
  members: [],
};

const OrganizationReducer = (state = initialState, action: OrganizationActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET:
        draft.entry = action.payload;
        break;
      case SET_MEMBERS:
        draft.members = action.payload;
        break;
      case SET_LOADING_STATUS:
        draft.loading = action.payload;
        break;
      case RESET:
        draft.entry = initialState.entry;
        draft.pagination = initialState.pagination;
        draft.loading = initialState.loading;
        break;
    }
  });

export default OrganizationReducer;
