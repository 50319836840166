import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Pagination,
  Popconfirm,
  Row,
  Table,
  TableProps,
  Tag,
} from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import BillingRfidCardRelationService from 'services/billing/billing-car-owner-rfid-card-relation.service';
import { IBillingRFIDCardCarOwnerRelationship } from 'services/billing/billing-service.types';
import { IBillingCarOwner } from 'services/billing-v2/billing-v2-service.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import styles from './styles.module.sass';

interface Props {
  entry?: IBillingCarOwner;
}

const RfidCardCarOwnersTab: React.FC<Props> = ({ entry }: Props) => {
  const history = useHistory();
  const [count, setCount] = useState<number>();
  const [entries, setEntries] = useState<IBillingRFIDCardCarOwnerRelationship[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [payload, setPayload] = useState<any>({
    limit: 10,
    offset: 0,
    rfidCardId: entry?.id,
  });

  useEffect(() => {
    setPayload({
      ...payload,
      offset: (currentPage - 1) * payload.limit!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const refreshEntries = async () => {
    try {
      const response = await BillingRfidCardRelationService.getEntries({ ...payload });
      setEntries(response?.items);
      setCount(response?.total || response?.count);
    } catch (error) {
      showErrorNotification(error);
    }
  };

  useEffect(() => {
    refreshEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.offset]);

  async function dettachRfidCard(relationship: IBillingRFIDCardCarOwnerRelationship) {
    try {
      await BillingRfidCardRelationService.deleteById({ id: relationship.id });
      showSuccessNotification('Successfully dettached');
      showSuccessNotification('reload in 3 seconds');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (e) {
      showErrorNotification(e);
    }
  }
  const handleChangeEnable = (card: IBillingRFIDCardCarOwnerRelationship) => {
    updateRelationship(card);
  };

  async function updateRelationship(relationship: IBillingRFIDCardCarOwnerRelationship) {
    if (!relationship?.validFrom) {
      return showErrorNotification('Valid from date should be filled');
    }
    const validFromDate = new Date(relationship.validFrom!);
    const validtoDate = new Date(relationship.validTo!);
    const formattedFromDate = validFromDate.toISOString();
    const formattedToDate = validtoDate.toISOString();
    if (relationship.validTo && formattedFromDate > formattedToDate) {
      return showErrorNotification('Valid from date should be less than valid to date');
    }

    try {
      const update = {
        ...relationship,
        id: relationship.id,
        validFrom: relationship.validFrom,
        validTo: relationship.validTo,
        rfidCard: {
          id: relationship?.rfidCard?.id,
        },
        carOwner: {
          id: relationship?.carOwner?.id,
        },
      };

      const resp: any = await BillingRfidCardRelationService.save(update as any);
      if (resp?.status) {
        return showErrorNotification(resp?.message);
      }
      showSuccessNotification('Successfully saved');
      showSuccessNotification('Reload in 2 seconds');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (e) {
      showErrorNotification(e);
    }
  }

  const navigateToCarOwner = (carOwner?: IBillingCarOwner) => {
    history.push(createLink(links.billCarOwner, { id: carOwner?.id }));
  };

  const isActiveRow = (row: IBillingRFIDCardCarOwnerRelationship): boolean => {
    const now = new Date().getTime();

    return moment(now).isBetween(row.validFrom, row.validTo);
  };

  const actions = (row: IBillingRFIDCardCarOwnerRelationship) => (
    <Row>
      <Button onClick={() => navigateToCarOwner(row.carOwner)}>Open Car Owner</Button>
      <Popconfirm
        title="Are you sure you want to delete this relationship?"
        onConfirm={() => dettachRfidCard(row)}
      >
        <Button
          style={{ marginLeft: '10px' }}
          type="primary"
          shape="round"
          danger
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Row>
  );

  const changableDate = (date: Date, onChange: Function) => {
    return (
      <DatePicker
        onChange={(newDate) => onChange(newDate ? newDate.toDate() : null)}
        // @ts-ignore
        defaultValue={date ? moment(date) : ''}
      />
    );
  };

  const columns = [
    {
      title: 'Car owner',
      dataIndex: 'carOwner',
      render: (carOwner: IBillingCarOwner) => `${carOwner?.firstname} ${carOwner?.lastname}`,
      width: 150,
      fixed: true,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Status',
      render: (date: any, row: IBillingRFIDCardCarOwnerRelationship) => {
        if (row.validTo === null) {
          return <Tag color="yellow">To Validate</Tag>;
        }
        return isActiveRow(row) ? <Tag color="green">Active</Tag> : <Tag color="red">Expired</Tag>;
      },
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Valid from',
      dataIndex: 'validFrom',
      render: (date: Date, card: IBillingRFIDCardCarOwnerRelationship) =>
        changableDate(date, (newDate?: Date) => {
          card.validFrom = newDate;
          updateRelationship(card);
        }),
      width: 200,
    },
    {
      title: 'Valid to',
      dataIndex: 'validTo',
      render: (date: Date, card: IBillingRFIDCardCarOwnerRelationship) => {
        console.log('date', date, card);
        return changableDate(date, (newDate?: Date) => {
          card.validTo = newDate;
          updateRelationship(card);
        });
      },
      width: 200,
    },
    {
      title: 'Is Enanbled',
      dataIndex: 'isEnanbled',
      render: (date: Date, card: IBillingRFIDCardCarOwnerRelationship) => {
        console.log(card);
        return (
          <Checkbox
            checked={card.isEnabled}
            onChange={(e: any) => handleChangeEnable({ ...card, isEnabled: e.target.checked })}
          >
            is RFID Enabled
          </Checkbox>
        );
      },
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      width: 200,
    },
    {
      title: 'Actions',
      render: (any: any, row: IBillingRFIDCardCarOwnerRelationship) => actions(row),
      width: 250,
    },
  ];

  const tableProps: TableProps<IBillingRFIDCardCarOwnerRelationship> = {
    scroll: { x: '100%' },
    columns: columns,
    dataSource: entries,
    pagination: false,
  };

  const paginationProps: PaginationProps = {
    total: count,
    showTotal: (total) => `Total ${total} items`,
    showSizeChanger: false,
    current: currentPage,
    onChange: setCurrentPage,
    showQuickJumper: true,
    defaultPageSize: payload.limit,
  };

  if (!entry) {
    return <div>Rfid card data is missing</div>;
  }

  return (
    <>
      <Table {...tableProps} />
      <div className={styles.pagination}>
        <Pagination {...paginationProps} />
      </div>
    </>
  );
};

export default RfidCardCarOwnersTab;
