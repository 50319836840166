import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Col, Input, Row, Select, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { Loader } from 'components/ui';
import { DATE_AND_TIME } from 'framework/constants/dateFormats';
import links from 'framework/links';
import TendergyFeedbackService from 'services/tendergy/tendergy-feedback.service';
import TendergyInstallerService from 'services/tendergy/tendergy-installer.service';
import TendergyOfferService from 'services/tendergy/tendergy-offer.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import TendergyClientRequestsService from 'services/tendergy/tendergy-request.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import {
  FeedbackReceiverType,
  FeedbackReferenceType,
  IClientRequest,
  IOffer,
  ITendergyFeedback,
  ITendergyOrganization,
  ITendergyUser,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

const ITEMS_PER_PAGE = 10;

const columns = [
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Comment',
    dataIndex: 'text',
    key: 'text',
    render: (text: string) => text || '–',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Reference Type',
    dataIndex: 'referenceType',
    key: 'referenceType',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Reference Id',
    dataIndex: 'referenceId',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'referenceId',
    render: (referenceId: string, row: any) => {
      return row?.reference?.displayId || referenceId || '–';
    },
  },
  {
    title: 'Receiver Type',
    dataIndex: 'receiverType',
    key: 'receiverType',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Receiver Id',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'receiverId',
    key: 'receiverId',
    render: (receiverId: string) => receiverId || '–',
  },
  {
    title: 'Author',
    dataIndex: 'author',
    sorter: true,
    sortParameter: 'author.firstName',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'author',
    render: (author: ITendergyUser) => (author ? `${author.firstName} ${author.lastName}` : '–'),
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (createdAt: string) => (createdAt ? formatDefaultDate(createdAt, DATE_AND_TIME) : '–'),
  },
];

export enum LoadingFields {
  Rating,
  Author,
  ReferenceType,
  ReferenceId,
  ReceiverType,
  ReceiverId,
}

export enum RatingOperator {
  Less = 'lessThanRating',
  Equal = 'rating',
  Greater = 'greaterThanRating',
}

const FeedbackOverviewPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [feedback, setFeedback] = useState<ITendergyFeedback[] | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(+(query.get('page') || '1'));
  const [rating, setRating] = useState<string | null>(
    query.get('lessThanRating') || query.get('rating') || query.get('greaterThanRating'),
  );
  const [author, setAuthor] = useState<string | null>(query.get('author'));
  const [referenceType, setReferenceType] = useState<string | null>(query.get('referenceType'));
  const [referenceId, setReferenceId] = useState<string | null>(query.get('referenceId'));
  const [receiverType, setReceiverType] = useState<string | null>(query.get('receiverType'));
  const [receiverId, setReceiverId] = useState<string | null>(query.get('receiverId'));
  const [comment, setComment] = useState<string | null>(query.get('query'));
  const [loading, setLoading] = useState<LoadingFields | null>(null);
  const getInitialOperator = () => {
    if (query.get('lessThanRating')) {
      return RatingOperator.Less;
    }
    if (query.get('greaterThanRating')) {
      return RatingOperator.Greater;
    }
    return RatingOperator.Equal;
  };
  const [operotor, setOperator] = useState<RatingOperator>(getInitialOperator());

  const params: any = {
    [operotor]: rating && rating,
    author: author && author,
    query: comment && comment,
    referenceType: referenceType && referenceType,
    referenceId: referenceId && referenceId,
    receiverType: receiverType && receiverType,
    receiverId: receiverId && receiverId,
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
    sortFields: 'createdAt',
    sortDirections: -1,
  };

  const changeUrl = () => {
    const data = Object.entries({
      ...params,
      limit: undefined,
      offset: undefined,
      sortFields: undefined,
      sortDirections: undefined,
      page: params.offset / ITEMS_PER_PAGE + 1,
    });
    const searchParams = data
      .filter((value: any[]) => value[1])
      .map(([key, value]: any[]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    history.push(`${links.feedback}?${searchParams}`);
  };

  const getFeedback = async () => {
    try {
      const response = await TendergyFeedbackService.getEntries({ ...params });
      setLoading(null);
      setFeedback(response.items);
      setTotal(response.total || 0);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    setPage(1);
    changeUrl();
    getFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating, author, referenceType, referenceId, receiverType, comment, receiverId]);

  useEffect(() => {
    setLoading(LoadingFields.Rating);
    setPage(1);
    changeUrl();
    getFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operotor]);

  useEffect(() => {
    changeUrl();
    getFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!feedback) {
    return <Loader />;
  }

  const ratingSelectProps = {
    placeholder: 'Select Rating',
    onChange: (value: string) => {
      setRating(value);
      setLoading(LoadingFields.Rating);
    },
    style: { width: '100%' },
    allowClear: true,
    options: ['1', '2', '3', 4, '5'].map((v) => ({ value: v, label: v })),
    loading: loading === LoadingFields.Rating,
    value: rating || undefined,
  };

  const operatorSelectProps = {
    onChange: (value: string) => {
      setOperator(value as RatingOperator);
    },
    style: { width: '100%' },
    options: [
      { value: RatingOperator.Equal, label: 'Rating equal' },
      { value: RatingOperator.Less, label: 'Rating less then' },
      { value: RatingOperator.Greater, label: 'Rating greater then' },
    ],
    loading: loading === LoadingFields.Rating,
    value: operotor,
  };

  const authorEntitySelectorProps = {
    entityService: TendergyUserService,
    placeholder: 'Select Author',
    name: 'author',
    onChange: (userId: string) => {
      setAuthor(userId);
      setLoading(LoadingFields.Author);
    },
    allowClear: true,
    loading: loading === LoadingFields.Author,
    value: author,
    style: { width: '100%' },
  };

  const referenceTypeSelectProps = {
    placeholder: 'Select Reference Type',
    onChange: (value: FeedbackReferenceType) => {
      setReferenceType(value);
      setLoading(LoadingFields.ReferenceType);
      setReferenceId(null);
    },
    style: { width: '100%' },
    allowClear: true,
    options: [
      { value: FeedbackReferenceType.ClientRequests, label: 'Client Requests' },
      { value: FeedbackReferenceType.Offers, label: 'Offers' },
    ],
    loading: loading === LoadingFields.ReferenceType,
    value: referenceType as FeedbackReferenceType,
  };

  const receiverTypeSelectProps = {
    placeholder: 'Select Receiver Type',
    onChange: (value: FeedbackReceiverType) => {
      setReceiverType(value);
      setReceiverId(null);
      setLoading(LoadingFields.ReceiverType);
    },
    style: { width: '100%' },
    allowClear: true,
    options: [
      { value: FeedbackReceiverType.Installers, label: 'Installers' },
      { value: FeedbackReceiverType.Organizations, label: 'Organizations' },
      { value: FeedbackReceiverType.Platform, label: 'Platform' },
    ],
    loading: loading === LoadingFields.ReceiverType,
    value: receiverType as FeedbackReceiverType,
  };

  const commentInputProps = {
    placeholder: 'Comment...',
    onChange: (event: any) => setComment(event.target.value),
    allowClear: true,
    value: comment || undefined,
  };

  const baseReferenceIdProps = {
    name: 'referenceId',
    allowClear: true,
    onClear: () => {
      setReferenceId(null);
      setLoading(LoadingFields.ReferenceId);
    },
    loading: loading === LoadingFields.ReferenceId,
    value: referenceId,
    style: { width: '100%' },
  };

  const offersEntitySelectorProps = {
    ...baseReferenceIdProps,
    placeholder: 'Select Offer',
    entityService: TendergyOfferService,
    onSelect: (offer: IOffer) => {
      setReferenceId(offer?.id || null);
      setLoading(LoadingFields.ReferenceId);
    },
  };

  const clientRequestsEntitySelectorProps = {
    ...baseReferenceIdProps,
    placeholder: 'Select Client Request',
    entityService: TendergyClientRequestsService,
    onSelect: (clientRequests: IClientRequest) => {
      setReferenceId(clientRequests?.id || null);
      setLoading(LoadingFields.ReferenceId);
    },
  };

  const baseReceiverIdProps = {
    name: 'receiverId',
    allowClear: true,
    onClear: () => {
      setReceiverId(null);
      setLoading(LoadingFields.ReceiverId);
    },
    loading: loading === LoadingFields.ReceiverId,
    value: receiverId,
    style: { width: '100%' },
  };

  const installerEntitySelectorProps = {
    ...baseReceiverIdProps,
    placeholder: 'Select Installer',
    entityService: TendergyInstallerService,
    onSelect: (user: ITendergyUser) => {
      setReceiverId(user?.id || null);
      setLoading(LoadingFields.ReceiverId);
    },
  };

  const organizationsEntitySelectorProps = {
    ...baseReceiverIdProps,
    placeholder: 'Select Organization',
    entityService: TendergyOrganizationService,
    onSelect: (organization: ITendergyOrganization) => {
      setReceiverId(organization?.id || null);
      setLoading(LoadingFields.ReceiverId);
    },
  };

  const tableProps = {
    rowKey: 'id',
    columns,
    dataSource: feedback,
    pagination: {
      total,
      defaultPageSize: ITEMS_PER_PAGE,
      current: page,
      onChange: (page: number) => setPage(page),
      showSizeChanger: false,
    },
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Select {...operatorSelectProps} />
        </Col>
        <Col span={4}>
          <Select {...ratingSelectProps} />
        </Col>
        <Col span={8}>
          <Input {...commentInputProps} />
        </Col>
        <Col span={8}>
          <EntitySelector {...authorEntitySelectorProps} />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Select {...referenceTypeSelectProps} />
        </Col>
        {referenceType === FeedbackReferenceType.Offers && (
          <Col span={6}>
            <EntitySelector {...offersEntitySelectorProps} />
          </Col>
        )}
        {referenceType === FeedbackReferenceType.ClientRequests && (
          <Col span={6}>
            <EntitySelector {...clientRequestsEntitySelectorProps} />
          </Col>
        )}
        <Col span={6}>
          <Select {...receiverTypeSelectProps} />
        </Col>
        {receiverType === FeedbackReceiverType.Installers && (
          <Col span={6}>
            <EntitySelector {...installerEntitySelectorProps} />
          </Col>
        )}
        {receiverType === FeedbackReceiverType.Organizations && (
          <Col span={6}>
            <EntitySelector {...organizationsEntitySelectorProps} />
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Table {...tableProps} />
        </Col>
      </Row>
    </>
  );
};

export default FeedbackOverviewPage;
