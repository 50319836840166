import React from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { Formik, FormikConfig, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';

import { CrudFormDefaultParams } from 'framework/abstract-crud/abstractCrud.constants';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import AutoSubmitButton from './AutoSubmitButton';

export interface IFormikFormProps<T> extends FormikConfig<T> {
  children?: React.ReactNode;
  onSubmit: (values: T) => void | Promise<T>;
  className?: string;
  successLabel?: string;
  buttonsContainerId?: string;
  autoSave?: boolean;
}

function FormikForm<T>(props: IFormikFormProps<T>) {
  const {
    onSubmit,
    children,
    className = '',
    successLabel,
    buttonsContainerId,
    autoSave,
    ...rest
  } = props;
  const [buttonsContainer, setButtonsContainer] = React.useState<Element | null>(null);

  React.useEffect(() => {
    buttonsContainerId && setButtonsContainer(document.getElementById(buttonsContainerId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(values: T, actions: FormikHelpers<T>) {
    if (onSubmit) {
      try {
        await onSubmit(values);
        successLabel && showSuccessNotification(successLabel);
      } catch (error) {
        showErrorNotification(error);
      }
    }

    actions.setSubmitting(false);
  }

  const formikProps: FormikConfig<T> = {
    onSubmit: handleSubmit,
    ...rest,
  };

  return (
    <Formik {...formikProps}>
      <Form {...CrudFormDefaultParams} className={clsx({ [className]: !!className })}>
        {children}
        {buttonsContainer &&
          ReactDOM.createPortal(<AutoSubmitButton autoSave={autoSave} />, buttonsContainer)}
      </Form>
    </Formik>
  );
}

export default FormikForm;
