import React from 'react';

import { FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { IISAAnswer } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISAAnswer;
  onSubmit: (entry: IISAAnswer) => Promise<IISAAnswer>;
}

export default function ISAAnswersForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISAAnswer> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISAAnswer,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="value" label="Answer Title" required />
      <FormInput name="logicalName" label="Logical Name" required />
      <FormSubmit />
    </FormikForm>
  );
}
