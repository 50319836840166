import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Dropdown, Menu, message, Modal, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import * as Yup from 'yup';

import FormBuilder from 'components/forms/FormBuilder/FormBuilder';
import schemaBuilder, { uiSchemaBuilder } from 'components/forms/FormBuilder/helper';
import links from 'framework/links';
import { QuestionnaireStepType } from 'pages/tendergy/questionnaire-steps/questionnaireTypes';
import { QuestionnaireValidation } from 'pages/tendergy/questionnaires/questionnaire.validation';
import { ITendergyQuestionnaireStep } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

import completeUiSchemaStructure from './completeUiSchemaStructure.json';

interface Props {
  entry: ITendergyQuestionnaireStep;
  onSave: (step: ITendergyQuestionnaireStep) => void;
  onDelete: () => void;
}

const QuestionnaireStepOverviewTab: React.FC<Props> = (props: Props) => {
  const Ajv = require('ajv');
  const ajv = new Ajv({ allErrors: true });
  require('ajv-errors')(ajv);

  const { entry, onSave, onDelete } = props;
  const [pastedQuestions, setPastedQuestions] = useState();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const isCopyPasteDisabled = entry.parent === null;
  if (!props) {
    return <div>data entry is missing</div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  const onOk = (values: ITendergyQuestionnaireStep, setSubmitting: Function) => {
    let questionnaireStep: ITendergyQuestionnaireStep = { ...values };

    try {
      const uiSchema = uiSchemaBuilder(values.uiSchema);
      const validated = validateUiSchema(uiSchema);
      if (validated) {
        questionnaireStep.uiSchema = uiSchema;
        if (values.uiSchema) questionnaireStep.schema = schemaBuilder(uiSchema);
        onSave(questionnaireStep);
        setVisible(false);
        setSubmitting(false);
        message.success('Step successfully updated');
      }
    } catch (error) {
      showErrorNotification(error);
    }
  };

  const validateUiSchema = (uiSchema: any) => {
    const validate = ajv.compile(completeUiSchemaStructure);
    const valid = validate(uiSchema);
    if (!valid) {
      showErrorNotification(validate.errors[0].message);
      return false;
    }
    return true;
  };

  const onCancel = (setSubmitting: Function, resetForm: Function) => {
    setVisible(false);
    setSubmitting(false);
    resetForm();
  };

  const handleCopyQuestions = () => {
    navigator.clipboard.writeText(JSON.stringify(entry));
    message.success('Questions copied successfully, paste them in another step');
  };

  const handlePasteQuestions = async (setFieldValue: Function) => {
    const pastedData = await navigator.clipboard.readText();
    const stepData = JSON.parse(pastedData);
    if (stepData.uiSchema) {
      const uiSchema = uiSchemaBuilder(stepData.uiSchema);
      const validated = validateUiSchema(uiSchema);
      if (validated) {
        setFieldValue('uiSchema', stepData.uiSchema);
        setPastedQuestions(stepData.uiSchema);
        message.success('Questions pasted successfully, submit the changes');
      } else {
        message.error('Questions data is not valid');
      }
    }
  };

  const validation = Yup.object().shape(QuestionnaireValidation);
  const isShowStepTypeSelect = !entry.parentId;
  const questionnaireStepsLink = entry
    ? // @ts-ignore
      `${links.tendergyQuestionnaires}/${entry.versionedQuestionnaireId.id}/${entry.versionedQuestionnaireId.version}/steps`
    : '';

  return (
    <div>
      <Formik
        initialValues={entry as ITendergyQuestionnaireStep}
        onSubmit={() => {}}
        validationSchema={validation}
      >
        {({ handleSubmit, values, setSubmitting, setFieldValue, resetForm }) => (
          <Form
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 24 }}
            labelAlign={'left'}
            onFinish={handleSubmit}
          >
            <Card
              title={
                <Breadcrumb>
                  <Breadcrumb.Item>Tendergy</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <a href="/tendergy/questionnaires">Questionnaires</a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <a href={questionnaireStepsLink}>steps</a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Step</Breadcrumb.Item>
                </Breadcrumb>
              }
              extra={
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="0" onClick={() => setVisible(true)}>
                        Submit
                      </Menu.Item>
                      <Modal
                        title="CONFIRM SUBMIT"
                        visible={visible}
                        onOk={() => onOk(values, setSubmitting)}
                        onCancel={() => onCancel(setSubmitting, resetForm)}
                      >
                        <p>
                          Editing this config incorrectly can cause questionnaire to fail, are you
                          sure you want to do this?
                        </p>
                      </Modal>
                      <Menu.Item key="1" onClick={() => setVisibleDelete(true)}>
                        Delete
                      </Menu.Item>
                      <Modal
                        title="CONFIRM DELETE"
                        visible={visibleDelete}
                        onOk={() => onDelete()}
                        onCancel={() => setVisibleDelete(false)}
                      >
                        <p>{'Are you sure you want to delete this step?'}</p>
                      </Modal>
                      <Menu.Item
                        key="2"
                        onClick={handleCopyQuestions}
                        disabled={isCopyPasteDisabled}
                      >
                        Copy questions
                      </Menu.Item>
                      <Menu.Item
                        key="3"
                        onClick={() => handlePasteQuestions(setFieldValue)}
                        disabled={isCopyPasteDisabled}
                      >
                        Paste questions
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>
                    Actions <DownOutlined />
                  </Button>
                </Dropdown>
              }
            >
              <Row>
                <Col span={10}>
                  <Form.Item name="title" label="Title">
                    <Input name="title" />
                  </Form.Item>
                </Col>
                <Col span={10} offset={1}>
                  <Form.Item name="order" label="Order">
                    <Input name="order" type={'number'} />
                  </Form.Item>
                </Col>
              </Row>

              <Col span={10}>
                {isShowStepTypeSelect ? (
                  <Form.Item name="stepType" label="Type">
                    <Select name="stepType">
                      {[
                        QuestionnaireStepType.QuickCheck,
                        QuestionnaireStepType.User,
                        QuestionnaireStepType.CostCalculation,
                        QuestionnaireStepType.RemoteHomeCheck,
                      ].map((type) => (
                        <Select.Option value={type} key={type}>
                          {type}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : null}
              </Col>
              {props.entry.parent && (
                <FormBuilder
                  data={pastedQuestions ? pastedQuestions : entry.uiSchema || undefined}
                  onChange={(uiSchema: any) => {
                    setFieldValue('uiSchema', uiSchema);
                  }}
                />
              )}
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionnaireStepOverviewTab;
