import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillInvoicesService from 'services/billing-v3/billing-v3-invoices.service';
import { IBillingInvoice, IParams } from 'services/billing-v3/billing-v3-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import InvoiceActionsComponent from './components/InvoiceActionsComponent/InvoiceActionsComponent';
import InvoiceDetailsTab from './tabs/InvoiceDetailsTab';

const InvoiceDetailsPage: React.FC = () => {
  const { id } = useParams<IParams>();
  const [cancelIsDone, setCancelIsDone] = useState(false);
  const history = useHistory();

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['documents', 'invoiceAttempts'],
  };

  const downloadZip = async (entry: IBillingInvoice) => {
    BillInvoicesService.downloadZip(entry);
  };

  const cancelInvoice = async (entry: IBillingInvoice) => {
    try {
      await BillInvoicesService.cancelInvoice(entry);
      setCancelIsDone(true);
      showSuccessNotification('Invoice documents cancelled successfully');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const sendByEmail = async (entry: IBillingInvoice, onlyChanged: boolean) => {
    try {
      await BillInvoicesService.sendByEmail(entry, onlyChanged);
      showSuccessNotification('Invoice documents were sent by email successfully');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const deleteInvoice = async (invoiceId: string) => {
    try {
      await BillInvoicesService.deleteInvoice(invoiceId);
      showSuccessNotification('Invoice deleted successfully');
      history.go(-1);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IBillingInvoice) => <InvoiceDetailsTab entry={entity} />,
    },
  ];
  const renderActions = (entry: IBillingInvoice) => (
    <InvoiceActionsComponent
      cancelIsDone={cancelIsDone}
      entry={entry}
      downloadZip={() => downloadZip(entry)}
      cancelInvoice={() => cancelInvoice(entry)}
      sendDocsByEmail={(onlyChanged: boolean) => sendByEmail(entry, onlyChanged)}
      deleteInvoice={(invoiceId: string) => deleteInvoice(invoiceId)}
    />
  );

  const renderTitle = (entry: IBillingInvoice) => {
    return `${moment()
      .month(entry.month - 1)
      .format('MMMM')} ${entry.year} - ${entry.status}`;
  };

  return (
    <CrudDetailContainer
      entityName="Invoice"
      tabs={tabs}
      legacy
      renderActions={renderActions}
      renderTitle={renderTitle}
      baseLink={links.billInvoice}
      defaultPayload={params}
      hideJsonTab
      resource={BillInvoicesService}
      shouldHideDelete={true}
    />
  );
};

export default InvoiceDetailsPage;
