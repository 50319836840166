import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IBillingCarOwner } from 'services/billing-v2/billing-v2-service.types';

import GridwareModal from '../GridwareModal/GridwareModal';

import styles from './styles.module.sass';

interface ActionsComponentProps {
  entry: IBillingCarOwner;
  sendToGrid: Function;
}

const CarOwnerActionsComponent: React.FC<ActionsComponentProps> = ({
  entry,
  sendToGrid,
}: ActionsComponentProps) => {
  const history = useHistory();
  const [isModalOpen, toggleModal] = useState<boolean>(false);

  return (
    <>
      <div className={styles.containerInner}>
        {entry.organization && (
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={() =>
              history.push(createLink(links.billOrganization, { id: entry.organization?.id }))
            }
          >
            Go to organization
          </Button>
        )}
      </div>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push({ pathname: links.billAttachRfidCardCarOwner }, { carOwner: entry })
          }
        >
          Attach RFID Card to Car Owner
        </Button>
      </div>

      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() => toggleModal(true)}
        >
          Create in Gridware
        </Button>
      </div>
      <GridwareModal toggleModal={toggleModal} isModalOpen={isModalOpen} sendToGrid={sendToGrid} />
    </>
  );
};
export default CarOwnerActionsComponent;
