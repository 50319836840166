import { ColumnsType } from 'antd/lib/table';

import { IConsultantParameters } from 'services/consulant/consultant-service.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IConsultantParameters> = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Work Comfort Ratio',
    dataIndex: 'workComfortRatio',
    key: 'workComfortRatio',
  },
  {
    title: 'Lead Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Worst Case Factor',
    dataIndex: 'worstCaseFactor',
    key: 'worstCaseFactor',
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (date: string) => formatDefaultDate(date),
  },

    {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'created',
        render: (date: string) => formatDefaultDate(date),
    },
];

export default columns;
