import { all, fork } from 'redux-saga/effects';

import auth from './auth/auth.sagas';
import organization from './authService/organization/organization.sagas';
import organizations from './authService/organizations/organizations.sagas';
import user from './authService/user/user.sagas';
import users from './authService/users/users.sagas';
import offer from './tendegyService/offer/offer.sagas';
import offers from './tendegyService/offers/offers.sagas';
import request from './tendegyService/request/request.sagas';

export default function* root() {
  yield all([
    fork(auth),
    fork(users),
    fork(user),
    fork(request),
    fork(offers),
    fork(offer),
    fork(organizations),
    fork(organization),
  ]);
}
