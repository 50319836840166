import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { IAuthPermission } from 'store/authService/authService.types';

class AuthPermissionServiceClass extends EntityService<IAuthPermission> {
  getDisplayName(entry: IAuthPermission): string {
    return entry && entry.name;
  }
}

const AuthPermissionService = new AuthPermissionServiceClass({
  apiURL: config.PERMISSION_SERVICE_URL,
  entityURL: 'admin/permissions',
  // createLink: links.createPermission,
  // detailLink: links.authPermission,
});

export default AuthPermissionService;
