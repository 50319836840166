import React, { useEffect, useState } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Spin, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import { Loader } from 'components/ui';
import { BASE_DATE_FORMAT } from 'framework/constants/dateFormats';
import { formatCurrency } from 'framework/utils/currency';
import IntermediatesService from 'services/tendergy/tendergy-intermediates.service';
import { showErrorNotification } from 'utils/notificationUtil';

const { RangePicker } = DatePicker;

export interface OrganizationStatistic {
  completedOffers: string;
  completedVolume: string;
  id: string;
  name: string;
  pendingOffers: string;
  pendingVolume: string;
  totalOffers: string;
  totalPools: string;
  totalRequests: string;
  variableFee: string;
  totalEarnings: string;
}

const columns = [
  {
    title: 'Total transactions',
    dataIndex: 'totalOffers',
    key: 'totalOffers',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: '# of pending transactions',
    dataIndex: 'pendingOffers',
    key: 'pendingOffers',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Pending Volume in €',
    dataIndex: 'pendingVolume',
    key: 'pendingVolume',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (pendingVolume: number) => formatCurrency(pendingVolume),
  },
  {
    title: '# of completed transactions',
    dataIndex: 'completedOffers',
    key: 'completedOffers',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Completed Volume in €',
    dataIndex: 'completedVolume',
    key: 'completedVolume',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (completedVolume: number) => formatCurrency(completedVolume),
  },
  {
    title: 'Variable Fee %',
    dataIndex: 'variableFee',
    key: 'variableFee',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (variableFee: number) => `${variableFee || '5.00'}%`,
  },
  {
    title: 'Variable Fee €',
    dataIndex: 'totalEarnings',
    key: 'totalEarnings',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (totalEarnings: number) => formatCurrency(totalEarnings),
  },
];

const OrganizationStatisticTable: React.FC = () => {
  const [organizationsStatistic, setOrganizationsStatistic] = useState<
    OrganizationStatistic[] | null
  >(null);

  const [organization, setOrganization] = useState<{ name: string; id: string } | null>(null);
  const [pool, setPool] = useState<{ name: string; id: string } | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const [limit, setLimit] = useState<number>(10);

  useEffect(() => {
    setPage(1);
    setLoading(true);
    getOrganizationsStatistic();
  }, [pool, organization, fromDate, toDate, limit]); // eslint-disable-line

  useEffect(() => {
    setLoading(true);
    getOrganizationsStatistic();
  }, [page]); // eslint-disable-line

  const params: any = {
    fromDate,
    toDate,
    limit,
    offset: (page - 1) * limit,
  };

  const getOrganizationsStatistic = async () => {
    try {
      let response;
      if (pool) {
        response = await IntermediatesService.getRequestsStatistic(pool.id, params);
      } else if (organization) {
        response = await IntermediatesService.getPoolsStatistic(organization.id, params);
      } else {
        response = await IntermediatesService.getOrganizationsStatistic(params);
      }
      setOrganizationsStatistic(response?.items);
      setTotal(response.total);
      setLoading(false);
    } catch (e) {
      showErrorNotification(e);
      setLoading(false);
    }
  };

  if (!organizationsStatistic) {
    return <Loader />;
  }

  const getColumns = () => {
    if (pool) {
      return [
        {
          title: 'Request',
          dataIndex: 'name',
          key: 'name',
        },
        ...columns,
      ];
    }
    if (organization) {
      return [
        {
          title: 'Pool',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Request',
          dataIndex: 'totalRequests',
          key: 'totalRequests',
        },
        ...columns,
      ];
    }
    return [
      {
        title: 'Pool Operator Org.',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Pool(s)',
        dataIndex: 'totalPools',
        key: 'totalPools',
      },
      {
        title: 'Request(s)',
        dataIndex: 'totalRequests',
        key: 'totalRequests',
      },
      ...columns,
    ];
  };

  const tableProps = {
    rowKey: 'id',
    columns: getColumns(),
    dataSource: organizationsStatistic,
    pagination: {
      total,
      defaultPageSize: limit,
      current: page,
      onChange: (page: number, pageSize: number | undefined = 10) => {
        setPage(page);
        setLimit(pageSize);
      },
      showSizeChanger: true,
    },
    onRow: (record: any) => ({
      onClick: !pool
        ? () => {
            if (!organization) {
              setOrganization({
                name: record.name,
                id: record.id,
              });
            } else {
              setPool({
                name: record.name,
                id: record.id,
              });
            }
          }
        : undefined,
    }),
  };

  const renderTitle = () => {
    if (pool) {
      return `In client request pool: ${pool.name}`;
    }
    if (organization) {
      return `In organization: ${organization.name}`;
    }
    return 'All organizations';
  };

  const onBackClick = () => {
    if (pool) {
      setPool(null);
    } else {
      setOrganization(null);
    }
  };

  const getFileName = () => {
    let name = 'Invoices Report';
    if (pool) {
      name += ` by poll ${pool.name}`;
    } else if (organization) {
      name += ` by organization ${organization.name}`;
    }
    if (toDate && fromDate) {
      name += ` (${moment(toDate).format(BASE_DATE_FORMAT)} - ${moment(fromDate).format(
        BASE_DATE_FORMAT,
      )})`;
    }
    return `${name}.csv`;
  };

  const onExportClick = async () => {
    try {
      if (pool) {
        await IntermediatesService.downloadRequestsStatistic(pool.id, getFileName(), params);
      } else if (organization) {
        await IntermediatesService.downloadPoolsStatistic(organization.id, getFileName(), params);
      } else {
        await IntermediatesService.downloadOrganizationsStatistic(getFileName(), params);
      }
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const dateRangePickerProps = {
    value: [fromDate ? moment(fromDate) : null, toDate ? moment(toDate) : null] as any,
    style: { margin: '0 10px' },
    onChange: (dates: any) => {
      setFromDate(dates ? dates[0].toISOString() : null);
      setToDate(dates ? dates[1].toISOString() : null);
    },
  };

  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col>
        <h2>{renderTitle()}</h2>
      </Col>
      <Col>
        {organization && <Button onClick={onBackClick}>Back</Button>}
        <RangePicker {...dateRangePickerProps} picker="date" />
        <Button onClick={onExportClick} icon={<FileExcelOutlined />}>
          Export to Excel
        </Button>
      </Col>
      <Col span={24}>
        <Spin tip="Loading..." spinning={loading}>
          <Table {...tableProps} />
        </Spin>
      </Col>
    </Row>
  );
};

export default OrganizationStatisticTable;
