import * as React from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';

import { FormEntitySelect, FormSelect, FormSubmit } from 'components/forms';
import FormDatePicker from 'components/forms/FormDatePicker';
import FormSwitch from 'components/forms/FormSwitch/FormSwitch';
import { CrudFormDefaultParams } from 'framework/abstract-crud/abstractCrud.constants';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import {
  InviteLinkType,
  ITendergyClientRequestPool,
} from 'store/tendegyService/tendergyService.types';

import { InvitationLinkValidation } from '../../../invitation-links/invitation-link.validation';

interface IUserInvitationLinkStep {
  entry: ITendergyClientRequestPool | null;
  onSubmitStep: (entry: ITendergyClientRequestPool) => void;
  saveInviteLink: Function;
}

const UserInvitationLinkStep: React.FC<IUserInvitationLinkStep> = ({
  entry,
  saveInviteLink,
  onSubmitStep,
}: IUserInvitationLinkStep) => {
  const validation = Yup.object().shape(InvitationLinkValidation);

  return (
    <Formik
      initialValues={(entry ? { clientRequestPool: entry } : {}) as any}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        saveInviteLink({ ...values, clientRequestPool: entry });
        entry && onSubmitStep(entry);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, errors, values }) => (
        <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
          <FormSelect
            name="type"
            label="Type"
            required
            initialValue={InviteLinkType.user}
            options={[InviteLinkType.user, InviteLinkType.installer]}
          />

          {values.type === InviteLinkType.installer && (
            <FormEntitySelect
              entityService={TendergyOrganizationService}
              name="organization"
              label="Organization"
            />
          )}
          <FormDatePicker name="expires" label="Expire date" />
          <FormSwitch name="isEnabled" label="Is Enabled" />
          <Form.Item required name="clientRequestPool" label="Client Request Pool">
            {entry ? entry.name : 'To continue create a Client Request Pool in the previous tab'}
          </Form.Item>

          <FormSubmit disabled={!isEmpty(errors)} />
        </Form>
      )}
    </Formik>
  );
};

export default UserInvitationLinkStep;
