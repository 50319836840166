import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISAWallboxesService } from 'services/isa-service';

import ISAWallboxesForm from './components/ISAWallboxesForm';

export default function ISAWallboxesCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Wallbox',
          render: (_entry, onSubmit) => <ISAWallboxesForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="wallbox"
      baseLink={links.isaWallboxesOverview}
      resource={ISAWallboxesService}
    />
  );
}
