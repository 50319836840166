import * as React from 'react';

interface Props {
  entry: any;
  title: string;
}

const JsonOneEntryTab: React.FC<Props> = ({ entry, title }: Props) => {
  if (!entry) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <h4>{title}</h4>
      <pre>{JSON.stringify(entry, null, 2)}</pre>
    </div>
  );
};

export default JsonOneEntryTab;
