import React, { useState } from 'react';
import { Button, Card, DatePicker, Popconfirm, Typography } from 'antd';
import moment, { Moment } from 'moment';

import BillInvoicesService from 'services/billing-v2/billing-v2-invoices.service';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import styles from './styles.module.sass';

const InvoiceCreatePage: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [selectedYear, setSelectedYear] = useState<string>();

  const generateInvoice = () => {
    if (selectedMonth && selectedYear) {
      try {
        BillInvoicesService.generateInvoice({ month: selectedMonth, year: selectedYear });
        showSuccessNotification('Invoice Generated Successfully');
      } catch (e) {
        showErrorNotification(e);
      }
    }
  };

  const onSelectDate = (date: Moment | null) => {
    setSelectedMonth((moment(date).month() + 1).toString());
    setSelectedYear(moment(date).year().toString());
  };

  return (
    <div className={styles.wrapper}>
      <Card title="Generate invoice" bordered={false} className={styles.card}>
        <div className={styles.wrapper}>
          <Typography className={styles.generateText}>
            Please select month & year to new generate invoice:
          </Typography>
          <DatePicker picker="month" onChange={(date: Moment | null) => onSelectDate(date)} />
          <Popconfirm
            title="Are you sure you want to generate new invoice?"
            onConfirm={generateInvoice}
          >
            <Button type="primary" shape="round" className={styles.generateButton}>
              Generate
            </Button>
          </Popconfirm>
        </div>
      </Card>
    </div>
  );
};

export default InvoiceCreatePage;
