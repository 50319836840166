import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'antd';
import { Formik, FormikProps, FormikValues } from 'formik';
import _merge from 'lodash/merge';
import * as yup from 'yup';

import ButtonTypeSelector from 'components/forms/ButtonTypeSelector/ButtonTypeSelector';
import CustomizeNumberInput from 'components/forms/CustomizeNumberInput/CustomizeNumberInput';
import CustomizeStoreInput from 'components/forms/CustomizeStoreInput/CustomizeStoreInput';
import FontPickerComponent from 'components/forms/FontPicker/FontPicker';
import ImageComponent from 'components/forms/ImageComponent/ImageComponent';
import { Loader } from 'components/ui';
import { ColorPalette } from 'components/ui/ColorPalette/ColorPalette';
import {
  IStoreCustomizationOption,
  IStoreCustomizationScheme,
} from 'services/shop/shop-service.types';
import StoreService from 'services/shop/shop-stores.service';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from './StoreCustomizationContainerStyles.module.sass';

export interface IStoreCustomizationProps {
  scheme: IStoreCustomizationScheme;
}

interface ISchemaObjectValue {
  primaryValue?: yup.StringSchema;
  inverseValue?: yup.StringSchema;
  value?: yup.StringSchema | yup.NumberSchema;
}

interface ISchemaObject {
  [key: string]: yup.ObjectSchema<any>;
}

const genSchema = (theme: IStoreCustomizationScheme) => {
  if (!theme) {
    return yup.object().shape({});
  }
  const schemaObject: ISchemaObject = {};
  const stringRequired = yup.string().required();
  Object.entries(theme).map(([key, { type }]) => {
    const propertyObject: ISchemaObjectValue = {};
    if (type === 'color') {
      propertyObject.primaryValue = stringRequired;
      propertyObject.inverseValue = stringRequired;
    }

    if (type === 'text') {
      propertyObject.value = stringRequired;
    }

    if (type === 'fontfamily') {
      propertyObject.value = stringRequired;
    }

    if (type === 'buttonType') {
      propertyObject.value = stringRequired;
    }

    if (type === 'number') {
      propertyObject.value = yup.number().min(0).truncate().required();
    }
    schemaObject[key] = yup.object(propertyObject as any);
    return true;
  });

  return yup.object().shape(schemaObject);
};

interface IContentProps extends IStoreCustomizationOption {
  name: string;
}

const Content = ({ name, type, ...elseProps }: IContentProps) => {
  switch (type) {
    case 'fontfamily':
      return <FontPickerComponent entry={elseProps} name={`${name}.value`} />;
    case 'text':
      return <CustomizeStoreInput entry={elseProps} name={`${name}.value`} />;
    case 'image':
      return <ImageComponent entry={{ ...elseProps, type }} />;
    case 'buttonType':
      return <ButtonTypeSelector entry={elseProps} name={`${name}.value`} />;
    case 'number':
      return (
        <CustomizeNumberInput
          entry={elseProps}
          minValue={0}
          maxValue={6}
          step={6}
          name={`${name}.value`}
        />
      );
    default:
      return null;
  }
};

const StoreCustomizationContainer: React.FC<IStoreCustomizationProps> = (
  props: IStoreCustomizationProps,
) => {
  const { scheme } = props;

  const [schema, setSchema] = useState<IStoreCustomizationScheme>();
  const [validationSchema, setValidationSchema] = useState(genSchema(scheme));
  const { id } = useRouteMatch().params as any;

  const getSchema = async () => {
    try {
      const response = await StoreService.getSchema(id);
      setSchema(response.scheme);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    getSchema();
  }, []); //eslint-disable-line

  useEffect(() => {
    setValidationSchema(genSchema(schema as IStoreCustomizationScheme));
  }, [schema]);

  const onSubmit = async (values: FormikValues) => {
    const payload = {
      id,
      scheme: { scheme: _merge(schema, values) },
    };
    await StoreService.saveSchema(payload);
  };

  if (!schema) {
    return <Loader />;
  }
  const renderContent = () => {
    const {
      colors,
      customizations,
    }: {
      colors: IStoreCustomizationScheme;
      customizations: IStoreCustomizationScheme;
    } = Object.entries(schema).reduce(
      (acc, [key, value]) => {
        const accType = value.type === 'color' ? 'colors' : 'customizations';
        // @ts-ignore
        acc[accType][key] = value;

        return acc;
      },
      { colors: {}, customizations: {} },
    );

    return (
      <>
        {Object.entries(customizations).map(([key, value]) => (
          <Content key={key} name={key} {...value} />
        ))}
        <ColorPalette scheme={colors} label="COLORS" />
      </>
    );
  };

  return (
    <div>
      <Formik onSubmit={onSubmit} initialValues={schema || {}} validationSchema={validationSchema}>
        {({ isValid, submitForm }: FormikProps<FormikValues>) => (
          <>
            {renderContent()}
            <div className={styles.buttonWrapper}>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={submitForm}
                disabled={!isValid}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
export default StoreCustomizationContainer;
