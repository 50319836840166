import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IFleetAppUsers } from 'services/fleet-app/fleet-app-service.types';
import FleetAppUsersService from 'services/fleet-app/fleet-app-users.service';

const columns: ColumnsType<IFleetAppUsers> = [
  {
    title: 'Full name',
    dataIndex: 'firstName',
    render: (_, entry) => `${entry?.firstName} ${entry?.lastName}`,
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Has Temporary Password',
    dataIndex: 'isTemporaryPassword',
    render: (_, entry) => (entry?.isTemporaryPassword ? 'Yes' : 'No'),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const FleetAppUsersListPage: React.FC = () => (
  <CrudListViewContainer entityName="Users" columns={columns} resource={FleetAppUsersService} />
);
export default FleetAppUsersListPage;
