import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IBillingEnergyPrice } from 'services/billing/billing-service.types';

import styles from './styles.module.sass';

interface IProps {
  entry: IBillingEnergyPrice;
  triggerReload: Function;
}

const EnergyPricesActionsComponent: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();

  const { entry } = props;

  return (
    <div className={styles.containerInner}>
      {entry.organization && (
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push(
              createLink(links.billingOrganization, {
                id: entry?.organization?.id,
              }),
            )
          }
        >
          Go to Billing Organization
        </Button>
      )}
    </div>
  );
};
export default EnergyPricesActionsComponent;
