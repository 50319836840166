import React from 'react';

import { IStoreCustomizationOption } from 'services/shop/shop-service.types';

import styles from '../FontPicker/FontPickerStyles.module.sass';

interface ImageComponentProps {
  entry: IStoreCustomizationOption;
}

const ImageComponent: React.FC<ImageComponentProps> = (props: ImageComponentProps) => {
  const { entry } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{entry.title.toUpperCase()}</div>
      {/* @ts-ignore */}
      <a href={entry.value}>{entry.value}</a>
    </div>
  );
};

export default ImageComponent;
