import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import SurveyService from 'services/tendergy/tendergy-survey.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergySurvey } from 'store/tendegyService/tendergyService.types';

import SurveyOverviewTab from './tabs/SurveyOverviewTab';

const SurveyDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: ITendergySurvey, optionalProps?: ICrudDetailViewCallbacks) => (
        <SurveyOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  return (
    <CrudDetailContainer
      entityName="Survey"
      tabs={tabs}
      baseLink={links.survey}
      defaultPayload={params}
      resource={SurveyService}
    />
  );
};

export default SurveyDetailsPage;
