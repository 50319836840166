import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IEmailTemplate } from 'services/email/email-service.types';
import EmailTemplatesService from 'services/email/email-templates.service';
import { formatDefaultDate } from 'utils/date.helper';

import EmailTemplatesFilters from '../components/EmailTemplatesFilters';

const columns: ColumnsType<IEmailTemplate> = [
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Language',
    dataIndex: 'language',
    key: 'language',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Subject',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'subject',
    key: 'subject',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const defaultPayload = {
  language: 'de',
};

const TemplatesOverviewPage: React.FC = () => (
  <CrudListViewContainer
    defaultPayload={defaultPayload as any}
    disableSearch
    customFilters={EmailTemplatesFilters}
    entityName="Templates"
    columns={columns}
    resource={EmailTemplatesService}
  />
);
export default TemplatesOverviewPage;
