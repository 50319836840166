import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { ClientRequestEventType } from 'framework/constants';
import links from 'framework/links';
import CommentsService from 'services/tendergy/tendergy-comments.service';
import TendergyRequestService from 'services/tendergy/tendergy-request.service';
import * as actions from 'store/tendegyService/request/request.actions';
import { IClientRequest, ITendergyComment } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import ClientRequestActionsComponent from './components/ClientRequestActionsComponent/ClientRequestActionsComponent';
import ClientRequestOverviewTab from './tabs/ClientRequestOverviewTab/ClientRequestOverviewTab';
import CommentsTab from './tabs/CommentsTab/CommentsTab';
import DocumentsTab from './tabs/DocumentsTab/DocumentsTab';
import HistoryTab from './tabs/HistoryLogsTab/HistoryTab';
import OffersTab from './tabs/OffersTab/OffersTab';
import QATab from './tabs/QATab/QATab';

interface IParams {
  id: string;
  page: string;
}

export default function ClientRequestDetailsPage() {
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const [rejectReason, setRejectReason] = useState('');
  const [comments, setComments] = useState<ITendergyComment[]>();

  const getComments = async () => {
    const commentsParams: any = {
      commentableId: id,
      commentableType: 'client_requests',
    };
    try {
      const response = await CommentsService.getEntries({ ...commentsParams });

      setComments(response?.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const rejectRequest = (triggerReload: Function) => {
    const payload = {
      type: ClientRequestEventType.COORDINATOR_REJECTED,
      id,
      rejectReason,
    };

    dispatch(actions.transition(payload));
    showSuccessNotification('Client request rejected successfully.');

    setTimeout(() => {
      triggerReload();
    }, 300);
  };

  const acceptRequest = (triggerReload: Function) => {
    const payload = {
      type: ClientRequestEventType.COORDINATOR_ACCEPTED,
      id,
    };

    dispatch(actions.transition(payload));
    showSuccessNotification('Client request accepted successfully.');

    setTimeout(() => {
      triggerReload();
    }, 300);
  };

  useEffect(() => {
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const payload = {
    id,
    relations: ['answers', 'owner', 'pool', 'offers', 'questionnaire'],
  };

  const renderActions = (entry: IClientRequest, triggerReload: Function) => (
    <ClientRequestActionsComponent
      rejectReason={rejectReason}
      setRejectReason={setRejectReason}
      rejectRequest={() => rejectRequest(triggerReload)}
      acceptRequest={acceptRequest}
      entry={entry}
    />
  );

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => (
            <ClientRequestOverviewTab entry={entry} onSubmit={onSave} />
          ),
        },
        {
          name: 'offers',
          displayName: 'Offers',
          render: (entry) => <OffersTab clientRequest={entry} />,
        },
        {
          name: 'qa',
          displayName: 'Q&A',
          render: (entry: IClientRequest) => <QATab answers={entry?.answers} />,
        },
        {
          name: 'comments',
          displayName: 'Comments',
          render: () => <CommentsTab comments={comments} />,
        },
        {
          name: 'documents',
          displayName: 'Documents',
          render: (entity: IClientRequest, optionalProps?: ICrudDetailViewCallbacks) => (
            <DocumentsTab
              documents={optionalProps?.documents}
              deleteFile={optionalProps?.deleteFile}
              uploadFile={optionalProps?.uploadFile}
            />
          ),
        },
        {
          name: 'historyLogs',
          displayName: 'History Logs',
          render: (entry) => <HistoryTab clientRequest={entry} />,
        },
      ]}
      entityName="client request"
      baseLink={links.request}
      defaultPayload={payload}
      resource={TendergyRequestService}
      renderActions={renderActions}
      renderTitle={(entry: IClientRequest) => (entry?.displayId ? entry.displayId : '')}
      shouldGetFiles
    />
  );
}
