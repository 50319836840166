import { IPagination } from 'framework/models';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergyUser } from 'store/tendegyService/tendergyService.types';

import { IAuthUser } from '../authService.types';
import {
  GET_AUTHUSER,
  GET_AUTHUSERS,
  GET_CRAFTUSER,
  RESET,
  SET_CRAFTUSER,
  SET_LOADING_STATUS,
  SET_USER_AUTH,
  SET_USERS,
  UserActionTypes,
} from './user.types';

export function getCraftUser(payload: IGetSingleEntityPayload): UserActionTypes {
  return {
    type: GET_CRAFTUSER,
    payload,
  };
}

export function setCraftUser(payload: ITendergyUser): UserActionTypes {
  return {
    type: SET_CRAFTUSER,
    payload,
  };
}

export function setUsers(payload: IPagination<IAuthUser>): UserActionTypes {
  return {
    type: SET_USERS,
    payload,
  };
}

export function getUsers(): UserActionTypes {
  return {
    type: GET_AUTHUSERS,
  };
}

export function getAuthUser(payload: IGetSingleEntityPayload): UserActionTypes {
  return {
    type: GET_AUTHUSER,
    payload,
  };
}

export function setAuthUser(payload: IAuthUser): UserActionTypes {
  return {
    type: SET_USER_AUTH,
    payload,
  };
}

export function setLoadingStatus(payload: boolean): UserActionTypes {
  return {
    type: SET_LOADING_STATUS,
    payload,
  };
}

export function reset(): UserActionTypes {
  return {
    type: RESET,
  };
}
