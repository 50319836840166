import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { ColumnsType } from 'antd/lib/table';

import links from 'framework/links';
import QuestionnaireConfigService from 'services/tendergy/questionnaire-config.service';
import {
  ITendergyClientRequestPool,
  ITendergyQuestionnaireConfig,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

interface IPoolQuestionnaireConfigsTab {
  entry?: ITendergyClientRequestPool;
}

const ClientRequestPoolQuestionnaireConfigsTab: React.FC<IPoolQuestionnaireConfigsTab> = (
  props: IPoolQuestionnaireConfigsTab,
) => {
  const { entry } = props;
  const [questionnaireConfigs, setQuestionnaireConfigs] = useState<
    ITendergyQuestionnaireConfig[] | null
  >(null);

  const history = useHistory();

  const params: any = {
    poolId: entry && entry.id,
  };

  const getQuestionnaireConfigs = async () => {
    try {
      const response = await QuestionnaireConfigService.getEntries({ ...params });
      setQuestionnaireConfigs(response?.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    getQuestionnaireConfigs();
  }, []); // eslint-disable-line

  const columns: ColumnsType<ITendergyQuestionnaireConfig> = [
    {
      title: 'Question Key',
      dataIndex: 'questionKey',
      key: 'questionKey',
      width: 150,
    },
    {
      title: 'Disabled',
      dataIndex: 'isDisabled',
      key: 'isDisabled',
      render: (isDisabled: boolean) => `${isDisabled}`,
      width: 150,
    },
    {
      title: 'Visible',
      dataIndex: 'isVisible',
      key: 'isVisible',
      render: (isVisible: boolean) => `${isVisible}`,
      width: 150,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => formatDefaultDate(date),
      width: 150,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (q: ITendergyQuestionnaireConfig) => (
        <Button onClick={() => history.push(`${links.questionnaireConfigs}/${q.id}`)}>Open</Button>
      ),
      width: 150,
    },
  ];

  const buttonProps: ButtonProps = {
    type: 'primary',
    shape: 'round',
    icon: <PlusOutlined />,
    onClick: () => history.push(`${links.createQuestionnaireConfig}/?poolId=${entry && entry.id}`),
  };

  if (!questionnaireConfigs) {
    return <div>Client Request Pool Questionnaire configs data is missing</div>;
  }

  return (
    <>
      <Row justify="end">
        <Col>
          <div style={{ marginBottom: 10 }}>
            <Button {...buttonProps}>Add questionnaire configs</Button>
          </div>
        </Col>
      </Row>
      <Table columns={columns} dataSource={questionnaireConfigs} scroll={{ x: '100%' }} />
    </>
  );
};

export default ClientRequestPoolQuestionnaireConfigsTab;
