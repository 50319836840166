import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ISAWallboxesService } from 'services/isa-service';
import { formatDefaultDate } from 'utils/date.helper';

export default function ISAWallboxesOverview() {
  return (
    <CrudListViewContainer
      columns={[
        { title: 'Wallbox Title', dataIndex: 'name' },
        { title: 'Logical Name', dataIndex: 'logicalName' },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          render: (date: string) => formatDefaultDate(date),
        },
      ]}
      entityName="wallboxes"
      resource={ISAWallboxesService}
      withSorting
    />
  );
}
