import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Button } from 'antd';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import CustomDocumentsService from 'services/tendergy/tendergy-custom-documents.service';
import {
  CustomDocumentsCategoryType,
  ICustomDocument,
  ITendergyClientRequestPool,
  ITendergyDocument,
} from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

import { CustomDocumentValidation } from '../../custom-document.validation';

import styles from '../../../../shop/product-options/details/tabs/ProductAttributeOptionStyles.module.sass';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */

const listOptions = ['unordered', 'ordered'];
const inlineOptions = ['bold', 'italic', 'underline'];
const toolbarOptions = ['inline', 'list', 'remove', 'history', 'emoji'];

export interface ICustomDocumentOverviewTabProps {
  entry: ICustomDocument;
  onSave: any;
}

const CustomDocumentOverviewTab: React.FC<ICustomDocumentOverviewTabProps> = (
  props: ICustomDocumentOverviewTabProps,
) => {
  const { entry, onSave } = props;
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<ITendergyDocument | null>(null);
  const [mediaLink, setMediaLink] = useState('');

  const getFileMediaLink = async (fileName: string) => {
    try {
      const response = await CustomDocumentsService.getFileLink({ fileName });

      if (response?.link) {
        setMediaLink(response.link);
      }
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    if (uploadedFile || entry?.media[0]) {
      getFileMediaLink(uploadedFile?.name || entry.media[0].name || entry.media[0].media.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile, entry]);

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(CustomDocumentValidation);

  const payload = {
    relations: ['users'],
  };

  const defaultEditorValue = entry?.text
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(entry.text)))
    : EditorState.createEmpty();

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (!file || !entry.id) {
      return;
    }

    setFileUploading(true);

    try {
      const response = await CustomDocumentsService.uploadMedia(file, entry.id);
      if (response) {
        setFileUploading(false);
        setUploadedFile(response);
      }
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <div>
      <Formik
        initialValues={entry as ICustomDocument}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave({
            ...values,
            media: uploadedFile ? [uploadedFile.id] : entry.media,
          }).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="scopeType" label="Scope Type" required>
              <Select name="scopeType">
                <Select.Option value="client-request-pools">Client Request Pools</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="scopeId" label="Scope Id" required>
              <EntitySelector
                name="scopeId"
                entityService={ClientRequestPoolService}
                current={entry.scopeId}
                defaultPayload={payload}
                onSelect={(pool: ITendergyClientRequestPool) => setFieldValue('scopeId', pool.id)}
              />
            </Form.Item>

            <Form.Item name="category" label="Category" required>
              <Select name="category">
                <Select.Option value={CustomDocumentsCategoryType.InstallerLandingPage}>
                  Installer Landing Page
                </Select.Option>
                <Select.Option value={CustomDocumentsCategoryType.UserCostCalculatorDescription}>
                  User Cost Calculator Description
                </Select.Option>
                <Select.Option value={CustomDocumentsCategoryType.UserFAQPage}>
                  FAQ Page
                </Select.Option>
                <Select.Option value={CustomDocumentsCategoryType.SurveyLandingPage}>
                  Survey Landing Page
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="text" label="Text">
              <Editor
                toolbar={{
                  options: toolbarOptions,
                  inline: {
                    options: inlineOptions,
                  },
                  list: {
                    options: listOptions,
                  },
                }}
                defaultEditorState={defaultEditorValue}
                wrapperClassName={styles.editorWrapper}
                editorClassName={styles.textAreaWrapper}
                onEditorStateChange={(editorData) =>
                  setFieldValue(
                    'text',
                    JSON.stringify(convertToRaw(editorData.getCurrentContent())),
                  )
                }
              />
            </Form.Item>

            <Form.Item name="imageLink" label="Image">
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInput}
                onChange={onFileChange}
              />
              <Button
                type="primary"
                loading={fileUploading}
                onClick={() => fileInput?.current?.click()}
              >
                {mediaLink ? 'Change Image' : 'Upload Image'}
              </Button>
              {mediaLink && (
                <img src={mediaLink} alt="Document Media" className={styles.numberInput} />
              )}
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomDocumentOverviewTab;
