import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';

import BillingImportsService from 'services/billing/billing-imports.service';
import { showNotification } from 'utils/notificationUtil';

import styles from './styles.module.sass';

const ImportsCreatePage: React.FC = () => {
  const gridFileUploadProps = BillingImportsService.getFileUploadProps({
    url: 'admin/imports/csv',
  });
  const empFileUploadProps = BillingImportsService.getFileUploadProps({
    url: 'admin/imports/emp-csv',
  });
  const carOwnerFileUploadProps = BillingImportsService.getFileUploadProps({
    url: 'admin/imports/car-owner',
  });

  const showStatus = (info: UploadChangeParam) => {
    switch (info.file.status) {
      case 'uploading':
        break;
      case 'done':
        showNotification({
          message: `${info.file.name} imported successfully`,
          type: 'success',
        });
        break;
      case 'error':
        showNotification({
          message: `${info.file.name} upload failed.`,
          error: info.file.error,
        });
        break;
    }
  };

  gridFileUploadProps.onChange = showStatus;
  empFileUploadProps.onChange = showStatus;
  carOwnerFileUploadProps.onChange = showStatus;

  const bodyStyle = {
    display: 'grid',
    'text-align': 'center',
  };

  return (
    <Card bodyStyle={bodyStyle}>
      <div>To create a new import you need to upload the CSV file</div>
      <Upload {...gridFileUploadProps}>
        <Button icon={<UploadOutlined />} className={styles.button}>
          Upload Gridware CSV
        </Button>
      </Upload>
      <Upload {...empFileUploadProps}>
        <Button icon={<UploadOutlined />} className={styles.button}>
          Upload EMP CSV
        </Button>
      </Upload>
      <Upload {...carOwnerFileUploadProps}>
        <Button icon={<UploadOutlined />} className={styles.button}>
          Upload Car Owner CSV
        </Button>
      </Upload>
    </Card>
  );
};

export default ImportsCreatePage;
