import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { ISAMappingService } from 'services/isa-service';
import { formatDefaultDate } from 'utils/date.helper';

export default function ISAMappingOverview() {
  return (
    <CrudListViewContainer
      columns={[
        {
          title: 'Wallbox',
          dataIndex: 'wallbox',
          render: (_data, record) => record.wallbox?.logicalName,
          searchKey: 'wallboxLogicalName',
        },
        {
          title: 'Solution',
          dataIndex: 'solution',
          render: (_data, record) => record.solution?.logicalName,
          searchKey: 'solutionLogicalName',
        },
        {
          title: 'Problem',
          dataIndex: 'problem',
          render: (_data, record) => record.problem?.logicalName,
          searchKey: 'problemLogicalName',
        },
        {
          title: 'Diagnostic',
          dataIndex: 'diagnostic',
          render: (_data, record) => record.diagnostic?.logicalName,
          searchKey: 'diagnosticLogicalName',
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          render: (date: string) => formatDefaultDate(date),
        },
      ]}
      entityName="mapping"
      resource={ISAMappingService}
      defaultPayload={{ relations: ['wallbox', 'problem', 'solution', 'diagnostic'] }}
      disableSearch
      withSorting
    />
  );
}
