import * as Yup from 'yup';

export const INITIAL_VALUES = {
  name: null,
  logicalName: null,
  description: null,
  imageUrl: null,
  sequenceNumber: 0,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  logicalName: Yup.string().nullable().required('Required field'),
  description: Yup.string().nullable().required('Required field'),
  imageUrl: Yup.string()
    .nullable()
    .max(300, 'Field has not to be longer than 300 characters!')
    .required('Required field'),
  sequenceNumber: Yup.number().required('Required field'),
});
