import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import { Alert, Button, message } from 'antd';
import config from 'config';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { ITendergyInvitationLink } from 'store/tendegyService/tendergyService.types';

import styles from './styles.module.sass';

interface InvitationActionsComponentProps {
  entry: ITendergyInvitationLink;
}

const InvitationActionsComponent: React.FC<InvitationActionsComponentProps> = ({
  entry,
}: InvitationActionsComponentProps) => {
  const history = useHistory();

  const getLink = () => `${config.TENDERGY_UI_URL}registration/${entry.token}`;

  return (
    <div className={styles.containerOuter}>
      <div className={styles.containerInner}>
        <Alert message={getLink()} type="info" />
      </div>
      <div className={styles.containerInner}>
        <CopyToClipboard text={getLink()}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={() => message.info('Invitation Link Copied')}
          >
            Copy Link
          </Button>
        </CopyToClipboard>
      </div>
      {entry.organization && entry.organization.id && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={() =>
              history.push(
                createLink(links.organization, {
                  id: entry.organization?.id,
                }),
              )
            }
          >
            Open Organization
          </Button>
        </div>
      )}
      {entry.clientRequestPool && entry.clientRequestPool.id && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={() =>
              history.push(
                createLink(links.clientRequestPool, {
                  id: entry.clientRequestPool?.id,
                }),
              )
            }
          >
            Open Pool
          </Button>
        </div>
      )}
    </div>
  );
};

export default InvitationActionsComponent;
