import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Formik } from 'formik';
import { Form, Input, InputNumber, Select, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import ProductAttributesService from 'services/shop/shop-product-attributes.service';
import {
  IShopProductAttribute,
  IShopProductAttributeOption,
  PlugType,
  ProductAttributeOptionType,
} from 'services/shop/shop-service.types';

import { ProductAttributeOptionsValidation } from '../../product-attribute-options.validation';

import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './ProductAttributeOptionStyles.module.sass';
/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */

const listOptions = ['unordered', 'ordered'];
const inlineOptions = ['bold', 'italic', 'underline'];
const toolbarOptions = ['inline', 'list', 'remove', 'history', 'emoji'];

const ProductAttributeOptionOverviewTab: React.FC<
  IGenericEditTabProps<IShopProductAttributeOption>
> = (props: IGenericEditTabProps<IShopProductAttributeOption>) => {
  const { entry, onSave } = props;
  const defaultEditorValue = entry.description
    ? convertToRaw(stateFromHTML(entry.description))
    : convertToRaw(stateFromHTML(''));

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(ProductAttributeOptionsValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IShopProductAttributeOption}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          const formattedValues = { ...values, imageUrls: [] };
          onSave(formattedValues).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="name" label="Name" required>
              <Input name="name" placeholder="" />
            </Form.Item>
            <Form.Item required name="type" label="Type">
              <Select name="type">
                <Select.Option value={ProductAttributeOptionType.Model}>Model</Select.Option>
                <Select.Option value={ProductAttributeOptionType.Option}>Option</Select.Option>
                <Select.Option value={ProductAttributeOptionType.Service}>Service</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="price" label="Price (in Cents)">
              <InputNumber
                name="price"
                placeholder=""
                type="number"
                className={styles.numberInput}
              />
            </Form.Item>
            <Form.Item name="priceComment" label="Price comment">
              <Input name="priceComment" placeholder="" />
            </Form.Item>
            <Form.Item name="index" label="Index" required>
              <Input name="index" placeholder="" type="number" />
            </Form.Item>
            <Form.Item name="stockQuantity" label="Stock quantity">
              <InputNumber
                name="stockQuantity"
                placeholder=""
                type="number"
                className={styles.numberInput}
              />
            </Form.Item>
            <Form.Item name="vat" label="Vat %">
              <InputNumber name="vat" placeholder="" type="number" className={styles.numberInput} />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Editor
                toolbar={{
                  options: toolbarOptions,
                  inline: {
                    options: inlineOptions,
                  },
                  list: {
                    options: listOptions,
                  },
                }}
                wrapperClassName={styles.editorWrapper}
                defaultContentState={defaultEditorValue}
                editorClassName={styles.textAreaWrapper}
                onEditorStateChange={(editorData) =>
                  setFieldValue('description', stateToHTML(editorData.getCurrentContent()))
                }
              />
            </Form.Item>
            <Form.Item name="information" label="Information">
              <Input name="information" placeholder="" />
            </Form.Item>
            <Form.Item name="productAttribute" required label="Product Attribute">
              <EntitySelector
                name="productAttribute"
                entityService={ProductAttributesService}
                current={entry.productAttribute}
                onSelect={(productAttribute: IShopProductAttribute) =>
                  setFieldValue('productAttribute', productAttribute)
                }
              />
            </Form.Item>

            {values.type === ProductAttributeOptionType.Model && (
              <>
                <Form.Item name="chargePoints" label="Charge Points">
                  <Input name="chargePoints" placeholder="" type="number" />
                </Form.Item>
                <Form.Item name="kwAC" label="kwAC">
                  <Input name="kwAC" placeholder="" type="number" />
                </Form.Item>
                <Form.Item name="plugType" label="Plug type">
                  <Select name="plugType">
                    <Select.Option value={PlugType.Typ1}>Typ1</Select.Option>
                    <Select.Option value={PlugType.Typ2}>Typ2</Select.Option>
                    <Select.Option value={PlugType.CCS}>CCS</Select.Option>
                    <Select.Option value={PlugType.CHAdeMO}>CHAdeMO</Select.Option>
                    <Select.Option value={PlugType.Schuko}>Schuko</Select.Option>
                    <Select.Option value={PlugType.Supercharger}>Supercharger</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="phases" label="Phases">
                  <InputNumber
                    name="phases"
                    placeholder=""
                    type="number"
                    className={styles.numberInput}
                  />
                </Form.Item>
                <Form.Item name="accessSecurity" label="Access security">
                  <Input name="accessSecurity" placeholder="" />
                </Form.Item>
                <Form.Item name="subsidyReady" label="Subsidy ready">
                  <Switch name="subsidyReady" />
                </Form.Item>
                <Form.Item name="communicationModule" label="Communication module">
                  <Input name="communicationModule" placeholder="" />
                </Form.Item>
                <Form.Item name="communicationProtocol" label="Communication protocol">
                  <Input name="communicationProtocol" placeholder="" />
                </Form.Item>
                <Form.Item name="metering" label="Metering">
                  <Input name="metering" placeholder="" />
                </Form.Item>
                <Form.Item name="securityComponents" label="Security components">
                  <Switch name="securityComponents" />
                </Form.Item>
              </>
            )}
            {values.type === ProductAttributeOptionType.Service ? (
              <Form.Item name="installation" label="Installation">
                <Switch name="installation" />
              </Form.Item>
            ) : null}

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductAttributeOptionOverviewTab;
