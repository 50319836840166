import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import {
  IPoolTenderOrganization,
  ITendergyClientRequestPool,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

interface Props {
  poolTenderOrganizations?: IPoolTenderOrganization[];
}

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'clientRequestPool',
    render: (pool: ITendergyClientRequestPool) => (pool?.name ? pool.name : '-'),
  },
  {
    title: 'Created At',
    key: 'createdAt',
    dataIndex: 'clientRequestPool',
    render: (pool: ITendergyClientRequestPool) =>
      pool?.createdAt ? formatDefaultDate(pool?.createdAt) : '–',
  },
];

const ClientRequestPoolsTab: React.FC<Props> = ({ poolTenderOrganizations }: Props) => {
  const history = useHistory();

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={poolTenderOrganizations}
      onRow={(record) => ({
        onClick: () =>
          record?.clientRequestPool &&
          history.push(createLink(links.clientRequestPool, { id: record.clientRequestPool.id })),
      })}
    />
  );
};

export default ClientRequestPoolsTab;
