import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

interface ISaveButtonProps extends Omit<ButtonProps, 'autoSave'> {
  autoSave?: boolean;
  alternativeChildren?: {
    isSubmitting?: string;
    default?: string;
    hasChanged?: string;
  };
}

export default function FormikContextSubmitButton({
  autoSave,
  alternativeChildren = {},
  ...rest
}: ISaveButtonProps) {
  const { submitForm, isValid, isSubmitting, values, setSubmitting } = useFormikContext();
  const [lastSavedValue, setLastSavedValue] = useState(values);
  const [isMounted, setIsMounted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  const submitNow = () => {
    if (canSubmit && isMounted) {
      setCanSubmit(false);
      setLastSavedValue(values);
      submitForm().finally(() => setSubmitting(false));
    }
  };

  const debounceSubmit = debounce(submitNow, 500);

  useEffect(() => {
    if (!autoSave) return;
    debounceSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, canSubmit]);

  useEffect(() => {
    setIsMounted(true);
    setCanSubmit(true);

    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasChanged = !isEqual(values, lastSavedValue);
    setCanSubmit(hasChanged && isValid && !isSubmitting);
  }, [isSubmitting, values, isValid, lastSavedValue]);

  if (isSubmitting) {
    return (
      <Button loading={isSubmitting} type="primary" disabled {...rest}>
        {alternativeChildren.isSubmitting || 'Submitting'}
      </Button>
    );
  }

  if (!isValid) {
    return (
      <Button loading={isSubmitting} type="primary" disabled {...rest}>
        Invalid
      </Button>
    );
  }

  if (canSubmit || canSubmit === undefined) {
    return (
      <Button
        loading={isSubmitting}
        type="primary"
        onClick={submitNow}
        disabled={canSubmit === undefined}
        {...rest}
      >
        {alternativeChildren.hasChanged || 'Submit'}
      </Button>
    );
  }

  return (
    <Button type="primary" disabled {...rest}>
      {alternativeChildren.default || 'Submitted'}
    </Button>
  );
}
