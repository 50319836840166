import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import WebhooksService from 'services/integration/webhooks.service';
import { IWebhook } from 'services/integration/webhooks-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import WebhookOverviewTab from './tabs/WebhookOverviewTab';

const WebhookDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IWebhook, optionalProps?: ICrudDetailViewCallbacks) => (
        <WebhookOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  return (
    <CrudDetailContainer
      entityName="Webhook"
      tabs={tabs}
      baseLink={links.webhooksOverview}
      defaultPayload={params}
      // Render some additional actions like Delete button
      // renderActions={renderActions}
      resource={WebhooksService}
    />
  );
};

export default WebhookDetailsPage;
