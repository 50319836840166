import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import BillingInvoiceService from 'services/billing/billing-invoices.service';
import { IBillingInvoice } from 'services/billing/billing-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { showErrorNotification } from 'utils/notificationUtil';

import InvoiceActionsComponent from './components/InvoiceActionsComponent/InvoiceActionsComponent';
import BillingInvoiceDetailsTab from './tabs/BillingInvoiceDetailsTab';

const BillingInvoicePage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params
  const params: IGetSingleEntityPayload = {
    id,
    relations: ['documents'],
  };

  const downloadZip = async (entry: IBillingInvoice) => {
    BillingInvoiceService.downloadZip(entry);
  };

  const cancelInvoice = async (entry: IBillingInvoice) => {
    try {
      await BillingInvoiceService.cancelInvoice(entry);
      window.location.reload();
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: any) => <BillingInvoiceDetailsTab entry={entity} />,
    },
  ];

  const renderActions = (entry: IBillingInvoice) => (
    <InvoiceActionsComponent
      entry={entry}
      downloadZip={() => downloadZip(entry)}
      cancelInvoice={() => cancelInvoice(entry)}
    />
  );

  const renderTitle = (entry: IBillingInvoice) =>
    `${moment(entry.month).format('MMMM')} ${entry.year} - ${entry.status}`;

  return (
    <CrudDetailContainer
      entityName="Invoice"
      tabs={tabs}
      renderActions={renderActions}
      renderTitle={renderTitle}
      baseLink={links.billingInvoice}
      defaultPayload={params}
      resource={BillingInvoiceService}
    />
  );
};

export default BillingInvoicePage;
