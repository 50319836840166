import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import ApiClientService from 'services/auth/api-client.service';
import { IApiClient } from 'store/authService/authService.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IApiClient> = [
  {
    title: 'Client Name',
    dataIndex: 'clientName',
    key: 'clientName',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Client Email',
    dataIndex: 'clientEmail',
    key: 'clientEmail',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Client Description',
    dataIndex: 'clientDescription',
    key: 'clientDescription',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date: string) => formatDefaultDate(date),
  },
];

const ApiClientsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="API Clients"
    columns={columns}
    resource={ApiClientService}
    disableSearch
  />
);
export default ApiClientsOverviewPage;
