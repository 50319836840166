import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import BillingCarOwnerService from 'services/billing/billing-car-owner.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import CreateCarOwnerStep from './steps/CreateCarOwnerStep';

const CreateCarOwnerPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Basic Information',
      description: 'Firstname, Lastname etc.',
      render: (entry, onSubmitStep) => (
        <CreateCarOwnerStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Car Owner"
      steps={steps}
      baseLink={links.billingCarOwners}
      defaultPayload={params}
      resource={BillingCarOwnerService}
    />
  );
};

export default CreateCarOwnerPage;
