import { createSelector } from 'reselect';

import { UserRole } from 'framework/constants';
import { RootState } from 'store/rootReducer';

import { AuthState } from './auth.types';

export const authSelector = (state: RootState) => state.auth;

export const isUserAdminSelector = createSelector(
  authSelector,
  (auth: AuthState) => auth.user.role === UserRole.Admin,
);
export const isAuthorizedSelector = createSelector(authSelector, (auth: AuthState) => !!auth.token);
export const isLoadingSelector = createSelector(authSelector, (auth: AuthState) => auth.loading);
export const userSelector = createSelector(authSelector, (auth: AuthState) => auth.user);

// Fingerprint
export const fingerprintSelector = createSelector(
  authSelector,
  (auth: AuthState) => auth.fingerprint,
);
