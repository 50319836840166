import * as React from 'react';

import { IChargingStationDetailsTab } from '.';

const RFIDCardsDetailsTab: React.FC<IChargingStationDetailsTab> = (
  props: IChargingStationDetailsTab,
) => {
  const { entry } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  return <pre>{JSON.stringify(entry, null, 2)}</pre>;
};

export default RFIDCardsDetailsTab;
