import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISACarBrandsService } from 'services/isa-service';
import { IISACarBrand, IParams } from 'services/isa-service/isa-service.types';

import ISACarBrandsForm from './components/ISACarBrandsForm';
import ISACarModelsTab from './components/ISACarModelsTab';

export default function ISACarBrandsDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISACarBrandsForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'carModels',
          displayName: 'Car Models',
          render: (entry: IISACarBrand) => <ISACarModelsTab carModels={entry.carModels} />,
        },
      ]}
      entityName="car brand"
      baseLink={links.isaCarBrandsDetails}
      defaultPayload={{ id, relations: ['carModels'] }}
      resource={ISACarBrandsService}
      hideJsonTab
    />
  );
}
