import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IB } from 'pages/billing-v3/charging-stations/constants';
import TendergyQuestionnaireStepService from 'services/tendergy/tendergy-questionnaire-step.service';
import { ITendergyQuestionnaireStep } from 'store/tendegyService/tendergyService.types';

const columns: IB<ITendergyQuestionnaireStep>[] = [
  {
    title: 'Step Title',
    dataIndex: 'title',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'title',
  },
  {
    title: 'Step Order',
    dataIndex: 'order',
    key: 'order',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Questionnaire',
    key: 'questionnaire',
    sorter: true,
    sortParameter: 'questionnaire.name',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (entry) => entry.questionnaire?.name,
  },
];

const QuestionnaireOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Questionnaire Steps"
    columns={columns}
    resource={TendergyQuestionnaireStepService}
    additionalPayload={{ relations: ['questionnaire'] }}
  />
);
export default QuestionnaireOverviewPage;
