import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { DatePicker, Form, Select, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IAuthOrganization } from 'store/authService/authService.types';
import {
  InviteLinkType,
  ITendergyInvitationLink,
} from 'store/tendegyService/tendergyService.types';

import { InvitationLinkValidation } from '../../../../tendergy/invitation-links/invitation-link.validation';

interface InstallerInvitationLinkStepProps {
  entry: IAuthOrganization;
  onSubmitStep: (entry: ITendergyInvitationLink) => void;
  saveInviteLink: Function;
}

const InstallerInvitationLinkStep: React.FC<InstallerInvitationLinkStepProps> = ({
  entry,
  saveInviteLink,
}: InstallerInvitationLinkStepProps) => {
  const validation = Yup.object().shape(InvitationLinkValidation);

  return (
    <div>
      <Formik
        initialValues={entry as any}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          saveInviteLink(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item required name="type" label="Type" initialValue={InviteLinkType.installer}>
              <Select name="type">
                <Select.Option value={InviteLinkType.installer}>For Installer</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="expires" label="Expire date">
              <DatePicker inputReadOnly name="expires" placeholder="" />
            </Form.Item>
            <Form.Item name="isEnabled" label="Is Enabled">
              <Switch name="isEnabled" />
            </Form.Item>

            <Form.Item required name="organization" label="Organization">
              {entry.name}
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting}
              >
                Create Link
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InstallerInvitationLinkStep;
