import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import { IAuthOrganization } from '../authService.types';
import {
  AuthOrganizationMember,
  GET,
  GET_MEMBERS,
  OrganizationActionTypes,
  RESET,
  SET,
  SET_LOADING_STATUS,
  SET_MEMBERS,
} from './organization.types';

export function get(payload: IGetSingleEntityPayload): OrganizationActionTypes {
  return {
    type: GET,
    payload,
  };
}

export function getMembers(payload: string): OrganizationActionTypes {
  return {
    type: GET_MEMBERS,
    payload,
  };
}

export function setMembers(payload: AuthOrganizationMember[]): OrganizationActionTypes {
  return {
    type: SET_MEMBERS,
    payload,
  };
}

export function set(payload: IAuthOrganization): OrganizationActionTypes {
  return {
    type: SET,
    payload,
  };
}

export function setLoadingStatus(payload: boolean): OrganizationActionTypes {
  return {
    type: SET_LOADING_STATUS,
    payload,
  };
}

export function reset(): OrganizationActionTypes {
  return {
    type: RESET,
  };
}
