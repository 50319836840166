import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import InvitationLinkService from 'services/tendergy/tendergy-invitation-links.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergyInvitationLink } from 'store/tendegyService/tendergyService.types';

import InvitationActionsComponent from './component/InvitationActionsComponent/InvitationActionsComponent';
import InvitationLinkDetailsTab from './tabs/invitation-links-details-tab';

const InvitationLinksDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: ITendergyInvitationLink, optionalProps?: ICrudDetailViewCallbacks) => (
        <InvitationLinkDetailsTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  const renderActions = (entry: ITendergyInvitationLink) => (
    <InvitationActionsComponent entry={entry} />
  );

  return (
    <CrudDetailContainer
      entityName="Invitation Link"
      tabs={tabs}
      baseLink={links.invitationLink}
      defaultPayload={params}
      resource={InvitationLinkService}
      renderActions={renderActions}
    />
  );
};

export default InvitationLinksDetailsPage;
