import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import CarModelsService from 'services/shop/car-models.service';
import { IShopCarModel } from 'services/shop/shop-service.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IShopCarModel> = [
  {
    title: 'Make',
    dataIndex: 'make',
    key: 'make',
  },
  {
    title: 'Model',
    dataIndex: 'model',
    key: 'model',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const CarModelsOverviewPage: React.FC = () => (
  <CrudListViewContainer entityName="Car Models" columns={columns} resource={CarModelsService} />
);

export default CarModelsOverviewPage;
