import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';

import { FormEntitySelect, FormInput, FormSelect, FormSubmit } from 'components/forms';
import BillCarOwnersService from 'services/billing-v2/billing-v2-car-owners.service';
import BillOrganizationService from 'services/billing-v2/billing-v2-organizations.service';
import {
  IBillingAddress,
  IBillingChargingStation,
} from 'services/billing-v2/billing-v2-service.types';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from './styles.module.sass';

interface IProps {
  initialValues: IBillingChargingStation;
  entry: IBillingChargingStation;
  setInitialValues: (values: IBillingChargingStation) => void;
}

interface AddressButtonProps {
  updateInitialValues: (address: IBillingAddress | undefined) => void;
}

const GetAddressButton = ({ updateInitialValues }: AddressButtonProps) => {
  const { values } = useFormikContext<IBillingChargingStation>();
  const getUserAddress = async () => {
    if (!values.carOwner) return;

    try {
      const id = values?.carOwner?.id || '';
      const response = await BillCarOwnersService.getById({ id });
      updateInitialValues(response?.address);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <div className={styles.centered}>
      <Button onClick={getUserAddress} disabled={!values?.carOwner?.id}>
        Autofill
      </Button>
    </div>
  );
};
const ChargingStationForm = ({ entry, initialValues, setInitialValues }: IProps) => {
  const [userAddress, setUserAddress] = useState<IBillingAddress>();
  const { values, setFieldValue } = useFormikContext<IBillingChargingStation>();
  // if value.type === 'work' then remove carOwner from values by setFieldValue to null and if value.type === 'home' then remove organization from values by setFieldValue to null
  useEffect(() => {
    if (values.type === 'work') {
      setFieldValue('carOwner', null);
    } else if (values.type === 'home') {
      setFieldValue('organization', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type]);
  const carOwnersSelectProps = {
    label: 'Car Owner',
    entityService: BillCarOwnersService,
    name: 'carOwner',
    current: entry?.carOwner,
    required: true,
    selectId: true,
  };
  const OrganizationSelectProps = {
    label: 'Organizations',
    entityService: BillOrganizationService,
    name: 'organization',
    current: entry?.organization,
    required: true,
    selectId: true,
  };
  useEffect(() => {
    renderAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress]);

  const renderAddress = () => {
    return (
      <>
        <FormInput name="address.country" label="Country" />
        <FormInput name="address.iso3166_1_alpha_3" label="ISO 3166-1 alpha-3 Code" required />
        <FormInput name="address.region" label="Region" />
        <FormInput name="address.city" label="City" required />
        <FormInput name="address.address" label="Address" required />
        <FormInput name="address.postalCode" label="Postcode" required />
        <FormInput name="address.latitude" label="Latitude" />
        <FormInput name="address.longitude" label="Longitude" />
      </>
    );
  };
  const updateInitialValues = (address: IBillingAddress | undefined) => {
    if (address) {
      setInitialValues({ ...initialValues, address });
      setUserAddress(address);
    }
  };
  return (
    <>
      <FormSelect name="type" label="Type" options={['home', 'work', 'public']} required />
      <FormInput name="evse" label="EVSE" required />
      <FormInput name="name" label="Name" />
      <FormInput name="label" label="Label" />
      {values.type === 'work' && <FormEntitySelect {...OrganizationSelectProps} />}
      {values.type === 'home' && <FormEntitySelect {...carOwnersSelectProps} />}
      <div className={styles.centered}>
        <h4>Address</h4>
      </div>
      <GetAddressButton updateInitialValues={updateInitialValues} />
      {renderAddress()}
      <FormSubmit />
    </>
  );
};

export default ChargingStationForm;
