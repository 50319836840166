import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table } from 'antd';

import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { IBillingCarOwner, IBillingChargingStation } from 'services/billing/billing-service.types';
import ChargingStationService from 'services/billing/charging-station.service';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const CarOwnerChargingStationsTab: React.FC<IGenericEditTabProps<IBillingCarOwner>> = (
  props: IGenericEditTabProps<IBillingCarOwner>,
) => {
  const { entry } = props;

  const history = useHistory();

  function navigateToChargingStation(card?: IBillingChargingStation) {
    if (!card) {
      return;
    }
    const link = ChargingStationService.getDetailLink(card);
    if (!link) {
      return;
    }
    history.push(link);
  }

  const columns = [
    {
      title: 'evse',
      dataIndex: 'evse',
      key: 'evse',
    },
    {
      title: 'label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'actions',
      render: (any: any, row: IBillingChargingStation) => (
        <Button onClick={() => navigateToChargingStation(row)}>Open Station</Button>
      ),
    },
  ];

  if (!entry) {
    return <div>Client Request Pool data is missing</div>;
  }

  return (
    <div>
      {' '}
      <Table columns={columns} dataSource={entry.chargingStations} />
    </div>
  );
};

export default CarOwnerChargingStationsTab;
