import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Upload } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import links from 'framework/links';
import { QuestionnaireValidation } from 'pages/tendergy/questionnaires/questionnaire.validation';
import TendergyQuestionnaireService from 'services/tendergy/tendergy-questionnaire.service';
import { ITendergyQuestionnaire } from 'store/tendegyService/tendergyService.types';
import { showSuccessNotification } from 'utils/notificationUtil';

interface Props {
  entry: any;
  onSubmitStep: (entry: ITendergyQuestionnaire) => void;
  onChangeCopy: (isCopy: boolean) => void;
  onChangeQuestionnaireId: (id: string, version: string) => void;
}

const defaultValues = {
  name: '',
};

const ImportQuestionnairePage: React.FC<Props> = ({ onSubmitStep }: Props) => {
  const validation = Yup.object().shape(QuestionnaireValidation);
  const { id, version } = useRouteMatch().params as any;
  const history = useHistory();
  const [uploadError, setError] = useState('');
  const [JsonFile, setjson] = useState('');

  const propsUpload = {
    name: 'file',

    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        const isJSON = info.fileList[0].type === 'application/json';
        if (!isJSON) {
          //message.error(`${info.fileList[0].name} is not Json file`);
          setError(`${info.fileList[0].name} is not Json file`);
        } else {
          setError(``);
          setjson(info.fileList[0].originFileObj);
        }

        return isJSON;
      }
    },
  };
  const onSubmit = () => {
    TendergyQuestionnaireService.importQuestionaire(id, version, JsonFile).then((res) => {
      if (res) {
        showSuccessNotification('Success', 'Questionnaires import successfully');
        history.push(links.tendergyQuestionnaires);
      }
    });
  };

  const renderForm = ({ isSubmitting }: FormikProps<any>) => {
    const isSubmitDisabled = isSubmitting;

    return (
      <Card>
        <Form {...CrudFormDefaultParams}>
          <Form.Item name="file" label="File">
            <Upload {...propsUpload} maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload JSON</Button>
            </Upload>
            {uploadError ? <span style={{ color: 'red' }}>{uploadError}</span> : ''}
          </Form.Item>

          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton
              type="primary"
              htmlType="submit"
              disabled={isSubmitDisabled}
              style={{ marginRight: '20px' }}
              onClick={onSubmit}
            >
              Submit
            </SubmitButton>
          </Form.Item>
        </Form>
      </Card>
    );
  };

  return (
    <div>
      <Formik
        // @ts-ignore
        initialValues={defaultValues}
        validationSchema={validation}
        onSubmit={onSubmitStep}
        render={renderForm}
      />
    </div>
  );
};

export default ImportQuestionnairePage;
