import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import AuthOrganizationService from 'services/auth/auth-organization.service';
import InvitationLinkService from 'services/tendergy/tendergy-invitation-links.service';
import { IAuthOrganization } from 'store/authService/authService.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergyInvitationLink } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import InstallerInvitationLinkStep from './steps/InstallerInvitationLinkStep';
import OrganizationStep from './steps/OrganizationStep';

const CreateOrganizationPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const saveNewOrganization = async (entry: IAuthOrganization) => {
    try {
      await AuthOrganizationService.save(entry);
    } catch (e) {
      showErrorNotification(e);
      return;
    }
    showSuccessNotification('Organization was created successfully');
  };

  const saveInviteLink = async (entry: ITendergyInvitationLink) => {
    try {
      await InvitationLinkService.save(entry);
    } catch (e) {
      showErrorNotification(e);
      return;
    }
    showSuccessNotification('Invitation Link was created successfully');
  };

  const steps: ICrudStep[] = [
    {
      name: 'Create a new organization',
      render: (entry, onSubmitStep) => (
        <OrganizationStep
          entry={entry}
          onSubmitStep={onSubmitStep}
          saveNewOrganization={saveNewOrganization}
        />
      ),
    },
    {
      name: 'Create invitation link for Installer',
      render: (entry, onSubmitStep) => (
        <InstallerInvitationLinkStep
          entry={entry}
          onSubmitStep={onSubmitStep}
          saveInviteLink={saveInviteLink}
        />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Organization"
      steps={steps}
      baseLink={links.organization}
      defaultPayload={params}
      resource={AuthOrganizationService}
    />
  );
};

export default CreateOrganizationPage;
