import * as React from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'formik-antd';

import EntitySelector, { IEntitySelectorProps } from 'components/forms/organization/EntitySelector';

export interface IFormEntitySelectProps extends IEntitySelectorProps {
  name: string;
  label?: string;
  required?: boolean;
}

function FormEntitySelect(props: IFormEntitySelectProps) {
  const { name, label, required, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  function handleChange(value: any) {
    setFieldValue(name, value || null);
  }

  return (
    <Form.Item name={name} label={label} required={required}>
      <EntitySelector name={name} {...rest} onSelect={handleChange} />
    </Form.Item>
  );
}

FormEntitySelect.defaultProps = {
  allowClear: true,
};

export default FormEntitySelect;
