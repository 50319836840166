import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { OfferEventType, OfferState } from 'framework/constants';
import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import * as actions from 'store/tendegyService/offer/offer.actions';
import { IOffer } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import styles from './OfferActionsComponent.module.sass';

interface IOfferActionProps {
  entry: IOffer;
  triggerReload: Function;
}

const OfferActionsComponent: React.FC<IOfferActionProps> = (props: IOfferActionProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [rejectReason, setRejectReason] = useState('');
  const { entry } = props;
  const rejectOffer = () => {
    if (!entry.id) {
      showErrorNotification('Can not reject offer, id not found');
      return;
    }

    if (!rejectReason) {
      showErrorNotification('Can not reject offer, id not found');
      return;
    }

    const payload = {
      type: OfferEventType.COORDINATOR_REJECTED,
      id: entry.id,
      rejectReason,
    };

    dispatch(actions.transition(payload));
    props.triggerReload();
    showSuccessNotification('Offer rejected successfully');
  };

  const acceptOffer = () => {
    if (!entry.id) {
      showErrorNotification('Can not accept offer, id not found');
      return;
    }
    const payload = {
      type: OfferEventType.COORDINATOR_ACCEPTED,
      id: entry.id,
    };

    // Todo: Loader, wait for completion, handle errors, don't just dispatch and navigate
    dispatch(actions.transition(payload));
    props.triggerReload();
    showSuccessNotification('Offer accepted successfully');
  };

  return (
    <>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push(`${links.activityLogs}?scope=offers&affectedEntry=${entry.id}`)
          }
        >
          Go to Activity Logs
        </Button>
      </div>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push(
              createLink(links.request, {
                id: entry && entry.clientRequestId,
              }),
            )
          }
        >
          Go to Client Request
        </Button>
      </div>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push(
              createLink(links.user, {
                id: entry && entry.installerUserId,
              }),
            )
          }
        >
          Go to Installer
        </Button>
      </div>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push(
              createLink(links.organization, {
                id: entry && entry.organizationId,
              }),
            )
          }
        >
          Go to Organization
        </Button>
        <div className={styles.break} />
      </div>
      {(entry.state === OfferState.TenderSubmitted ||
        entry.state === OfferState.TenderSubmittedHomeCheck) && (
        <div className={styles.containerOuter}>
          {entry.state === OfferState.TenderSubmitted && (
            <div className={styles.containerInner}>
              <Button
                className={styles.button}
                type="primary"
                size="large"
                shape="round"
                onClick={() => acceptOffer()}
              >
                Accept Direct Installation Offer
              </Button>
            </div>
          )}

          {entry.state === OfferState.TenderSubmittedHomeCheck && (
            <div className={styles.containerInner}>
              <Button
                className={styles.button}
                type="primary"
                size="large"
                shape="round"
                onClick={() => acceptOffer()}
              >
                Accept Home Check Offer
              </Button>
            </div>
          )}

          <div className={styles.blockWrapper}>
            <div className={styles.containerInner}>
              <TextArea
                rows={4}
                placeholder="Reject Reason"
                onChange={(e) => setRejectReason(e.target.value)}
                value={rejectReason}
              />
              <Tooltip title={!rejectReason && 'Please enter reason to reject tender'}>
                <Button
                  className={styles.rejectButton}
                  type="primary"
                  danger
                  size="large"
                  shape="round"
                  disabled={!rejectReason}
                  onClick={() => rejectOffer()}
                >
                  Reject Tender
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default OfferActionsComponent;
