import { createSelector } from 'reselect';

import { ICommonState } from './common.types';

export const appSelector = (state: any) => state.common;

// Last opened page
export const lastOpenedPageSelector = createSelector(
  appSelector,
  (common: ICommonState) => common.lastOpenedPage,
);
