import React from 'react';

import styles from './styles.module.sass';

interface IProps {
  triggerReload: Function;
}

const VendorActionsComponent: React.FC<IProps> = () => {
  return <div className={styles.containerOuter}></div>;
};
export default VendorActionsComponent;
