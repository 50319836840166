import * as React from 'react';
import { useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import ClientIdEntitySelector from 'components/forms/organization/ClientIdEntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { WebhookValidation } from 'pages/integration/webhooks/webhooks.validation';
import ApiClientService from 'services/auth/api-client.service';
import { IWebhook, WebhookSubscriptionType } from 'services/integration/webhooks-service.types';

const WebhookOverviewTab: React.FC<IGenericEditTabProps<IWebhook>> = (
  props: IGenericEditTabProps<IWebhook>,
) => {
  const { entry, onSave } = props;
  const [webhook, setWebhook] = React.useState(entry);

  useEffect(() => {
    setWebhook(webhook);
  }, [entry]); // eslint-disable-line

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(WebhookValidation);

  return (
    <div>
      <Formik
        initialValues={webhook as IWebhook}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, setFieldValue, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="id" label="Id">
              <span>{entry?.id}</span>
            </Form.Item>

            <Form.Item name="createdAt" label="createdAt">
              <span>{entry?.createdAt}</span>
            </Form.Item>

            <Form.Item name="updatedAt" label="updatedAt">
              <span>{entry?.updatedAt}</span>
            </Form.Item>

            <Form.Item name="deletedDate" label="deletedDate">
              <span>{entry?.deletedDate || 'N/A'}</span>
            </Form.Item>

            <Form.Item name="enabled" label="Enabled">
              <Switch name="enabled" />
            </Form.Item>

            <Form.Item name="url" label="URL">
              <Input name="url" />
            </Form.Item>

            <Form.Item name="subscription" label="Subscription">
              <Select name="subscription">
                <Select.Option value={WebhookSubscriptionType.ClientRequestStatusUpdate}>
                  ClientRequestStatusUpdate
                </Select.Option>
                <Select.Option value={WebhookSubscriptionType.OfferStatusUpdate}>
                  OfferStatusUpdate
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="apiClientId" label="Api Client Id">
              <ClientIdEntitySelector
                name="apiClientId"
                entityService={ApiClientService}
                current={entry?.apiClientId}
                onSelect={(entry: any) => setFieldValue('apiClientId', entry.apiClientId)}
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WebhookOverviewTab;
