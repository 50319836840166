import config from 'config';

import { ISA_PROBLEMS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAProblem } from '../isa-service.types';

const ISAProblemsService = new EntityService<IISAProblem>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_PROBLEMS_ENDPOINT,
  createLink: links.isaProblemsCreate,
  detailLink: links.isaProblemsDetails,
});

ISAProblemsService.getDisplayName = (entry) => entry.value || entry.logicalName;

export default ISAProblemsService;
