import React from 'react';
import { Button, Typography } from 'antd';
import { FieldArray, FormikValues } from 'formik';

import { FormInput, FormSelect } from 'components/forms';

import DeleteButton from './DeleteCircleBtn';
import ImageContentBlock from './ImageContentBlock';
import TextContentBlock from './TextContentBlock';

import styles from './LandingComponentsStyles.module.sass';

const { Title } = Typography;

interface SubComponentProps {
  index: number;
  i: number;
  values: FormikValues;
}

const BlockContentSubComponent: React.FC<SubComponentProps> = (props: SubComponentProps) => {
  const { index, i, values } = props;

  const renderContent = (contentType: string, contentItemString: string) => {
    switch (contentType) {
      case 'text':
        return <TextContentBlock contentItemPath={contentItemString} />;
      case 'image':
        return <ImageContentBlock contentItemPath={contentItemString} />;
      case 'form':
        return <FormInput name={`${contentItemString}.form`} label="Form" />;
    }
  };

  return (
    <FieldArray
      name={`scheme.blocks[${index}].content[${i}].content`}
      render={(arrayHelpers) => {
        return (
          <div>
            {values?.scheme?.blocks[index]?.content[i].content?.map(
              (content: any, subindex: number) => {
                const subcontentType = content.type;
                const subcontentItemString = `scheme.blocks[${index}].content[${i}].content[${subindex}]`;

                return (
                  <React.Fragment key={`${subcontentItemString}_${subcontentType}_${i}`}>
                    <DeleteButton
                      tooltip="Delete this content sub-block"
                      onClick={() => arrayHelpers.remove(subindex)}
                    />

                    <div className={styles.center}>
                      <Title level={5}>Content Sub Block</Title>
                    </div>
                    <FormSelect
                      name={`${subcontentItemString}.type`}
                      options={['text', 'image', 'form']}
                      label="Сontent block type"
                    />
                    {renderContent(subcontentType, subcontentItemString)}
                  </React.Fragment>
                );
              },
            )}
            <div className={styles.center}>
              <Button className={styles.button} onClick={() => arrayHelpers.push({})}>
                Add new content sub-block
              </Button>
            </div>
          </div>
        );
      }}
    />
  );
};

export default BlockContentSubComponent;
