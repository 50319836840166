import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import AppointmentService from 'services/tendergy/tendergy-appointment.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import AppointmentBaseStep from './steps/AppointmentBaseStep';

const AppointmentCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add appointment',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <AppointmentBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Appointment"
      steps={steps}
      baseLink={links.appointments}
      defaultPayload={params}
      resource={AppointmentService}
    />
  );
};

export default AppointmentCreatePage;
