import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import ProductsService from 'services/shop/shop-products.service';
import { IShopProduct, IShopProductAttribute } from 'services/shop/shop-service.types';

import { ProductAttributesValidation } from '../../product-attributes.validation';

interface Props {
  entry: IShopProductAttribute;
  onSubmitStep: (entry: IShopProductAttribute) => void;
}

const ProductAttributeBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(ProductAttributesValidation);

  const defaultEntry = { ...entry, imageUrls: [], required: true };
  return (
    <div>
      <Formik
        initialValues={defaultEntry as IShopProductAttribute}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, dirty }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="title" label="Title" required>
              <Input name="title" placeholder="" />
            </Form.Item>
            <Form.Item name="subtitle" label="Subtitle">
              <Input name="subtitle" placeholder="" />
            </Form.Item>
            <Form.Item name="descriptionTitle" label="Description Title">
              <Input name="descriptionTitle" placeholder="" />
            </Form.Item>
            <Form.Item name="orderTitle" label="Order Title">
              <Input name="orderTitle" placeholder="" />
            </Form.Item>
            <Form.Item name="index" label="Index" required>
              <Input name="index" placeholder="" type="number" />
            </Form.Item>
            <Form.Item name="required" label="Required">
              <Switch name="required" />
            </Form.Item>

            <Form.Item name="product" required label="Product">
              <EntitySelector
                name="product"
                entityService={ProductsService}
                current={entry.product}
                onSelect={(product: IShopProduct) => setFieldValue('product', product)}
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting || !isValid || !dirty}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductAttributeBaseStep;
