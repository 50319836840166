import React from 'react';

import { FormInput, FormSelect } from 'components/forms';
import FormSwitch from 'components/forms/FormSwitch/FormSwitch';

import styles from './LandingComponentsStyles.module.sass';

interface ImageContentBlockProps {
  contentItemPath: string;
}

const ImageContentBlock: React.FC<ImageContentBlockProps> = ({
  contentItemPath,
}: ImageContentBlockProps) => {
  const blockContentStyle = `${contentItemPath}.style`;
  return (
    <>
      <FormInput name={`${contentItemPath}.title`} label="Title" />
      <FormInput name={`${contentItemPath}.subTitle`} label="Subtitle" required />
      <FormInput name={`${contentItemPath}.text`} label="Text" required />
      <FormSwitch name={`${contentItemPath}.underlined`} label="Underlined" />
      <div className={styles.center}>
        <p className={styles.subtitle}>Styling:</p>
      </div>
      <FormInput name={`${blockContentStyle}.backgroundColor`} label={'Background color'} />
      <FormSelect
        name={`${blockContentStyle}.fontWeight`}
        label={'Font weight'}
        options={['lighter', 'bold', 'bolder']}
      />
      <FormSelect
        name={`${blockContentStyle}.position`}
        label={'Position'}
        options={['fixed', 'absolute', 'relative']}
      />
      <FormInput name={`${blockContentStyle}.fontSize`} label={'Font size'} />
      <FormInput name={`${blockContentStyle}.margin`} label={'Margin'} />
      <FormInput name={`${blockContentStyle}.padding`} label={'Padding'} />
      <FormInput name={`${blockContentStyle}.borderRadius`} label={'Border radius'} />
    </>
  );
};

export default ImageContentBlock;
