import * as React from 'react';
import { useState } from 'react';
import CodeMirror from 'react-codemirror';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import { CrudFormDefaultActionParams } from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { IEmailLayout } from 'services/email/email-service.types';

import { LayoutValidation } from '../../layout.validation';

import 'codemirror/theme/material.css';
import styles from './LayoutTabsStyles.module.sass';

require('codemirror/lib/codemirror.css');
require('codemirror/mode/htmlmixed/htmlmixed');

const validation = Yup.object().shape(LayoutValidation);

const LayoutOverviewTab: React.FC<IGenericEditTabProps<IEmailLayout>> = (
  props: IGenericEditTabProps<IEmailLayout>,
) => {
  const { entry, onSave } = props;
  const [header, setHeader] = useState<string>(entry.header || '');
  const [footer, setFooter] = useState<string>(entry.footer || '');
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const options = {
    lineNumbers: true,
    mode: { name: 'htmlmixed' },
    theme: 'material',
    tags: {
      style: [
        ['type', /^text\/(x-)?scss$/, 'text/x-scss'],
        [null, null, 'css'],
      ],
      custom: [[null, null, 'customMode']],
    },
  };
  return (
    <Formik
      initialValues={entry as IEmailLayout}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        onSave(values).then(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit}>
          <Form.Item name="context" label="Context" required>
            <Input name="context" />
          </Form.Item>

          <Form.Item name="language" label="Language" required>
            <Select name="language">
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="de">German</Select.Option>
              <Select.Option value="fr">French</Select.Option>
              <Select.Option value="it">Italy</Select.Option>
            </Select>
          </Form.Item>
          <div>
            <p className={styles.tipsWrapper}>
              Please read the
              <a href="https://mjml.io/documentation/#components"> documentation </a>
              and use
              <a href="https://mjml.io/try-it-live/BJrgTzjav"> this editor </a>
              to preview the email. Copy & paste MJML code to header and footer fields.
            </p>
          </div>
          <Form.Item name="header" required>
            <div className={styles.codeWrapper}>Header:</div>
            <CodeMirror
              value={header}
              onChange={(value) => {
                setFieldValue('header', value);
                setHeader(value);
              }}
              options={options}
            />
          </Form.Item>
          <Form.Item name="footer" required>
            <div className={styles.codeWrapper}>Footer:</div>
            <CodeMirror
              value={footer}
              onChange={(value) => {
                setFieldValue('footer', value);
                setFooter(value);
              }}
              options={options}
            />
          </Form.Item>

          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton style={{ marginLeft: '30px' }}>Submit</SubmitButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default LayoutOverviewTab;
