import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd/lib';
import { SiderProps } from 'antd/lib/layout';
import { MenuProps } from 'antd/lib/menu';
import config from 'config';

import links from 'framework/links';
import { logout } from 'store/auth/auth.actions';
import { isLoadingSelector, userSelector } from 'store/auth/auth.selectors';

import i2gSmallLogo from '../..//assets/images/inno2fleetIcon.png';
import i2gLogo from '../../assets/images/inno2fleet.svg';
import MenuItem from './components/MenuItem';
import { MENU_ITEMS } from './items';

import styles from './styles.module.sass';

function Sidebar(props: SiderProps) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector(userSelector);
  const isLoading = useSelector(isLoadingSelector);
  const [openedSubmenu, setOpenedSubmenu] = React.useState<string[]>(['']);
  const [selectedKey, setSelectedKey] = React.useState<string>('');

  const { collapsed } = props;
  React.useEffect(() => {
    const opnedSubmenus: string[] = [];
    const currentSubmenu = MENU_ITEMS.find((item) => {
      return (item.menuItems as any).find((option: any) => {
        if (option?.link) {
          const link = pathname.includes(option.link);
          link && setSelectedKey(option.link);
          return !!link;
        } else {
          const subItem = option.menuItems.find((o: any) => pathname.includes(o.link));
          subItem && setSelectedKey(subItem.link);
          subItem && opnedSubmenus.push(option.key);
          return !!subItem;
        }
      });
    });
    if (currentSubmenu) {
      opnedSubmenus.unshift(currentSubmenu.key as string);
    } else {
      opnedSubmenus.push('');
    }
    setOpenedSubmenu(opnedSubmenus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function handleSubmenuOpen(submenus: React.ReactText[]) {
    setOpenedSubmenu(submenus as string[]);
  }

  const menuProps: MenuProps = {
    mode: 'inline',
    selectedKeys: [selectedKey],
    openKeys: openedSubmenu,
    onOpenChange: handleSubmenuOpen,
    style: { backgroundColor: '#131630' },
  };

  const renderSubMenu = (item: any) => (
    <Menu.SubMenu key={item.key} title={item.title} style={{ backgroundColor: '#131630' }}>
      {item?.menuItems
        .filter(
          (menuItem: any) =>
            !(menuItem.permission && !userProfile.permissions?.includes(menuItem.permission)),
        )
        .map((menuItem: any) =>
          menuItem?.link ? (
            <MenuItem key={menuItem.link} {...menuItem} style={{ backgroundColor: '#131630' }} />
          ) : (
            renderSubMenu(menuItem)
          ),
        )}
    </Menu.SubMenu>
  );

  if (!isLoading && (!userProfile?.permissions || userProfile?.permissions.length === 0)) {
    dispatch(logout());
    history.push(links.login);
  }

  return (
    <Layout.Sider
      width={250}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: '#131630',
      }}
      {...props}
    >
      <div className={styles.sideWrapper}>
        <div>
          <div className={styles.logo}>
            <img
              src={collapsed ? i2gSmallLogo : i2gLogo}
              alt="logo"
              width={collapsed ? '45px' : '123px'}
              height="auto!important"
            />
          </div>
          <div className={styles.customItem}>
            {userProfile && (
              <Menu {...menuProps}>
                {MENU_ITEMS.filter(
                  (menuItem) =>
                    !(
                      menuItem.permission &&
                      !userProfile?.permissions?.includes(menuItem.permission)
                    ),
                ).map((item) =>
                  item?.type === 'link'
                    ? item.menuItems.map((menuItem) => (
                        <MenuItem key={menuItem.link} {...menuItem} />
                      ))
                    : renderSubMenu(item),
                )}
              </Menu>
            )}
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <a href={`${config.NEW_PORTAL_LINK}`} rel="noopener noreferrer" target="_blank">
            <Button type="primary" style={{ width: '100%' }}>
              Go to new Portal
            </Button>
          </a>
        </div>
      </div>
    </Layout.Sider>
  );
}

export default Sidebar;
