import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Typography } from 'antd';
import config from 'config';

import JsonTwoEntriesTab from 'components/layout/JsonTwoEntriesTab/JsonTwoEntriesTab';
import Tabs from 'components/layout/Tabs';
import { Loader } from 'components/ui';
import { UserRole } from 'framework/constants';
import links from 'framework/links';
import TendergyInstallerTab from 'pages/auth/users/details/tabs/TendergyInstallerTab/TendergyInstallerTab';
import TendergyUserTab from 'pages/auth/users/details/tabs/TendergyUserTab/TendergyUserTab';
import UserOverviewTab from 'pages/auth/users/details/tabs/UserOverviewTab/UserOverviewTab';
import AuthAPI from 'services/auth/auth.service';
import UserAuthService from 'services/auth/user-auth.service';
import TendergyUserService, {
  TendergyUserAuthService,
} from 'services/tendergy/tendergy-user.service';
import { IAuthUser } from 'store/authService/authService.types';
import { lastOpenedPageSelector } from 'store/common/common.selectors';
import {
  IGetFileLinkPayload,
  IGetSingleEntityPayload,
  IOfferDocument,
} from 'store/storeInterfaces';
import { ITendergyUser } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import UserActionsComponent from './components/UserActionsComponent/UserActionsComponent';
import UserDocumentsTab from './tabs/UserDocumentsTab/UserDocumentsTab';
import UserRatingsTab from './tabs/UserRatingsTab/UserRatingsTab';

import styles from './UserDetailsPage.module.sass';

const UserDetailsPages = Object.freeze({
  JSON: 'json',
  OVERVIEW: 'overview',
  TENDERGY_USER: 'tendergyUser',
  TENDERGY_INSTALLER: 'installer',
  USER_DOCUMENTS: 'userDocuments',
  RATINGS: 'ratings',
});

const UserDetailsPageNames = Object.freeze({
  [UserDetailsPages.OVERVIEW]: 'Overview',
  [UserDetailsPages.TENDERGY_USER]: 'Tendergy User',
  [UserDetailsPages.TENDERGY_INSTALLER]: 'Tendergy Installer',
  [UserDetailsPages.USER_DOCUMENTS]: 'Document Uploads',
  [UserDetailsPages.RATINGS]: 'Ratings',
  [UserDetailsPages.JSON]: 'JSON',
});

const UserDetails: React.FC = () => {
  const history = useHistory();
  const { id, page } = useParams<{ id: string; page: string }>();
  const activePage = page || UserDetailsPages.OVERVIEW;

  const [tendergyUser, setTendergyUser] = useState<ITendergyUser>();
  const [authUser, setAuthUser] = useState<IAuthUser>();
  const [documents, setDocuments] = useState<IOfferDocument[]>();

  const lastOpenedPage = useSelector(lastOpenedPageSelector);

  const craftParams: IGetSingleEntityPayload = {
    id,
    relations: ['currentPool'],
  };

  const filesPayload = {
    id,
    category: 'installer:confirmation',
  };

  const getCraftData = async () => {
    try {
      const response = await TendergyUserService.getById({ ...craftParams });
      setTendergyUser(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };
  const getAuthData = async () => {
    try {
      const response = await UserAuthService.getById({ id });
      setAuthUser(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const saveAuthData = async (updatedValues: IAuthUser) => {
    try {
      await UserAuthService.save({ ...updatedValues });
      showSuccessNotification('Successfully updated');
      getAuthData();
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const saveCraftData = async (updatedValues: ITendergyUser) => {
    try {
      await TendergyUserService.save({ ...updatedValues });
      getCraftData();
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const getUserDocuments = async () => {
    try {
      const response = await TendergyUserService.getFiles(filesPayload);
      // @ts-ignore
      setDocuments(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const openFile = async (fileName: string) => {
    const payload: IGetFileLinkPayload = {
      fileName,
    };
    let response;

    try {
      response = await TendergyUserService.getFileLink(payload);
      window.open(response.link, '_blank');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const confirmInstaller = () => {
    if (tendergyUser?.installer) {
      TendergyUserService.confirmById(tendergyUser.installer.id).then(() => {
        getCraftData();
      });
    }
  };

  const sendPasswordRecoverEmail = () => {
    if (tendergyUser) {
      TendergyUserAuthService.sendPasswordRecoverEmail(tendergyUser.email).then(() => {
        message.info('Password recovery email successfully sent');
      });
    }
  };

  const sendEmailVerification = () => {
    if (tendergyUser) {
      UserAuthService.sendVerifyEmail(tendergyUser.id).then(() => {
        message.info('Password recovery email successfully sent');
      });
    }
  };

  const sendPasswordSetEmail = () => {
    if (tendergyUser?.createdByClientId) {
      TendergyUserService.sendPasswordSetEmail(tendergyUser.id).then(() => {
        message.info('Password set email successfully sent');
      });
    }
  };

  const rejectInstaller = (rejectReason: string) => {
    if (tendergyUser?.installer) {
      const payload = { id: tendergyUser.installer.id, rejectReason };

      TendergyUserService.rejectById(payload).then(() => {
        getCraftData();
      });
    }
  };

  const deleteUser = async () => {
    const payload = { id };
    try {
      await UserAuthService.deleteById(payload);
      showSuccessNotification('Entry successfully removed');
      // @ts-ignore
      history.goBack();
    } catch (e) {
      showErrorNotification(e);
      console.error(e);
    }
  };

  const loginAsUser = async () => {
    let token;
    try {
      token = await AuthAPI.loginAsUser(id);
      window.open(`${config.TENDERGY_UI_URL}login/token/${token.data?.tempToken}`, '_blank');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    getAuthData();
    getCraftData();
    getUserDocuments();
  }, [id]); //eslint-disable-line

  const pagesToRender = { ...UserDetailsPageNames };

  const renderContent = () => {
    switch (activePage) {
      case UserDetailsPages.JSON:
        return (
          <JsonTwoEntriesTab
            firstEntry={authUser}
            firstTitle="Auth Data"
            secondEntry={tendergyUser}
            secondTitle="Craft Data"
          />
        );
      case UserDetailsPages.OVERVIEW:
        return <UserOverviewTab entry={authUser} onSave={saveAuthData} />;
      case UserDetailsPages.TENDERGY_USER:
        return <TendergyUserTab entry={tendergyUser} onSave={saveCraftData} />;
      case UserDetailsPages.TENDERGY_INSTALLER:
        return <TendergyInstallerTab entry={tendergyUser} onSave={saveCraftData} />;
      case UserDetailsPages.USER_DOCUMENTS:
        return <UserDocumentsTab entry={documents} onOpenFile={openFile} />;
      case UserDetailsPages.RATINGS:
        return <UserRatingsTab entry={tendergyUser} />;
      default:
        return null;
    }
  };

  const renderTabs = () => {
    if (!tendergyUser) delete pagesToRender.tendergyUser;

    authUser && // eslint-disable-next-line array-callback-return
      authUser.roles.map((role: string) => {
        switch (role) {
          case UserRole.TendergyInstaller:
            delete pagesToRender.craftUser;
            break;
          case UserRole.TendergyInstallerManager:
            delete pagesToRender.craftUser;
            break;
          case UserRole.TendergyIntermediateManager:
            delete pagesToRender.craftUser;
            break;
          case UserRole.User:
            delete pagesToRender.installer;
            delete pagesToRender.ratings;
            delete pagesToRender.userDocuments;
            break;
          default:
            delete pagesToRender.installer;
            delete pagesToRender.craftUser;
            delete pagesToRender.userDocuments;
            delete pagesToRender.ratings;
            break;
        }
      });

    return (
      <Tabs
        activeTitle={
          activePage === UserDetailsPages.RATINGS ? 'Ratings and reviews' : 'Details of User'
        }
        activePageKey={activePage}
        baseRoute={{
          link: links.user,
          params: { id },
        }}
        contentMinHeight="calc(100vh - 280px)"
        pages={pagesToRender}
      >
        {renderContent()}
      </Tabs>
    );
  };

  const renderActions = () => (
    <UserActionsComponent
      isEmailVerified={authUser?.emailVerified}
      entry={tendergyUser}
      loginAsUser={loginAsUser}
      onDelete={deleteUser}
      rejectInstaller={rejectInstaller}
      confirmInstaller={confirmInstaller}
      passwordRecover={sendPasswordRecoverEmail}
      setPassword={sendPasswordSetEmail}
      emailVerification={sendEmailVerification}
    />
  );

  const goBack = () => {
    if (lastOpenedPage) {
      history.push(lastOpenedPage);
    } else {
      // @ts-ignore
      history.push(links.users);
    }
  };

  if (!authUser) {
    return <Loader />;
  }

  return (
    <Row gutter={16}>
      <Col span={18}>
        <Button type="link" className={styles.backButton} onClick={goBack}>
          <ArrowLeftOutlined />
          <span>Back</span>
        </Button>
        <Typography.Title level={3} className={styles.title}>
          {`${authUser.displayId} ${authUser.firstName} ${authUser.lastName}`}
        </Typography.Title>
        {renderTabs()}
      </Col>
      <Col span={6}>{renderActions()}</Col>
    </Row>
  );
};

export default UserDetails;
