import React from 'react';
import { Tag } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillInvoicesService from 'services/billing-v3/billing-v3-invoices.service';
import {
  IBillingInvoice,
  IBillingOrganization,
} from 'services/billing-v3/billing-v3-service.types';
import { formatDefaultDate } from 'utils/date.helper';

import { IB } from '../charging-stations/constants';
import Filter from './filter';

export const StatusChecker = (status: string) => {
  switch (status) {
    case 'done':
      return <Tag color="green">Done</Tag>;
    case 'active':
      return <Tag color="green">Active</Tag>;
    case 'error':
      return <Tag color="red">Error</Tag>;
    case 'pending':
      return <Tag color="yellow">Pending</Tag>;
    case 'calculating':
      return <Tag color="blue">Calculating</Tag>;
    case 'canceled':
      return <Tag color="orange">Canceled</Tag>;
    case 'inprogress':
      return <Tag color="orange">In Progress</Tag>;
    case 'generating_bill':
      return <Tag color="blue">Generating Bill</Tag>;
    case 'calculation_done':
      return <Tag color="orange">Calculation Done</Tag>;
    case 'waiting_for_feedback':
      return <Tag color="gray">Waiting For Feedback</Tag>;
    default:
      return <Tag color="gray">Unknown</Tag>;
  }
};

const columns: IB<IBillingInvoice>[] = [
  {
    title: 'Organization',
    dataIndex: 'organization',
    render: (organization: IBillingOrganization) => organization?.name || '-',
    sorter: true,
    sortParameter: 'organization.name',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Period',
    key: 'period',
    dataIndex: 'month',
    render: (_, entry: IBillingInvoice) =>
      `${moment()
        .month(entry.month - 1)
        .format('MMMM')} ${entry.year}`,
    sorter: true,
    sortParameter: 'month',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (value: string) => StatusChecker(value),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Modified',
    dataIndex: 'updatedAt',
    render: (date: string) => formatDefaultDate(date),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const InvoicesOverviewPages: React.FC = () => {
  return (
    <CrudListViewContainer
      // additionalPayload={params}
      entityName="Billing Invoices"
      customFilters={Filter}
      columns={columns}
      resource={BillInvoicesService}
    />
  );
};

export default InvoicesOverviewPages;
