import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import ProductAttributesService from 'services/shop/shop-product-attributes.service';
import { IShopProductAttribute } from 'services/shop/shop-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import ProductAttributeActionsComponent from './components/ProductAttributeActionsComponent/ProductAttributeActionsComponent';
import AttributeOptionsTab from './tabs/AttributeOptionsTab';
import ProductAttributeOverviewTab from './tabs/ProductAttributeOverviewTab';

const ProductAttributeDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['productAttributeOptions', 'product'],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IShopProductAttribute, optionalProps?: ICrudDetailViewCallbacks) => (
        <ProductAttributeOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
    {
      name: 'Attribute Options',
      displayName: 'Attribute Options',
      render: (entity: IShopProductAttribute) => (
        <AttributeOptionsTab options={entity.productAttributeOptions} />
      ),
    },
  ];

  const renderActions = () => <ProductAttributeActionsComponent />;

  return (
    <CrudDetailContainer
      entityName="Product Attribute"
      tabs={tabs}
      baseLink={links.shopProductAttribute}
      defaultPayload={params}
      renderActions={renderActions}
      resource={ProductAttributesService}
    />
  );
};

export default ProductAttributeDetailsPage;
