import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ConsultantCountriesService } from 'services/consulant/countries';
import { IParams } from 'services/isa-service/isa-service.types';

import DetailTab from './components/details/CountriesDetailTab';

export default function CountriesDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <DetailTab entry={entry} onSave={onSave} />,
        },
      ]}
      entityName="Countries"
      baseLink={links.eCCountriesDetail}
      defaultPayload={{ id }}
      resource={ConsultantCountriesService}
      hideJsonTab
    />
  );
}
