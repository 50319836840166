import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { ColumnsType } from 'antd/lib/table';

import links from 'framework/links';
import CustomDocumentsService from 'services/tendergy/tendergy-custom-documents.service';
import {
  ICustomDocument,
  ITendergyClientRequestPool,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

interface IPoolCustomDocumentsTab {
  entry?: ITendergyClientRequestPool;
}

const ClientRequestPoolCustomDocumentsTab: React.FC<IPoolCustomDocumentsTab> = (
  props: IPoolCustomDocumentsTab,
) => {
  const { entry } = props;
  const [customDocuments, setCustomDocuments] = useState<ICustomDocument[] | null>(null);

  const history = useHistory();

  const params: any = {
    scopeType: 'client_request_pools',
    scopeId: entry && entry.id,
  };

  const getCustomDocuments = async () => {
    try {
      const response = await CustomDocumentsService.getEntries({ ...params });
      setCustomDocuments(response?.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    getCustomDocuments();
  }, []); // eslint-disable-line

  const columns: ColumnsType<ICustomDocument> = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 150,
    },
    {
      title: 'Scope Type',
      dataIndex: 'scopeType',
      key: 'scopeType',
      width: 150,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => formatDefaultDate(date),
      width: 150,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (doc: ICustomDocument) => (
        <Button onClick={() => history.push(`${links.customDocuments}/${doc.id}`)}>Open</Button>
      ),
      width: 150,
    },
  ];

  const buttonProps: ButtonProps = {
    type: 'primary',
    shape: 'round',
    icon: <PlusOutlined />,
    onClick: () => history.push(`${links.createCustomDocument}/?poolId=${entry && entry.id}`),
  };

  if (!customDocuments) {
    return <div>Client Request Pool Custom Documents data is missing</div>;
  }

  return (
    <>
      <Row justify="end">
        <Col>
          <div style={{ marginBottom: 10 }}>
            <Button {...buttonProps}>Add custom document</Button>
          </div>
        </Col>
      </Row>
      <Table columns={columns} dataSource={customDocuments} scroll={{ x: '100%' }} />
    </>
  );
};

export default ClientRequestPoolCustomDocumentsTab;
