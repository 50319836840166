import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import links from 'framework/links';
import { IBillingRFIDCard } from 'services/billing-v2/billing-v2-service.types';

import styles from './styles.module.sass';

interface ActionsComponentProps {
  entry: IBillingRFIDCard;
}

const RfidCardActions: React.FC<ActionsComponentProps> = ({ entry }: ActionsComponentProps) => {
  const history = useHistory();

  return (
    <div className={styles.containerInner}>
      <Button
        className={styles.button}
        type="primary"
        size="large"
        shape="round"
        onClick={() =>
          history.push({ pathname: links.billAttachRfidCardCarOwner }, { rfidCard: entry })
        }
      >
        Attach RFID Card to Car Owner
      </Button>
    </div>
  );
};
export default RfidCardActions;
