import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { ICustomDocument } from 'store/tendegyService/tendergyService.types';

import links from '../../framework/links';

class CustomDocumentsServiceClass extends EntityService<ICustomDocument> {
  getMedia = (id: string) => this.get(`${this.config.entityURL}/${id}/media`);

  uploadMedia = (document: File, customDocumentId: string): Promise<any> => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const data = new FormData();
    data.append('document', document);
    data.append('customDocumentId', customDocumentId);
    data.append('category', 'custom_document:image');

    return this.post(`files/upload/`, data, true, headers) as Promise<any>;
  };
}

const CustomDocumentsService = new CustomDocumentsServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/custom-documents',
  detailLink: links.customDocument,
  createLink: links.createCustomDocument,
});

export default CustomDocumentsService;
