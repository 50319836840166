import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Loader } from 'components/ui';
import links from 'framework/links';
import TendergyQuestionnaireStepService from 'services/tendergy/tendergy-questionnaire-step.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergyQuestionnaireStep } from 'store/tendegyService/tendergyService.types';

import QuestionnaireStepOverviewTab from './tabs/QuestionnaireStepOverviewTab';

const QuestionnaireStepDetailsPage: React.FC = () => {
  const [stepData, setStepData] = useState<ITendergyQuestionnaireStep>();
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params
  const params: IGetSingleEntityPayload = {
    id,
    relations: ['parent'],
  };
  const history = useHistory();

  const questionnaireStepsLink = stepData
    ? // @ts-ignore
      `${links.tendergyQuestionnaires}/${stepData.versionedQuestionnaireId.id}/${stepData.versionedQuestionnaireId.version}/steps`
    : '';

  useEffect(() => {
    TendergyQuestionnaireStepService.getById(params).then((response) => setStepData(response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return stepData ? (
    <QuestionnaireStepOverviewTab
      entry={stepData}
      onSave={(step: ITendergyQuestionnaireStep) => {
        TendergyQuestionnaireStepService.save(step);
      }}
      onDelete={() => {
        // @ts-ignore
        TendergyQuestionnaireStepService.deleteById({ id: stepData.id }).then(() => {
          history.push(questionnaireStepsLink);
        });
      }}
    />
  ) : (
    <Loader />
  );
};

export default QuestionnaireStepDetailsPage;
