import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IApiClient } from 'store/authService/authService.types';

class ApiClientServiceClass extends EntityService<IApiClient> {
  getDisplayName(entry: IApiClient): string {
    return entry && entry.clientName;
  }

  regenerateKey = (clientId: string) =>
    this.post(`${this.config.entityURL}/${clientId}/key/regenerate`);
}

const ApiClientService = new ApiClientServiceClass({
  apiURL: config.CLIENT_SERVICE_URL,
  entityURL: 'admin/api-client',
  createLink: links.createAuthApiClient,
  saveURL: 'admin/api-client/register',
  detailLink: links.authApiClient,
  entityPrimaryKey: 'clientId',
});

export default ApiClientService;
