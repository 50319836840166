import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/containers/PrivateRoute';
import DashboardLayout from 'components/layout/DashboardLayout';
import links from 'framework/links';
import CreateApiClientsPage from 'pages/auth/api-clients/create/CreateApiClientsPage';
import ApiClientsDetailsPage from 'pages/auth/api-clients/details/ApiClientsDetailsPage';
import ApiClientsOverviewPage from 'pages/auth/api-clients/overview/ApiClientsOverviewPage';
import Login from 'pages/auth/login';
import CreateOrganizationPage from 'pages/auth/organizations/create/CreateOrganizationPage';
import OrganizationDetails from 'pages/auth/organizations/details/OrganizationDetailsPage';
import OrganizationsOverview from 'pages/auth/organizations/overview/AuthOrganizationsPage';
import RoleDetails from 'pages/auth/roles/details/RoleDetailsPage';
import RolesOverview from 'pages/auth/roles/overview/RolesListView';
import UserDetails from 'pages/auth/users/details/UserDetailsPage';
import UsersOverview from 'pages/auth/users/overview/UsersListView';
import Dashboard from 'pages/DashboardPage/DashboardPage';
import ForbiddenPage from 'pages/ForbiddenPage/ForbiddenPage';
import HelpPage from 'pages/help/helpPage';

import { ROUTES_LIST } from './routes-list';

function Routes() {
  return (
    <Switch>
      <Redirect exact from="/" to={links.home} />

      <Route path={links.login} component={Login} exact />
      <Route path={links.forbidden} component={ForbiddenPage} exact />

      <DashboardLayout>
        <Switch>
          <PrivateRoute path={links.home} component={Dashboard} exact />

          <PrivateRoute path={links.users} component={UsersOverview} exact />
          <PrivateRoute path={links.user} component={UserDetails} exact />
          <PrivateRoute path={links.authRoles} component={RolesOverview} exact />
          <PrivateRoute path={links.authRole} component={RoleDetails} exact />

          <PrivateRoute path={links.authApiClients} component={ApiClientsOverviewPage} exact />
          <PrivateRoute path={links.createAuthApiClient} component={CreateApiClientsPage} exact />
          <PrivateRoute path={links.authApiClient} component={ApiClientsDetailsPage} exact />

          <PrivateRoute path={links.organizations} component={OrganizationsOverview} exact />
          <PrivateRoute path={links.organization} component={OrganizationDetails} exact />
          <PrivateRoute path={links.createOrganization} component={CreateOrganizationPage} exact />

          {ROUTES_LIST.map((route) => (
            <PrivateRoute key={route.path} path={route.path} component={route.component} exact />
          ))}

          <PrivateRoute path={links.helpPage} component={HelpPage} exact />
        </Switch>
      </DashboardLayout>
    </Switch>
  );
}

export default Routes;
