import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { CurrencyUtils } from 'utils/currencyUtils';

interface OrderItemsTabProps {
  entry?: any;
}

const OrderItemsTab: React.FC<OrderItemsTabProps> = (props: OrderItemsTabProps) => {
  const { entry } = props;
  const history = useHistory();

  if (!entry) {
    return <div>data entry is missing</div>;
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (value: string) => value || '-',
      width: 200,
      fixed: true,
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      render: (value: string) => value || '-',
      width: 150,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (value: number) => CurrencyUtils.defaultFormatter(value / 100) || '-',
      width: 100,
    },
    {
      title: 'Vat',
      dataIndex: 'vat',
      render: (value: string) => value || '-',
      width: 100,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (value: string) => value || '-',
      width: 100,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (value: number) => CurrencyUtils.defaultFormatter(value / 100) || '-',
      width: 100,
    },
  ];

  return (
    <Table
      columns={columns}
      scroll={{ x: '100%' }}
      dataSource={entry.orderItems}
      onRow={(record) => ({
        onClick: () => history.push(createLink(links.shopOrderItem, { id: record.id })),
      })}
    />
  );
};

export default OrderItemsTab;
