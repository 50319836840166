import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import {
  IConsultantElectrification,
  IConsultantLeadDetail,
  IConsultantParameters,
} from './consultant-service.types';

class ConsultantDefaultVariablesServiceClass extends EntityService<IConsultantParameters> {
  getDisplayName(entry: IConsultantParameters): string {
    return entry?.items?.[0].entity;
  }
}

class ConsultantLeadServiceClass extends EntityService<IConsultantLeadDetail> {
  getDisplayName(entry: IConsultantLeadDetail): string {
    return entry.data.message;
  }
}

class ConsultantElectrificationServiceClass extends EntityService<IConsultantElectrification> {
  getDisplayName(entry: IConsultantElectrification): string {
    return entry!.id!;
  }
}

const ConsultantLeadParametersService = new ConsultantDefaultVariablesServiceClass({
  apiURL: config.CONSULTANT_SERVICE_URL,
  entityURL: 'admin/leads',
  detailLink: links.eConsultantLead,
});
export const ConsultantLeadAllParametersService = new ConsultantDefaultVariablesServiceClass({
  apiURL: config.CONSULTANT_SERVICE_URL,
  entityURL: 'admin/leads/all',
  detailLink: links.eConsultantLeadAll,
});
export const ConsultantLocalLeadParametersService = new ConsultantDefaultVariablesServiceClass({
  apiURL: config.CONSULTANT_SERVICE_URL,
  entityURL: 'admin/leads',
  detailLink: links.eConsultantLead,
});
export const ConsultantLeadServiceFleetElectrification = new ConsultantElectrificationServiceClass({
  apiURL: config.CONSULTANT_SERVICE_URL,
  entityURL: 'admin/electrification',
  detailLink: links.eConsultantLead,
});
export const ConsultantLeadServiceFleetLead = new ConsultantLeadServiceClass({
  apiURL: config.CONSULTANT_SERVICE_URL,
  entityURL: 'admin/leads',
  detailLink: links.eConsultantLead,
});
// this way I can autowire the result (with command + .);
export default ConsultantLeadParametersService;
