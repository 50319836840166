import config from 'config';

import { ISA_DIAGNOSTICS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISADiagnostic } from '../isa-service.types';

const ISADiagnosticsService = new EntityService<IISADiagnostic>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_DIAGNOSTICS_ENDPOINT,
  createLink: links.isaDiagnosticsCreate,
  detailLink: links.isaDiagnosticsDetails,
});

ISADiagnosticsService.getDisplayName = (entry) => entry.title || entry.logicalName;

export default ISADiagnosticsService;
