import * as Yup from 'yup';

export const INITIAL_VALUES = {
  title: null,
  description: null,
  sequenceNumber: 0,
  solution: null,
};

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .max(64, 'Field has not to be longer than 64 characters!')
    .required('Required field'),
  description: Yup.string().nullable().required('Required field'),
  sequenceNumber: Yup.number().required('Required field'),
  solution: Yup.object().nullable(),
});
