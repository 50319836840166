import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  evse: Yup.string()
    .required('EVSE is required')
    .max(64, 'Field has not to be longer than 64 characters!'),
  type: Yup.string().required('Type is required'),
  chargePointGroup: Yup.object().required('chargePointGroup is required'),
  partnerType: Yup.string().required('Partner Type is required'),
  stationId: Yup.string().required('Station ID Type is required'),
  stationName: Yup.string().required('Station Name Type is required'),
  connectorId: Yup.string().required('connector ID Type is required'),
  connectorName: Yup.string().required('connector Name Type is required'),
  rentalId: Yup.string().required('Rental ID is required'),
  description: Yup.string().required('description is required'),
  power: Yup.string().required('Power is required'),
  plugType: Yup.string().required('Plug Type is required'),

  address: Yup.object().shape({
    address: Yup.string()
      .required('Address (street, house number, apartment number) is required')
      .max(256, 'Field has not to be longer than 256 characters!'),
    city: Yup.string()
      .required('City required')
      .max(64, 'Field has not to be longer than 64 characters!'),
    region: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
    country: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
    postalCode: Yup.string()
      .required('Postal code is required')
      .max(10, 'Field has not to be longer than 10 characters!'),
    latitude: Yup.string().nullable().required('latitude is required'),
    longitude: Yup.string().nullable().required('longitude is required'),
    iso3166_1_alpha_3: Yup.string()
      .required('ISO 3166-1 alpha-3 Country Code is required')
      .nullable()
      .max(3, 'Field has not to be longer than 3 characters!'),
  }),
  generateCoordinates: Yup.boolean().nullable(),
});
export const validationSchemaCPG = Yup.object().shape({
  type: Yup.string().required('Type is required'),

  address: Yup.object().shape({
    address: Yup.string()
      .required('Address (street, house number, apartment number) is required')
      .max(256, 'Field has not to be longer than 256 characters!'),
    city: Yup.string()
      .required('City required')
      .max(64, 'Field has not to be longer than 64 characters!'),
    region: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
    country: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
    postalCode: Yup.string()
      .required('Postal code is required')
      .max(10, 'Field has not to be longer than 10 characters!'),
    latitude: Yup.string().nullable().required('latitude is required'),
    longitude: Yup.string().nullable().required('longitude is required'),
    iso3166_1_alpha_3: Yup.string()
      .required('ISO 3166-1 alpha-3 Country Code is required')
      .nullable()
      .max(3, 'Field has not to be longer than 3 characters!'),
  }),
  generateCoordinates: Yup.boolean().nullable(),
});
export enum EEnergyPriceStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DECLINED = 'declined',
}

export const CreateEnergyPriceValidation = {
  chargePerUnit: Yup.number().required('Required').positive('Must be positive'),
  chargingPointId: Yup.string().required('Required'),
  payoutPerUnit: Yup.number().nullable(),
  validFrom: Yup.date().typeError('Date must be valid').required('Required'),
  status: Yup.mixed<EEnergyPriceStatus>()
    .required('Required')
    .oneOf(Object.values(EEnergyPriceStatus)),
};
