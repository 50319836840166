import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  evse: Yup.string()
    .required('EVSE is required')
    .max(64, 'Field has not to be longer than 64 characters!'),
  type: Yup.string()
    .required('Type is required')
    .max(10, 'Field has not to be longer than 10 characters!'),
  name: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
  label: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
  carOwner: Yup.object().when('type', {
    is: 'home',
    then: Yup.object().required('Please select car owner!'),
    otherwise: Yup.object().nullable(),
  }),
  organization: Yup.object().when('type', {
    is: 'work',
    then: Yup.object().required('Please select organization!'),
    otherwise: Yup.object().nullable(),
  }),
  address: Yup.object().shape({
    // ...rest of the address validation
  }),
  generateCoordinates: Yup.boolean().nullable(),
});
