import * as React from 'react';
import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';

import { CrudFormDefaultParams } from 'framework/abstract-crud/abstractCrud.constants';
import {
  IBusinessCase,
  IConsultantElectrification,
} from 'services/consulant/consultant-service.types';

import { validationSchema } from '../leads/validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */

interface Props {
  entry?: IBusinessCase | null;
  onSave: (updatedValues: IConsultantElectrification) => Promise<any>;
}

const RecommendationTab: React.FC<Props> = (props: Props) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }
  if (!entry) {
    return <div> Loading </div>;
  }

  return (
    <div>
      <Formik
        initialValues={entry as IBusinessCase}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ handleSubmit }) => {
          return (
            <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
              <Row>
                <Col xs={8}>Total Energy: {entry?.chartSummary?.totalEnergy}</Col>
                <Col xs={8}>Co2 Emission Saved: {entry?.chartSummary?.co2EmissionsSaved}</Col>
                <Col xs={8}>Saving Potential: {entry?.chartSummary?.savingPotential}</Col>
              </Row>
              <Divider />
              <Form.Item name="locationsAtWork" label="Locations At Work">
                {entry?.locationsAtWork || 'not specified'}
              </Form.Item>

              <Form.Item
                name="forecastedAnnualOpexCostsInfrastructure"
                label="Forecasted Annual Opex Costs Infrastructure"
              >
                {entry?.forecastedAnnualOpexCostsInfrastructure || 'not specified'}
              </Form.Item>
              <Form.Item
                name="forecastedCapexCostsInfrastructure"
                label="Forecasted Annual Capex Costs Infrastructure"
              >
                {entry?.forecastedCapexCostsInfrastructure || 'not specified'}
              </Form.Item>
              <Form.Item name="workChargingStations" label="Charge At Work">
                {entry?.workChargingStations || 'not specified'}
              </Form.Item>
              <Form.Item name="publicRFIDCards" label="Public RFID Cards">
                {entry?.publicRFIDCards || 'not specified'}
              </Form.Item>
              <Form.Item name="homeWallboxes" label="Charge At Home">
                {entry?.homeWallboxes || 'not specified'}
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RecommendationTab;
