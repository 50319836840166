import * as React from 'react';
import { Button, Col, Row, Table } from 'antd';

import FileUploader from 'components/ui/FileUploader';
import OfferService from 'services/tendergy/tendergy-offer.service';
import { IGetFileLinkPayload } from 'store/storeInterfaces';
import { MediaCategoryType } from 'store/tendegyService/tendergyService.types';
import { formatDateFromIso } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

interface IProps {
  documents?: any;
  deleteFile?: (fileName: string) => void;
  uploadFile?: (document: File, category: MediaCategoryType) => Promise<void>;
}

const OfferDocumentsTab: React.FC<IProps> = (props: IProps) => {
  const { documents, deleteFile, uploadFile } = props;
  if (!documents) {
    return <div>data entry is missing</div>;
  }

  const onFileClick = async (name: string) => {
    const payload: IGetFileLinkPayload = {
      fileName: name,
    };
    let response;

    try {
      response = await OfferService.getFileLink(payload);
      window.open(response.link, '_blank');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const columns = [
    {
      title: 'Original Name',
      key: 'originalName',
      dataIndex: 'document',
      render: (document: any) => document?.originalName,
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'document',
      render: (document: any) => document?.category,
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'document',
      render: (document: any) => formatDateFromIso(document?.createdAt),
    },
    {
      title: 'actions',
      key: 'action',
      render: (entry: any) => (
        <Row gutter={[16, 0]}>
          <Col>
            <Button onClick={() => onFileClick(entry?.document.name)}>Open</Button>
          </Col>
          <Col>
            <Button onClick={() => !!deleteFile && deleteFile(entry?.document.name)}>Delete</Button>
          </Col>
        </Row>
      ),
    },
  ];

  const fileUploaderProps = {
    categoriesList: Object.values(MediaCategoryType).filter((value) => value.includes('offer')),
    onFileUpload: uploadFile,
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <FileUploader {...fileUploaderProps} />
      </Col>
      <Col span={24}>
        <Table columns={columns} dataSource={documents} />
      </Col>
    </Row>
  );
};

export default OfferDocumentsTab;
