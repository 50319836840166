import React, { useEffect, useState } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Spin, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import { BASE_DATE_FORMAT, DATE_AND_TIME } from 'framework/constants/dateFormats';
import ProductModelService from 'services/tendergy/tendergy-product.service';
import { IHardwareReport } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

const { RangePicker } = DatePicker;

const HardwareOverviewPage: React.FC = () => {
  const [hardwareReport, setHardwareReport] = useState<IHardwareReport[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<IHardwareReport | null>(null);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);

  useEffect(() => {
    setPage(1);
    setLoading(true);
    getHardwareReport();
  }, [fromDate, toDate, limit, selectedProduct]); // eslint-disable-line

  useEffect(() => {
    setLoading(true);
    getHardwareReport();
  }, [page]); // eslint-disable-line

  const params: any = {
    fromDate,
    toDate,
    limit,
    offset: (page - 1) * limit,
  };

  const getHardwareReport = async () => {
    try {
      const response = selectedProduct
        ? await ProductModelService.getHardwareReport(selectedProduct.id, params)
        : await ProductModelService.getOverallHardwareReport(params);
      setHardwareReport(response.items);
      setTotal(response.total);
      setLoading(false);
    } catch (e) {
      showErrorNotification(e);
      setLoading(false);
    }
  };

  const onBackClick = () => {
    setSelectedProduct(null);
  };

  const renderTitle = () => {
    if (selectedProduct) {
      return `Hardware report by product: ${selectedProduct.name}`;
    }
    return 'Hardware report';
  };

  const dateRangePickerProps = {
    value: [fromDate ? moment(fromDate) : null, toDate ? moment(toDate) : null] as any,
    style: { margin: '0 10px' },
    onChange: (dates: any) => {
      setFromDate(dates ? dates[0].toISOString() : null);
      setToDate(dates ? dates[1].toISOString() : null);
    },
  };

  const overalColumns = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Total Installed',
      dataIndex: 'totalInstalled',
      key: 'totalInstalled',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
  ];

  const columns = [
    {
      title: 'Offer',
      dataIndex: 'displayId',
      key: 'displayId',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Installer First Name',
      dataIndex: 'firstName',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      key: 'firstName',
    },
    {
      title: 'Installer Last Name',
      dataIndex: 'lastName',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      key: 'lastName',
    },
    {
      title: 'Installation date',
      dataIndex: 'date',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      key: 'date',
      render: (date: string) => (date ? formatDefaultDate(date, DATE_AND_TIME) : '–'),
    },
  ];

  const tableProps = {
    rowKey: 'id',
    columns: selectedProduct ? columns : overalColumns,
    dataSource: hardwareReport,
    pagination: {
      total,
      defaultPageSize: limit,
      current: page,
      onChange: (page: number, pageSize: number | undefined = 10) => {
        setPage(page);
        setLimit(pageSize);
      },
      showSizeChanger: true,
    },
    onRow: (record: IHardwareReport) => ({
      onClick: !selectedProduct ? () => setSelectedProduct(record) : undefined,
    }),
  };

  const getFileName = () => {
    let name = renderTitle();
    if (toDate && fromDate) {
      name += ` (${moment(toDate).format(BASE_DATE_FORMAT)} - ${moment(fromDate).format(
        BASE_DATE_FORMAT,
      )})`;
    }
    return `${name}.csv`;
  };

  const onExportClick = async () => {
    try {
      if (selectedProduct) {
        await ProductModelService.downloadHardwareReport(getFileName(), selectedProduct.id, params);
      } else {
        await ProductModelService.downloadOverallHardwareReport(getFileName(), params);
      }
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col>
        <h2>{renderTitle()}</h2>
      </Col>
      <Col>
        {selectedProduct && <Button onClick={onBackClick}>Back</Button>}
        <RangePicker {...dateRangePickerProps} picker="date" />
        <Button icon={<FileExcelOutlined />} onClick={onExportClick}>
          Export to CSV
        </Button>
      </Col>
      <Col span={24}>
        <Spin tip="Loading..." spinning={loading}>
          <Table {...tableProps} />
        </Spin>
      </Col>
    </Row>
  );
};

export default HardwareOverviewPage;
