import * as React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import { ITendergyOrganization } from 'store/tendegyService/tendergyService.types';

interface Props {
  organization?: ITendergyOrganization;
}

interface Payload {
  organization?: string;
}

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (value: string) => value || '–',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    render: (value: string) => value || '–',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (value: string) => value || '–',
  },
];

const OrganizationMembersTab: React.FC<Props> = ({ organization }: Props) => {
  const payload: Payload = {
    organization: organization?.id,
  };
  return payload.organization ? (
    <CrudListViewContainer
      entityName="Members"
      columns={columns}
      additionalPayload={payload}
      resource={TendergyUserService}
      disableSearch
    />
  ) : null;
};

export default OrganizationMembersTab;
