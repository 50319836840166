// import * as Yup from 'yup';

/*
export interface IBillingCarOwner extends IBaseEntity {
  firstname?: string;
  lastname?: string;
  email: string;
  authId?: string;
  organizationId?: string;
  organization?: IBillingOrganization;
}
*/
import * as Yup from 'yup';

export const EnergyPriceValidation = {
  chargePerUnit: Yup.number().nullable(),
  payoutPerUnit: Yup.number().nullable(),
  validFrom: Yup.date().typeError('Date must be valid').required('Required'),
  validTo: Yup.date().typeError('Date must be valid').required('Required'),
  type: Yup.string().required('Required'),
};
