import React from 'react';
import { BarcodeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import DashboardTableContainer from 'components/containers/DashboardTableContainer/DashboardTableContainer';
import links from 'framework/links';
import ProductModelService from 'services/tendergy/tendergy-product.service';
import { ApprovalState, IProduct } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

import styles from './DashboardPage.module.sass';

const columns: ColumnsType<IProduct> = [
  {
    title: 'Product name',
    dataIndex: 'name',
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const payload: any = {
  state: ApprovalState.Pending,
};

const ProductTable: React.FC = () => (
  <DashboardTableContainer
    columns={columns}
    resource={ProductModelService}
    title="Unapproved products"
    defaultPayload={payload}
    basicUrl={links.productModels}
    icon={<BarcodeOutlined className={styles.icon} />}
  />
);

export default ProductTable;
