import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IBillingChargingStation } from 'services/billing/billing-service.types';
import ChargingStationService from 'services/billing/charging-station.service';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IBillingChargingStation> = [
  {
    title: 'evse',
    dataIndex: 'evse',
    key: 'evse',
  },
  {
    title: 'country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const ChargingStationsOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Charging Stations"
    columns={columns}
    resource={ChargingStationService}
  />
);
export default ChargingStationsOverviewPage;
