import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import {
  ITendergyClientRequestPool,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

interface IClientRequestsTab {
  entry?: ITendergyClientRequestPool;
}

const PoolTenderOrganizationsTab: React.FC<IClientRequestsTab> = (props: IClientRequestsTab) => {
  const { entry } = props;

  const history = useHistory();

  const columns = [
    {
      title: 'Id',
      dataIndex: 'organization',
      key: 'organization',
      render: (organization: ITendergyOrganization) => organization?.displayId,
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'organization',
      key: 'organization',
      render: (organization: ITendergyOrganization) => organization?.name,
      width: 150,
    },
    {
      title: 'Type',
      dataIndex: 'organization',
      key: 'organization',
      render: (organization: ITendergyOrganization) => organization?.type,
      width: 150,
    },
    {
      title: 'Created',
      dataIndex: 'organization',
      key: 'organization',
      render: (organization: ITendergyOrganization) =>
        organization?.createdAt && formatDefaultDate(organization.createdAt),
      width: 150,
    },
  ];

  if (!entry) {
    return <div>Client Request Pool data is missing</div>;
  }

  return (
    <Table
      columns={columns}
      dataSource={entry.poolTenderOrganizations}
      scroll={{ x: '100%' }}
      onRow={(relation) => ({
        onClick: () =>
          history.push(createLink(links.organization, { id: relation.organization.id })),
      })}
    />
  );
};

export default PoolTenderOrganizationsTab;
