import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import VendorService from 'services/tendergy/tendergy-vendor.service';
import { IProductModel, IProductVendor } from 'store/tendegyService/tendergyService.types';

import { ProductValidation } from '../../product.validation';

interface Props {
  entry: IProductModel;
  onSubmitStep: (entry: IProductModel) => void;
}

const ProductStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(ProductValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IProductModel}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep(values)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, isValid, dirty }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="vendor" label="Vendor">
              <EntitySelector
                name="vendor"
                entityService={VendorService}
                current={entry.vendor}
                onSelect={(vendor: IProductVendor) => setFieldValue('vendor', vendor)}
              />
            </Form.Item>

            <Form.Item name="name" label="Product name">
              <Input name="name" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<ArrowRightOutlined />}
                disabled={isSubmitting || !isValid || !dirty}
              >
                Create
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductStep;
