import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import BillingCarOwnerService from 'services/billing/billing-car-owner.service';
import { IBillingCarOwner, IBillingChargingStation } from 'services/billing/billing-service.types';

import { ChargingStationValidation } from '../../charging-station.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const ChargingStationDetailsTab: React.FC<IGenericEditTabProps<IBillingChargingStation>> = (
  props: IGenericEditTabProps<IBillingChargingStation>,
) => {
  const { entry, onSave } = props;
  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  // @todo: we might pick only relevant fields..
  const validation = Yup.object().shape(ChargingStationValidation);

  return (
    <div>
      <Formik
        initialValues={entry as any}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, setFieldValue, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="carOwner" label="Carowner">
              <EntitySelector
                name="carOwner"
                entityService={BillingCarOwnerService}
                current={entry.carOwner}
                onSelect={(carOwner: IBillingCarOwner) => setFieldValue('carOwner', carOwner)}
              />
            </Form.Item>

            <Form.Item name="evse" label="Evse">
              <Input name="evse" placeholder="" />
            </Form.Item>
            <Form.Item name="label" label="Label">
              <Input name="label" placeholder="label" />
            </Form.Item>

            <Form.Item name="name" label="name">
              <Input name="name" placeholder="" />
            </Form.Item>

            <Form.Item name="type" label="type">
              <Select name="type">
                <Select.Option value="home">home</Select.Option>
                <Select.Option value="work">work</Select.Option>
                <Select.Option value="public">public</Select.Option>
              </Select>
            </Form.Item>

            {/* country?: string;
  region?: string;
  city?: string;
  postal_code?: string;
  street?: string;
  house_number?: string;
  latitude?: string;
  longitude?: string;
   */}

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChargingStationDetailsTab;
