import * as React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import ChargingStationService from 'services/billing-v2/billing-v2-charging-stations.service';
import { CarOwnerPayload, IBillingCarOwner } from 'services/billing-v2/billing-v2-service.types';

interface Props {
  entry?: IBillingCarOwner;
}

const columns = [
  {
    title: 'EVSE',
    dataIndex: 'evse',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Label',
    dataIndex: 'label',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const ChargingStationsTab: React.FC<Props> = ({ entry }: Props) => {
  const payload: CarOwnerPayload = {
    carOwnerId: entry?.id,
  };

  return (
    <CrudListViewContainer
      entityName="Charging Stations"
      columns={columns}
      additionalPayload={payload}
      resource={ChargingStationService}
      disableSearch
    />
  );
};

export default ChargingStationsTab;
