import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import OrganizationAuthAPI from 'services/auth/auth-organization.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';

import * as actions from './organizations.actions';
import { GET, GET_CRAFT_ENTRIES, GetOrganizationsAction } from './organizations.types';

export function* get({ payload }: GetOrganizationsAction) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(OrganizationAuthAPI.getEntries, payload);

    yield put(actions.set(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getCraftOrganizations() {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(TendergyOrganizationService.getEntries);

    yield put(actions.setCraftOrganizations(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([takeLatest(GET, get), takeLatest(GET_CRAFT_ENTRIES, getCraftOrganizations)]);
}
