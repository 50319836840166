import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { OfferState } from 'framework/constants';
import { BASE_DATE_FORMAT } from 'framework/constants/dateFormats';
import OfferService from 'services/tendergy/tendergy-offer.service';
import { IOffer } from 'store/tendegyService/tendergyService.types';

import OffersFilters from '../details/components/OffersFilters';

const columns = [
  {
    title: 'No.',
    dataIndex: 'displayId',
    sorter: true,
    defaultSortOrder: 'descend' as SortOrder,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
  },
  {
    title: 'Installer',
    key: 'installer',
    sorter: true,
    dataIndex: 'installerUser',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    sortParameter: 'installerUser.firstName',
    render: (_: any, entry: IOffer) =>
      entry.installerUser
        ? `${entry.installerUser?.firstName} ${entry.installerUser?.lastName}`
        : '–',
  },

  {
    title: 'State',
    dataIndex: 'state',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (value: OfferState) => value || '–',
  },
  {
    title: 'Action',
    dataIndex: 'state',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (value: OfferState) => {
      switch (value) {
        case OfferState.TenderSubmitted:
        case OfferState.TenderSubmittedHomeCheck:
          return <span>Accept / Reject Tender</span>;
        default:
          return <span>View Only</span>;
      }
    },
  },
  {
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    title: 'Created',
    dataIndex: 'createdAt',
    render: (date: string) => (date ? moment(date).format(BASE_DATE_FORMAT) : '–'),
  },
];

const RequestsOverview: React.FC = () => {
  return (
    <CrudListViewContainer
      entityName="Offers"
      columns={columns}
      disableSearch
      resource={OfferService}
      customFilters={OffersFilters}
      defaultPayload={{ sortFields: 'displayId' }}
    />
  );
};
export default RequestsOverview;
