import config from 'config';

import EntityService from 'services/abstract/entity.service';
import { ITendergyMembershipPlan } from 'store/tendegyService/tendergyService.types';

class MembershipServiceClass extends EntityService<ITendergyMembershipPlan> {}

const MembershipService = new MembershipServiceClass({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/membership-plans',
});

export default MembershipService;
