import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import EmailLayoutService from 'services/email/email-layouts.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import LayoutBasicStep from './steps/LayoutBasicStep';

const LayoutCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add layout',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <LayoutBasicStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Layout"
      steps={steps}
      baseLink={links.emailLayout}
      defaultPayload={params}
      resource={EmailLayoutService}
    />
  );
};

export default LayoutCreatePage;
