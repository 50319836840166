import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import AuthOrganizationService from 'services/auth/auth-organization.service';

import * as actions from './organization.actions';
import { GET, GET_MEMBERS, GetMembersAction, GetOrganizationAction } from './organization.types';

export function* getById({ payload }: GetOrganizationAction) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(AuthOrganizationService.getById, payload);

    yield put(actions.set(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getMembersById({ payload }: GetMembersAction) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield call(AuthOrganizationService.getMembersById, payload);
    yield put(actions.setMembers(data));
  } catch (err) {
    message.error(err);
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([takeLatest(GET, getById), takeLatest(GET_MEMBERS, getMembersById)]);
}
