import React from 'react';

import { FormEntitySelect, FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import FormSwitch from 'components/forms/FormSwitch/FormSwitch';
import FleetAppOrganizationService from 'services/fleet-app/fleet-app-organizations.service';
import { IFleetAppUsers } from 'services/fleet-app/fleet-app-service.types';

import { userValidationSchema as validationSchema } from '../userValidation';

import styles from './styles.module.sass';

export interface UserCreateFormProps {
  entry?: IFleetAppUsers;
  onSubmit: (entry: IFleetAppUsers) => Promise<IFleetAppUsers>;
}

export const UserCreateForm = (props: UserCreateFormProps) => {
  const { entry, onSubmit } = props;

  const formikProps: IFormikFormProps<IFleetAppUsers> = {
    onSubmit,
    initialValues: entry as IFleetAppUsers,
    validationSchema,
  };

  const orgSelectProps = {
    label: 'Organization',
    entityService: FleetAppOrganizationService,
    name: 'organization',
    current: entry?.organization,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="email" label="Email" required />
      <FormInput name="firstName" label="First Name" required />
      <FormInput name="lastName" label="Last Name" required />
      <FormInput name="password" label="Password" required />
      <FormInput name="fingerprint" label="FingerPrint" required />
      <FormEntitySelect {...orgSelectProps} required />
      <FormInput name="billing.iban" label="IBAN" />
      <FormInput name="billing.receiverName" label="Receiver Name" />
      <div className={styles.centered}>
        <h4>User Address</h4>
      </div>
      <FormInput name="address.country" label="Country" />
      <FormInput name="address.iso3166_1_alpha_3" label="ISO 3166-1 alpha-3 Code" />
      <FormInput name="address.region" label="Region" />
      <FormInput name="address.city" label="City" />
      <FormInput name="address.address" label="Address" />
      <FormInput name="address.postalCode" label="Postcode" />
      <FormInput name="address.latitude" label="Latitude" />
      <FormInput name="address.longitude" label="Longitude" />
      <FormSwitch name="generateCoordinates" label="Auto-generate coordinates" defaultChecked />
      <FormSubmit />
    </FormikForm>
  );
};
