import config from 'config';

import { ClientRequestEventType } from 'framework/constants';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IClientRequest } from 'store/tendegyService/tendergyService.types';

class ClientRequestAPI extends EntityService<IClientRequest> {
  transition = (type: ClientRequestEventType, id: string, rejectReason?: string) => {
    this.put(`${this.config.entityURL}/transition`, {
      type,
      clientRequest: {
        id,
        rejectReason,
      },
    });
  };
  getDisplayName(entry: IClientRequest): string {
    return entry.displayId as string;
  }
  unarchive = (id: string) => {
    return this.put(`${this.config.entityURL}/${id}/archive`, { archived: false });
  };
}
const TendergyRequestService = new ClientRequestAPI({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/client-requests',
  detailLink: links.request,
});

export default TendergyRequestService;
