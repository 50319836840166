import React from 'react';

import links from 'framework/links';

import { ICONS } from './icons';

export const MENU_ITEMS = [
  {
    type: 'link',
    menuItems: [{ link: links.home, icon: <ICONS.DashboardOutlined />, label: 'Dashboard' }],
  },
  {
    key: 'Authentication',
    title: (
      <>
        <ICONS.UserOutlined /> <span>Master data</span>
      </>
    ),
    menuItems: [
      {
        link: links.users,
        icon: <ICONS.UserOutlined />,
        label: 'Users',
        permission: 'manage_users',
      },
      {
        link: links.organizations,
        icon: <ICONS.AppstoreOutlined />,
        label: 'Organizations',
        permission: 'manage_organizations',
      },
      {
        link: links.authRoles,
        icon: <ICONS.ForkOutlined />,
        label: 'Roles',
        permission: 'edit_role_permissions',
      },
      {
        link: links.authApiClients,
        icon: <ICONS.ApiOutlined />,
        label: 'API Clients',
      },
    ],
  },
  {
    key: 'Tendergy',
    title: (
      <>
        <ICONS.FontSizeOutlined /> <span>Tendergy</span>
      </>
    ),
    menuItems: [
      {
        link: links.tendergyUsers,
        icon: <ICONS.UserOutlined />,
        label: 'Users',
        permission: 'manage_users',
      },
      {
        link: links.tendergyOrganizations,
        icon: <ICONS.AppstoreOutlined />,
        label: 'Organizations',
        permission: 'manage_organizations',
      },
      { link: links.requests, icon: <ICONS.FileSearchOutlined />, label: 'Requests' },
      {
        link: links.archiveRequests,
        icon: <ICONS.FileZipOutlined />,
        label: 'Archive Requests',
      },
      { link: links.offers, icon: <ICONS.FileDoneOutlined />, label: 'Offers' },
      {
        link: links.tendergyQuestionnaires,
        icon: <ICONS.QuestionCircleOutlined />,
        label: 'Questionnaires',
      },
      {
        link: links.countries,
        icon: <ICONS.CompassOutlined />,
        label: 'Countries',
      },
      {
        link: links.surveys,
        icon: <ICONS.PictureOutlined />,
        label: 'Surveys',
      },
      // {
      //   link: links.tendergyQuestionnaireSteps,
      //   icon: <ICONS.UnorderedListOutlined />,
      //   label: 'Questionnaire Steps',
      // },
      { link: links.invitationLinks, icon: <ICONS.UserAddOutlined />, label: 'Invitation Links' },
      { link: links.clientRequestPools, icon: <ICONS.GroupOutlined />, label: 'Pools' },
      { link: links.productModels, icon: <ICONS.ControlOutlined />, label: 'Product Models' },
      { link: links.productVendors, icon: <ICONS.TagsOutlined />, label: 'Vendors' },
      { link: links.intermediates, icon: <ICONS.ApartmentOutlined />, label: 'Intermediates' },
      { link: links.customDocuments, icon: <ICONS.CopyOutlined />, label: 'Custom Documents' },
      { link: links.comments, icon: <ICONS.MessageOutlined />, label: 'Comments' },
      {
        link: links.questionnaireConfigs,
        icon: <ICONS.SettingOutlined />,
        label: 'Questionnaire Configs',
      },
      { link: links.activityLogs, icon: <ICONS.SettingOutlined />, label: 'Activity Logs' },
      { link: links.leads, icon: <ICONS.FormOutlined />, label: 'Leads' },
      { link: links.feedback, icon: <ICONS.CommentOutlined />, label: 'Feedback' },
      { link: links.appointments, icon: <ICONS.CalendarOutlined />, label: 'Appointments' },
      {
        key: 'Reporting',
        title: (
          <>
            <ICONS.FontSizeOutlined /> <span>Reporting</span>
          </>
        ),
        menuItems: [
          { link: links.tendergyBilling, icon: <ICONS.DollarCircleOutlined />, label: 'Billing' },
          { link: links.tendergyHardware, icon: <ICONS.AppstoreOutlined />, label: 'Hardware' },
        ],
      },
    ],
  },
  {
    key: 'Billing',
    title: (
      <>
        <ICONS.DollarCircleOutlined /> <span>Billing</span>
      </>
    ),
    menuItems: [
      {
        link: links.billingCreateCarOwnerFlow,
        icon: <ICONS.CodeSandboxOutlined />,
        label: 'Create CarOwner Flow',
      },
      {
        link: links.billingChargingStations,
        icon: <ICONS.CodeSandboxOutlined />,
        label: 'Stations',
      },
      {
        link: links.billingOrganizations,
        icon: <ICONS.CodeSandboxOutlined />,
        label: 'Organizations',
      },
      { link: links.billingCarOwners, icon: <ICONS.CodeSandboxOutlined />, label: 'Car Owner' },
      { link: links.billingRFIDCards, icon: <ICONS.CodeSandboxOutlined />, label: 'RFID Cards' },
      { link: links.billingImports, icon: <ICONS.CodeSandboxOutlined />, label: 'Imports' },
      {
        link: links.billingEnergyPrices,
        icon: <ICONS.CodeSandboxOutlined />,
        label: 'Energy Prices',
      },
      { link: links.billingInvoices, icon: <ICONS.CodeSandboxOutlined />, label: 'Invoices' },
      { link: links.billingDocuments, icon: <ICONS.CodeSandboxOutlined />, label: 'Documents' },
    ],
  },
  {
    key: 'Billing v2',
    title: (
      <>
        <ICONS.DollarCircleOutlined /> <span>Billing v2</span>
      </>
    ),
    menuItems: [
      {
        link: links.fleetAppUsersList,
        icon: <ICONS.UserOutlined />,
        label: 'Users',
      },
      {
        link: links.billOrganizations,
        icon: <ICONS.AppstoreOutlined />,
        label: 'Organizations',
      },
      {
        link: links.billCarOwners,
        icon: <ICONS.UserOutlined />,
        label: 'Car Owners',
      },
      {
        link: links.billChargingStations,
        icon: <ICONS.ApiOutlined />,
        label: 'Charging Stations',
      },
      {
        link: links.billRfidCards,
        icon: <ICONS.IdcardOutlined />,
        label: 'RFID Cards',
      },
      {
        link: links.billImports,
        icon: <ICONS.UploadOutlined />,
        label: 'Imports',
      },
      {
        link: links.billInvoices,
        icon: <ICONS.FileTextOutlined />,
        label: 'Invoices',
      },
    ],
  },
  {
    key: 'Billing v3',
    title: (
      <>
        <ICONS.DollarCircleOutlined /> <span>Billing v3</span>
      </>
    ),
    menuItems: [
      {
        link: links.billV3ChargingStations,
        icon: <ICONS.ApiOutlined />,
        label: 'Charging Stations',
      },
      {
        link: links.billV3Invoices,
        icon: <ICONS.FileTextOutlined />,
        label: 'Invoices',
      },
      {
        link: links.billV3Organizations,
        icon: <ICONS.AppstoreOutlined />,
        label: 'Organizations',
      },
    ],
  },
  {
    key: 'email',
    title: (
      <>
        <ICONS.MailOutlined /> <span>Email</span>
      </>
    ),
    permission: 'manage_email_templates',
    menuItems: [
      { link: links.emailLayouts, icon: <ICONS.PicCenterOutlined />, label: 'Layouts' },
      { link: links.emailTemplates, icon: <ICONS.FormOutlined />, label: 'Templates' },
      { link: links.emailActivityLogs, icon: <ICONS.SettingOutlined />, label: 'Activity Logs' },
    ],
  },
  {
    key: 'eConsultant',
    title: (
      <>
        <ICONS.UserSwitchOutlined /> <span>eConsultant</span>
      </>
    ),
    menuItems: [
      {
        link: links.eConsultantLeads,
        icon: <ICONS.FormOutlined />,
        label: 'Leads',
      },
      {
        link: links.eCCountries,
        icon: <ICONS.PictureOutlined />,
        label: 'Countries',
      },
      {
        link: links.eConsultantFleetTypes,
        icon: <ICONS.CarOutlined />,
        label: 'Fleet Types',
      },
      {
        link: links.eConsultantCommercialFleetTypes,
        icon: <ICONS.ControlOutlined />,
        label: 'Commercial Fleet Type',
      },
      // TODO:Comment for now.
      // {
      //   link: links.consultantLocalLeadParameters,
      //   icon: <ICONS.ControlOutlined />,
      //   label: 'Local Lead Parameters',
      // },
    ],
  },
  {
    type: 'link',
    menuItems: [{ link: links.helpPage, icon: <ICONS.QuestionCircleOutlined />, label: 'Help' }],
  },
];
