import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';

export interface IFilters {
  query: null | string;
  supportMail: string | null;
  supportNumber: string | null;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const Filters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IFilters>({
    query: null,
    supportMail: null,
    supportNumber: null,
  });

  useEffect(() => {
    onFilter &&
      onFilter({
        ...value,
      });
  }, [value]); // eslint-disable-line

  const queryInputProps = {
    placeholder: 'Search ...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };
  const supportMailProps = {
    placeholder: 'Support Mail',
    onChange: (event: any) => setValue({ ...value, supportMail: event.target.value || null }),
    allowClear: true,
  };
  const supportNumberProps = {
    placeholder: 'Support Number',
    onChange: (event: any) => setValue({ ...value, supportNumber: event.target.value || null }),
    allowClear: true,
  };
  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col flex="1">
        <Input {...queryInputProps} />
      </Col>
      <Col flex="1">
        <Input {...supportMailProps} />
      </Col>
      <Col flex="1">
        <Input {...supportNumberProps} />
      </Col>
    </Row>
  );
};

export default Filters;
