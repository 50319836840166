import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISAFeedbacksService } from 'services/isa-service';
import { IISAFeedback, IParams } from 'services/isa-service/isa-service.types';

import ISAFeedbacksForm from './components/ISAFeedbacksForm';

export default function ISAFeedbacksDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISAFeedbacksForm entry={entry} onSubmit={onSave} />,
        },
      ]}
      entityName="feedback"
      baseLink={links.isaFeedbacksDetails}
      defaultPayload={{ id }}
      resource={ISAFeedbacksService}
      hideJsonTab
      renderTitle={(entry: IISAFeedback) => entry.description}
    />
  );
}
