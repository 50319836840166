export interface IParams {
  id: string;
}

interface IBaseEntity {
  id?: string;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly deleteDate?: Date;
}

export enum EnumISAPrefferedContact {
  PHONE = 'phone',
  EMAIL = 'email',
}

export enum EnumISAServiceTicketStatus {
  OPEN = 'open',
  PENDING = 'pending',
  CLOSED = 'closed',
}

export enum EnumISAConnectionType {
  SINGLE_PHASE = 'single phase',
  THREE_PHASE = 'three phase',
}

export enum EnumISAWallboxLocation {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

export interface IISAWallbox extends IBaseEntity {
  id: string;
  name: string;
  logicalName: string;
  description: string;
  imageUrl: string;
  sequenceNumber: number;
}

export interface IISACarBrand extends IBaseEntity {
  name: string;
  carModels: IISACarModel[];
}

export interface IISACarModel extends IBaseEntity {
  name: string;
  logicalName: string;
  carBrand: IISACarBrand;
}

export interface IISAProblemCategory extends IBaseEntity {
  title: string;
  problems: IISAProblem[];
}

export interface IISAProblem extends IBaseEntity {
  value: string;
  logicalName: string;
  problemCategory: IISAProblemCategory;
}

export interface IISASolution extends IBaseEntity {
  title: string;
  summary: string;
  logicalName: string;
  solutionSteps: IISASolutionStep[];
}

export interface IISASolutionStep extends IBaseEntity {
  title: string;
  description: string;
  sequenceNumber: number;
  solution: IISASolution;
}

export interface IISADiagnostic {
  title: string;
  logicalName: string;
  summary: string;
  diagnosticSteps: IISADiagnosticStep[];
}

export interface IISADiagnosticStep extends IBaseEntity {
  title: string;
  description: string;
  sequenceNumber: number;
  diagnostic: IISADiagnostic;
  questions: IISAQuestion[];
}

export interface IISAQuestion extends IBaseEntity {
  value: string;
  description: string;
  answerLabel: string;
  logicalName: string;
  answers: IISAAnswer[];
  diagnosticStep: IISADiagnosticStep;
}

export interface IISAAnswer extends IBaseEntity {
  value: string;
  logicalName: string;
}

export interface IISAMapping extends IBaseEntity {
  id: string;
  wallbox: IISAWallbox;
  problem: IISAProblem;
  solution: IISASolution;
  diagnostic: IISADiagnostic;
}

export interface IISAQuestionResponse {
  question: IISAQuestion;
  answer: IISAAnswer;
}

export interface IISAServiceTicket extends IBaseEntity {
  id: string;
  connectionType: EnumISAConnectionType;
  issueDescription: string;
  maintenanceReportUrl: string;
  questionResponse: IISAQuestionResponse[];
  serialNumber: string;
  status: EnumISAServiceTicketStatus;
  ticketNumber: string;
  typeReference: string;
  wallboxLocation: EnumISAWallboxLocation;
  carModel: IISACarModel;
  wallbox: IISAWallbox;
  problem: IISAProblem;
  user: IISAUser;
  diagnostic: IISADiagnostic;
}

export interface IISAFeedback extends IBaseEntity {
  rating: number;
  description: string;
  id: string;
}

export interface IISAUser extends IBaseEntity {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  preferredContact: EnumISAPrefferedContact;
  address: IISAAddress;
}

export interface IISAAddress extends IBaseEntity {
  street: string;
  doorNumber: string;
  city: string;
  postalCode: string;
}
