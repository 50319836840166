import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IAuthUser } from 'store/authService/authService.types';

import config from '../../config';

class UserAuthClass extends EntityService<IAuthUser> {
  getDisplayName(entry: IAuthUser): string {
    return entry && `${entry.firstName || ''} ${entry.lastName || ''}`;
  }
  getUserPermissions = () => this.get(`users/profile/acl`);
  sendVerifyEmail = (id: string) =>
    this.post(`${this.config.entityURL}/${id}/email-verify-resend`, { context: 'tendergy' });
}

const UserAuthService = new UserAuthClass({
  apiURL: config.USER_SERVICE_URL,
  entityURL: 'admin/users',
  name: 'User',
  detailLink: links.user,
});

export default UserAuthService;
