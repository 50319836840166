import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import LandingsService from 'services/shop/shop-landings.service';
import { ICommonParams, IShopLanding } from 'services/shop/shop-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import LandingForm from '../components/LandingForm';

const LandingDetailsPage: React.FC = () => {
  const { id } = useParams<ICommonParams>() as any;

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['store'],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IShopLanding, optionalProps?: ICrudDetailViewCallbacks) => (
        <LandingForm
          entry={entity}
          onSubmitStep={optionalProps?.onSave}
          wrapperCol={{ span: 13 }}
          labelCol={{ span: 10 }}
        />
      ),
    },
  ];

  return (
    <CrudDetailContainer
      entityName="Landing"
      tabs={tabs}
      baseLink={links.shopLanding}
      defaultPayload={params}
      resource={LandingsService}
    />
  );
};

export default LandingDetailsPage;
