import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';
import { ITendergyClientRequestPool } from 'store/tendegyService/tendergyService.types';

import PoolActionsComponent from './components/PoolActionsComponent/PoolActionsComponent';
import ClientRequestPoolCustomDocumentsTab from './tabs/ClientRequestPoolCustomDocumentsTab';
import ClientRequestPoolDetailsTab from './tabs/ClientRequestPoolDetailsTab';
import ClientRequestPoolDocumentsTab from './tabs/ClientRequestPoolDocumentsTab';
import ClientRequestPoolQuestionnaireConfigsTab from './tabs/ClientRequestPoolQuestionnaireConfigsTab';
import ClientRequestPoolUsersTab from './tabs/ClientRequestPoolUsersTab';
import ClientRequestsTab from './tabs/ClientRequestsTab';
import PoolTenderOrganizationsTab from './tabs/PoolTenderOrganizationsTab';

const ClientRequestPoolsDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
    relations: [
      'poolTenderOrganizations',
      'poolTenderOrganizations.organization',
      'inviteTokens',
      'questionnaire',
      'surveyQuestionnaire',
      'intermediateOrganization',
    ],
  };

  const tabs = [
    {
      name: 'details',
      displayName: 'Details',
      render: (entity: ITendergyClientRequestPool, optionalProps?: ICrudDetailViewCallbacks) => (
        <ClientRequestPoolDetailsTab clientRequestPool={entity} onSubmit={optionalProps?.onSave} />
      ),
      triggerReload: true,
    },
    {
      name: 'users',
      displayName: 'Users',
      render: (entity: ITendergyClientRequestPool) => <ClientRequestPoolUsersTab entry={entity} />,
    },
    {
      name: 'client-requests',
      displayName: 'Client Requests',
      render: (entity: ITendergyClientRequestPool) => <ClientRequestsTab entry={entity} />,
    },
    {
      name: 'pool-tender-organizations',
      displayName: 'Pool Organizations',
      render: (entity: ITendergyClientRequestPool) => <PoolTenderOrganizationsTab entry={entity} />,
    },
    {
      name: 'documents',
      displayName: 'Documents',
      render: (entity: ITendergyClientRequestPool, optionalProps?: ICrudDetailViewCallbacks) => (
        <ClientRequestPoolDocumentsTab
          documents={optionalProps?.documents}
          onOpen={optionalProps?.getFileLink}
          deleteFile={optionalProps?.deleteFile}
          uploadFile={optionalProps?.uploadFile}
        />
      ),
    },
    {
      name: 'customDocuments',
      displayName: 'Custom Documents',
      render: (entity: ITendergyClientRequestPool) => (
        <ClientRequestPoolCustomDocumentsTab entry={entity} />
      ),
    },
    {
      name: 'questionnaireConfigs',
      displayName: 'Questionnaire Configs',
      render: (entity: ITendergyClientRequestPool) => (
        <ClientRequestPoolQuestionnaireConfigsTab entry={entity} />
      ),
    },
  ];

  const renderActions = (entry: ITendergyClientRequestPool) => (
    <PoolActionsComponent pool={entry} />
  );

  return (
    <CrudDetailContainer
      entityName="Client Request Pool"
      tabs={tabs}
      baseLink={links.clientRequestPool}
      defaultPayload={params}
      resource={ClientRequestPoolService}
      renderActions={renderActions}
      shouldGetFiles
    />
  );
};
export default ClientRequestPoolsDetailsPage;
