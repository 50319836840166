import React from 'react';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IFilterValue } from 'framework/utils/crud-columns-transform';
import { ISAServiceTicketsService } from 'services/isa-service';
import { EnumISAServiceTicketStatus, IISAUser } from 'services/isa-service/isa-service.types';
import { formatDefaultDate } from 'utils/date.helper';

const STATUS_OPTIONS = Object.values(EnumISAServiceTicketStatus);

export default function ISAServiceTicketsOverview() {
  return (
    <CrudListViewContainer
      columns={[
        { title: 'Service Ticket Number', dataIndex: 'ticketNumber' },
        { title: 'User', dataIndex: 'user', render: (user: IISAUser) => user?.email },
        {
          title: 'Status',
          dataIndex: 'status',
          filters: STATUS_OPTIONS.reduce((accumulator, option) => {
            accumulator.push({ text: option, value: option });
            return accumulator;
          }, [] as IFilterValue[]),
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          render: (date: string) => formatDefaultDate(date),
        },
      ]}
      defaultPayload={{ relations: ['user'], sortFields: 'ticketNumber', sortDirections: -1 }}
      entityName="service tickets"
      resource={ISAServiceTicketsService}
      withSorting
    />
  );
}
