/* Service App (ISA) */

export const ISA_WALLBOXES_ENDPOINT = 'admin/wallboxes';
export const ISA_CAR_BRANDS_ENDPOINT = 'admin/car-brands';
export const ISA_CAR_MODELS_ENDPOINT = 'admin/car-models';
export const ISA_PROBLEM_CATEGORIES_ENDPOINT = 'admin/problem-categories';
export const ISA_PROBLEMS_ENDPOINT = 'admin/problems';
export const ISA_SOLUTIONS_ENDPOINT = 'admin/solutions';
export const ISA_SOLUTION_STEPS_ENDPOINT = 'admin/solution-steps';
export const ISA_DIAGNOSTICS_ENDPOINT = 'admin/diagnostics';
export const ISA_DIAGNOSTIC_STEPS_ENDPOINT = 'admin/diagnostic-steps';
export const ISA_QUESTIONS_ENDPOINT = 'admin/questions';
export const ISA_ANSWERS_ENDPOINT = 'admin/answers';
export const ISA_MAPPINGS_ENDPOINT = 'admin/wallbox-problem-solution-diagnostics';
export const ISA_SERVICE_TICKETS_ENDPOINT = 'admin/service-tickets';
export const ISA_FEEDBACKS_ENDPOINT = 'admin/feedbacks';
export const ISA_USERS_ENDPOINT = 'admin/users';
export const ISA_ADDRESSES_ENDPOINT = 'admin/addresses';
