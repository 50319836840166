import React from 'react';

import CrudCreateWizardContainer from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import { ISACarModelsService } from 'services/isa-service';

import ISACarModelsForm from './components/ISACarModelsForm';

export default function ISACarModelsCreate() {
  return (
    <CrudCreateWizardContainer
      steps={[
        {
          name: 'Add Car Model',
          render: (_entry, onSubmit) => <ISACarModelsForm onSubmit={onSubmit} />,
        },
      ]}
      entityName="car model"
      baseLink={links.isaCarModelsOverview}
      resource={ISACarModelsService}
    />
  );
}
