import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillCarOwnersService from 'services/billing-v3/billing-v3-car-owners.service';
import {
  IBillingCarOwner,
  IBillingOrganization,
} from 'services/billing-v3/billing-v3-service.types';

const columns: ColumnsType<IBillingCarOwner> = [
  {
    title: 'Full name',
    dataIndex: 'firstname',
    render: (_, entry) => `${entry?.firstname} ${entry?.lastname}`,
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Employee number',
    dataIndex: 'employeeNumber',
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    render: (organization: IBillingOrganization) => organization.name,
  },
];

const BillCarOwnersOverviewPage: React.FC = () => (
  <CrudListViewContainer entityName="Car owner" columns={columns} resource={BillCarOwnersService} />
);
export default BillCarOwnersOverviewPage;
