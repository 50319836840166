import * as React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import { IAuthUser } from 'store/authService/authService.types';
import {
  IPoolTenderOrganization,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';

import { IntermediateValidation } from '../../intermediate.validation';

interface IChooseOrganizationStep {
  entry: IPoolTenderOrganization;
  upgradeOrganization: (entry: ITendergyOrganization) => void;
  manager?: IAuthUser;
  updateUser?: () => void;
}

const IntermediateBaseStep: React.FC<IChooseOrganizationStep> = ({
  entry,
  upgradeOrganization,
  manager,
  updateUser,
}: IChooseOrganizationStep) => {
  const validation = Yup.object().shape(IntermediateValidation);

  return (
    <div>
      <Formik
        initialValues={entry as any}
        validationSchema={validation}
        onSubmit={() => {
          updateUser && updateUser();
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, errors }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item required name="organization" label="Organization">
              <EntitySelector
                current={entry.organization}
                onSelect={(organization) => setFieldValue('organization', organization)}
                entityService={TendergyOrganizationService}
                name="organization"
              />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="button">
              <Button
                type="primary"
                shape="round"
                icon={<ArrowRightOutlined />}
                onClick={() => upgradeOrganization(values)}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                Update organization
              </Button>
            </Form.Item>

            {manager && (
              <>
                <Form.Item name="user" label="Organization manager" required>
                  <div>{`${manager.firstName} ${manager.lastName}`}</div>
                  <div>{manager.email}</div>
                </Form.Item>

                <Form.Item {...CrudFormDefaultActionParams} name="submit">
                  <Form.Item name="submit">
                    <Button
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      icon={<ArrowRightOutlined />}
                      disabled={false}
                    >
                      Set as intermediate manager
                    </Button>
                  </Form.Item>
                </Form.Item>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IntermediateBaseStep;
