import * as Yup from 'yup';

// export interface IShopProductAttributes extends IBaseEntity {
//   title: string;
//   subtitle: string;
//   order?: number;
//   required?: boolean;
//   product?: IShopProduct;
//   productOptions?: IShopProductOption;
// }

export const ProductAttributesValidation = {
  title: Yup.string().required('Required').min(2, 'Too short!').max(2000, 'Too long!'),
  subtitle: Yup.string().nullable().min(2, 'Too short!').max(3000, 'Too long!'),
  index: Yup.number().required('Required!').positive('Index cannot be less than 1'),
  required: Yup.boolean(),
  product: Yup.object({
    id: Yup.string().required(),
  }).required('Required!'),
  orderTitle: Yup.string().nullable().min(2, 'Too short!').max(3000, 'Too long!'),
  descriptionTitle: Yup.string().nullable().min(2, 'Too short!').max(3000, 'Too long!'),
};
