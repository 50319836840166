import React from 'react';

import { FormEntitySelect, FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormEntitySelectProps } from 'components/forms/FormEntitySelect';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { ISACarBrandsService } from 'services/isa-service';
import { IISACarModel } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISACarModel;
  onSubmit: (entry: IISACarModel) => Promise<IISACarModel>;
}

export default function ISACarModelsForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISACarModel> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISACarModel,
  };

  const carBrandsProps: IFormEntitySelectProps = {
    label: 'Car Brand',
    name: 'carBrand',
    entityService: ISACarBrandsService,
    current: entry.carBrand,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="name" label="Car Model Title" required />
      <FormInput name="logicalName" label="Logical Name" required />
      <FormEntitySelect {...carBrandsProps} />
      <FormSubmit />
    </FormikForm>
  );
}
