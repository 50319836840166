import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import BillingEnergyPricingService from 'services/billing/billing-energy-prices.service';
import { IBillingEnergyPrice } from 'services/billing/billing-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import EnergyPricesActionsComponent from './components/EnergyPricesActionsComponent/EnergyPricesActionsComponent';
import EnergyPriceDetailsTab from './tabs/EnergyPriceDetailsTab';

const BillingEnergyPricePage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: any, optionalProps?: ICrudDetailViewCallbacks) => (
        <EnergyPriceDetailsTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  const renderActions = (entry: IBillingEnergyPrice, triggerReload: Function) => (
    <EnergyPricesActionsComponent entry={entry} triggerReload={triggerReload} />
  );

  return (
    <CrudDetailContainer
      entityName="Energy Price"
      tabs={tabs}
      renderActions={renderActions}
      baseLink={links.billingEnergyPrice}
      defaultPayload={params}
      resource={BillingEnergyPricingService}
    />
  );
};

export default BillingEnergyPricePage;
