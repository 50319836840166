import produce from 'immer';

import {
  OrganizationsActionTypes,
  OrganizationsState,
  RESET,
  SET,
  SET_CRAFT_ENTRIES,
  SET_LOADING_STATUS,
} from './organizations.types';

const initialState: OrganizationsState = {
  entries: [],
  paginationCount: 0,
  loading: false,
  craftEntries: [],
};

const OrganizationsReducer = (state = initialState, action: OrganizationsActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET:
        draft.entries = action.payload.items;
        draft.paginationCount = action.payload.count;
        break;
      case SET_CRAFT_ENTRIES:
        draft.craftEntries = action.payload;
        break;
      case SET_LOADING_STATUS:
        draft.loading = action.payload;
        break;
      case RESET:
        draft.entries = initialState.entries;
        draft.loading = initialState.loading;
        break;
    }
  });

export default OrganizationsReducer;
