import { IPagination } from 'framework/models';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

import { IOffer } from '../tendergyService.types';
import { GET, OffersActionTypes, RESET, SET, SET_LOADING_STATUS } from './offers.types';

export function get(payload: IGetMultiEntityPayload): OffersActionTypes {
  return {
    type: GET,
    payload,
  };
}

export function set(payload: IPagination<IOffer>): OffersActionTypes {
  return {
    type: SET,
    payload,
  };
}

export function setLoadingStatus(payload: boolean): OffersActionTypes {
  return {
    type: SET_LOADING_STATUS,
    payload,
  };
}

export function reset(): OffersActionTypes {
  return {
    type: RESET,
  };
}
