import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Select } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import ClientRequestPoolService from 'services/tendergy/client-request-pool.service';
import {
  ITendergyClientRequestPool,
  OrganizationState,
  OrganizationTypes,
} from 'store/tendegyService/tendergyService.types';

export interface IOrganizationsFilters {
  query: null | string;
  pools: null | string;
  state: null | string;
  type: null | string;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const OrganizationsFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IOrganizationsFilters>({
    query: null,
    state: null,
    pools: null,
    type: null,
  });

  useEffect(() => {
    onFilter && onFilter(value);
  }, [value]); // eslint-disable-line

  const queryInputProps = {
    placeholder: 'Search by name...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };

  const poolEntitySelectorProps = {
    placeholder: 'Select Client Request Pool',
    name: 'installerOrganization',
    allowClear: true,
    value: value.pools,
    style: { width: '100%' },
    entityService: ClientRequestPoolService,
    onSelect: (pool: ITendergyClientRequestPool) => {
      setValue({ ...value, pools: pool?.id || null });
    },
  };

  const stateSelectProps = {
    placeholder: 'Select State',
    onChange: (state: OrganizationState) => {
      setValue({ ...value, state });
    },
    style: { width: '100%' },
    allowClear: true,
    options: Object.values(OrganizationState).map((value: string) => ({ value, label: value })),
  };

  const typeSelectProps = {
    placeholder: 'Select Types',
    onChange: (type: OrganizationTypes) => {
      setValue({ ...value, type });
    },
    style: { width: '100%' },
    allowClear: true,
    options: Object.values(OrganizationTypes).map((value: string) => ({ value, label: value })),
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col span={6}>
        <Input {...queryInputProps} />
      </Col>
      <Col span={6}>
        <EntitySelector {...poolEntitySelectorProps} />
      </Col>
      <Col span={6}>
        <Select {...stateSelectProps} />
      </Col>
      <Col span={6}>
        <Select {...typeSelectProps} />
      </Col>
    </Row>
  );
};

export default OrganizationsFilters;
