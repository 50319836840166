import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IBillingChargingStation } from './billing-service.types';

class ChargingStationServiceClass extends EntityService<IBillingChargingStation> {
  getDisplayName(entry: IBillingChargingStation): string {
    return entry.evse;
  }
}

const ChargingStationService = new ChargingStationServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/charging-stations',
  detailLink: links.billingChargingStation,
});

// this way I can autowire the result (with command + .);
export default ChargingStationService;
