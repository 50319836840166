import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, InputNumber, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { ITendergyCountry } from 'store/tendegyService/tendergyService.types';

import { CountryValidation } from '../../country.validation';

interface Props {
  entry: ITendergyCountry;
  onSubmitStep: (entry: ITendergyCountry) => void;
}

const CountryBaseStep: React.FC<Props> = ({ entry, onSubmitStep }: Props) => {
  const validation = Yup.object().shape(CountryValidation);

  return (
    <div>
      <Formik
        initialValues={entry as ITendergyCountry}
        validationSchema={validation}
        onSubmit={(values) => onSubmitStep({ ...values, id: null } as any)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="iso31661Alpha3" label="ISO 31661 Alpha 3">
              <Input name="iso31661Alpha3" placeholder="" />
            </Form.Item>
            <Form.Item name="vatRate" label="Vat Rate">
              <InputNumber
                name="vatRate"
                type="number"
                placeholder="0"
                min={0}
                max={100}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name="currency" label="Currency">
              <Input name="currency" placeholder="" />
            </Form.Item>
            <Form.Item name="defaultTimezone" label="Default Timezone">
              <Input name="defaultTimezone" placeholder="" />
            </Form.Item>
            <Form.Item name="isDefault" label="Is Default">
              <Switch name="isDefault" />
            </Form.Item>

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CountryBaseStep;
