import React, { useEffect, useState } from 'react';
import { ExportOutlined } from '@ant-design/icons/lib/icons';
import { Button, Col, Input, Row, Select } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { UserRole } from 'framework/constants';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import TendergyUserService from 'services/tendergy/tendergy-user.service';
import { ITendergyOrganization } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

export interface IUsersFilters {
  query: null | string;
  organization: null | ITendergyOrganization;
  roles: null | UserRole;
}

interface IProps {
  onFilter?: (value: any) => void;
}

const UsersFilters = (props: IProps) => {
  const { onFilter } = props;

  const [value, setValue] = useState<IUsersFilters>({
    query: null,
    organization: null,
    roles: null,
  });

  useEffect(() => {
    onFilter &&
      onFilter({
        ...value,
        organization: undefined,
        organizationName: value.organization?.name || null,
      });
  }, [value]); // eslint-disable-line

  const queryInputProps = {
    placeholder: 'Search by name...',
    onChange: (event: any) => setValue({ ...value, query: event.target.value || null }),
    allowClear: true,
  };

  const organizationEntitySelectorProps = {
    placeholder: 'Select Organization',
    name: 'installerOrganization',
    allowClear: true,
    value: value.organization?.id,
    style: { width: '100%' },
    entityService: TendergyOrganizationService,
    onSelect: (organization: ITendergyOrganization) => {
      setValue({ ...value, organization: organization || null });
    },
  };

  const userRoleProps = {
    placeholder: 'Select Role',
    onChange: (roles: UserRole) => {
      setValue({ ...value, roles });
    },
    style: { width: '100%' },
    allowClear: true,
    options: Object.values(UserRole).map((value: string) => ({ value, label: value })),
  };

  const onExportClick = async () => {
    try {
      await TendergyUserService.exportUsers();
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col flex="1">
        <Input {...queryInputProps} />
      </Col>
      <Col flex="1">
        <EntitySelector {...organizationEntitySelectorProps} />
      </Col>
      <Col flex="1">
        <Select {...userRoleProps} />
      </Col>
      <Col>
        <Button icon={<ExportOutlined />} onClick={onExportClick}>
          Export to CSV
        </Button>
      </Col>
    </Row>
  );
};

export default UsersFilters;
