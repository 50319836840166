import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons/lib';
import { Button, Col, ConfigProvider, Empty, Row, Table } from 'antd';

import EntitySelector from 'components/forms/organization/EntitySelector';
import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IShopStore, IShopStoreEmail } from 'services/shop/shop-service.types';
import StoreEmailsService from 'services/shop/shop-store-emails.service';
import StoreService from 'services/shop/shop-stores.service';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Key',
    dataIndex: 'key',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    render: (date: string) => (date ? formatDefaultDate(date) : '–'),
  },
];

const StoreEmailsOverviewPage: React.FC = () => {
  const history = useHistory();
  const [store, setStore] = useState<IShopStore | null>(null);
  const [emails, setEmails] = useState<IShopStoreEmail[] | null>(null);

  const params: any = {
    storeId: store && store.id,
  };

  const tableProps = {
    rowKey: 'id',
    columns: columns,
    dataSource: emails,
    onRow: (record: IShopStoreEmail) => ({
      onClick: () => history.push(createLink(links.shopStoreEmail, { id: record.id })),
    }),
  };

  const onCreateButtonClick = () => {
    history.push(links.shopCreateStoreEmail);
  };

  const getEmails = async () => {
    try {
      const response = await StoreEmailsService.getEntries({ ...params });
      setEmails(response?.items);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  useEffect(() => {
    if (store) getEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={19}>
          <EntitySelector
            entityService={StoreService}
            name="Select Store"
            onSelect={(selectedStore) => setStore(selectedStore)}
          />
        </Col>
        <Col span={3}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={onCreateButtonClick}
          >
            Add new store email
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ConfigProvider
            renderEmpty={() => <Empty description={!store ? 'Please select store' : 'No data'} />}
          >
            {/* @ts-ignore*/}
            <Table {...tableProps} />
          </ConfigProvider>
        </Col>
      </Row>
    </>
  );
};

export default StoreEmailsOverviewPage;
