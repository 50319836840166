import React, { useState } from 'react';

import { FormikForm } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { IBillingChargingStation } from 'services/billing-v2/billing-v2-service.types';

import { validationSchema } from '../../validation';
import ChargingStationForm from './Form';

interface ChargingStationFormProps {
  entry: IBillingChargingStation;
  onSubmit: (entry: IBillingChargingStation) => Promise<IBillingChargingStation>;
}

const ChargingStationFormWrapper = (props: ChargingStationFormProps) => {
  const { entry, onSubmit } = props;
  const [initialValues, setInitialValues] = useState(entry || {});

  const formikProps: IFormikFormProps<IBillingChargingStation> = {
    onSubmit,
    validationSchema,
    initialValues: initialValues as IBillingChargingStation,
    enableReinitialize: true,
  };

  return (
    <FormikForm {...formikProps}>
      <ChargingStationForm
        initialValues={initialValues}
        entry={entry}
        setInitialValues={setInitialValues}
      />
    </FormikForm>
  );
};

export default ChargingStationFormWrapper;
