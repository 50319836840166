import * as React from 'react';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillInvoicesService from 'services/billing-v3/billing-v3-invoices.service';
import {
  BillOrganizationPayload,
  IBillingInvoice,
  IBillingOrganization,
} from 'services/billing-v3/billing-v3-service.types';

interface Props {
  entry?: IBillingOrganization;
}

const columns = [
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Period',
    dataIndex: 'month',
    render: (row: Date, entry: IBillingInvoice) =>
      `${moment(entry.month).format('MMMM')} ${entry.year}`,
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
];

const InvoicesTab: React.FC<Props> = ({ entry }: Props) => {
  const payload: BillOrganizationPayload = {
    organizationId: entry?.id,
  };

  return (
    <CrudListViewContainer
      entityName="Invoices"
      columns={columns}
      additionalPayload={payload}
      resource={BillInvoicesService}
      disableSearch
    />
  );
};

export default InvoicesTab;
