import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Checkbox, Form, SubmitButton } from 'formik-antd';

import { Loader } from 'components/ui';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import {
  ITendergyOrganization,
  ITendergyOrgDefaultSettings,
} from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

interface Props {
  organization?: ITendergyOrganization | null;
}

export const DefaultSettings: React.FC<Props> = (props: Props) => {
  const { organization } = props;
  const [defaultSettings, setDefaultSettings] = useState({});

  useEffect(() => {
    if (organization?.id) {
      getDefaultSettings(organization.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const getDefaultSettings = async (id: string) => {
    try {
      const response = await TendergyOrganizationService.getDefaultSettings(id);
      setDefaultSettings(response);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const saveDefaultSettings = async (values: ITendergyOrgDefaultSettings) => {
    try {
      if (organization?.id) {
        await TendergyOrganizationService.saveDefaultSettings(organization?.id, {
          ...values,
        });
      }
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <div>
      {!Object.keys(defaultSettings).length ? (
        <Loader />
      ) : (
        <Formik initialValues={defaultSettings} onSubmit={(data) => saveDefaultSettings(data)}>
          {({ handleSubmit, isSubmitting }) => (
            <Form onFinish={handleSubmit}>
              <Form.Item name="appointmentOffTendergy" valuePropName="checked">
                <Checkbox name="appointmentOffTendergy">
                  Appointment coordination always off tendergy
                </Checkbox>
              </Form.Item>
              <Form.Item name="submit">
                <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                  Submit
                </SubmitButton>
              </Form.Item>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default DefaultSettings;
