import * as React from 'react';
import { useEffect } from 'react';
import { Button, Col, message, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import TendergyRequestService from 'services/tendergy/tendergy-request.service';
import { IGetFileLinkPayload } from 'store/storeInterfaces';
import { CommentStates, ITendergyComment } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

import { CommentValidation } from '../../comments.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const CommentOverviewTab: React.FC<IGenericEditTabProps<ITendergyComment>> = (
  props: IGenericEditTabProps<ITendergyComment>,
) => {
  const { entry, onSave } = props;
  const [comment, setComment] = React.useState(entry);

  useEffect(() => {
    setComment(comment);
  }, [entry]); // eslint-disable-line

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(CommentValidation);

  const onFileClick = async (fileName: string) => {
    const payload: IGetFileLinkPayload = {
      fileName,
    };
    try {
      const response = await TendergyRequestService.getFileLink(payload);
      window.open(response.link, '_blank');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  const onFileDeleteClick = async (fileName: string) => {
    const payload: IGetFileLinkPayload = {
      fileName,
    };
    try {
      await TendergyRequestService.deleteFile(payload);
      setComment({
        ...comment,
        attachments: comment.attachments.filter((i) => i.name !== fileName),
      });
      message.info('Attachment successfully deleted');
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <div>
      <Formik
        initialValues={comment as ITendergyComment}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="text" label="Text">
              <Input name="text" placeholder="" />
            </Form.Item>
            <Form.Item name="state" label="State">
              <Select name="state">
                <Select.Option value={CommentStates.Pending}>Pending</Select.Option>
                <Select.Option value={CommentStates.Resolved}>Resolved</Select.Option>
              </Select>
            </Form.Item>
            {entry?.author?.firstName && entry?.author?.lastName ? (
              <Form.Item name="author" label="Author">
                {`${entry?.author?.firstName} ${entry?.author?.lastName}`}
              </Form.Item>
            ) : null}

            {entry?.author?.email ? (
              <Form.Item name="author" label="Author Email">
                {entry.author.email}
              </Form.Item>
            ) : null}

            {entry?.commentableType ? (
              <Form.Item name="commentableType" label="Commentable Type">
                {entry?.commentableType}
              </Form.Item>
            ) : null}

            {entry?.commentableId ? (
              <Form.Item name="commentableId" label="Commentable ID">
                {entry?.commentableId}
              </Form.Item>
            ) : null}

            {entry?.parentComment?.text ? (
              <Form.Item name="parentComment" label="Parent Comment">
                {entry?.parentComment?.text}
              </Form.Item>
            ) : null}

            {entry?.createdAt ? (
              <Form.Item name="createdAt" label="Created At">
                {formatDefaultDate(entry?.createdAt)}
              </Form.Item>
            ) : null}

            {!!comment?.attachments?.length && (
              <Form.Item name="id" label="Attachments">
                {comment?.attachments?.map((item) => (
                  <Row key={item.id} gutter={[8, 8]}>
                    <Col>
                      <Button onClick={() => onFileClick(item.name)}>{item.originalName}</Button>
                    </Col>
                    <Col>
                      <Button danger onClick={() => onFileDeleteClick(item.name)}>
                        Delete
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Form.Item>
            )}

            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CommentOverviewTab;
