import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

class BillChargingStationsServiceClass extends EntityService<any> {}

const BillChargingStationsService = new BillChargingStationsServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/charging-stations',
  detailLink: links.billChargingStation,
  createLink: links.billCreateChargingStation,
});

export default BillChargingStationsService;
