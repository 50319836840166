import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { ITendergyComment } from 'store/tendegyService/tendergyService.types';

import styles from './CommentsActionsComponent.module.sass';

interface IProps {
  entry: ITendergyComment;
  triggerReload: Function;
}

const CommentsActionsComponent: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();

  const { entry, triggerReload } = props;

  return (
    <>
      <div className={styles.containerInner}>
        <Button
          className={styles.button}
          type="primary"
          size="large"
          shape="round"
          onClick={() =>
            history.push(
              createLink(links.user, {
                id: entry.authorId,
              }),
            )
          }
        >
          Go to the author
        </Button>
      </div>
      {entry.parentCommentId && (
        <div className={styles.containerInner}>
          <Button
            className={styles.button}
            type="primary"
            size="large"
            shape="round"
            onClick={() => {
              history.push(
                createLink(links.comment, {
                  id: entry.parentCommentId,
                }),
              );
              triggerReload();
            }}
          >
            Open parent comment
          </Button>
        </div>
      )}
    </>
  );
};

export default CommentsActionsComponent;
