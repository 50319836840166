import config from 'config';

import EntityService from 'services/abstract/entity.service';

import { IEmailActivityLog } from './email-service.types';

class EmailActivityLogsServiceClass extends EntityService<IEmailActivityLog> {}

const EmailActivityLogsServiceService = new EmailActivityLogsServiceClass({
  apiURL: config.EMAIL_SERVICE_URL,
  entityURL: 'admin/email-logs',
});

export default EmailActivityLogsServiceService;
