import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm, Row, Typography } from 'antd';

import Tabs from 'components/layout/Tabs';
import { Loader } from 'components/ui';
import EntityService from 'services/abstract/entity.service';
import {
  IGetFileLinkPayload,
  IGetSingleEntityPayload,
  IUploadFilePayload,
} from 'store/storeInterfaces';
import { MediaCategoryType } from 'store/tendegyService/tendergyService.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import DetailviewJsonTab from './components/detailview-json-tab';
import { ICrudDetailViewCallbacks } from './interfaces/abstractCrud.intefaces';

import styles from './abstract-crud.module.sass';

export interface ICrudDetailViewProps {
  entityName: string;
  resource: EntityService<any>;
  renderTitle?: (entry: any) => ReactElement | string;
  renderActions?: (entry: any, triggerReload: Function) => ReactElement | string;
  defaultPayload: IGetSingleEntityPayload;
  baseLink?: string; // @depracted, this information is now available in entity service
  hideJsonTab?: boolean;
  shouldGetFiles?: boolean;
  tabs: ICrudTab[];
  lastOpenedPage?: string;
  entityURL?: string;
  legacy?: boolean;
  shouldHideDelete?: boolean;
}

type TCrudDetailViewProps = ICrudDetailViewProps & RouteComponentProps;

interface ICrudDetailState {
  entry: any; // Record
  currentTab?: number;
  payload: IGetSingleEntityPayload;
  loading: boolean;
  documents: any;
}

export interface ICrudTab {
  name: string;
  displayName: string;
  render: (entry: any, optionalProps: ICrudDetailViewCallbacks) => ReactElement;
  triggerReload?: boolean;
}

export interface Entry {
  id: string;
  name?: string;
  displayId?: string;
  title?: string;
  value?: string;
  clientName?: string;
}

/** **
 * @docs:
 *  This container is made to wrap a single entity a view container.
 *  The subpages (tabs) will be provided by tabs property
 * <CrudDetailViewContainer tabs>
 * </CrudDetailViewContainer>
 *
 * Plase sie InvitationLinkDetailsPage.tsx for example!
 *
 * TODO:
 *  - Handle PUT, POST of data.
 *  - provide parameter to add "actions" (e.g. transitons etc.)
 *  - Integrate state (is this needed?)
 *
 */
class CrudDetailViewContainer extends React.Component<TCrudDetailViewProps, ICrudDetailState> {
  constructor(props: TCrudDetailViewProps) {
    super(props);
    this.state = {
      loading: true,
      entry: null,
      payload: props.defaultPayload,
      documents: null,
    };
    this.ensureParameters();
  }

  componentDidMount() {
    const { shouldGetFiles } = this.props;
    this.refreshEntity();
    if (shouldGetFiles) this.getDocuments();
  }

  async onSaveCallback(updatedValues: Entry, triggerReload?: boolean, onSuccess?: () => void) {
    // @todo: do I need to update local state?
    const { entry } = this.state;
    const { resource } = this.props;
    const newEntry = { ...entry, ...updatedValues };
    this.setState({ entry: newEntry });
    try {
      const resp = await resource.save(newEntry);
      if (resp?.severity === 'ERROR') {
        return showErrorNotification('Something went wrong, please try again later.');
      }
      triggerReload && this.refreshEntity();
      onSuccess && onSuccess();
      showSuccessNotification('successfully updated');
    } catch (e) {
      showErrorNotification(e);
      console.error(e);
      throw e;
    }
  }

  async onDeleteCallback() {
    const { payload } = this.state;
    const { resource, history } = this.props;
    try {
      const res = await resource.deleteById(payload);
      if (res !== undefined) {
        showSuccessNotification('Entry successfully removed');
        // @ts-ignore
        history.goBack();
      } else {
        showErrorNotification('Entry could not be removed, please try again later.');
      }
    } catch (e) {
      showErrorNotification(e);
      console.error(e);
    }
  }

  async getDocuments() {
    const { payload } = this.state;
    const { resource } = this.props;
    this.setState({ loading: true });
    let response;
    try {
      response = await resource.getFiles(payload);
    } catch (e) {
      showErrorNotification(e);
      this.setState({ loading: false });
      return;
    }
    this.setState({ documents: response, loading: false });
  }

  async refreshEntity() {
    const { payload } = this.state;
    const { resource } = this.props;

    this.setState({ loading: true });
    let response;
    try {
      response = await resource.getById(payload);
    } catch (e) {
      showErrorNotification(e);
      this.setState({ loading: false });
      return;
    }
    if (!response) {
      showErrorNotification({
        message:
          "API response invalid, please check if URL is se correc in http service or if the server responds it' data correctly",
      });
      return;
    }
    this.setState({ entry: response, loading: false });
  }

  ensureParameters() {
    const { props } = this;
    if (props.tabs.length > 1) {
      if (!props.baseLink) {
        throw new Error('A valid base link is required if you want to use tabs (for navigation)');
      }
      if (props.baseLink.indexOf(':page') === -1) {
        throw new Error(
          'CrudDetailView baseLink requires to have :?page in the url to reflect the tabs',
        );
      }
    }
  }

  async downloadFileByUrlCallback(fileName: string) {
    const { resource } = this.props;

    const payload: IGetFileLinkPayload = {
      fileName,
    };
    this.setState({ loading: true });
    let response;
    try {
      response = await resource.getFileLink(payload);
      window.open(response.link, '_blank');
      this.refreshEntity();
    } catch (e) {
      showErrorNotification(e);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
  }

  async deleteFileByNameCallback(fileName: string) {
    const { resource } = this.props;

    const payload: IGetFileLinkPayload = {
      fileName,
    };
    try {
      await resource.deleteFile(payload);
      const updatedDocuments = this.state.documents.filter(
        (file: any) => (file?.document?.name || file?.name) !== fileName,
      );
      this.setState({ documents: updatedDocuments });
    } catch (e) {
      showErrorNotification(e);
      return;
    }
  }

  async uploadFileCallback(document: File, category: MediaCategoryType) {
    const { resource } = this.props;
    const {
      payload: { id },
    } = this.state;

    const payload: IUploadFilePayload = {
      document,
      category,
      entityId: id,
    };
    try {
      await resource.uploadFile(payload);
      await this.getDocuments();
    } catch (e) {
      showErrorNotification(e);
      return;
    }
  }

  renderContent() {
    const { entityName, hideJsonTab, tabs, match, baseLink, legacy = false } = this.props;
    const { entry, documents, loading } = this.state;

    if (!this.state || loading) {
      return <Loader />;
    }

    if (!entry) {
      return (
        <p>
          failed to load
          {entityName}
        </p>
      );
    }

    const actions = this.renderActions(entry, legacy);

    if (!hideJsonTab) {
      tabs.push({
        name: 'Raw data',
        displayName: 'Raw data',
        render: (entity: any) => <DetailviewJsonTab entry={entity} />,
      });
    }
    const params = match.params as any;
    const currentTabName = params.page;

    // the tab view, needs this custom format for tabs. let's providei ..
    const pages: { [pageRoute: string]: string } = {};
    tabs.forEach((tab: ICrudTab) => {
      pages[tab.name] = tab.displayName;
    });

    let currentTab = tabs.find((tab: ICrudTab) => tab.name === currentTabName);

    // fallback to default, if tab not found;
    if (!currentTab) {
      [currentTab] = tabs;
    }

    const optionalProps = {
      documents,
      onSave: (updatedValues: Entry, onSuccess?: () => void) =>
        this.onSaveCallback(updatedValues, currentTab?.triggerReload, onSuccess),
      onDelete: () => this.onDeleteCallback(),
      getFileLink: (fileName: string) => this.downloadFileByUrlCallback(fileName),
      deleteFile: (fileName: string) => this.deleteFileByNameCallback(fileName),
      uploadFile: (document: File, category: MediaCategoryType) =>
        this.uploadFileCallback(document, category),
    };

    return (
      <>
        {legacy && <div className={styles.legacy}>{actions}</div>}{' '}
        <Row className={styles.row}>
          <Col span={legacy ? 24 : 17}>
            <Tabs
              baseRoute={{
                link: baseLink as string,
                params,
              }}
              activeTitle={`Details of ${entityName}`}
              activePageKey={currentTab.name}
              contentMinHeight="calc(100vh - 280px)"
              pages={pages}
            >
              {currentTab ? currentTab.render(entry, optionalProps) : 'unknown tab'}
            </Tabs>
          </Col>
          {!legacy && <Col span={7}>{actions}</Col>}
        </Row>
      </>
    );
  }

  //  the way to find the dispaly id has to be provided by parameter..
  renderTitle(entry: Entry) {
    const { renderTitle } = this.props;

    const renderStringToTitle = (string: string) => (
      <Typography.Title level={3}>{string}</Typography.Title>
    );

    if (!entry) {
      // still loading..
      return renderStringToTitle('n/a');
    }
    const { entityPrimaryKey } = this.props.resource.config;
    if (entityPrimaryKey && (entry as any)[entityPrimaryKey]) {
      return renderStringToTitle((entry as any)[entityPrimaryKey]);
    }

    // we have an entry and rendering method:
    if (renderTitle) {
      const renderedTitle = renderTitle(entry);
      if (typeof renderedTitle === 'string') {
        return renderStringToTitle(renderedTitle);
      }
      return renderedTitle;
    }

    // fallback
    const title =
      entry.name ||
      entry.displayId ||
      entry.title ||
      entry.value ||
      entry.id ||
      entry.clientName ||
      'n/a';
    return renderStringToTitle(title);
  }

  renderActions(entry: Entry, legacy: boolean): ReactElement | string | undefined {
    const { renderActions } = this.props;
    const refresh = () => setTimeout(() => this.refreshEntity(), 500);

    return (
      <div>
        {legacy ? (
          <div className={styles.containerOuterRow}>
            <div className={styles.containerInnerRow}>
              {renderActions ? renderActions(entry, refresh) : ''}
              {!this.props.shouldHideDelete && (
                <Popconfirm
                  title="Are you sure you want to delete this entry?"
                  onConfirm={() => this.onDeleteCallback()}
                >
                  <Button type="primary" className={styles.button} size="middle" danger>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.containerOuter}>
            <div className={styles.containerInner}>
              {renderActions ? renderActions(entry, refresh) : ''}
              {!this.props.shouldHideDelete && (
                <Popconfirm
                  title="Are you sure you want to delete this entry?"
                  onConfirm={() => this.onDeleteCallback()}
                >
                  <Button type="primary" className={styles.button} size="middle" danger>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  goBack = () => {
    const { history, lastOpenedPage, entityURL, resource } = this.props;
    if (lastOpenedPage && resource.getEntityUrl() === entityURL) {
      history.push(lastOpenedPage);
    } else {
      // @ts-ignore
      history.goBack();
    }
  };

  render() {
    const { entry } = this.state;
    if (!entry) {
      return <div>Loading</div>;
    }

    const title = this.renderTitle(entry);

    return (
      <div>
        {/* @ts-ignore */}
        <Button className={styles.backButton} type="link" onClick={this.goBack}>
          <ArrowLeftOutlined />
          <span>Back</span>
        </Button>
        {title}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lastOpenedPage: state.common.lastOpenedPage,
    entityURL: state.common.entityURL,
  };
};

export default withRouter(connect(mapStateToProps)(CrudDetailViewContainer));
