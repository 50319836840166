import React from 'react';
import { Button, notification } from 'antd';

import ApiClientService from 'services/auth/api-client.service';
import { IApiClient } from 'store/authService/authService.types';
import { showErrorNotification } from 'utils/notificationUtil';

import styles from './ApiClientsActionsComponent.module.sass';

interface IProps {
  entry: IApiClient;
  triggerReload: Function;
}

const ApiClientsActionsComponent: React.FC<IProps> = (props: IProps) => {
  const { entry } = props;

  const regenerateKey = async () => {
    try {
      const res = await ApiClientService.regenerateKey(entry.clientId!);
      notification.info({
        duration: 0,
        message: 'Key successfully regenerated',
        description: (
          <>
            <div>
              <b>API key:</b> {res.apiKey}
            </div>
            <div>
              <b>Client Id:</b> {res.clientId}
            </div>
          </>
        ),
      });
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <div className={styles.containerInner}>
      <Button
        className={styles.button}
        type="primary"
        onClick={() => {
          regenerateKey();
        }}
      >
        Regenerate Key
      </Button>
    </div>
  );
};

export default ApiClientsActionsComponent;
