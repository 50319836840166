import links from 'framework/links';
import {
  ISAAddressesCreatePage,
  ISAAddressesDetailsPage,
  ISAAddressesOverviewPage,
} from 'pages/isa-service/addresses';
import {
  ISAAnswersCreatePage,
  ISAAnswersDetailsPage,
  ISAAnswersOverviewPage,
} from 'pages/isa-service/answers';
import {
  ISACarBrandsCreatePage,
  ISACarBrandsDetailsPage,
  ISACarBrandsOverviewPage,
} from 'pages/isa-service/car-brands';
import {
  ISACarModelsCreatePage,
  ISACarModelsDetailsPage,
  ISACarModelsOverviewPage,
} from 'pages/isa-service/car-models';
import {
  ISADiagnosticStepsCreatePage,
  ISADiagnosticStepsDetailsPage,
  ISADiagnosticStepsOverviewPage,
} from 'pages/isa-service/diagnostic-steps';
import {
  ISADiagnosticsCreatePage,
  ISADiagnosticsDetailsPage,
  ISADiagnosticsOverviewPage,
} from 'pages/isa-service/diagnostics';
import { ISAFeedbacksDetailsPage, ISAFeedbacksOverviewPage } from 'pages/isa-service/feedbacks';
import {
  ISAMappingCreatePage,
  ISAMappingDetailsPage,
  ISAMappingOverviewPage,
} from 'pages/isa-service/mapping';
import {
  ISAProblemCategoriesCreatePage,
  ISAProblemCategoriesDetailsPage,
  ISAProblemCategoriesOverviewPage,
} from 'pages/isa-service/problem-categories';
import {
  ISAProblemsCreatePage,
  ISAProblemsDetailsPage,
  ISAProblemsOverviewPage,
} from 'pages/isa-service/problems';
import {
  ISAQuestionsCreatePage,
  ISAQuestionsDetailsPage,
  ISAQuestionsOverviewPage,
} from 'pages/isa-service/questions';
import {
  ISAServiceTicketsDetailsPage,
  ISAServiceTicketsOverviewPage,
} from 'pages/isa-service/service-tickets';
import {
  ISASolutionStepsCreatePage,
  ISASolutionStepsDetailsPage,
  ISASolutionStepsOverviewPage,
} from 'pages/isa-service/solution-steps';
import {
  ISASolutionsCreatePage,
  ISASolutionsDetailsPage,
  ISASolutionsOverviewPage,
} from 'pages/isa-service/solutions';
import {
  ISAUsersCreatePage,
  ISAUsersDetailsPage,
  ISAUsersOverviewPage,
} from 'pages/isa-service/users';
import {
  ISAWallboxesCreatePage,
  ISAWallboxesDetailsPage,
  ISAWallboxesOverviewPage,
} from 'pages/isa-service/wallboxes';

export const SERVICE_APP_ROUTES = [
  { path: links.isaWallboxesCreate, component: ISAWallboxesCreatePage },
  { path: links.isaWallboxesDetails, component: ISAWallboxesDetailsPage },
  { path: links.isaWallboxesOverview, component: ISAWallboxesOverviewPage },
  { path: links.isaCarBrandsCreate, component: ISACarBrandsCreatePage },
  { path: links.isaCarBrandsDetails, component: ISACarBrandsDetailsPage },
  { path: links.isaCarBrandsOverview, component: ISACarBrandsOverviewPage },
  { path: links.isaCarModelsCreate, component: ISACarModelsCreatePage },
  { path: links.isaCarModelsDetails, component: ISACarModelsDetailsPage },
  { path: links.isaCarModelsOverview, component: ISACarModelsOverviewPage },
  { path: links.isaProblemCategoriesCreate, component: ISAProblemCategoriesCreatePage },
  { path: links.isaProblemCategoriesDetails, component: ISAProblemCategoriesDetailsPage },
  { path: links.isaProblemCategoriesOverview, component: ISAProblemCategoriesOverviewPage },
  { path: links.isaProblemsCreate, component: ISAProblemsCreatePage },
  { path: links.isaProblemsDetails, component: ISAProblemsDetailsPage },
  { path: links.isaProblemsOverview, component: ISAProblemsOverviewPage },
  { path: links.isaSolutionsCreate, component: ISASolutionsCreatePage },
  { path: links.isaSolutionsDetails, component: ISASolutionsDetailsPage },
  { path: links.isaSolutionsOverview, component: ISASolutionsOverviewPage },
  { path: links.isaSolutionStepsCreate, component: ISASolutionStepsCreatePage },
  { path: links.isaSolutionStepsDetails, component: ISASolutionStepsDetailsPage },
  { path: links.isaSolutionStepsOverview, component: ISASolutionStepsOverviewPage },
  { path: links.isaDiagnosticsCreate, component: ISADiagnosticsCreatePage },
  { path: links.isaDiagnosticsDetails, component: ISADiagnosticsDetailsPage },
  { path: links.isaDiagnosticsOverview, component: ISADiagnosticsOverviewPage },
  { path: links.isaDiagnosticStepsCreate, component: ISADiagnosticStepsCreatePage },
  { path: links.isaDiagnosticStepsDetails, component: ISADiagnosticStepsDetailsPage },
  { path: links.isaDiagnosticStepsOverview, component: ISADiagnosticStepsOverviewPage },
  { path: links.isaQuestionsCreate, component: ISAQuestionsCreatePage },
  { path: links.isaQuestionsDetails, component: ISAQuestionsDetailsPage },
  { path: links.isaQuestionsOverview, component: ISAQuestionsOverviewPage },
  { path: links.isaAnswersCreate, component: ISAAnswersCreatePage },
  { path: links.isaAnswersDetails, component: ISAAnswersDetailsPage },
  { path: links.isaAnswersOverview, component: ISAAnswersOverviewPage },
  { path: links.isaMappingCreate, component: ISAMappingCreatePage },
  { path: links.isaMappingDetails, component: ISAMappingDetailsPage },
  { path: links.isaMappingOverview, component: ISAMappingOverviewPage },
  { path: links.isaServiceTicketsDetails, component: ISAServiceTicketsDetailsPage },
  { path: links.isaServiceTicketsOverview, component: ISAServiceTicketsOverviewPage },
  { path: links.isaFeedbacksDetails, component: ISAFeedbacksDetailsPage },
  { path: links.isaFeedbacksOverview, component: ISAFeedbacksOverviewPage },
  { path: links.isaUsersCreate, component: ISAUsersCreatePage },
  { path: links.isaUsersDetails, component: ISAUsersDetailsPage },
  { path: links.isaUsersOverview, component: ISAUsersOverviewPage },
  { path: links.isaAddressCreate, component: ISAAddressesCreatePage },
  { path: links.isaAddressDetails, component: ISAAddressesDetailsPage },
  { path: links.isaAddressOverview, component: ISAAddressesOverviewPage },
];
