import * as React from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';

import { CrudFormDefaultParams } from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import { FULL_DATE_FORMAT } from 'framework/constants/dateFormats';
import { IEmailActivityLog } from 'services/email/email-service.types';
import { formatDefaultDate } from 'utils/date.helper';
import { capitalize } from 'utils/string.helper';

const ActivityLogOverviewTab: React.FC<IGenericEditTabProps<IEmailActivityLog>> = (
  props: IGenericEditTabProps<IEmailActivityLog>,
) => {
  const { entry } = props;
  const { error, payload } = entry;

  const getError = () => {
    console.log('err', error);

    if (error === null) {
      return 'No Error';
    }
    if (error.message) {
      return `Error: ${error.message}`;
    }
    return error;
  };

  const renderItem = (field: keyof IEmailActivityLog) => {
    const item = entry[field];
    let itemToShow;

    switch (field) {
      case 'error':
        itemToShow = getError();
        break;

      case 'timestamp':
        itemToShow = formatDefaultDate(item, FULL_DATE_FORMAT);
        break;

      default:
        itemToShow = item;
        break;
    }

    return (
      <Form.Item name={field} label={capitalize(field)}>
        {itemToShow}
      </Form.Item>
    );
  };

  if (!props) {
    return <div>data entry is missing</div>;
  }

  if (!entry) {
    return <div>Loading ...</div>;
  }

  return (
    <Formik initialValues={entry as IEmailActivityLog} onSubmit={() => {}}>
      {({ handleSubmit }) => (
        <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
          {renderItem('layout')}
          {renderItem('template')}
          {renderItem('jobId')}
          {renderItem('attempts')}
          {renderItem('error')}
          {payload?.recipientName && (
            <Form.Item name="recipientName" label="Recipient Name">
              {payload.recipientName}
            </Form.Item>
          )}
          {renderItem('to')}
          {renderItem('timestamp')}
        </Form>
      )}
    </Formik>
  );
};

export default ActivityLogOverviewTab;
