import links from 'framework/links';
import ActivityLogsOverviewPage from 'pages/tendergy/activity-logs/overview/ActivityLogsOverviewPage';
import CreateAppointmentPage from 'pages/tendergy/appointments/create/CreateAppointmentPage';
import AppointmentDetailsPage from 'pages/tendergy/appointments/details/AppointmentDetailsPage';
import AppointmentsOverviewPage from 'pages/tendergy/appointments/overview/AppointmentsOverviewPage';
import ArchiveRequestsOverviewPage from 'pages/tendergy/archive-requests/overview/ArchiveRequestsOverviewPage';
import BillingOverviewPage from 'pages/tendergy/billing/BillingOverviewPage';
import CreateClientRequestPoolPage from 'pages/tendergy/client-request-pools/create/CreateClintRequestPoolPage';
import ClientRequestPoolsDetailsPage from 'pages/tendergy/client-request-pools/details/ClientRequestPoolsDetailsPage';
import ClientRequestPoolsOverview from 'pages/tendergy/client-request-pools/overview/ClientRequestPoolsOverviewPage';
import ClientRequestDetailsPage from 'pages/tendergy/client-requests/ClientRequestDetailsPage';
import ClientRequestsOverviewPage from 'pages/tendergy/client-requests/ClientRequestsOverviewPage';
import CommentCreatePage from 'pages/tendergy/comments/create/CreateCommentPage';
import CommentDetailsPage from 'pages/tendergy/comments/details/CommentDetailsPage';
import CommentsOverviewPage from 'pages/tendergy/comments/overview/CommentsOverviewPage';
import CountryCreatePage from 'pages/tendergy/country/create/CreateCountryPage';
import CountryDetailsPage from 'pages/tendergy/country/details/CountryDetailsPage';
import CountryOverviewPage from 'pages/tendergy/country/overview/CountryOverviewPage';
import CustomDocumentCreatePage from 'pages/tendergy/custom-documents/create/CustomDocumentCreatePage';
import CustomDocumentDetailsPage from 'pages/tendergy/custom-documents/details/CustomDocumentDetailsPage';
import CustomDocumentsOverviewPage from 'pages/tendergy/custom-documents/overview/CustomDocumentsOverviewPage';
import FeedbackOverviewPage from 'pages/tendergy/feedback/FeedbackOverviewPage';
import HardwareOverviewPage from 'pages/tendergy/hardware/HardwareOverviewPage';
import CreateInvitationLinkPage from 'pages/tendergy/invitation-links/create/createInvitationLinkPage';
import InvitationLinksDetails from 'pages/tendergy/invitation-links/details/InvitationLinksDetailsPage';
import InvitationLinksOverview from 'pages/tendergy/invitation-links/overview/InvitationLinksOverviewPage';
import LeadCreatePage from 'pages/tendergy/leads/create/CreateLeadPage';
import LeadDetailsPage from 'pages/tendergy/leads/details/LeadDetailsPage';
import LeadsOverviewPage from 'pages/tendergy/leads/overview/LeadsOverviewPage';
import OfferDetails from 'pages/tendergy/offers/details/offerDetailsPage';
import OffersOverview from 'pages/tendergy/offers/overview/OffersOverviewPage';
import TendergyOrganizationsPage from 'pages/tendergy/organizations/overview/TendergyOrganizationsPage';
import ProductCreatePage from 'pages/tendergy/product/create/ProductCreatePage';
import ProductDetailsPage from 'pages/tendergy/product/details/ProductDetailsPage';
import ProductsOverviewPage from 'pages/tendergy/product/overview/ProductsOverviewPage';
import CreateQuestionnaireConfigPage from 'pages/tendergy/questionnaire-configs/create/CreateQuestionnaireConfigPage';
import QuestionnaireConfigDetailsPage from 'pages/tendergy/questionnaire-configs/details/QuestionnaireConfigDetailsPage';
import QuestionnaireConfigOverviewPage from 'pages/tendergy/questionnaire-configs/overview/QuestionnaireConfigsOverviewPage';
import CreateQuestionnaireStepPage from 'pages/tendergy/questionnaire-steps/create/CreateQuestionnaireStepPage';
import QuestionnaireStepDetailsPage from 'pages/tendergy/questionnaire-steps/details/QuestionnaireStepDetailsPage';
import QuestionnaireStepOverviewPage from 'pages/tendergy/questionnaire-steps/overview/QuestionnaireStepOverviewPage';
import CreateQuestionnairePage from 'pages/tendergy/questionnaires/create/CreateQuestionnairePage';
import QuestionnaireDetailsPage from 'pages/tendergy/questionnaires/details/QuestionnaireDetailsPage';
import ImportQuestionnairePage from 'pages/tendergy/questionnaires/ImportQuestionnairePage';
import QuestionnaireOverviewPage from 'pages/tendergy/questionnaires/overview/QuestionnaireOverviewPage';
import SurveyDetailsPage from 'pages/tendergy/surveys/details/SurveyDetailsPage';
import SurveyOverviewPage from 'pages/tendergy/surveys/overview/SurveyOverviewPage';
import TendergyUsersOverviewPage from 'pages/tendergy/users/overview/TendergyUsersOverviewPage';
import VendorCreatePage from 'pages/tendergy/vendors/create/VendorCreatePage';
import VendorDetailsPage from 'pages/tendergy/vendors/details/VendorDetailsPage';
import VendorsOverviewPage from 'pages/tendergy/vendors/overview/VendorsOverviewPage';

export const TENDERGY_ROUTES = [
  { path: links.requests, component: ClientRequestsOverviewPage },
  { path: links.request, component: ClientRequestDetailsPage },
  { path: links.offers, component: OffersOverview },
  { path: links.offer, component: OfferDetails },
  { path: links.invitationLinks, component: InvitationLinksOverview },
  { path: links.invitationLink, component: InvitationLinksDetails },
  { path: links.createInviteLink, component: CreateInvitationLinkPage },
  { path: links.clientRequestPools, component: ClientRequestPoolsOverview },
  { path: links.clientRequestPool, component: ClientRequestPoolsDetailsPage },
  { path: links.createClientRequestPool, component: CreateClientRequestPoolPage },
  { path: links.productModels, component: ProductsOverviewPage },
  { path: links.productModel, component: ProductDetailsPage },
  { path: links.createProductModel, component: ProductCreatePage },
  { path: links.productVendors, component: VendorsOverviewPage },
  { path: links.productVendor, component: VendorDetailsPage },
  { path: links.createProductVendor, component: VendorCreatePage },
  { path: links.customDocuments, component: CustomDocumentsOverviewPage },
  { path: links.customDocument, component: CustomDocumentDetailsPage },
  { path: links.createCustomDocument, component: CustomDocumentCreatePage },
  { path: links.comments, component: CommentsOverviewPage },
  { path: links.comment, component: CommentDetailsPage },
  { path: links.createComment, component: CommentCreatePage },
  { path: links.questionnaireConfigs, component: QuestionnaireConfigOverviewPage },
  { path: links.questionnaireConfig, component: QuestionnaireConfigDetailsPage },
  { path: links.createQuestionnaireConfig, component: CreateQuestionnaireConfigPage },
  { path: links.activityLogs, component: ActivityLogsOverviewPage },
  { path: links.leads, component: LeadsOverviewPage },
  { path: links.lead, component: LeadDetailsPage },
  { path: links.createLead, component: LeadCreatePage },
  { path: links.archiveRequests, component: ArchiveRequestsOverviewPage },
  { path: links.feedback, component: FeedbackOverviewPage },
  { path: links.tendergyBilling, component: BillingOverviewPage },
  { path: links.tendergyUsers, component: TendergyUsersOverviewPage },
  { path: links.tendergyOrganizations, component: TendergyOrganizationsPage },
  { path: links.tendergyHardware, component: HardwareOverviewPage },
  { path: links.tendergyQuestionnaires, component: QuestionnaireOverviewPage },
  { path: links.tendergyQuestionnaire, component: QuestionnaireDetailsPage },
  { path: links.tendergyCreateQuestionnaire, component: CreateQuestionnairePage },
  { path: links.tendergyQuestionnaireSteps, component: QuestionnaireStepOverviewPage },
  { path: links.tendergyQuestionnaireStep, component: QuestionnaireStepDetailsPage },
  { path: links.tendergyCreateQuestionnaireStep, component: CreateQuestionnaireStepPage },
  { path: links.tendergyimportQuestionnaire, component: ImportQuestionnairePage },

  { path: links.appointments, component: AppointmentsOverviewPage },
  { path: links.appointment, component: AppointmentDetailsPage },
  { path: links.createAppointment, component: CreateAppointmentPage },
  { path: links.countries, component: CountryOverviewPage },
  { path: links.country, component: CountryDetailsPage },
  { path: links.createCountry, component: CountryCreatePage },
  { path: links.surveys, component: SurveyOverviewPage },
  { path: links.survey, component: SurveyDetailsPage },
];
