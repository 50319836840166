import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, DatePicker, Popconfirm, Row, Table, Tag } from 'antd';
import moment from 'moment';

import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import BillingRfidCardRelationService from 'services/billing/billing-car-owner-rfid-card-relation.service';
import {
  IBillingCarOwner,
  IBillingRFIDCard,
  IBillingRFIDCardCarOwnerRelationship,
} from 'services/billing/billing-service.types';
import RFIDCardsService from 'services/billing/rfid-cards.service';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

const CarOwnerRFIDCardsTab: React.FC<IGenericEditTabProps<IBillingCarOwner>> = (
  props: IGenericEditTabProps<IBillingCarOwner>,
) => {
  const { entry } = props;

  const history = useHistory();

  function navigateToCard(card?: IBillingRFIDCard) {
    if (!card) {
      return;
    }
    const link = RFIDCardsService.getDetailLink(card);
    if (!link) {
      return;
    }
    history.push(link);
  }

  async function dettachRfidCard(relationship: IBillingRFIDCardCarOwnerRelationship) {
    try {
      await BillingRfidCardRelationService.deleteById({ id: relationship.id });
      showSuccessNotification('Successfully dettached, please reload screen');
      // @todo: we should emit a reload to parent view..
    } catch (e) {
      showErrorNotification(e);
    }
  }

  async function updateRelationship(relationship: IBillingRFIDCardCarOwnerRelationship) {
    try {
      const update = {
        id: relationship.id,
        validFrom: relationship.validFrom,
        validTo: relationship.validTo,
        rfidCard: {
          id: relationship.rfidCard?.id,
        },
        carOwner: {
          id: entry.id,
        },
      };

      await BillingRfidCardRelationService.save(update as any);
      showSuccessNotification('Successfully saved, please reload screen');
      // @todo: we should emit a reload to parent view..
    } catch (e) {
      showErrorNotification(e);
    }
  }

  const isActiveRow = (row: IBillingRFIDCardCarOwnerRelationship): boolean => {
    const now = new Date().getTime();

    return moment(now).isBetween(row.validFrom, row.validTo);
  };

  const highlighRow = (row: IBillingRFIDCardCarOwnerRelationship): string =>
    isActiveRow(row) ? 'active' : '';

  const actions = (row: IBillingRFIDCardCarOwnerRelationship) => (
    <Row>
      <Button onClick={() => navigateToCard(row.rfidCard)}>Open RFID Card</Button>
      <Popconfirm
        title="Are you sure you want to delete this relationship?"
        onConfirm={() => dettachRfidCard(row)}
      >
        <Button
          style={{ marginLeft: '10px' }}
          type="primary"
          shape="round"
          danger
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Row>
  );

  const changableDate = (date: Date, onChange: Function) => (
    <DatePicker
      onChange={(newDate) => onChange(newDate ? newDate.toDate() : null)}
      defaultValue={moment(date)}
    />
  );
  const columns = [
    {
      title: 'Identifier',
      dataIndex: 'rfidCard.identifier',
      render: (field: any, row: IBillingRFIDCardCarOwnerRelationship) => row.rfidCard?.identifier,
      width: 150,
      fixed: true,
    },
    {
      title: 'Status',
      render: (date: any, row: IBillingRFIDCardCarOwnerRelationship) =>
        isActiveRow(row) ? <Tag color="green">Active</Tag> : <Tag color="red">Expired</Tag>,
      width: 100,
    },
    {
      title: 'Valid from',
      dataIndex: 'validFrom',
      key: 'validFrom',
      render: (date: Date, card: IBillingRFIDCardCarOwnerRelationship) =>
        changableDate(date, (newDate?: Date) => {
          card.validFrom = newDate;
          updateRelationship(card);
        }),
      width: 200,
    },
    {
      title: 'Valid to',
      dataIndex: 'validTo',
      render: (date: Date, card: IBillingRFIDCardCarOwnerRelationship) =>
        changableDate(date, (newDate?: Date) => {
          card.validTo = newDate;
          updateRelationship(card);
        }),
      width: 200,
    },
    {
      title: 'evcoId',
      dataIndex: 'evcoId',
      render: (field: any, row: IBillingRFIDCardCarOwnerRelationship) => row.rfidCard?.evcoId,
      width: 200,
    },
    {
      title: 'Actions',
      render: (any: any, row: IBillingRFIDCardCarOwnerRelationship) => actions(row),
      width: 250,
    },
  ];

  if (!entry) {
    return <div>Client Request Pool data is missing</div>;
  }

  return (
    <Table
      scroll={{ x: '100%' }}
      rowClassName={highlighRow}
      columns={columns}
      dataSource={entry.carOwnerRFIDCards}
    />
  );
};

export default CarOwnerRFIDCardsTab;
