import config from 'config';

import { ISA_USERS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAUser } from '../isa-service.types';

const ISAUsersService = new EntityService<IISAUser>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_USERS_ENDPOINT,
  createLink: links.isaUsersCreate,
  detailLink: links.isaUsersDetails,
});

ISAUsersService.getDisplayName = (entry) =>
  `${entry.firstName} ${entry.lastName}, ${entry.email}, ${entry.address?.city} ${entry.address?.street} ${entry.address?.doorNumber}`;

export default ISAUsersService;
