import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import ProductAttributesService from 'services/shop/shop-product-attributes.service';
import { IShopProductAttribute } from 'services/shop/shop-service.types';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

const columns: ColumnsType<IShopProductAttribute> = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Subtitle',
    dataIndex: 'subtitle',
    key: 'subtitle',
  },
];

const params: IGetMultiEntityPayload = {
  relations: ['product', 'productAttributeOptions'],
};

const ProductAttributesOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Product Attributes"
    columns={columns}
    defaultPayload={params}
    resource={ProductAttributesService}
  />
);
export default ProductAttributesOverviewPage;
