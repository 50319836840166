import config from 'config';

import { ISA_ADDRESSES_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAAddress } from '../isa-service.types';

const ISAAddressesService = new EntityService<IISAAddress>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_ADDRESSES_ENDPOINT,
  detailLink: links.isaAddressDetails,
});

ISAAddressesService.getDisplayName = (entry) =>
  `${entry.city}, ${entry.street} ${entry.doorNumber}`;

export default ISAAddressesService;
