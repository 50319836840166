import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import CustomDocumentsService from 'services/tendergy/tendergy-custom-documents.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import CustomDocumentBaseStep from './steps/CustomDocumentBaseStep';

const CustomDocumentCreatePage: React.FC = () => {
  const { id } = useRouteMatch().params as any;
  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Add custom document',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <CustomDocumentBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="Custom document"
      steps={steps}
      baseLink={links.customDocuments}
      defaultPayload={params}
      resource={CustomDocumentsService}
    ></CrudCreateWizardContainer>
  );
};

export default CustomDocumentCreatePage;
