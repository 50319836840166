import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IEmailLayout } from './email-service.types';

class EmailLayoutServiceClass extends EntityService<IEmailLayout> {
  getDisplayName(entry: IEmailLayout): string {
    return `${entry.context}, ${entry.language}`;
  }
}

const EmailLayoutService = new EmailLayoutServiceClass({
  apiURL: config.EMAIL_SERVICE_URL,
  entityURL: 'admin/layouts',
  createLink: links.emailCreateLayout,
  detailLink: links.emailLayout,
});

export default EmailLayoutService;
