import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import ProductModelService from 'services/tendergy/tendergy-product.service';
import { IProductModel } from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: ColumnsType<IProductModel> = [
  {
    title: 'Product Model name',
    dataIndex: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Category',
    dataIndex: 'category',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (date: string) => formatDefaultDate(date),
  },
];

const WallboxesOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Product Models"
    columns={columns}
    resource={ProductModelService}
  />
);
export default WallboxesOverviewPage;
