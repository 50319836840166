import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import links from 'framework/links';
import { ISAQuestionsService } from 'services/isa-service';
import { IISAQuestion, IParams } from 'services/isa-service/isa-service.types';

import ISAAnswersTab from './components/ISAAnswersTab';
import ISAQuestionsForm from './components/ISAQuestionsForm';

export default function ISAQuestionsDetails() {
  const { id } = useParams<IParams>();

  return (
    <CrudDetailContainer
      tabs={[
        {
          name: 'details',
          displayName: 'Details',
          render: (entry, { onSave }) => <ISAQuestionsForm entry={entry} onSubmit={onSave} />,
        },
        {
          name: 'answers',
          displayName: 'Answers',
          render: (entry: IISAQuestion) => <ISAAnswersTab answers={entry.answers} />,
        },
      ]}
      entityName="question"
      baseLink={links.isaQuestionsDetails}
      defaultPayload={{ id, relations: ['answers', 'diagnosticStep'] }}
      resource={ISAQuestionsService}
      hideJsonTab
    />
  );
}
