import React from 'react';
import { useParams } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import { IBillingEnergyPrice } from 'services/billing-v3/billing-service.types';
import BillingEnergyPricingService from 'services/billing-v3/billing-v3-energy-prices.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import EnergyPricesActionsComponent from './components/EnergyPricesActionsComponent/EnergyPricesActionsComponent';
import EnergyPriceDetailsTab from './tabs/EnergyPriceDetailsTab';

interface IParams {
  id: string;
}
const BillingEnergyPricePage: React.FC = () => {
  const { id } = useParams<IParams>();

  const params: IGetSingleEntityPayload = {
    id,
    relations: ['documents'],
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: any, optionalProps?: ICrudDetailViewCallbacks) => (
        <EnergyPriceDetailsTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  const renderActions = (entry: IBillingEnergyPrice, triggerReload: Function) => (
    <EnergyPricesActionsComponent entry={entry} triggerReload={triggerReload} />
  );

  return (
    <CrudDetailContainer
      entityName="Energy Price"
      tabs={tabs}
      renderActions={renderActions}
      baseLink={links.billV3EnergyPrice}
      defaultPayload={params}
      resource={BillingEnergyPricingService}
    />
  );
};

export default BillingEnergyPricePage;
