import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IShopProductAttribute } from './shop-service.types';

class ProductAttributesServiceClass extends EntityService<any> {
  getDisplayName(entry: IShopProductAttribute): string {
    return entry && entry.title;
  }
}

const ProductAttributesService = new ProductAttributesServiceClass({
  apiURL: config.SHOP_SERVICE_URL,
  entityURL: 'admin/product-attributes',
  createLink: links.shopCreateProductAttribute,
  detailLink: links.shopProductAttribute,
});

export default ProductAttributesService;
