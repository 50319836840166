import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import BillingDocumentService from 'services/billing/billing-documents.service';
import BillingInvoiceService from 'services/billing/billing-invoices.service';
import { IBillingInvoice } from 'services/billing/billing-service.types';
import { formatDefaultDate } from 'utils/date.helper';
import { showErrorNotification } from 'utils/notificationUtil';

function downloadInvoice(invoice: IBillingInvoice) {
  try {
    BillingInvoiceService.downloadInvoice(invoice);
  } catch (e) {
    showErrorNotification(e);
  }
}

const columns: ColumnsType<any> = [
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
  },

  {
    title: 'EndDate',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (date: string) => formatDefaultDate(date),
  },
  {
    title: 'StartDate',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (date: string) => formatDefaultDate(date),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
  {
    title: 'Action',
    key: 'action',
    render: (date: string, invoice: IBillingInvoice) => (
      <div>
        <Button onClick={() => downloadInvoice(invoice)}>Download</Button>
      </div>
    ),
  },
];

const BillingDocumentPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Billing Documents"
    columns={columns}
    resource={BillingDocumentService}
  />
);
export default BillingDocumentPage;
