import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Pagination,
  Popconfirm,
  Row,
  Table,
  TableProps,
  Tag,
} from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import BillingRfidCardRelationService from 'services/billing/billing-car-owner-rfid-card-relation.service';
import { IBillingRFIDCardCarOwnerRelationship } from 'services/billing/billing-service.types';
import { IBillingCarOwner, IBillingRFIDCard } from 'services/billing-v2/billing-v2-service.types';
import { showErrorNotification, showSuccessNotification } from 'utils/notificationUtil';

import styles from './styles.module.sass';

interface Props {
  entry?: IBillingCarOwner;
}

const RFIDCardsTab: React.FC<Props> = ({ entry }: Props) => {
  const history = useHistory();
  const [count, setCount] = useState<number>();
  const [entries, setEntries] = useState<IBillingRFIDCardCarOwnerRelationship[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [payload, setPayload] = useState<any>({
    limit: 10,
    offset: 0,
    carOwnerId: entry?.id,
  });

  useEffect(() => {
    setPayload({
      ...payload,
      offset: (currentPage - 1) * payload.limit!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const refreshEntries = async () => {
    try {
      const response = await BillingRfidCardRelationService.getEntries({ ...payload });
      setEntries(response.items);
      setCount(response.total || response.count);
    } catch (error) {
      showErrorNotification(error);
    }
  };

  useEffect(() => {
    refreshEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.offset]);

  async function dettachRfidCard(relationship: IBillingRFIDCardCarOwnerRelationship) {
    try {
      await BillingRfidCardRelationService.deleteById({ id: relationship.id });
      showSuccessNotification('Successfully dettached');
      window.location.reload();
    } catch (e) {
      showErrorNotification(e);
    }
  }

  async function updateRelationship(relationship: IBillingRFIDCardCarOwnerRelationship) {
    try {
      const update = {
        ...relationship,
        id: relationship.id,
        validFrom: relationship.validFrom,
        validTo: relationship.validTo,
        rfidCard: {
          id: relationship.rfidCard?.id,
        },
        carOwner: {
          id: entry?.id,
        },
      };

      const res = await BillingRfidCardRelationService.save(update as any);
      if (res?.message) {
        showErrorNotification(res.message);
        return;
      }
      showSuccessNotification('Successfully saved');
      window.location.reload();
    } catch (e) {
      showErrorNotification(e);
    }
  }

  const navigateToCard = (rfidCard?: IBillingRFIDCard) => {
    history.push(createLink(links.billRfidCard, { id: rfidCard?.id }));
  };

  const isActiveRow = (row: IBillingRFIDCardCarOwnerRelationship): boolean => {
    const now = new Date().getTime();

    return moment(now).isBetween(row.validFrom, row.validTo);
  };

  const actions = (row: IBillingRFIDCardCarOwnerRelationship) => (
    <Row>
      <Button onClick={() => navigateToCard(row.rfidCard)}>Open RFID Card</Button>
      <Popconfirm
        title="Are you sure you want to delete this relationship?"
        onConfirm={() => dettachRfidCard(row)}
      >
        <Button
          style={{ marginLeft: '10px' }}
          type="primary"
          shape="round"
          danger
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Row>
  );

  const changableDate = (date: Date, onChange: Function) => (
    <DatePicker
      onChange={(newDate) => onChange(newDate ? newDate.toDate() : null)}
      // @ts-ignore
      defaultValue={date ? moment(date) : ''}
    />
  );
  const handleChangeEnable = (card: IBillingRFIDCardCarOwnerRelationship) => {
    updateRelationship(card);
  };
  const columns = [
    {
      title: 'Identifier',
      dataIndex: 'rfidCard.identifier',
      render: (field: any, row: IBillingRFIDCardCarOwnerRelationship) => row.rfidCard?.identifier,
      width: 150,
      fixed: true,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Status',
      render: (date: any, row: IBillingRFIDCardCarOwnerRelationship) => {
        const now = new Date().getTime();
        console.log('date is', row);
        if (row.validTo === null) {
          return <Tag color="yellow">To Validate</Tag>;
        }
        if (row.validTo && row.validFrom && moment(now).isBefore(row.validFrom)) {
          return <Tag color="green">To be activate</Tag>;
        }
        return isActiveRow(row) ? <Tag color="green">Active</Tag> : <Tag color="red">Expired</Tag>;
      },
      width: 100,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Valid from',
      dataIndex: 'validFrom',
      render: (date: Date, card: IBillingRFIDCardCarOwnerRelationship) =>
        changableDate(date, (newDate?: Date) => {
          card.validFrom = newDate;
          updateRelationship(card);
        }),
      width: 200,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Valid to',
      dataIndex: 'validTo',
      render: (date: Date, card: IBillingRFIDCardCarOwnerRelationship) =>
        changableDate(date, (newDate?: Date) => {
          card.validTo = newDate;
          updateRelationship(card);
        }),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      width: 200,
    },
    {
      title: 'Is Enanbled',
      dataIndex: 'isEnanbled',
      render: (date: Date, card: IBillingRFIDCardCarOwnerRelationship) => {
        console.log(card);
        return (
          <Checkbox
            checked={card.isEnabled}
            onChange={(e: any) => handleChangeEnable({ ...card, isEnabled: e.target.checked })}
          >
            is RFID Enabled
          </Checkbox>
        );
      },
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
      width: 200,
    },
    {
      title: 'Actions',
      render: (any: any, row: IBillingRFIDCardCarOwnerRelationship) => actions(row),
      width: 250,
    },
  ];

  const tableProps: TableProps<IBillingRFIDCardCarOwnerRelationship> = {
    scroll: { x: '100%' },
    columns: columns,
    dataSource: entries,
    pagination: false,
  };

  const paginationProps: PaginationProps = {
    total: count,
    showTotal: (total) => `Total ${total} items`,
    showSizeChanger: false,
    current: currentPage,
    onChange: setCurrentPage,
    showQuickJumper: true,
    defaultPageSize: payload.limit,
  };

  if (!entry) {
    return <div>Car Owner data is missing</div>;
  }

  return (
    <>
      <Table {...tableProps} />
      <div className={styles.pagination}>
        <Pagination {...paginationProps} />
      </div>
    </>
  );
};

export default RFIDCardsTab;
