import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { isObject, isString } from 'formik';
import debounce from 'lodash/debounce';

import EntityService from 'services/abstract/entity.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';

const { Option } = Select;

type QuestionnaireType = {
  name: string;
};

// any = record type
export interface IEntitySelectorProps extends SelectProps<any> {
  current?: string | any;
  questionnaire?: QuestionnaireType;
  entityService: EntityService<any>; //
  onSelect?: (entryId: any) => void; // emits the fund entity as full object!
  defaultPayload?: IGetMultiEntityPayload; // if we need sorting/filtering/etc. for search request
  name: string; // TODO: Change select to formik-antd and use name
}

const QuestionnaireEntitySelector: React.FC<IEntitySelectorProps> = (
  props: IEntitySelectorProps,
) => {
  const {
    onSelect,
    current,
    questionnaire,
    entityService,
    defaultPayload,
    disabled,
    ...rest
  } = props;
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState<{ id: string; label?: string; entry: any }[]>(
    [],
  );
  const [currentEntity, setCurrentEntity] = useState<any>(current);
  const [isEditMode, setIsEditMode] = useState<boolean>(!current);

  const history = useHistory();

  const searchEntities = async (searchQuery: string) => {
    const entities = await entityService.search(searchQuery, 50, defaultPayload);
    const items: any[] = [...entities?.items];
    if (Array.isArray(currentEntity)) {
      currentEntity.forEach((entity) => {
        !items.find((item) => item.id === entity.id) && items.push(entity);
      });
    }
    const results = items?.map((entry) => {
      const label = entityService.getDisplayName(entry);
      return { label, id: entry.id, entry };
    });
    setSearchResults(results);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce((q) => searchEntities(q), 500),
    [],
  );

  useEffect(() => {
    if (!isEditMode) {
      return;
    }
    delayedSearch(query);
  }, [query, isEditMode, entityService, delayedSearch]);

  useEffect(() => {
    let currentId: string = current;
    if (!current) {
      return;
    }
    // no need to fetch;
    if (typeof current === 'object' && current) {
      setCurrentEntity(current);
      return;
    }
    if (current.id) {
      currentId = current.id;
    }

    if (typeof current === 'string' && current) {
      currentId = current;
      setCurrentEntity(current);
    }

    entityService.getById({ id: currentId, ...defaultPayload }).then(() => {
      setCurrentEntity(currentEntity);
      setIsEditMode(false);
    });
    // @todo get view value of organization, so it's shown correctly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, entityService]);

  const entiyName = entityService.getName() || '';

  const onSelectEvent = (selected: string | string[], options: any | any[]) => {
    if (!onSelect) {
      return;
    }
    if (Array.isArray(options)) {
      // TODO: If this is good then use same approach for single select
      onSelect(options.map((option) => option?.item?.entry));
      return;
    }
    const foundEntity = searchResults.find((e) => e.entry.id === selected);
    onSelect(foundEntity?.entry);
  };

  // eslint-disable-next-line consistent-return
  const getDefaultValue = () => {
    if (!currentEntity) {
      return undefined;
    }
    if (Array.isArray(currentEntity)) {
      return currentEntity.map((entity) => entity && entity.id);
    }
    if (isObject(currentEntity)) {
      return currentEntity?.id;
    }
    if (isString(currentEntity)) {
      return currentEntity;
    }
  };

  const renderAutocomplete = () => (
    <Select
      defaultValue={getDefaultValue()}
      showSearch
      style={{ width: 200 }}
      placeholder={`Select ${entiyName}`}
      optionFilterProp="children"
      onChange={(value, options) => onSelectEvent(value as any, options as any[])}
      onSearch={(searchTerm: string) => setQuery(searchTerm)}
      disabled={disabled}
      {...rest}
    >
      {searchResults &&
        searchResults?.map((item) => (
          <Option key={item.id} value={item.id} item={item}>
            {item.label}
          </Option>
        ))}
    </Select>
  );

  const displayName = () => {
    if (!currentEntity) {
      return '';
    }

    if (Array.isArray(currentEntity)) {
      return (
        <span style={{ marginRight: 10 }}>
          {currentEntity.map((entity) => entityService.getDisplayName(entity)).join(', ')}
        </span>
      );
    }

    return (
      <span style={{ marginRight: 10 }}>
        {entityService.getDisplayName(currentEntity, questionnaire)}
      </span>
    );
  };

  const detailLink = entityService.getDetailLink(currentEntity);

  return (
    <>
      {isEditMode ? renderAutocomplete() : null}
      {current && (
        <div>
          {displayName()}{' '}
          {!isEditMode && !disabled ? (
            <Button onClick={() => setIsEditMode(true)}>
              <EditOutlined />
            </Button>
          ) : null}
          {detailLink ? (
            <Button onClick={() => history.push(detailLink)}>
              <EyeOutlined />
            </Button>
          ) : null}
        </div>
      )}
    </>
  );
};

export default QuestionnaireEntitySelector;
