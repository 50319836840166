import React from 'react';
import { Form } from 'formik-antd';

import { FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import { IISAWallbox } from 'services/isa-service/isa-service.types';

import { INITIAL_VALUES, validationSchema } from '../validation';

interface IFormProps {
  entry?: IISAWallbox;
  onSubmit: (entry: IISAWallbox) => Promise<IISAWallbox>;
}

export default function ISAWallboxesForm(props: IFormProps) {
  const { entry = INITIAL_VALUES, onSubmit } = props;

  const formikProps: IFormikFormProps<IISAWallbox> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IISAWallbox,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="name" label="Wallbox Title" required />
      <FormInput name="logicalName" label="Logical Name" required />
      <FormInput name="description" label="Description" required />
      <FormInput name="imageUrl" label="Image Url" required />
      {entry?.imageUrl && (
        <Form.Item name="image" label=" " colon={false}>
          <img src={entry.imageUrl} alt={entry.imageUrl} width={100} />
        </Form.Item>
      )}
      <FormInput name="sequenceNumber" label="Sequence Number" type="number" required />
      <FormSubmit />
    </FormikForm>
  );
}
