import * as Yup from 'yup';

//
// description?: string;
// information?: string;
// name: string;
// order: number;
// price?: number;
// priceComment?: string;
// stockQuantity?: number;
// vat?: number;

export const ProductAttributeOptionsValidation = {
  name: Yup.string().required('Required').min(2, 'Too short!').max(50, 'Too long!'),
  description: Yup.string().nullable().min(2, 'Too short!').max(3000, 'Too long!'),
  information: Yup.string().nullable().min(2, 'Too short!').max(3000, 'Too long!'),
  index: Yup.number().required('Required!').positive('Index cannot be less than 0'),
  price: Yup.number().nullable(),
  priceComment: Yup.string().nullable().min(2, 'Too short!').max(3000, 'Too long!'),
  stockQuantity: Yup.number().nullable(),
  vat: Yup.number().nullable(),
  productAttribute: Yup.object().required('Required'),
  type: Yup.string().required('Required'),
  chargePoints: Yup.number().nullable(),
  kwAC: Yup.number().nullable(),
  plugType: Yup.string().nullable(),
  phases: Yup.number().nullable(),
  accessSecurity: Yup.string().nullable(),
  subsidyReady: Yup.boolean().nullable(),
  communicationModule: Yup.string().nullable(),
  communicationProtocol: Yup.string().nullable(),
  metering: Yup.string().nullable(),
  securityComponents: Yup.boolean().nullable(),
  installation: Yup.boolean().nullable(),
};
