import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import EmailLayoutService from 'services/email/email-layouts.service';
import { IEmailLayout } from 'services/email/email-service.types';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import LayoutOverviewTab from './tabs/LayoutOverviewTab';

const LayoutDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IEmailLayout, optionalProps?: ICrudDetailViewCallbacks) => (
        <LayoutOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  return (
    <CrudDetailContainer
      entityName="Layout"
      tabs={tabs}
      baseLink={links.emailLayout}
      defaultPayload={params}
      resource={EmailLayoutService}
    />
  );
};

export default LayoutDetailsPage;
