import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';

import links from 'framework/links';
import { createLink } from 'framework/utils/links';
import { IShopProductAttributeOption } from 'services/shop/shop-service.types';
import { CurrencyUtils } from 'utils/currencyUtils';

interface Props {
  options?: IShopProductAttributeOption[];
}

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Price',
    key: 'price',
    dataIndex: 'price',
    render: (value: number) => CurrencyUtils.defaultFormatter(value / 100),
  },
  {
    title: 'Vat',
    key: 'vat',
    dataIndex: 'vat',
  },
];

const AttributeOptionsTab: React.FC<Props> = ({ options }: Props) => {
  const history = useHistory();

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={options}
      onRow={(record) => ({
        onClick: () =>
          history.push(createLink(links.shopProductAttributeOption, { id: record.id })),
      })}
    />
  );
};

export default AttributeOptionsTab;
