import React, { useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Input, SubmitButton, Switch } from 'formik-antd';
import moment from 'moment';

import EntitySelector from 'components/forms/organization/EntitySelector';
import { Loader } from 'components/ui';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import MembershipService from 'services/tendergy/tendergy-membership.service';
import MembershipOptionService from 'services/tendergy/tendergy-membership-option.service';
import TendergyOrganizationService from 'services/tendergy/tendergy-organization.service';
import {
  IMembershipOption,
  ITendergyMembershipPlan,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';
import { showErrorNotification } from 'utils/notificationUtil';

interface IProps {
  organization?: ITendergyOrganization;
}

const MembershipTab: React.FC<IProps> = ({ organization }: IProps) => {
  const [membership, setMembership] = useState<ITendergyMembershipPlan | null>(null);
  const [membershipOptions, setMembershipOptions] = useState<IMembershipOption[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (organization?.id) {
      getCraftData(organization.id);
    }
  }, [organization]);

  useEffect(() => {
    getMembershipOptions();
  }, []);

  const getCraftData = async (id: string) => {
    setLoading(true);
    try {
      const response = await TendergyOrganizationService.getMembership(id);
      setMembership(response);
      setLoading(false);
    } catch (e) {
      showErrorNotification(e);
      setLoading(false);
    }
  };

  const getMembershipOptions = async () => {
    try {
      const response = await MembershipOptionService.getEntries();
      setMembershipOptions(response.items);
    } catch (e) {
      //showErrorNotification(e);
    }
  };

  if (loading) {
    return <Loader />;
  }
  const onSubmitMembership = async (values: ITendergyMembershipPlan) => {
    if (organization?.id) {
      const payload = {
        ...values,
        optionsIncluded: (values?.optionsIncluded || [])?.map((item) => item.id) as any,
        organization: {
          id: organization.id,
        } as ITendergyOrganization,
        id: membership?.id || undefined,
      };

      try {
        const response = await MembershipService.save(payload);
        message.success(`Membership successfully ${membership ? 'updated' : 'created'}`);
        setMembership(response);
      } catch (e) {
        showErrorNotification(e);
      }
    }
  };

  const defaultValues: Partial<ITendergyMembershipPlan> = {
    monthlyPrice: membership?.monthlyPrice || 0,
    validUntil: membership?.validUntil,
    startFrom: membership?.startFrom || new Date(),
    commission: membership?.commission || 0,
    optionsIncluded: membership?.optionsIncluded,
    intermediatesWhiteList: membership?.intermediatesWhiteList,
    viewAllInstallers: membership?.viewAllInstallers,
    organization: membership?.organization?.id
      ? ({ id: membership.organization.id } as ITendergyOrganization)
      : undefined,
  };

  const switchOption = (
    optionToSwitch: IMembershipOption,
    optionsList: IMembershipOption[] = [],
  ) => {
    const existedOption = optionsList.find((item) => item.name === optionToSwitch.name);
    if (existedOption) {
      return optionsList.filter((item) => item.name !== optionToSwitch.name);
    } else {
      return [...optionsList, optionToSwitch];
    }
  };

  const convertOptionName = (name: string) => {
    const convertedName = name.charAt(0).toUpperCase() + name.slice(1);
    return convertedName.replaceAll('-', ' ');
  };

  return (
    <div>
      {!membership && <h4>Membership not found, you can create it in the following form.</h4>}
      <Formik initialValues={defaultValues} onSubmit={(values) => onSubmitMembership(values)}>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="startFrom" label="Start From">
              <DatePicker inputReadOnly name="startFrom" placeholder="" />
            </Form.Item>
            <Form.Item name="validUntil" label="Valid Until">
              <DatePicker
                inputReadOnly
                name="validUntil"
                placeholder=""
                disabledDate={(current) => current && current < moment().endOf('day')}
                allowClear
              />
            </Form.Item>
            <Form.Item name="monthlyPrice" label="Monthly Price">
              <Input name="monthlyPrice" type="number" />
            </Form.Item>
            <Form.Item name="commission" label="Commission">
              <Input name="commission" type="number" />
            </Form.Item>
            <Form.Item name="optionsIncluded" label="Membership Options">
              {membershipOptions?.map((option) => (
                <Row key={option.name} gutter={[8, 8]} wrap={false}>
                  <Col>
                    <Switch
                      checked={!!values.optionsIncluded?.find((item) => item.name === option.name)}
                      name={option.name}
                      onChange={() =>
                        setFieldValue(
                          'optionsIncluded',
                          switchOption(option, values.optionsIncluded),
                        )
                      }
                    />
                  </Col>
                  <Col>
                    {convertOptionName(option.name)}. {option.description}
                  </Col>
                </Row>
              ))}
            </Form.Item>
            <Form.Item name="viewAllInstallers" label="View All Installers">
              <Switch name="viewAllInstallers" />
            </Form.Item>
            <Form.Item name="intermediatesWhiteList" label="Organizations White List">
              <EntitySelector
                disabled={values.viewAllInstallers}
                onSelect={(membershipOption: ITendergyOrganization[]) => {
                  setFieldValue('intermediatesWhiteList', membershipOption);
                }}
                entityService={TendergyOrganizationService}
                mode="multiple"
                name="intermediatesWhiteList"
                current={values.intermediatesWhiteList}
              />
            </Form.Item>
            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton type="primary" htmlType="submit" disabled={isSubmitting}>
                {!membership ? 'Create' : 'Save'}
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MembershipTab;
