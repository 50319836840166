import * as React from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, Switch } from 'formik-antd';
import * as Yup from 'yup';

import EntitySelector from 'components/forms/organization/EntitySelector';
import {
  CrudFormDefaultActionParams,
  CrudFormDefaultParams,
} from 'framework/abstract-crud/abstractCrud.constants';
import { IGenericEditTabProps } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import ProductAttributeOptionsService from 'services/shop/shop-product-options.service';
import ProductsService from 'services/shop/shop-products.service';
import {
  IShopProduct,
  IShopProductAttribute,
  IShopProductAttributeOption,
} from 'services/shop/shop-service.types';

import { ProductAttributesValidation } from '../../product-attributes.validation';

/**
 * @docs: how to render forms:
 *  https://github.com/jannikbuschke/formik-antd
 * @param props
 */
const ProductAttributeOverviewTab: React.FC<IGenericEditTabProps<IShopProductAttribute>> = (
  props: IGenericEditTabProps<IShopProductAttribute>,
) => {
  const { entry, onSave } = props;

  if (!props) {
    return <div>data entry is missing</div>;
  }
  if (!onSave) {
    return <div> require save method </div>;
  }

  if (!entry) {
    return <div> Loading </div>;
  }

  const validation = Yup.object().shape(ProductAttributesValidation);

  return (
    <div>
      <Formik
        initialValues={entry as IShopProductAttribute}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values).then(() => setSubmitting(false));
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form {...CrudFormDefaultParams} onFinish={handleSubmit}>
            <Form.Item name="title" label="Title" required>
              <Input name="title" placeholder="" />
            </Form.Item>
            <Form.Item name="subtitle" label="Subtitle">
              <Input name="subtitle" placeholder="" />
            </Form.Item>
            <Form.Item name="descriptionTitle" label="Description Title">
              <Input name="descriptionTitle" placeholder="" />
            </Form.Item>
            <Form.Item name="orderTitle" label="Order Title">
              <Input name="orderTitle" placeholder="" />
            </Form.Item>
            <Form.Item name="index" label="Index" required>
              <Input name="index" placeholder="" type="number" />
            </Form.Item>
            <Form.Item name="required" label="Required">
              <Switch name="required" />
            </Form.Item>
            <Form.Item name="productAttributeOptions" label="Options">
              <EntitySelector
                name="productAttributeOptions"
                entityService={ProductAttributeOptionsService}
                current={entry.productAttributeOptions}
                mode="multiple"
                onSelect={(options: IShopProductAttributeOption[]) =>
                  setFieldValue('productAttributeOptions', options)
                }
              />
            </Form.Item>
            <Form.Item name="product" label="Product" required>
              <EntitySelector
                name="product"
                entityService={ProductsService}
                current={entry.product}
                onSelect={(product: IShopProduct) => setFieldValue('product', product)}
              />
            </Form.Item>
            <Form.Item {...CrudFormDefaultActionParams} name="submit">
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
                style={{ marginRight: '20px' }}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductAttributeOverviewTab;
