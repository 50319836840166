import config from 'config';

import { ISA_SOLUTION_STEPS_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISASolutionStep } from '../isa-service.types';

const ISASolutionStepsService = new EntityService<IISASolutionStep>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_SOLUTION_STEPS_ENDPOINT,
  createLink: links.isaSolutionStepsCreate,
  detailLink: links.isaSolutionStepsDetails,
});

ISASolutionStepsService.getDisplayName = (entry) => entry.title;

export default ISASolutionStepsService;
