import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudDetailContainer from 'framework/abstract-crud/CrudDetailContainer';
import { ICrudDetailViewCallbacks } from 'framework/abstract-crud/interfaces/abstractCrud.intefaces';
import links from 'framework/links';
import { IEmailTemplate } from 'services/email/email-service.types';
import EmailTemplatesService from 'services/email/email-templates.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import TemplateOverviewTab from './tabs/TemplateOverviewTab';

const TemplateDetailsPage: React.FC = () => {
  const { id } = useRouteMatch().params as any;

  const params: IGetSingleEntityPayload = {
    id,
  };

  const tabs = [
    {
      name: 'Details',
      displayName: 'Details',
      render: (entity: IEmailTemplate, optionalProps?: ICrudDetailViewCallbacks) => (
        <TemplateOverviewTab entry={entity} onSave={optionalProps?.onSave} />
      ),
    },
  ];

  return (
    <CrudDetailContainer
      entityName="Template"
      tabs={tabs}
      baseLink={links.emailTemplate}
      defaultPayload={params}
      resource={EmailTemplatesService}
    />
  );
};

export default TemplateDetailsPage;
