import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { FieldArray, Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Location } from 'history';

import { FormInput } from 'components/forms';
import { CrudFormDefaultActionParams } from 'framework/abstract-crud/abstractCrud.constants';
import { IShopLanding } from 'services/shop/shop-service.types';

import BlockContentComponent from './BlockContentComponent';
import DeleteButton from './DeleteCircleBtn';
import LandingBlock from './LandingBlock';

import styles from './LandingComponentsStyles.module.sass';

const { Title } = Typography;

interface LandingFormProps {
  entry: IShopLanding;
  onSubmitStep?: (values: IShopLanding) => Promise<IShopLanding>;
  labelCol: object;
  wrapperCol: object;
}

interface IState {
  storeId: string;
}

const LandingForm: React.FC<LandingFormProps> = (props: LandingFormProps) => {
  const { entry, onSubmitStep, labelCol, wrapperCol } = props;
  const location: Location<IState> = useLocation();

  useEffect(() => {
    console.log(JSON.stringify(location.state?.storeId));
  }, [location]);

  const LandingFormParams = {
    labelCol: labelCol,
    wrapperCol: wrapperCol,
  };

  const addBlockHandler = (arrayHelpers: any) => {
    arrayHelpers.push({
      type: 'horizontal',
      style: null,
      content: [''],
      shopUrl: true,
    });
  };

  if (!onSubmitStep) {
    return <div> require onSubmitStep method </div>;
  }

  return (
    <Formik
      initialValues={entry as IShopLanding}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitStep && onSubmitStep(values).then(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, values }) => (
        <Form {...LandingFormParams} onFinish={handleSubmit}>
          <div className={styles.center}>
            <Title level={3}>General info</Title>
          </div>
          <FormInput name="key" label="Key" required />
          <FormInput name="store.id" label="Store" required value={location.state?.storeId} />
          <FormInput name="menuLinkText" label="Menu link text" />
          <FormInput name="homePageButtonText" label="Homepage button text" />
          <FormInput name="tileTitle" label="Tile title" />
          <FormInput name="tileIcon" label="Tile image" />
          <FormInput name="tileDescription" label="Tile description" />
          <FormInput name="order" label="Order" />
          <div className={styles.center}>
            <Title level={3}>Page header</Title>
          </div>
          <FormInput name="scheme.title" label="Page title" />
          <FormInput name="scheme.subTitle" label="Page sub title" />
          <FormInput name="scheme.headerImage" label="Header Image" />

          <div className={styles.center}>
            <Title level={3}>Landing blocks</Title>
          </div>
          <FieldArray
            name="scheme.blocks"
            render={(arrayHelpers) => {
              return (
                <div>
                  {values.scheme?.blocks?.map((block: any, index: number) => {
                    return (
                      <div className={styles.landingBlock} key={index}>
                        <DeleteButton
                          tooltip="Delete this block"
                          onClick={() => arrayHelpers.remove(index)}
                        />

                        <LandingBlock index={index} />
                        <BlockContentComponent values={values} index={index} />
                      </div>
                    );
                  })}
                  <div className={styles.center}>
                    <Button className={styles.button} onClick={() => addBlockHandler(arrayHelpers)}>
                      Add new block
                    </Button>
                  </div>
                </div>
              );
            }}
          />

          <Form.Item {...CrudFormDefaultActionParams} name="submit">
            <SubmitButton>Submit</SubmitButton>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};
export default LandingForm;
