import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IAuthRole } from 'store/authService/authService.types';

class AuthRoleServiceClass extends EntityService<IAuthRole> {
  getDisplayName(entry: IAuthRole): string {
    return entry && entry.name;
  }

  updatePermissions = (roleId: number, permissions: number[]) =>
    this.patch(`admin/roles/${roleId}/permissions`, { permissions });
}

const AuthRoleService = new AuthRoleServiceClass({
  apiURL: config.ROLE_SERVICE_URL,
  entityURL: 'admin/roles',
  // createLink: links.createRole,
  detailLink: links.authRole,
});

export default AuthRoleService;
