import React from 'react';

import { FormikForm, FormInput, FormSubmit } from 'components/forms';
import { IFormikFormProps } from 'components/forms/FormikForm';
import FormSwitch from 'components/forms/FormSwitch/FormSwitch';
import { IBillingOrganization } from 'services/billing-v2/billing-v2-service.types';

import { validationSchema } from '../../validation';

import styles from './styles.module.sass';

interface BillOrganizationFormProps {
  entry?: IBillingOrganization;
  onSubmit: (entry: IBillingOrganization) => Promise<IBillingOrganization>;
}

const BillOrganizationForm = (props: BillOrganizationFormProps) => {
  const { entry, onSubmit } = props;

  const formikProps: IFormikFormProps<IBillingOrganization> = {
    onSubmit,
    validationSchema,
    initialValues: entry as IBillingOrganization,
  };

  return (
    <FormikForm {...formikProps}>
      <FormInput name="name" label="Name" required />
      <FormInput name="customerNumber" label="Customer Number" />
      <div className={styles.centered}>
        <h4>Address</h4>
      </div>
      <FormInput name="address.country" label="Country" />
      <FormInput name="address.iso3166_1_alpha_3" label="ISO 3166-1 alpha-3 Code" required />
      <FormInput name="address.region" label="Region" />
      <FormInput name="address.city" label="City" required />
      <FormInput name="address.address" label="Address" required />
      <FormInput name="address.postalCode" label="Postcode" required />
      <FormInput name="address.latitude" label="Latitude" />
      <FormInput name="address.longitude" label="Longitude" />
      <FormSwitch name="generateCoordinates" label="Auto-generate coordinates" defaultChecked />
      <FormSubmit />
    </FormikForm>
  );
};

export default BillOrganizationForm;
