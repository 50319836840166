import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(256, 'Field has not to be longer than 256 characters!')
    .required('Required field'),
  customerNumber: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
  supportMail: Yup.string().nullable().email(),
  supportNumber: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
  address: Yup.object()
    .nullable()
    .shape({
      address: Yup.string().nullable().max(256, 'Field has not to be longer than 256 characters!'),
      city: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
      region: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
      country: Yup.object()
        .nullable()
        .shape({
          nameDe: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
          nameEn: Yup.string().nullable().max(64, 'Field has not to be longer than 64 characters!'),
        }),
      postalCode: Yup.string().nullable().max(10, 'Field has not to be longer than 10 characters!'),
      latitude: Yup.string().nullable(),
      longitude: Yup.string().nullable(),
    }),
  generateCoordinates: Yup.boolean().nullable(),
});
