import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import CrudCreateWizardContainer, {
  ICrudStep,
} from 'framework/abstract-crud/CrudCreateWizardContainer';
import links from 'framework/links';
import InvitationLinkService from 'services/tendergy/tendergy-invitation-links.service';
import { IGetSingleEntityPayload } from 'store/storeInterfaces';

import InvitationLinkBaseStep from './steps/invitationLinkBaseStep';

const CreateInvitationLinkPage: React.FC = () => {
  const { id } = useRouteMatch().params as any; // not sure how we can explicit allow params

  const params: IGetSingleEntityPayload = {
    id,
  };

  const steps: ICrudStep[] = [
    {
      name: 'Basic Inforamtion',
      description: 'required information',
      render: (entry, onSubmitStep) => (
        <InvitationLinkBaseStep entry={entry} onSubmitStep={onSubmitStep} />
      ),
    },
  ];
  return (
    <CrudCreateWizardContainer
      entityName="registration Links"
      steps={steps}
      baseLink={links.invitationLinks}
      defaultPayload={params}
      resource={InvitationLinkService}
    />
  );
};

export default CreateInvitationLinkPage;
