import React from 'react';
import { CaretDownOutlined, CaretUpOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Radio, Select } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { SearchProps } from 'antd/lib/input';
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';
import { SelectProps } from 'antd/lib/select';

import { EnumSortDirections } from 'constants/crud';

import { IColumns } from '../CrudListViewContainer';

export interface ISortField {
  dataIndex: any;
  title: any;
}

export interface ICrudListViewActionsProps<T> {
  createLink?: string | null;
  entityName: string;
  disableSearch?: boolean;
  withSorting?: boolean;
  sortFields?: ISortField[];
  statesForFiltering?: string[];
  columns: IColumns<T>[];
  onFilter: (value: string) => void;
  onSearch: (value: string) => void;
  onCreate: () => void;
  onSort: (value: string) => void;
  onSortDirection: (e: RadioChangeEvent) => void;
  isCustomFilters?: boolean;
}

export default function CrudListViewActions<T>(props: ICrudListViewActionsProps<T>) {
  const {
    onFilter,
    onSearch,
    onCreate,
    createLink,
    columns,
    entityName,
    disableSearch,
    statesForFiltering,
    withSorting,
    sortFields,
    onSort,
    onSortDirection,
    isCustomFilters,
  } = props;

  const buttonProps: ButtonProps = {
    type: 'primary',
    shape: 'round',
    icon: <PlusOutlined />,
    onClick: onCreate,
  };
  const searchProps: SearchProps = {
    placeholder: 'Search entries..',
    onSearch,
    allowClear: true,
    style: { width: 240 },
  };

  const selectProps: SelectProps<string> = {
    style: { width: 240, marginBottom: 10 },
    placeholder: 'Filter entries',
    onChange: onFilter,
    allowClear: true,
  };

  const sortSelectProps: SelectProps<string> = {
    style: { minWidth: 240, marginBottom: 10 },
    placeholder: 'Sort by',
    onChange: onSort,
    allowClear: true,
  };

  const sortDirectionRadioProps: RadioGroupProps = {
    style: { marginBottom: 10, marginRight: 10 },
    onChange: onSortDirection,
  };

  return (
    <Col span={12} style={{ justifyContent: 'right', textAlign: 'right' }}>
      {createLink && (
        <div style={{ marginBottom: 10 }}>
          <Button {...buttonProps}>Add new {entityName}</Button>
        </div>
      )}

      {!disableSearch && !isCustomFilters && (
        <div style={{ marginBottom: 10 }}>
          <Input.Search {...searchProps} />
        </div>
      )}

      {statesForFiltering && !isCustomFilters && (
        <Select {...selectProps}>
          {statesForFiltering.map((offerState) => (
            <Select.Option key={offerState} value={offerState}>
              {offerState}
            </Select.Option>
          ))}
        </Select>
      )}

      {withSorting && !isCustomFilters && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Radio.Group {...sortDirectionRadioProps}>
            <Radio.Button value={1}>
              {EnumSortDirections.ASC} <CaretUpOutlined />
            </Radio.Button>
            <Radio.Button value={-1}>
              {EnumSortDirections.DESC} <CaretDownOutlined />
            </Radio.Button>
          </Radio.Group>
          <Select {...sortSelectProps}>
            {(sortFields || (columns as ISortField[])).map(({ title, dataIndex }) => (
              <Select.Option key={dataIndex} value={dataIndex}>
                {title}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}
    </Col>
  );
}
