import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { IBillingRFIDCardCarOwnerRelationship } from 'services/billing-v3/billing-v3-service.types';

class BillingRfidCardRelationServiceClass extends EntityService<IBillingRFIDCardCarOwnerRelationship> {
  downloadPDFInvoice(carOwnerId: string, carOwnerName?: string, year?: number, month?: number) {
    // /api/v1/organizations/{id}/document/csv
    const filename = `Proof-of-Charge-${carOwnerName}-${year}-${month}.pdf`;
    const url = `${this.getEntityUrl()}/${carOwnerId}/document/pdf`;
    const params = {
      year,
      month,
    };
    this.downloadFile(url, params, filename);
  }

  getDisplayName(entry: IBillingRFIDCardCarOwnerRelationship): string {
    return `${entry && entry.rfidCard && entry.rfidCard.evcoId}-relationship`;
  }
}

const BillingRfidCardRelationService = new BillingRfidCardRelationServiceClass({
  apiURL: config.BILLING_SERVICE_URL,
  entityURL: 'admin/car-owner-rfid-cards',
  detailLink: links.billingCarOwner,
  overviewLink: links.billingCarOwners,
});

// this way I can autowire the result (with command + .);
export default BillingRfidCardRelationService;
