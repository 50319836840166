import * as Yup from 'yup';

/*
export interface IBillingChargingStation {
  evse: string;
  label?: string;
  type: ChargingStationTypeEnum;
  name?: string;
  country?: string;
  region?: string;
  city?: string;
  postal_code?: string;
  street?: string;
  house_number?: string;
  latitude?: string;
  longitude?: string;

}
*/
export const ChargingStationValidation = {
  evse: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  label: Yup.string().min(2, 'Too Short!').max(20, 'Too Long!').nullable(),
  type: Yup.string().min(2, 'Too Short!').max(15, 'Too Long!').required('Required'),
  name: Yup.string().nullable().min(2, 'Too Short!').max(20, 'Too Long!'),
};
