import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import UserTendergyAPI from 'services/tendergy/tendergy-user.service';
import { IGetMultiEntityPayload } from 'store/storeInterfaces';
import {
  ITendergyClientRequestPool,
  ITendergyOrganization,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

import UsersFilters from '../components/UsersFilters';

const payload: IGetMultiEntityPayload = {
  sortFields: 'createdAt',
  sortDirections: '-1',
};

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'email',
    render: (value: string) => value || '–',
  },
  {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (value: string) => value || '–',
    defaultSortOrder: 'ascend' as SortOrder,
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    key: 'lastName',
    render: (value: string) => value || '–',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'organization',
    sortParameter: 'organization.name',
    render: (organization: ITendergyOrganization) => organization?.name || '–',
  },
  {
    title: 'Pool',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'currentPool',
    key: 'currentPool',
    sortParameter: 'currentPool.name',

    render: (currentPool: ITendergyClientRequestPool) => currentPool?.name || '–',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
  },
];

const TendergyUsersOverviewPage: React.FC = () => {
  return (
    <CrudListViewContainer
      entityName="Users"
      defaultPayload={payload}
      columns={columns}
      resource={UserTendergyAPI}
      customFilters={UsersFilters}
    />
  );
};

export default TendergyUsersOverviewPage;
