import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import CrudListViewContainer from 'framework/abstract-crud/CrudListViewContainer';
import { IB } from 'pages/billing-v3/charging-stations/constants';
import QuestionnaireConfigService from 'services/tendergy/questionnaire-config.service';
import {
  ITendergyClientRequestPool,
  ITendergyQuestionnaireConfig,
} from 'store/tendegyService/tendergyService.types';
import { formatDefaultDate } from 'utils/date.helper';

const columns: IB<ITendergyQuestionnaireConfig>[] = [
  {
    title: 'Question Key',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    dataIndex: 'questionKey',
    key: 'questionKey',
  },
  {
    title: 'Pool',
    dataIndex: 'pool',
    key: 'pool',
    sortParameter: 'pool.name',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    render: (pool: ITendergyClientRequestPool) => (pool?.name ? pool.name : '-'),
  },
  {
    title: 'Visible',
    dataIndex: 'isVisible',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'isVisible',
    render: (value: boolean) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Disabled',
    dataIndex: 'isDisabled',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'isDisabled',
    render: (value: boolean) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    key: 'createdAt',
    render: (date: string) => formatDefaultDate(date),
  },
];

const QuestionnaireConfigOverviewPage: React.FC = () => (
  <CrudListViewContainer
    entityName="Questionnaire Configs"
    columns={columns}
    resource={QuestionnaireConfigService}
  />
);
export default QuestionnaireConfigOverviewPage;
