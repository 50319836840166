import config from 'config';

import { ISA_WALLBOXES_ENDPOINT } from 'constants/endpoints';
import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';

import { IISAWallbox } from '../isa-service.types';

const ISAWallboxesService = new EntityService<IISAWallbox>({
  apiURL: config.I2G_SERVICE_URL,
  entityURL: ISA_WALLBOXES_ENDPOINT,
  createLink: links.isaWallboxesCreate,
  detailLink: links.isaWallboxesDetails,
});

ISAWallboxesService.getDisplayName = (entry) => entry.name || entry.logicalName;

export default ISAWallboxesService;
