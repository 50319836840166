import config from '../../config';
import links from '../../framework/links';
import EntityService from '../abstract/entity.service';
import { IConsultantParameters } from './consultant-service.types';

class ConsultantFleetTypeClass extends EntityService<IConsultantParameters> {
  getDisplayName(entry: IConsultantParameters): string {
    return entry?.items?.[0].entity;
  }
}
export const ConsultantFleetTypeService = new ConsultantFleetTypeClass({
  apiURL: config.CONSULTANT_SERVICE_URL,
  entityURL: 'admin/fleet-types',
  detailLink: links.eConsultantFleetTypesDetail,
});
