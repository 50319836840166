/* eslint-disable @typescript-eslint/no-unused-vars */
import jwt_decode from 'jwt-decode';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  AUTH_TOKEN_KEY,
  FINGERPRINT,
  REFRESH_TOKEN_EXPIRES_KEY,
  REFRESH_TOKEN_KEY,
} from 'framework/constants';
import AuthAPI from 'services/auth/auth.service';
import UserAuthAPI from 'services/auth/user-auth.service';

import { showNotification } from '../../utils/notificationUtil';
import {
  loginError,
  loginSuccess,
  logoutError,
  logoutSuccess,
  tokenRefresh,
  tokenRefreshError,
  tokenRefreshSuccess,
} from './auth.actions';
import { AUTH_INIT, AUTH_LOGIN, AUTH_LOGOUT, AUTH_TOKEN_REFRESH, LoginAction } from './auth.types';

export function* authInit() {
  const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);
  // Check if token exist
  if (accessToken) {
    const tokenDecoded: any = jwt_decode(accessToken || '');
    const { exp, iat, ...user } = tokenDecoded;
    const isTokenValid = exp * 1000 - Date.now() > 0;

    if (isTokenValid) {
      const permissions: string[] = yield call(UserAuthAPI.getUserPermissions);
      if (!permissions?.includes('admin_login')) {
        yield put(loginError('NOT_AUTHORIZED'));
      } else {
        yield put(loginSuccess({ ...user, permissions }, accessToken));
      }
    } else {
      yield put(tokenRefresh());
    }
  } else {
    yield put(loginError('NOT_AUTHORIZED'));
  }
}

export function* login(action: LoginAction) {
  try {
    const { fingerprint } = yield select((state) => state.auth);
    const {
      data: { accessToken, refreshSession },
    } = yield call(AuthAPI.login, {
      ...action.payload,
      fingerprint,
    });
    const { exp, iat, ...user }: any = jwt_decode(accessToken || '');

    localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshSession?.refreshToken);
    localStorage.setItem(REFRESH_TOKEN_EXPIRES_KEY, refreshSession?.expires);

    const permissions: string[] = yield call(UserAuthAPI.getUserPermissions);
    if (!permissions?.includes('admin_login')) {
      yield put(loginError('NOT_AUTHORIZED'));
    } else {
      yield put(loginSuccess({ ...user, permissions }, accessToken));
    }
  } catch (err) {
    showNotification({ message: 'Wrong login or password!' });
    yield put(loginError(err));
  }
}

export function* refreshToken() {
  try {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) || '';
    const currentAccessToken = localStorage.getItem(AUTH_TOKEN_KEY) || '';
    const fingerprint = localStorage.getItem(FINGERPRINT) || '';

    const response = yield call(
      AuthAPI.tokenRefresh,
      fingerprint,
      refreshToken,
      currentAccessToken,
    );
    const { accessToken, refreshSession } = response.data;
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshSession.refreshToken);
    localStorage.setItem(REFRESH_TOKEN_EXPIRES_KEY, refreshSession.expires);

    const permissions: string[] = yield call(UserAuthAPI.getUserPermissions);
    const tokenDecoded: any = jwt_decode(accessToken || '');
    const { exp, iat, ...user } = tokenDecoded;

    if (!permissions?.includes('admin_login')) {
      yield put(loginError('NOT_AUTHORIZED'));
    } else {
      yield put(loginSuccess({ ...user, permissions }, accessToken));
    }

    yield put(tokenRefreshSuccess({ ...user, permissions }, accessToken));
  } catch (err) {
    yield put(tokenRefreshError(err));
  }
}

export function* logout() {
  try {
    const state = yield select();
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) || '';
    yield call(AuthAPI.logout, state?.auth?.fingerprint, refreshToken);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_EXPIRES_KEY);
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutError());
  }
}

export default function* root() {
  yield all([
    takeLatest(AUTH_INIT, authInit),
    takeLatest(AUTH_LOGIN, login),
    takeLatest(AUTH_TOKEN_REFRESH, refreshToken),
    takeLatest(AUTH_LOGOUT, logout),
  ]);
}
