import config from 'config';

import links from 'framework/links';
import EntityService from 'services/abstract/entity.service';
import { ITendergyUser } from 'store/tendegyService/tendergyService.types';

import { IRejectPayload } from '../../store/storeInterfaces';

class UserAPI extends EntityService<ITendergyUser> {
  confirmById = (id: string) => this.post(`admin/installers/${id}/approve`, {}, true);

  rejectById = (payload: IRejectPayload) =>
    this.post(
      `admin/installers/${payload.id}/reject`,
      { rejectReason: payload.rejectReason },
      true,
    );
  sendPasswordSetEmail = (id: string) =>
    this.post(`${this.config.entityURL}/${id}/onboardingEmail`);

  sendVerificationEmail = (id: string) =>
    this.post(`${this.config.entityURL}/${id}/email-verify-resend`, { context: 'tendergy' });

  getDisplayName(entry: ITendergyUser): string {
    return entry.firstName && entry.lastName ? `${entry.firstName} ${entry.lastName}` : entry.id;
  }

  exportUsers = () =>
    this.downloadFile(this.getURL(`${this.config.entityURL}/export/csv`), null, 'users.csv');
}
class UserAuthAPI extends EntityService<ITendergyUser> {
  sendPasswordRecoverEmail = (email: string) =>
    this.post(`auth/recover`, { email, context: 'tendergy' });
}

const TendergyUserService = new UserAPI({
  apiURL: config.CRAFT_SERVICE_URL,
  entityURL: 'admin/users',
  detailLink: links.user,
});
export const TendergyUserAuthService = new UserAuthAPI({
  apiURL: config.CRAFT_AUTH_SERVICE_URL,
  entityURL: 'admin/users',
  detailLink: links.user,
});
export default TendergyUserService;
